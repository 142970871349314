import React, { useRef, useState, useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom'
import { post, get, put } from 'src/utils/apiManager';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CFormLabel,
  CRow,
} from '@coreui/react'
import { DefaultInput, DefaultTextarea } from 'src/components/common/input'
import { validateOrderNote } from 'src/utils/validator'

function add() {
  const navigate = useNavigate()
  const formRef = useRef(null)
  const [isDisable, setIsDisable] = useState(false);
  const state = {}
  const [iD, setID] = useState('')

  useEffect(() => {
    getOrderNote();
  }, [])

  const getOrderNote = () => {
    get(`/admin/order-note/get-orderNote`).then((res) => {
      handleSingleDataResponse(res)
    })
  }

  const handleSingleDataResponse = (res) => {
    if (!res.status) toast.success(res.message, res.status);
    formRef.current.setFieldValue('note_EN', res?.data?.note_EN)
    formRef.current.setFieldValue('note_TH', res?.data?.note_TH)
    formRef.current.setFieldValue('timingNote_EN', res?.data?.timingNote_EN)
    formRef.current.setFieldValue('timingNote_TH', res?.data?.timingNote_TH)
    formRef.current.setFieldValue('orderNote_EN', res?.data?.orderNote_EN)
    formRef.current.setFieldValue('orderNote_TH', res?.data?.orderNote_TH)
    formRef.current.setFieldValue('baseAmnt', res?.data?.baseAmountOfFreeDelivery)
    formRef.current.setFieldValue('pickupGapTime', res?.data?.placeToPickupTime)
    formRef.current.setFieldValue('deliveryGapTime', res?.data?.pickupToDeliverTime)
    if (res?.data) setID(res?.data?._id)
  }

  const submit = (values) => {
    setIsDisable(true)
    const data = {
      note_EN: values?.note_EN,
      note_TH: values?.note_TH,
      timingNote_EN: values?.timingNote_EN,
      timingNote_TH: values?.timingNote_TH,
      orderNote_EN: values?.orderNote_EN,
      orderNote_TH: values?.orderNote_TH,
      baseAmountOfFreeDelivery: values?.baseAmnt,
      placeToPickupTime: values?.pickupGapTime,
      pickupToDeliverTime: values?.deliveryGapTime
    }
    if (iD) {
      put(`/admin/order-note/update-orderNote?_id=${iD}`, data).then((res) => {
        handleSubmitResponse(res);
      })
    } else {
      post(`/admin/order-note/add-orderNote`, data).then((res) => {
        handleSubmitResponse(res);
      })
    }
  }

  const handleSubmitResponse = (res) => {
    if (res?.status == true) {
      toast.success(res?.message)
      getOrderNote()
    }
  }

  const onClickReset = () => {
    // Reset Button Function
    formRef.current.resetForm();
  }


  return (
    <>
      <CContainer >
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <CRow>
                        <CCol md="11">
                          <h1>Order Note</h1>
                        </CCol>
                      </CRow>
                      <Formik
                        initialValues={{
                          note_EN: '',
                          note_TH: '',
                          timingNote_EN: '',
                          timingNote_TH: '',
                          orderNote_EN: '',
                          orderNote_TH: '',
                          baseAmnt: '',
                          pickupGapTime: '',
                          deliveryGapTime: '',
                        }}
                        validationSchema={validateOrderNote}
                        onSubmit={(values, { setSubmitting }) => {
                          submit(values)
                          setSubmitting(false)
                        }}
                        innerRef={formRef}
                      >
                        {(props) => (
                          <form onSubmit={props?.handleSubmit}>
                            <CRow xs={12}>
                              <CCol md={6}>
                                <CFormLabel>Order Place to Pickup Time (in hours)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={"text"}
                                  placeholder={"Place to pickup time"}
                                  value={props?.values?.pickupGapTime}
                                  onChange={props?.handleChange("pickupGapTime")}
                                  onBlur={() => props?.setFieldTouched('pickupGapTime', true)}
                                  error={props?.touched?.pickupGapTime && props?.errors?.pickupGapTime}
                                  onKeyUp={() => {
                                    if (state?.pickupGapTime == props?.values?.pickupGapTime)
                                      setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />

                                <DefaultTextarea
                                  placeholder={'Note (EN)'}
                                  name={'Note (EN)'}
                                  id={'note_EN'}
                                  value={props?.values?.note_EN}
                                  onChange={props?.handleChange('note_EN')}
                                  onBlur={() => props?.setFieldTouched('note_EN', true)}
                                  error={props?.touched?.note_EN && props?.errors?.note_EN}
                                  onKeyUp={() => {
                                    if (state?.note_EN == props?.values?.note_EN) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />

                                <DefaultTextarea
                                  placeholder={'Timing note (EN)'}
                                  name={'Timing Note (EN)'}
                                  id={'timing_note_EN'}
                                  value={props?.values?.timingNote_EN}
                                  onChange={props?.handleChange('timingNote_EN')}
                                  onBlur={() => props?.setFieldTouched('timingNote_EN', true)}
                                  error={props?.touched?.timingNote_EN && props?.errors?.timingNote_EN}
                                  onKeyUp={() => {
                                    if (state?.timingNote_EN == props?.values?.timingNote_EN)
                                      setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />

                                <DefaultTextarea
                                  placeholder={'Order note (EN)'}
                                  name={'Order Note (EN)'}
                                  id={'oder_note_EN'}
                                  value={props?.values?.orderNote_EN}
                                  onChange={props?.handleChange('orderNote_EN')}
                                  onBlur={() => props?.setFieldTouched('orderNote_EN', true)}
                                  error={props?.touched?.orderNote_EN && props?.errors?.orderNote_EN}
                                  onKeyUp={() => {
                                    if (state?.orderNote_EN == props?.values?.orderNote_EN)
                                      setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                                
                                <CFormLabel>Base Amount of free delivery</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={"text"}
                                  placeholder={"Amount of free delivery"}
                                  value={props?.values?.baseAmnt}
                                  onChange={props?.handleChange("baseAmnt")}
                                  onBlur={() => props?.setFieldTouched('baseAmnt', true)}
                                  error={props?.touched?.baseAmnt && props?.errors?.baseAmnt}
                                  onKeyUp={() => {
                                    if (state?.baseAmnt == props?.values?.baseAmnt)
                                      setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />


                              </CCol>
                              <CCol md="6">

                              <CFormLabel>Order Pickup to Delivery Time (in hours)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={"text"}
                                  placeholder={"Pickup to delivery time"}
                                  value={props?.values?.deliveryGapTime}
                                  onChange={props?.handleChange("deliveryGapTime")}
                                  onBlur={() => props?.setFieldTouched('deliveryGapTime', true)}
                                  error={props?.touched?.deliveryGapTime && props?.errors?.deliveryGapTime}
                                  onKeyUp={() => {
                                    if (state?.deliveryGapTime == props?.values?.deliveryGapTime)
                                      setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />

                                <DefaultTextarea
                                  placeholder={'Note (TH)'}
                                  name={'Note (TH)'}
                                  id={'note_TH'}
                                  value={props?.values?.note_TH}
                                  onChange={props?.handleChange('note_TH')}
                                  onBlur={() => props?.setFieldTouched('note_TH', true)}
                                  error={props?.touched?.note_TH && props?.errors?.note_TH}
                                  onKeyUp={() => {
                                    if (state?.note_TH == props?.values?.note_TH)
                                      setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />

                                <DefaultTextarea
                                  placeholder={'Timing note (TH)'}
                                  name={'Timing Note (TH)'}
                                  id={'timing_note_TH'}
                                  value={props?.values?.timingNote_TH}
                                  onChange={props?.handleChange('timingNote_TH')}
                                  onBlur={() => props?.setFieldTouched('timingNote_TH', true)}
                                  error={props?.touched?.timingNote_TH && props?.errors?.timingNote_TH}
                                  onKeyUp={() => {
                                    if (state?.timingNote_TH == props?.values?.timingNote_TH)
                                      setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />

                                <DefaultTextarea
                                  placeholder={'Order note (TH)'}
                                  name={'Order Note (TH)'}
                                  id={'oder_note_TH'}
                                  value={props?.values?.orderNote_TH}
                                  onChange={props?.handleChange('orderNote_TH')}
                                  onBlur={() => props?.setFieldTouched('orderNote_TH', true)}
                                  error={props?.touched?.orderNote_TH && props?.errors?.orderNote_TH}
                                  onKeyUp={() => {
                                    if (state?.orderNote_TH == props?.values?.orderNote_TH)
                                      setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />



                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol md="2">
                                <button
                                  type="submit"

                                  className={'btn btn-success mt-2'}
                                  disabled={!isDisable || props?.isSubmitting}
                                >
                                  Submit
                                </button>
                              </CCol>
                              <CCol md="2">
                                <button
                                  type="button"
                                  className={'btn btn-warning mt-2'}
                                  onClick={() => navigate('/admin/dashboard')}
                                >
                                  Cancel
                                </button>
                              </CCol>
                              <CCol md="3"></CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default add