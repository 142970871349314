import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CRow,
  CImage,
} from '@coreui/react'
import { FaArrowLeft } from 'react-icons/fa'
import { ImageUrl } from 'src/config/config'

function SerivceProcessDetail() {
  const { state } = useLocation()
  return (
    <>
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <CRow>
                    <CCol>
                      {' '}
                      <Link style={{ color: '#000' }} to={{ pathname: `/admin/service-process` }}>
                        <FaArrowLeft />
                      </Link>
                      <span> Serivce Process Information</span>
                    </CCol>
                  </CRow>
                </CHeader>
                <CCardBody>
                  <CRow>
                    <CCol md={2}>
                      <strong>Title(English):-</strong>
                    </CCol>
                    <CCol>
                      <span>{state?.title_en}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Title(Thai):-</strong>
                    </CCol>
                    <CCol>
                      <span>{state?.title_th}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Description(English):-</strong>
                    </CCol>
                    <CCol>
                      <span dangerouslySetInnerHTML={{ __html: state?.description_en }}></span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Description(Thai):-</strong>
                    </CCol>
                    <CCol>
                      <span dangerouslySetInnerHTML={{ __html: state?.description_th }}></span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Image:-</strong>
                    </CCol>
                    <CCol>
                      <div
                        style={{
                          marginTop: '10px',
                          justifyContent: 'center',
                        }}
                      >
                        <a target="_blank" href={`${ImageUrl}${state?.image}`}>
                          <CImage
                            rounded
                            src={`${ImageUrl}${state?.image}`}
                            style={{
                              maxHeight: '100px',
                              maxWidth: '100px',
                              borderRadius: 50,
                              alignItems: 'center',
                            }}
                          />
                        </a>
                      </div>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default SerivceProcessDetail
