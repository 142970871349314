import React, { useRef, useState } from 'react';
import { Formik } from 'formik';
import { useNavigate, Link } from 'react-router-dom'
import { post } from 'src/utils/apiManager';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CFormLabel,
  CRow,
} from '@coreui/react'
import { DefaultInput } from 'src/components/common/input'
import { validateReason } from 'src/utils/validator'
import Select from 'react-select'
function add() {
  const navigate = useNavigate()
  const formRef = useRef(null)
  const [disable, setDisable] = useState(false)
  const [selectedReason, setSelectedReason] = useState(null);
  const reasonOptions = [
    { value: "driver", label: "Driver" },
    { value: "customer", label: "Customer" }
  ]

  const submit = async (values) => {
    setDisable(true)
    let temp = {
      reason: values?.reason,
      reasonType: values?.reasonType,
    }
    post(`/admin/reasons/add-reason`, temp).then((data) => handleResponse(data))
  }

  const handleResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      setTimeout(() => {
        navigate('/admin/reasons')
      }, 800)
    }
    else {
      setDisable(false)
      toast.error(data?.message)
    }
    formRef.current.resetForm()
  }

  const onClickReset = () => {
    console.log("this")
    console.log(this)
  };

  return (
    <>
      <CContainer >
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <CRow>
                        <CCol md="11">
                          <h3>Add New Reason</h3>
                        </CCol>
                        <CCol md="1">
                          <Link className={'btn btn-success mt-2'} style={{ background: '#d3dc40', border: '#d3dc40' }} to={{ pathname: `/admin/reasons` }}>
                            Back
                          </Link>
                        </CCol>
                      </CRow>
                      <Formik
                        initialValues={{
                          reason: '',
                          reasonType: '',
                        }}
                        validationSchema={validateReason}
                        onSubmit={(values, { setSubmitting }) => {
                          submit(values)
                          setSubmitting(false)
                        }}
                        innerRef={formRef}
                      >
                        {(props) => (
                          <form onSubmit={props?.handleSubmit}>
                            <CRow xs={12}>
                              <CCol md={6}>
                                <CFormLabel>Reason</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={"text"}
                                  placeholder={"Reason"}
                                  value={props?.values?.reason}
                                  onChange={props?.handleChange("reason")}
                                  onBlur={() => props?.setFieldTouched("reason", true)}
                                  error={props?.touched?.reason && props?.errors?.reason}
                                />

                              </CCol>
                              <CCol>
                                <CFormLabel>Reason Type</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>

                                <Select
                                  defaultValue={selectedReason}
                                  onChange={(val) => {
                                    setSelectedReason(val)
                                    props?.setFieldValue('reasonType', val?.value)
                                  }}
                                  options={reasonOptions}
                                  value={selectedReason}
                                  placeholder={'Select Reason Type'}
                                  name="Reason Type"
                                />
                                {selectedReason == undefined ? (
                                  <span className="text-danger">{props?.errors?.reasonType}</span>
                                ) : (
                                  ''
                                )}
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol md="2">
                                <button
                                  type="submit"

                                  className={'btn btn-success mt-2'}
                                  disabled={disable || props?.isSubmitting}
                                >
                                  Submit
                                </button>
                              </CCol>
                              <CCol md="2">
                                <button
                                  type="button"
                                  className={'btn btn-warning mt-2'}
                                  onClick={() => onClickReset(props.isSubmitting)}
                                >
                                  Reset
                                </button>
                              </CCol>
                              <CCol md="3"></CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default add