import { CCard, CCardBody, CCardGroup, CCol, CContainer, CRow } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { post, get } from 'src/utils/apiManager'
import Swal from 'sweetalert2'

const Bags = () => {
  const [bags, setBags] = useState([])
  const [orderData, setOrderData] = useState([])
  const { orderId } = useParams()
  const navigate = useNavigate()
  const confirmOrder = () => {
    if (bags?.length > 0) {
      let data = bags?.find((val) => {
        if (val.item.length == 0) {
          return val
        }
      })
      if (data) {
        toast.error("please add bag item")
        return
      }
    }
    let checkBagStatus = bags.every((bag) => bag.status == "active");

    if (!checkBagStatus) {
      toast.error("Please complated all bags !")
      return
    }

    Swal.fire({
      title: 'Are you sure to confirm the order?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      customClass: {
        actions: 'my-actions',
        confirmButton: 'order-2',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // Swal.fire('Completed!', '', 'success')
        api()
      }
    })
    const api = () =>
      get(`/admin/offline-order/confirm-order/${orderId}`).then((res) => {
        if (res?.status) {
          navigate('/admin/offline-order')
        }
      })
  }
  useEffect(() => {
    handleBagData()
  }, [])
  const handleBagData = () => {
    get(`/admin/offline-order/bag-list/${orderId}`).then((res) => {
      console.log({ res }, 'aa')
      if (res?.data !== null && res?.data?.length > 0) {
        // console.log({})
        setBags([...res?.data[0]?.bags])
        let newData = res?.data?.map((d) => {
          return {
            ...d,
            addOnServiceDetail: d?.addOnServiceDetail ? JSON.parse(d?.addOnServiceDetail) : [],
          }
        })
        setOrderData(newData)
      }
    })
  }
  const bagActions = (action) => {
    if (action === 'add') {
      post(`/admin/offline-order/bag-create`, { orderId, action }).then((res) => {
        console.log({ res })
        if (res?.data !== null && res?.data?.length > 0) {
          // console.log({})
          handleBagData()
          // setBags([...res?.data[0]?.bags])
        }
      })
    } else {
      let removeBagId = bags[bags.length - 1]
      Swal.fire({
        title: 'Are you sure you want to remove the last bag?',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          actions: 'my-actions',
          confirmButton: 'order-2',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          // Swal.fire('Removed!', '', 'success')
          if (removeBagId) {
            post(`/admin/offline-order/bag-create`, {
              orderId,
              action,
              bagId: removeBagId._id,
            }).then((res) => {
              console.log({ res })
              handleBagData()
              // if (res?.data !== null && res?.data?.length > 0) {
              //   handleBagData()
              //   // setBags([...res?.data[0]?.bags])
              // }
            })
          }
        }
      })
      // const userConfirmed = window.confirm('Are you sure you want to remove the last bag?')
      // if (userConfirmed) {
      //   if (removeBagId) {
      //     post(`/admin/offline-order/bag-create`, {
      //       orderId,
      //       action,
      //       bagId: removeBagId._id,
      //     }).then((res) => {
      //       console.log({ res })
      //       handleBagData()
      //       // if (res?.data !== null && res?.data?.length > 0) {
      //       //   handleBagData()
      //       //   // setBags([...res?.data[0]?.bags])
      //       // }
      //     })
      //   }
      // }
    }
  }
  const deleteBag = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be delete this bag",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        post(`/admin/offline-order/bag-delete`, { bagId: id?._id }).then((res) => {
          if (res?.status) {
            toast.success(res?.message)
            let filterData = bags.filter((val) => val._id !== id._id)
            setBags(filterData)

            // setBags([...res?.data[0]?.bags])
          }
        }).catch(err => {
          console.log(err);
          toast.success(err?.message)
        })
      }
    })
  }
  const handleBagAddDetail = (bagId, order_id, serviceName, unit) => {
    navigate(`/admin/offline-order/bag/add-item-service/${bagId}/${order_id}?serviceName=${serviceName}`, { state: { bags } })
    //     if (serviceName && unit) {
    //       get(`/admin/unit/get-unit/${unit}`).then((res) => {
    //         if (res?.data?.title_EN === 'pcs') {
    //           for (const _bag of bags) {
    //             if (_bag._id === bagId) {
    //               if (Number(_bag.unitValue) === _bag.item.length) {
    //                 toast.warn("Bag is already full.")
    //               } else {
    //                 navigate(`/admin/offline-order/bag/add-item-service/${bagId}/${order_id}?serviceName=${serviceName}`, { state: { bags } })
    // 
    //               }
    //             }
    //           }
    //         } else {
    //           navigate(`/admin/offline-order/bag/add-item-service/${bagId}/${order_id}?serviceName=${serviceName}`, { state: { bags } })
    //         }
    // 
    //       })
    // 
    //     } else {
    //       navigate(`/admin/offline-order/bag/add-item-service/${bagId}/${order_id}?serviceName=${serviceName}`, { state: { bags } })
    // 
    //     }

  }
  return (
    <CContainer>
      <CCard>
        <CCardGroup>
          <CCardBody>
            <CRow className="p-2">
              <CCol>
                <h5>Order Details</h5>
              </CCol>
              <CCol md="12" style={{ marginLeft: '10px' }}>
                {orderData && orderData[0] && orderData[0].orderId && (
                  <p className="mb-1">
                    <b>OrderId:</b> {orderData[0].orderId}
                  </p>
                )}
                {orderData && orderData[0] && orderData[0].orderStatus && (
                  <p className="mb-1">
                    <b>Order Status:</b> {orderData[0].orderStatus.toUpperCase()}
                  </p>
                )}
                {orderData && orderData[0] && orderData[0].orderType && (
                  <p className="mb-1">
                    <b>Order Type:</b> {orderData[0].orderType.toUpperCase()}
                  </p>
                )}
                {orderData && orderData[0] && orderData[0].QR && orderData[0].QR[0] && (
                  <p className="mb-1">
                    <b>QR Code ID:</b> {orderData[0].QR[0].QrId}
                  </p>
                )}
                {orderData && orderData[0] && orderData[0].customer && orderData[0].customer[0] && (
                  <p className="mb-1">
                    <b>Customer Name:</b> {orderData[0].customer[0].username}
                  </p>
                )}
                {orderData && orderData[0] && orderData[0].customer && orderData[0].customer[0] && (
                  <p className="mb-1">
                    <b>Customer Phone:</b> {orderData[0].customer[0].mobile}
                  </p>
                )}

                {orderData && orderData[0] && orderData[0].addressId && orderData[0].addressId[0] && (
                  <p className="mb-1">
                    <b>Customer Address:</b> {orderData[0].addressId[0].address}
                  </p>
                )}
                {orderData && orderData[0] && orderData[0]?.customerNotes && (
                  <p className="mb-1">
                    <b>Customer Notes:</b> {orderData[0].customerNotes}
                  </p>
                )}


                {orderData[0]?.addOnServiceDetail?.length > 0 ? (
                  <p className="mb-1">
                    <b>Addons:</b>{' '}
                  </p>
                ) : (
                  ''
                )}
                {orderData[0]?.addOnServiceDetail?.map((addon, k) => (
                  <div className="">
                    <span>
                      {' '}
                      <b>Name: </b> {addon?.serviceName_EN}{' '}
                    </span>
                    ,
                    <span>
                      {' '}
                      <b>Price: </b> {addon?.price}{' '}
                    </span>
                    ,
                    <span>
                      {' '}
                      <b>Quantity: </b> {addon?.quantity}{' '}
                    </span>
                  </div>
                ))}
                {/* {orderData && orderData[0] && orderData[0].customer && orderData[0].customer[0] && (
                  <p className="mb-1"><b>Add-On Service Requested:</b> {orderData[0].customer[0].username}</p>
                )} */}
                {orderData && orderData[0] && orderData[0].noteToGarmentExpert && (
                  <p className="mb-1">
                    <b>Addtional Comments:{orderData[0].noteToGarmentExpert}</b>{' '}
                  </p>
                )}
              </CCol>
            </CRow>
            <CRow className="p-2 align-items-center">
              <CCol>
                <h5 style={{ textTransform: 'uppercase' }}>Order summary page</h5>
              </CCol>
              <CCol md="1">
                <Link
                  className={'btn btn-success'}
                  style={{ background: '#d3dc40', border: '#d3dc40' }}
                  to={{ pathname: `/admin/offline-order` }}
                >
                  Back
                </Link>
              </CCol>
            </CRow>
            <hr />

            <div
              className="bag-list-box"
              style={{ maxHeight: '520px', overflowX: 'auto', overflow: 'auto' }}
            >
              <CRow className="p-2 align-items-center">
                {bags.length > 0 &&
                  bags.map((data, index) => (
                    <CCol xs={4} key={index} className="p-3">
                      <div
                        className="card p-3"
                        style={{ display: 'flex' }}
                        onClick={() => handleBagAddDetail(data._id, orderId, !data.service
                          ? ''
                          : data.service._id, data?.service?.unit)}
                      >
                        <span
                          onClick={(e) => { e.stopPropagation(); deleteBag(data) }}
                          class="mx-2 text-danger"
                          style={{
                            fontSize: '20px',
                            padding: '5px',
                            cursor: 'pointer',
                            alignSelf: 'end',
                          }}
                        >
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 448 512"
                            height="1em"
                            width="1em"


                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"></path>
                          </svg>
                        </span>
                        <div
                          // to={`/admin/offline-order/bag/${data._id}/${orderId}`}
                          // to={`/admin/offline-order/bag/add-item/${data._id}/${orderId}`}
                          // to={data.status != "active" ? `/admin/offline-order/bag/add-item-service/${data._id}/${orderId}` : "#"}

                          // to={`/admin/offline-order/bag/add-item-service/${data._id}/${orderId}`}

                          // to={
                          //   data?.item?.length
                          //     ? //  `/admin/offline-order/bag/${data._id}/${orderId}`
                          //       `/admin/offline-order/bag-item/${data._id}/${orderId}`
                          //     : `/admin/offline-order/bag/add-item-service/${data._id}/${orderId}`
                          // }
                          style={{
                            textDecoration: 'none',
                            color: 'black',
                          }}
                        >
                          <div className=" text-center">
                            <i class="fa-solid fa-bag-shopping" style={{ fontSize: '30px' }}></i>
                            <p style={{ margin: 0 }}>bag {index + 1}</p>
                            <p style={{ margin: 0 }}>
                              {data.item.length}:{' '}
                              <span
                                style={{
                                  fontWeight: 'bold',
                                }}
                              >
                                item
                              </span>
                            </p>
                            <p style={{ margin: 0 }}>
                              {!data.service
                                ? 'No service type available.'
                                : data.service.serviceName_EN}
                            </p>
                            {data.status == 'active' && (
                              <b style={{ fontSize: 'small' }}>Bag Completed</b>
                            )}
                          </div>
                        </div>
                      </div>
                    </CCol>
                  ))}
              </CRow>
            </div>
            <div>
              <CRow className="pagination-box d-flex  mt-5">
                <CCol style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <button
                    type="button"
                    onClick={() => bagActions('add')}
                    className="p-2"
                    style={{ background: '#d3dc40', border: '#d3dc40' }}
                  >
                    Add Bag
                  </button>
                  {/* {bags?.length
                    ? bags?.length == 1
                      ? `Total Bag: ${bags?.length}`
                      : `Total Bags: ${bags?.length}`
                    : ''} */}
                  {bags?.length ? (
                    <button
                      type="button"
                      onClick={confirmOrder}
                      className="p-2"
                      style={{ background: '#d3dc40', border: '#d3dc40' }}
                    >
                      Confirm Order
                    </button>
                  ) : (
                    <div></div>
                  )}
                </CCol>
                {/* <div className="d-flex justify-content-center align-items-center">
                  <button
                    type="button"
                    disabled={bags.length == 0 ? true : false}
                    onClick={() => bagActions('remove')}
                    className="btn-outline-dark"
                    style={{ width: '40px', backgroundColor: '#fff' }}
                  >
                    -
                  </button>
                  <div style={{ width: '100px', textAlign: 'center' }}>{bags.length}</div>
                  <button
                    type="button"
                    onClick={() => bagActions('add')}
                    className="btn-outline-dark"
                    style={{ width: '40px', backgroundColor: '#fff' }}
                  >
                    +
                  </button>
                </div> */}

                {/* <CCol>
                  {bags?.length ? (
                    <button
                      type="button"
                      onClick={confirmOrder}
                      className="p-2"
                      style={{ background: '#d3dc40', border: '#d3dc40' }}
                    >
                      Confirm Order
                    </button>
                  ) : (
                    ''
                  )}
                </CCol> */}
              </CRow>
            </div>
          </CCardBody>
        </CCardGroup>
      </CCard>
    </CContainer>
  )
}
export default Bags
