import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { post, get, deleteApi } from '../../utils/apiManager'
import CIcon from '@coreui/icons-react'
import { cilPlus } from '@coreui/icons'
import {
  CCard, CCardBody, CCardGroup, CCol, CContainer, CHeader, CCardSubtitle, CTable, CRow, CImage, CTableHead, CTableRow, CTableHeaderCell, CTableBody, CTableDataCell, CButton,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import { DefaultInputWithIcon } from 'src/components/common/input'
import Pagination from 'react-js-pagination'
import { FaEdit, FaTrash } from 'react-icons/fa';
import ReactStars from "react-rating-stars-component";
import Notgood from 'src/assets/images/Notgood.png'
import Average from 'src/assets/images/Average.png'
import Excellent from 'src/assets/images/Excellent.png'

function index() {
  const navigate = useNavigate();
  const [rateAndReviewData, setRateAndReviewData] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [count, setCount] = useState()
  const [pageData, setpageData] = useState({
    page: 1,
    perPage: 10,
    searchItem: "",
  });

  useEffect(() => {
    getData();
  }, [pageData]);

  const onSearchCLick = () => {
    setRateAndReviewData([])
    setpageData({
      ...pageData,
      searchItem: searchItem,
    });
  };

  const getData = () => {
    get(
      `/admin/rate-n-review/rate-review?page=${pageData?.page || 1}&perPage=${pageData?.perPage || 10
      }&searchItem=${pageData?.searchItem || ""}`
    ).then((data) => handleResponse(data));
  };
  const handleResponse = (data) => {
    if (!data?.status) toast.success(data?.message, data?.status);
    else {
      setRateAndReviewData(data?.data[0]?.data);
      setTotalPages(data?.data[0]?.metadata[0]?.page);
      setCount(data?.data[0]?.metadata[0]?.total)
    }
  };

  const changePage = (index) => {
    setpageData({
      ...pageData,
      page: index,
    });
  };

  const deleteData = (id) => {
    if (confirm("Are you sure you want to delete")) {
      deleteApi(`/admin/rate-n-review/delete?id=${id}`).then((data) => {
        handleDelete(data);
      });
    }
  };

  const handleDelete = (data) => {
    // console.log(data)
    if (data?.status == true) {
      toast.success(data?.message)
    } else {
      toast.error(data?.message)
    }
    // toast.success(data.message, data.status);
    getData();
  };


  return (
    <>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <CCardSubtitle as="h4">Rate And Review</CCardSubtitle>
                  <CRow>
                    <CCol md="8">
                      <DefaultInputWithIcon
                        type={'text'}
                        placeholder="search"
                        customType={'search'}
                        value={searchItem}
                        onClick={onSearchCLick}
                        onChange={(e) => {
                          // onSearchCLick(e?.target?.value)
                          setSearchItem(e?.target?.value)
                        }}
                      />

                    </CCol>
                    {/* <CCol >
                      <Link to={{ pathname: '/admin/not-deliver-reason/add' }} className="btn btn-dark">
                        <CIcon icon={cilPlus} />
                        Add
                      </Link>
                    </CCol> */}
                  </CRow>
                </CHeader>

                <CCardBody>
                  <CTable bordered hover responsive>

                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell>S. No</CTableHeaderCell>
                        <CTableHeaderCell>User Name</CTableHeaderCell>
                        <CTableHeaderCell>Order ID</CTableHeaderCell>
                        {/* <CTableHeaderCell>Ratings</CTableHeaderCell> */}
                        <CTableHeaderCell>Ratings</CTableHeaderCell>
                        <CTableHeaderCell>Review</CTableHeaderCell>
                        <CTableHeaderCell>Action</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {rateAndReviewData?.map((ele, index) => {
                        return (
                          <CTableRow key={index}>
                            <CTableDataCell>{index + 1}</CTableDataCell>
                            <CTableDataCell>{ele?.user_id[0]?.username}</CTableDataCell>
                            <CTableDataCell>{ele?.order_id}</CTableDataCell>
                            {/* <CTableDataCell>{ele?.rate != undefined ? <ReactStars
                              count={5}
                              value={ele?.rate}
                              size={24}
                              edit={false}
                              fullIcon={<i className="fa fa-star"></i>}
                              activeColor="#ffd700"
                            /> : '-'}</CTableDataCell> */}
                            <CTableDataCell>
                              {ele?.rate == 1 ?
                                <CImage
                                  src={Notgood}
                                  className="mx-2"
                                  style={{
                                    maxHeight: '80px',
                                  }}
                                  alt='IMG'
                                />
                                : ele?.rate == 2 ?
                                  <CImage
                                    src={Average}
                                    className="mx-2"
                                    style={{
                                      maxHeight: '80px',
                                    }}
                                    alt='IMG'
                                  />
                                  : ele?.rate == 3 ?
                                    <CImage
                                      src={Excellent}
                                      className="mx-2"
                                      style={{
                                        maxHeight: '80px',
                                      }}
                                      alt='IMG'
                                    />
                                    : ''}
                            </CTableDataCell>
                            <CTableDataCell>{ele?.review}</CTableDataCell>
                            <CTableDataCell>
                              <span className="mx-2 text-danger" onClick={() => deleteData(ele?._id)} style={{ fontSize: '20px', padding: '5px', cursor: 'pointer' }}><FaTrash /></span>
                            </CTableDataCell>
                          </CTableRow>
                        );
                      })}
                    </CTableBody>
                  </CTable>
                  {!rateAndReviewData?.length && (
                    <>
                      <h1>No Data</h1>
                    </>
                  )}
                  {rateAndReviewData?.length ? <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={pageData?.page}
                    itemsCountPerPage={pageData?.perPage}
                    totalItemsCount={count}
                    pageRangeDisplayed={5}
                    onChange={changePage}
                  /> : ''}
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default index