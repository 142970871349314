import React, { useEffect, useState } from 'react'
// import { ErrorText } from './commonError';
import { ErrorText } from './commonError';
import PhoneInput from "react-phone-number-input";
import 'react-phone-number-input/style.css'
import '../../style.css'
// import FileSizeValidation from 'src/utils/FileSizeValidation';

export const FileSizeValidation = (props) => {

}

export const DefaultInput = (props) => {
  // console.log("props", props?.file)
  // console.log("props", props?.type)
  const [errorMsg, setErrorMsg] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    validateSelectedFile()
  }, [props.onChange])
  const validateSelectedFile = () => {
    const MIN_FILE_SIZE = props?.MIN_FILE_SIZE // 1MB
    const MAX_FILE_SIZE = props?.MAX_FILE_SIZE // 5MB

    const fileSizeKiloBytes = props?.file?.size / MIN_FILE_SIZE

    if (fileSizeKiloBytes < MIN_FILE_SIZE) {
      setErrorMsg("File size is less than minimum limit");
      setIsSuccess(false)
      return
    }
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      setErrorMsg("File size is greater than maximum limit");
      setIsSuccess(false)
      return
    }

    setErrorMsg("")
    setIsSuccess(true)
  };
  return (
    <>
      <div>
        <div style={{ display: 'flex', position: 'relative' }}>
          {/* <label className="mb-2" htmlFor={props.id}>{props.name}</label> */}
          {/* <span className='text-danger' style={{ marginLeft: '5px' }}>*</span> */}
          <input
            type={props.type}
            title={props?.type == 'file' && "1024 X 1024"}
            // className="form-control default-input-class"
            className={props?.type == 'email' && props.error ?
              "form-control default-input-class alert alert-danger mb-0"
              : "form-control default-input-class"}
            placeholder={props.placeholder}
            name={props.name}
            id={props.id}
            ref={props.refr}
            value={props.value}
            defaultValue={props.defaultValue}
            onBlur={props.onBlur}
            onChange={props.onChange}
            required={props.required}
            readOnly={props.readOnly}
            disabled={props.disabled}
            maxLength={props.maxLength}
            multiple={props.multiple}
            accept={props.accept}
            onKeyUp={props.onKeyUp}
          />
          {props?.type == 'email' && props.error ?
            <svg style={{ position: 'absolute', right: '0', top: '10px' }} xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
              <path className="alert alert-danger" d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
            </svg> : null}
        </div>
        {props.error ? <>
          <ErrorText title={props.error} />
        </>
          : null}
        {props.file && !errorMsg ? <p className="text-success mt-2">Validation successful</p> : null}
        {errorMsg && <p className="text-danger mt-2">{errorMsg}</p>}
      </div>
    </>
  );
};
export const DefaultMobileInput = (props) => {
  return (
    <>
      <div>
        <label className='mb-2' htmlFor={props.id}>{props.name}</label>
        <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
        <PhoneInput
          style={{ display: "flex" }}
          className={"form-control"}
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
          // international={false}
          withCountryCallingCode={true}
          // defaultCountry={props.defaultCountry}
          addInternationalOption={false}
          maxLength={props.maxLength}
          smartCaret={props.smartCaret}
          defaultCountry={"TH"}
          onKeyUp={props.onKeyUp}
        />
        {/* {console.log(props.defaultCountry)} */}
        {props.error ? <ErrorText title={props.error} /> : null}
      </div>
    </>
  );
};
export const DefaultInputWithIcon = (props) => {
  return (
    <>
      <div>
        <div className="input-group mb-3">
          <input
            type={props.type}
            className="form-control"
            placeholder={props.placeholder}
            name={props.name}
            value={props.value}
            onBlur={props.onBlur}
            onChange={props.onChange}
            required={props.required}
            readOnly={props.readOnly}
            disabled={props.disabled}
            maxLength={props.maxLength}
          />
          <button
            className="btn btn-outline-secondary"
            type="button"
            onClick={props.onClick}
          >
            {props.customType == "search" ? (
              <i className="fa-solid fa-magnifying-glass"></i>
              // <span >search</span>
            ) : null}
          </button>
        </div>

        {props.error ? <ErrorText title={props.error} /> : null}
      </div>
    </>
  );
};
export const DefaultTextarea = (props) => {
  return (
    <>
      <div>
        <label className='mb-2' htmlFor={props.id}>{props.name}</label>
        <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
        <textarea
          type={props.type}
          className="form-control default-input-class"
          placeholder={props.placeholder}
          name={props.name}
          id={props.id}
          ref={props.refr}
          value={props.value}
          onBlur={props.onBlur}
          onChange={props.onChange}
          required={props.required}
          readOnly={props.readOnly}
          disabled={props.disabled}
          maxLength={props.maxLength}
          onKeyUp={props.onKeyUp}
        ></textarea>

        {props.error ? <ErrorText title={props.error} /> : null}
      </div>
    </>
  );
};
