import React,{useState,useEffect} from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { get } from 'src/utils/apiManager';
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CRow,
  CImage,
} from '@coreui/react'
import { ImageUrl } from 'src/config/config'
import { FaArrowLeft } from 'react-icons/fa'
import moment from 'moment';

function view() {
  const { id } = useParams()
  const [addOnServiceData, setAddOnServiceData] = useState([]);
  const [toDate, setToDate] = useState();
  const [fromDate, setFromDate] = useState();

  const getService= ()=>{

    get(`admin/add-on-service/service-view?_id=${atob(id)}`).then((data)=>{
      console.log(data)
      if(data?.data !== null ) {       
        const tempData= data?.data?.map((item,key)=>{

          // var to_date = moment(item?.to_date).utc().format('DD/MM/YYYY')
          const to_d = moment(item?.to_date).add(+1, 'days')
          const to_date = moment(to_d).utc().format('DD/MM/YYYY')

          const from_d = moment(item?.from_date).add(+1, 'days')
          const from_Date = moment(from_d).utc().format('DD/MM/YYYY')

          // const from_Date = moment(item?.from_date).utc().format('DD/MM/YYYY')

          setToDate(to_date);
          setFromDate(from_Date)
          setAddOnServiceData(item)
        })
      }
    })
  }
    useEffect(() => {
      getService();
    }, [])
  return (
    <>
    <CContainer fluid>
      <CRow>
        <CCol>
          <CCardGroup>
            <CCard className="p-4">
              <CHeader>
                <CRow>
                  <CCol >
                    {' '}
                    <Link style={{ color: '#000' }} to={{ pathname: `/admin/add-on-service` }}>
                      <FaArrowLeft />
                    </Link>
                    <span>  Add On Service Information</span>
                  </CCol>
                </CRow>
              </CHeader>
              <CCardBody>
                <CRow>
                  <CCol md={3}>
                    <strong>Add On Service Name (EN) :-</strong>
                  </CCol>
                  <CCol>
                    <span>{addOnServiceData?.serviceName_EN}</span>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md={3}>
                    <strong>Add On Service Name (TH) :-</strong>
                  </CCol>
                  <CCol>
                    <span>{addOnServiceData?.serviceName_TH}</span>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md={3}>
                    <strong>Price :-</strong>
                  </CCol>
                  <CCol>
                    <span>{addOnServiceData?.price}</span>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md={3}>
                    <strong>Add On Service Quantity :-</strong>
                  </CCol>
                  <CCol>
                    <span>{addOnServiceData?.addOnServiceQuantity}</span>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md={3}>
                    <strong>From Date :-</strong>
                  </CCol>
                  <CCol>
                    <span>{fromDate}</span>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md={3}>
                    <strong>To Date :-</strong>
                  </CCol>
                  <CCol>
                    <span>{toDate}</span>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md={3}>
                    <strong>Description (EN) :-</strong>
                  </CCol>
                  <CCol>
                    <span dangerouslySetInnerHTML={{ __html: addOnServiceData?.description_EN }}></span>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md={3}>
                    <strong>Description (TH) :-</strong>
                  </CCol>
                  <CCol>
                    <span dangerouslySetInnerHTML={{ __html: addOnServiceData?.description_TH }}></span>
                  </CCol>
                </CRow>
                <CRow>
                <CCol md={3}>
                    <strong>Image:-</strong>
                  </CCol>
                  <CCol>
                  <div
                    style={{
                      marginTop: '10px',
                      justifyContent: 'center',
                    }}
                  >
                   <a target='_blank' href={`${ImageUrl}${addOnServiceData?.image}`}>
                    <CImage
                      rounded
                      src={`${ImageUrl}${addOnServiceData?.image}`}
                      style={{
                        maxHeight: '100px',
                        maxWidth: '100px',
                        borderRadius: 50,
                        alignItems: 'center',
                      }}
                    />
                    </a>
                  </div>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </>
  )
}

export default view