import React, { useRef, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ImageUrl } from 'src/config/config'
import { post, get } from 'src/utils/apiManager'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CImage,
  CFormLabel,
  CAvatar,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilBurger, cilEnvelopeClosed, cilLockLocked, cilUser } from '@coreui/icons'
import { useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { Formik } from 'formik'
import { DefaultMobileInput, DefaultInput } from 'src/components/common/input'
import { validateProfile } from 'src/utils/validator'
import ImagePreview from 'src/components/ImagePreview'



export default function Profile() {
  const navigate = useNavigate()
  const [image, setImage] = useState()
  const formRef = useRef(null)
  const fileRef = useRef(null)
  const [isDisable, setIsDisable] = useState(false);
  const [invalidImage, setInvalidImage] = useState('')
  const [userName, setUserName] = useState('')
  const [mobile, setMobile] = useState('')
  const [profileImagePreview, setProfileImagePreview] = useState();
  // const [userData,setUserData] = useState([]);

  const hiddenFileInput = React.useRef(null);

  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  useEffect(() => {
    getProfile()
  }, [])

  const getProfile = () => {
    get(`admin/get-users`).then(
      (data) => handleSingleData(data),
      // console.log(data)
    )
  }
  const handleSingleData = (data) => {
    // console.log(data?.data)
    if (!data?.status) return toast.success(data?.status)
    setUserName(data?.data?.username)
    setMobile(data?.data?.mobile)
    formRef.current.setFieldValue('Name', data?.data?.username)
    formRef.current.setFieldValue('email', data?.data?.email)
    formRef.current.setFieldValue('mobile', data?.data?.mobile)
    if (data?.data?.image) setImage(`${data?.data?.image}`)
  }

  const submit = async (values) => {
    let formData = new FormData()
    for (var ele in values) {
      formData.append(ele, values[ele])
    }
    const Url = `admin/update-profile`
    post(Url, formData, 1).then(
      (data) => handleResponse(data),
    )
  }
  const handleResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      setTimeout(() => {
        getProfile();
        navigate('/admin/dashboard')
      }, 500)
    }
    formRef.current.resetForm()
    fileRef.current.value = null
  }

  const addDefaultSrc = (ev) => {
    ev.target.src = '/img/no-image.png'
  }

  return (
    <>

      <CContainer >
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <CRow>
                        <CCol md="11">

                          <h1>Update Profile</h1>
                        </CCol>
                        <CCol md="1">
                          <Link className={'btn btn-success mt-2'} style={{ background: '#d3dc40', border: '#d3dc40' }} to={{ pathname: `/admin/dashboard` }}>
                            Back
                          </Link>
                        </CCol>
                      </CRow>

                      <Formik
                        initialValues={{
                          Name: '',
                          email: '',
                          image: '',
                          mobile: '',
                        }}
                        validate={validateProfile}
                        onSubmit={(values, { setSubmitting }) => {
                          submit(values)
                          setSubmitting(false)
                        }}
                        innerRef={formRef}
                      >
                        {(props) => (
                          <form onSubmit={props?.handleSubmit}>
                            <CRow xs={12}>
                              <CCol md={6} className="form-group">
                              <CFormLabel>Name</CFormLabel>
                              <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'User Name'}
                                  id={'Name'}
                                  maxLength={70}
                                  value={props?.values?.Name}
                                  onChange={props?.handleChange('Name')}
                                  onKeyUp={() => {
                                    if (userName == props?.values?.Name) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                  onBlur={() => props?.setFieldTouched('Name', true)}
                                  error={props?.touched?.Name && props?.errors?.Name}
                                />
                              </CCol>
                              <CCol md={6}>
                                <CFormLabel>Email</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'email'}
                                  placeholder={'Email'}
                                  id={'email'}
                                  disabled={true}
                                  value={props?.values?.email}
                                  onChange={props?.handleChange('email')}
                                  onBlur={() => props?.setFieldTouched('email', true)}
                                  error={props?.touched?.email && props?.errors?.email}
                                />
                              </CCol>
                              <CCol md={6}>
                                {/* <CFormLabel >Profile</CFormLabel> 
                             <CButton style={{backgroundColor: 'white'}}>
                              <div className="profileBox"> */}
                                {/* <ImagePreview file={props?.values?.image} imageValue={ImageUrl + `${image}`}/> */}
                                {/* <CIcon onClick={handleClick} color="primary">edit</CIcon>
                              </div>    
                            </CButton> */}
                                {/* <input
                      size="small"
                      name="image"
                      type="file"
                      variant="outlined"
                      ref={hiddenFileInput}
                      accept="image/*"
                      id="image"
                      hidden
                      onBlur={props.handleBlur}
                      onChange={(event) => {
                        props?.setFieldValue('image', event.target.files[0])
                      }}
                      sx={{ mb: 1.5 }}
                    /> */}
                                <CFormLabel>Image</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'file'}
                                  placeholder={'Image'}
                                  accept="image/*"
                                  // value={props?.values?.image}
                                  refr={fileRef}
                                  onChange={(e) => {
                                    const imageFile = e?.target?.files[0];
                                    if (image == imageFile.name) setIsDisable(false)
                                    else setIsDisable(true)
                                    if (!imageFile.name.match(/\.(jpg|jpeg|png|gif)$/)) {
                                      setInvalidImage("Please select valid image")
                                    }
                                    else {
                                      setInvalidImage('')
                                    }
                                    //for image preview
                                    const reader = new FileReader();
                                    reader?.addEventListener("load", () => setProfileImagePreview(reader?.result));
                                    reader?.readAsDataURL(e?.target?.files[0]);
                                    //for image preview
                                    props?.setFieldValue('image', e?.target?.files[0])
                                  }}
                                  onBlur={() => props?.setFieldTouched('image', true)}
                                  error={(props?.touched?.image && props?.errors?.image) || invalidImage}
                                />
                                {/* for image preview */}
                                {profileImagePreview?.length > 0 ?
                                  <>
                                    <CCol>
                                      <CImage
                                        rounded
                                        thumbnail
                                        className="mt-2"
                                        src={`${profileImagePreview}`}
                                        style={{
                                          maxHeight: '80px',
                                          maxWidth: '80px',
                                          alignItems: 'center',
                                        }}
                                      />
                                    </CCol>
                                  </>
                                  : ''}
                                {/* for image preview */}
                              </CCol>
                              <CCol md={6}>
                                <DefaultMobileInput
                                  type={'text'}
                                  placeholder={'Mobile'}
                                  name={'Mobile'}
                                  id={'mobile'}
                                  minLength={7}
                                  maxLength={15}
                                  value={props?.values?.mobile}
                                  onChange={(val) => props?.setFieldValue('mobile', val || '')}
                                  onBlur={() => props?.setFieldTouched('mobile', true)}
                                  error={props?.touched?.mobile && props?.errors?.mobile}
                                  onKeyUp={() => {
                                    if (mobile == props?.values?.mobile) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                              </CCol>
                            </CRow>
                            <CRow className='mt-2'>
                              {/* <CAvatar src={ImageUrl+image} size="lg" style={{width:'100px'}} /> */}
                              <div>
                                {image && (
                                  <CImage
                                    rounded
                                    thumbnail
                                    // src={`${imageUrl}`}
                                    src={ImageUrl + image}
                                    // onError={addDefaultSrc}
                                    // roundedCircle
                                    style={{
                                      maxHeight: '80px',
                                      maxWidth: '80px',
                                      alignItems: 'center',
                                    }}
                                  />
                                )}
                              </div>
                              {/* <CCol md="3"></CCol> */}


                            </CRow>
                            <CRow className='mt-4'>

                              <CCol md="2">
                                <button type="submit" className={'btn btn-success mt-2'} disabled={!isDisable || props?.isSubmitting}>Submit</button>
                              </CCol>
                              <CCol md="2">
                                <button
                                  type="button"
                                  className={"btn btn-warning mt-2"}
                                  onClick={() => navigate('/admin/dashboard')}
                                >
                                  Cancel
                                </button>
                              </CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>

                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>

    </>
  )
}
