import React,{useRef,useEffect,useState} from 'react';
import { Formik } from 'formik';
import { useLocation,useNavigate,Link } from 'react-router-dom'
import { put, get } from 'src/utils/apiManager';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import {
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CFormLabel,
    CRow,
  } from '@coreui/react'
import { DefaultInput } from 'src/components/common/input'
import { validateCity } from 'src/utils/validator'
import Select from 'react-select'

const EditCity = () => {    const navigate = useNavigate()
    const { state } = useLocation()
    const formRef = useRef(null)
    const [isDisable, setIsDisable] = useState(false);
    const [stateOptions, setStateOptions] = useState([])
    const [stateList, setStateList] = useState(null)

    const fetchStateList = () => {
        get(`/admin/city/getAllState`).then((res) => {
            if(res?.data !== null) {
                const tempData = res.data.map((item, key) => {
                    if (state.state_id?._id == item._id) {
                        stateOptions.push({ label: item.stateName_EN , value: item?._id})  
                    }
                    return { label: item.stateName_EN , value: item?._id}
                })
                setStateList(tempData)
            }
        })
    };

    const submit = async (values) => {
        let CityData = {
            state_id: values?.state_id,
            cityName_EN: values?.cityName_EN,
            cityName_TH: values?.cityName_TH
        }
        put(`admin/city/update?_id=${state?._id}`, CityData).then((res) => {
            handleResponse(res)
        })
    };
    
    const handleResponse = (res) => {
        if (res.status == true) {
            toast.success(res.message)
            setTimeout(() => {
                navigate('/admin/city')
            }, 800)
        } else {
            toast.error(res.message)
        }
        formRef.current.resetForm()
    };

    useEffect(() => {
        fetchStateList();
        if (state != null) {
            formRef.current.setFieldValue('state_id', state?.state_id?.stateName_EN)
            formRef.current.setFieldValue('cityName_EN', state?.cityName_EN)
            formRef.current.setFieldValue('cityName_TH', state?.cityName_TH)
          }
    }, [state]);

  return (
    <>
      <CContainer >
            <CRow>
            <CCol>
                <CCardGroup>
                <CCard className="p-4">
                    <CCol xs={12}>
                    <CCardGroup>
                        <CCardBody>
                        <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                        <CRow>
                          <CCol md="11">
                            <h1>Edit City</h1>
                          </CCol>                    
                          <CCol md="1">
                            <Link className={'btn btn-success mt-2'} style={{background:'#d3dc40',border: '#d3dc40' }} to={{ pathname: `/admin/city` }}>
                                Back
                            </Link>
                          </CCol>
                        </CRow>
                        <Formik
                        initialValues={{
                            state_id: '',
                            cityName_EN: '',
                            cityName_TH: '',
                        }}
                        validationSchema={validateCity}
                        onSubmit={(values, { setSubmitting }) => {
                            submit(values)
                            setSubmitting(false)
                            
                        }}
                        innerRef={formRef}
                        >
                        {(props) => (
                            <form onSubmit={props.handleSubmit}>
                            <CFormLabel>State Name</CFormLabel>
                            <span className='text-danger' style={{marginLeft:'5px'}}>*</span>
                            <Select
                                defaultValue={stateOptions}
                                onChange={(val) => {setStateOptions(val)
                                    if(val == stateOptions) setIsDisable(false)
                                    else setIsDisable((true))
                                props.setFieldValue("state_id", val.value)}}
                                options={stateList}
                                value={stateOptions}
                                placeholder={"Select State"} 
                                name={"State Name (EN)"}
                            />  
                            {stateOptions == undefined  ? <span className='text-danger'>{props.errors.state_id}</span> : '' }
                            <DefaultInput
                                type={"text"}
                                placeholder={"City Name (EN)"}
                                name={"City Name (EN)"}
                                // maxLength={30}
                                value={props.values.cityName_EN}
                                onChange={props.handleChange("cityName_EN")}
                                onKeyUp={()=>{
                                    if(state.cityName_EN == props.values.cityName_EN) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                onBlur={() => props.setFieldTouched("cityName_EN", true)}
                                error={props.touched.cityName_EN && props.errors.cityName_EN}
                            />

                            <DefaultInput
                                type={"text"}
                                placeholder={"City Name (TH)"}
                                name={"City Name (TH)"}
                                // maxLength={30}
                                value={props.values.cityName_TH}
                                onChange={props.handleChange("cityName_TH")}
                                onKeyUp={()=>{
                                    if(state.cityName_TH == props.values.cityName_TH) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                onBlur={() => props.setFieldTouched("cityName_TH", true)}
                                error={props.touched.cityName_TH && props.errors.cityName_TH}
                            />

                            <CRow>
                                <CCol md="2">
                                <button type="submit"className={'btn btn-success mt-2'} disabled={!isDisable || props.isSubmitting}>Submit</button>
                                </CCol>
                                <CCol md="2">
                                <button
                                    type="button"
                                    className={'btn btn-warning mt-2'}
                                    onClick={() => navigate('/admin/city')}
                                >
                                    Cancel
                                </button>
                                </CCol>
                                <CCol md="3"></CCol>
                            </CRow>
                            </form>
                        )}
                        </Formik>
                        </CCardBody>
                    </CCardGroup>
                    </CCol>               
                </CCard>
                </CCardGroup>
            </CCol>
            </CRow>
        </CContainer>
    </>
  )
}

export default EditCity;