import React, { useState, useRef, useEffect } from 'react'
import { Formik } from 'formik'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { baseURL, ImageUrl } from 'src/config/config'
import { put } from 'src/utils/apiManager'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CImage,
  CFormLabel,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilImage, cilLockLocked, cilPhone, cilUser } from '@coreui/icons'
import { DefaultInput } from 'src/components/common/input'
import { editTemplate } from 'src/utils/validator'
import { Ckeditor } from './Ckeditor'
import { FormLabel } from '@mui/material'
import { ErrorDesc } from 'src/components/common/commonError'

function EditDescription() {
  const navigate = useNavigate()
  const { state } = useLocation()
   console.log("state", state)
  const formRef = useRef(null)
  const fileRef = useRef(null)
  const [description_en, setDescription_en] = useState('')
  const [description_th, setDescription_th] = useState('')
   const [isDisable, setIsDisable] = useState(false)

  useEffect(() => {
    if (state != null) {
      formRef.current.setFieldValue('title_en', state?.title_en)
      formRef.current.setFieldValue('title_th', state?.title_th)
      formRef.current.setFieldValue('description_en', state?.description_en)
      formRef.current.setFieldValue('description_th', state?.description_th)
    }
  }, [state])

  const submit = async (values) => {
    //  console.log("my values",values)
    const Url = `/admin/safe-checking-message/${state?._id}`
    let data = {
      id: state?._id,
      description_en: values?.description_en,
      description_th: values?.description_th,
      title_en: values?.title_en,
      title_th: values?.title_th
    }
    put(Url, data).then(data => {
      handleResponse(data)
      // console.log(data)
    })
  }
  const handleResponse = (data) => {
    // console.log(data)
    if (data?.status == true) {
      toast.success(data?.message)
      setTimeout(() => {
        navigate('/admin/safeDescription')
      }, 1000)
    }
    // ShowToast(data?.message, data?.status);
    formRef.current.resetForm()
    // fileRef.current.value = null
  }

  return (
    <>
      <CContainer >
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <CRow>
                        <CCol md="11">
                          <h1>Edit Description</h1>
                        </CCol>
                        <CCol md="1">
                          <Link className={'btn btn-success mt-2'} style={{ background: '#d3dc40', border: '#d3dc40' }} to={{ pathname: `/admin/safeDescription` }}>
                            Back
                          </Link>
                        </CCol>
                      </CRow>
                      <Formik
                        innerRef={formRef}
                        initialValues={{
                          title_en: '', title_th: '', description_en: '', description_th: ''
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                          submit(values)
                          setSubmitting(false)
                        }}
                      >
                        {(props) => (
                          <form onSubmit={props?.handleSubmit}>
                            <CFormLabel>Title EN</CFormLabel>
                            <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                            <DefaultInput
                              type={'text'}
                              placeholder={'Title'}
                              id={'title_en'}
                              value={props?.values?.title_en}
                              onChange={props?.handleChange('title_en')}
                              onBlur={() => props?.setFieldTouched('title_en', true)}
                              error={props?.touched?.title_en && props?.errors?.title_en}
                            />
                            <CFormLabel>Title TH</CFormLabel>
                            <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                            <DefaultInput
                              type={'text'}
                              placeholder={'Title'}
                              id={'title_th'}
                              value={props?.values?.title_th}
                              onChange={props?.handleChange('title_th')}
                              onBlur={() => props?.setFieldTouched('title_th', true)}
                              error={props?.touched?.title_th && props?.errors?.title_th}
                            />
                            <FormLabel> Description (EN)</FormLabel>
                            <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                            <Ckeditor
                              setDescription={setDescription_en}
                              description={description_en}
                              value={props?.values?.description_en}
                              onChange={(val) => {
                                setDescription_en(val)
                                // console.log(val,"DESCRIPT");
                                if (state?.description == val) setIsDisable(false)
                                else setIsDisable(true)
                                props?.setFieldValue('description_en', val)
                              }}
                              onBlur={() => props?.setFieldTouched('description_en', true)}
                              error={props?.touched?.description_en && props?.errors?.description_en}
                            />

                            <FormLabel> Description (TH)</FormLabel>
                            <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                            <Ckeditor
                              setDescription={setDescription_th}
                              description={description_th}
                              value={props?.values?.description_th}
                              onChange={(val) => {
                                setDescription_th(val)
                                // console.log(val,"DESCRIPT");
                                if (state?.description == val) setIsDisable(false)
                                else setIsDisable(true)
                                props?.setFieldValue('description_th', val)
                              }}
                              onBlur={() => props?.setFieldTouched('description_th', true)}
                              error={props?.touched?.description_th && props?.errors?.description_th}
                            />
                            <CRow>
                              <CCol md="2">
                                <button
                                  type="submit"
                                  className={'btn btn-success mt-2'}
                                  disabled={!isDisable || props?.isSubmitting}
                                >
                                  Submit
                                </button>
                              </CCol>
                              <CCol md="2">
                                <button
                                  type="button"
                                  className={'btn btn-warning mt-2'}
                                  onClick={() => navigate('/admin/safeDescription')}
                                >
                                  Cancel
                                </button>
                              </CCol>
                              <CCol md="3"></CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default EditDescription
