import { CButton, CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter } from '@coreui/react'
const ImagePreview = ({ visible, setVisible, image }) => {
    return (
        <>

            <CModal
                visible={visible}
                alignment="center"
                onClose={() => setVisible(false)}
                aria-labelledby="LiveDemoExampleLabel"
            >
                <CModalHeader onClose={() => setVisible(false)}>
                    <CModalTitle id="LiveDemoExampleLabel">Image Preview</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <img
                        src={image}
                        style={{ cursor: "pointer", width: '100%', height: '100%    ' }}
                        onClick={() => setVisible(!visible)}

                    />
                </CModalBody>

            </CModal>
        </>


    )
}

export default ImagePreview;