import React, { useRef, useState, useEffect } from 'react'
import { Formik } from 'formik'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import { get, put } from 'src/utils/apiManager'
import { ImageUrl } from 'src/config/config'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Select from 'react-select'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CFormLabel,
  CRow,
  CImage,
} from '@coreui/react'
import { DefaultInput } from 'src/components/common/input'
import { validateEditItem } from 'src/utils/validator'

function edit() {
  const navigate = useNavigate()
  const { state } = useLocation()
  const formRef = useRef(null)
  const fileRef = useRef(null)
  const [isDisable, setIsDisable] = useState(false)
  const [invalidImage, setInvalidImage] = useState('')
  const [isReset, setIsReset] = useState(true)
  const [selecteSizeOption, setSelecteSizeOption] = useState([])
  const [itemSizeData, setItemSizeData] = useState(null)
  const [imageUrl, setimageUrl] = useState()
  const [selectedItemCategoryOption, setSelectedItemCategoryOption] = useState([])
  const [itemCategoryData, setItemCategoryData] = useState(null)
  const [itemImagePreview, setItemImagePreview] = useState();

  const getItemSize = () => {
    get(`admin/items/item-size`).then((data) => {
      if (data?.data !== null) {
        const tempData = data?.data?.map((item, key) => {
          console.log(state?.itemSize)
          var ExistSizeId = Object.values(state?.itemSize)
          if (ExistSizeId?.find((element) => element == item?._id)) {
            selecteSizeOption?.push({ label: item?.itemSize_EN, value: item?._id })
          }
          return { label: item?.itemSize_EN, value: item?._id }
        })
        setItemSizeData(tempData)
      }
    })
  }

  const getItemCategory = () => {
    get(`admin/items/item-category`).then((data) => {
      if (data?.data !== null) {
        const tempData = data?.data?.map((item, key) => {
          if (state?.itemCategory == item?._id) {
            selectedItemCategoryOption.push({ label: item?.itemName_EN, value: item?._id })
          }
          return { label: item?.itemName_EN, value: item?._id }
        })
        setItemCategoryData(tempData)
      }
    })
  }

  const submit = async (values) => {
    // if (values?.itemSize.length > 0) {
    if (values?.price <= 0 || values?.itemSize?.length == 0) {
      if (values?.price <= 0) {
        formRef.current.setFieldValue('price', '')
      } else {
        toast.error("Item size required")
      }
    } else {
      if (invalidImage?.length === 0) {
        let existValue = selecteSizeOption.map((item, key) => {
          return item?.value
        })
        const SIZE = []
        if (values?.itemSize != undefined && values?.itemSize?.length != 0) {
          values?.itemSize.forEach((item, key) => {
            SIZE.push(item?.value)
          })
        }
        let formData = new FormData()
        formData.append('itemName_EN', values?.itemName_EN)
        formData.append('itemName_TH', values?.itemName_TH)
        formData.append('price', values?.price)
        formData.append('image', values?.image)
        formData.append('itemCategory', values?.itemCategory)
        formData.append('itemSize', values?.itemSize == undefined || values?.itemSize?.length == 0 ? state?.itemSize : SIZE)
        formData.append('itemQuota', values?.itemQuota)
        formData.append('offer', values?.offer)
        put(`admin/items/update?_id=${state?._id}`, formData, 1).then((data) => handleResponse(data))
      } else {
        return
      }
    }
    // } else {
    //   toast.error("Fill the required fields")
    // }

  }
  const handleResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      setTimeout(() => {
        navigate('/admin/items')
      }, 800)
    } else {
      toast.error(data?.message)
    }
    formRef.current.resetForm()
    fileRef.current.value = null
    // setSelecteSizeOption('')
  }
  const onClickReset = () => {
    // Reset Button Function
    // formRef.current.setFieldValue('vanNo', '')
  }
  useEffect(() => {
    getItemSize()
    getItemCategory()
    if (state != null) {
      formRef.current.setFieldValue('itemName_EN', state?.itemName_EN)
      formRef.current.setFieldValue('itemName_TH', state?.itemName_TH)
      formRef.current.setFieldValue('price', state?.price)
      formRef.current.setFieldValue('itemCategory', state?.itemCategory)
      formRef.current.setFieldValue('itemQuota', state?.itemQuota)
      formRef.current.setFieldValue('offer', state?.offer)
      if (state?.image) setimageUrl(`${ImageUrl}${state?.image}`)
    }
  }, [state])
  return (
    <>
      <CContainer>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <CRow>
                        <CCol md="11">
                          <h1>Edit Item</h1>
                        </CCol>
                        <CCol md="1">
                          <Link
                            className={'btn btn-success mt-2'}
                            style={{ background: '#d3dc40', border: '#d3dc40' }}
                            to={{ pathname: `/admin/items` }}
                          >
                            Back
                          </Link>
                        </CCol>
                      </CRow>
                      <Formik
                        initialValues={{
                          itemName_EN: '',
                          itemName_TH: '',
                          price: '',
                          itemQuota: '',
                          offer: '',
                        }}
                        validationSchema={validateEditItem}
                        onSubmit={(values, { setSubmitting }) => {
                          submit(values)
                          setSubmitting(false)
                        }}
                        innerRef={formRef}
                      >
                        {(props) => (
                          <form onSubmit={props?.handleSubmit}>
                            <CRow xs={12}>
                              <CCol md={6}>
                                <CFormLabel>Item Name (EN)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Item Name (EN)'}
                                  value={props?.values?.itemName_EN}
                                  onChange={props?.handleChange('itemName_EN')}
                                  onBlur={() => props?.setFieldTouched('itemName_EN', true)}
                                  error={props?.touched?.itemName_EN && props?.errors?.itemName_EN}
                                  onKeyUp={() => {
                                    if (state?.itemName_EN == props?.values?.itemName_EN)
                                      setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                                <CFormLabel>Price</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Price'}
                                  value={props?.values?.price}
                                  onChange={props?.handleChange('price')}
                                  onBlur={() => props?.setFieldTouched('price', true)}
                                  error={props?.touched?.price && props?.errors?.price}
                                  onKeyUp={() => {
                                    if (state?.price == props?.values?.price) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                                <CFormLabel>Item Quota</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Item Quota'}
                                  value={props?.values?.itemQuota}
                                  onChange={props?.handleChange('itemQuota')}
                                  onBlur={() => props?.setFieldTouched('itemQuota', true)}
                                  error={props?.touched?.itemQuota && props?.errors?.itemQuota}
                                  onKeyUp={() => {
                                    if (state?.itemQuota == props?.values?.itemQuota) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                                <br />
                                <CFormLabel>Image</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'file'}
                                  placeholder={'Image'}
                                  accept="image/*"
                                  refr={fileRef}
                                  onChange={(e) => {
                                    const imageFile = e?.target?.files[0]
                                    if (imageUrl == imageFile?.name) setIsDisable(false)
                                    else setIsDisable(true)
                                    if (!imageFile?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
                                      setInvalidImage('Please select valid image')
                                    } else {
                                      setInvalidImage('')
                                    }
                                    //for image preview
                                    const reader = new FileReader();
                                    reader?.addEventListener("load", () => setItemImagePreview(reader?.result));
                                    reader?.readAsDataURL(e?.target?.files[0]);
                                    //for image preview
                                    props?.setFieldValue('image', e?.target?.files[0])
                                  }}
                                  onBlur={() => props?.setFieldTouched('image', true)}
                                  error={
                                    (props?.touched?.image && props?.errors?.image) || invalidImage
                                  }
                                />
                                {/* for image preview */}
                                {itemImagePreview?.length > 0 ?
                                  <>
                                    <CCol>
                                      <CImage
                                        rounded
                                        thumbnail
                                        className="mt-2"
                                        src={`${itemImagePreview}`}
                                        style={{
                                          maxHeight: '80px',
                                          maxWidth: '80px',
                                          alignItems: 'center',
                                        }}
                                      />
                                    </CCol>
                                  </>
                                  : ''}
                                {/* for image preview */}
                                {imageUrl && (
                                  <CImage
                                    rounded
                                    thumbnail
                                    className="mt-2"
                                    src={`${imageUrl}`}
                                    style={{
                                      maxHeight: '80px',
                                      maxWidth: '80px',
                                      alignItems: 'center',
                                    }}
                                  />
                                )}
                              </CCol>
                              <CCol md="6">
                                <CFormLabel>Item Name (TH)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Item Name (TH)'}
                                  value={props?.values?.itemName_TH}
                                  onChange={props?.handleChange('itemName_TH')}
                                  onBlur={() => props?.setFieldTouched('itemName_TH', true)}
                                  error={props?.touched?.itemName_TH && props?.errors?.itemName_TH}
                                  onKeyUp={() => {
                                    if (state?.itemName_TH == props?.values?.itemName_TH)
                                      setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                                <CFormLabel>Item Size</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>
                                <Select
                                  isMulti={true}
                                  defaultValue={selecteSizeOption}
                                  onChange={(val) => {
                                    setSelecteSizeOption(val)
                                    if (val == selecteSizeOption) setIsDisable(false)
                                    else setIsDisable(true)
                                    props?.setFieldValue('itemSize', val)
                                  }}
                                  options={itemSizeData}
                                  value={selecteSizeOption}
                                  placeholder={'Select size'}
                                  name="Item Size"
                                />
                                {selecteSizeOption?.length == 0 ? (
                                  <span className="text-danger">Item size required</span>
                                ) : (
                                  ''
                                )}
                                <br />
                                <CFormLabel>Offer (In %)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Offer'}
                                  value={props?.values?.offer}
                                  onChange={props?.handleChange('offer')}
                                  onBlur={() => props?.setFieldTouched('offer', true)}
                                  error={props?.touched?.offer && props?.errors?.offer}
                                  onKeyUp={() => {
                                    if (state?.offer == props?.values?.offer)
                                      setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                                <br />
                                <CFormLabel>Item Category</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>
                                <Select
                                  defaultValue={selectedItemCategoryOption}
                                  onChange={(val) => {
                                    setSelectedItemCategoryOption(val)
                                    if (val == selectedItemCategoryOption) setIsDisable(false)
                                    else setIsDisable(true)
                                    props?.setFieldValue('itemCategory', val.value)
                                  }}
                                  options={itemCategoryData}
                                  value={selectedItemCategoryOption}
                                  placeholder={'Select item categories'}
                                  name="Item Size"
                                />
                                {selectedItemCategoryOption == undefined ? (
                                  <span className="text-danger">{props?.errors?.itemCategory}</span>
                                ) : (
                                  ''
                                )}
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol md="2">
                                <button
                                  type="submit"
                                  className={'btn btn-success mt-2'}
                                  disabled={!isDisable || props?.isSubmitting}
                                >
                                  Submit
                                </button>
                              </CCol>
                              <CCol md="2">
                                <button
                                  type="button"
                                  className={'btn btn-warning mt-2'}
                                  onClick={() => navigate('/admin/items')}
                                >
                                  Cancel
                                </button>
                              </CCol>
                              <CCol md="3"></CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default edit
