import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { post, get, deleteApi } from '../../utils/apiManager'
import CIcon from '@coreui/icons-react'
import { cilPlus } from '@coreui/icons'
import {
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CHeader,
    CCardSubtitle,
    CTable,
    CRow,
    CImage,
    CTableHead,
    CTableRow,
    CTableHeaderCell,
    CTableBody,
    CTableDataCell,
    CButton,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import { DefaultInputWithIcon } from 'src/components/common/input'
import Pagination from 'react-js-pagination'
import { FaRegEye, FaEdit, FaTrash } from 'react-icons/fa'
import ShowMoreText from 'react-show-more-text'
import { ImageUrl } from 'src/config/config'

function index() {
    const navigate = useNavigate();
    const [sliderData, setSliderData] = useState([]);
    const [deletedArray, setDeletedArray] = useState([])

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        get(
            `/admin/homeSlider/getHomeSlider`
        ).then((data) => handleResponse(data));
    };
    const handleResponse = (data) => {
        if (!data?.status) toast.success(data?.message, data?.status);
        else {
            // setAreaData([...data?.data]);
            setSliderData([...data?.data]);
        }
    };
    const statusChange = (status, id) => {
        let default_status = ''
        if (status == true) default_status = "Active"
        else default_status = "Inactive"
        if (confirm(`Are you sure you want to ${default_status} the home slider`)) {
            let formData = {
                _id: id,
                status: status == '1' ? 1 : 0
            };
            console.log('formData', formData)
            post('/admin/homeSlider/changeStatus', formData).then(
                (data) => {
                    handleStatusChange(data);
                }
            );
        }
    };
    const handleStatusChange = (data) => {
        if (data?.status) {
            toast.success(data?.message, data?.status);
            getData();
        }
        else {
            toast.error(data?.message);
        }

    };
    const onClickUserEdit = async (value) => {
        // console.log(value)
        navigate('/admin/appHomeSlider/add', { state: value })
    }
    const deleteData = (id) => {
        if (confirm("Are you sure")) {
            deleteApi(`/admin/homeSlider/delete?_id=${id}`).then((data) => {
                handleDelete(data);
            });
        }
    };
    const handleDelete = (data) => {
        toast.success(data?.message, data?.status);
        getData();
    };


    const handleCheck = (id) => {
        let findValue = deletedArray.includes(id)
        if (findValue) {
            let filterData = deletedArray?.filter((item) => item != id)
            setDeletedArray(filterData)
        } else {
            setDeletedArray((prev) => [...prev, id])
        }
    }

    const deleteOrders = () => {
        post(`admin/homeSlider/deleted-app-home-slider`, deletedArray).then((data) =>
            handleDeletedResponse(data),
        )
    }

    const handleDeletedResponse = (data) => {
        if (data?.status == true) {
            toast.success(data?.message)
            setDeletedArray([])
            getData()
        }
    }


    return (
        <>
            <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
            <CContainer fluid>
                <CRow>
                    <CCol>
                        <CCardGroup>
                            <CCard className="p-4">
                                <CHeader>
                                    <div className="d-flex" style={{ flexDirection: 'column' }}>

                                        <CCardSubtitle as="h4">Sliders </CCardSubtitle>
                                        {deletedArray.length > 0 && (
                                            <button className="btn btn-danger mt-2 text-white" onClick={deleteOrders}>
                                                Delete
                                            </button>
                                        )}
                                    </div>
                                    <CRow>
                                        <CCol>
                                            <Link to={{ pathname: '/admin/appHomeSlider/add' }} className="btn btn-dark">
                                                <CIcon icon={cilPlus} />
                                                Add
                                            </Link>
                                        </CCol>
                                    </CRow>
                                </CHeader>

                                <CCardBody>
                                    <CTable bordered hover responsive>
                                        <CTableHead>
                                            <CTableRow>
                                                <CTableHeaderCell>S. No</CTableHeaderCell>
                                                <CTableHeaderCell>English Image</CTableHeaderCell>
                                                <CTableHeaderCell>Thai Image</CTableHeaderCell>
                                                <CTableHeaderCell>Status</CTableHeaderCell>
                                                <CTableHeaderCell>Action</CTableHeaderCell>
                                            </CTableRow>
                                        </CTableHead>
                                        <CTableBody>
                                            {sliderData?.map((ele, index) => {
                                                return (
                                                    <CTableRow key={index} onClick={() => handleCheck(ele._id)} >
                                                        <CTableDataCell>{index + 1}
                                                            <div class="form-check">
                                                                <input
                                                                    class="form-check-input"
                                                                    style={{ marginLeft: '2px', marginTop: '-20px' }}
                                                                    type="checkbox"
                                                                    value=""
                                                                    id="flexCheckDefault"
                                                                    checked={deletedArray?.includes(ele._id)}
                                                                // onChange={() => handleCheck(ele._id)}
                                                                />
                                                            </div>
                                                        </CTableDataCell>
                                                        <CTableDataCell >
                                                            <a target='_blank' href={`${ImageUrl}${ele?.en_image}`}>
                                                                <CImage
                                                                    rounded
                                                                    src={`${ImageUrl}${ele?.en_image}`}
                                                                    style={{
                                                                        maxHeight: '50px',
                                                                        maxWidth: '50px',
                                                                        borderRadius: 50,
                                                                        alignItems: 'center',
                                                                    }}
                                                                />
                                                            </a>
                                                        </CTableDataCell>
                                                        <CTableDataCell >
                                                            <a target='_blank' href={`${ImageUrl}${ele?.th_image}`}>
                                                                <CImage
                                                                    rounded
                                                                    src={`${ImageUrl}${ele?.th_image}`}
                                                                    style={{
                                                                        maxHeight: '50px',
                                                                        maxWidth: '50px',
                                                                        borderRadius: 50,
                                                                        alignItems: 'center',
                                                                    }}
                                                                />
                                                            </a>
                                                        </CTableDataCell>
                                                        <CTableDataCell>
                                                            <CButton
                                                                size="sm"
                                                                outline={ele?.status ? "1" : "0"}
                                                                style={{ backgroundColor: ele?.status ? '#00796B' : '#D32F2F', borderColor: ele?.status ? '#00796B' : '#D32F2F' }}
                                                                onClick={() =>
                                                                    statusChange(!ele?.status, ele?._id)
                                                                }
                                                            >
                                                                {ele?.status ? "Active" : "Inactive"}
                                                            </CButton>
                                                        </CTableDataCell>
                                                        <CTableDataCell>
                                                            <span className="mx-2" onClick={() => onClickUserEdit(ele)} style={{ fontSize: '20px', color: '#d3dc40', cursor: 'pointer' }}><FaEdit /></span>
                                                            <span className="mx-2 text-danger" onClick={() => deleteData(ele?._id)} style={{ fontSize: '20px', padding: '5px', cursor: 'pointer' }}><FaTrash /></span>
                                                        </CTableDataCell>
                                                    </CTableRow>
                                                );
                                            })}
                                        </CTableBody>
                                    </CTable>
                                    {!sliderData?.length && (
                                        <>
                                            <h1>No Data</h1>
                                        </>
                                    )}
                                    {/* <Pagination
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        activePage={pageData?.page}
                                        itemsCountPerPage={pageData?.perPage}
                                        totalItemsCount={totalPages * 10}
                                        pageRangeDisplayed={5}
                                        onChange={changePage}
                                    /> */}
                                </CCardBody>
                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </>
    )
}

export default index