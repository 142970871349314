import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { post, get, deleteApi } from '../../utils/apiManager'
import CIcon from '@coreui/icons-react'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CCardSubtitle,
  CTable,
  CRow,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CButton,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import { DefaultInputWithIcon } from 'src/components/common/input'
import Pagination from 'react-js-pagination'
import { cilCloudDownload } from '@coreui/icons'
function index() {
  const navigate = useNavigate()
  const [paymentData, setPaymentData] = useState([])
  const [totalPages, settotalPages] = useState(0)
  const [searchItem, setsearchItem] = useState('')
  const [deletedArray, setDeletedArray] = useState([])
  const [pageData, setpageData] = useState({
    page: 1,
    perPage: 10,
    searchItem: '',
  })

  const getData = () => {
    get(
      `/admin/payment/list?page=${pageData?.page || 1}&perPage=${pageData?.perPage || 10
      }&searchItem=${pageData?.searchItem || ''}`,
    ).then((data) => handleResponse(data))
  }
  const handleResponse = (data) => {
    if (!data?.status) toast.success(data?.message, data?.status)
    else {
      setPaymentData([...data?.data])
      settotalPages(data?.pages)
    }
  }
  const changePage = (index) => {
    setpageData({
      ...pageData,
      page: index,
    })
  }
  const onSearchCLick = () => {
    setpageData({
      ...pageData,
      searchItem: searchItem,
    })
  }

  const downloadPaymentData = () => {
    const temp = {
      queryData: pageData,
      reportDownload: true,
    }
    post(`/admin/report/payment-csv-download`, temp).then((res) => {
      if (res?.status) {
        const linkurl = res?.url
        const link = document.createElement('a')
        link.download = linkurl
        link.href = linkurl
        link.click()
        toast.success('CSV Downloaded Successfully')
      }
    })
  }

  useEffect(() => {
    getData()
  }, [pageData])

  const handleCheck = (id) => {
    let findValue = deletedArray.includes(id)
    if (findValue) {
      let filterData = deletedArray?.filter((item) => item != id)
      setDeletedArray(filterData)
    } else {
      setDeletedArray((prev) => [...prev, id])
    }
  }

  const deleteOrders = () => {
    // post(`admin/placed-order/deleted-order`, deletedArray).then((data) =>
    //   handleDeletedResponse(data),
    // )
  }

  const handleDeletedResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      setDeletedArray([])
      //   get(
      //     `/admin/placed-order/order-placed-list?page=${pageData?.page || 1}&perPage=${
      //       pageData?.perPage || 10
      //     }&searchItem=${pageData?.searchItem || ''}&from_date=${searchDate ? searchDate : ''}`,
      //   ).then((res) => handleResponse(res))
    }
  }

  return (
    <>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  {/* <div className="d-flex" style={{ flexDirection: 'column' }}> */}
                  <CCardSubtitle as="h4">Payment</CCardSubtitle>
                  {/* {deletedArray.length > 0 && (
                      <button className="btn btn-danger mt-2 text-white" onClick={deleteOrders}>
                        Delete
                      </button>
                    )}
                  </div> */}
                  <CRow>
                    <CCol md="6">
                      <DefaultInputWithIcon
                        type={'text'}
                        placeholder="Search"
                        customType={'search'}
                        value={searchItem}
                        onClick={onSearchCLick}
                        onChange={(e) => {
                          onSearchCLick()
                          setsearchItem(e?.target?.value)
                        }}
                      />
                    </CCol>
                    <CCol md="5" align={'right'}>
                      <Link onClick={() => downloadPaymentData()} className="btn btn-dark">
                        <CIcon icon={cilCloudDownload} />
                        <span style={{ marginLeft: '2px' }}>Export CSV</span>
                      </Link>
                    </CCol>
                    {/* <CCol md="4">
                                            <Link to={{ pathname: '/admin/items/add' }} className="btn btn-dark">
                                                <CIcon icon={cilPlus} />
                                                Add
                                            </Link>
                                        </CCol> */}
                  </CRow>
                </CHeader>
                <CCardBody>
                  <CTable bordered hover responsive>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell>S. No</CTableHeaderCell>
                        <CTableHeaderCell>User</CTableHeaderCell>
                        <CTableHeaderCell>Transaction Id</CTableHeaderCell>
                        <CTableHeaderCell>Package</CTableHeaderCell>
                        <CTableHeaderCell>Amount</CTableHeaderCell>
                        <CTableHeaderCell>Order No</CTableHeaderCell>
                        <CTableHeaderCell>Type</CTableHeaderCell>
                        <CTableHeaderCell>Status</CTableHeaderCell>
                        {/* <CTableHeaderCell>Action</CTableHeaderCell> */}
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {paymentData?.map((ele, index) => {
                        // console.log(ele)
                        return (
                          <>
                            <CTableRow key={index}>
                              <CTableDataCell>
                                {pageData?.perPage * (pageData?.page - 1) + index + 1}
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    style={{ marginLeft: '2px', marginTop: '-20px' }}
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                    checked={deletedArray?.includes(ele._id)}
                                    onChange={() => handleCheck(ele._id)}
                                  />
                                </div>
                              </CTableDataCell>
                              <CTableDataCell>{ele?.customers?.username}</CTableDataCell>
                              <CTableDataCell>{ele?.transactionId}</CTableDataCell>
                              <CTableDataCell>{ele?.subscriptions?.packageName_EN}</CTableDataCell>
                              <CTableDataCell>{ele?.amount}</CTableDataCell>
                              <CTableDataCell>
                                {ele?.type == 'order' ? ele?.orderId : '-'}
                              </CTableDataCell>
                              <CTableDataCell>
                                {ele?.type == 'order' ? 'Order' : 'Package'}
                              </CTableDataCell>
                              <CTableDataCell>{ele?.paymentStatus?.toUpperCase()}</CTableDataCell>

                              {/* <CTableDataCell>
                                                                <CButton
                                                                    size="sm"
                                                                    outline={ele?.status ? "1" : "0"}
                                                                    style={{ backgroundColor: ele?.status ? '#00796B' : '#D32F2F', borderColor: ele?.status ? '#00796B' : '#D32F2F' }}
                                                                    onClick={() =>
                                                                        statusChange(ele?.status, ele?._id)
                                                                    }
                                                                >
                                                                    {ele?.status ? "Active" : "Inactive"}
                                                                </CButton>
                                                            </CTableDataCell> */}
                              {/* <CTableDataCell>
                                                                <span className="mx-2" onClick={() => onClickUserEdit(ele)} style={{ fontSize: '20px', color: '#d3dc40', cursor: 'pointer' }}><FaEdit /></span>
                                                                <span className="mx-2 text-danger" onClick={() => deleteData(ele?._id)} style={{ fontSize: '20px', padding: '5px', cursor: 'pointer' }}><FaTrash /></span>
                                                                <span className="mx-2" onClick={() => onClickUserView(ele)} style={{ fontSize: '20px', color: '#d3dc40', padding: '5px', cursor: 'pointer' }}><FaRegEye /></span>
                                                            </CTableDataCell> */}
                            </CTableRow>
                          </>
                        )
                      })}
                    </CTableBody>
                  </CTable>
                  {!paymentData?.length && (
                    <>
                      <h1>No Data</h1>
                    </>
                  )}
                  {paymentData?.length ? (
                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={pageData?.page}
                      itemsCountPerPage={pageData?.perPage}
                      totalItemsCount={totalPages * 10}
                      pageRangeDisplayed={5}
                      onChange={changePage}
                    />
                  ) : (
                    ''
                  )}
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default index
