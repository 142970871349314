import { createSlice } from '@reduxjs/toolkit'

export const counterSlice = createSlice({
  name: 'auth',
  initialState: {
    token: null,
    userId: null,
    email: null,
    role: null,
    sidebarShow: true,
    filterShow: false
  },
  reducers: {
    LoginDetails: (state, action) => {
      // console.log(state)
      state.token = action.payload.token
      state.userId = action.payload.userId
      state.email = action.payload.email
      state.role = action.payload.role
      state.sidebarShow = true
    },
    LogoutDetails: (state, action) => {
      state.token = action.payload.token
      state.userId = action.payload.userId
      state.email = action.payload.email
      state.role = action.payload.role
    },
    toggleVisible: (state, action) => {
      state.sidebarShow = true
    },
    toggleInvisible: (state) => {
      state.sidebarShow = false
    },
    toggleFilterVisible: (state) => {
      state.filterShow =  true
    },
    toggleFilterInvisible: (state) => {
      state.filterShow =  false
    },
  },
})

export const { LoginDetails, LogoutDetails, toggleVisible, toggleInvisible ,toggleFilterVisible , toggleFilterInvisible } = counterSlice.actions
export default counterSlice.reducer
