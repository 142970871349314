// import React, { useRef, useState, useEffect } from 'react';
// import { Formik } from 'formik';
// import { Link, useNavigate } from 'react-router-dom'
// import { baseURL } from 'src/config/config'
// import { get, post } from 'src/utils/apiManager';
// import { ToastContainer, toast } from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css'
// import {
//   CCard,
//   CCardBody,
//   CCardGroup,
//   CCol,
//   CContainer,
//   CImage,
//   CRow,
//   CFormLabel
// } from '@coreui/react'
// import { signUp } from 'src/utils/validator';
// import Select from 'react-select'

// export default function addBulkOrderQR() {
//   const navigate = useNavigate()
//   const formRef = useRef(null)
//   const [driverData, setDriverData] = useState(null);
//   const [selectedDriverOption, setSelectedDriverOption] = useState(null);


//   useEffect(() => {
//     getDrivers();
//   }, [])


//   const getDrivers = () => {
//       get(`/admin/driver/get`,).then((data) => {
//         if (data?.data !== null) {
//           const tempData = data?.data?.map((item, key) => {
//             return { label: item?.username, value: item?._id }
//           })
//           console.log(tempData)
//           setDriverData(tempData)
//         }
//       })
//   }

//   const submit = async (values) => {
//     console.log(values)
//     let formData = new FormData()
//     for (var ele in values) {
//       formData?.append(ele, values[ele])
//     }
//     const Url = `/admin/bulk-order/genrate-qr-code`
//     post(Url, formData, 1).then((data) => {
//       handleResponse(data)
//     })
//   }

//   const handleResponse = (data) => {
//     if (data?.status == true) {
//       toast.success(data?.message)
//       setTimeout(() => {
//         navigate('/admin/bulk-qr-code')
//       }, 500)
//     }
//     else {
//       toast.error(data?.message)
//     }
//     formRef.current.resetForm()
//     fileRef.current.value = null
//     setUserImagePreview('')
//   }


//   const onClickReset = () => {
//     formRef.current.setFieldValue('user_id', '')
//     fileRef.current.value = null
//   };

//   return (
//     <>
//       <CContainer >
//         <CRow>
//           <CCol>
//             <CCardGroup>
//               <CCard className="p-4">
//                 <CCol xs={12}>
//                   <CCardGroup>
//                     <CCardBody>
//                       <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
//                       <CRow>
//                         <CCol md="11">
//                           <h1>New Bulk Order QR</h1>
//                         </CCol>
//                         <CCol md="1">
//                           <Link className={'btn btn-success mt-2'} style={{ background: '#d3dc40', border: '#d3dc40' }} to={{ pathname: `/admin/user` }}>
//                             Back
//                           </Link>
//                         </CCol>
//                       </CRow>
//                       <Formik
//                         initialValues={{
//                           userId: '',
//                         }}
//                         validationSchema={signUp}
//                         onSubmit={(values, { setSubmitting }) => {
//                           submit(values)
//                           setSubmitting(false)

//                         }}
//                         innerRef={formRef}
//                       >
//                         {(props) => (
//                           <form onSubmit={props?.handleSubmit}>
//                             {/* {console.log(props)} */}
//                             <CRow>
//                               <CCol md={12}>
//                                 <CFormLabel>Driver</CFormLabel>
//                                 <span className="text-danger" style={{ marginLeft: '5px' }}>*</span>
//                                 <Select
//                                   defaultValue={selectedDriverOption}
//                                   onChange={(val) => {
//                                     setSelectedDriverOption(val)
//                                     props?.setFieldValue("userId", val.value)
//                                   }}
//                                   options={driverData}
//                                   placeholder={"Select Driver"}
//                                   value={selectedDriverOption}
//                                   name="Driver" />
//                               </CCol>
//                             </CRow>

//                             <CRow>
//                               <CCol md="2">
//                                 <button
//                                   type="submit"
//                                   className={'btn btn-success mt-2'}
//                                   disabled={props?.isSubmitting}
//                                 >
//                                   Submit
//                                 </button>
//                               </CCol>
//                               <CCol md="2">
//                                 <button
//                                   type="button"
//                                   className={'btn btn-warning mt-2'}
//                                   onClick={onClickReset}
//                                 >
//                                   Reset
//                                 </button>
//                               </CCol>
//                               <CCol md="3"></CCol>
//                             </CRow>
//                           </form>
//                         )}
//                       </Formik>
//                     </CCardBody>
//                   </CCardGroup>
//                 </CCol>
//               </CCard>
//             </CCardGroup>
//           </CCol>
//         </CRow>
//       </CContainer>
//     </>
//   )
// }


import React, { useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import { useNavigate, Link } from 'react-router-dom'
import { post, get } from 'src/utils/apiManager';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CFormLabel,
  CImage,
  CRow,
} from '@coreui/react'
import { validateBulkQr, validateDriver } from 'src/utils/validator'
import Select from 'react-select'

function addBulkOrderQR() {
  const navigate = useNavigate()
  const formRef = useRef(null)
  const [selectedDriverOption, setSelectedDriverOption] = useState(null);
  const [driverData, setDriverData] = useState(null);
  const [buttonDisable, setButtonDisable] = useState(false);
  const options = [];

  const getDrivers = () => {
    get(`/admin/driver/get`,).then((data) => {
      if (data?.data !== null) {
        const tempData = data?.data?.map((item, key) => {
          return { label: item?.username, value: item?._id }
        })
        console.log(tempData)
        setDriverData(tempData)
      }
    })
  }
  
  const submit = async (values) => {
    setButtonDisable(true)
    let obj ={
      "userId":selectedDriverOption?.value
    }
    post(`admin/bulk-order/genrate-qr-code`, obj).then((data) => {
      handleResponse(data)
    })
  }
  const handleResponse = (data) => {
    console.log("data data", data)
    if (data.status == true) {
      toast.success(data.message)
      navigate('/admin/bulk-qr-code')
    } else {
      setButtonDisable(false)
      toast.error(data?.message)
    }
    formRef?.current?.resetForm()
    setSelectedDriverOption('')
  }
  const onClickReset = () => {
    // Reset Button Function
    formRef.current.setFieldValue('userId', '')
    setSelectedDriverOption('')

  };
  useEffect(() => {
    getDrivers();
  }, [])

  return (
    <>
      <CContainer >
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <CRow>
                        <CCol md="11">
                          <h1>New Bulk QR Code</h1>
                        </CCol>
                        <CCol md="1">
                          <Link className={'btn btn-success mt-2'} style={{ background: '#d3dc40', border: '#d3dc40' }} to={{ pathname: `/admin/bulk-qr-code` }}>
                            Back
                          </Link>
                        </CCol>
                      </CRow>
                      <Formik
                        initialValues={{
                          userId: ''
                        }}
                        validationSchema={validateBulkQr}
                        onSubmit={(values, { setSubmitting }) => {
                          console.log(values)
                          submit(values)
                          setSubmitting(false)

                        }}
                        innerRef={formRef}
                      >
                        {(props) => (
                          <form onSubmit={props?.handleSubmit}>
                            <CRow>
                              <CCol md={12}>
                                <CFormLabel>Driver</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <Select
                                  defaultValue={selectedDriverOption}
                                  onChange={(val) => {
                                    setSelectedDriverOption(val)
                                    props?.setFieldValue("userId", val?.value)
                                  }}
                                  options={driverData}
                                  placeholder={"Select Driver"}
                                  value={selectedDriverOption}
                                  name="Driver" />
                                {selectedDriverOption == undefined ? <span className='text-danger'>{props?.errors?.userId}</span> : ''}
                                <br />

                              </CCol>
                            </CRow>

                            <CRow>
                              <CCol md="2">
                                <button
                                  type="submit"
                                  className={'btn btn-success mt-2'}
                                >
                                  Submit
                                </button>
                              </CCol>
                              <CCol md="2">
                                <button
                                  type="button"
                                  className={'btn btn-warning mt-2'}
                                  onClick={onClickReset}
                                >
                                  Reset
                                </button>
                              </CCol>
                              <CCol md="3"></CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>

    </>
  )
}

export default addBulkOrderQR