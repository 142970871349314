import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { post, get, deleteApi } from '../../utils/apiManager'
import CIcon from '@coreui/icons-react'
import { cilPlus } from '@coreui/icons'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CCardSubtitle,
  CTable,
  CRow,
  CImage,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CButton,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import { DefaultInputWithIcon } from 'src/components/common/input'
import { FaRegEye, FaEdit, FaTrash } from 'react-icons/fa'
import { ImageUrl } from 'src/config/config'
import Pagination from 'react-js-pagination'
import ShowMoreText from 'react-show-more-text'

const data = [{ title_en: 'english', title_th: 'Thai', description_en: 'Des_en', description_th: 'Des_th', image: '' },
{ title_en: 'english', title_th: 'Thai', description_en: 'Des_en', description_th: 'Des_th', image: '' },
{ title_en: 'english', title_th: 'Thai', description_en: 'Des_en', description_th: 'Des_th', image: '' }]

const index = () => {
  const navigate = useNavigate();
  const [aboutUsData, setAboutUsData] = useState([])
  const [deletedArray, setDeletedArray] = useState([])
  const [image, setImage] = useState('')
  const IMAGE = [];

  useEffect(() => {
    getData()
  }, [])

  const getData = () => {
    get(`admin/homeAboutUs/get`).then((response) => {
      handleResponse(response)
    })
  }
  const handleResponse = (response) => {
    // console.log('response', response)
    if (!response?.status) toast.success(response?.message, response?.status)
    else {
      setAboutUsData([...response?.data])
      // settotalPages(response?.pages)
      // setimageUrl(response?.imageUrl)
    }
  }

  const deleteData = (id) => {
    // console.log('delete call', id)
    deleteApi(`admin/homeAboutUs/delete?id=${id}`).then((res) => {
      // console.log('respons', res)
      toast.success(res?.message)
      if (res?.status) {
        getData()
      }
    })
  }

  const onClickUserEdit = (data) => {
    navigate('/admin/appHomeAboutUs/add', { state: data })
  }

  // const onSearchCLick = (val) => {
  //   setpageData({ ...pageData, searchItem: val, page: 1 })
  // }

  // const changePage = (page) => {
  //   setpageData({ ...pageData, page: page })
  // }

  const statusChange = (status, id) => {
    let default_status = ''
    if (status == 0) default_status = "Active"
    else default_status = "Inactive"
    if (confirm(`Are you sure you want to ${default_status} the status`)) {
      let formData = {
        _id: id,
        status: status == '1' ? 1 : 0
      };
      post('/admin/homeAboutUs/changeStatus', formData).then(
        (data) => {
          handleStatusChange(data);
        }
      );
    }
  };
  const handleStatusChange = (data) => {
    // console.log('response data',data)
    if (data?.status) {
      toast.success(data?.message, data?.status);
      getData();
    }
    else {
      toast.error(data?.message);
    }

  };
  const executeOnClick = async (isExpanded) => {
    // console.log(isExpanded);
  }


  const handleCheck = (id) => {
    let findValue = deletedArray.includes(id)
    if (findValue) {
      let filterData = deletedArray?.filter((item) => item != id)
      setDeletedArray(filterData)
    } else {
      setDeletedArray((prev) => [...prev, id])
    }
  }

  const deleteOrders = () => {
    post(`admin/homeAboutUs/deleted-aboutus`, deletedArray).then((data) =>
      handleDeletedResponse(data),
    )
  }

  const handleDeletedResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      setDeletedArray([])
      getData()
    }
  }

  return (<>
    <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
    <CContainer fluid>
      <CRow>
        <CCol>
          <CCardGroup>
            <CCard className="p-4">
              <CHeader>
                <div className="d-flex" style={{ flexDirection: 'column' }}>
                  <CCardSubtitle as="h4">App Home About Us</CCardSubtitle>
                  {deletedArray.length > 0 && (
                    <button className="btn btn-danger mt-2 text-white" onClick={deleteOrders}>
                      Delete
                    </button>
                  )}
                </div>
                <CRow>
                  {/* <CCol md="8">
                    <DefaultInputWithIcon
                      type={'text'}
                      placeholder="search"
                      customType={'search'}
                      value={pageData?.searchItem}
                      onClick={onSearchCLick}
                      onChange={(e) => {
                        onSearchCLick(e.target.value)
                      }}
                    />

                  </CCol> */}
                  <CCol >
                    <Link to={{ pathname: '/admin/appHomeAboutUs/add' }} className="btn btn-dark">
                      <CIcon icon={cilPlus} />
                      Add
                    </Link>
                  </CCol>
                </CRow>
              </CHeader>

              <CCardBody>
                <CTable bordered hover responsive>
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell>S. No</CTableHeaderCell>
                      <CTableHeaderCell>Title</CTableHeaderCell>
                      {/* <CTableHeaderCell>Title(Th)</CTableHeaderCell> */}
                      <CTableHeaderCell>Description</CTableHeaderCell>
                      {/* <CTableHeaderCell>Description(Th)</CTableHeaderCell> */}
                      <CTableHeaderCell>Image</CTableHeaderCell>
                      <CTableHeaderCell>Status</CTableHeaderCell>
                      <CTableHeaderCell>Action</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {aboutUsData?.map((ele, index) => {
                      {/* console.log(ele?.image) */ }
                      // console.log(`${ImageUrl}${ele.image}`)

                      return (
                        <CTableRow key={index} onClick={() => handleCheck(ele._id)} >
                          <CTableDataCell>{index + 1}

                            <div class="form-check">
                              <input
                                class="form-check-input"
                                style={{ marginLeft: '2px', marginTop: '-20px' }}
                                type="checkbox"
                                value=""
                                id="flexCheckDefault"
                                checked={deletedArray?.includes(ele._id)}
                              // onChange={() => handleCheck(ele._id)}
                              />
                            </div>
                          </CTableDataCell>

                          <CTableDataCell className="col col-md-2">{ele?.title_en}</CTableDataCell>
                          {/* <CTableDataCell>{ele?.title_th}</CTableDataCell> */}
                          <CTableDataCell
                            className="col col-md-6"
                          >
                            <ShowMoreText
                              /* Default options */
                              lines={1}
                              more="Show more"
                              less="Show less"
                              className="content-css"
                              anchorClass="my-anchor-css-class"
                              onClick={executeOnClick}
                              expanded={false}
                              // width={280}
                              truncatedEndingComponent={"... "}
                            >

                              <p dangerouslySetInnerHTML={{ __html: ele?.description_en }}></p>
                            </ShowMoreText>


                          </CTableDataCell>
                          {/* <CTableDataCell dangerouslySetInnerHTML={{ __html: ele?.description_en }}></CTableDataCell> */}
                          {/* <CTableDataCell>{ele?.description_th}</CTableDataCell> */}

                          <CTableDataCell >
                            {ele?.image?.map((i, index) => {
                              return (
                                <a target='_blank' href={`${ImageUrl}${i}`}>
                                  <CImage
                                    rounded
                                    src={`${ImageUrl}${i}`}
                                    className="mx-2"
                                    style={{
                                      maxHeight: '50px',
                                      maxWidth: '50px',
                                      borderRadius: 50,
                                      alignItems: 'center',
                                    }}
                                  />
                                </a>
                              )
                            })}

                          </CTableDataCell>
                          <CTableDataCell>
                            <CButton
                              size="sm"
                              outline={ele?.status ? "1" : "0"}
                              style={{ backgroundColor: ele?.status ? '#00796B' : '#D32F2F', borderColor: ele?.status ? '#00796B' : '#D32F2F' }}
                              onClick={() =>
                                statusChange(!ele?.status, ele?._id)
                              }
                            >
                              {!ele?.status ? "Active" : "Inactive"}
                            </CButton>
                          </CTableDataCell>

                          <CTableDataCell>
                            <span className="mx-2" onClick={() => onClickUserEdit(ele)} style={{ fontSize: '20px', color: '#d3dc40', cursor: 'pointer' }}><FaEdit /></span>
                            <span className="mx-2 text-danger" onClick={() => deleteData(ele?._id)} style={{ fontSize: '20px', padding: '5px', cursor: 'pointer' }}><FaTrash /></span>
                          </CTableDataCell>
                        </CTableRow>
                      );
                    })}
                  </CTableBody>
                </CTable>
                {!aboutUsData?.length && (
                  <>
                    <h1>No Data</h1>
                  </>
                )}
                {/* <Pagination
                  itemClass="page-item"
                  linkClass="page-link"
                  activePage={pageData?.page}
                  itemsCountPerPage={pageData?.perPage}
                  totalItemsCount={totalPages * 10}
                  pageRangeDisplayed={5}
                  onChange={changePage}
                /> */}
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer >
  </>)

}

export default index