import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCardSubtitle,
  CCol,
  CContainer,
  CHeader,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'
import React, { useEffect, useState } from 'react'
import Pagination from 'react-js-pagination'
import { ToastContainer, toast } from 'react-toastify'
import { DefaultInputWithIcon } from 'src/components/common/input'
import { get, put } from 'src/utils/apiManager'
import Lottie from 'lottie-react'
import groovyWalkAnimation from './groovyWalk.json'

function pickupRejected() {
  const [pickupRejectedOrders, setPickupRejectedOrders] = useState()
  const [pickupCancellationOrders, setPickupCancellationOrders] = useState(true)
  const [searchItem, setSearchItem] = useState('')
  const [totalPages, setTotalPages] = useState(0)
  const [count, setCount] = useState()
  const [deletedArray, setDeletedArray] = useState([])
  const [pageData, setPageData] = useState({
    page: 1,
    perPage: 10,
    searchItem: '',
  })
  let data = {
    orderStatus: 'hold',
    driverStatus: 'cancel(pickup)',
  }
  const getData = () => {
    get(
      `/admin/order-status/rejected-orders?page=${pageData?.page || 1}&perPage=${
        pageData?.perPage || 10
      }&searchItem=${pageData?.searchItem || ''}&orderStatus=${data.orderStatus}&driverStatus=${
        data.driverStatus
      }`,
    ).then((res) => handleResponse(res))
  }

  const handleResponse = (res) => {
    // console.log(res)
    // console.log(res.data.pickupRejectedOrders[0].data)
    if (res.status === true) {
      // toast.success(res.message)
      setTimeout(() => {
        setPickupCancellationOrders(false)
      }, 1500)
      setPickupRejectedOrders(res?.data?.pickupRejectedOrders[0]?.data)
      setTotalPages(res?.data?.pickupRejectedOrders[0]?.metadata[0]?.page)
      setCount(res?.data?.pickupRejectedOrders[0]?.metadata[0]?.total)
    } else {
      toast.error('No pickup rejected order ')
    }
  }
  const onSearchCLick = () => {
    setPageData({
      ...pageData,
      searchItem: searchItem,
    })
  }
  const changePage = (index) => {
    setPageData({
      ...pageData,
      page: index,
    })
  }

  useEffect(() => {
    getData()
  }, [pageData])

  const handleCheck = (id) => {
    let findValue = deletedArray.includes(id)
    if (findValue) {
      let filterData = deletedArray?.filter((item) => item != id)
      setDeletedArray(filterData)
    } else {
      setDeletedArray((prev) => [...prev, id])
    }
  }

  const deleteOrders = () => {
    post(`admin/placed-order/deleted-order`, deletedArray).then((data) =>
      handleDeletedResponse(data),
    )
  }

  const handleDeletedResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      setDeletedArray([])
      //   get(
      //     `/admin/placed-order/order-placed-list?page=${pageData?.page || 1}&perPage=${
      //       pageData?.perPage || 10
      //     }&searchItem=${pageData?.searchItem || ''}&from_date=${searchDate ? searchDate : ''}`,
      //   ).then((res) => handleResponse(res))
      getData()
    }
  }

  return (
    <>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <div className="d-flex" style={{ flexDirection: 'column' }}>
                    <CCardSubtitle as="h4">Pickup Rejected Orders</CCardSubtitle>
                    {deletedArray.length > 0 && (
                      <button className="btn btn-danger mt-2 text-white" onClick={deleteOrders}>
                        Delete
                      </button>
                    )}
                  </div>
                  <CRow>
                    <CCol md="8">
                      <DefaultInputWithIcon
                        type={'text'}
                        placeholder="Search"
                        customType={'search'}
                        value={searchItem}
                        onClick={onSearchCLick}
                        onChange={(e) => {
                          onSearchCLick()
                          setSearchItem(e.target.value)
                        }}
                      />
                    </CCol>
                  </CRow>
                </CHeader>
                {pickupCancellationOrders !== true ? (
                  <CCardBody>
                    <CTable bordered hover responsive>
                      <CTableHead>
                        <CTableRow>
                          <CTableHeaderCell>S. No</CTableHeaderCell>
                          <CTableHeaderCell>Order ID</CTableHeaderCell>
                          <CTableHeaderCell>Order Status</CTableHeaderCell>
                          <CTableHeaderCell>Pickup Rejected</CTableHeaderCell>
                          <CTableHeaderCell>User Name</CTableHeaderCell>
                          <CTableHeaderCell>Mobile Number</CTableHeaderCell>
                          <CTableHeaderCell>Driver Name</CTableHeaderCell>
                          <CTableHeaderCell>Driver Mobile Number</CTableHeaderCell>
                          <CTableHeaderCell>Driver Status</CTableHeaderCell>
                        </CTableRow>
                      </CTableHead>
                      <CTableBody>
                        {pickupRejectedOrders?.map((item, index) => {
                          return (
                            <CTableRow key={index} onClick={() => handleCheck(item._id)}>
                              <CTableDataCell  >{index + 1}
                              <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    style={{ marginLeft: '2px', marginTop: '-20px' }}
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                    checked={deletedArray?.includes(item._id)}
                                    // onChange={() => handleCheck(item._id)}
                                  />
                                </div>
                              </CTableDataCell>
                              <CTableDataCell style={{ textDecoration: 'none', color: '#80861C' }}>
                                #{item?.orderId}
                                {/* <Link to={{ pathname: `/admin/placed-order/item-detail/${btoa(item._id)}/${btoa(item.orderId)}`, state: item }} style={{ textDecoration: 'none', color: '#80861C' }}>#{item?.orderId}</Link> */}
                              </CTableDataCell>

                              <CTableDataCell>
                                {item.orderStatus == 'hold' ? (
                                  <CButton
                                    size="sm"
                                    style={{ backgroundColor: '#2196F3', border: '#2196F3' }}
                                    outline={item?.orderStatus}
                                  >
                                    <span>
                                      <strong>Hold</strong>
                                    </span>
                                  </CButton>
                                ) : (
                                  ''
                                )}
                              </CTableDataCell>
                              <CTableDataCell>
                                {item.driverStatus == 'cancel(pickup)' ? (
                                  <CButton
                                    size="sm"
                                    style={{ backgroundColor: '#FBC02D', border: '#FBC02D' }}
                                    outline={item?.driverStatus}
                                  >
                                    <span>
                                      <strong>{item.driverStatus}</strong>
                                    </span>
                                  </CButton>
                                ) : (
                                  ''
                                )}
                              </CTableDataCell>
                              <CTableDataCell>
                                {item?.userId.map((i, k) => {
                                  return <span>{i.username}</span>
                                })}
                              </CTableDataCell>
                              <CTableDataCell>
                                {item?.userId.map((i, k) => {
                                  return <span>{i.mobile}</span>
                                })}
                              </CTableDataCell>
                              <CTableDataCell>
                                {item?.pickup_driver.map((i, k) => {
                                  return <span>{i.username}</span>
                                })}
                              </CTableDataCell>
                              <CTableDataCell>
                                {item?.pickup_driver.map((i, k) => {
                                  return <span>{i.mobile}</span>
                                })}
                              </CTableDataCell>
                              <CTableDataCell>
                                {item.driverStatus == 'cancel(pickup)' ? (
                                  <CButton
                                    size="sm"
                                    style={{ backgroundColor: '#FF0000', border: '#FF0000' }}
                                    outline={item?.driverStatus}
                                  >
                                    <span>
                                      <strong>Cancel</strong>
                                    </span>
                                  </CButton>
                                ) : (
                                  ''
                                )}
                              </CTableDataCell>
                            </CTableRow>
                          )
                        })}
                      </CTableBody>
                    </CTable>
                    {!pickupRejectedOrders?.length && (
                      <>
                        <h1>No Data</h1>
                      </>
                    )}
                    {pickupRejectedOrders?.length ? (
                      <Pagination
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={pageData?.page}
                        itemsCountPerPage={pageData?.perPage}
                        totalItemsCount={count}
                        pageRangeDisplayed={5}
                        onChange={changePage}
                      />
                    ) : (
                      ''
                    )}
                  </CCardBody>
                ) : (
                  <div className="lodderClass" style={{ width: '50%', marginLeft: '20%' }}>
                    <Lottie animationData={groovyWalkAnimation} />
                  </div>
                )}
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default pickupRejected
