import axios from "axios"
import { baseURL } from 'src/config/config'

export class UploadAdapter {
    constructor(loader) {
        this.loader = loader
    }

    async upload() {
        return this.loader.file.then((file) => {
            const data = new FormData()
            data.append("file", file)
            const genericError = `Couldn't upload file: ${file.name}.`

            console.log(baseURL, "Upload base url")
            return axios({
                data,
                method: "POST",
                url: `${baseURL}/admin/upload-image`,
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            })
                .then(({ data }) => ({ default: data.url }))
                .catch(({ error }) => Promise.reject(error?.message ?? genericError))
        })
    }

    abort() {
        return Promise.reject()
    }
}

// CKEditor FileRepository
export function uploadAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) =>
        new UploadAdapter(loader)
}