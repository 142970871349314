import { Button, Modal } from '@coreui/coreui'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCardSubtitle,
  CCol,
  CContainer,
  CHeader,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'
import React, { useEffect, useRef, useState } from 'react'
import { FaReply, FaReplyd } from 'react-icons/fa'
import Pagination from 'react-js-pagination'
import { Form } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { DefaultInputWithIcon } from 'src/components/common/input'
import { get, post } from 'src/utils/apiManager'
import { ImageUrl } from 'src/config/config'
import Lottie from 'lottie-react'
import groovyWalkAnimation from './groovyWalk.json'

function CustomerCancelOrders() {
  const [customerRejectedOrders, setCustomerRejectedOrders] = useState()
  const [visible, setVisible] = useState(false)
  const [customerCancelOrders, setCustomerCancelOrders] = useState(true)
  const [modalData, setModalData] = useState()
  const [logo, setLogo] = useState()
  const [modalReason, setModalReason] = useState()
  const [searchItem, setSearchItem] = useState('')
  const [totalPages, setTotalPages] = useState(0)
  const [count, setCount] = useState()
  const [deletedArray, setDeletedArray] = useState([])
  const [pageData, setPageData] = useState({
    page: 1,
    perPage: 10,
    searchItem: '',
  })
  let data = {
    orderStatus: 'cancel(request)',
    driverStatus: 'hold',
  }

  const getData = () => {
    get(
      `/admin/order-status/rejected-orders-list?page=${pageData?.page || 1}&perPage=${
        pageData?.perPage || 10
      }&searchItem=${pageData?.searchItem || ''}&orderStatus=${data.orderStatus}&driverStatus=${
        data.driverStatus
      }`,
    ).then((res) => handleResponse(res))
  }

  const handleResponse = (res) => {
    // console.log(res)
    // console.log(res.data.deliverRejectedOrders[0].data)
    if (res.status === true) {
      // toast.success(res.message)
      setTimeout(() => {
        setCustomerCancelOrders(false)
      }, 1500)
      setCustomerRejectedOrders(res?.data?.customerRejectedOrders[0]?.data)
      setTotalPages(res?.data?.customerRejectedOrders[0]?.metadata[0]?.page)
      setCount(res?.data?.customerRejectedOrders[0]?.metadata[0]?.total)
    } else {
      toast.error('No pickup rejected order ')
    }
  }
  const onSearchCLick = () => {
    setPageData({
      ...pageData,
      searchItem: searchItem,
    })
  }
  const changePage = (index) => {
    setPageData({
      ...pageData,
      page: index,
    })
  }
  const showRejectReason = (data) => {
    const temp = data[0]?.reasonId[0]
    return temp?.reason
  }
  const showModalRejectReason = (data) => {
    if (visible) {
      const temp = data[0]?.reasonId[0]
      return temp?.reason
    }
  }
  const getLogo = () => {
    get(`/admin/global/global-setting`).then((response) => {
      if (response?.status == true) {
        setLogo(`${ImageUrl}${response.data.logoImage}`)
      }
    })
  }
  const userReplay = async (data) => {
    setVisible(true)
    setModalData(data)
  }
  const onClickRequest = async (user, data, status) => {
    const temp = {
      userId: user,
      orderId: data?.orderId,
      customerRequest: status,
    }
    post(`admin/order-status/request-action`, temp).then((res) => {
      handleCustomerResponse(res)
    })
    setVisible(false)
  }
  const handleCustomerResponse = (res) => {
    if (res.status === true) {
      toast.success(res.message)
      getData()
    } else {
      toast.error('No pickup rejected order ')
    }
  }

  useEffect(() => {
    getData()
    getLogo()
  }, [pageData])

  const handleCheck = (id) => {
    let findValue = deletedArray.includes(id)
    if (findValue) {
      let filterData = deletedArray?.filter((item) => item != id)
      setDeletedArray(filterData)
    } else {
      setDeletedArray((prev) => [...prev, id])
    }
  }

  const deleteOrders = () => {
    post(`admin/placed-order/deleted-order`, deletedArray).then((data) =>
      handleDeletedResponse(data),
    )
  }

  const handleDeletedResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      setDeletedArray([])
      //   get(
      //     `/admin/placed-order/order-placed-list?page=${pageData?.page || 1}&perPage=${
      //       pageData?.perPage || 10
      //     }&searchItem=${pageData?.searchItem || ''}&from_date=${searchDate ? searchDate : ''}`,
      //   ).then((res) => handleResponse(res))
      getData()
    }
  }

  return (
    <>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <div className="d-flex" style={{ flexDirection: 'column' }}>
                    <CCardSubtitle as="h4">Customer Rejected Orders</CCardSubtitle>
                    {deletedArray.length > 0 && (
                      <button className="btn btn-danger mt-2 text-white" onClick={deleteOrders}>
                        Delete
                      </button>
                    )}
                  </div>
                  <CRow>
                    <CCol md="8">
                      <DefaultInputWithIcon
                        type={'text'}
                        placeholder="Search"
                        customType={'search'}
                        value={searchItem}
                        onClick={onSearchCLick}
                        onChange={(e) => {
                          onSearchCLick()
                          setSearchItem(e.target.value)
                        }}
                      />
                    </CCol>
                  </CRow>
                </CHeader>
                {customerCancelOrders !== true ? (
                  <CCardBody>
                    <CTable bordered hover responsive>
                      <CTableHead>
                        <CTableRow>
                          <CTableHeaderCell>S. No</CTableHeaderCell>
                          <CTableHeaderCell>Order ID</CTableHeaderCell>
                          <CTableHeaderCell>Order Status</CTableHeaderCell>
                          <CTableHeaderCell>Customer Cancelation (Reason)</CTableHeaderCell>
                          <CTableHeaderCell>User Name</CTableHeaderCell>
                          <CTableHeaderCell>Mobile Number</CTableHeaderCell>
                          {/* <CTableHeaderCell>Action</CTableHeaderCell> */}
                        </CTableRow>
                      </CTableHead>
                      <CTableBody>
                        {customerRejectedOrders?.map((item, index) => {
                          return (
                            <CTableRow key={index} onClick={() => handleCheck(item._id)}>
                              <CTableDataCell >
                                {index + 1}
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    style={{ marginLeft: '2px', marginTop: '-20px' }}
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                    checked={deletedArray?.includes(item._id)}
                                    // onChange={() => handleCheck(item._id)}
                                  />
                                </div>
                              </CTableDataCell>
                              <CTableDataCell style={{ textDecoration: 'none', color: '#80861C' }}>
                                #{item?.orderId}
                                {/* <Link to={{ pathname: `/admin/placed-order/item-detail/${btoa(item._id)}/${btoa(item.orderId)}`, state: item }} style={{ textDecoration: 'none', color: '#80861C' }}>#{item?.orderId}</Link> */}
                              </CTableDataCell>
                              <CTableDataCell>
                                {item.orderStatus == 'cancel(request)' ? (
                                  <CButton
                                    size="sm"
                                    style={{ backgroundColor: '#BDBDBD', border: '#BDBDBD' }}
                                    outline={item?.orderStatus}
                                  >
                                    <span>
                                      <strong>{item.orderStatus}</strong>
                                    </span>
                                  </CButton>
                                ) : (
                                  ''
                                )}
                              </CTableDataCell>
                              <CTableDataCell>
                                <span>{showRejectReason(item?.orderCancelReason)}</span>
                              </CTableDataCell>
                              <CTableDataCell>
                                {item?.userId.map((i, k) => {
                                  return <span>{i.username}</span>
                                })}
                              </CTableDataCell>
                              <CTableDataCell>
                                {item?.userId.map((i, k) => {
                                  return <span>{i.mobile}</span>
                                })}
                              </CTableDataCell>
                              {/* <CTableDataCell>
                                                                <span className="mx-2 " onClick={() => userReplay(item)} style={{ color: '#d3dc40', fontSize: '20px', padding: '5px', cursor: 'pointer' }}><FaReply /></span>
                                                            </CTableDataCell> */}
                            </CTableRow>
                          )
                        })}
                      </CTableBody>
                    </CTable>
                    {!customerRejectedOrders?.length && (
                      <>
                        <h1>No Data</h1>
                      </>
                    )}
                    {customerRejectedOrders?.length ? (
                      <Pagination
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={pageData?.page}
                        itemsCountPerPage={pageData?.perPage}
                        totalItemsCount={count}
                        pageRangeDisplayed={5}
                        onChange={changePage}
                      />
                    ) : (
                      ''
                    )}
                  </CCardBody>
                ) : (
                  <div className="lodderClass" style={{ width: '50%', marginLeft: '20%' }}>
                    <Lottie animationData={groovyWalkAnimation} />
                  </div>
                )}
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
        {/* <CButton onClick={() => setVisible(!visible)}>Launch demo modal</CButton> */}
        <CModal alignment="center" visible={visible} onClose={() => setVisible(false)}>
          <CModalHeader onClose={() => setVisible(false)}>
            <CModalTitle>
              <img src={logo} /> Order Cancellation Request
            </CModalTitle>
          </CModalHeader>
          <CModalBody>
            <CRow>
              <CCol md={8}>
                <strong>Reason of the Cancellation</strong>
              </CCol>
              <CCol>
                <strong>Order ID :-{modalData?.orderId}</strong>
              </CCol>
            </CRow>
            <CRow>
              <CCol className="mt-2">
                <ul>
                  <li>{showModalRejectReason(modalData?.orderCancelReason)}</li>
                </ul>
              </CCol>
            </CRow>
          </CModalBody>
          <CModalFooter>
            <CButton
              color="danger"
              onClick={() => onClickRequest(modalData?.userId[0]?._id, modalData, 'reject')}
            >
              Reject
            </CButton>
            <CButton
              color="success"
              onClick={() => onClickRequest(modalData?.userId[0]?._id, modalData, 'confirm')}
            >
              Confirm
            </CButton>
          </CModalFooter>
        </CModal>
      </CContainer>
    </>
  )
}

export default CustomerCancelOrders
