import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom'
import { get, post } from '../../../utils/apiManager'
import {
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CHeader,
    CCardSubtitle,
    CTable,
    CRow,
    CImage,
    CTableHead,
    CTableRow,
    CTableHeaderCell,
    CTableBody,
    CTableDataCell,
    CButton,
    CModal,
    CModalHeader,
    CModalTitle,
    CModalBody,
    CModalFooter,
    CFormLabel,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import { DefaultInputWithIcon } from 'src/components/common/input'
import Pagination from 'react-js-pagination'
import ReactToPrint from 'react-to-print'
import { Button, Modal } from '@coreui/coreui'
import { FaRegEye, FaPrint, FaSearchengin, FaTruckMoving, FaRegAddressCard, FaShoppingBag, FaFolderOpen, FaArrowLeft } from 'react-icons/fa'
import { ImageUrl, ImageUrl2 } from 'src/config/config'
import Select from 'react-select'
import { cibElectron } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import ImagePreview from '../previewImage'
import ItemImagePreview from '../itemImagePreview'
import Description from './descriptionModal'
import { MdOutlineQrCodeScanner } from "react-icons/md";

function ItemList() {
    const navigate = useNavigate();
    let componentRef = useRef();
    // const [visible, setVisible] = useState(false)
    const [visible, setVisible] = useState(false)
    const [visibleDescription, setVisibleDescription] = useState(false)
    const [modalData, setModalData] = useState()
    const [logo, setLogo] = useState()
    const [placedOrderList, setPlacedOrderList] = useState();
    const [visiblePreview, setVisiblePreview] = useState(false)
    const [pickupDrivers, setPickupDrivers] = useState(null);
    const [selectedPickupDrivers, setSelectedPickupDrivers] = useState(null);
    const pickupDriverOptions = [];

    const [deliverDrivers, setDeliverDrivers] = useState(null);
    const [selectedDeliverDrivers, setSelectedDeliverDrivers] = useState(null);
    const deliverDriverOptions = [];

    const [searchItem, setSearchItem] = useState("");
    const [totalPages, setTotalPages] = useState(0);
    const [count, setCount] = useState()
    const [stainImage, setStainImage] = useState([]);
    const [itemPicture, setItemPicture] = useState("");
    const [order, setOrder] = useState();
    const [pageData, setPageData] = useState({
        page: 1,
        perPage: 10,
        searchItem: "",
    });
    const { state } = useLocation();
    console.log({ state });
    const { orderId, item, orderData } = state;
    console.log({ orderData });
    const onClickOrderReply = (item) => {
        setVisible(true)
    }

    const getData = () => {
        get(
            `/admin/offline-order/bag-details/items/${item._id}`
            // ?page=${pageData?.page || 1}&perPage=${pageData?.perPage || 10
            // }&searchItem=${pageData?.searchItem || ""}`
        ).then((res) => handleResponse(res));
    };

    const handleResponse = (res) => {
        console.log({ res });
        setOrder(res?.data[0]);
        setPlacedOrderList(res?.data)
        // setTotalPages(res?.data[0]?.metadata[0]?.page);
        // setCount(res?.data[0]?.metadata[0]?.total)
    };

    useEffect(() => {
        getData();
        getLogo();
        getDriver();
    }, [pageData]);

    const onSearchCLick = () => {
        setPageData({
            ...pageData,
            searchItem: searchItem,
        });
    }

    const changePage = (index) => {
        setPageData({
            ...pageData,
            page: index,
        });
    };

    const handleExaminItem = (item) => {
        if (item?.packageUser?.length > 0) {
            navigate(`/admin/placed-order/examin-package-items/${btoa(item?._id)}/${btoa(item?.orderId)}/${btoa(item?.user[0]?._id)}`, { state: item })
        } else {
            navigate(`/admin/placed-order/examin/${btoa(item?._id)}/${btoa(item?.orderId)}/${btoa(item?.user[0]?._id)}`, { state: item })
        }
    }

    const onClickUserView = (data) => {
        navigate(`/admin/offer/view/${btoa(data?._id)}/${btoa(data?.service_type)}`, { state: data })
    }
    const onClickUserEdit = (data) => {
        navigate(`/admin/offer/edit/${btoa(data?._id)}/${btoa(data?.service_type)}`, { state: data })
    }
    const getLogo = () => {
        get(`/admin/global/global-setting`).then((response) => {
            if (response?.status == true) {
                setLogo(`${ImageUrl}${response.data.logoImage}`)
            }
        })
    }
    const getDriver = () => {
        get(`/admin/placed-order/get-driver`).then((response) => {
            setPickupDrivers(response?.data)
            setDeliverDrivers(response?.data)
        })
    }
    if (pickupDrivers !== null) {
        pickupDrivers?.map((item, key) => {
            pickupDriverOptions?.push({ label: item?.username, value: item?._id })
        })
    }
    if (deliverDrivers !== null) {
        deliverDrivers?.map((item, key) => {
            deliverDriverOptions?.push({ label: item?.username, value: item?._id })
        })
    }
    const ChangeDriver = async (data) => {
        setVisible(true);
        setModalData(data)
    }
    const onClickChangeDriver = async (val) => {
        val.preventDefault();
        const changeDriver = {
            orderId: modalData?.orderId,
            pickup_driver: val?.target?.PickupDriver != undefined ? val?.target?.PickupDriver.value : '',
            dropOff_driver: val?.target?.DeliverDriver != undefined ? val?.target?.DeliverDriver.value : '',
        }
        post(`admin/placed-order/change-driver`, changeDriver).then((data) => handleDriverResponse(data))
    }
    const handleDriverResponse = (data) => {
        if (data?.status == true) {
            toast.success(data?.message)
            setVisible(false);
        }
    }
    return (
        <>
            <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
            <CContainer fluid>
                <CRow>
                    <CCol>
                        <CCardGroup>
                            <CCard className="p-4">
                                <CHeader>
                                    <CRow>
                                        <CCol >
                                            {' '}
                                            <FaArrowLeft style={{ color: '#000' }} onClick={() => {
                                                navigate('/admin/offline-orders/bags', { state: { _id: orderData } })
                                            }} />
                                            <span>{' '}Items</span>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol md="8">
                                            {/* <DefaultInputWithIcon
                                                type={'text'}
                                                placeholder="Search"
                                                customType={'search'}
                                                value={searchItem}
                                                onClick={onSearchCLick}
                                                onChange={(e) => {
                                                    onSearchCLick()
                                                    setSearchItem(e?.target?.value)
                                                }}
                                            /> */}
                                        </CCol>
                                    </CRow>
                                </CHeader>
                                <CCardBody>
                                    <CTable bordered hover responsive>
                                        <CTableHead>
                                            <CTableRow>
                                                <CTableHeaderCell>S. No</CTableHeaderCell>
                                                <CTableHeaderCell>Item Name</CTableHeaderCell>
                                                <CTableHeaderCell>Brands</CTableHeaderCell>
                                                <CTableHeaderCell>Colors</CTableHeaderCell>
                                                <CTableHeaderCell>Description</CTableHeaderCell>
                                                <CTableHeaderCell>Service</CTableHeaderCell>
                                                <CTableHeaderCell>Picture</CTableHeaderCell>
                                                <CTableHeaderCell>Stain</CTableHeaderCell>
                                                <CTableHeaderCell>Stain Image</CTableHeaderCell>
                                            </CTableRow>
                                        </CTableHead>
                                        <CTableBody>
                                            {console.log("placedOrderList", placedOrderList)}
                                            {placedOrderList?.map((item, index) => {
                                                // console.log(item?.packageUser?.length, "placeorder List items")
                                                return (
                                                    item?.item ? (


                                                        <CTableRow key={index} className={`${item?.item?.status == "active" ? "order_scanned" : ""}`}>
                                                            <CTableDataCell>{pageData?.perPage * (pageData?.page - 1) + index + 1}</CTableDataCell>
                                                            <CTableDataCell >
                                                                {item?.items?.itemName_EN}
                                                            </CTableDataCell>
                                                            <CTableDataCell >
                                                                {item?.item?.brand}
                                                            </CTableDataCell>
                                                            <CTableDataCell >
                                                                {(item?.colors?.map((data) => data.name)).join(", ")}
                                                            </CTableDataCell>
                                                            <CTableDataCell >
                                                                {item?.item?.description?.length > 10 ?
                                                                    `${item?.item?.description.substring(0, 10)}...` : item?.item?.description
                                                                }
                                                                {item?.item?.description?.length > 10 && <span onClick={() => setVisibleDescription(!visibleDescription)}>more</span>}
                                                                {/* {item?.item?.description} */}
                                                                <Description visibleDescription={visibleDescription} setVisibleDescription={setVisibleDescription} description={item?.item?.description} ></Description>
                                                            </CTableDataCell>
                                                            <CTableDataCell >
                                                                {item?.service?.serviceName_EN}
                                                            </CTableDataCell>
                                                            <CTableDataCell >
                                                                <i class="fa-solid fa-image" style={{ fontSize: "x-large" }} onClick={() => {
                                                                    if (item?.item?.picture) {
                                                                        setItemPicture(item?.item?.picture)
                                                                        setVisible(!visible)
                                                                    }
                                                                }}></i>

                                                            </CTableDataCell>
                                                            <CTableDataCell >
                                                                {item?.item?.stain}
                                                            </CTableDataCell>
                                                            <CTableDataCell >
                                                                <div className="d-flex align-items-center">

                                                                    <i class="fa-solid fa-images" style={{ fontSize: "x-large" }}
                                                                        onClick={() => {
                                                                            if (item?.item?.image && item?.item?.image[0] != "" && item?.item?.image.length > 0) {
                                                                                setStainImage(item?.item?.image)
                                                                                setVisiblePreview(!visiblePreview)
                                                                            }
                                                                        }
                                                                        }></i>
                                                                    {item?.item?.status != "active" ?
                                                                        <span
                                                                            style={{ fontSize: "20px", margin: "0 0 5px 8px" }}
                                                                            onClick={() => navigate("/admin/offline-orders/scan", { state: { item_id: item?.item?._id, bag_id: item?._id, lastPageNavigateData: state } })}
                                                                        >
                                                                            <MdOutlineQrCodeScanner />

                                                                        </span>
                                                                        : ""
                                                                    }
                                                                </div>

                                                            </CTableDataCell>

                                                        </CTableRow>
                                                    ) : ""
                                                );
                                            })}
                                            <ImagePreview visible={visible} setVisible={setVisible} image={ImageUrl2 + itemPicture} />
                                            <ItemImagePreview visiblePreview={visiblePreview} setVisiblePreview={setVisiblePreview} image={stainImage} />
                                        </CTableBody>
                                    </CTable>
                                    {!placedOrderList?.length && (
                                        <>
                                            <h1>No Data</h1>
                                        </>
                                    )}
                                    {/* {placedOrderList?.length ? <Pagination
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        activePage={pageData?.page}
                                        itemsCountPerPage={pageData?.perPage}
                                        totalItemsCount={count}
                                        pageRangeDisplayed={5}
                                        onChange={changePage}
                                    /> : ''} */}
                                </CCardBody>
                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer >
        </>
    )
}

export default ItemList
