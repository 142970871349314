import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { post, get, deleteApi } from '../../utils/apiManager'
import CIcon from '@coreui/icons-react'
import { cilPlus } from '@coreui/icons'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CCardSubtitle,
  CTable,
  CRow,
  CImage,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CButton,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import { DefaultInputWithIcon } from 'src/components/common/input'
import Pagination from 'react-js-pagination'
import { FaRegEye, FaEdit, FaTrash } from 'react-icons/fa'

function index() {
  const navigate = useNavigate()
  const [subAdminData, setSubAdminData] = useState([])
  const [imageUrl, setimageUrl] = useState('')
  const [totalPages, settotalPages] = useState(0)
  const [searchItem, setsearchItem] = useState('')
  const [addData, setAddData] = useState()
  const [deletedArray, setDeletedArray] = useState([])
  const [pageData, setpageData] = useState({
    page: 1,
    perPage: 10,
    searchItem: '',
  })
  useEffect(() => {
    getData()
    getAddData()
  }, [pageData])

  const getData = () => {
    get(
      `/admin/subAdmin/subAdmin-list?page=${pageData?.page || 1}&perPage=${pageData?.perPage || 10
      }&searchItem=${pageData?.searchItem || ''}`,
    ).then((data) => handleResponse(data))
  }

  const getAddData = () => {
    get(`/admin/subAdmin/sidebar-modules`).then((res) => {
      if (res.status == true) {
        let tempData = []
        tempData = res?.data?.map((item, index) => {
          return {
            ...item,
            chacked: false,
            isAdd: false,
            isEdit: false,
            isDelete: false,
            isView: false,
          }
        })
        setAddData(tempData)
      }
    })
  }

  const handleResponse = (data) => {
    // console.log(data)
    if (!data?.status) toast.success(data?.message, data?.status)
    else {
      setSubAdminData([...data?.data])
      setimageUrl(data?.imageUrl)
      settotalPages(data?.pages)
    }
  }
  const changePage = (index) => {
    setpageData({
      ...pageData,
      page: index,
    })
  }
  const statusChange = (status, id) => {
    let default_status = ''
    if (status == 0) default_status = "Active"
    else default_status = "Inactive"
    if (confirm(`Are you sure you want to ${default_status} the sub-admin`)) {
      let formData = {
        _id: id,
      }
      post(`${status ? '/admin/subAdmin/inactive' : '/admin/subAdmin/active'}`, formData).then(
        (data) => {
          handleStatusChange(data)
        },
      )
    }
  }
  const handleStatusChange = (data) => {
    console.log(data)
    toast.success(data?.message, data?.status)
    getData()
  }

  const onClickUserEdit = (value) => {
    // navigate('/admin/sub-admin/edit', { state: addData })
    navigate('/admin/sub-admin/edit', { state: { data: addData, userData: value } })
  }

  const onClickUserView = (value) => {
    console.log('values values', value)
    navigate(`/admin/sub-admin/view/${btoa(value?._id)}`, { state: value })
  }
  const deleteData = (id) => {
    if (confirm('Are you sure')) {
      let formData = {
        _id: id,
      }
      deleteApi(`/admin/subAdmin/delete?_id=${id}`).then((data) => {
        handleDelete(data)
      })
    }
  }
  const handleDelete = (data) => {
    toast.success(data?.message, data?.status)
    getData()
  }
  const onSearchCLick = () => {
    setpageData({
      ...pageData,
      searchItem: searchItem,
    })
  }
  const executeOnClick = async (isExpanded) => {
    // console.log(isExpanded);
  }

  const handleCheck = (id) => {
    let findValue = deletedArray.includes(id)
    if (findValue) {
      let filterData = deletedArray?.filter((item) => item != id)
      setDeletedArray(filterData)
    } else {
      setDeletedArray((prev) => [...prev, id])
    }
  }

  const deleteOrders = () => {
    post(`admin/subAdmin/bulk-delete`, { ids: deletedArray }).then((data) =>
      handleDeletedResponse(data),
    )
  }

  const handleDeletedResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      setDeletedArray([])
      getData()
    }
  }


  return (
    <>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <div className="d-flex" style={{ flexDirection: 'column' }}>
                    <CCardSubtitle as="h4">Sub Admin</CCardSubtitle>
                    {deletedArray.length > 0 && (
                      <button className="btn btn-danger mt-2 text-white" onClick={deleteOrders}>
                        Delete
                      </button>
                    )}
                  </div>
                  <CRow>
                    <CCol md="8">
                      <DefaultInputWithIcon
                        type={'text'}
                        placeholder="Search"
                        customType={'search'}
                        value={searchItem}
                        onClick={onSearchCLick}
                        onChange={(e) => {
                          onSearchCLick()
                          setsearchItem(e?.target?.value)
                        }}
                      />
                    </CCol>
                    {/* <CCol md="2"></CCol> */}
                    <CCol md="4">
                      <Link to={{ pathname: '/admin/sub-admin/add' }} className="btn btn-dark">
                        <CIcon icon={cilPlus} />
                        Add
                      </Link>
                    </CCol>
                  </CRow>
                </CHeader>

                <CCardBody>
                  <CTable bordered hover responsive>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell>S. No</CTableHeaderCell>
                        {/* <CTableHeaderCell>Image</CTableHeaderCell> */}
                        <CTableHeaderCell>Name</CTableHeaderCell>
                        <CTableHeaderCell>Email</CTableHeaderCell>
                        <CTableHeaderCell>Status</CTableHeaderCell>
                        <CTableHeaderCell>Action</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {subAdminData.map((ele, index) => {
                        return (
                          <CTableRow key={index} >
                            <CTableDataCell onClick={() => handleCheck(ele._id)}>
                              {pageData?.perPage * (pageData?.page - 1) + index + 1}
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  style={{ marginLeft: '2px', marginTop: '-20px' }}
                                  type="checkbox"
                                  value=""
                                  id="flexCheckDefault"
                                  checked={deletedArray?.includes(ele._id)}
                                // onChange={() => handleCheck(ele._id)}
                                />
                              </div>
                            </CTableDataCell>
                            {/* <CTableDataCell >
                            <CImage
                                  rounded
                                  src={`${imageUrl}${ele?.image}`}
                                  // onError={addDefaultSrc}
                                  style={{
                                    maxHeight: '50px',
                                    maxWidth: '50px',
                                    borderRadius: 50,
                                    alignItems: 'center',
                                  }}
                                />
                          </CTableDataCell> */}
                            <CTableDataCell>{ele?.username}</CTableDataCell>
                            <CTableDataCell>{ele?.email}</CTableDataCell>
                            <CTableDataCell>
                              <CButton
                                size="sm"
                                outline={ele?.status ? '1' : '0'}
                                onClick={() => statusChange(ele?.status, ele?._id)}
                                style={{ backgroundColor: ele?.status ? '#00796B' : '#D32F2F', borderColor: ele?.status ? '#00796B' : '#D32F2F' }}
                              >
                                {ele?.status ? 'Active' : 'Inactive'}
                              </CButton>
                            </CTableDataCell>
                            <CTableDataCell>
                              <span className="mx-2" onClick={() => onClickUserEdit(ele)} style={{ fontSize: '20px', color: '#d3dc40', cursor: 'pointer' }}><FaEdit /></span>
                              <span className="mx-2 text-danger" onClick={() => deleteData(ele?._id)} style={{ fontSize: '20px', padding: '5px', cursor: 'pointer' }}><FaTrash /></span>
                              <span className="mx-2" onClick={() => onClickUserView(ele)} style={{ fontSize: '20px', color: '#d3dc40', padding: '5px', cursor: 'pointer' }}><FaRegEye /></span>
                            </CTableDataCell>
                          </CTableRow>
                        )
                      })}
                    </CTableBody>
                  </CTable>
                  {!subAdminData?.length && (
                    <>
                      <h1>No Data</h1>
                    </>
                  )}
                  {subAdminData?.length ? <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={pageData?.page}
                    itemsCountPerPage={pageData?.perPage}
                    totalItemsCount={totalPages * 10}
                    pageRangeDisplayed={5}
                    onChange={changePage}
                  /> : ''}
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default index
