import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom'
import { get, post } from '../../../utils/apiManager'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CTable,
  CRow,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CButton,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import { FaArrowLeft } from 'react-icons/fa'
import { ImageUrl, ImageUrl2 } from 'src/config/config'
import ItemImagePreview from './itemImagePreview'
import ImagePreview from './previewImage'
import ItemScanModal from './ItemScanModal'
import OrderComplete from './orderComplete'
import OrderNotFound from 'src/components/LaundryProcessModals/OrderNotFound'
import ItemImagePreviewStain from './itemImagePreviewStain'

function BagList() {
  const navigate = useNavigate()
  let inputScannerRef = useRef()
  const [visible, setVisible] = useState(false)
  const [logo, setLogo] = useState()
  const [stainImage, setStainImage] = useState([])
  const [stainArray, setStainArray] = useState([])
  const [visiblePreview, setVisiblePreview] = useState(false)
  const [itemPicture, setItemPicture] = useState('')
  const [successModal, setSuccessModal] = useState(false)
  const [allTotalItems, setAllTotalItems] = useState(0)
  const [scanModelShow, setScanModelShow] = useState(false)
  const [totalCompleteItems, setTotalCompleteItems] = useState(0)
  const [incompleteLength, setIncompleteLength] = useState(0)
  const [newOrderId, setNewOrderId] = useState()
  const [barcodeValue, setBarcodeValue] = useState('')
  const [order, setOrder] = useState()
  const [notFoundModal, setNotFoundModal] = useState(false)

  const [pageData, setPageData] = useState({
    page: 1,
    perPage: 10,
    searchItem: '',
  })
  const { state } = useLocation()
  const { orderId, _id } = state
  const [numberOrderId, setNumberOrderId] = useState(orderId)
  const getData = (checkAllOrderComplete) => {
    get(`/admin/placed-order/order-details?orderId=${orderId}&checkValue=true`).then((res) => {
      if (res?.status) {
        setOrder(res?.data)
        setNewOrderId(res?.data?.[0]?.orderId)

        if (checkAllOrderComplete) {
          checkAllItemsScanned(res?.data)
        }
        let k = 0
        let r = 0
        let j = 0
        let totalItems = res?.data?.forEach((item) => {
          if (item?.service_id != null) {
            k += item?.items?.length
            if (item?.items?.length > 0) {
              item?.items?.forEach((data) => {
                if (data?.isCompleted) {
                  r += 1
                } else if (!data?.isCompleted) {
                  j += 1
                }
              })
            }
          }
        })
        if (k == r) {
          setSuccessModal(true)
        }
        setAllTotalItems(k)
        setIncompleteLength(j)
        setTotalCompleteItems(r)
        setNumberOrderId(res?.data?.[0]?.orderId)
      }
    })
  }
  const checkAllItemsScanned = (allData) => {
    get(`/admin/placed-order/check-is-order-completed?orderId=${orderId}`)
      .then((res) => {
        if (res?.status) {
          if (res?.message != 'Some items in the order are not completed') {
            setSuccessModal(true)
          }
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  useEffect(() => {
    getData()
    getLogo()
  }, [pageData])

  const getLogo = () => {
    get(`/admin/global/global-setting`).then((response) => {
      if (response?.status == true) {
        setLogo(`${ImageUrl}${response.data.logoImage}`)
      }
    })
  }

  const showTotal = (item) => {
    if (item?.serviceName_EN != 'Add Ons') {
      let count = item?.items?.reduce((accumulator, currentItem) => {
        if (currentItem?.isCompleted) {
          return accumulator + 1
        } else {
          return accumulator
        }
      }, 0)
      return `${count}/${item?.items?.length}`
    }
  }
  useEffect(() => {
    if (incompleteLength > 0) {
      setScanModelShow(true)
    }
  }, [incompleteLength])
  useEffect(() => {
    if (barcodeValue.length != '' && barcodeValue?.length > 0) {
      changeStatus()
    }
  }, [barcodeValue])
  useEffect(() => {
    inputScannerRef.current.focus()
  }, [])

  const changeStatus = () => {
    get(
      `/admin/placed-order/mark-item-as-completed?itemCode=${barcodeValue}&orderId=${numberOrderId}`,
    )
      .then((res) => {
        if (res?.response?.data?.status == false) {
          setNotFoundModal(true)
          setBarcodeValue('')
        } else {
          getData()
          handleModalClose()
          setBarcodeValue('')
          refresh(true)
        }
      })
      .catch((error) => {
        console.log('error.status', error?.response?.data?.status)
        setBarcodeValue('')
      })
  }
  return (
    <>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard
                className="p-4 "
                onClick={() => {
                  inputScannerRef.current.focus()
                }}
              >
                <CHeader>
                  <CRow>
                    <CCol>
                      {' '}
                      <FaArrowLeft
                        style={{ color: '#000' }}
                        onClick={() => {
                          navigate('/admin/checkout-orders')
                        }}
                      />
                      <span> Bags </span>
                      <CCol md="4">
                        <input
                          type="text"
                          className=""
                          ref={inputScannerRef}
                          value={barcodeValue}
                          onChange={(e) => setBarcodeValue(e.target.value)}
                          style={{
                            width: '1px',
                            height: '0px',
                            border: 'none',
                          }}
                        />
                      </CCol>
                      {/* <CButton
                                                className="btn btn-success"

                                                style={{
                                                    width: '150px',
                                                    background: '#d3dc40',
                                                    border: '#d3dc40',
                                                    padding: '10px 0',
                                                    position:"absolute",
                                                    right:0,
                                                    bottom:"10px"
                                                }}
                                                onClick={() => {setScanModelShow(true)}}
                                            >
                                                Scan Item
                                            </CButton> */}
                      {/* <CButton className="text-end " onClick={() => {setScanModelShow(true)}}>
                                    
                                                Scan Order
                                            </CButton> */}
                    </CCol>
                  </CRow>

                  <CRow>
                    <CCol md="8">
                      {/* <DefaultInputWithIcon
                                                type={'text'}
                                                placeholder="Search"
                                                customType={'search'}
                                                value={searchItem}
                                                onClick={onSearchCLick}
                                                onChange={(e) => {
                                                    onSearchCLick()
                                                    setSearchItem(e?.target?.value)
                                                }}
                                            /> */}
                    </CCol>
                  </CRow>
                </CHeader>
                <CCardBody>
                  <CCol>
                    <span className="d-flex justify-content-end">
                      {' '}
                      Total Items : {totalCompleteItems}/{allTotalItems}
                    </span>
                  </CCol>

                  {order?.map((ord, idx) => (
                    <>
                      <br />
                      <h4>{ord?.service_id != null && `Bag ${idx + 1}`}</h4>
                      <div className="d-flex align-items-center justify-content-between">
                        <h6>{ord?.serviceName_EN}</h6>
                        <h6>{showTotal(ord)} </h6>
                      </div>
                      <CTable bordered hover responsive>
                        <CTableHead>
                          <CTableRow>
                            <CTableHeaderCell>Barcode</CTableHeaderCell>
                            <CTableHeaderCell>Item Name</CTableHeaderCell>
                            <CTableHeaderCell>Brands</CTableHeaderCell>
                            <CTableHeaderCell>Colors</CTableHeaderCell>
                            <CTableHeaderCell>Description</CTableHeaderCell>
                            <CTableHeaderCell>Service</CTableHeaderCell>
                            <CTableHeaderCell>Picture</CTableHeaderCell>
                            <CTableHeaderCell>Stain</CTableHeaderCell>
                            <CTableHeaderCell>Stain Image</CTableHeaderCell>
                            {/* {ord?.serviceName_EN != "Add Ons" &&
                                                            <CTableHeaderCell>Scan</CTableHeaderCell>
                                                        } */}
                          </CTableRow>
                        </CTableHead>
                        <CTableBody>
                          {ord?.items?.map((item, index) => {
                            return (
                              <CTableRow
                                key={index}
                                color={
                                  ord?.serviceName_EN != 'Add Ons'
                                    ? item.isCompleted
                                      ? 'success'
                                      : 'danger'
                                    : ''
                                }
                              >
                                <CTableDataCell>{item?.itemCode || 'N/A'} </CTableDataCell>
                                <CTableDataCell>{item?.itemName_EN || 'N/A'}</CTableDataCell>
                                <CTableDataCell>{item?.brand || 'N/A'}</CTableDataCell>
                                <CTableDataCell>
                                  {item?.colorInfo?.length > 0
                                    ? item?.colorInfo?.map(
                                      (color, i) =>
                                        `${color.name}${item?.colorInfo?.length > i + 1 ? ',' : ''
                                        } `,
                                    )
                                    : 'N/A'}
                                </CTableDataCell>
                                <CTableDataCell>{item?.description || 'N/A'}</CTableDataCell>
                                <CTableDataCell>{ord?.serviceName_EN || 'N/A'}</CTableDataCell>
                                <CTableDataCell>
                                  <i
                                    class="fa-solid fa-image"
                                    style={{ fontSize: 'x-large' }}
                                    onClick={() => {
                                      if (item?.item_image) {
                                        setItemPicture(item?.item_image)
                                        setVisible(!visible)
                                      }
                                    }}
                                  ></i>
                                </CTableDataCell>
                                <CTableDataCell>
                                  {item?.stain.length ? item?.stain?.map((item) => item.stainName || 'N/A').join(",") : 'N/A'}
                                </CTableDataCell>
                                <CTableDataCell>
                                  <span>
                                    <i
                                      class="fa-solid fa-images"
                                      style={{ fontSize: 'x-large' }}
                                      onClick={() => {
                                        if (item?.stain) {
                                          //   setStainImage(item?.stain_image)
                                          setStainArray(item?.stain)
                                          setVisiblePreview(!visiblePreview)
                                        }
                                      }}
                                    ></i>
                                  </span>
                                </CTableDataCell>

                                {/* {ord?.serviceName_EN != "Add Ons" &&
                                                                    <CTableDataCell>
                                                                        <ItemScanModal item={item} orderId={orderId} refresh={getData} />
                                                                    </CTableDataCell>
                                                                } */}
                              </CTableRow>
                            )
                          })}
                        </CTableBody>
                      </CTable>
                    </>
                  ))}

                  {/*     {
                                        scanModelShow &&
                                        // <CTableDataCell>
                                        <ItemScanModal
                                            // item={item} 
                                            orderId={newOrderId}
                                            refresh={getData}
                                            scanModelShow={scanModelShow}
                                            setScanModelShow={setScanModelShow}
                                            setAllTotalItems={setAllTotalItems}
                                            setTotalCompleteItems={setTotalCompleteItems}
                                            setSuccessModal={setSuccessModal}
                                            setOrder={setOrder}
                                        />
                                        // </CTableDataCell>
                                    }
                                */}

                  {!order?.length && (
                    <>
                      <h1>No Data</h1>
                    </>
                  )}
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
        <ImagePreview visible={visible} setVisible={setVisible} image={ImageUrl + itemPicture} />
        <ItemImagePreviewStain
          stain={stainArray}
          visiblePreview={visiblePreview}
          setVisiblePreview={setVisiblePreview}
          image={stainImage}
        />
        {successModal ? <OrderComplete visible={successModal} setVisible={setSuccessModal} /> : ''}
        {notFoundModal ? (
          <OrderNotFound
            orderId={orderId}
            inputScannerRef={inputScannerRef}
            visible={notFoundModal}
            setVisible={setNotFoundModal}
          />
        ) : (
          ''
        )}
      </CContainer>
    </>
  )
}

export default BagList
