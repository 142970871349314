import React,{useState,useEffect} from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { get } from 'src/utils/apiManager';
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CCardSubtitle,
  CRow,
  CFormLabel,
  CImage,
  CButton,
  CFormCheck,
} from '@coreui/react'
import { ImageUrl } from 'src/config/config'
import { FaArrowLeft } from 'react-icons/fa'

function view() {
  const { id } = useParams()
  const [userData, setUserData] = useState([]);
  const [sidebarModuleData, setSidebarModuleData] = useState([]);

  const getSingleUserData= ()=>{

    get(`admin/subAdmin/single-user?_id=${atob(id)}`).then((data)=>{
      if(data?.data !== null ) {  
        const tempData= data?.data?.map((item,key)=>{
          setUserData(item)          
          setSidebarModuleData(item?.sidebarModule)          
        })
      }
    })
  }
    useEffect(() => {
      getSingleUserData();
    }, [id])
  return (
    <>
    <CContainer fluid>
      <CRow>
        <CCol>
          <CCardGroup>
            <CCard className="p-4">
              <CHeader>
                <CRow>
                  <CCol >
                    {' '}
                    <Link style={{ color: '#000' }} to={{ pathname: `/admin/sub-admin` }}>
                      <FaArrowLeft />
                    </Link>
                    <span> Sub Admin Information</span>
                  </CCol>
                </CRow>
              </CHeader>
              <CCardBody>
                <CRow>
                  <CCol md={2}>
                    <strong>Name :-</strong>
                  </CCol>
                  <CCol>
                    <span>{userData?.username}</span>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md={2}>
                    <strong>Email :-</strong>
                  </CCol>
                  <CCol>
                    <span>{userData?.email}</span>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md={2}>
                    <strong>Mobile :-</strong>
                  </CCol>
                  <CCol>
                    <span>{userData?.mobile}</span>
                  </CCol>
                </CRow>
                <CRow className='mb-4'>
                  <CCol md={2}>
                    <strong>Role :-</strong>
                  </CCol>
                  <CCol>
                  <CButton size="sm"style={{backgroundColor:'#d3dc40',border:'#d3dc40'}} outline={userData?.role ? 'Customer' : 'Sub-Admin'}>
                        <span>{userData?.role == 'customer' ? 'Customer' : 'Sub-Admin'}</span>
                      </CButton>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md={2}>
                    <strong>Status :-</strong>
                  </CCol>
                  <CCol>
                  <CButton size="sm" outline={userData?.status ? '1' : '0'}>
                        {userData?.status ? 'Active' : 'Deactive'}
                      </CButton>
                  </CCol>
                </CRow>
                {/* <CRow>
                <CCol md={2}>
                    <strong>Image:-</strong>
                  </CCol>
                  <CCol>
                  <div
                    style={{
                      marginTop: '10px',
                      justifyContent: 'center',
                    }}
                  >
                    <CImage
                      rounded
                      src={`${ImageUrl}${userData?.image}`}
                      style={{
                        maxHeight: '100px',
                        maxWidth: '100px',
                        borderRadius: 50,
                        alignItems: 'center',
                      }}
                    />
                  </div>
                  </CCol>
                </CRow> */}
                <CRow className="mt-4">
                              <CCol>
                                <div className="card">
                                  <div className="card-body">
                                    <strong>Modules Permission</strong>
                                    <hr />
                                    <CRow className="mt-2">
                                      <CCol>
                                        {sidebarModuleData?.map((value, key) => {
                                          return (
                                            <>
                                              <CRow key={key}>
                                                <CCol>
                                                <span>{value?.name}</span>
                                                </CCol>
                                                <CCol>
                                                  <CFormCheck
                                                    label="Add"
                                                    className="mt-2"
                                                    checked={value?.isAdd}
                                                    disabled={value?.isAdd ? false : true}              //to disable to click on checkbox
                                                  />
                                                </CCol>
                                                <CCol>
                                                  <CFormCheck
                                                    label="Edit"
                                                    className="mt-2"
                                                    checked={value?.isEdit}
                                                    disabled={value?.isEdit ? false : true}             //to disable to click on checkbox
                                                  />
                                                </CCol>
                                                <CCol>
                                                  <CFormCheck
                                                    label="Delete"
                                                    className="mt-2"
                                                    checked={value?.isDelete}
                                                    disabled={value?.isDelete ? false : true}           //to disable to click on checkbox
                                                  />
                                                </CCol>
                                                <CCol>
                                                  <CFormCheck
                                                    label="View"
                                                    className="mt-2"
                                                    checked={value?.isView}
                                                    disabled={value?.isView ? false : true}               //to disable to click on checkbox
                                                  />
                                                </CCol>
                                              </CRow>
                                              <hr />
                                            </>
                                          )
                                        })}
                                      </CCol>
                                    </CRow>
                                  </div>
                                </div>
                              </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </>
  )
}

export default view