import React, { useRef, useState } from 'react'
import { CCard, CCardBody, CCardGroup, CCol, CContainer, CRow } from '@coreui/react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Barcode from 'src/assets/images/barcode-scan.png'
import { get } from 'src/utils/apiManager'
import { toast } from 'react-toastify'



const ScanOrder = () => {

    const inputRef = useRef();
    const [qrValue, setQRValue] = useState('')
    const [orderId, setOrderId] = useState('')
    const navigate = useNavigate()


    const handleKeyPress = (event) => {
        // if(event.charCode )
        if (event.charCode === 13 && qrValue.length > 0) {
            //   const findOrderUniqueId = atob(qrValue.split('/')[1])
            //   console.log(findOrderUniqueId,"findOrderUniqueId")
            get(`/admin/offline-order/get-order-id/${qrValue}`).then((data) => {
                if (data.status) {
                    setOrderId(data.order)
                    if (data.order) {
                        navigate(`/admin/offline-bags/scan/${data.order}`)
                    }
                } else {
                    toast.error(data.message)
                }
            })
            // navigate(`/admin/online-user-list/${}`)
        }
    }

    return (
        <CContainer>
            <CCard>
                <CCardGroup>
                    <CCardBody>
                        <Link
                            className={'btn btn-success'}
                            style={{ background: '#d3dc40', border: '#d3dc40', float: "right" }}
                            to={"/admin/offline-order"}
                        >
                            Back
                        </Link>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '70vh',
                                // overflowY: 'scroll',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <img
                                src={Barcode}
                                alt="barcode" style={{ maxWidth: '200px' }}
                            />
                            <h4 className='mt-3'>Scan OR code for item</h4>
                            <input
                                autoFocus={true}
                                // disabled
                                // hidden
                                // style={{ border: 'none', outline: 'none', widht: '0', height: '0' }}
                                ref={inputRef}
                                name="qrValue"
                                id="qrValue"
                                value={qrValue}
                                onChange={(event) => setQRValue(event.target.value)}
                                onKeyPress={handleKeyPress}
                            />
                        </div>
                    </CCardBody>
                </CCardGroup>
            </CCard>
        </CContainer>
    )
}

export default ScanOrder