import React, { useRef, useState, useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigate, Link, useParams } from 'react-router-dom'
import { put, get } from 'src/utils/apiManager';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CFormLabel,
  CRow,
} from '@coreui/react';
import Select from 'react-select'
import { DefaultInput } from 'src/components/common/input'
import { optionValidation } from 'src/utils/validator'
import CIcon from '@coreui/icons-react'
import { cilPlus, cilMinus } from '@coreui/icons'

function EditOption() {

  const navigate = useNavigate()
  const { id } = useParams()
  const formRef = useRef(null)
  const [isDisable, setIsDisable] = useState(true)
  const [optionData, setOptionData] = useState(null);

  // const [attributeList, setAttributeList] = useState();
  // const [selectedAttribute, setSelectedAttribute] = useState(null)
  // const AttributeOptions = []

  const [optionInputList, setOptionInputList] = useState([{
    optionName_EN: '',
    optionName_TH: ''
  }])

  // const getAttributeList = () => {
  //   get(
  //     `/admin/option/get-attribute`
  //   ).then((res) => {
  //     if (res?.status) {
  //       setAttributeList(res?.data)
  //     } else {
  //       toast.error(res?.message)
  //     }
  //   })
  // };

  // useEffect(() => {
  //   getAttributeList();
  // }, [])

  // if (attributeList != null) {
  //   attributeList?.map((item, key) => {
  //     AttributeOptions?.push({ label: item?.attributeName_EN, value: item?._id })
  //   })
  // }

  const getOptionDetails = () => {
    get(
      `/admin/option/get-single?_id=${atob(id)}`
    ).then((response) => {
      if (response?.status) {
        setOptionData(response?.data)
      }
    })

  }
  useEffect(() => {
    getOptionDetails();
  }, []);

  useEffect(() => {
    formRef.current.setFieldValue('attributeName_EN', optionData?.attributeName_EN)
    formRef.current.setFieldValue('attributeName_TH', optionData?.attributeName_TH)
    setOptionInputList(optionData?.option)
  }, [optionData]);

  const handleOnChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...optionInputList];
    list[index][name] = value;
    setOptionInputList(list)
  }

  const handleIncreaseField = (e) => {
    e.preventDefault()
    setOptionInputList([...optionInputList, {
      optionName_EN: '',
      optionName_TH: ''
    }])
  }

  const handleRemoveField = (e, index) => {
    e.preventDefault()
    const list = [...optionInputList]
    list.splice(index, 1);
    setOptionInputList(list)
  }

  const submit = async (values) => {
    setIsDisable(false)
    let list = optionInputList?.map((item, i) => {
      if (item?.optionName_EN == "" || item?.optionName_TH == "") {
        toast.error("Fill all the selected fields or delete them")
        return 1
      }
    })

    if (list.every(v => v === list[0])) {
      let newOptionData = [];
      optionInputList?.map((item, k) => {
        newOptionData?.push({ optionName_EN: item?.optionName_EN, optionName_TH: item?.optionName_TH })
      })
      const temp = {
        // attributeId: values?.attributeName,
        attributeName_EN: values?.attributeName_EN,
        attributeName_TH: values?.attributeName_TH,
        option: optionInputList
      }
      put(
        `/admin/option/update-option?_id=${atob(id)}`, temp
      ).then((response) => {
        if (response?.status) {
          toast.success(response?.message)
          navigate('/admin/option')
        } else {
          toast.error(response?.message)
          setIsDisable(true)
        }
      })
    } else {
      return
    }
  }

  return (
    <>
      <CContainer >
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <CRow>
                        <CCol md="11">
                          <h1>Edit Option</h1>
                        </CCol>
                        <CCol md="1">
                          <Link className={'btn btn-success mt-2'} style={{ background: '#d3dc40', border: '#d3dc40' }} to={{ pathname: `/admin/option` }}>
                            Back
                          </Link>
                        </CCol>
                      </CRow>
                      <Formik
                        initialValues={{
                          // attributeName: '',
                          attributeName_EN: '',
                          attributeName_TH: '',
                          optionName_EN: '',
                          optionName_TH: '',
                        }}
                        validationSchema={optionValidation}
                        onSubmit={(values, { setSubmitting }) => {
                          submit(values)
                          setSubmitting(false)
                        }}
                        innerRef={formRef}
                      >
                        {(props) => (
                          <form onSubmit={props.handleSubmit}>
                            {/* <CRow>
                              <CCol md={5} >
                              <CFormLabel>Attribute Name</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>
                                <Select
                                  defaultValue={selectedAttribute}
                                  onChange={(val) => {
                                    setSelectedAttribute(val)
                                    props?.setFieldValue('attributeName', val.value)
                                  }}
                                  options={AttributeOptions}
                                  value={selectedAttribute}
                                  placeholder={'Select Attribute Name'}
                                  name="Attribute Name"
                                />
                                {selectedAttribute == null ? (
                                  <span className="text-danger">{props?.errors?.attributeName}</span>
                                ) : (
                                  ''
                                )}
                              </CCol>
                            </CRow> */}

                            <CRow>
                              <CCol md={5} >
                                <CFormLabel>Brand Name (EN)</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={"text"}
                                  placeholder={"Brand Name (EN)"}
                                  value={props?.values?.attributeName_EN}
                                  onChange={props?.handleChange("attributeName_EN")}
                                  onBlur={() => props?.setFieldTouched("attributeName_EN", true)}
                                  error={props?.touched?.attributeName_EN && props?.errors?.attributeName_EN}
                                  onKeyUp={() => {
                                    if (optionData?.attributeName_EN == props?.values?.attributeName_EN) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />

                              </CCol>
                              <CCol md="5">
                                <CFormLabel>Brand Name (TH)</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={"text"}
                                  placeholder={"Brand Name (TH)"}
                                  value={props?.values?.attributeName_TH}
                                  onChange={props?.handleChange("attributeName_TH")}
                                  onBlur={() => props?.setFieldTouched("attributeName_TH", true)}
                                  error={props?.touched?.attributeName_TH && props?.errors?.attributeName_TH}
                                  onKeyUp={() => {
                                    if (optionData?.attributeName_TH == props?.values?.attributeName_TH) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                              </CCol>
                            </CRow>

                            {optionInputList?.map((data, index) => {
                              return (
                                <CRow>
                                  <CCol md="5">
                                    <CFormLabel>Option Name (EN)</CFormLabel>
                                    <span className="text-danger" style={{ marginLeft: '5px' }}>*</span>
                                    <DefaultInput
                                      type={"text"}
                                      name={"optionName_EN"}
                                      placeholder={"Option Name (EN)"}
                                      value={data?.optionName_EN}
                                      onChange={(e) => handleOnChange(e, index)}
                                      onBlur={() => props?.setFieldTouched("optionName_EN", true)}
                                    />
                                    {data?.optionName_EN == '' && props?.touched?.optionName_EN ? (
                                      <span className="text-danger">{"Option is required"}</span>
                                    ) : (
                                      ''
                                    )}
                                  </CCol>
                                  <CCol md="5">
                                    <CFormLabel>Option Name (TH)</CFormLabel>
                                    <span className="text-danger" style={{ marginLeft: '5px' }}>*</span>
                                    <DefaultInput
                                      type={"text"}
                                      name={"optionName_TH"}
                                      placeholder={"Option Name (TH)"}
                                      value={data?.optionName_TH}
                                      onChange={(e) => handleOnChange(e, index)}
                                      onBlur={() => props?.setFieldTouched("optionName_TH", true)}
                                    />
                                    {data?.optionName_TH == '' && props?.touched?.optionName_TH ? (
                                      <span className="text-danger">{"Option is required"}</span>
                                    ) : (
                                      ''
                                    )}
                                  </CCol>
                                  {optionInputList?.length !== 1 &&
                                    <CCol md="1" style={{ paddingTop: '23px' }}>
                                      <button
                                        type="button"
                                        className={'btn btn-primary mt-2'}
                                        onClick={(e) => handleRemoveField(e, index)}
                                      >
                                        <CIcon icon={cilMinus} />
                                      </button>
                                    </CCol>}
                                  {optionInputList?.length - 1 === index &&
                                    <CCol md="1" style={{ paddingTop: '23px' }}>
                                      <button
                                        type="button"
                                        className={'btn btn-primary mt-2'}
                                        onClick={(e) => handleIncreaseField(e)}
                                      >
                                        <CIcon icon={cilPlus} />
                                      </button>
                                    </CCol>}
                                </CRow>
                              )
                            })}

                            <CRow>
                              <CCol md="2">
                                <button
                                  type="submit"
                                  className={'btn btn-success mt-2'}
                                  disabled={!isDisable || props?.isSubmitting}
                                >
                                  Submit
                                </button>
                              </CCol>
                              <CCol md="2">
                                <button
                                  type="button"
                                  className={'btn btn-warning mt-2'}
                                  onClick={() => navigate('/admin/option')}
                                >
                                  Cancel
                                </button>
                              </CCol>
                              <CCol md="3"></CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default EditOption


