import { CCard, CCardBody, CCardGroup, CCol, CContainer, CFormLabel, CRow } from '@coreui/react'
import { Formik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { DefaultInput, DefaultMobileInput, DefaultTextarea } from 'src/components/common/input'
import { baseURL } from 'src/config/config'
import { post, get } from 'src/utils/apiManager'
import 'react-toastify/dist/ReactToastify.css'
import Select from 'react-select'

function add() {
  const navigate = useNavigate()
  const formRef = useRef(null)
  const fileRef = useRef(null)
  const [invalidImage, setInvalidImage] = useState('')
  const [userImagePreview, setUserImagePreview] = useState()
  const [passVisibility, setPassVisibility] = useState(false)
  const [passwordType, setPasswordType] = useState('password')
  // const [stateOptions, setStateOptions] = useState([]);
  const [stateID, setStateID] = useState(null)
  const [cityID, setCityID] = useState(null)
  const [areaOptions, setAreaOptions] = useState([])
  const [areaList, setAreaList] = useState(null)
  const [selectService, setSelectService] = useState(null)
  const [addOnServiceList, setAddOnServiceList] = useState(null)
  const addOnServiceOptions = []
  const [imageFile, setImageFile] = useState(null)

  useEffect(() => {
    fetchAddOnServices()
    fetchStateID()
    if (stateID != null) {
      fetchCityList()
    }
    if (cityID != null) {
      fetchAreaList()
    }
  }, [stateID, cityID])

  useEffect(() => {
    if (passVisibility) {
      setPasswordType('text')
    } else {
      setPasswordType('password')
    }
  }, [passVisibility])
  //get the area
  const fetchStateID = () => {
    get(`/admin/address/state-list`).then((res) => {
      if (res?.data !== null) {
        res?.data?.map((item, key) => {
          setStateID(item?._id)
        })
      }
    })
  }
  const fetchCityList = () => {
    const data = {
      state_id: stateID,
    }
    post(`/admin/address/city-list`, data).then((res) => {
      if (res?.data !== null) {
        res?.data?.map((item, key) => {
          setCityID(item?._id)
        })
      }
    })
  }
  const fetchAreaList = () => {
    const data = { city_id: cityID }
    post(`/admin/address/area-list`, data).then((res) => {
      if (res?.data !== null) {
        const tempData = res?.data?.map((item, key) => {
          areaOptions?.push({ label: item?.areaName_EN, value: item?._id })
          return item
        })
        setAreaList(tempData)
      }
    })
  }
  //get the area
  //get the add on services data
  const fetchAddOnServices = () => {
    get(`/admin/offine-order/addOnService-list`).then((data) => {
      // console.log(data?.data, "data");
      setAddOnServiceList(data?.data)
    })
  }
  if (addOnServiceList !== null) {
    addOnServiceList?.map((item, key) => {
      addOnServiceOptions?.push({ label: item?.serviceName_EN, value: item?._id })
    })
  }
  // console.log(addOnServiceList, "get the all add on services");
  const submit = async (values) => {
    // console.log()
    // console.log(values, "valuessssss", areaList?.value, addOnServiceList);
    // var SERVICE = []
    // values?.addOnServiceId?.forEach((item, key) => {
    //   SERVICE?.push(item?.value)
    // })
    // console.log(SERVICE, "fsyfsfsfy");
    let formData = new FormData()
    formData.append('username', values.username)
    formData.append('email', values.email)
    formData.append('image', imageFile)
    formData.append('password', values.password)
    formData.append('mobile', values.mobile)
    formData.append('areaName', areaList?.value)
    formData.append('address', values.address)
    // formData.append('addOnServiceId', JSON.stringify(addOnServiceList));
    // formData.append('addOnServiceId', SERVICE)
    // formData.append('noOfPacket', values.noOfPacket)
    post(`admin/offline-order/store`, formData, 1).then((data) => {
      handleResponse(data)
    })
  }
  const handleResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      navigate('/admin/offline-order')

      // setTimeout(() => {
      //   navigate('/admin/offline-order')
      // }, 500)
    } else {
      toast.error(data?.message)
    }
    // formRef.current.resetForm()
    // fileRef.current.value = null
    // setUserImagePreview('')
  }
  return (
    <>
      <CContainer>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <CRow>
                        <CCol md="11">
                          <h1>New User</h1>
                        </CCol>
                        <CCol md="1">
                          <Link
                            className={'btn btn-success mt-2'}
                            style={{ background: '#d3dc40', border: '#d3dc40' }}
                            to={{ pathname: `/admin/offline-order` }}
                          >
                            Back
                          </Link>
                        </CCol>
                      </CRow>
                      <Formik
                        initialValues={{
                          username: '',
                          email: '',
                          image: '',
                          mobile: '',
                          password: '',
                          //store in address coll
                          areaName: '',
                          address: '',
                          //store in place order
                          // addOnServiceId: '',
                          // noOfPacket: '',
                        }}
                        // validationSchema={signUp}
                        onSubmit={(values, { setSubmitting }) => {
                          submit(values)
                          setSubmitting(false)
                        }}
                        innerRef={formRef}
                      >
                        {(props) => (
                          <form onSubmit={props?.handleSubmit}>
                            {/* {console.log(props)} */}
                            <CRow>
                              <CCol md={6}>
                                <CFormLabel>Name</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Name'}
                                  id={'username'}
                                  maxLength="70"
                                  value={props?.values?.username}
                                  onChange={props?.handleChange('username')}
                                  onBlur={() => props?.setFieldTouched('username', true)}
                                  error={props?.touched?.username && props?.errors?.username}
                                />
                              </CCol>
                              <CCol md={6}>
                                <CFormLabel>Email</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>
                                <DefaultInput
                                  type={'email'}
                                  placeholder={'Email'}
                                  id={'email'}
                                  value={props?.values?.email}
                                  // value={this.state.name}
                                  onChange={props?.handleChange('email')}
                                  onBlur={() => props?.setFieldTouched('email', true)}
                                  error={props?.touched?.email && props?.errors?.email}
                                />
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol md={6}>
                                <DefaultMobileInput
                                  type={'text'}
                                  placeholder={'Mobile'}
                                  name={'Mobile'}
                                  id={'mobile'}
                                  minLength={7}
                                  maxLength={15}
                                  value={props?.values?.mobile}
                                  onChange={(val) => props?.setFieldValue('mobile', val || '')}
                                  onBlur={() => props?.setFieldTouched('mobile', true)}
                                  error={props?.touched?.mobile && props?.errors?.mobile}
                                />
                              </CCol>
                              <CCol md={6}>
                                <CFormLabel>Password</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>

                                <div className="input-group">
                                  <input
                                    type={passwordType}
                                    autoComplete="off"
                                    className="form-control"
                                    placeholder={'Current Password'}
                                    id={'password'}
                                    value={props?.values?.password}
                                    onChange={props?.handleChange('password')}
                                    onBlur={() => props?.setFieldTouched('password', true)}
                                  />
                                  <button
                                    className="btn btn-outline-secondary"
                                    type="button"
                                    onClick={() => setPassVisibility(!passVisibility)}
                                    style={{ backgroundColor: '#d8dbe0' }}
                                  >
                                    {passwordType == 'password' ? (
                                      <i
                                        style={{ color: '#2C384A' }}
                                        className="fa-solid fa-eye-slash"
                                      ></i>
                                    ) : (
                                      <i
                                        style={{ color: '#2C384A' }}
                                        className="fa-solid fa-eye"
                                      ></i>
                                    )}
                                  </button>
                                </div>
                                {props?.touched?.password && props?.errors?.password ? (
                                  <ErrorText title={props?.errors?.password} />
                                ) : null}
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol md={6}>
                                <CFormLabel>Image</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>
                                <DefaultInput
                                  type={'file'}
                                  placeholder={'Image'}
                                  // MIN_FILE_SIZE={1024}
                                  // MAX_FILE_SIZE={5120}
                                  title={'1024 X 1024'}
                                  size={5}
                                  // file={props?.values?.image}
                                  // accept="image/*"
                                  accept="image/png, image/gif, image/jpeg, image/jpg"
                                  // refr={fileRef}
                                  onChange={(e) => {
                                    setImageFile(e?.target?.files[0])
                                  }}
                                  // if (!imageFile?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
                                  //     setInvalidImage("Please select valid image")
                                  // }
                                  //     else {
                                  //         setInvalidImage('')
                                  //     }
                                  //     //for image preview
                                  //     const reader = new FileReader();
                                  //     reader?.addEventListener("load", () => setUserImagePreview(reader?.result));
                                  //     reader?.readAsDataURL(e?.target?.files[0]);
                                  //     //for image preview
                                  //     props?.setFieldValue('image', e?.target?.files[0])
                                  //     // console.log(imageFile, "imageFile")
                                  // }}
                                  // onBlur={() => props?.setFieldTouched('image', true)}
                                  // error={(props?.touched?.image && props?.errors?.image) || invalidImage}
                                />
                                {/* for image preview */}
                                {/* {userImagePreview?.length > 0 ?
                                                                    <>
                                                                        <CCol>
                                                                            <CImage
                                                                                rounded
                                                                                thumbnail
                                                                                className="mt-2"
                                                                                src={`${userImagePreview}`}
                                                                                style={{
                                                                                    maxHeight: '80px',
                                                                                    maxWidth: '80px',
                                                                                    alignItems: 'center',
                                                                                }}
                                                                            />
                                                                        </CCol>
                                                                    </>
                                                                    : ''} */}
                                {/* for image preview */}
                              </CCol>
                              <CCol md={6}>
                                <CFormLabel>Area Name</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>
                                <Select
                                  // isMulti
                                  // className='mt-2'
                                  defaultValue={areaList}
                                  onChange={(val) => {
                                    setAreaList(val)
                                    props?.setFieldValue('areaName', val?.value)
                                    // props?.setFieldValue("areaName", val)
                                  }}
                                  options={areaOptions}
                                  placeholder={'Select Area Name'}
                                  // value={areaList}
                                  name="Area Name"
                                />
                                {areaList == undefined ? (
                                  <span className="text-danger">{props?.errors?.areaName}</span>
                                ) : (
                                  ''
                                )}
                                <br />
                                <DefaultTextarea
                                  placeholder={'Address'}
                                  name={'address'}
                                  id={'address'}
                                  maxLength={50}
                                  value={props?.values?.address}
                                  onChange={props?.handleChange('address')}
                                  onBlur={() => props?.setFieldTouched('address', true)}
                                  error={props?.touched?.address && props?.errors?.address}
                                />
                              </CCol>
                            </CRow>
                            {/* <hr />
                                                        <CRow>
                                                            <CCol>
                                                                <CFormLabel>Add on Services</CFormLabel>
                                                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                                                <Select
                                                                    isMulti
                                                                    // className='mt-2'
                                                                    defaultValue={selectService}
                                                                    onChange={(val) => {
                                                                        setSelectService(val)
                                                                        props?.setFieldValue("addOnServiceId", val)
                                                                        // props?.setFieldValue("areaName", val)
                                                                    }}
                                                                    options={addOnServiceOptions}
                                                                    placeholder={"Select Add on Service"}
                                                                    value={selectService}
                                                                    name="Area Name" />
                                                                {selectService == undefined ? <span className='text-danger'>{props?.errors?.addOnServiceId}</span> : ''}
                                                                <br />
                                                            </CCol>
                                                            <CCol>
                                                                <CFormLabel>No of Packets</CFormLabel>
                                                                <span className="text-danger" style={{ marginLeft: '5px' }}>*</span>
                                                                <DefaultInput
                                                                    type={'text'}
                                                                    placeholder={'No Of Packet'}
                                                                    id={'noOfPacket'}
                                                                    maxLength="70"
                                                                    value={props?.values?.noOfPacket}
                                                                    onChange={props?.handleChange('noOfPacket')}
                                                                    onBlur={() => props?.setFieldTouched('noOfPacket', true)}
                                                                    error={props?.touched?.noOfPacket && props?.errors?.noOfPacket}
                                                                />
                                                            </CCol>
                                                        </CRow> */}

                            <CRow>
                              <CCol md="2">
                                <button
                                  type="submit"
                                  className={'btn btn-success mt-2'}
                                  // disabled={props?.isSubmitting}
                                >
                                  Submit
                                </button>
                              </CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default add
