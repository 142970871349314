import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { post, get } from '../../utils/apiManager'
import CIcon from '@coreui/icons-react'
import { cilCloudDownload } from '@coreui/icons'
import {
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CHeader,
    CCardSubtitle,
    CTable,
    CRow,
    CTableHead,
    CTableRow,
    CTableHeaderCell,
    CTableBody,
    CTableDataCell,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import Pagination from 'react-js-pagination'
import moment from 'moment'
import { DefaultInputWithIcon } from 'src/components/common/input';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

function CouponUsedReport() {
    const [userData, setUserData] = useState([]);
    const [totalPages, settotalPages] = useState(0);
    const [searchItem, setsearchItem] = useState('')
    const [fromDate, setFromDate] = useState(moment().subtract(1, 'year'))
    const [toDate, setToDate] = useState(moment())
    const [toMinimum, setToMinimum] = useState(moment().subtract(1, 'year'));
    const [fromMax, setFromMax] = useState(null);
    const [pageData, setpageData] = useState({
        page: 1,
        perPage: 10,
        searchItem: "",
    });

    const from_date = (moment(fromDate)).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
    const to_date = (moment(toDate)).format("YYYY-MM-DDTHH:mm:ss[Z]")

    useEffect(() => {
        // getData();
    }, [pageData, fromDate, toDate]);

    const getData = () => {
        // const to_date = (moment(toDate)).format("YYYY-MM-DDTHH:mm:ssZ")
        get(
            `admin/User/user-list?page=${pageData?.page || 1}&perPage=${pageData?.perPage || 10}&searchItem=${pageData?.searchItem || ''
            }&from_date=${from_date}&to_date=${to_date}`,
        ).then(
            (response) => handleResponse(response),
        )
    }
    const handleResponse = (response) => {
        if (!response?.status) toast.success(response?.message, response?.status)
        else {
            setUserData([...response?.data])
            settotalPages(response?.pages)
        }
    }

    const downloadUsersData = () => {
        const temp = {
            to_date: to_date,
            from_date: from_date,
            queryData: pageData
        }
        post(
            `/admin/report/users-csv-download`, temp
        ).then((res) => {
            if (res?.status) {
                const linkurl = res?.url
                const link = document.createElement("a");
                link.download = linkurl;
                link.href = linkurl;
                link.click();
                toast.success("CSV Downloaded Successfully")
            }
        })
    }

    const onSearchCLick = () => {
        setpageData({
            ...pageData,
            searchItem: searchItem,
        })
    }

    const changePage = (index) => {
        setpageData({
            ...pageData,
            page: index,
        });
    };

    const handleDateChange = (event, type) => {
        if (type == 'fromDate') {
            setFromDate(event?.$d)
            setToMinimum(event?.$d)
        } else {
            setToDate(event?.$d)
            setFromMax(event?.$d)
        }
    }


    return (
        <>
            <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
            <CContainer fluid>
                <CRow>
                    <CCol>
                        <CCardGroup>
                            <CCard className="p-4">
                                <CHeader>
                                    <CCardSubtitle as="h4">Coupons Used Report</CCardSubtitle>
                                    <CRow align={'center'}>
                                        <CCol md="3">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DesktopDatePicker
                                                    inputFormat="DD/MM/YYYY"
                                                    name="fromDate"
                                                    value={fromDate}
                                                    maxDate={fromMax == null ? moment() : fromMax}
                                                    onChange={(e) => {
                                                        handleDateChange(e, 'fromDate')
                                                    }}
                                                    renderInput={(params) => <TextField style={{ marginTop: '5px' }} name="fromDate" {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </CCol>
                                        <CCol md="3">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DesktopDatePicker
                                                    inputFormat="DD/MM/YYYY"
                                                    name="toDate"
                                                    value={toDate}
                                                    minDate={toMinimum}
                                                    maxDate={moment()}
                                                    onChange={(e) => {
                                                        handleDateChange(e, 'toDate')
                                                    }}
                                                    renderInput={(params) => <TextField style={{ marginTop: '5px' }} name="fromDate" {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </CCol>
                                        <CCol md="3">
                                            <DefaultInputWithIcon
                                                type={'text'}
                                                placeholder="Search"
                                                customType={'search'}
                                                value={searchItem}
                                                onClick={onSearchCLick}
                                                onChange={(e) => {
                                                    onSearchCLick()
                                                    setsearchItem(e?.target?.value)
                                                }}
                                            />
                                        </CCol>
                                        {/* <CCol md="2"></CCol> */}
                                        <CCol md="3" align={'right'}>
                                            <Link onClick={() => downloadUsersData()} className="btn btn-dark">
                                                <CIcon icon={cilCloudDownload} />
                                                <span style={{ marginLeft: '2px' }}>Export CSV</span>
                                            </Link>
                                        </CCol>
                                    </CRow>
                                </CHeader>

                                <CCardBody>
                                    <CTable bordered hover responsive>
                                        <CTableHead>
                                            <CTableRow>
                                                <CTableHeaderCell>S. No</CTableHeaderCell>
                                                <CTableHeaderCell>Coupon Name</CTableHeaderCell>
                                                <CTableHeaderCell>No. of counts coupon used</CTableHeaderCell>
                                                {/* <CTableHeaderCell>Contact No.</CTableHeaderCell>
                                                <CTableHeaderCell>User Created Date</CTableHeaderCell> */}
                                            </CTableRow>
                                        </CTableHead>
                                        <CTableBody>
                                            {/* {userData?.map((ele, index) => {
                                                return (
                                                    <CTableRow key={index}>
                                                        <CTableDataCell>{pageData?.perPage * (pageData?.page - 1) + index + 1}</CTableDataCell>
                                                        <CTableDataCell ><img src={ele?.image} style={{ width: '40px' }} /></CTableDataCell>
                                                        <CTableDataCell>{ele?.username}</CTableDataCell>
                                                        <CTableDataCell>{ele?.email}</CTableDataCell>
                                                        <CTableDataCell>{ele?.mobile}</CTableDataCell>
                                                        <CTableDataCell>{moment(ele?.createdAt).format('DD MMMM YYYY h:mm:ss a')}</CTableDataCell>
                                                    </CTableRow>
                                                );
                                            })} */}
                                        </CTableBody>
                                    </CTable>
                                    {!userData?.length && (
                                        <>
                                            <h1>No Data</h1>
                                        </>
                                    )}
                                    {userData?.length ? <Pagination
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        activePage={pageData?.page}
                                        itemsCountPerPage={pageData?.perPage}
                                        totalItemsCount={totalPages * 10}
                                        pageRangeDisplayed={5}
                                        onChange={changePage}
                                    /> : ''}
                                </CCardBody>
                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </>
    )
}

export default CouponUsedReport