import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { get, post, deleteApi } from '../../utils/apiManager'
import CIcon from '@coreui/icons-react'
import { cilPen, cilPlus, cilTrash } from '@coreui/icons'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CCardSubtitle,
  CTable,
  CRow,
  CImage,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CButton,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import { DefaultInputWithIcon } from 'src/components/common/input'
import Pagination from 'react-js-pagination'
import { FaRegEye, FaEdit, FaTrash, FaTrashAlt } from 'react-icons/fa'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
// import Lottie from 'lottie-react'
// import groovyWalkAnimation from '../orderStatus/'

function AttributeList() {
  const navigate = useNavigate()
  const [attribute, setAttributeData] = useState([])
  // const [attributesList, setAttributesList] = useState(true)
  const [totalPages, settotalPages] = useState(0)
  const [pageData, setpageData] = useState({
    page: 1,
    perPage: 10,
    searchItem: '',
  })
  const [searchItem, setsearchItem] = useState('')
  const getAttributeData = () => {
    get(
      `/admin/attribute/getList?page=${pageData?.page || 1}&perPage=${pageData?.perPage || 10}&searchItem=${pageData?.searchItem || ''
      }`,
    ).then(
      (response) => {
      //   setTimeout(() => {
      //     setAllOrders(false);
      // }, 1500);
        setAttributeData(response?.data)
        settotalPages(response?.pages)
      }
    )
  }

  const changePage = (index) => {
    setpageData({
      ...pageData,
      page: index,
    })
  }
  const onSearchCLick = () => {
    setpageData({
      ...pageData,
      searchItem: searchItem,
    })
  }
 
  const onClickAttributeEdit = async (value) => {
    navigate(`/admin/attribute/edit/${btoa(value?._id)}`, { state: value })
  }
  const onClickAttributeView = (value) => {
    navigate(`/admin/attribute/view/${btoa(value?._id)}`, { state: value })
  }

  const deleteData = (id) => {
    if (confirm("Are you sure")) {
      let formData = {
        _id: id,
      };
      deleteApi(`/admin/attribute/delete?_id=${id}`).then((data) => {
        handleDelete(data);
      });
    }
  };
  const handleDelete = (data) => {
    toast.success(data?.message, data?.status);
    getAttributeData();
  };
  
  useEffect(() => {
    getAttributeData()
  }, [pageData]);

  return (
    <>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <CCardSubtitle as="h4">Attribute</CCardSubtitle>
                  <CRow>
                    <CCol md="8">
                      <DefaultInputWithIcon
                        type={'text'}
                        placeholder="Search"
                        customType={'search'}
                        value={searchItem}
                        onClick={onSearchCLick}
                        onChange={(e) => {
                          onSearchCLick()
                          setsearchItem(e?.target?.value)
                        }}
                      />
                    </CCol>
                    {/* <CCol md="2"></CCol> */}
                    <CCol md="4">
                      <Link to={{ pathname: '/admin/attribute/add' }} className="btn btn-dark">
                        <CIcon icon={cilPlus} />Add
                      </Link>
                    </CCol>
                  </CRow>
                </CHeader>
                {/* {attributesList !== true ? */}
                <CCardBody>
                  <CTable bordered hover responsive>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell>S. No</CTableHeaderCell>
                        <CTableHeaderCell>Attribute Name(EN)</CTableHeaderCell>
                        <CTableHeaderCell>Attribute Name(TH)</CTableHeaderCell>
                        <CTableHeaderCell>Action</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {attribute &&
                        attribute?.map((data, key) => {
                          return (
                            <CTableRow key={key}>
                              <CTableDataCell>
                                {pageData?.perPage * (pageData?.page - 1) + key + 1}
                              </CTableDataCell>
                              <CTableDataCell>{data?.attributeName_EN}</CTableDataCell>
                              <CTableDataCell>{data?.attributeName_TH}</CTableDataCell>
                              <CTableDataCell>
                                <span className="mx-2" onClick={() => onClickAttributeEdit(data)} style={{ fontSize: '20px', color: '#d3dc40', cursor: 'pointer' }}><FaEdit /></span>
                                <span className="mx-2 text-danger" onClick={() => deleteData(data?._id)} style={{ fontSize: '20px', padding: '5px', cursor: 'pointer' }}><FaTrash /></span>
                                <span className="mx-2" onClick={() => onClickAttributeView(data)} style={{ fontSize: '20px', color: '#d3dc40', padding: '5px', cursor: 'pointer' }}><FaRegEye /></span>
                              </CTableDataCell>
                            </CTableRow>
                          )
                        })}
                    </CTableBody>
                  </CTable>
                  {!attribute?.length && (
                    <>
                      <h1>NO Data</h1>
                    </>
                  )}

                  {attribute?.length ? <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={pageData?.page}
                    itemsCountPerPage={pageData?.perPage}
                    totalItemsCount={totalPages * 10}
                    pageRangeDisplayed={5}
                    onChange={changePage}
                  /> : ''}
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>

    </>
  )
}

export default AttributeList

