import React, { useEffect, useState } from 'react'
import { CButton, CCard, CCardBody, CCol, CCardHeader, CRow, CHeader, CCardSubtitle, CTable, CTableHead, CTableRow, CTableHeaderCell, CTableBody, CTableDataCell } from '@coreui/react'
import {
  CChart,
  CChartBar,
  CChartDoughnut,
  CChartLine,
  CChartPie,
  CChartPolarArea,
  CChartRadar,
} from '@coreui/react-chartjs'
import { DocsCallout } from 'src/components'
import CIcon from '@coreui/icons-react'
import { cilCloudDownload } from '@coreui/icons'
import { get } from 'src/utils/apiManager'
import { height, style } from '@mui/system'
import {
  FaCartArrowDown,
  FaTruck,
  FaTruckPickup,
  FaUserCog,
  FaUserFriends,
  FaUsers,
} from 'react-icons/fa'

const Charts = ({ filterTrigger, ranges,  homeData }) => {
  console.log("🚀 ~ Charts ~ homeData:", homeData)
  const random = () => Math.round(Math.random() * 100)
  let colorArr = []
  var color = '#'

  // var randomColor = Math.floor(Math.random()*16777215).toString(16);
  var randomColor = () => {
    return (color += Math.floor(Math.random() * 16777215).toString(16))
  }
  // const randomColor = () => {
  //   var color = '#';
  //   for (var i = 0; i < 6; i++) {
  //     color += Math.floor(Math.random() * 16);
  //   }
  //   console.log(color);
  //   colorArr.push(color)
  //   // return color;
  // }

  //  console.log('randomColor', randomColor())

  const [customerData, setCustomerData] = useState()
  const [driverData, setDriverData] = useState()
  const [vanData, setVanData] = useState([])
  const [subAdmin, setSubAdminData] = useState()
  const [PackageOrderData, setPackageOrderData] = useState()
  const [regularOrderData, setRegularOrderData] = useState()
  const [chartLabel, setChartLabel] = useState()

  const getData = () => {
    const role = 'customer,driver,subAdmin,van'
    let url = `admin/dashboard/charts?role=${role}`
    if (ranges?.startDate && ranges?.endDate) {
      url = `admin/dashboard/charts?role=${role}&start_date=${formatDateToYMD(ranges?.startDate)}&end_date=${formatDateToYMD(ranges?.endDate)}`
    }
    get(url).then((res) => {
      console.log(res?.data, 'calling chart')
      setCustomerData(res?.data?.customerData[0]?.data ? Object.values(res?.data?.customerData[0]?.data) : [])
      setDriverData(res?.data?.driverData[0]?.data ? Object.values(res?.data?.driverData[0]?.data) : [])
      setVanData(res?.data?.vanData[0]?.data ? Object.values(res?.data?.vanData[0]?.data) : [])
      setSubAdminData(res?.data?.subAdminData[0]?.data ? Object.values(res?.data?.subAdminData[0]?.data) : [])
      setRegularOrderData(res?.data?.RegularOrders[0]?.data ? Object.values(res?.data?.RegularOrders[0]?.data) : [])
      setPackageOrderData(res?.data?.PackageOrders[0]?.data ? Object.values(res?.data?.PackageOrders[0]?.data) : [])
      setChartLabel(res?.data?.customerData[0]?.data ? Object.keys(res?.data?.customerData[0]?.data) : [])
    })
  }

  function formatDateToYMD(date) {
    if (!date) return
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(date.getDate()).padStart(2, '0'); // Pad single-digit days with leading zero
    return `${year}-${month}-${day}`;
  }


  useEffect(() => {
    getData()
  }, [filterTrigger])
  // for(let i=0; i<12; i++){

  //   randomColor();
  // }

  return (
    <>
      <CRow>
        <CCol xs={12}>
          {/* <span>hiiii new user graph </span> */}
          <CCard className="mb-4">
            <CCardBody>
              <CRow>
                <CCol sm={5}>
                  {/* <span>cilCart</span> */}

                  <h4 id="traffic" className="card-title mb-0">
                    <FaCartArrowDown style={{ color: '#d3dc40' }} /> 
                  </h4>
                  {/* <div className="small text-medium-emphasis">January - July 2021</div> */}
                </CCol>
                <CCol sm={7} className="d-none d-md-block">
                  {/* <CButton color="primary" className="float-end"><CIcon icon={cilCloudDownload} /></CButton> */}
                </CCol>
              </CRow>
              <CChartLine
                height={60}
                data={{
                  labels: chartLabel,
                  datasets: [
                    {
                      label: 'Package',
                      backgroundColor: 'rgba(210,220,35, 0.2)',
                      borderColor: 'rgba(210,220,35, 1)',
                      pointBackgroundColor: 'rgba(210,220,35, 1)',
                      // backgroundColor: 'rgba(151, 187, 205, 0.2)',
                      // borderColor: 'rgba(151, 187, 205, 1)',
                      // pointBackgroundColor: 'rgba(151, 187, 205, 1)',
                      pointBorderColor: '#fff',
                      data: PackageOrderData,
                    },
                    {
                      label: 'Regular',
                      backgroundColor: 'rgba(30, 136, 229, 0.1)',
                      borderColor: 'rgba(30, 136, 229, 0.5)',
                      pointBackgroundColor: 'rgba(30, 136, 229, 0.5)',
                      // backgroundColor: "rgba(220, 220, 220, 0.2)",
                      // borderColor: "rgba(220, 220, 220, 1)",
                      // pointBackgroundColor: "rgba(220, 220, 220, 1)",
                      pointBorderColor: '#fff',
                      data: regularOrderData,
                    },
                  ],
                }}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CCol xs={12}>
          {/* <span>hiiii new user graph </span> */}
          <CCard className="mb-4">
            <CCardBody>
              <CRow>
                <CCol sm={5}>
                  <h4 id="traffic" className="card-title mb-0">
                    <FaUserCog style={{ color: '#97BBCD' }} /> Users
                  </h4>
                  {/* <div className="small text-medium-emphasis">January - July 2021</div> */}
                </CCol>
                <CCol sm={7} className="d-none d-md-block">
                  {/* <CButton color="primary" className="float-end"><CIcon icon={cilCloudDownload} /></CButton> */}
                </CCol>
              </CRow>
              <CChartLine
                height={60}
                data={{
                  labels: chartLabel,
                  datasets: [
                    {
                      label: 'Users',
                      backgroundColor: 'rgba(151, 187, 205, 0.2)',
                      borderColor: 'rgba(151, 187, 205, 1)',
                      pointBackgroundColor: 'rgba(151, 187, 205, 1)',
                      pointBorderColor: '#fff',
                      data: customerData,
                    },
                  ],
                }}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CCol xs={4}>
          {/* <span>VAN DRIVERS</span> */}
          <CCard className="mb-4">
            <CCardBody>
              <CRow>
                <CCol sm={5}>
                  <h4 id="traffic" className="card-title mb-0">
                    <FaUsers style={{ color: '#97BBCD' }} /> Van Drivers
                  </h4>
                  {/* <div className="small text-medium-emphasis">January - July 2021</div> */}
                </CCol>
                <CCol sm={7} className="d-none d-md-block">
                  {/* <FaUserCog style={{ color: '#97BBCD' }} /> {' '}  */}
                  {/* <CIcon className="float-end" icon={cilCloudDownload} /> */}
                </CCol>
              </CRow>
              <CChartDoughnut
                data={{
                  labels: chartLabel ? chartLabel : '',
                  datasets: [
                    {
                      // label: 'Van Drivers',
                      // backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16'],
                      backgroundColor: [
                        '#FAEEF2',
                        '#F7EEF1',
                        '#FCE4EC',
                        '#F8BBD0',
                        '#F48FB1',
                        '#F06292',
                        '#EC407A',
                        '#E91E63',
                        '#D81B60',
                        '#C2185B',
                        '#AD1457',
                        '#880E4F',
                      ],
                      data: driverData,
                    },
                  ],
                }}
              />
            </CCardBody>
          </CCard>
        </CCol>
        <CCol xs={4}>
          {/* <span>VANS</span> */}
          <CCard className="mb-4 ">
            <CCardBody>
              <CRow>
                <CCol sm={5}>
                  <h4 id="traffic" className="card-title mb-0">
                    <FaTruck style={{ color: '#d3dc40' }} /> Van
                  </h4>
                  {/* <div className="small text-medium-emphasis">January - July 2021</div> */}
                </CCol>
                <CCol sm={7} className="d-none d-md-block">
                  {/* <CButton color="primary" className="float-end"><CIcon icon={cilCloudDownload} /></CButton> */}
                </CCol>
              </CRow>
              <CChartPie
                data={{
                  labels: chartLabel ? chartLabel : '',
                  datasets: [
                    {
                      // backgroundColor: [randomColor(), randomColor(), randomColor(), randomColor(), randomColor(), randomColor(), randomColor(), randomColor(), randomColor(), randomColor(), randomColor(), randomColor()],
                      backgroundColor: [
                        '#DFEBEA',
                        '#D1DEDD',
                        '#E0F2F1',
                        '#B2DFDB',
                        '#80CBC4',
                        '#4DB6AC',
                        '#26A69A',
                        '#009688',
                        '#00897B',
                        '#00796B',
                        '#00695C',
                        '#004D40',
                      ],
                      data: vanData,
                      // hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
                    },
                  ],
                }}
              />
            </CCardBody>
          </CCard>
        </CCol>
        <CCol xs={4}>
          {/* <span>Sub admin</span> */}
          <CCard className="mb-4">
            <CCardBody>
              <CRow>
                <CCol sm={5}>
                  <h4 id="traffic" className="card-title mb-0">
                    <FaUserFriends style={{ color: '#f87979' }} /> Sub Admin
                  </h4>
                  {/* <div className="small text-medium-emphasis">January - July 2021</div> */}
                </CCol>
                <CCol sm={7} className="d-none d-md-block">
                  {/* <CButton color="primary" className="float-end"><CIcon icon={cilCloudDownload} /></CButton> */}
                </CCol>
              </CRow>
              <CChartBar
                height={300}
                data={{
                  labels: chartLabel,
                  datasets: [
                    {
                      label: 'Sub-Admin',
                      backgroundColor: '#f87979',
                      data: subAdmin,
                    },
                  ],
                }}
                labels="months"
              />
            </CCardBody>
          </CCard>
        </CCol>

        {/* <CCol xs={4}>
          <span>USERS</span>
          <CCard className="mb-4">
            <CCardBody>
              <CRow>
                <CCol sm={5}>
                  <h4 id="traffic" className="card-title mb-0">Users</h4>
                  <div className="small text-medium-emphasis">January - July 2021</div>
                </CCol>
                <CCol sm={7} className="d-none d-md-block">
                  <CButton color="primary" className="float-end"><CIcon icon={cilCloudDownload} /></CButton>
                </CCol>
              </CRow>
              <CChartBar
                data={{
                  labels: chartLabel,
                  datasets: [
                    {
                      label: 'Users',
                      backgroundColor: '#f87979',
                      data: customerData,
                    },
                  ],
                }}
                labels="months"
              />
            </CCardBody>
          </CCard>
        </CCol> */}
      </CRow>
      <CRow>
     { Array.isArray(homeData?.data?.orderByStatus) && <CCol xs={4}>
          {/* <span>VANS</span> */}
          <CCard className="mb-4 ">
            <CCardBody>
              <CRow>
                <CCol sm={5}>
                  <h4 id="traffic" className="card-title mb-0">
                    <FaTruck style={{ color: '#d3dc40' }} /> Order by Status
                  </h4>
                  {/* <div className="small text-medium-emphasis">January - July 2021</div> */}
                </CCol>
                <CCol sm={7} className="d-none d-md-block">
                  {/* <CButton color="primary" className="float-end"><CIcon icon={cilCloudDownload} /></CButton> */}
                </CCol>
              </CRow>
              <CChart
                type="doughnut"
                data={{
                  labels: homeData?.data?.orderByStatus.map(((_data , i)=> {
                    return _data?._id
                  })),
                  datasets: [
                    {
                      backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16'],
                      data: homeData?.data?.orderByStatus.map(((_data , i)=> {
                        return _data?.count
                      })),
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      labels: {
                        // color: getStyle('--cui-body-color'),
                      }
                    }
                  },
                }}
              />
            </CCardBody>
          </CCard>
        </CCol>}
        <CCol xs={4}>
          {/* <span>VANS</span> */}
          <CCard className="p-4">
                <CHeader>
                  <div className="d-flex" style={{ flexDirection: 'column' }}>
                    <CCardSubtitle as="h4">Order by Service</CCardSubtitle>
                  </div>
                </CHeader>
                <CCardBody>
                  <CTable bordered hover responsive>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell>S. No</CTableHeaderCell>
                        <CTableHeaderCell>Service Name</CTableHeaderCell>
                        <CTableHeaderCell>Price</CTableHeaderCell>
                        <CTableHeaderCell>Count</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {homeData && homeData?.data?.orderByService?.map((data, key) => {
                          return (
                            <CTableRow key={key}>
                              <CTableDataCell >
                                {key + 1}
                              </CTableDataCell>
                              <CTableDataCell>{data?.serviceName}</CTableDataCell>
                              <CTableDataCell>{data?.price}</CTableDataCell>
                              <CTableDataCell>{data?.count}</CTableDataCell>
                            </CTableRow>
                          )
                        })}
                    </CTableBody>
                  </CTable>
                </CCardBody>
              </CCard>
        </CCol>
        <CCol xs={4}>
          {/* <span>VANS</span> */}
          <CCard className="p-4">
                <CHeader>
                  <div className="d-flex" style={{ flexDirection: 'column' }}>
                    <CCardSubtitle as="h4">Spending per User Service</CCardSubtitle>
                  </div>
                </CHeader>
                <CCardBody>
                  <CTable bordered hover responsive>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell>S. No</CTableHeaderCell>
                        <CTableHeaderCell>Service Name</CTableHeaderCell>
                        <CTableHeaderCell>User</CTableHeaderCell>
                        <CTableHeaderCell>Price</CTableHeaderCell>
                        <CTableHeaderCell>Count</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {homeData && homeData?.data?.spendingPerUserService?.map((data, key) => {
                          return (
                            <CTableRow key={key}>
                              <CTableDataCell >
                                {key + 1}
                              </CTableDataCell>
                              <CTableDataCell>{data?.service}</CTableDataCell>
                              <CTableDataCell>{data?.user}</CTableDataCell>
                              <CTableDataCell>{data?.price}</CTableDataCell>
                              <CTableDataCell>{data?.count}</CTableDataCell>
                            </CTableRow>
                          )
                        })}
                    </CTableBody>
                  </CTable>
                </CCardBody>
              </CCard>
        </CCol>
      </CRow>
    </>
  )
}

export default Charts
