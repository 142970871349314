import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react'
import React, { useState } from 'react'

const OrderNotFound = ({ orderId, visible, setVisible, inputScannerRef }) => {
    return (
        <CModal
            alignment="center"
            visible={visible}
            onClose={() => setVisible(false)}
            aria-labelledby="VerticallyCenteredExample"
            className='warning-modal'
        >

            <CModalBody>
                <div className='warning-content'>
                    <img src="/img/warning.png" alt='warning_image' />
                    <h1>Oh snap!</h1>
                    <p>The item you scanned not belongs to this order</p>
                </div>
            </CModalBody>
            <CModalFooter>
                <CButton color="primary" onClick={() => { setVisible(false), inputScannerRef?.current.focus() }}>Dismiss</CButton>
            </CModalFooter>
        </CModal>
    )
}

export default OrderNotFound