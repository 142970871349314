import { configureStore } from '@reduxjs/toolkit';
import auth from '../slice/auth';
import sliderShow from '../slice/sliderShow';
import { combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import garmentShorting from '../slice/garmentShorting';

const rootReducer = combineReducers({
    auth: auth,
    garmentShorting: garmentShorting,
     sliderShow:sliderShow
})
const persistConfig = {
    key: "root",
    version: 1,
    storage: storage,
    whitelist: ["auth", 'sliderShow']
}
const customizedMiddleware = getDefaultMiddleware({
    serializableCheck: false
})
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: customizedMiddleware
})






