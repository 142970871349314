import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { post, get, deleteApi } from '../../utils/apiManager'
import CIcon from '@coreui/icons-react'
import { cilPen, cilPlus, cilTrash } from '@coreui/icons'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CCardSubtitle,
  CTable,
  CRow,
  CImage,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CButton,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import { DefaultInputWithIcon } from 'src/components/common/input'
import Pagination from 'react-js-pagination'
import { FaRegEye, FaEdit, FaTrash } from 'react-icons/fa'
import ShowMoreText from 'react-show-more-text'
import { cilLocationPin } from '@coreui/icons';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import GoogleMapReact from 'google-map-react';

function index() {
  const navigate = useNavigate();
  const [address, setAddress] = useState([]);
  const [totalPages, settotalPages] = useState(0);
  const [searchItem, setsearchItem] = useState("");
  const [pageData, setpageData] = useState({
    page: 1,
    perPage: 10,
    searchItem: "",
  });

  useEffect(() => {
    getData();
  }, [pageData]);

  const getData = () => {
    get(
      `/admin/address/get?page=${pageData?.page || 1}&perPage=${pageData?.perPage || 10
      }&searchItem=${pageData?.searchItem || ""}`
    ).then((data) => handleResponse(data));
  };
  const handleResponse = (data) => {
    if (!data?.status) toast.success(data?.message, data?.status);
    else {
      setAddress([...data?.data]);
      settotalPages(data?.pages);
    }
  };
  // const defaultAddress = () => { 

  //     get(`/admin/address/default-address`)
  // }   
  const currentAddressChange = (status, id) => {

    let default_status = ''
    if (status == 0) default_status = "Active"
    else default_status = "Inactive"
    if (confirm(`Are you sure you want to ${default_status} the address`)) {
      let formData = {
        _id: id,

      };
      post(`/admin/address/default-address`, formData).then(
        (data) => {
          handleStatusChange(data);
        }
      );
    }
  };
  const handleStatusChange = (data) => {
    toast.success(data?.message, data?.status);
    getData();
  };
  const changePage = (index) => {
    setpageData({
      ...pageData,
      page: index,
    });
  };
  const onClickUserEdit = async (value) => {
    navigate('/admin/address/edit', { state: value })
  }
  const onClickUserView = (value) => {
    navigate(`/admin/address/view/${btoa(value?._id)}`, { state: value })
  }
  const deleteData = (id) => {
    if (confirm("Are you sure")) {
      let formData = {
        _id: id,
      };
      deleteApi(`/admin/address/delete?_id=${id}`).then((data) => {
        handleDelete(data);
      });
    }
  };
  const handleDelete = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
    } else {
      toast.error(data?.message)
    }

    getData();
  };
  const onSearchCLick = () => {
    setpageData({
      ...pageData,
      searchItem: searchItem,
    });
  };

  return (
    <>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <CCardSubtitle as="h4">Address Information </CCardSubtitle>
                  <CRow>
                    <CCol md="8">
                      {/* <DefaultInputWithIcon
                        type={'text'}
                        placeholder="Search"
                        customType={'search'}
                        value={searchItem}
                        onClick={onSearchCLick}
                        onChange={(e) => {
                          onSearchCLick()
                          setsearchItem(e.target.value)
                        }}
                      /> */}
                    </CCol>
                    {/* <CCol md="4">
                      <Link to={{ pathname: '/admin/address/add' }} className="btn btn-dark">
                        <CIcon icon={cilPlus} />
                        Add
                      </Link>
                    </CCol> */}
                  </CRow>
                </CHeader>

                <CCardBody>
                  <CTable bordered hover responsive>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell>S. No</CTableHeaderCell>
                        <CTableHeaderCell>User Name</CTableHeaderCell>
                        <CTableHeaderCell>Address</CTableHeaderCell>
                        {/* <CTableHeaderCell>Status</CTableHeaderCell> */}
                        <CTableHeaderCell>Action</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {address.map((ele, index) => {
                        // console.log(ele)
                        return (
                          <CTableRow key={index}>
                            <CTableDataCell>{pageData?.perPage * (pageData?.page - 1) + index + 1}</CTableDataCell>
                            <CTableDataCell>{ele?.user_id === null ? "--" : ele?.user_id?.username}</CTableDataCell>
                            <CTableDataCell >{ele?.address}</CTableDataCell>
                            {/* <CTableDataCell>
                              <CButton
                                size="sm"
                                outline={ele?.status ? "1" : "0"}
                                onClick={() =>
                                  currentAddressChange(ele?.default, ele?._id)
                                }
                              >
                                {ele?.default == 0 ? "Inactive" : "Active"}
                              </CButton>
                            </CTableDataCell> */}
                            <CTableDataCell>
                              <span className="mx-2" onClick={() => onClickUserEdit(ele)} style={{ fontSize: '20px', color: '#d3dc40', cursor: 'pointer' }}><FaEdit /></span>
                              <span className="mx-2 text-danger" onClick={() => deleteData(ele?._id)} style={{ fontSize: '20px', padding: '5px', cursor: 'pointer' }}><FaTrash /></span>
                              <span className="mx-2" onClick={() => onClickUserView(ele)} style={{ fontSize: '20px', color: '#d3dc40', padding: '5px', cursor: 'pointer' }}><FaRegEye /></span>
                            </CTableDataCell>
                          </CTableRow>
                        );
                      })}
                    </CTableBody>
                  </CTable>
                  {!address?.length && (
                    <>
                      <h1>No Data</h1>
                    </>
                  )}
                  {address?.length ? <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={pageData?.page}
                    itemsCountPerPage={pageData?.perPage}
                    totalItemsCount={totalPages * 10}
                    pageRangeDisplayed={5}
                    onChange={changePage}
                  /> : ''}
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>

    </>
  )
}

export default index