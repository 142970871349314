import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { post, get, deleteApi } from '../../utils/apiManager'
import CIcon from '@coreui/icons-react'
import { cilPlus } from '@coreui/icons'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CCardSubtitle,
  CTable,
  CRow,
  CImage,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CButton,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import { DefaultInputWithIcon } from 'src/components/common/input'
import { FaRegEye, FaEdit, FaTrash } from 'react-icons/fa'
import { ImageUrl } from 'src/config/config'
import Pagination from 'react-js-pagination'


const index = () => {
  const navigate = useNavigate();
  const [helpData, setHelpData] = useState([])
  const [deletedArray, setDeletedArray] = useState([])

  // const [totalPages, settotalPages] = useState(0)
  // const [pageData, setpageData] = useState({
  //     page: 1,
  //     perPage: 10,
  //     searchItem: '',
  //   })

  useEffect(() => {
    getData()
  }, [])

  const getData = () => {
    get(`admin/help/get-help`).then((response) => {
      handleResponse(response)
    })
  }
  const handleResponse = (response) => {
    // console.log('response',response)
    if (!response?.status) toast.success(response?.message, response?.status)
    else {
      setHelpData([...response?.data])
      // settotalPages(response?.pages)
      // setimageUrl(response?.imageUrl)
    }
  }

  const deleteData = (id) => {
    // console.log('delete call',id)
    deleteApi(`admin/help/delete?id=${id}`).then((res) => {
      // console.log('respons',res)
      toast.success(res?.message)
      if (res?.status) {
        getData()
      }
    })
  }

  const onClickUserEdit = (data) => {
    navigate('/admin/help/add', { state: data })
  }


  const handleCheck = (id) => {
    let findValue = deletedArray.includes(id)
    if (findValue) {
      let filterData = deletedArray?.filter((item) => item != id)
      setDeletedArray(filterData)
    } else {
      setDeletedArray((prev) => [...prev, id])
    }
  }

  const deleteOrders = () => {
    post(`admin/help/deleted-help`, deletedArray).then((data) =>
      handleDeletedResponse(data),
    )
  }

  const handleDeletedResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      setDeletedArray([])
      getData()
    }
  }

  // const onSearchCLick = (val)=>{
  //     setpageData({...pageData,searchItem:val, page:1})
  // }

  // const changePage = (page)=>{
  //     setpageData({...pageData, page:page})
  // }
  return (<>
    <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
    <CContainer fluid>
      <CRow>
        <CCol>
          <CCardGroup>
            <CCard className="p-4">
              <CHeader>
                <div className="d-flex" style={{ flexDirection: 'column' }}>

                  <CCardSubtitle as="h4">App Help</CCardSubtitle>
                  {deletedArray.length > 0 && (
                    <button className="btn btn-danger mt-2 text-white" onClick={deleteOrders}>
                      Delete
                    </button>
                  )}
                </div>
                <CRow>
                  {/* <CCol md="8">
                      <DefaultInputWithIcon
                        type={'text'}
                        placeholder="search"
                        customType={'search'}
                        value={pageData?.searchItem}
                        onClick={onSearchCLick}
                        onChange={(e) => {
                          onSearchCLick(e.target.value)
                        }}
                      />
                    
                      </CCol> */}
                  <CCol >
                    <Link to={{ pathname: '/admin/help/add' }} className="btn btn-dark">
                      <CIcon icon={cilPlus} />
                      Add
                    </Link>
                  </CCol>
                </CRow>
              </CHeader>

              <CCardBody>
                <CTable bordered hover responsive>
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell>S. No</CTableHeaderCell>
                      <CTableHeaderCell>Title(en)</CTableHeaderCell>
                      <CTableHeaderCell>Title(Th)</CTableHeaderCell>
                      <CTableHeaderCell>Description(en)</CTableHeaderCell>
                      <CTableHeaderCell>Description(Th)</CTableHeaderCell>
                      <CTableHeaderCell>Images</CTableHeaderCell>
                      <CTableHeaderCell>Action</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {helpData?.map((ele, index) => {
                      return (
                        <CTableRow key={index} onClick={() => handleCheck(ele._id)} >
                          <CTableDataCell>{index + 1}


                            <div class="form-check">
                              <input
                                class="form-check-input"
                                style={{ marginLeft: '2px', marginTop: '-20px' }}
                                type="checkbox"
                                value=""
                                id="flexCheckDefault"
                                checked={deletedArray?.includes(ele._id)}
                              // onChange={() => handleCheck(ele._id)}
                              />
                            </div>
                          </CTableDataCell>

                          <CTableDataCell>{ele?.title_en}</CTableDataCell>
                          <CTableDataCell>{ele?.title_th}</CTableDataCell>
                          <CTableDataCell dangerouslySetInnerHTML={{ __html: ele?.description_en }}></CTableDataCell>
                          <CTableDataCell dangerouslySetInnerHTML={{ __html: ele?.description_th }}></CTableDataCell>

                          <CTableDataCell >
                            <a target='_blank' href={`${ImageUrl}${ele?.image}`}>
                              <CImage
                                rounded
                                src={`${ImageUrl}${ele?.image}`}
                                style={{
                                  maxHeight: '50px',
                                  maxWidth: '50px',
                                  borderRadius: 50,
                                  alignItems: 'center',
                                }}
                              />
                            </a>
                          </CTableDataCell>

                          <CTableDataCell>
                            <span className="mx-2" onClick={() => onClickUserEdit(ele)} style={{ fontSize: '20px', color: '#d3dc40', cursor: 'pointer' }}><FaEdit /></span>
                            <span className="mx-2 text-danger" onClick={() => deleteData(ele?._id)} style={{ fontSize: '20px', padding: '5px', cursor: 'pointer' }}><FaTrash /></span>
                          </CTableDataCell>
                        </CTableRow>
                      );
                    })}
                  </CTableBody>
                </CTable>
                {!helpData?.length && (
                  <>
                    <h1>No Data</h1>
                  </>
                )}
                {/* <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={pageData?.page}
                    itemsCountPerPage={pageData?.perPage}
                    totalItemsCount={totalPages * 10}
                    pageRangeDisplayed={5}
                    onChange={changePage}
                  /> */}
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </>)

}

export default index