import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CFormInput,
  CRow,
} from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { garmentState, setService } from 'src/reduxToolkit/slice/garmentShorting'
import { get, post } from 'src/utils/apiManager'
import Swal from 'sweetalert2'

const AddItemService = () => {
  let { search } = useLocation()
  let pramasData = useParams()
  const [serviceNameValue, setServiceNameValue] = useState("")
  const { service } = useSelector(garmentState)
  const [serviceData, setServiceData] = useState([])
  const [serviceError, setServiceError] = useState("")
  const dispatch = useDispatch()
  const [selectedService, setSelectedService] = useState('')
  const { bagId, orderId } = useParams()
  const [amount, setAmount] = useState('')
  const [errors, setErrors] = useState({})
  const { state } = useLocation()

  useEffect(() => {
    get(`/admin/subscription/services`).then((data) => {
      setServiceData(data?.data)
    })
  }, [])

  useEffect(() => {
    if (search !== "") {
      dispatch(setService(search?.split("?serviceName=")?.[1]))
    }
  }, [search, dispatch])

  const navigate = useNavigate();

  useEffect(() => {
    for (const _bag of state?.bags) {
      if (_bag._id === bagId) {
        setAmount(_bag.unitValue)
      }
    }
  }, [])

  const submitData = (id) => {
    let bag = state.bags.find((val) => val._id.toString() === pramasData.bagId.toString());

    //     console.log
    // 
    //     get(`admin/offline-order/unit/get-unit/${}`).then(
    //       (data) => {
    //         console.log({ data })
    //       },
    //     )

    if (bag.serviceType && bag.serviceType !== id) {
      Swal.fire({
        title: 'Are you sure you want to change services?',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          actions: 'my-actions',
          confirmButton: 'order-2',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          window.localStorage.setItem('categoryId', id)
          setErrors({})
          dispatch(setService(id))
          post(`admin/offline-order/bag/edit-bag/${bagId}`, { serviceType: id }, 0).then(
            (data) => {
              console.log({ data })
            },
          )
        }
      })
    } else {
      setErrors({})
      dispatch(setService(id))
    }
  }

  const nextPage = () => {
    navigate(`/admin/offline-order/bag/${bagId}/${orderId}?amount=${amount}`)
  }

  const prevPage = () => {
    navigate(`/admin/offline-order/bags/${orderId}`)
  }

  const handleNext = () => {
    if (/^\d+$/.test(amount)) {
      setErrors((prevState) => ({ ...prevState, [id]: null }))
    } else {
      console.log({ amount })
      setServiceError('Please enter a valid number')
      return
    }

    dispatch(setService(service))
    post(`admin/offline-order/bag/edit-bag/${bagId}`, { serviceType: service, unitValue: amount }, 0)
      .then((data) => {
        console.log({ data })
      })
    window.localStorage.setItem("categoryId", service);
    nextPage()
  }

  const handleOnBlur = (id, value) => {
    if (/^\d+$/.test(value)) {
      setErrors((prevState) => ({ ...prevState, [id]: null }))
    } else {
      setErrors((prevState) => ({ ...prevState, [id]: 'Please enter a valid number' }))
    }
  }

  return (
    <CContainer>
      <CCard>
        <CCardGroup>
          <CCardBody>
            <CRow className="p-2 align-items-center">
              <CCol>
                <h5 style={{ textTransform: 'uppercase' }}>Service </h5>
              </CCol>
            </CRow>
            <hr />
            <CRow className="p-2 align-items-center">
              {serviceData?.length > 0 &&
                serviceData.map((data, index) => (
                  <CCol xs={3} key={index}>
                    <div style={{ position: 'relative' }}>
                      <div
                        onClick={() => submitData(data._id)}
                        className="card p-4 w-100 d-flex align-items-center justify-content-center text-center my-2"
                        style={{ wordBreak: 'break-all', cursor: 'pointer' }}
                      >
                        {data.serviceName_EN}
                      </div>
                      {service === data?._id && (
                        <div
                          style={{ position: 'absolute', top: 0, right: '10%', fontSize: '24px' }}
                        >
                          <i className="fa-solid fa-check"></i>
                        </div>
                      )}
                      {service === data._id && (
                        <>
                          <CFormInput
                            type="text"
                            placeholder={`Enter amount in ${data?.unit?.title_EN}`}
                            name='quantity'
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            onBlur={(e) => handleOnBlur(data._id, e.target.value)}
                            className="mt-2"
                          />
                          {serviceError && (
                            <div style={{ color: 'red', marginTop: '5px' }}>{serviceError}</div>
                          )}
                        </>
                      )}
                      {!serviceError && errors[data?._id] && (
                        <div style={{ color: 'red', marginTop: '5px' }}>{errors[data?._id]}</div>
                      )}

                    </div>
                  </CCol>
                ))}
              <CCol xs={3}>
                <div style={{ position: 'relative' }}>
                  <div
                    onClick={() => navigate('/admin/services')}
                    className="card p-4 w-100 d-flex align-items-center justify-content-center text-center my-2"
                    style={{ wordBreak: 'break-all', cursor: 'pointer' }}
                  >
                    Add more & Delete
                  </div>
                </div>
              </CCol>
            </CRow>

            <CRow className="justify-content-between">
              <CCol xs={2} className="justify-content-end">
                {service !== '' && (
                  <button
                    style={{
                      marginTop: '20px',
                      width: '100%',
                      background: '#d3dc40',
                      border: '#d3dc40',
                      padding: '10px 0',
                    }}
                    onClick={() => prevPage()}
                  >
                    prev
                  </button>
                )}
              </CCol>
              <CCol xs={2} className="justify-content-end">
                {service !== '' && (
                  <button
                    style={{
                      marginTop: '20px',
                      width: '100%',
                      background: '#d3dc40',
                      border: '#d3dc40',
                      padding: '10px 0',
                    }}
                    onClick={handleNext}
                  >
                    Next
                  </button>
                )}
              </CCol>
            </CRow>
          </CCardBody>
        </CCardGroup>
      </CCard>
    </CContainer>
  )
}

export default AddItemService
