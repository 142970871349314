import React, { useEffect, useRef, useState } from 'react'
import { Formik } from 'formik'
import { Link, useLocation, useNavigate, useRoutes } from 'react-router-dom'
import { baseURL } from 'src/config/config'
import { put, get } from 'src/utils/apiManager'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { CCard, CCardBody, CCardGroup, CCol, CContainer, CFormCheck, CFormLabel, CRow } from '@coreui/react'
import { DefaultMobileInput, DefaultInput } from 'src/components/common/input'
import { validateSubAdmin } from 'src/utils/validator'

function edit() {
  const navigate = useNavigate()
  const { state } = useLocation()
  // console.log(state)
  const formRef = useRef(null)
  const fileRef = useRef(null)
  const [moduleName, setModuleName] = useState(state?.data)
  const [userData, setUserData] = useState(state?.userData)
  const [isDisable, setIsDisable] = useState(false);

  useEffect(() => {
    modulePermissionData()
  }, [])

  const modulePermissionData = () => {
    get(`/admin/subAdmin/single-user?_id=${state?.userData?._id}`).then((data) => {
      // console.log('updated single data', data?.data[0]?.sidebarModule)
      handleResponse(data)
    })
  }

  const handleResponse = (data) => {
    if (data?.status == true) {
      let tempData = []
      state?.data?.map((item, index) => {
        let tempID = []
        
    // we start from here
        tempID = data?.data[0]?.sidebarModule?.filter((c_item, index) => c_item?.moduleId == item?._id)
        if (tempID?.length) {
          let tempItems = tempID[0]
          tempData?.push({ ...tempItems, chacked: true })
        } else {
          tempData?.push({ ...item, moduleId: item?._id })
        }
      })
      formRef.current.setFieldValue('username', userData?.username)
      formRef.current.setFieldValue('email', userData?.email)
      formRef.current.setFieldValue('mobile', userData?.mobile)
      setModuleName(tempData)
    }
  }
  const handleCheckParent = ({ _id }) => {
    let tempData = []
    tempData = moduleName?.map((item, index) => {
      if (_id == item?._id) {
        return { ...item, chacked: !item?.chacked }
      } else {
        return item
      }
    })

    setModuleName(tempData)
  }

  const handleCheckChild = (value, chiledKey) => {
    let tempData = []
    tempData = moduleName?.map((item, index) => {
      if (item?._id == value?._id) {
        return { ...item, [chiledKey]: !value?.[chiledKey] }
      } else {
        return item
      }
    })
    setModuleName(tempData)
  }
  const submit = async (values) => {
    let moduleArr = []
    let moduleData = []

    moduleArr = moduleName?.filter((item, index) => item?.chacked)
// console.log(moduleArr)
    moduleArr.map((value, key) => {
      // console.log("valuee@@@@@@",value)
      moduleData.push({
        _id: value?.moduleId,
        name: value?.name,
        isAdd: value?.isAdd,
        isEdit: value?.isEdit,
        isView: value?.isView,
        isDelete: value?.isDelete,
      })
    })

    let data = {
      id: userData?._id,
      username: values?.username,
      email: values?.email,
      mobile: values?.mobile,
      moduleId: moduleData,
    }
    // console.log('submit *** ', data)    
    if (moduleData?.length > 0) {
      put(`/admin/subAdmin/edit-subadmin?_id=${userData?._id}`, data).then((data) => {
        handleResponseData(data)
      })
    } else {
      toast.warn('Select a permission module !')
    }
  }
  const handleResponseData = (data) => {
    // console.log(data)
    if (data?.status == true) {
      toast.success(data?.message)
      setTimeout(() => {
        navigate('/admin/sub-admin')
      }, 800)
    } else {
      toast.error(data?.message)
    }
    formRef.current.resetForm()
    // fileRef.current.value = null
  }
  const handleAllSelect=(e)=>{
    console.log(e.target.checked);
     let tempData = []
    tempData = moduleName?.map((item, index) => {

        return { ...item, chacked: e.target.checked }
      
    })
    setModuleName(tempData)
  }
  return (
    <>
      <CContainer>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <CRow>
                        <CCol md="11">
                          <h1>Edit sub-admin</h1>
                        </CCol>
                        <CCol md="1">
                          <Link
                            className={'btn btn-success mt-2'}
                            style={{ background: '#d3dc40', border: '#d3dc40' }}
                            to={{ pathname: `/admin/sub-admin` }}
                          >
                            Back
                          </Link>
                        </CCol>
                      </CRow>
                      <Formik
                        initialValues={{
                          username: '',
                          email: '',
                          image: '',
                          mobile: '',
                        }}
                        validationSchema={validateSubAdmin}
                        onSubmit={(values, { setSubmitting }) => {
                          submit(values)
                          setSubmitting(false)
                        }}
                        innerRef={formRef}
                      >
                        {(props) => (
                          <form onSubmit={props?.handleSubmit}>
                            <CRow xs={12}>
                              <CCol md={6}>
                              <CFormLabel>Name</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Name'}
                                  id={'username'}
                                  maxLength="70"
                                  value={props?.values?.username}
                                  onChange={props?.handleChange('username')}
                                  onBlur={() => props?.setFieldTouched('username', true)}
                                  error={props?.touched?.username && props?.errors?.username}
                                  onKeyUp={(value)=>{
                                    if(userData?.username == props?.values?.username) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                              </CCol>
                              <CCol md={6}>
                              <CFormLabel>Email</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  disabled={true}
                                  type={'email'}
                                  placeholder={'Email'}
                                  // name={'Email'}
                                  id={'email'}
                                  value={props?.values?.email}
                                  // value={this.state?.name}
                                  onChange={props?.handleChange('email')}
                                  onBlur={() => props?.setFieldTouched('email', true)}
                                  error={props?.touched?.email && props?.errors?.email}
                                />
                              </CCol>
                            </CRow>
                            <CRow xs={12}>
                              <CCol md={6}>
                                <DefaultMobileInput
                                  type={'text'}
                                  placeholder={'Mobile'}
                                  name={'Mobile'}
                                  id={'mobile'}
                                  minLength={7}
                                  maxLength={15}
                                  value={props?.values?.mobile}
                                  onChange={(val) => props?.setFieldValue('mobile', val || '')}
                                  onBlur={() => props?.setFieldTouched('mobile', true)}
                                  error={props?.touched?.mobile && props?.errors?.mobile}
                                  onKeyUp={(value)=>{
                                    if(userData?.mobile == props?.values?.mobile) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                              </CCol>
                            </CRow>
                            <CRow className="mt-4">
                              <CCol>
                                <div className="card">
                                  <div className="card-body">
                                    <strong>Modules Permission</strong>
                                  <br/>
                                    <input type='checkbox' onChange={(e)=>handleAllSelect(e)}/>
                                    <label>&nbsp; All Select</label>
                                    <hr />
                                    <CRow className="mt-2">
                                      <CCol>
                                        {moduleName?.map((value, key) => {
                                          // console.log('moduleName+++', value)
                                          return (
                                            <>
                                              <CRow>
                                                <CCol>
                                                  <CFormCheck
                                                    id={value?._id}
                                                    label={value?.name}
                                                    style={{cursor: "pointer"}}
                                                    checked={value?.chacked}
                                                    onChange={(val)=>{handleCheckParent(value) }}
                                                  />
                                                </CCol>
                                                <CCol>
                                                  <strong>Add</strong>
                                                  <br />
                                                  <CFormCheck
                                                    // id="flexCheckChecked"
                                                    // label="Checked checkbox"
                                                    style={{cursor: "pointer"}}
                                                    disabled={value?.chacked ? false : true}
                                                    checked={value?.isAdd}
                                                    onChange={(val) => {
                                                      handleCheckChild(value, 'isAdd')
                                                    }}
                                                  />
                                                </CCol>
                                                <CCol>
                                                  <strong>Edit</strong>
                                                  <br />
                                                  <CFormCheck
                                                    // id="flexCheckChecked"
                                                    // label="Checked checkbox"
                                                    style={{cursor: "pointer"}}
                                                    disabled={value?.chacked ? false : true}
                                                    checked={value?.isEdit}
                                                    onChange={(val) => {
                                                      handleCheckChild(value, 'isEdit')
                                                    }}
                                                  />
                                                </CCol>
                                                <CCol>
                                                  <strong>Delete</strong>
                                                  <br />
                                                  <CFormCheck
                                                    // id="flexCheckChecked"
                                                    // label="Checked checkbox"
                                                    style={{cursor: "pointer"}}
                                                    disabled={value?.chacked ? false : true}
                                                    checked={value?.isDelete}
                                                    onChange={(val) => {
                                                      handleCheckChild(value, 'isDelete')
                                                    }}
                                                  />
                                                </CCol>
                                                <CCol>
                                                  <strong>View</strong>
                                                  <br />
                                                  {/* <span className='text-center'>hii</span> */}
                                                  <CFormCheck
                                                    // id="flexCheckChecked"
                                                    // label="Checked checkbox"
                                                    style={{cursor: "pointer"}}
                                                    disabled={value?.chacked ? false : true}
                                                    checked={value?.isView}
                                                    onChange={(val) => {
                                                      handleCheckChild(value, 'isView')
                                                    }}
                                                  />
                                                </CCol>
                                              </CRow>
                                              <hr />
                                            </>
                                          )
                                        })}
                                      </CCol>
                                    </CRow>
                                  </div>
                                </div>
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol md="2">
                              {/* <button type="submit"className={'btn btn-success mt-2'}disabled={!isDisable || props?.isSubmitting}>Submit</button> */}
                                <button
                                  type="submit"
                                  className={'btn btn-success mt-2'}
                                  disabled={props?.isSubmitting}
                                >
                                  Submit
                                </button>
                              </CCol>
                              <CCol md="2">
                                <button
                                  type="button"
                                  className={'btn btn-warning mt-2'}
                                  onClick={() => navigate('/admin/sub-admin')}
                                >
                                  Cancel
                                </button>
                              </CCol>
                              <CCol md="3"></CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default edit
