import React, { useRef, useState, useEffect, useCallback } from 'react'
import { connect, Formik, setNestedObjectValues } from 'formik'
import { useNavigate, Link, useLocation, useParams } from 'react-router-dom'
import { post, get, put } from 'src/utils/apiManager'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Select from 'react-select'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CFormLabel,
  CRow,
  CImage,
  CFormCheck,
  CHeader,
  CFormTextarea,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
  CTable,
  CCardSubtitle,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableDataCell,
  CTableBody,
  CButton,
} from '@coreui/react'
import { DefaultInput, DefaultTextarea } from 'src/components/common/input'
import {
  validatePendingStatusItem,
  validateRejectedStatusItem,
  validateConfirmedStatusItem,
} from 'src/utils/validator'
import { cilPlus, cilX } from '@coreui/icons'
import { FaEdit, FaEye, FaTrash, FaWindowClose } from 'react-icons/fa'
import CIcon from '@coreui/icons-react'
import { ImageUrl } from 'src/config/config'

function ExaminItems() {
  const navigate = useNavigate()
  const { orderId } = useParams()
  const { userId } = useParams()
  const { id } = useParams()
  const formRef = useRef(null)
  const fileRef = useRef(null)
  const [openForm, setOpenForm] = useState(false)
  const [invalidImage, setInvalidImage] = useState('')
  const [files, setFiles] = useState([])
  const [imageURI, setImageURI] = useState([])
  const [status, setStatus] = useState('confirm')
  const [pendingStatus, setPendingStatus] = useState(false)
  const [rejectedStatus, setRejectedStatus] = useState(false)
  const [confirmStatus, setConfirmStatus] = useState(true)
  const [allServices, setAllServices] = useState()
  const [servicesData, setServicesData] = useState()
  const [selectService, setSelectService] = useState([])
  const [additionalServiceData, setAdditionalServiceData] = useState(null)
  const [selectAdditionalService, setSelectAdditionalService] = useState([])
  const [ID, setID] = useState(atob(id))
  const [addedItemsList, setAddedItemsList] = useState([])
  const [listDisable, setListDisable] = useState(true)
  const [selectedItem, setSelectedItem] = useState(null)
  const [itemSizeData, setItemSizeData] = useState(null)
  const [isButtonShow, setIsButtonShow] = useState(false)
  const [isClickButton, setIsClickButton] = useState(false)

  const [attributeList, setAttributeList] = useState()
  const [selectedAttribute, setSelectedAttribute] = useState(null)
  const [selectedoptionList, setSelectedOptionList] = useState()
  const [OptionsArr, setOptionsArr] = useState([])

  let servicesOptions = []
  let additionalServicesOptions = []
  let additionalServicePrice = 0
  let servicesPrice = 0
  const itemOptions = []
  let servicesListing = []
  const AttributeOptions = []

  const uploadSingleFile = (e) => {
    readURI(e)
  }

  const readURI = (e) => {
    if (e?.target?.files) {
      /* Get files in array form */

      const selectedFiles = Array.from(e.target.files)
      selectedFiles?.map((item, index) => {
        if (!item?.name?.match(/\.(jpg|jpeg|png)$/)) {
          setInvalidImage('Please select valid image')
        } else {
          setInvalidImage('')
        }
      })
      setFiles([...files, ...selectedFiles])
      /* Map each file to a promise that resolves to an array of image URI's */
      Promise.all(
        selectedFiles?.map((file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader?.addEventListener('load', (ev) => {
              resolve(ev?.target?.result)
            })
            reader?.addEventListener('error', reject)
            reader?.readAsDataURL(file)
          })
        }),
      ).then(
        (images) => {
          setImageURI([...imageURI, ...images])
        },
        (error) => {
          console.error(error)
        },
      )
    }
  }

  const deleteParticularFile = (id) => {
    const s = imageURI?.filter((item, index) => index !== id)
    const extractFile = files?.filter((item, index) => index !== id)
    setFiles(extractFile)
    setImageURI(s)
  }

  const getAttributesList = () => {
    get(`/admin/placed-order/get-option-list`).then((res) => {
      if (res?.status) {
        setAttributeList(res?.data)
      } else {
        toast.error(res?.message)
      }
    })
  }

  useEffect(() => {
    getServices()
    getItems()
    getAttributesList()
    // fetchItemsList();
  }, [])

  useEffect(() => {
    if (openForm) {
      setListDisable(false)
    } else {
      setListDisable(true)
      fetchItemsList()
    }
  }, [openForm])

  if (attributeList != null) {
    attributeList?.map((item, key) => {
      AttributeOptions?.push({ label: item?.attributeName_EN, value: item?._id })
    })
  }

  const getOptionsList = (value) => {
    if (value != selectedAttribute?._id) {
      setOptionsArr([])
    }
    attributeList?.map((item, k) => {
      if (item?._id == value) {
        item?.option?.map((option, i) => {
          setOptionsArr((prevState) => [
            ...prevState,
            { label: option?.optionName_EN, value: option?._id },
          ])
        })
      }
    })
  }

  const fetchItemsList = () => {
    get(
      `/admin/placed-order/get-examin-item-details?order_id=${atob(orderId)}&user_id=${atob(
        userId,
      )}`,
    ).then((res) => {
      setAddedItemsList(res?.data)
      const filterArr = res?.data?.filter((aitem) => aitem?.itemStatus == 'pending')
      // console.log(filterArr, "fil arr")
      if (filterArr?.length == 0) {
        setIsButtonShow(true)
      }
    })
  }

  const getItems = () => {
    get(`admin/services/get-items`).then((data) => {
      setItemSizeData(data?.data)
    })
  }
  if (itemSizeData !== null) {
    itemSizeData?.map((item, key) => {
      itemOptions?.push({ label: item?.itemName_EN, value: item?._id })
    })
  }
  const getServices = () => {
    get(`admin/subscription/services`).then((data) => {
      setAllServices(data?.data)
      setServicesData(data?.data)
    })
  }
  if (servicesData !== null) {
    servicesData?.map((item, key) => {
      servicesOptions?.push({ label: item?.serviceName_EN, value: item?._id })
    })
  }

  const handleAdditionalOptions = (value) => {
    // console.log('vall.............',value)
    const arrcopy = Array.from(allServices)
    value?.map((item) => {
      const objWithIdIndex = arrcopy?.findIndex((obj) => obj?._id == item?.value)
      // console.log('objWithIdIndex', objWithIdIndex)
      arrcopy?.splice(objWithIdIndex, 1)
      // console.log(arrcopy)
      setAdditionalServiceData(arrcopy)
    })
  }

  if (additionalServiceData !== null) {
    additionalServiceData?.map((item, key) => {
      additionalServicesOptions?.push({ label: item?.serviceName_EN, value: item?._id })
    })
  }

  const openStatusInfo = (e, status) => {
    formRef.current.setFieldValue('servicesPrice', '')
    formRef.current.setFieldValue('additionalServicesPrice', '')
    formRef.current.setFieldValue('totalPrice', '')
    formRef.current.setFieldValue('description', '')
    formRef.current.setFieldValue('services', '')
    formRef.current.setFieldValue('additionalServices', '')
    setSelectService([])
    setSelectAdditionalService([])

    if (status == 'pending') {
      setPendingStatus(true)
      setRejectedStatus(false)
      setConfirmStatus(false)
      setStatus('pending')
    } else if (status == 'rejected') {
      setPendingStatus(false)
      setRejectedStatus(true)
      setConfirmStatus(false)
      setStatus('rejected')
    } else {
      setPendingStatus(false)
      setRejectedStatus(false)
      setConfirmStatus(true)
      setStatus('confirm')
    }
  }

  const handleFormSubmit = (values) => {
    let data = {}
    var OPTIONS = []
    if (values?.optionName) {
      values?.optionName?.forEach((item, k) => {
        OPTIONS?.push(item?.value)
      })
    }

    if (status == 'pending') {
      var SERVICES = []
      var ADD_SERVICES = []
      if (values?.services) {
        values?.services?.forEach((item, key) => {
          SERVICES?.push(item?.value)
        })
      }
      if (values?.additionalServices) {
        values?.additionalServices?.forEach((item, key) => {
          ADD_SERVICES?.push(item?.value)
        })
      }
      data = {
        id: ID,
        itemName: values?.itemName,
        attributeName: values?.attributeName,
        optionName: JSON.stringify(OPTIONS),
        totalPrice: values?.totalPrice,
        servicePrice: values?.servicesPrice,
        additionalServicePrice: values?.additionalServicesPrice,
        additionalServices: ADD_SERVICES ? JSON.stringify(ADD_SERVICES) : [],
        services: JSON.stringify(SERVICES),
        itemStatus: status,
        image: files,
      }
    }

    if (status == 'confirm') {
      var SERVICES = []
      if (values?.services) {
        values?.services?.forEach((item, key) => {
          SERVICES.push(item?.value)
        })
      }
      data = {
        id: ID,
        itemName: values?.itemName,
        attributeName: values?.attributeName,
        optionName: JSON.stringify(OPTIONS),
        description: values?.description,
        services: JSON.stringify(SERVICES),
        servicePrice: values?.servicesPrice,
        itemStatus: status,
        images: files,
      }
    }

    if (status == 'rejected') {
      var SERVICES = []
      if (values?.services) {
        values?.services?.forEach((item, key) => {
          SERVICES.push(item?.value)
        })
      }
      data = {
        id: ID,
        itemName: values?.itemName,
        attributeName: values?.attributeName,
        optionName: JSON.stringify(OPTIONS),
        services: JSON.stringify(SERVICES),
        description: values?.description,
        itemStatus: status,
        images: files,
      }
    }

    if (
      status == 'confirm' &&
      values?.services == '' &&
      values?.description == '' &&
      files?.length <= 0
    ) {
      toast.error('All fields are required')
    } else if (status == 'pending' && values?.services == '' && files?.length == 0) {
      toast.error('All fields are required')
    } else if (
      status == 'rejected' &&
      values?.services == '' &&
      values?.description == '' &&
      files?.length <= 0
    ) {
      toast.error('All fields are required')
    } else {
      post(`/admin/placed-order/add-examin-items`, { ...data, orderType: 'online' }, 1).then(
        (res) => {
          if (res?.data) {
            setOpenForm(false)
            setSelectService([])
            setSelectAdditionalService([])
            setImageURI([])
            setFiles([])
            setStatus('confirm')
            setSelectedItem([])
            setSelectedAttribute([])
            setSelectedOptionList([])
            // servicesListing = []
            // setPendingServicesList([])
            // setConfirmServicesList([])
            // setAdditionalServicesList([])
            // servicesList = []
            // confirmServicesList = []
            // // console.log('res$data2.filter', res?.data)
            // const filterArr = res?.data?.filter((aitem) => aitem?.itemStatus == 'pending')
            const filterArr = res?.data?.itemStatus == 'pending'
            // console.log(filterArr, "fil arr")
            if (filterArr?.length == 0) {
              setIsButtonShow(true)
            }
          }
        },
      )
    }
  }
  const outForServices = () => {
    setIsClickButton(true)
    put(`admin/placed-order/items-out-for-services?orderType=online&orderId=${atob(orderId)}`).then(
      (response) => {
        handleSubmitButton(response)
      },
    )
  }
  const handleSubmitButton = (response) => {
    if (response?.status == true) {
      toast?.success(response?.message)
      setIsButtonShow(false)
      setTimeout(() => {
        navigate(`/admin/placed-order`)
      }, 2000)
    }
  }

  return (
    <>
      <CContainer>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <CRow>
                        <CCol md="10">
                          <h1>Examine Order Items</h1>
                        </CCol>
                        <CCol md="2">
                          <Link onClick={() => setOpenForm(true)} className="btn btn-dark mx-2">
                            <CIcon icon={cilPlus} />
                            Add
                          </Link>
                          <Link
                            className={'btn btn-success'}
                            style={{ background: '#d3dc40', border: '#d3dc40' }}
                            to={{ pathname: `/admin/placed-order` }}
                          >
                            Back
                          </Link>
                        </CCol>
                      </CRow>

                      {listDisable && addedItemsList?.length ? (
                        <CAccordion flush>
                          <CAccordionItem itemKey={1}>
                            <CAccordionHeader>Examin Items List</CAccordionHeader>
                            <CAccordionBody>
                              <CRow>
                                <CCol>
                                  <CCardGroup>
                                    <CCard className="p-4">
                                      <CHeader>
                                        <CCardSubtitle as="h4">
                                          Order ID: {atob(orderId)}
                                        </CCardSubtitle>
                                        <CRow>
                                          <CCol md="8"></CCol>
                                        </CRow>
                                      </CHeader>
                                      <CCardBody>
                                        <CTable bordered hover responsive>
                                          <CTableHead>
                                            <CTableRow>
                                              <CTableHeaderCell>S. No</CTableHeaderCell>
                                              <CTableHeaderCell>Item Name</CTableHeaderCell>
                                              <CTableHeaderCell>Item Status</CTableHeaderCell>
                                              <CTableHeaderCell>Bar Code</CTableHeaderCell>
                                              {/* <CTableHeaderCell>Services</CTableHeaderCell>
                                              <CTableHeaderCell>Additional Services</CTableHeaderCell> */}
                                              {/* <CTableHeaderCell>Total Price</CTableHeaderCell> */}
                                              {/* <CTableHeaderCell>Description</CTableHeaderCell> */}
                                              <CTableHeaderCell>Images</CTableHeaderCell>
                                            </CTableRow>
                                          </CTableHead>
                                          <CTableBody>
                                            {addedItemsList?.map((item, index) => {
                                              // console.log(item)
                                              return (
                                                <CTableRow key={index}>
                                                  <CTableDataCell>{index + 1}</CTableDataCell>
                                                  <CTableDataCell>
                                                    {item?.itemName[0]?.itemName_EN}
                                                  </CTableDataCell>
                                                  <CTableDataCell>
                                                    {item?.itemStatus == 'rejected' ? (
                                                      <span className="text-danger">Rejected</span>
                                                    ) : item?.itemStatus == 'pending' ? (
                                                      <span className="text-info">Pending</span>
                                                    ) : item?.itemStatus == 'confirm' ? (
                                                      <span className="text-success">Approved</span>
                                                    ) : (
                                                      ''
                                                    )}
                                                  </CTableDataCell>
                                                  <CTableDataCell>
                                                    <p
                                                      dangerouslySetInnerHTML={{
                                                        __html: item?.barCode,
                                                      }}
                                                    ></p>
                                                  </CTableDataCell>

                                                  <CTableDataCell>
                                                    {item?.image?.map((i, index) => {
                                                      return (
                                                        <a target="_blank" href={`${ImageUrl}${i}`}>
                                                          <CImage
                                                            rounded
                                                            src={`${ImageUrl}${i}`}
                                                            className="mx-2"
                                                            style={{
                                                              maxHeight: '80px',
                                                              maxWidth: '80px',
                                                              alignItems: 'center',
                                                            }}
                                                          />
                                                        </a>
                                                      )
                                                    })}
                                                  </CTableDataCell>
                                                </CTableRow>
                                              )
                                            })}
                                          </CTableBody>
                                        </CTable>
                                        {isButtonShow && (
                                          <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                            <CButton color="primary" onClick={outForServices}>
                                              submit
                                            </CButton>
                                          </div>
                                        )}
                                      </CCardBody>
                                    </CCard>
                                  </CCardGroup>
                                </CCol>
                              </CRow>
                            </CAccordionBody>
                          </CAccordionItem>
                        </CAccordion>
                      ) : (
                        ''
                      )}

                      {openForm ? (
                        <Formik
                          initialValues={{
                            images: '',
                            itemName: '',
                            services: '',
                            additionalServices: '',
                            description: '',
                            servicesPrice: '',
                            additionalServicesPrice: '',
                            totalPrice: '',
                            attributeName: '',
                            optionName: '',
                          }}
                          onSubmit={(values, { setSubmitting }) => {
                            handleFormSubmit(values)
                            setSubmitting(false)
                          }}
                          innerRef={formRef}
                        >
                          {(props) => (
                            <form onSubmit={props?.handleSubmit}>
                              <CRow>
                                <CCol md={6}>
                                  <div style={{ marginTop: '10px' }}>
                                    <CFormLabel>Item Status</CFormLabel>
                                    <span className="text-danger" style={{ marginLeft: '5px' }}>
                                      *
                                    </span>
                                    <CFormCheck
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="pending"
                                      label="Issue with Item/Clothes"
                                      onClick={(e) => openStatusInfo(e, 'pending')}
                                    />
                                    <CFormCheck
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="rejected"
                                      label="Reject"
                                      onClick={(e) => openStatusInfo(e, 'rejected')}
                                    />
                                    <CFormCheck
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="confirm"
                                      label="Confirm"
                                      defaultChecked
                                      onClick={(e) => openStatusInfo(e, 'confirm')}
                                    />
                                  </div>

                                  <CFormLabel>Item Name</CFormLabel>
                                  <span className="text-danger" style={{ marginLeft: '5px' }}>
                                    *
                                  </span>

                                  <Select
                                    defaultValue={selectedItem}
                                    onChange={(val) => {
                                      setSelectedItem(val)
                                      props?.setFieldValue('itemName', val?.value)
                                    }}
                                    options={itemOptions}
                                    value={selectedItem}
                                    placeholder={'Select Item Name'}
                                    name="Items"
                                  />
                                  {selectedItem == undefined ? (
                                    <span className="text-danger">{props?.errors?.itemName}</span>
                                  ) : (
                                    ''
                                  )}

                                  <CFormLabel>Brand Name</CFormLabel>
                                  <span className="text-danger" style={{ marginLeft: '5px' }}>
                                    *
                                  </span>

                                  <Select
                                    defaultValue={selectedAttribute}
                                    onChange={(val) => {
                                      setSelectedAttribute(val)
                                      getOptionsList(val?.value)
                                      props?.setFieldValue('attributeName', val?.value)
                                    }}
                                    options={AttributeOptions}
                                    value={selectedAttribute}
                                    placeholder={'Select Brand Name'}
                                    name="Brand"
                                  />
                                  {selectedAttribute == undefined ? (
                                    <span className="text-danger">
                                      {props?.errors?.attributeName}
                                    </span>
                                  ) : (
                                    ''
                                  )}

                                  <CFormLabel>Options</CFormLabel>
                                  <span className="text-danger" style={{ marginLeft: '5px' }}>
                                    *
                                  </span>

                                  <Select
                                    isMulti={true}
                                    defaultValue={selectedoptionList}
                                    onChange={(val) => {
                                      setSelectedOptionList(val)
                                      props?.setFieldValue('optionName', val)
                                    }}
                                    options={OptionsArr}
                                    value={selectedoptionList}
                                    placeholder={'Select Options Name'}
                                    name="Options"
                                  />
                                  {selectedoptionList == undefined ? (
                                    <span className="text-danger">{props?.errors?.optionName}</span>
                                  ) : (
                                    ''
                                  )}

                                  <CFormLabel>Image</CFormLabel>
                                  <span className="text-danger" style={{ marginLeft: '5px' }}>
                                    *
                                  </span>
                                  <DefaultInput
                                    multiple
                                    type={'file'}
                                    placeholder={'Image'}
                                    accept="image/*"
                                    refr={fileRef}
                                    onChange={(e) => uploadSingleFile(e)}
                                  />
                                  <div className="myImges row">
                                    {imageURI?.length > 0 &&
                                      imageURI?.map((item, index) => {
                                        return (
                                          <>
                                            <div className="col-md-3">
                                              <figure>
                                                <img src={`${item}`} />
                                                <figcaption>
                                                  <button type="button">
                                                    <FaTrash
                                                      onClick={() => deleteParticularFile(index)}
                                                      style={{ color: 'red' }}
                                                    />
                                                  </button>
                                                </figcaption>
                                              </figure>
                                            </div>
                                          </>
                                        )
                                      })}
                                  </div>
                                </CCol>
                                {pendingStatus ? (
                                  <CCol md="6">
                                    <CFormLabel>Services</CFormLabel>
                                    <span className="text-danger" style={{ marginLeft: '5px' }}>
                                      *
                                    </span>
                                    <Select
                                      isMulti={true}
                                      defaultValue={selectService}
                                      onChange={(val) => {
                                        setSelectService(val)
                                        if (Object.keys(val)?.length == 0) {
                                          servicesPrice = 0
                                          formRef.current.setFieldValue(
                                            'servicesPrice',
                                            servicesPrice,
                                          )
                                          formRef.current.setFieldValue(
                                            'totalPrice',
                                            props?.values?.additionalServicesPrice,
                                          )
                                        }
                                        allServices.find((element) => {
                                          val?.map((item) => {
                                            if (element?._id == item?.value) {
                                              servicesPrice = servicesPrice + element?.price
                                              formRef.current.setFieldValue(
                                                'servicesPrice',
                                                servicesPrice,
                                              )
                                              formRef.current.setFieldValue(
                                                'totalPrice',
                                                props?.values?.additionalServicesPrice +
                                                  servicesPrice,
                                              )
                                            }
                                          })
                                        })

                                        handleAdditionalOptions(val)
                                        props.setFieldValue('services', val)
                                      }}
                                      options={servicesOptions}
                                      value={selectService}
                                      placeholder={'Select Services'}
                                      name="Services"
                                    />
                                    <CFormLabel>Additional Services</CFormLabel>
                                    <Select
                                      isMulti={true}
                                      defaultValue={selectAdditionalService}
                                      onChange={(val) => {
                                        setSelectAdditionalService(val)
                                        if (Object.keys(val).length == 0) {
                                          additionalServicePrice = 0
                                          formRef.current.setFieldValue(
                                            'additionalServicesPrice',
                                            additionalServicePrice,
                                          )
                                          formRef.current.setFieldValue(
                                            'totalPrice',
                                            props?.values?.servicesPrice,
                                          )
                                        }

                                        allServices?.find((element) => {
                                          val?.map((item) => {
                                            if (element?._id == item?.value) {
                                              additionalServicePrice =
                                                additionalServicePrice + element?.price
                                              formRef.current.setFieldValue(
                                                'additionalServicesPrice',
                                                additionalServicePrice,
                                              )
                                              formRef.current.setFieldValue(
                                                'totalPrice',
                                                props?.values?.servicesPrice +
                                                  additionalServicePrice,
                                              )
                                            }
                                          })
                                        })
                                        props.setFieldValue('additionalServices', val)
                                      }}
                                      options={additionalServicesOptions}
                                      value={selectAdditionalService}
                                      placeholder={'Selecvt Additional Services'}
                                      name="Additional Services"
                                    />

                                    <CFormLabel>Services Price</CFormLabel>
                                    <span className="text-danger" style={{ marginLeft: '5px' }}>
                                      *
                                    </span>
                                    <DefaultInput
                                      type="number"
                                      disabled={true}
                                      placeholder={'Services price'}
                                      value={props?.values?.servicesPrice}
                                      onChange={props?.handleChange('servicesPrice')}
                                    />

                                    <CFormLabel>Additonal Services Price</CFormLabel>
                                    {/* <span className='text-danger' style={{ marginLeft: '5px' }}>*</span> */}
                                    <DefaultInput
                                      type="number"
                                      disabled={true}
                                      placeholder={'Additonal services price'}
                                      value={props?.values?.additionalServicesPrice}
                                      onChange={props?.handleChange('additionalServicesPrice')}
                                    />

                                    <CFormLabel>Approximate Total Price</CFormLabel>
                                    <span className="text-danger" style={{ marginLeft: '5px' }}>
                                      *
                                    </span>
                                    <DefaultInput
                                      type="number"
                                      disabled={true}
                                      placeholder={'Approximate total price'}
                                      value={props?.values?.totalPrice}
                                      onChange={props?.handleChange('totalPrice')}
                                    />
                                  </CCol>
                                ) : (
                                  ''
                                )}

                                {rejectedStatus ? (
                                  <CCol md={6}>
                                    <CFormLabel>Services</CFormLabel>
                                    <span className="text-danger" style={{ marginLeft: '5px' }}>
                                      *
                                    </span>
                                    <Select
                                      isMulti={true}
                                      defaultValue={selectService}
                                      onChange={(val) => {
                                        setSelectService(val)
                                        props?.setFieldValue('services', val)
                                      }}
                                      options={servicesOptions}
                                      value={selectService}
                                      placeholder={'Select Services'}
                                      name="Services"
                                    />
                                    <DefaultTextarea
                                      placeholder={'Description'}
                                      name={'Description'}
                                      value={props?.values?.description}
                                      onChange={props?.handleChange('description')}
                                    />
                                  </CCol>
                                ) : (
                                  ''
                                )}

                                {confirmStatus ? (
                                  <CCol md={6}>
                                    <CFormLabel>Services</CFormLabel>
                                    <span className="text-danger" style={{ marginLeft: '5px' }}>
                                      *
                                    </span>
                                    <Select
                                      isMulti={true}
                                      defaultValue={selectService}
                                      onChange={(val) => {
                                        setSelectService(val)
                                        if (Object.keys(val)?.length == 0) {
                                          servicesPrice = 0
                                          formRef.current.setFieldValue(
                                            'servicesPrice',
                                            servicesPrice,
                                          )
                                        }
                                        allServices.find((element) => {
                                          val?.map((item) => {
                                            if (element?._id == item?.value) {
                                              servicesPrice = servicesPrice + element?.price
                                              formRef.current.setFieldValue(
                                                'servicesPrice',
                                                servicesPrice,
                                              )
                                            }
                                          })
                                        })
                                        props?.setFieldValue('services', val)
                                      }}
                                      options={servicesOptions}
                                      value={selectService}
                                      placeholder={'Select Services'}
                                      name="Services"
                                    />
                                    <CFormLabel>Services Price</CFormLabel>
                                    <span className="text-danger" style={{ marginLeft: '5px' }}>
                                      *
                                    </span>
                                    <DefaultInput
                                      type="number"
                                      disabled={true}
                                      placeholder={'Services price'}
                                      value={props?.values?.servicesPrice}
                                      onChange={props?.handleChange('servicesPrice')}
                                    />
                                    <DefaultTextarea
                                      placeholder={'Description'}
                                      name={'Description'}
                                      value={props?.values?.description}
                                      onChange={props?.handleChange('description')}
                                    />
                                  </CCol>
                                ) : (
                                  ''
                                )}
                              </CRow>
                              <CRow>
                                <CCol md="2">
                                  <button type="submit" className={'btn btn-success mt-2'}>
                                    Submit
                                  </button>
                                </CCol>
                                <CCol md="3">
                                  <button
                                    type="button"
                                    className={'btn btn-warning mt-2'}
                                    onClick={() => setOpenForm(false)}
                                  >
                                    Cancel
                                  </button>
                                </CCol>
                              </CRow>
                            </form>
                          )}
                        </Formik>
                      ) : (
                        ''
                      )}
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default ExaminItems
