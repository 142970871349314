import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { post, get, deleteApi } from '../../utils/apiManager'
import CIcon from '@coreui/icons-react'
import { cilPlus } from '@coreui/icons'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CCardSubtitle,
  CTable,
  CRow,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CButton,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import { DefaultInputWithIcon } from 'src/components/common/input'
import Pagination from 'react-js-pagination'
import { FaRegEye, FaEdit, FaTrash } from 'react-icons/fa'

function index() {
  const navigate = useNavigate()
  const [offerList, setOfferList] = useState([])
  const [searchItem, setSearchItem] = useState('')
  const [totalPages, setTotalPages] = useState(0)
  const [deletedArray, setDeletedArray] = useState([])
  const [pageData, setPageData] = useState({
    page: 1,
    perPage: 10,
    searchItem: '',
  })

  const getData = () => {
    get(
      `/admin/offer-promotion/get-offers?page=${pageData?.page || 1}&perPage=${pageData?.perPage || 10
      }&searchItem=${pageData?.searchItem || ''}`,
    ).then((res) => handleResponse(res))
  }
  const handleResponse = (res) => {
    if (!res?.status) toast.success(res?.message, res?.status)
    else {
      setOfferList([...res?.data])
      setTotalPages(res?.pages)
    }
  }
  const changePage = (index) => {
    setPageData({
      ...pageData,
      page: index,
    })
  }
  const onSearchCLick = () => {
    setPageData({
      ...pageData,
      searchItem: searchItem,
    })
  }
  const statusChange = (status, id) => {
    let default_status = ''
    if (status == 0) default_status = 'Active'
    else default_status = 'Inactive'
    if (confirm(`Are you sure you want to ${default_status} the offer`)) {
      let formData = {
        _id: id,
      }
      post(
        `${status ? '/admin/offer-promotion/inactive-offer' : '/admin/offer-promotion/active-offer'
        }`,
        formData,
      ).then((data) => {
        handleStatusChange(data)
      })
    }
  }
  const handleStatusChange = (data) => {
    toast.success(data?.message, data?.status)
    getData()
  }
  const onClickUserView = (data) => {
    navigate(`/admin/offer/view/${btoa(data?._id)}/${btoa(data?.service_type)}`, { state: data })
  }
  const onClickUserEdit = (data) => {
    navigate(`/admin/offer/edit/${btoa(data?._id)}/${btoa(data?.service_type)}`, { state: data })
  }
  const deleteData = (id) => {
    if (confirm('Are you sure')) {
      deleteApi(`/admin/offer-promotion/delete-offer?_id=${id}`).then((data) => {
        handleDelete(data)
      })
    }
  }
  const handleDelete = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
    } else {
      toast.error(data?.message)
    }

    getData()
  }
  useEffect(() => {
    getData()
  }, [pageData])

  const handleCheck = (id) => {
    let findValue = deletedArray.includes(id)
    if (findValue) {
      let filterData = deletedArray?.filter((item) => item != id)
      setDeletedArray(filterData)
    } else {
      setDeletedArray((prev) => [...prev, id])
    }
  }

  const deleteOrders = () => {
    post(`admin/offer-promotion/bulk-delete-offer`, { ids: deletedArray }).then((data) =>
      handleDeletedResponse(data),
    )
  }

  const handleDeletedResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      setDeletedArray([])
      getData()
    }
  }

  return (
    <>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <div className="d-flex" style={{ flexDirection: 'column' }}>
                    <CCardSubtitle as="h4">Offer Management</CCardSubtitle>
                    {deletedArray.length > 0 && (
                      <button className="btn btn-danger mt-2 text-white" onClick={deleteOrders}>
                        Delete
                      </button>
                    )}
                  </div>
                  <CRow>
                    <CCol md="8">
                      <DefaultInputWithIcon
                        type={'text'}
                        placeholder="Search"
                        customType={'search'}
                        value={searchItem}
                        onClick={onSearchCLick}
                        onChange={(e) => {
                          onSearchCLick()
                          setSearchItem(e?.target?.value)
                        }}
                      />
                    </CCol>
                    <CCol md="4">
                      <Link to={{ pathname: '/admin/offer/add' }} className="btn btn-dark">
                        <CIcon icon={cilPlus} />
                        Add
                      </Link>
                    </CCol>
                  </CRow>
                </CHeader>
                <CCardBody>
                  <CTable bordered hover responsive>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell>S. No</CTableHeaderCell>
                        <CTableHeaderCell>Service Type</CTableHeaderCell>
                        <CTableHeaderCell>Offer Type</CTableHeaderCell>
                        <CTableHeaderCell>Coupon Code</CTableHeaderCell>
                        <CTableHeaderCell>Status</CTableHeaderCell>
                        <CTableHeaderCell>Action</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {offerList?.map((item, index) => {
                        return (
                          <CTableRow key={index}>
                            <CTableDataCell onClick={() => handleCheck(item._id)}>
                              {pageData?.perPage * (pageData?.page - 1) + index + 1}
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  style={{ marginLeft: '2px', marginTop: '-20px' }}
                                  type="checkbox"
                                  value=""
                                  id="flexCheckDefault"
                                  checked={deletedArray?.includes(item._id)}
                                // onChange={() => handleCheck(item._id)}
                                />
                              </div>
                            </CTableDataCell>
                            <CTableDataCell
                              style={{
                                color: item.service_type == 0 ? '#2196F3' : '#d3dc40',
                                fontWeight: item.service_type == 0 ? 500 : 500,
                              }}
                            >
                              {item.service_type == 0 ? 'Reguler' : 'Package'}
                            </CTableDataCell>
                            <CTableDataCell>{item?.offer_type}</CTableDataCell>
                            <CTableDataCell>{item?.couponCode}</CTableDataCell>
                            <CTableDataCell>
                              <CButton
                                size="sm"
                                outline={item?.status ? '1' : '0'}
                                style={{
                                  backgroundColor: item?.status ? '#00796B' : '#D32F2F',
                                  borderColor: item?.status ? '#00796B' : '#D32F2F',
                                }}
                                onClick={() => statusChange(item?.status, item?._id)}
                              >
                                {item?.status ? 'Active' : 'Inactive'}
                              </CButton>
                            </CTableDataCell>
                            <CTableDataCell>
                              <span
                                className="mx-2"
                                onClick={() => onClickUserEdit(item)}
                                style={{ fontSize: '20px', color: '#d3dc40', cursor: 'pointer' }}
                              >
                                <FaEdit />
                              </span>
                              <span
                                className="mx-2 text-danger"
                                onClick={() => deleteData(item?._id)}
                                style={{ fontSize: '20px', padding: '5px', cursor: 'pointer' }}
                              >
                                <FaTrash />
                              </span>
                              <span
                                className="mx-2"
                                onClick={() => onClickUserView(item)}
                                style={{
                                  fontSize: '20px',
                                  color: '#d3dc40',
                                  padding: '5px',
                                  cursor: 'pointer',
                                }}
                              >
                                <FaRegEye />
                              </span>
                            </CTableDataCell>
                          </CTableRow>
                        )
                      })}
                    </CTableBody>
                  </CTable>
                  {!offerList?.length && (
                    <>
                      <h1>No Data</h1>
                    </>
                  )}
                  {offerList?.length ? (
                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={pageData?.page}
                      itemsCountPerPage={pageData?.perPage}
                      totalItemsCount={totalPages * 10}
                      pageRangeDisplayed={5}
                      onChange={changePage}
                    />
                  ) : (
                    ''
                  )}
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default index
