import { CCard, CCardBody, CCardGroup, CCol, CContainer, CFormLabel, CRow } from '@coreui/react'
import { Formik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { DefaultInput, DefaultMobileInput, DefaultTextarea } from 'src/components/common/input'
import { baseURL } from 'src/config/config'
import { post, get } from 'src/utils/apiManager'
import 'react-toastify/dist/ReactToastify.css'
import Select from 'react-select'

function AddColor() {
  const navigate = useNavigate()
  const formRef = useRef(null)

  const submit = async (values) => {
    post(
      `admin/items/color/store`,
      { name: values.colorName, colorCode: values.colorCode },
      0,
    ).then((data) => {
      handleResponse(data)
    })
  }
  const handleResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)

      // navigate('/admin/offline-order')
    } else {
      toast.error(data?.message)
    }
  }
  return (
    <>
      <CContainer>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <CRow>
                        <CCol md="11">
                          <h1>New User</h1>
                        </CCol>
                        <CCol md="1">
                          <Link
                            className={'btn btn-success mt-2'}
                            style={{ background: '#d3dc40', border: '#d3dc40' }}
                            to={{ pathname: `/admin/offline-order` }}
                          >
                            Back
                          </Link>
                        </CCol>
                      </CRow>
                      <Formik
                        initialValues={{
                          colorName: '',
                          colorCode: '',
                        }}
                        // validationSchema={signUp}
                        onSubmit={(values, { setSubmitting }) => {
                          submit(values)
                          setSubmitting(false)
                        }}
                        innerRef={formRef}
                      >
                        {(props) => (
                          <form onSubmit={props?.handleSubmit}>
                            {/* {console.log(props)} */}
                            <CRow>
                              <CCol md={6}>
                                <CFormLabel>Color Name</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Color Name'}
                                  id={'colorName'}
                                  maxLength="70"
                                  value={props?.values?.colorName}
                                  onChange={props?.handleChange('colorName')}
                                  onBlur={() => props?.setFieldTouched('colorName', true)}
                                  error={props?.touched?.colorName && props?.errors?.colorName}
                                />
                              </CCol>
                              <CCol md={6}>
                                <CFormLabel>Color code</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Color Code'}
                                  id={'colorCode'}
                                  value={props?.values?.colorCode}
                                  // value={this.state.name}
                                  onChange={props?.handleChange('colorCode')}
                                  onBlur={() => props?.setFieldTouched('colorCode', true)}
                                  error={props?.touched?.colorCode && props?.errors?.colorCode}
                                />
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol md="2">
                                <button
                                  type="submit"
                                  className={'btn btn-success mt-2'}
                                  // disabled={props?.isSubmitting}
                                >
                                  Submit
                                </button>
                              </CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default AddColor
