import React, { useState, useEffect } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { get } from 'src/utils/apiManager'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CRow,
  CButton,
  CImage,
  CCardHeader,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
} from '@coreui/react'
import { FaArrowLeft } from 'react-icons/fa'
import { ImageUrl } from 'src/config/config'
import avatar1 from 'src/assets/images/avatars/1.jpg'
import orderStatusImage from 'src/assets/images/orderStatusImage.png'

function Itemdetail() {
  const { id } = useParams()
  const { orderId } = useParams()
  const [placedOrderDetails, setPlacedOrderDetails] = useState()
  const [orderItemsList, setOrderItemsList] = useState([])
  const [orderQR, setOrderQR] = useState([])
  const services = []

  const getPlacedOrderInfo = () => {
    get(
      `admin/placed-order/view-singlepacket-details?_id=${atob(id)}&orderId=${atob(orderId)}`,
    ).then((response) => {
      setPlacedOrderDetails(response?.data[0])
      setOrderQR(response?.orderQR)
    })
  }

  const fetchOrderItemsList = () => {
    get(`/admin/placed-order/get-examin-item-details?order_id=${atob(orderId)}`).then((res) => {
      setOrderItemsList(res?.data)
    })
  }
  const showpandingService = (data) => {
    const pendingServiceArr = data?.map((i, k) => {
      return i?.serviceName_EN
    })
    return pendingServiceArr.join(',')
  }
  const showadditionalService = (data) => {
    const additionalServiceArr = data?.map((i, k) => {
      return i?.serviceName_EN
    })
    return additionalServiceArr?.join(',')
  }
  useEffect(() => {
    getPlacedOrderInfo()
    fetchOrderItemsList()
  }, [])
  // console.log(orderItemsList, "orderItemsList")
  // console.log(placedOrderDetails, "placedOrderDetails")
  return (
    <>
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <CRow>
                    <CCol>
                      <Link
                        style={{ color: '#000' }}
                        onClick={() => window.history.back()}
                        // to={{ pathname: `/admin/placed-order` }}
                      >
                        <FaArrowLeft />
                      </Link>
                      <span> Item Details</span>
                    </CCol>
                  </CRow>
                </CHeader>
                <CCardBody>
                  <CCardBody>
                    <CAccordion alwaysOpen>
                      <CAccordionItem itemKey={1}>
                        <CAccordionHeader>Item Information</CAccordionHeader>
                        <CAccordionBody>
                          {Object.keys(orderItemsList)?.length > 0 ? (
                            <CRow className="mt-4">
                              <CCol>
                                <div className="card">
                                  <div className="card-body">
                                    <strong>Item Information</strong>
                                    <hr />
                                    {orderItemsList?.map((data, index) => (
                                      <>
                                        <ul>
                                          <div>
                                            <li>
                                              <strong>Item Number: {index + 1}</strong>
                                            </li>
                                          </div>
                                          <ol>
                                            <CRow>
                                              <CCol md={3}>
                                                <strong>Item Name :-</strong>
                                              </CCol>
                                              <CCol>
                                                <span>{data?.itemName[0]?.itemName_EN}</span>
                                              </CCol>
                                            </CRow>
                                            <CRow>
                                              <CCol md={3}>
                                                <strong>Item Status :-</strong>
                                              </CCol>
                                              <CCol>
                                                {data?.itemStatus == 'rejected' ? (
                                                  <span className="text-danger">Rejected</span>
                                                ) : data?.itemStatus == 'pending' ? (
                                                  <span className="text-info">Pending</span>
                                                ) : data?.itemStatus == 'confirm' ? (
                                                  <span className="text-success">Approved</span>
                                                ) : (
                                                  ''
                                                )}
                                              </CCol>
                                            </CRow>

                                            {data?.itemStatus == 'pending' &&
                                            data?.additionalServices?.length > 0 ? (
                                              <CRow>
                                                <CCol md={3}>
                                                  <strong>Additional Services :-</strong>
                                                </CCol>
                                                <CCol>
                                                  <span>
                                                    {showadditionalService(
                                                      data?.additionalServices,
                                                    )}
                                                  </span>
                                                </CCol>
                                              </CRow>
                                            ) : (
                                              ''
                                            )}

                                            {data?.itemStatus == 'pending' ? (
                                              <>
                                                <CRow>
                                                  <CCol md={3}>
                                                    <strong>Services :-</strong>
                                                  </CCol>
                                                  <CCol>
                                                    <span>
                                                      {showpandingService(data?.services)}
                                                    </span>
                                                  </CCol>
                                                </CRow>
                                                <CRow>
                                                  <CCol md={3}>
                                                    <strong>Services Price:-</strong>
                                                  </CCol>
                                                  <CCol>
                                                    <span>{data?.servicePrice}</span>
                                                  </CCol>
                                                </CRow>
                                                <CRow>
                                                  <CCol md={3}>
                                                    <strong>Additional Services Price:-</strong>
                                                  </CCol>
                                                  <CCol>
                                                    <span>{data?.additionalServicePrice}</span>
                                                  </CCol>
                                                </CRow>
                                                <CRow>
                                                  <CCol md={3}>
                                                    <strong>Total Price :-</strong>
                                                  </CCol>
                                                  <CCol>
                                                    <span>{data?.totalPrice}</span>
                                                  </CCol>
                                                </CRow>
                                              </>
                                            ) : (
                                              ''
                                            )}

                                            {data?.itemStatus == 'confirm' ? (
                                              <>
                                                <CRow>
                                                  <CCol md={3}>
                                                    <strong>Services :-</strong>
                                                  </CCol>
                                                  <CCol md={3}>
                                                    <span>
                                                      {showpandingService(data?.services)}
                                                    </span>
                                                  </CCol>
                                                </CRow>
                                                <CRow>
                                                  <CCol md={3}>
                                                    <strong>Services Price :-</strong>
                                                  </CCol>
                                                  <CCol md={3}>
                                                    <span>{data?.servicePrice}</span>
                                                  </CCol>
                                                </CRow>
                                                <CRow>
                                                  <CCol md={3}>
                                                    <strong>Description :-</strong>
                                                  </CCol>
                                                  <CCol>
                                                    <span>{data?.description}</span>
                                                  </CCol>
                                                </CRow>
                                              </>
                                            ) : (
                                              ''
                                            )}
                                            {data?.itemStatus == 'rejected' ? (
                                              <>
                                                <CRow>
                                                  <CCol md={3}>
                                                    <strong>Services :-</strong>
                                                  </CCol>
                                                  <CCol>
                                                    <span>
                                                      {showpandingService(data?.services)}
                                                    </span>
                                                  </CCol>
                                                </CRow>
                                                <CRow>
                                                  <CCol md={3}>
                                                    <strong>Description :-</strong>
                                                  </CCol>
                                                  <CCol>
                                                    <span>{data?.description}</span>
                                                  </CCol>
                                                </CRow>
                                              </>
                                            ) : (
                                              ''
                                            )}
                                            <CRow>
                                              <CCol md={3}>
                                                <strong>Item Bar Code :-</strong>
                                              </CCol>
                                              <CCol>
                                                <span>
                                                  <p
                                                    dangerouslySetInnerHTML={{
                                                      __html: data?.barCode,
                                                    }}
                                                  ></p>
                                                </span>
                                              </CCol>
                                            </CRow>
                                            <CRow>
                                              <CCol md={3}>
                                                <strong>Images :-</strong>
                                              </CCol>
                                              <CCol>
                                                {data?.image?.map((i, index) => {
                                                  return (
                                                    <a target="_blank" href={`${ImageUrl}${i}`}>
                                                      <CImage
                                                        rounded
                                                        src={`${ImageUrl}${i}`}
                                                        className="mx-2"
                                                        style={{
                                                          maxHeight: '80px',
                                                          maxWidth: '80px',
                                                          alignItems: 'center',
                                                        }}
                                                      />
                                                    </a>
                                                  )
                                                })}
                                              </CCol>
                                            </CRow>
                                          </ol>
                                        </ul>
                                        <hr />
                                      </>
                                    ))}
                                  </div>
                                </div>
                              </CCol>
                            </CRow>
                          ) : (
                            <strong className="text-danger">
                              <center>No Item Added Yet</center>
                            </strong>
                          )}
                        </CAccordionBody>
                      </CAccordionItem>
                    </CAccordion>
                  </CCardBody>
                  {/* </CCard> */}
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default Itemdetail
