import React, { useState, useEffect } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { get } from 'src/utils/apiManager';
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CRow,
  CImage,
} from '@coreui/react'
import { ImageUrl } from 'src/config/config'
import { FaArrowLeft } from 'react-icons/fa'

function view() {
  const { id } = useParams()
  const [address, setAddress] = useState([]);
  const [userArr, setUserArr] = useState([]);
  const userName = [];
  const [cityArr, setCityArr] = useState([]);
  const cityName = [];
  const [stateArr, setStateArr] = useState([]);
  const stateName = [];
  const [areaArr, setAreaArr] = useState([]);
  const areaName = [];
  const getService = () => {

    get(`admin/address/view?_id=${atob(id)}`).then((data) => {
      if (data?.data !== null) {
        const tempData = data?.data?.map((item, key) => {
          setAddress(item)
          var userData = [];
          var stateData = [];
          var cityData = [];
          var areaData = [];
          userData?.push(item?.user_id)
          setUserArr(userData)
          stateData?.push(item?.state_id)
          setStateArr(stateData)
          cityData?.push(item?.city_id)
          setCityArr(cityData)
          areaData?.push(item?.area_id)
          setAreaArr(areaData)
        })
      }
    })
  }
  useEffect(() => {
    getService();
  }, [])
  return (
    <>
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <CRow>
                    <CCol >
                      {' '}
                      <Link style={{ color: '#000' }} to={{ pathname: `/admin/address` }}>
                        <FaArrowLeft />
                      </Link>
                      <span>{' '} Address Information</span>
                    </CCol>
                  </CRow>
                </CHeader>
                <CCardBody>
                  <CRow>
                    <CCol md={2}>
                      <strong>User Name :-</strong>
                    </CCol>
                    <CCol>
                      {userArr?.map((data, key) => {
                        userName?.push(data?.username)
                      })}
                      <span>{userName}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Type :-</strong>
                    </CCol>
                    <CCol>
                      <span>{address?.type}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Address :-</strong>
                    </CCol>
                    <CCol>
                      <span>{address?.address}</span>
                    </CCol>
                  </CRow>
                  {/* <CRow>
                  <CCol md={2}>
                    <strong>State Name :-</strong>
                  </CCol>
                  <CCol>
                  {stateArr.map((data,key)=>{
                    stateName.push(data.stateName_EN)
                    })}
                    <span>{stateName}</span>
                  </CCol>
                </CRow> */}
                  <CRow>
                    <CCol md={2}>
                      <strong>City Name :-</strong>
                    </CCol>
                    <CCol>
                      {cityArr?.map((data, key) => {
                        cityName?.push(data?.cityName_EN)
                      })}
                      <span>{cityName}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Area Name :-</strong>
                    </CCol>
                    <CCol>
                      {areaArr?.map((data, key) => {
                        areaName?.push(data?.areaName_EN)
                      })}
                      <span>{areaName}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Current Selected Address :-</strong>
                    </CCol>
                    <CCol>
                      <span>{address?.default == 1 ? "Active" : "Inactive"}</span>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default view