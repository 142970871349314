import React, { useState, useEffect } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { get, post, put } from 'src/utils/apiManager';
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CRow,
  CButton,
  CImage,
  CCardHeader,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
  CFormLabel,
} from '@coreui/react'
import { FaArrowLeft, FaSearchengin, FaWallet } from 'react-icons/fa'
import { ImageUrl } from 'src/config/config';
import avatar1 from 'src/assets/images/avatars/1.jpg'
import orderStatusImage from 'src/assets/images/orderStatusImage.png'
import { cibElectron } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import Select from 'react-select'
import { ChangeOrderStatus, ChangeDriverStatus } from 'src/constants/statusConstants'
import { ToastContainer, toast } from 'react-toastify';

function view() {
  const { id } = useParams();
  const { orderId } = useParams();
  const [placedOrderDetails, setPlacedOrderDetails] = useState();
  const [deliveryDropImages, setDeliveryDropImages] = useState();
  const [orderQR, setOrderQR] = useState([]);
  const [QROptions, setQROptions] = useState([]);
  const services = [];
  const [selectedOrderstatus, setSelectedOrderstatus] = useState([]);
  const [selectedQR, setSelectedQR] = useState([]);
  const [selectedDriverstatus, setSelectedDriverstatus] = useState([]);
  const OrderStatusOptions = [
    { value: ChangeOrderStatus?.obj?.OrderPlace, label: "Order Placed" },
    // { value: ChangeOrderStatus?.obj?.CancelRequest, label: "Cancel Request" },
    { value: ChangeOrderStatus?.obj?.pickedUp, label: "Picked Up" },
    { value: ChangeOrderStatus?.obj?.InprocessClothesExamine, label: "Clothes examined" },
    // { value: ChangeOrderStatus?.obj?.InprocessService, label: "Service Process" },
    { value: ChangeOrderStatus?.obj?.InprocessPayment, label: "Waiting for payment" },
    // { value: ChangeOrderStatus?.obj?.delivery, label: "Delivery" },
    // { value: ChangeOrderStatus?.obj?.deliverd, label: "waiting for payment" },
    { value: ChangeOrderStatus?.obj?.deliverd, label: "Deliverd" },
    { value: ChangeOrderStatus?.obj?.hold, label: "Pending" },
    { value: ChangeOrderStatus?.obj?.CancelCustomer, label: "Cancel customer" },
    { value: ChangeOrderStatus?.obj?.CancelDriver, label: "Cancel by driver" },
    { value: ChangeOrderStatus?.obj?.CancelAdmin, label: "Cancel by admin" },
    { value: ChangeOrderStatus?.obj?.OutForDelivery, label: "Out For Delivery" },
  ]
  const DriverStatusOptions = [
    { value: ChangeDriverStatus?.obj?.OrderPlace, label: "Order Placed" },
    { value: ChangeDriverStatus?.obj?.CancelPickup, label: "Pickup Cancellation" },
    { value: ChangeDriverStatus?.obj?.CancelDeliver, label: "Deliver Cancellation" },
    { value: ChangeDriverStatus?.obj?.PickUp, label: "Pick Up" },
    { value: ChangeDriverStatus?.obj?.Inprocess, label: "In Warehouse" },
    // { value: ChangeDriverStatus?.obj?.delivery, label: "Delivery" },
    { value: ChangeDriverStatus?.obj?.deliverd, label: "Deliverd" },
    { value: ChangeDriverStatus?.obj?.hold, label: "Pending" },
    { value: ChangeDriverStatus?.obj?.CancelCustomer, label: "Cancel customer" },
    { value: ChangeDriverStatus?.obj?.OutForDelivery, label: "Out For Delivery" },
  ]
  const ChangeStatus = (data, type) => {
    const temp = {
      orderId: atob(orderId),
      orderStatus: "",
      driverStatus: ""
    }
    if (confirm("Are you sure want to change status")) {
      if (type == 'customer') {
        setSelectedOrderstatus(OrderStatusOptions?.filter((val) => val?.value == data?.value))
        temp.orderStatus = data?.value;
      } else {
        setSelectedDriverstatus(DriverStatusOptions?.filter((status) => status?.value == data?.value))
        // console.log("driver ka status change krna h ")
        temp.driverStatus = data?.value
      }
      put(`/admin/order-status/change-order-status`, temp).then((res) => handleResponse(res));
    }
  };
  const handleResponse = (res) => {
    if (res?.status == true) {
      toast.success(res?.message)
      getPlacedOrderInfo()
    }
  }
  const getPlacedOrderInfo = () => {
    get(`admin/placed-order/view-singlepacket-details?_id=${atob(id)}&orderId=${atob(orderId)}`).then((response) => {
      setPlacedOrderDetails(response?.data[0])
      setSelectedOrderstatus(OrderStatusOptions.filter((val) => val.value == response?.data[0]?.orderStatus))
      setSelectedDriverstatus(DriverStatusOptions.filter((val) => val.value == response?.data[0]?.driverStatus))
      setDeliveryDropImages(response?.data[0]?.dropOff_Images[0]?.dropOffImage)
      setOrderQR(response?.orderQR)
    })
  };
  const getQRCodeList = async () => {
    let data = await get(`admin/genrate-QR/get-QR-order?orderId=${orderId}`);
    let codeData = data.data;
    let newArr = [];
    for (const iterator of codeData) {
      if (iterator.order_id == atob(orderId)) {
        setSelectedQR({
          value: iterator._id,
          label: iterator?.QrId,
        })
      }
      let obj = {
        value: iterator._id,
        label: iterator?.QrId,
      }
      newArr.push(obj)
    }
    setQROptions(newArr)
  }
  const handleQRCode = async (v) => {
    let obj = {
      qrId: v.value,
      orderId: atob(orderId)
    }
    let data = await post(`admin/genrate-QR/reassgin-order`, obj);
    if (data.status) {
      toast.success(data?.message)
      getQRCodeList()
      getPlacedOrderInfo()
    }
  }
  // const fetchOrderItemsList = () => {
  //   get(`/admin/placed-order/get-examin-item-details?order_id=${atob(orderId)}`).then((res) => {
  //     setOrderItemsList(res?.data)
  //   })
  // }
  const showpandingService = (data) => {
    const pendingServiceArr = data?.map((i, k) => {
      return i?.serviceName_EN;
    })
    return pendingServiceArr.join(',')
  }
  const showadditionalService = (data) => {
    const additionalServiceArr = data?.map((i, k) => {
      return i?.serviceName_EN;
    })
    return additionalServiceArr?.join(',')
  }
  useEffect(() => {
    getPlacedOrderInfo();
    getQRCodeList();
  }, []);
  return (
    <>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <CRow>
                    <CCol >
                      {' '}
                      {/* <Link style={{ color: '#000' }} to={{ pathname: `/admin/placed-order` }}>
                        <FaArrowLeft />
                      </Link> */}
                      <span>{' '}About Order Details</span>
                    </CCol>
                  </CRow>
                </CHeader>
                <CCardBody>
                  <CRow className="mt-4">
                    <CCol>
                      <div className="card">
                        <div className="card-body">
                          <strong>User Information</strong>
                          <hr />
                          <CRow>
                            <CCol md={10}>
                              {/* <span><i> 🙂 order detail  🙂</i></span> */}
                              <CRow>
                                <CCol md={2}>
                                  <strong>User Name :-</strong>
                                </CCol>
                                <CCol>
                                  <span>{placedOrderDetails?.userId[0]?.username}</span>
                                </CCol>
                              </CRow>
                              <CRow>
                                <CCol md={2}>
                                  <strong>User Email :-</strong>
                                </CCol>
                                <CCol>
                                  <span>{placedOrderDetails?.userId[0]?.email}</span>
                                </CCol>
                              </CRow>
                              <CRow>
                                <CCol md={2}>
                                  <strong>User Mobile NO. :-</strong>
                                </CCol>
                                <CCol>
                                  <span>{placedOrderDetails?.userId[0]?.mobile}</span>
                                </CCol>
                              </CRow>
                              <CRow>
                                <CCol md={3}>
                                  <strong>User Note To Garment Experts:-</strong>
                                </CCol>
                                <CCol>
                                  <span>{placedOrderDetails?.noteToGarmentExpert}</span>
                                </CCol>
                              </CRow>
                            </CCol>

                            <CCol md={2}>
                              <CRow>
                                <CCol>
                                  <div className="myImges row">

                                    <div className="col-md-10">
                                      <figure>
                                        <img src={`${ImageUrl}${placedOrderDetails?.userId[0]?.image}`} alt='img' />
                                        <figcaption>
                                          {/* <button type="button"><FaTrash onClick={() => deleteParticularFile(index)} style={{ color: 'red' }} /></button> */}
                                        </figcaption>
                                      </figure>
                                    </div>
                                  </div>
                                </CCol>
                              </CRow>
                            </CCol>
                          </CRow>
                        </div>
                      </div>
                    </CCol>
                  </CRow>
                  {/* ORDER INFORMATION CODE IS HERE */}
                  <CRow className="mt-4">
                    <CCol>
                      <div className="card">
                        <div className="card-body">
                          <CRow>
                            <CCol md={3}><strong>Order Information</strong></CCol>
                            {(orderQR && orderQR.length) > 0 ? <CCol md={3}>
                              <Select
                                // isDisabled={selectedOrderstatus[0]?.label == "Deliverd" ? true : false}
                                defaultValue={selectedQR}
                                onChange={handleQRCode}
                                options={QROptions}
                                value={selectedQR}
                                placeholder={"Change Order QR"}
                                name={"Change Order QR"}
                              />
                              {/* {console.log(selectedOrderstatus[0]?.label == Deliverd)} */}
                            </CCol> : <CCol md={3}></CCol>}
                            <CCol md={6}>
                              <Select
                                // isDisabled={selectedOrderstatus[0]?.label == "Deliverd" ? true : false}
                                defaultValue={selectedOrderstatus}
                                onChange={(val) => { ChangeStatus(val, "customer") }}
                                options={OrderStatusOptions}
                                value={selectedOrderstatus}
                                placeholder={"Change Order Status"}
                                name={"Change Order Status"}
                              />
                              {/* {console.log(selectedOrderstatus[0]?.label == Deliverd)} */}
                            </CCol>
                          </CRow>
                          <hr />
                          <CRow>
                            <CCol md={10}>
                              {/* <span><i> 🙂 order detail  🙂</i></span> */}
                              <CRow>
                                <CCol md={2}>
                                  <strong>Order ID :-</strong>
                                </CCol>
                                <CCol>
                                  <span>{placedOrderDetails?.orderId}</span>
                                </CCol>
                              </CRow>
                              <CRow>
                                <CCol md={2}>
                                  <strong>Order Status :-</strong>
                                </CCol>
                                <CCol>
                                  {
                                    placedOrderDetails?.orderStatus == 'order_place' ?
                                      <CButton size="sm" style={{ backgroundColor: '#d3dc40', border: '#d3dc40' }} outline={placedOrderDetails?.orderStatus}>
                                        <span><strong>Order Place</strong></span>
                                      </CButton>
                                      : placedOrderDetails?.orderStatus == 'cancel(request)' ?
                                        <CButton size="sm" style={{ backgroundColor: '#BDBDBD', border: '#BDBDBD' }} outline={placedOrderDetails?.orderStatus}>
                                          <span><strong>Customer Cancel Request</strong></span>
                                        </CButton>
                                        : placedOrderDetails?.orderStatus == 'cancel(customer)' ?
                                          <CButton size="sm" style={{ backgroundColor: '#757575', border: '#757575' }} outline={placedOrderDetails?.orderStatus}>
                                            <span><strong>Cancel By Customer</strong></span>
                                          </CButton>
                                          : placedOrderDetails?.orderStatus == 'inprocess(Examine clothes)' ?
                                            <CButton size="sm" style={{ backgroundColor: '#757575', border: '#757575' }} outline={placedOrderDetails?.orderStatus}>
                                              <span><strong>In Process Checking Items {''}<FaSearchengin /></strong></span>
                                            </CButton>
                                            : placedOrderDetails?.orderStatus == 'inprocess(service process)' ?
                                              <CButton size="sm" style={{ backgroundColor: '#BDBDBD', border: '#BDBDBD' }} outline={placedOrderDetails?.orderStatus}>
                                                <span><strong>In Process Service {' '}<CIcon icon={cibElectron} customClassName="nav-icon" style={{ height: '20px' }} /> </strong></span>
                                              </CButton>
                                              : placedOrderDetails?.orderStatus == 'inprocess(payment success)' ?
                                                <CButton CButton size="sm" style={{ backgroundColor: '#757575', border: '#757575' }} outline={placedOrderDetails?.orderStatus}>
                                                  <span><strong>In Process Payment {' '} <FaWallet /></strong></span>
                                                </CButton>
                                                : placedOrderDetails?.orderStatus == 'cancel' ?
                                                  <CButton size="sm" style={{ backgroundColor: '#FF0000', border: '#FF0000' }} outline={placedOrderDetails?.orderStatus}>
                                                    <span><strong>Cancel</strong></span>
                                                  </CButton>
                                                  : placedOrderDetails?.orderStatus == 'hold' ?
                                                    <CButton size="sm" style={{ backgroundColor: '#2196F3', border: '#2196F3' }} outline={placedOrderDetails?.orderStatus}>
                                                      <span><strong>Hold</strong></span>
                                                    </CButton>
                                                    : placedOrderDetails?.orderStatus == 'deliverd' ?
                                                      <CButton size="sm" style={{ backgroundColor: '#2E7D32', border: '#2E7D32' }} outline={placedOrderDetails?.orderStatus}>
                                                        <span><strong>Deliverd</strong></span>
                                                      </CButton>
                                                      : placedOrderDetails?.orderStatus == 'delivery' ?
                                                        <CButton size="sm" style={{ backgroundColor: '#689F38', border: '#689F38' }} outline={placedOrderDetails?.orderStatus}>
                                                          <span><strong>Ready to Deliver</strong></span>
                                                        </CButton>
                                                        : placedOrderDetails?.orderStatus == 'picked_up' ?
                                                          <CButton size="sm" style={{ backgroundColor: '#FBC02D', border: '#FBC02D' }} outline={placedOrderDetails?.orderStatus}>
                                                            <span><strong>Picked Up</strong></span>
                                                          </CButton>
                                                          : placedOrderDetails?.orderStatus == 'out_for_delivery' ?
                                                            <CButton size="sm" style={{ backgroundColor: '#689F38', border: '#689F38' }} outline={placedOrderDetails?.orderStatus}>
                                                              <span><strong>Out for deliver</strong></span>
                                                            </CButton>
                                                            : 'In Process'
                                  }

                                </CCol>
                              </CRow>
                              <CRow>
                                <CCol md={2}>
                                  <strong>Address :-</strong>
                                </CCol>
                                <CCol>
                                  <span>{placedOrderDetails?.addressId[0]?.address}</span>
                                </CCol>
                              </CRow>
                              {placedOrderDetails?.addOnServiceId?.length > 0 ? <CRow>
                                <CCol md={2}>
                                  <strong>Add On Services :-</strong>
                                </CCol>
                                <CCol>
                                  {placedOrderDetails?.addOnServiceId.map((i, k) => {
                                    services?.push(i?.serviceName_EN)
                                  })}
                                  <span>{services?.join(' , ')}</span>
                                </CCol>
                              </CRow> : ''}
                              <CRow>
                                <CCol md={2}>
                                  <strong>Offer Data :-</strong>
                                </CCol>
                                <CCol>
                                  <span>{placedOrderDetails?.offerData}</span>
                                </CCol>
                              </CRow>
                              <CRow>
                                <CCol md={2}>
                                  <strong>Number of Packets :-</strong>
                                </CCol>
                                <CCol>
                                  <span>{placedOrderDetails?.noOfPacket}</span>
                                </CCol>
                              </CRow>
                              <CRow>
                                <CCol md={2}>
                                  <strong>Apply Offer :-</strong>
                                </CCol>
                                <CCol>
                                  <span>{placedOrderDetails?.applyOffer}</span>
                                </CCol>
                              </CRow>
                            </CCol>

                            <CCol md={2}>
                              <CRow>
                                <CCol>
                                  {orderQR?.map((i, k) => {
                                    return <p style={{ textAlign: "center" }}><img src={i.QrImage} alt="Img" />
                                      <br />
                                      {/* <small style={{ fontWeight: "600" }}> QR{i.QrId}</small> */}
                                      <small style={{ fontWeight: "600" }}>{i._id}</small>
                                    </p>
                                  })}
                                </CCol>
                              </CRow>
                            </CCol>
                          </CRow>
                        </div>
                      </div>
                    </CCol>
                  </CRow>
                  {/* DRIVER INFORMATION CODE IS HERE */}
                  <CRow className="mt-4">
                    <CCol>
                      <div className="card">
                        <div className="card-body">
                          {/* <CRow>
                            <CCol md={6}><strong>Pickup Driver Information</strong></CCol>
                            <CCol>
                              <Select
                                //  isDisabled={selectedDriverstatus[0]?.label == "Deliverd" ? true : false}
                                defaultValue={selectedDriverstatus}
                                onChange={(val) => { ChangeStatus(val, "driver") }}
                                options={DriverStatusOptions}
                                value={selectedDriverstatus}
                                placeholder={"Change driver Status"}
                                name={"Change driver Status"}
                              />
                            </CCol>
                          </CRow> */}

                          <hr />
                          <CRow>
                            <CCol md={10}>
                              {/* <span><i> 🙂 order detail  🙂</i></span> */}
                              <CRow>
                                <CCol md={3}>
                                  <strong>Driver Name :-</strong>
                                </CCol>
                                <CCol>
                                  <span>{placedOrderDetails?.pickup_driver[0]?.username}</span>
                                </CCol>
                              </CRow>
                              <CRow>
                                <CCol md={3}>
                                  <strong>Driver Email :-</strong>
                                </CCol>
                                <CCol>
                                  <span>{placedOrderDetails?.pickup_driver[0]?.email}</span>
                                </CCol>
                              </CRow>
                              <CRow>
                                <CCol md={3}>
                                  <strong>Driver Mobile NO. :-</strong>
                                </CCol>
                                <CCol>
                                  <span>{placedOrderDetails?.pickup_driver[0]?.mobile}</span>
                                </CCol>
                              </CRow>
                              <CRow>
                                <CCol md={3}>
                                  <strong>Driver Status :-</strong>
                                </CCol>
                                <CCol>
                                  {
                                    placedOrderDetails?.driverStatus == 'order_place' ?
                                      <CButton size="sm" style={{ backgroundColor: '#d3dc40', border: '#d3dc40' }} outline={placedOrderDetails?.driverStatus}>
                                        <span><strong>Order Place</strong></span>
                                      </CButton>
                                      : placedOrderDetails?.driverStatus == 'hold' ?
                                        <CButton size="sm" style={{ backgroundColor: '#BDBDBD', border: '#BDBDBD' }} outline={placedOrderDetails?.driverStatus}>
                                          <span><strong>Hold</strong></span>
                                        </CButton>
                                        : placedOrderDetails?.driverStatus == 'cancel(customer)' ?
                                          <CButton size="sm" style={{ backgroundColor: '#757575', border: '#757575' }} outline={placedOrderDetails?.driverStatus}>
                                            <span><strong>Cancel By Customer</strong></span>
                                          </CButton>
                                          : placedOrderDetails?.driverStatus == 'pickup' ?
                                            <CButton size="sm" style={{ backgroundColor: '#FBC02D', border: '#FBC02D' }} outline={placedOrderDetails?.orderStatus}>
                                              <span><strong>Picked Up</strong></span>
                                            </CButton>
                                            : placedOrderDetails?.driverStatus == 'cancel(pickup)' ?
                                              <CButton size="sm" style={{ backgroundColor: '#2196F3', border: '#2196F3' }} outline={placedOrderDetails?.orderStatus}>
                                                <span><strong>Cancel By Dirver at Pickup</strong></span>
                                              </CButton>
                                              : placedOrderDetails?.driverStatus == 'inprocess(picked up)' ?
                                                <CButton size="sm" style={{ backgroundColor: '#757575', border: '#757575' }} outline={placedOrderDetails?.orderStatus}>
                                                  <span><strong>In Process {''}</strong></span>
                                                </CButton>
                                                : placedOrderDetails?.driverStatus == 'deliverd' ?
                                                  <CButton size="sm" style={{ backgroundColor: '#2E7D32', border: '#2E7D32' }} outline={placedOrderDetails?.driverStatus}>
                                                    <span><strong>Deliverd</strong></span>
                                                  </CButton>
                                                  : placedOrderDetails?.driverStatus == 'delivery' ?
                                                    <CButton size="sm" style={{ backgroundColor: '#689F38', border: '#689F38' }} outline={placedOrderDetails?.driverStatus}>
                                                      <span><strong>Ready to Deliver</strong></span>
                                                    </CButton>
                                                    : placedOrderDetails?.driverStatus == 'out_for_delivery' ?
                                                      <CButton size="sm" style={{ backgroundColor: '#689F38', border: '#689F38' }} outline={placedOrderDetails?.driverStatus}>
                                                        <span><strong>Out for deliver</strong></span>
                                                      </CButton>
                                                      : 'IN PROCESS'
                                  }
                                </CCol>
                              </CRow>
                              <CRow>
                                <CCol md={3}>
                                  <strong>Note To Driver :-</strong>
                                </CCol>
                                <CCol>
                                  <span>{placedOrderDetails?.noteToDriver}</span>
                                </CCol>
                              </CRow>
                              <CRow>
                                <CCol md={3}>
                                  <strong>Note To Garment Experts:-</strong>
                                </CCol>
                                <CCol>
                                  <span>{placedOrderDetails?.noteToGarmentExpert}</span>
                                </CCol>
                              </CRow>
                            </CCol>
                            <CCol md={2}>
                              <CRow>
                                <CCol>
                                  <div className="myImges row">

                                    <div className="col-md-10">
                                      <figure>
                                        <img src={`${ImageUrl}${placedOrderDetails?.pickup_driver[0]?.image == undefined ? "img/dummy_user.png" : placedOrderDetails?.pickup_driver[0]?.image}`} alt='img' />
                                        <figcaption>
                                          {/* <button type="button"><FaTrash onClick={() => deleteParticularFile(index)} style={{ color: 'red' }} /></button> */}
                                        </figcaption>
                                      </figure>
                                    </div>
                                  </div>
                                </CCol>
                              </CRow>
                            </CCol>
                          </CRow>
                        </div>
                      </div>
                    </CCol>
                  </CRow>
                  {/* DELIVER DRIVER INFORMATION CODE IS HERE */}
                  {placedOrderDetails?.dropOff_driver?.length > 0 && <CRow className="mt-4">
                    <CCol>
                      <div className="card">
                        <div className="card-body">
                          <strong>Deliver Driver Information</strong>
                          <hr />
                          <CRow>
                            <CCol md={10}>
                              <CRow>
                                <CCol md={3}>
                                  <strong>Driver Name :-</strong>
                                </CCol>
                                <CCol>
                                  <span>{placedOrderDetails?.dropOff_driver[0]?.username}</span>
                                </CCol>
                              </CRow>
                              <CRow>
                                <CCol md={3}>
                                  <strong>Driver Email :-</strong>
                                </CCol>
                                <CCol>
                                  <span>{placedOrderDetails?.dropOff_driver[0]?.email}</span>
                                </CCol>
                              </CRow>
                              <CRow>
                                <CCol md={3}>
                                  <strong>Driver Mobile NO. :-</strong>
                                </CCol>
                                <CCol>
                                  <span>{placedOrderDetails?.dropOff_driver[0]?.mobile}</span>
                                </CCol>
                              </CRow>
                              <CRow>
                                <CCol md={3}>
                                  <strong>Driver Status :-</strong>
                                </CCol>
                                <CCol>
                                  {
                                    placedOrderDetails?.driverStatus == 'deliverd' ?
                                      <CButton size="sm" style={{ backgroundColor: '#2E7D32', border: '#2E7D32' }} outline={placedOrderDetails?.driverStatus}>
                                        <span><strong>Deliverd</strong></span>
                                      </CButton>
                                      : ''
                                  }
                                </CCol>
                              </CRow>
                              <CRow>
                                <CCol md={3}>
                                  <strong>Delivery Drop Off Images:-</strong>
                                </CCol>
                                <CCol>
                                  {deliveryDropImages?.map((i, index) => {
                                    // console.log(i, "map iiiiiii")
                                    return (
                                      <a target='_blank' href={`${ImageUrl}${i}`}>
                                        <CImage
                                          rounded
                                          src={`${ImageUrl}${i}`}
                                          className="mx-2 mt-4"
                                          style={{
                                            maxHeight: '80px',
                                            maxWidth: '80px',
                                            alignItems: 'center',
                                          }}
                                        />
                                      </a>
                                    )
                                  })}
                                  {/* <span>{placedOrderDetails?.noteToGarmentExpert}</span> */}
                                </CCol>
                              </CRow>
                            </CCol>
                            <CCol md={2}>
                              <CRow>
                                <CCol>
                                  <div className="myImges row">

                                    <div className="col-md-10">
                                      <figure>
                                        <img src={`${ImageUrl}${placedOrderDetails?.dropOff_driver[0]?.image}`} alt='img' />

                                        <figcaption>
                                          {/* <button type="button"><FaTrash onClick={() => deleteParticularFile(index)} style={{ color: 'red' }} /></button> */}
                                        </figcaption>
                                      </figure>
                                    </div>
                                  </div>
                                </CCol>
                              </CRow>
                            </CCol>
                          </CRow>
                        </div>
                      </div>
                    </CCol>
                  </CRow>}
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default view