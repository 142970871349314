import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { get } from 'src/utils/apiManager';
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CRow,
} from '@coreui/react'
import { FaArrowLeft } from 'react-icons/fa'

function view() {
  const { state } = useLocation()
  const [orderPlacedData, setOrderPlacedData] = useState([]);

  const getOrderPlacedData = () => {
      get(
        `/admin/order-note/view-orderNote?_id=${state?._id}`
      ).then((res) => {
        res?.data?.map((item, i) => {
          setOrderPlacedData(item)
        })
      })
  }

  useEffect(() => {
    getOrderPlacedData();
  }, [state])
  return (
    <>
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <CRow>
                    <CCol >
                      {' '}
                      <Link style={{ color: '#000' }} to={{ pathname: `/admin/order-note` }}>
                        <FaArrowLeft />
                      </Link>
                      <span>Order Note Details</span>
                    </CCol>
                  </CRow>
                </CHeader>
                <CCardBody>
                  <CRow>
                    <CCol md={3}>
                      <strong>Note (EN) :-</strong>
                    </CCol>
                    <CCol>
                      <span>{orderPlacedData?.note_EN}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={3}>
                      <strong>Note (TH) :-</strong>
                    </CCol>
                    <CCol>
                      <span>{orderPlacedData?.note_TH}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={3}>
                      <strong>Order Note (EN) :-</strong>
                    </CCol>
                    <CCol>
                      <span>{orderPlacedData?.orderNote_EN}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={3}>
                      <strong>Order Note (TH) :-</strong>
                    </CCol>
                    <CCol>
                      <span>{orderPlacedData?.orderNote_TH}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={3}>
                      <strong>Timing Note (EN) :-</strong>
                    </CCol>
                    <CCol>
                      <span>{orderPlacedData?.timingNote_EN}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={3}>
                      <strong>Base Amount of Free Delivery :-</strong>
                    </CCol>
                    <CCol>
                      <span>{orderPlacedData?.baseAmountOfFreeDelivery}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={3}>
                      <strong>Place to Pickup Time (in hours):-</strong>
                    </CCol>
                    <CCol>
                      <span>{orderPlacedData?.placeToPickupTime}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={3}>
                      <strong>Pickup to Delivery Time (in hours):-</strong>
                    </CCol>
                    <CCol>
                      <span>{orderPlacedData?.pickupToDeliverTime}</span>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default view