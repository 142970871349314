import React, { useState, useEffect } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { get } from 'src/utils/apiManager';
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CRow,
  CButton,
  CImage,
} from '@coreui/react'
import { FaArrowLeft } from 'react-icons/fa'
import moment from 'moment';
import { ImageUrl } from 'src/config/config'

function view() {
  const { state } = useLocation();
  const { id } = useParams();
  const { service_type } = useParams()
  const [offerDetails, setOfferDetails] = useState();
  const [toDate, setToDate] = useState();
  const [fromDate, setFromDate] = useState();
  const [selectedServices, setSelectedServices] = useState();
  const Ids_list = [];

  const getOfferDetails = () => {

    get(`admin/offer-promotion/view-offer?_id=${atob(id)}`).then((res) => {
      if (res.data !== null) {
        res?.data?.map((item, index) => {
          const to_d = moment(item?.to_date).add(+1, 'days')
          const to_date = moment(to_d).utc().format('DD/MM/YYYY')

          const from_d = moment(item?.from_date).add(+1, 'days')
          const from_date = moment(from_d).utc().format('DD/MM/YYYY')

          // const to_date = moment(item?.to_date).utc().format('DD/MM/YYYY')
          // const from_Date = moment(item?.from_Date).utc().format('DD/MM/YYYY')

          setToDate(to_date);
          setFromDate(from_date)
          setOfferDetails(item)
        })
      }
    })
  };

  const getServices = () => {

    get(`admin/offer-promotion/get-edit?_id=${atob(id)}&service_type=${atob(service_type)}`).then((data) => {
      const selService = []
      data?.data?.map((item, key) => {
        if (item?.service_type == 1) {
          item?.package_id?.map((data, key) => {
            selService?.push({ label: data?.packageName_EN, value: data?._id })
          })
          setSelectedServices(selService)
        } else {
          item?.regular_id?.map((data, key) => {
            selService?.push({ label: data?.serviceName_EN, value: data?._id })
          })
          setSelectedServices(selService)
        }
      })
    })
  }

  useEffect(() => {
    getOfferDetails();
  }, []);

  useEffect(() => {
    getServices();
  }, [state])

  return (
    <>
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <CRow>
                    <CCol >
                      {' '}
                      <Link style={{ color: '#000' }} to={{ pathname: `/admin/offer` }}>
                        <FaArrowLeft />
                      </Link>
                      <span>{' '} Offer Details</span>
                    </CCol>
                  </CRow>
                </CHeader>
                <CCardBody>
                  <CRow>
                    <CCol md={2}>
                      <strong>Title (EN) :-</strong>
                    </CCol>
                    <CCol>
                      <span>{offerDetails?.title_EN}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Title (TH) :-</strong>
                    </CCol>
                    <CCol>
                      <span>{offerDetails?.title_TH}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Service Type :-</strong>
                    </CCol>
                    <CCol>
                      {offerDetails?.service_type === "0" ? <span>{'Regular'}</span> : <span>{'Package'}</span>}
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      {offerDetails?.service_type === "0" ? <strong>Regular Offers :-</strong> : <strong>Package Offers :-</strong>}
                    </CCol>
                    <CCol>
                      {selectedServices?.map((data, key) => {
                        Ids_list?.push(data?.label)
                      })}
                      <span>{Ids_list?.join(',')}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Offer Type :-</strong>
                    </CCol>
                    <CCol>
                      <span>{offerDetails?.offer_type}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Offer Value :-</strong>
                    </CCol>
                    <CCol>
                      <span>{offerDetails?.offerQuantity}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Coupon Code :-</strong>
                    </CCol>
                    <CCol>
                      <span>{offerDetails?.couponCode}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>From Date :-</strong>
                    </CCol>
                    <CCol>
                      <span>{fromDate}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>To Date :-</strong>
                    </CCol>
                    <CCol>
                      <span>{toDate}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Minimum Cart Value :-</strong>
                    </CCol>
                    <CCol>
                      <span>{offerDetails?.minimumCartValue}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Description (EN) :-</strong>
                    </CCol>
                    <CCol>
                      <span>{offerDetails?.description_EN}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Description (TH) :-</strong>
                    </CCol>
                    <CCol>
                      <span>{offerDetails?.description_TH}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Image (EN):-</strong>
                    </CCol>
                    <CCol>
                      <div
                        style={{
                          marginTop: '10px',
                          justifyContent: 'center',
                        }}
                      >
                        <a target='_blank' href={`${ImageUrl}${state?.image_EN}`}>
                          <CImage
                            rounded
                            src={`${ImageUrl}${state?.image_EN}`}
                            style={{
                              maxHeight: '100px',
                              maxWidth: '100px',
                              borderRadius: 50,
                              alignItems: 'center',
                            }}
                          />
                        </a>
                      </div>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Image (TH):-</strong>
                    </CCol>
                    <CCol>
                      <div
                        style={{
                          marginTop: '10px',
                          justifyContent: 'center',
                        }}
                      >
                        <a target='_blank' href={`${ImageUrl}${state?.image_TH}`}>
                          <CImage
                            rounded
                            src={`${ImageUrl}${state?.image_TH}`}
                            style={{
                              maxHeight: '100px',
                              maxWidth: '100px',
                              borderRadius: 50,
                              alignItems: 'center',
                              marginBottom: '10px'
                            }}
                          />
                        </a>
                      </div>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Offer Status :-</strong>
                    </CCol>
                    <CCol>
                      <CButton
                        size="sm"
                        outline={offerDetails?.status ? "1" : "0"}
                      >
                        {offerDetails?.status ? "Active" : "Inactive"}
                      </CButton>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default view