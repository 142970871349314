import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { post, get, deleteApi } from '../../utils/apiManager'
import CIcon from '@coreui/icons-react'
import { cilPlus } from '@coreui/icons'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CCardSubtitle,
  CTable,
  CRow,
  CImage,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CButton,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import { DefaultInputWithIcon } from 'src/components/common/input'
import Pagination from 'react-js-pagination'
import { FaRegEye, FaEdit, FaTrash } from 'react-icons/fa'

function index() {
  const navigate = useNavigate();
  const [globalData, setGlobalData] = useState([]);
  const [imageUrl, setimageUrl] = useState('')
  const [totalPages, settotalPages] = useState(0);
  const [searchItem, setsearchItem] = useState("");
  const [pageData, setpageData] = useState({
    page: 1,
    perPage: 10,
    searchItem: "",
  });
  useEffect(() => {
    getData();
  }, [pageData]);

  const getData = () => {
    get(
      `/admin/social/get?page=${pageData?.page || 1}&perPage=${pageData?.perPage || 10
      }&searchItem=${pageData?.searchItem || ""}`
    ).then((data) => handleResponse(data));
  };
  const handleResponse = (data) => {
    if (!data?.status) toast.success(data?.message, data?.status);
    else {
      setGlobalData([...data?.data]);
      setimageUrl(data?.imageUrl)
      settotalPages(data?.pages);
    }
  };
  const changePage = (index) => {
    setpageData({
      ...pageData,
      page: index,
    });
  };

  const onClickUserEdit = async (value) => {
    navigate('/admin/social-setting/edit', { state: value })
  }
 
  const deleteData = (id) => {
    if (confirm("Are you sure")) {
      let formData = {
        _id: id,
      };
      deleteApi(`/admin/social/delete?_id=${id}`).then((data) => {
        handleDelete(data);
      });
    }
  };
  const handleDelete = (data) => {
    toast.success(data?.message, data?.status);
    getData();
  };
  const onSearchCLick = () => {
    setpageData({
      ...pageData,
      searchItem: searchItem,
    });
  };
  const executeOnClick = async (isExpanded) => {
    // console.log(isExpanded);
  }
  return (
    <>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <CCardSubtitle as="h4">Social settings</CCardSubtitle>
                  <CRow>
                    <CCol md="8">
                      <DefaultInputWithIcon
                        type={'text'}
                        placeholder="Search"
                        customType={'search'}
                        value={searchItem}
                        onClick={onSearchCLick}
                        onChange={(e) => {
                          onSearchCLick()
                          setsearchItem(e?.target?.value)
                        }}
                      />
                    </CCol>
                    {/* <CCol md="2"></CCol> */}
                    <CCol md="4">
                      <Link to={{ pathname: '/admin/social-setting/add' }} className="btn btn-dark">
                        <CIcon icon={cilPlus} />
                        Add
                      </Link>
                    </CCol>
                  </CRow>
                </CHeader>

                <CCardBody>
                  <CTable bordered hover responsive>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell>S. No</CTableHeaderCell>
                        <CTableHeaderCell>social Link</CTableHeaderCell>
                        {/* <CTableHeaderCell>Answer</CTableHeaderCell> */}
                        <CTableHeaderCell>Images</CTableHeaderCell>
                        <CTableHeaderCell>Action</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {globalData.map((ele, index) => {
                        return (
                          <CTableRow key={index}>
                            <CTableDataCell>
                              {pageData?.perPage * (pageData?.page - 1) +
                                index +
                                1}
                            </CTableDataCell>
                            <CTableDataCell>{ele?.socialLink}</CTableDataCell>
                            <CTableDataCell >
                            <a target='_blank' href={`${imageUrl}${ele?.image}`}>
                            <CImage
                              rounded
                              src={`${imageUrl}${ele?.image}`}
                              // onError={addDefaultSrc}
                              style={{
                                maxHeight: '50px',
                                maxWidth: '50px',
                                borderRadius: 50,
                                alignItems: 'center',
                              }}
                            />
                            </a></CTableDataCell>
                            {/* <CTableDataCell>
                            <CButton
                              size="sm"
                              outline={ele?.status ? "1" : "0"}
                              onClick={() =>
                                statusChange(ele?.status, ele?._id)
                              }
                            >
                              {ele?.status ? "Active" : "Inactive"}
                            </CButton>
                          </CTableDataCell> */}
                            <CTableDataCell>
                              <span className="mx-2" onClick={() => onClickUserEdit(ele)} style={{ fontSize: '20px', color: '#d3dc40', cursor: 'pointer' }}><FaEdit /></span>
                              <span className="mx-2 text-danger" onClick={() => deleteData(ele?._id)} style={{ fontSize: '20px', padding: '5px', cursor: 'pointer' }}><FaTrash /></span>
                              {/* <span onClick={() => onClickUserView(ele)} style={{color:'#d3dc40',padding:'5px'}}><FaRegEye/></span> */}
                            </CTableDataCell>
                          </CTableRow>
                        );
                      })}
                    </CTableBody>
                  </CTable>
                  {!globalData?.length && (
                    <>
                      <h1>No Data</h1>
                    </>
                  )}
                  {globalData?.length ? <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={pageData?.page}
                    itemsCountPerPage={pageData?.perPage}
                    totalItemsCount={totalPages * 10}
                    pageRangeDisplayed={5}
                    onChange={changePage}
                  /> : ''}
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default index