import React from 'react'
import { mainRoutes } from 'src/routes'
import { Route,Routes,Navigate } from 'react-router-dom'
import { AppSidebar, AppFooter, AppHeader } from '../components/index'

export default function Admin() {

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
          return <Route path={prop.path} element={
            <div>
              <AppSidebar />
              <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                <AppHeader />
                <div className="body flex-grow-1 px-3">
                <prop.element />
                </div>
                <AppFooter />
              </div>
            </div>           
          } key={prop.name}/>
        });
      };

  return (
    <>
      <Routes>
        {getRoutes(mainRoutes)}
        {/* {console.log(mainRoutes)} */}
        <Route path="*" element={<Navigate to="admin/dashboard" replace />} /> 
      </Routes>
    </>
  )
}

