import { CButton, CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter, CRow, CCol } from '@coreui/react'
import { ImageUrl, ImageUrl2 } from 'src/config/config';
const ItemImagePreview = ({ visiblePreview, setVisiblePreview, image, safeMessage }) => {
    return (
        <>

            <CModal
                visible={visiblePreview}
                alignment="center"
                onClose={() => setVisiblePreview(false)}
                aria-labelledby="LiveDemoExampleLabel"
                size='xl'
            >
                <CModalHeader onClose={() => setVisiblePreview(false)}>
                    <CModalTitle id="LiveDemoExampleLabel">Image Preview</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <CRow className="justify-content-center mb-2">
                        {image && image.length > 0 && image.map((value) => {
                            return (<CCol
                                xs={6}
                                className="align-items-center mb-3"
                                style={{ display: 'flex', flexDirection: 'column' }}
                            >
                                <img
                                    src={ImageUrl + value}
                                    style={{ cursor: "pointer", width: '100%', height: '100%    ' }}
                                    onClick={() => setVisiblePreview(!visiblePreview)}
                                /></CCol>)
                        })}
                    </CRow>
                </CModalBody>
             {  safeMessage &&
                   <div className='d-flex' style={{"padding"  :"20px"}}><h6 >Description : {`  `}</h6>
                  <div dangerouslySetInnerHTML={{__html: safeMessage}} />
                 </div>
                 }
            </CModal>
        </>
    )
}

export default ItemImagePreview;
