export const ChangeOrderStatus = {
    obj: {
        OrderPlace: "order_place",
        CancelRequest: "cancel(request)",
        pickedUp: "picked_up",
        InprocessClothesExamine: "inprocess(Examine clothes)",
        InprocessService: "inprocess(service process)",
        InprocessPayment: "inprocess(payment success)",
        delivery: "delivery",
        deliverd: "deliverd",
        CancelDriver: "cancel(driver)",
        CancelAdmin: "cancel(admin)",
        hold: "hold",
        CancelCustomer: "cancel(customer)",
        OutForDelivery: "out_for_delivery",
    },
};
export const ChangeDriverStatus = {
    obj: {
        OrderPlace: "order_place",
        CancelPickup: "cancel(pickup)",
        CancelDeliver: "cancel(deliver)",
        PickUp: "pickup",
        Inprocess: "inprocess(picked up)",
        delivery: "delivery",
        deliverd: "deliverd",
        hold: "hold",
        CancelCustomer: "cancel(customer)",
        OutForDelivery: "out_for_delivery",
    },
}