import ReactDOM from "react-dom";
import React, { PureComponent } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from "@coreui/react";

class ImageCrop extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            src: this?.props?.file || null,
            crop: {
                unit: "%",
                x: 0,
                y: 0,
                width: 50,
                height: 50,
            },
            visible: false
        };
        // console.log(this.props.file);
    }

    // If you setState the crop in here you should return false.
    onImageLoaded = (image) => {
        // console.log(image);
        this.imageRef = image;
        // console.log(this.imageRef);
    };

    onCropComplete = (crop) => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };

    saveHandler = () => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        // this.setState({ crop });

        this.props.setImageUser(this?.state?.croppedImageUrl);
        // console.log(this.state);
    };

    async makeClientCrop(crop) {
        if (this?.imageRef && crop?.width && crop?.height) {
            const croppedImageUrl = await this?.getCroppedImg(
                this?.imageRef,
                crop,
                "newFile.png"
            );
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        // console.log(image, crop, fileName);
        const canvas = document.createElement("canvas");
        const scaleX = image?.naturalWidth / image?.width;
        const scaleY = image?.naturalHeight / image?.height;
        canvas.width = crop?.width;
        canvas.height = crop?.height;
        const ctx = canvas?.getContext("2d");

        // console.log(image);

        ctx.drawImage(
            image,
            crop?.x * scaleX,
            crop?.y * scaleY,
            crop?.width * scaleX,
            crop?.height * scaleY,
            0,
            0,
            crop?.width,
            crop?.height
        );

        return new Promise((resolve, reject) => {
            canvas?.toBlob((blob) => {
                // console.log(blob);
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    // console.error("Canvas is empty");
                    return;
                }
                // new Blob(blob, { type: this.rawImage.type });
                // console.log(this.props)
                if (this.props.type === "image") {
                    // this.props.setImageBlog(blob);
                    this?.props?.setBannerImageBlob(blob);

                    // console.log(blob);
                }
                // else {
                //     this.props.setBackgroundImageBlob(blob);
                // }

                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);

                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
            }, "image/png");
        });
    }

    render() {
        const { crop, croppedImageUrl, src } = this.state;



        return (
            <>

                {/* <CButton onClick={() => { this.setState({ visible: !this.state.visible }) }}>modal</CButton> */}
                <CModal
                    visible={this?.props?.open} onClose={() => { this?.props?.setOpen(false) }}
                >
                    {/* <CModalHeader>
                        <CModalTitle>Modal title</CModalTitle>
                    </CModalHeader> */}
                    <CModalBody>
                        {/* {console.log(src)} */}
                        {src && (
                            <ReactCrop
                                src={src}
                                crop={crop}
                                ruleOfThirds
                                onImageLoaded={this?.onImageLoaded}
                                onComplete={this?.onCropComplete}
                                onChange={this?.onCropChange}
                                style={{ backgroundColor: "transparent" }}
                            />
                        )}
                        {/* I will not close if you click outside me. Don't even try to press escape key. */}
                    </CModalBody>
                    <CModalFooter>
                        <CButton color="secondary" onClick={() => { this?.props?.setOpen(false) }}>
                            Close
                        </CButton>
                        <CButton color="primary"
                            onClick={() =>
                                // console.log(this.props.type, "save vala e@@@@@@")
                                // this.props.type.toLowerCase() === "image"
                                // ?
                                this?.props?.handleDoneClose(croppedImageUrl, "bannerImage")
                                //     : this.props.handleDoneClose(croppedImageUrl, "backgroundImage")
                            }
                            autoFocus
                        >Save changes</CButton>
                    </CModalFooter>
                </CModal>
            </>
        );
    }
}
export default ImageCrop;



// import React, { useState } from 'react'
// import ReactCrop from 'react-image-crop'
// import 'react-image-crop/dist/ReactCrop.css'

// function ImageCrop() {
//     // const [crop, setCrop] = useState()
//     const [state, setState] = useState({
//         src: null,
//         crop: {
//             unit: "%",
//             x: 0,
//             y: 0,
//             width: 50,
//             height: 50
//         }
//     });
//     // state = {
//     //     src: null,
//     //     crop: {
//     //         unit: "%",
//     //         x: 0,
//     //         y: 0,
//     //         width: 50,
//     //         height: 50
//     //     }
//     // };
//     const { crop, croppedImageUrl, src } = state;
//     console.log("crop", crop, "croppedImageUrl", croppedImageUrl, "src", src)
//     function onSelectFile(e) {
//         console.log(e, "onSelect file")
//         if (e.target.files && e.target.files.length > 0) {
//             const reader = new FileReader();
//             reader.addEventListener("load", () =>
//                 setState({ src: reader.result })
//             );
//             reader.readAsDataURL(e.target.files[0]);
//         }
//     };
//     const onImageLoaded = (image) => {
//         console.log(image, "onImageLoaded")
//         this.imageRef = image;
//     };
//     console.log("IMage@@@@@@@@", onImageLoaded)
//     const onCropComplete = (crop) => {
//         this.makeClientCrop(crop);
//     };

//     const onCropChange = (crop, percentCrop) => {
//         // You could also use percentCrop:
//         // this.setState({ crop: percentCrop });
//         this.setState({ crop });
//     };
//     async function makeClientCrop(crop) {
//         if (this.imageRef && crop.width && crop.height) {
//             const croppedImageUrl = await this.getCroppedImg(
//                 this.imageRef,
//                 crop,
//                 "newFile.jpeg"
//             );
//             this.setState({ croppedImageUrl });
//         }
//     }
//     const getCroppedImg = (image, crop, fileName) => {
//         console.log("get crop img", image)
//         const canvas = document.createElement("canvas");
//         const scaleX = image.naturalWidth / image.width;
//         const scaleY = image.naturalHeight / image.height;
//         canvas.width = crop.width;
//         canvas.height = crop.height;
//         const ctx = canvas.getContext("2d");

//         ctx.drawImage(
//             image,
//             crop.x * scaleX,
//             crop.y * scaleY,
//             crop.width * scaleX,
//             crop.height * scaleY,
//             0,
//             0,
//             crop.width,
//             crop.height
//         );

//         return new Promise((resolve, reject) => {
//             canvas.toBlob((blob) => {
//                 if (!blob) {
//                     //reject(new Error('Canvas is empty'));
//                     console.error("Canvas is empty");
//                     return;
//                 }
//                 blob.name = fileName;
//                 window.URL.revokeObjectURL(this.fileUrl);
//                 this.fileUrl = window.URL.createObjectURL(blob);
//                 resolve(this.fileUrl);
//             }, "image/jpeg");
//         });
//     }
//     return (
//         <>
//             <input type="file" accept="image/*"
//                 // onChange={(e) => console.log(e, "eeeeeeeeeeee")}
//                 onChange={onSelectFile}
//             />
//             {src && (
//                 <ReactCrop
//                     src={src}
//                     crop={crop}
//                     ruleOfThirds
//                     onImageLoaded={onImageLoaded}
//                     onComplete={onCropComplete}
//                     onChange={onCropChange}
//                 />
//             )}
//             {croppedImageUrl && (
//                 <img alt="Crop" style={{ maxWidth: "100%" }} src={croppedImageUrl} />
//             )}
//             {/* <ReactCrop crop={crop} onChange={c => setCrop(c)}>
//                 <img src={src} />
//             </ReactCrop> */}
//         </>
//     )
// }

// export default ImageCrop