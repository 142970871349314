import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom'
import { get, post } from '../../../utils/apiManager'
import {
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CHeader,
    CCardSubtitle,
    CTable,
    CRow,
    CImage,
    CTableHead,
    CTableRow,
    CTableHeaderCell,
    CTableBody,
    CTableDataCell,
    CButton,
    CModal,
    CModalHeader,
    CModalTitle,
    CModalBody,
    CModalFooter,
    CFormLabel,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import { DefaultInputWithIcon } from 'src/components/common/input'
import Pagination from 'react-js-pagination'
import ReactToPrint from 'react-to-print'
import { Button, Modal } from '@coreui/coreui'
import { FaRegEye, FaPrint, FaSearchengin, FaTruckMoving, FaRegAddressCard, FaShoppingBag, FaFolderOpen, FaArrowLeft } from 'react-icons/fa'
import { ImageUrl, ImageUrl2 } from 'src/config/config'
import Select from 'react-select'
import { cibElectron } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import ItemImagePreview from '../itemImagePreview'
import ImagePreview from '../previewImage'
import OrderNotFound from 'src/components/LaundryProcessModals/OrderNotFound'
import BagScanComplete from 'src/components/LaundryProcessModals/BagScanComplete'


function BagList() {
    const navigate = useNavigate();
    let componentRef = useRef();
    // const [visible, setVisible] = useState(false)
    const [visible, setVisible] = useState(false)
    const [modalData, setModalData] = useState()
    const [logo, setLogo] = useState()
    const [placedOrderList, setPlacedOrderList] = useState();

    const [pickupDrivers, setPickupDrivers] = useState(null);
    const [selectedPickupDrivers, setSelectedPickupDrivers] = useState(null);
    const pickupDriverOptions = [];

    const [deliverDrivers, setDeliverDrivers] = useState(null);
    const [selectedDeliverDrivers, setSelectedDeliverDrivers] = useState(null);
    const deliverDriverOptions = [];

    const [stainImage, setStainImage] = useState([]);
    const [visiblePreview, setVisiblePreview] = useState(false)
    const [itemPicture, setItemPicture] = useState("");



    const [searchItem, setSearchItem] = useState("");
    const [totalPages, setTotalPages] = useState(0);
    const [count, setCount] = useState()
    const [order, setOrder] = useState();
    const [pageData, setPageData] = useState({
        page: 1,
        perPage: 10,
        searchItem: "",
    });
    const { state } = useLocation();
    console.log("state", state);
    const { orderId, _id } = state;
    const onClickOrderReply = (item) => {
        setVisible(true)
    }
    const getData = () => {

        get(
            `/admin/placed-order/order-details?orderId=${orderId}&checkValue=true`
            // ?page=${pageData?.page || 1}&perPage=${pageData?.perPage || 10
            // }&searchItem=${pageData?.searchItem || ""}`
        ).then((res) => {
            handleResponse(res)
        });


    };

    const handleResponse = (res) => {
        console.log({ res });
        setOrder(res?.data);
        // setPlacedOrderList(res?.data[0]?.bags)
        // setTotalPages(res?.data[0]?.metadata[0]?.page);
        // setCount(res?.data[0]?.metadata[0]?.total)
    };

    useEffect(() => {
        getData();
        getLogo();
        getDriver();
    }, [pageData]);

    const onSearchCLick = () => {
        setPageData({
            ...pageData,
            searchItem: searchItem,
        });
    }

    const changePage = (index) => {
        setPageData({
            ...pageData,
            page: index,
        });
    };

    const handleExaminItem = (item) => {
        if (item?.packageUser?.length > 0) {
            navigate(`/admin/placed-order/examin-package-items/${btoa(item?._id)}/${btoa(item?.orderId)}/${btoa(item?.user[0]?._id)}`, { state: item })
        } else {
            navigate(`/admin/placed-order/examin/${btoa(item?._id)}/${btoa(item?.orderId)}/${btoa(item?.user[0]?._id)}`, { state: item })
        }
    }

    const onClickUserView = (data) => {
        navigate(`/admin/offer/view/${btoa(data?._id)}/${btoa(data?.service_type)}`, { state: data })
    }
    const onClickUserEdit = (data) => {
        navigate(`/admin/offer/edit/${btoa(data?._id)}/${btoa(data?.service_type)}`, { state: data })
    }
    const getLogo = () => {
        get(`/admin/global/global-setting`).then((response) => {
            if (response?.status == true) {
                setLogo(`${ImageUrl}${response.data.logoImage}`)
            }
        })
    }
    const getDriver = () => {
        get(`/admin/placed-order/get-driver`).then((response) => {
            setPickupDrivers(response?.data)
            setDeliverDrivers(response?.data)
        })
    }
    if (pickupDrivers !== null) {
        pickupDrivers?.map((item, key) => {
            pickupDriverOptions?.push({ label: item?.username, value: item?._id })
        })
    }
    if (deliverDrivers !== null) {
        deliverDrivers?.map((item, key) => {
            deliverDriverOptions?.push({ label: item?.username, value: item?._id })
        })
    }
    const ChangeDriver = async (data) => {
        setVisible(true);
        setModalData(data)
    }
    const onClickChangeDriver = async (val) => {
        val.preventDefault();
        const changeDriver = {
            orderId: modalData?.orderId,
            pickup_driver: val?.target?.PickupDriver != undefined ? val?.target?.PickupDriver.value : '',
            dropOff_driver: val?.target?.DeliverDriver != undefined ? val?.target?.DeliverDriver.value : '',
        }
        post(`admin/placed-order/change-driver`, changeDriver).then((data) => handleDriverResponse(data))
    }
    const handleDriverResponse = (data) => {
        if (data?.status == true) {
            toast.success(data?.message)
            setVisible(false);
        }
    }

    const showTotal = (item) => {
        let count = item?.reduce((accumulator, currentItem) => {
            if (currentItem?.status) {
                return accumulator + 1;
            } else {
                return accumulator;
            }
        }, 0);
        return `${count}/${item?.length}`

    }


    return (
        <>
            <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
            <CContainer fluid>
                <CRow>
                    <CCol>
                        <CCardGroup>
                            <CCard className="p-4">
                                <CHeader>
                                    <CRow>
                                        <CCol >
                                            {' '}
                                            <FaArrowLeft style={{ color: '#000' }} onClick={() => {
                                                navigate('/admin/offline-orders')
                                            }} />
                                            <span>{' '}Bags</span>
                                        </CCol>
                                    </CRow>

                                    <CRow>
                                        <CCol md="8">
                                            {/* <DefaultInputWithIcon
                                                type={'text'}
                                                placeholder="Search"
                                                customType={'search'}
                                                value={searchItem}
                                                onClick={onSearchCLick}
                                                onChange={(e) => {
                                                    onSearchCLick()
                                                    setSearchItem(e?.target?.value)
                                                }}
                                            /> */}
                                        </CCol>
                                    </CRow>
                                </CHeader>
                                <CCardBody>



                                    {order?.map((ord, idx) => (
                                        <>
                                            <br />
                                            <h4>Bag {idx + 1}</h4>
                                            <div className="d-flex justify-content-between align-center">
                                                <h6>{ord?.serviceName_EN}</h6>
                                                <h6>{showTotal(ord?.items)}</h6>
                                            </div>
                                            <CTable bordered hover responsive>
                                                <CTableHead>
                                                    <CTableRow>
                                                        <CTableHeaderCell>Barcode</CTableHeaderCell>
                                                        <CTableHeaderCell>Item Name</CTableHeaderCell>
                                                        <CTableHeaderCell>Brands</CTableHeaderCell>
                                                        <CTableHeaderCell>Colors</CTableHeaderCell>
                                                        <CTableHeaderCell>Description</CTableHeaderCell>
                                                        <CTableHeaderCell>Service</CTableHeaderCell>
                                                        <CTableHeaderCell>Picture</CTableHeaderCell>
                                                        <CTableHeaderCell>Stain</CTableHeaderCell>
                                                        <CTableHeaderCell>Stain Image</CTableHeaderCell>
                                                    </CTableRow>
                                                </CTableHead>
                                                <CTableBody>
                                                    {ord?.items?.map((item, index) => {
                                                        return (
                                                            <CTableRow key={index}>
                                                                {console.log(item, "item")}
                                                                <CTableDataCell>{item?.itemCode || "N/A"} </CTableDataCell>
                                                                <CTableDataCell >{item?.itemName_EN || "N/A"}</CTableDataCell>
                                                                <CTableDataCell >{item?.brand || "N/A"}</CTableDataCell>
                                                                <CTableDataCell >
                                                                    {item?.colorInfo?.length > 0
                                                                        ? item?.colorInfo?.map((color, i) => `${color.name}${item?.colorInfo?.length > i + 1 ? "," : ""} `)
                                                                        : "N/A"
                                                                    }
                                                                </CTableDataCell>
                                                                <CTableDataCell >{item?.description || "N/A"}</CTableDataCell>
                                                                <CTableDataCell >{ord?.serviceName_EN || "N/A"}</CTableDataCell>
                                                                <CTableDataCell >
                                                                    <i class="fa-solid fa-image" style={{ fontSize: "x-large" }} onClick={() => {
                                                                        if (item?.item_image) {
                                                                            setItemPicture(item?.item_image)
                                                                            setVisible(!visible)
                                                                        }
                                                                    }}></i>
                                                                </CTableDataCell>
                                                                <CTableDataCell >{item?.stain?.length > 0 ? item?.stain?.map((val) => {
                                                                    return (
                                                                        val?.stainName
                                                                    )
                                                                }) : "N/A"}</CTableDataCell>
                                                                {/* <CTableDataCell>
                                                                    <span >
                                                                        <i class="fa-solid fa-images" style={{ fontSize: "x-large" }}
                                                                            onClick={() => {
                                                                                if (item?.stain_image && item?.stain_image?.[0] != "" && item?.stain_image.length > 0) {
                                                                                    setStainImage(item?.stain_image)
                                                                                    setVisiblePreview(!visiblePreview)
                                                                                }
                                                                            }
                                                                            }>

                                                                        </i>
                                                                    </span>
                                                                </CTableDataCell> */}
                                                                <CTableDataCell>
                                                                    <span >
                                                                        <i class="fa-solid fa-images" style={{ fontSize: "x-large" }}
                                                                            onClick={() => {
                                                                                if (item?.stain && item?.stain?.[0] != "" && item?.stain.length > 0) {
                                                                                    setStainImage(item?.stain?.[0]?.images)
                                                                                    setVisiblePreview(!visiblePreview)
                                                                                }
                                                                            }
                                                                            }>

                                                                        </i>
                                                                    </span>
                                                                </CTableDataCell>
                                                            </CTableRow>
                                                        );
                                                    })}
                                                </CTableBody>
                                            </CTable>

                                        </>
                                    ))}

                                    {!order?.length && (
                                        <>
                                            <h1>No Data</h1>
                                        </>
                                    )}
                                    {/* {placedOrderList?.length ? <Pagination
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        activePage={pageData?.page}
                                        itemsCountPerPage={pageData?.perPage}
                                        totalItemsCount={count}
                                        pageRangeDisplayed={5}
                                        onChange={changePage}
                                    /> : ''} */}
                                </CCardBody>
                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>

                <ImagePreview visible={visible} setVisible={setVisible} image={ImageUrl + itemPicture} />
                <ItemImagePreview visiblePreview={visiblePreview} setVisiblePreview={setVisiblePreview} image={stainImage} />


                {/* <CModal alignment="center" visible={visible} onClose={() => setVisible(false)}>
                    <CModalHeader onClose={() => setVisible(false)}>
                        <CModalTitle>
                            <img src={logo} /> {' '}
                            Change the driver for Order No #{modalData?.orderId}</CModalTitle>
                    </CModalHeader>
                    <form onSubmit={onClickChangeDriver}>
                        <CModalBody>
                            <CRow>
                                {modalData?.orderStatus == 'order_place' ?
                                    (<>
                                        <CFormLabel>Pickup Driver</CFormLabel>
                                        <Select
                                            onChange={(val) => {
                                                setSelectedPickupDrivers(val)
                                            }}
                                            options={pickupDriverOptions}
                                            value={selectedPickupDrivers}
                                            placeholder={"Select Pickup Driver"}
                                            name={"PickupDriver"}
                                        />
                                    </>) : ''}
                                {modalData?.orderStatus == 'delivery' ?
                                    (<>
                                        <CFormLabel>Deliver Driver</CFormLabel>
                                        <Select
                                            onChange={(val) => {
                                                setSelectedDeliverDrivers(val)
                                            }}
                                            options={deliverDriverOptions}
                                            value={selectedDeliverDrivers}
                                            placeholder={"Select Deliver Driver"}
                                            name={"DeliverDriver"}
                                        />
                                    </>) : ''}
                            </CRow>
                        </CModalBody>
                        <CModalFooter>
                            <CButton color="success"
                                type='submit'
                            >Submit</CButton>
                        </CModalFooter>
                    </form>
                </CModal> */}
            </CContainer >

            {/* <OrderNotFound /> */}
            <BagScanComplete />
        </>
    )
}

export default BagList
