import React, { useRef, useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Link, useNavigate } from 'react-router-dom'
import { baseURL } from 'src/config/config'
import { post } from 'src/utils/apiManager';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CImage,
  CRow,
  CFormLabel
} from '@coreui/react'
import { DefaultMobileInput, DefaultInput } from 'src/components/common/input'
import { signUp } from 'src/utils/validator';
import { ErrorText } from "src/components/common/commonError";

export default function addUser() {
  const navigate = useNavigate()
  const formRef = useRef(null)
  const fileRef = useRef(null)
  const [invalidImage, setInvalidImage] = useState('')
  const [userImagePreview, setUserImagePreview] = useState();
  const [passVisibility, setPassVisibility] = useState(false);
  const [passwordType, setPasswordType] = useState("password");

  useEffect(() => {
    if (passVisibility) {
      setPasswordType("text")
    } else {
      setPasswordType("password")
    }
  }, [passVisibility]);

  const submit = async (values) => {

    if (invalidImage?.length === 0) {
      let formData = new FormData()
      for (var ele in values) {
        formData?.append(ele, values[ele])
      }
      const Url = `/admin/User/add-user`
      post(Url, formData, 1).then((data) => {
        handleResponse(data)
      })
    } else {
      return
    }
  }
  const handleResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      setTimeout(() => {
        navigate('/admin/user')
      }, 500)
    }
    else {
      toast.error(data?.message)
    }
    formRef.current.resetForm()
    fileRef.current.value = null
    setUserImagePreview('')
  }
  const onClickReset = () => {
    // Reset Button Function
    formRef.current.setFieldValue('username', '')
    formRef.current.setFieldValue('email', '')
    fileRef.current.value = null
    formRef.current.setFieldValue('mobile', '')
    formRef.current.setFieldValue('password', '')

  };
  return (
    <>
      <CContainer >
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <CRow>
                        <CCol md="11">
                          <h1>New User</h1>
                        </CCol>
                        <CCol md="1">
                          <Link className={'btn btn-success mt-2'} style={{ background: '#d3dc40', border: '#d3dc40' }} to={{ pathname: `/admin/user` }}>
                            Back
                          </Link>
                        </CCol>
                      </CRow>
                      <Formik
                        initialValues={{
                          username: '',
                          email: '',
                          image: '',
                          mobile: '',
                          password: '',
                        }}
                        validationSchema={signUp}
                        onSubmit={(values, { setSubmitting }) => {
                          submit(values)
                          setSubmitting(false)

                        }}
                        innerRef={formRef}
                      >
                        {(props) => (
                          <form onSubmit={props?.handleSubmit}>
                            {/* {console.log(props)} */}
                            <CRow>
                              <CCol md={6}>
                                <CFormLabel>Name</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Name'}
                                  id={'username'}
                                  maxLength="70"
                                  value={props?.values?.username}
                                  onChange={props?.handleChange('username')}
                                  onBlur={() => props?.setFieldTouched('username', true)}
                                  error={props?.touched?.username && props?.errors?.username}
                                />
                              </CCol>
                              <CCol md={6}>
                                <CFormLabel>Email</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'email'}
                                  placeholder={'Email'}
                                  id={'email'}
                                  value={props?.values?.email}
                                  // value={this.state.name}
                                  onChange={props?.handleChange('email')}
                                  onBlur={() => props?.setFieldTouched('email', true)}
                                  error={props?.touched?.email && props?.errors?.email}
                                />
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol md={6}>
                                <DefaultMobileInput
                                  type={'text'}
                                  placeholder={'Mobile'}
                                  name={'Mobile'}
                                  id={'mobile'}
                                  minLength={7}
                                  maxLength={15}
                                  value={props?.values?.mobile}
                                  onChange={(val) => props?.setFieldValue('mobile', val || '')}
                                  onBlur={() => props?.setFieldTouched('mobile', true)}
                                  error={props?.touched?.mobile && props?.errors?.mobile}
                                />
                              </CCol>
                              <CCol md={6}>
                                <CFormLabel>Password</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>*</span>

                                <div className="input-group">
                                  <input
                                    type={passwordType}
                                    autoComplete="off"
                                    className="form-control"
                                    placeholder={"Current Password"}
                                    id={"password"}
                                    value={props?.values?.password}
                                    onChange={props?.handleChange('password')}
                                    onBlur={() => props?.setFieldTouched('password', true)}
                                  />
                                  <button
                                    className="btn btn-outline-secondary"
                                    type="button"
                                    onClick={() => setPassVisibility(!passVisibility)}
                                    style={{ backgroundColor: '#d8dbe0' }}
                                  >
                                    {passwordType == "password" ? (
                                      <i style={{ color: '#2C384A' }} className="fa-solid fa-eye-slash"></i>
                                    ) : <i style={{ color: '#2C384A' }} className="fa-solid fa-eye"></i>}
                                  </button>
                                </div>
                                {props?.touched?.password && props?.errors?.password ? <ErrorText title={props?.errors?.password} /> : null}

                                {/* <DefaultInput
                                  type={'password'}
                                  placeholder={'password'}
                                  id={'password'}
                                  // maxLength={8}
                                  value={props?.values?.password}
                                  onChange={props?.handleChange('password')}
                                  onBlur={() => props?.setFieldTouched('password', true)}
                                  error={props?.touched?.password && props?.errors?.password}
                                /> */}
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol md={6}>
                                <CFormLabel>Image</CFormLabel>
                                {/* <span className="text-danger" style={{ marginLeft: '5px' }}>*</span> */}
                                <DefaultInput
                                  type={'file'}
                                  placeholder={'Image'}
                                  // MIN_FILE_SIZE={1024}
                                  // MAX_FILE_SIZE={5120}
                                  title={"1024 X 1024"}
                                  size={5}
                                  // file={props?.values?.image}
                                  // accept="image/*"
                                  accept="image/png, image/gif, image/jpeg, image/jpg"
                                  refr={fileRef}
                                  onChange={(e) => {
                                    const imageFile = e?.target?.files[0];
                                    if (!imageFile?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
                                      // setInvalidImage("Please select valid image")
                                    }
                                    else {
                                      setInvalidImage('')
                                    }
                                    //for image preview
                                    const reader = new FileReader();
                                    reader?.addEventListener("load", () => setUserImagePreview(reader?.result));
                                    reader?.readAsDataURL(e?.target?.files[0]);
                                    //for image preview
                                    props?.setFieldValue('image', e?.target?.files[0])
                                    // console.log(imageFile, "imageFile")
                                  }}
                                  // onBlur={() => props?.setFieldTouched('image', true)}
                                  // error={(props?.touched?.image && props?.errors?.image) || invalidImage}
                                />
                                {/* for image preview */}
                                {userImagePreview?.length > 0 ?
                                  <>
                                    <CCol>
                                      <CImage
                                        rounded
                                        thumbnail
                                        className="mt-2"
                                        src={`${userImagePreview}`}
                                        style={{
                                          maxHeight: '80px',
                                          maxWidth: '80px',
                                          alignItems: 'center',
                                        }}
                                      />
                                    </CCol>
                                  </>
                                  : ''}
                                {/* for image preview */}
                              </CCol>
                              <CCol md={6}>
                                {/* <DefaultTextarea
                            placeholder={"Address"}
                            name={"address"}
                            id={"address"}
                            maxLength={50}
                            value={props?.values?.address}
                            onChange={props?.handleChange("address")}
                            onBlur={() =>
                              props?.setFieldTouched("address", true)
                            }
                            error={ props?.touched?.address && props?.errors?.address}
                          /> */}
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol md="2">
                                <button
                                  type="submit"

                                  className={'btn btn-success mt-2'}
                                  disabled={props?.isSubmitting}
                                >
                                  Submit
                                </button>
                              </CCol>
                              <CCol md="2">
                                <button
                                  type="button"
                                  className={'btn btn-warning mt-2'}
                                  onClick={onClickReset}
                                >
                                  Reset
                                </button>
                              </CCol>
                              <CCol md="3"></CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>


    </>
  )
}
