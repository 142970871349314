import React, { useState, useRef, useEffect } from 'react'
import { Formik } from 'formik'
import { useLocation, useNavigate,Link } from 'react-router-dom'
import { put } from 'src/utils/apiManager'
import { ImageUrl } from 'src/config/config'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CFormLabel,
  CRow,
  CImage,
} from '@coreui/react'
import { DefaultInput } from 'src/components/common/input'
import { slider } from 'src/utils/validator'
import { Ckeditor } from '../Template/Ckeditor'

function edit() {
    const navigate = useNavigate()
    const { state } = useLocation()
    // console.log(state)
    const [description_EN, setDescription_EN] = useState('')
    const [description_TH, setDescription_TH] = useState('')
    // console.log(state)
    const [isDisable, setIsDisable] = useState(false);
    const formRef = useRef(null)
    const submit = async (values) => {
      let sliderData = {
        id: state?._id,
        title_EN: values?.title_EN,
        title_TH: values?.title_TH,
        metaTitle_EN: values?.metaTitle_EN,
        metaTitle_TH: values?.metaTitle_TH,
        sliderUrl: values?.sliderUrl,
        description_EN: values?.description_EN,
        description_TH: values?.description_TH,
      }
      put(`admin/slider/update?_id=${state?._id}`, sliderData).then((data) => {
        // console.log(data)
        handleResponse(data)
      })
    }
    const handleResponse = (data) => {
      if (data?.status == true) {
        toast.success(data?.message)
        setTimeout(() => {
          navigate('/admin/slider')
        }, 800)
      }
      formRef.current.resetForm()
    }
    const onClickReset = () => {
        // Reset Button Function
        formRef.current.setFieldValue('title_EN', '')
        formRef.current.setFieldValue('title_TH', '')
        formRef.current.setFieldValue('metaTitle_EN', '')
        formRef.current.setFieldValue('metaTitle_TH', '')
        formRef.current.setFieldValue('sliderUrl', '')
        formRef.current.setFieldValue('description_EN', '')
        formRef.current.setFieldValue('description_TH', '')    
      };
    useEffect(() => {
      if (state != null) {
        formRef.current.setFieldValue('title_EN', state?.title_EN)
        formRef.current.setFieldValue('title_TH', state?.title_TH)
        formRef.current.setFieldValue('metaTitle_EN', state?.metaTitle_EN)
        formRef.current.setFieldValue('metaTitle_TH', state?.metaTitle_TH)
        formRef.current.setFieldValue('sliderUrl', state?.sliderUrl)
        formRef.current.setFieldValue('description_EN', state?.description_EN)
        formRef.current.setFieldValue('description_TH', state?.description_TH)
      }
    }, [state])
    return (
      <>
      <CContainer >
          <CRow>
            <CCol>
              <CCardGroup>
                <CCard className="p-4">
                  <CCol xs={12}>
                  <CCardGroup>
                      <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                          <CRow>
                            <CCol md="11">
                            <h1>Edit Slider</h1>
                            </CCol>                    
                            <CCol md="1">
                              <Link className={'btn btn-success mt-2'} style={{background:'#d3dc40',border: '#d3dc40' }} to={{ pathname: `/admin/slider` }}>
                                  Back
                              </Link>
                            </CCol>
                          </CRow>
                          <Formik
                        initialValues={{
                            title_EN: '',
                            title_TH: '',
                            metaTitle_EN: '',
                            metaTitle_TH: '',
                            sliderUrl: '',
                            description_EN: '',
                            description_TH: '',
                        }}
                        validationSchema={slider}
                        onSubmit={(values, { setSubmitting }) => {
                          submit(values)
                          setSubmitting(false)
                          
                        }}
                        innerRef={formRef}
                      >
                        {(props) => (
                          <form onSubmit={props?.handleSubmit}>
                            <CRow>
                              <CCol md="6">
                              <CFormLabel>Title (EN)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                type={'text'}
                                placeholder={'Title'}
                                // name={'Title (EN)'}
                                id={'title_EN'}
                                value={props?.values?.title_EN}
                                onChange={props?.handleChange('title_EN')}
                                onBlur={() => props?.setFieldTouched('title_EN', true)}
                                error={props?.touched?.title_EN && props?.errors?.title_EN}
                                onKeyUp={()=>{
                                  if(state?.title_EN == props?.values?.title_EN) setIsDisable(false)
                                  else setIsDisable(true)
                                }}
                              /> 
                              </CCol>
                              <CCol className="mb-4">
                              <CFormLabel>Title (TH)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                type={'text'}
                                placeholder={'Title'}
                                // name={'Title (TH)'}
                                id={'title_TH'}
                                value={props?.values?.title_TH}
                                onChange={props?.handleChange('title_TH')}
                                onBlur={() => props?.setFieldTouched('title_TH', true)}
                                error={props?.touched?.title_TH && props?.errors?.title_TH}
                                onKeyUp={()=>{
                                  if(state?.title_TH == props?.values?.title_TH) setIsDisable(false)
                                  else setIsDisable(true)
                                }}
                              />
                              </CCol>  
                              <CCol md="6">
                              <CFormLabel>Meta Title (EN)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                type={'text'}
                                placeholder={'Title'}
                                // name={'Meta Title (EN)'}
                                id={'metaTitle_EN'}
                                value={props?.values?.metaTitle_EN}
                                onChange={props?.handleChange('metaTitle_EN')}
                                onBlur={() => props?.setFieldTouched('metaTitle_EN', true)}
                                error={props?.touched?.metaTitle_EN && props?.errors?.metaTitle_EN}
                                onKeyUp={()=>{
                                  if(state?.metaTitle_EN == props?.values?.metaTitle_EN) setIsDisable(false)
                                  else setIsDisable(true)
                                }}
                              /> 
                              </CCol>
                              <CCol className="mb-4">
                              <CFormLabel>Meta Title (TH)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                type={'text'}
                                placeholder={'Title'}
                                // name={'Meta Title (TH)'}
                                id={'metaTitle_TH'}
                                value={props?.values?.metaTitle_TH}
                                onChange={props?.handleChange('metaTitle_TH')}
                                onBlur={() => props?.setFieldTouched('metaTitle_TH', true)}
                                error={props?.touched?.metaTitle_TH && props?.errors?.metaTitle_TH}
                                onKeyUp={()=>{
                                  if(state?.metaTitle_TH == props?.values?.metaTitle_TH) setIsDisable(false)
                                  else setIsDisable(true)
                                }}
                              />
                              </CCol>
                              <CCol md="6">
                                <CFormLabel>Description (EN)</CFormLabel>  
                                <span className='text-danger' style={{marginLeft:'5px'}}>*</span>
                                <Ckeditor
                                  setDescription_EN={setDescription_EN}
                                  description_EN={description_EN}
                                  value={props?.values?.description_EN}
                                  onChange={(val) => {
                                    setDescription_EN(val)
                                    if(state?.description_EN == val) setIsDisable(false)
                                    else setIsDisable(true)
                                    props.setFieldValue('description_EN',val)
                                  }}
                                  onBlur={() => props?.setFieldTouched('description_EN', true)}
                                  error={props?.touched?.description_EN && props?.errors?.description_EN}
                                  
                                />
                              </CCol>
                              <CCol md="6">
                                <CFormLabel>Description (TH)</CFormLabel>  
                                <span className='text-danger' style={{marginLeft:'5px'}}>*</span>
                                <Ckeditor
                                  setDescription_TH={setDescription_TH}
                                  description_TH={description_TH}
                                  value={props?.values?.description_TH}
                                  onChange={(val) => {
                                    setDescription_TH(val)
                                    if(state?.description_TH == val) setIsDisable(false)
                                    else setIsDisable(true)
                                    props.setFieldValue('description_TH',val)                            
                                  }}
                                  onBlur={() => props?.setFieldTouched('description_TH', true)}
                                  error={props?.touched?.description_TH && props?.errors?.description_TH}
                                />
                              </CCol>
                              <CCol md="6">
                              <CFormLabel>Slider url</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                    type={"text"}
                                    placeholder={"Slider url"}
                                    name={"Slider url"}
                                    value={props?.values?.sliderUrl}
                                    onChange={props?.handleChange("sliderUrl")}
                                    onBlur={() => props?.setFieldTouched("sliderUrl", true)}
                                    error={props?.touched?.sliderUrl && props?.errors?.sliderUrl}
                                    onKeyUp={()=>{
                                      if(state?.sliderUrl == props?.values?.sliderUrl) setIsDisable(false)
                                      else setIsDisable(true)
                                    }}
                                />
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol md="2">
                                <button
                                  type="submit"
                                  
                                  className={'btn btn-success mt-2'}
                                  disabled={!isDisable || props?.isSubmitting}
                                >
                                  Submit
                                </button>
                              </CCol>
                              <CCol md="2">
                                <button
                                  type="button"
                                  className={'btn btn-warning mt-2'}
                                  onClick={() => navigate('/admin/slider')}
                              >
                                Cancel
                                </button>
                              </CCol>
                              <CCol md="3"></CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>
                      </CCardBody>
                  </CCardGroup>
                  </CCol>               
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </>
    )
}

export default edit