import React, { useEffect, useRef, useState } from 'react'
import { Formik } from 'formik'
import { useNavigate, Link } from 'react-router-dom'
import { post, get } from 'src/utils/apiManager'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { CCard, CCardBody, CCardGroup, CCol, CContainer, CFormLabel, CRow } from '@coreui/react'
import { DefaultInput, DefaultTextarea } from 'src/components/common/input'
import { validateArea } from 'src/utils/validator'
import Select from 'react-select'

function addArea() {
  const navigate = useNavigate()
  const formRef = useRef(null)
  const [disable, setDisable] = useState(false)
  // const [selecteCityOption, setSelecteCityOption] = useState(null)
  // const [cityData, setCityData] = useState(null)
  // const options = []
  // const getCities = () => {
  //   get(`admin/Area/city-list`).then((data) => {
  //     setCityData(data.data)
  //   })
  // }
  // if (cityData !== null) {
  //   cityData.map((item, key) => {
  //     options.push({ label: item.cityName_EN, value: item._id })
  //   })
  // }
  // useEffect(() => {
  //   getCities()
  // }, [])
  const submit = async (values) => {
    setDisable(true)
    // if (values?.zipcode <= 0 || values?.zipcode.length < 6) {
    //   toast.error("Enter correct zipcode")
    //   formRef.current.setFieldValue('zipcode', '')
    //   setDisable(false)
    // } else {
    let AreaData = {
      areaName_EN: values?.areaName_EN,
      areaName_TH: values?.areaName_TH,
      // city_id: values?.city_id,
      // zipcode: values?.zipcode,
      lat_long: values?.lat_long,
    }
    post(`admin/Area/insert`, AreaData).then((data) => {
      // console.log(data)
      handleResponse(data)
    })
    // }
  }
  const handleResponse = (data) => {
    // console.log(data)
    if (data?.status == true) {
      toast.success(data?.message)
      setTimeout(() => {
        navigate('/admin/area')
      }, 800)
    } else {
      setDisable(false)
      toast.error(data?.message)
    }
    formRef.current.resetForm()
    // setSelecteCityOption('');
  }
  const onClickReset = () => {
    // Reset Button Function
    formRef.current.resetForm()
    // setSelecteCityOption('');
  }
  return (
    <>
      <CContainer>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <CRow>
                        <CCol md="11">
                          <h1>New Area</h1>
                        </CCol>
                        <CCol md="1">
                          <Link
                            className={'btn btn-success mt-2'}
                            style={{ background: '#d3dc40', border: '#d3dc40' }}
                            to={{ pathname: `/admin/area` }}
                          >
                            Back
                          </Link>
                        </CCol>
                      </CRow>
                      <Formik
                        initialValues={{
                          areaName_EN: '',
                          areaName_TH: '',
                          // city_id: '',
                          // zipcode: '',
                          lat_long: '',
                        }}
                        validationSchema={validateArea}
                        onSubmit={(values, { setSubmitting }) => {
                          submit(values)
                          setSubmitting(false)
                          // console.log(values)
                        }}
                        innerRef={formRef}
                      >
                        {(props) => (
                          <form onSubmit={props?.handleSubmit}>
                            <CRow >
                              <CCol md={6}>
                              <CFormLabel>Area Name (EN)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Area Name'}
                                  // name={'Area Name (EN)'}
                                  // maxLength={30}
                                  value={props?.values?.areaName_EN}
                                  onChange={props?.handleChange('areaName_EN')}
                                  onBlur={() => props?.setFieldTouched('areaName_EN', true)}
                                  error={props?.touched?.areaName_EN && props?.errors?.areaName_EN}
                                />
                                {/* <CFormLabel>City</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>
                                <Select
                                  defaultValue={selecteCityOption}
                                  onChange={(val) => {
                                    setSelecteCityOption(val)
                                    // console.log(val)
                                    props.setFieldValue('city_id', val.value)
                                  }}
                                  options={options}
                                  value={selecteCityOption}
                                  placeholder={'Select city'}
                                />
                                {selecteCityOption == undefined ? (
                                  <span className="text-danger">{props?.errors?.city_id}</span>
                                ) : (
                                  ''
                                )} */}
                                {/* <DefaultInput
                                  type={'text'}
                                  placeholder={'Zipcode'}
                                  name={'Zipcode'}
                                  value={props?.values?.zipcode}
                                  maxLength={6}
                                  onChange={props?.handleChange('zipcode')}
                                  onBlur={() => props?.setFieldTouched('zipcode', true)}
                                  error={props?.touched?.zipcode && props?.errors?.zipcode}
                                /> */}
                                <DefaultTextarea
                                  placeholder={'Area Range'}
                                  name={'Area Range'}
                                  id={'lat_long'}
                                  value={props?.values?.lat_long}
                                  onChange={props?.handleChange('lat_long')}
                                  onBlur={() => props?.setFieldTouched('lat_long', true)}
                                  error={props?.touched?.lat_long && props?.errors?.lat_long}
                                />
                                <span><strong>Note:- {''}</strong>Through the help of the given Google My Maps link, you may create a new Map by selecting various locations area-wise for the user addresses.<br /><a href="https://www.google.com/maps/d/" target="_blank">https://www.google.com/maps/d/</a>  </span>
                              </CCol>
                              <CCol md={6}>
                              <CFormLabel>Area Name (TH)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Area Name'}
                                  // name={'Area Name (TH)'}
                                  // maxLength={30}
                                  value={props?.values?.areaName_TH}
                                  onChange={props?.handleChange('areaName_TH')}
                                  onBlur={() => props?.setFieldTouched('areaName_TH', true)}
                                  error={props?.touched?.areaName_TH && props?.errors?.areaName_TH}
                                />
                                {/* <DefaultTextarea
                                  placeholder={'Area Range'}
                                  name={'Area Range'}
                                  id={'lat_long'}
                                  value={props?.values?.lat_long}
                                  onChange={props?.handleChange('lat_long')}
                                  onBlur={() => props?.setFieldTouched('lat_long', true)}
                                  error={props?.touched?.lat_long && props?.errors?.lat_long}
                                />
                                <span><strong>Note:- {''}</strong>Through the help of the given Google My Maps link, you may create a new Map by selecting various locations area-wise for the user addresses.<br /><a href="https://www.google.com/maps/d/" target="_blank">https://www.google.com/maps/d/</a>  </span> */}
                              </CCol>
                            </CRow>

                            <CRow>
                              <CCol md="2">
                                <button
                                  type="submit"
                                  className={'btn btn-success mt-2'}
                                  disabled={disable}
                                >
                                  Submit
                                </button>
                              </CCol>
                              <CCol md="2">
                                <button
                                  type="button"
                                  className={'btn btn-warning mt-2'}
                                  onClick={onClickReset}
                                >
                                  Reset
                                </button>
                              </CCol>
                              <CCol md="3"></CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default addArea
