import React,{useRef,useState} from 'react';
import { Formik } from 'formik';
import { useNavigate,Link} from 'react-router-dom'
import { post } from 'src/utils/apiManager';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import {
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CFormLabel,
    CRow,
  } from '@coreui/react'
  import { DefaultInput } from 'src/components/common/input'
import { validateItemSize } from 'src/utils/validator'
function add() {
    const navigate = useNavigate()
    const formRef = useRef(null)
    const [disable, setDisable] = useState(false)
    const submit = async (values) => {
      setDisable(true)
        let ItemData = {
          itemSize_EN: values?.itemSize_EN,
          itemSize_TH: values?.itemSize_TH,
        }
        post(`admin/ItemSize/add-item-size`, ItemData).then((data) =>handleResponse(data))
      }
      const handleResponse = (data) => {
        if (data?.status == true) {
          toast.success(data?.message)
          setTimeout(() => {
            navigate('/admin/item/item-size')
          }, 800)
        }
        else{
          setDisable(false)
          toast.error(data?.message)
        }
        formRef.current.resetForm()
      }
      const onClickReset = () => {
        console.log("this")
        console.log(this)
      };
    
  return (
    <>
        <CContainer >
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                <CCardGroup>
                    <CCardBody>
                    <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                    <CRow>
                          <CCol md="11">
                    <h1>New Item Size</h1>
                          </CCol>                    
                          <CCol md="1">
                            <Link className={'btn btn-success mt-2'} style={{background:'#d3dc40',border: '#d3dc40' }} to={{ pathname: `/admin/item/item-size` }}>
                                Back
                            </Link>
                          </CCol>
                        </CRow>
                    <Formik
                    initialValues={{
                      itemSize_EN: '',
                        itemSize_TH: '',
                    }}
                    validationSchema={validateItemSize}
                    onSubmit={(values, { setSubmitting }) => {
                        submit(values)
                        setSubmitting(false)
                    }}
                    innerRef={formRef}
                    >
                    {(props) => (
                        <form onSubmit={props?.handleSubmit}>
                            <CRow xs={12}>
                                <CCol md={6}>
                                    <CFormLabel>Item Size (EN)</CFormLabel>
                                    <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                    <DefaultInput
                                        type={"text"}
                                        placeholder={"Item Size (EN)"}
                                        value={props?.values?.itemSize_EN}
                                        onChange={props?.handleChange("itemSize_EN")}
                                        onBlur={() => props?.setFieldTouched("itemSize_EN", true)}
                                        error={props?.touched?.itemSize_EN && props?.errors?.itemSize_EN}
                                    />
                                </CCol>
                                <CCol md="6">
                                    <CFormLabel>Item Size (TH)</CFormLabel>
                                    <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                    <DefaultInput
                                        type={"text"}
                                        placeholder={"Item Size (TH)"}
                                        value={props?.values?.itemSize_TH}
                                        onChange={props?.handleChange("itemSize_TH")}
                                        onBlur={() => props?.setFieldTouched("itemSize_TH", true)}
                                        error={props?.touched?.itemSize_TH && props?.errors?.itemSize_TH}
                                    />
                                </CCol>
                            </CRow>
                        <CRow>
                            <CCol md="2">
                            <button
                                type="submit"
                                
                                className={'btn btn-success mt-2'}
                                disabled={disable || props?.isSubmitting}
                            >
                                Submit
                            </button>
                            </CCol>
                            <CCol md="2">
                            <button
                                type="button"
                                className={'btn btn-warning mt-2'}
                                onClick={()=>onClickReset(props?.isSubmitting)}
                                >
                                  Reset
                            </button>
                            </CCol>
                            <CCol md="3"></CCol>
                        </CRow>
                        </form>
                    )}
                    </Formik>
                    </CCardBody>
                </CCardGroup>
                </CCol>               
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer> 
    </>
  )
}

export default add