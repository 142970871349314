import React, { useEffect, useRef, useState } from 'react'
import { Formik } from 'formik'
import { useNavigate, Link } from 'react-router-dom'
import { post, get } from 'src/utils/apiManager'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CFormLabel,
  CImage,
  CRow,
} from '@coreui/react'
import { DefaultInput, DefaultTextarea } from 'src/components/common/input'
import { validateServices } from 'src/utils/validator'
import Select from 'react-select'
import { FormLabel } from 'react-bootstrap'
import { Ckeditor } from '../Template/Ckeditor'

function add() {
  const navigate = useNavigate()
  const formRef = useRef(null)
  const fileRef = useRef(null)
  const [invalidImage, setInvalidImage] = useState('')
  const [invalidServiceIconImage, setInvalidServiceIconImage] = useState('')
  const [description_EN, setDescription_EN] = useState('')
  const [description_TH, setDescription_TH] = useState('')
  const [userPolicy_EN, setUserPolicy_EN] = useState('')
  const [userPolicy_TH, setUserPolicy_TH] = useState('')
  const [selectedUnitOption, setSelectedUnitOption] = useState(null)
  const [unitData, setUnitData] = useState(null)
  const unitOption = []
  const [selectedItemOption, setSelectedItemOption] = useState([])
  const [itemSizeData, setItemSizeData] = useState(null)
  const itemOptions = [{ label: 'All Items', value: '001' }]
  const itemsData = []
  const [serviceImagePreview, setServiceImagePreview] = useState()
  const [serviceIconPreview, setServiceIconPreview] = useState()
  const [itemsDataOptions, setItemsDataOptions] = useState([])

  // const [itemsData, setItemsData] = useState(null)
  const getUnit = () => {
    get(`admin/services/get-unit`).then((data) => {
      setUnitData(data?.data)
    })
  }
  if (unitData !== null) {
    unitData.map((item, key) => {
      unitOption.push({ label: item?.title_EN, value: item?._id })
    })
  }

  const getItems = () => {
    get(`admin/services/get-items`).then((data) => {
      setItemSizeData(data?.data)
    })
  }
  if (itemSizeData !== null) {
    itemSizeData?.map((item, key) => {
      itemsData.push({ label: item?.itemName_EN, value: item?._id })
      itemOptions?.push({ label: item?.itemName_EN, value: item?._id })
    })
  }
  useEffect(() => {
    getUnit()
    getItems()
  }, [])

  
  const submit = async (values) => {
    if (values?.price <= 0 || selectedItemOption?.length == 0) {
      if (values?.item?.length == 0) {
        toast.error('Items required')
      } else if (values?.price <= 0) {
        formRef.current.setFieldValue('price', '')
      }
      // values?.price <= 0 ? formRef.current.setFieldValue('price', '') : formRef.current.setFieldValue('serviceQuota', '')
    } else {
      if (invalidImage?.length === 0) {
        // var SelectedItem = []
        // values?.item?.map((item, key) => {
        //   SelectedItem?.push(item?.value)
        // })
        // console.log(typeof(SelectedItem))
        let items = []
        selectedItemOption?.map((item) => {
          items.push(item.value)
        })

        let formData = new FormData()
        formData.append('serviceName_EN', values?.serviceName_EN)
        formData.append('serviceName_TH', values?.serviceName_TH)
        formData.append('subTitle_EN', values?.subTitle_EN)
        formData.append('subTitle_TH', values?.subTitle_TH)

        formData.append('description_EN', values?.description_EN)
        formData.append('description_TH', values?.description_TH)
        formData.append('userPolicy_EN', values?.userPolicy_EN)
        formData.append('userPolicy_TH', values?.userPolicy_TH)

        formData.append('unit', values?.unit)
        // formData.append('item', SelectedItem)
        // var items = []
        // formData.append('item', [selectedItemOption.map((item) => item.value)])
        formData.set('item', [selectedItemOption.map((item) => item.value)])

        formData.append('price', values?.price)
        formData.append('image', values?.image)
        formData.append('serviceQuota', values?.serviceQuota)
        formData.append('service_icon', values?.service_icon)

        post(`admin/services/add-service`, formData, 1).then((data) => handleResponse(data))
      } else {
        return
      }
    }
  }
  console.log('selectedItemOption===>>>', selectedItemOption)
  const handleResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      navigate('/admin/services')
    }
    // formRef.current.resetForm()
    setSelectedUnitOption('')
  }
  const onClickReset = () => {
    // Reset Button Function
    formRef.current.resetForm()
    fileRef.current.value = null
    setSelectedUnitOption('')
    setSelectedItemOption('')
  }

  return (
    <>
      <CContainer>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <CRow>
                        <CCol md="11">
                          <h1>New Service</h1>
                        </CCol>
                        <CCol md="1">
                          <Link
                            className={'btn btn-success mt-2'}
                            style={{ background: '#d3dc40', border: '#d3dc40' }}
                            to={{ pathname: `/admin/services` }}
                          >
                            Back
                          </Link>
                        </CCol>
                      </CRow>
                      <Formik
                        initialValues={{
                          serviceName_EN: '',
                          serviceName_TH: '',
                          subTitle_EN: '',
                          subTitle_TH: '',
                          description_EN: '',
                          description_TH: '',
                          userPolicy_EN: '',
                          userPolicy_TH: '',
                          unit: '',
                          // item: '',
                          price: '',
                          image: '',
                          service_icon: '',
                          serviceQuota: '',
                        }}
                        validationSchema={validateServices}
                        
                        onSubmit={(values, { setSubmitting }) => {
                          submit(values)
                          setSubmitting(false)
                        }}
                        innerRef={formRef}
                      >
                        {(props) => (
                          <form onSubmit={props?.handleSubmit}>
                            <CRow>
                              <CCol md={6}>
                                <CFormLabel>Service Name (EN)</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Service Name (EN)'}
                                  // name={'Service Name (EN)'}
                                  id={'serviceName_EN'}
                                  maxLength="50"
                                  value={props?.values?.serviceName_EN}
                                  onChange={props?.handleChange('serviceName_EN')}
                                  onBlur={() => props?.setFieldTouched('serviceName_EN', true)}
                                  error={
                                    props?.touched?.serviceName_EN && props?.errors?.serviceName_EN
                                  }
                                />
                                <CFormLabel>Sub Title (EN)</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Sub Title (EN)'}
                                  id={'subTitle_EN'}
                                  maxLength="50"
                                  value={props?.values?.subTitle_EN}
                                  onChange={props?.handleChange('subTitle_EN')}
                                  onBlur={() => props?.setFieldTouched('subTitle_EN', true)}
                                  error={props?.touched?.subTitle_EN && props?.errors?.subTitle_EN}
                                />
                                {console.log("props.errors",props.errors)}
                                <FormLabel> Description (EN)</FormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>
                                <Ckeditor
                                  setDescription_EN={setDescription_EN}
                                  description_EN={description_EN}
                                  value={props?.values?.description_EN}
                                  onChange={(val) => {
                                    setDescription_EN(val)
                                    props?.setFieldValue('description_EN', val)
                                  }}
                                  onBlur={() => props?.setFieldTouched('description_EN', true)}
                                  error={
                                    props?.touched?.description_EN && props?.errors?.description_EN
                                  }
                                />
                                <CFormLabel>Image</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>
                                <DefaultInput
                                  type={'file'}
                                  placeholder={'Image'}
                                  accept="image/*"
                                  refr={fileRef}
                                  onChange={(e) => {
                                    const imageFile = e?.target?.files[0]
                                    // console.log(imageFile)
                                    if (!imageFile.name.match(/\.(jpg|jpeg|png|gif)$/)) {
                                      setInvalidImage('Please select valid image')
                                    } else {
                                      setInvalidImage('')
                                    }
                                    //for image preview
                                    const reader = new FileReader()
                                    reader?.addEventListener('load', () =>
                                      setServiceImagePreview(reader?.result),
                                    )
                                    reader?.readAsDataURL(e?.target?.files[0])
                                    //for image preview
                                    props?.setFieldValue('image', e?.target?.files[0])
                                  }}
                                  onBlur={() => props?.setFieldTouched('image', true)}
                                  error={
                                    (props?.touched?.image && props?.errors?.image) || invalidImage
                                  }
                                />
                                {/* for image preview */}
                                {serviceImagePreview?.length > 0 ? (
                                  <>
                                    <CCol>
                                      <CImage
                                        rounded
                                        thumbnail
                                        className="mt-2"
                                        src={`${serviceImagePreview}`}
                                        style={{
                                          maxHeight: '80px',
                                          maxWidth: '80px',
                                          alignItems: 'center',
                                        }}
                                      />
                                    </CCol>
                                  </>
                                ) : (
                                  ''
                                )}
                                {/* for image preview */}

                                <FormLabel> User Policy (EN)</FormLabel>
                                {/* <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span> */}
                                <Ckeditor
                                  setUserPolicy_EN={setUserPolicy_EN}
                                  userPolicy_EN={userPolicy_EN}
                                  value={props?.values?.userPolicy_EN}
                                  onChange={(val) => {
                                    setDescription_EN(val)
                                    props?.setFieldValue('userPolicy_EN', val)
                                  }}
                                  // onBlur={() => props?.setFieldTouched('userPolicy_EN', true)}
                                  // error={props?.touched?.userPolicy_EN && props?.errors?.userPolicy_EN}
                                />
                                {/* <CFormLabel>Service Quota</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Service Quota'}
                                  value={props?.values?.serviceQuota}
                                  onChange={props?.handleChange('serviceQuota')}
                                  onBlur={() => props?.setFieldTouched('serviceQuota', true)}
                                  error={
                                    props?.touched?.serviceQuota && props?.errors?.serviceQuota
                                  }
                                /> */}
                                <CFormLabel>Unit (Per)</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>
                                <Select
                                  defaultValue={selectedUnitOption}
                                  onChange={(val) => {
                                    setSelectedUnitOption(val)
                                    props?.setFieldValue('unit', val.value)
                                  }}
                                  options={unitOption}
                                  placeholder={'Select unit'}
                                  value={selectedUnitOption}
                                />
                                {selectedUnitOption == undefined ? (
                                  <span className="text-danger">{props?.errors?.unit}</span>
                                ) : (
                                  ''
                                )}
                              </CCol>
                              <CCol md={6}>
                                <CFormLabel>Service Name (TH)</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Service Name (TH)'}
                                  id={'serviceName_TH'}
                                  maxLength="50"
                                  value={props?.values?.serviceName_TH}
                                  onChange={props?.handleChange('serviceName_TH')}
                                  onBlur={() => props?.setFieldTouched('serviceName_TH', true)}
                                  error={
                                    props?.touched?.serviceName_TH && props?.errors?.serviceName_TH
                                  }
                                />
                                <CFormLabel>Sub Title (TH)</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Sub Title (TH)'}
                                  id={'subTitle_TH'}
                                  maxLength="50"
                                  value={props?.values?.subTitle_TH}
                                  onChange={props?.handleChange('subTitle_TH')}
                                  onBlur={() => props?.setFieldTouched('subTitle_TH', true)}
                                  error={props?.touched?.subTitle_TH && props?.errors?.subTitle_TH}
                                />
                                <FormLabel> Description (TH)</FormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>
                                <Ckeditor
                                  setDescription_TH={setDescription_TH}
                                  description_TH={description_TH}
                                  value={props?.values?.description_TH}
                                  onChange={(val) => {
                                    setDescription_TH(val)
                                    // console.log(val,"DESCRIPT");
                                    props?.setFieldValue('description_TH', val)
                                  }}
                                  onBlur={() => props?.setFieldTouched('description_TH', true)}
                                  error={
                                    props?.touched?.description_TH && props?.errors?.description_TH
                                  }
                                />
                                <CFormLabel>Service Icon</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>
                                <DefaultInput
                                  type={'file'}
                                  placeholder={'Image'}
                                  accept="image/*"
                                  refr={fileRef}
                                  onChange={(e) => {
                                    const imageFile = e?.target?.files[0]
                                    if (!imageFile.name.match(/\.(jpg|jpeg|png|gif)$/)) {
                                      setInvalidServiceIconImage('Please select valid image')
                                    } else {
                                      setInvalidServiceIconImage('')
                                    }
                                    //for image preview
                                    const reader = new FileReader()
                                    reader?.addEventListener('load', () =>
                                      setServiceIconPreview(reader?.result),
                                    )
                                    reader?.readAsDataURL(e?.target?.files[0])
                                    //for image preview
                                    props?.setFieldValue('service_icon', e?.target?.files[0])
                                  }}
                                  onBlur={() => props?.setFieldTouched('service_icon', true)}
                                  error={
                                    (props?.touched?.service_icon && props?.errors?.service_icon) ||
                                    invalidServiceIconImage
                                  }
                                />
                                {/* for image preview */}
                                {serviceIconPreview?.length > 0 ? (
                                  <>
                                    <CCol>
                                      <CImage
                                        rounded
                                        thumbnail
                                        className="mt-2"
                                        src={`${serviceIconPreview}`}
                                        style={{
                                          maxHeight: '80px',
                                          maxWidth: '80px',
                                          alignItems: 'center',
                                        }}
                                      />
                                    </CCol>
                                  </>
                                ) : (
                                  ''
                                )}
                                {/* for image preview */}

                                <FormLabel> User Policy (TH)</FormLabel>
                                {/* <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span> */}
                                <Ckeditor
                                  setUserPolicy_TH={setUserPolicy_TH}
                                  userPolicy_TH={userPolicy_TH}
                                  value={props?.values?.userPolicy_TH}
                                  onChange={(val) => {
                                    setDescription_TH(val)
                                    props?.setFieldValue('userPolicy_TH', val)
                                  }}
                                  // onBlur={() => props?.setFieldTouched('userPolicy_TH', true)}
                                  // error={props?.touched?.userPolicy_TH && props?.errors?.userPolicy_TH}
                                />
                                <CFormLabel>Price</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Price'}
                                  id={'price'}
                                  maxLength="50"
                                  value={props?.values?.price}
                                  onChange={props?.handleChange('price')}
                                  onBlur={() => props?.setFieldTouched('price', true)}
                                  error={props?.touched?.price && props?.errors?.price}
                                />
                              </CCol>
                              <CCol md={12}>
                                <CFormLabel>Items</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>

                                <Select
                                  isMulti={true}
                                  defaultValue={selectedItemOption}
                                  onChange={(val) => {
                                    // if (val.length == 0) {
                                    //   setSelectedItemOption(val)
                                    // }
                                    // val.filter((ele) => {
                                    //   if (ele.value == '001') {
                                    //     setSelectedItemOption(itemsData)
                                    //   } else {
                                    //     setSelectedItemOption(val)
                                    //   }
                                    // })

                                    if (val.length == 0) {
                                      setSelectedItemOption(val)
                                    }
                                    val.filter((ele) => {
                                      if (ele.value == '001') {
                                        setSelectedItemOption(itemsData)
                                      } else {
                                        setSelectedItemOption(val)
                                      }
                                    })
                                    // props?.setFieldValue('item', val)
                                    props?.setFieldValue('item', ["0000000"])
                                  }}
                                  options={itemOptions}
                                  value={selectedItemOption}
                                  placeholder={'Select Items'}
                                  name="Item Size"
                                />
                                {selectedItemOption.length == 0 ? (
                                  <span className="text-danger">{props?.errors?.item}</span>
                                ) : (
                                  ''
                                )}
                              </CCol>
                              {/* <CCol md={12}>
                                <CFormLabel>Items</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>
                                <Select
                                  isMulti={true}
                                  defaultValue={selectedItemOption}
                                  onChange={(val) => {
                                    if (val.length == 0) {
                                      setSelectedItemOption(val)
                                    }
                                    val.filter((ele) => {
                                      if (ele.value == '001') {
                                        setSelectedItemOption(itemsData)
                                      } else {
                                        setSelectedItemOption(val)
                                      }
                                    })

                                    // setSelectedItemOption(val)
                                    if (val == selectedItemOption) setIsDisable(false)
                                    else setIsDisable(true)
                                    props?.setFieldValue('item', val)
                                  }}
                                  // options={[{ label: 'All Items', value: '001' }, ...itemsData]}
                                  options={itemsDataOptions}
                                  value={selectedItemOption}
                                  placeholder={'Select items'}
                                  name="Item Size"
                                />
                                {console.log('GHGHG', selectedItemOption)}
                                {selectedItemOption?.length == 0 ? (
                                  <span className="text-danger">Items required</span>
                                ) : (
                                  ''
                                )}
                              </CCol> */}
                            </CRow>
                            <CRow>
                              <CCol md="2">
                                <button
                                  type="submit"
                                  className={'btn btn-success mt-2'}
                                  disabled={props?.isSubmitting}
                                >
                                  Submit
                                </button>
                              </CCol>
                              <CCol md="2">
                                <button
                                  type="button"
                                  className={'btn btn-warning mt-2'}
                                  onClick={onClickReset}
                                >
                                  Reset
                                </button>
                              </CCol>
                              <CCol md="3"></CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default add
