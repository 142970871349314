import React, { useState, useEffect } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { get } from 'src/utils/apiManager';
import {
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CHeader,
    CRow,
    CButton,
    CImage,
    CCardHeader,
    CAccordion,
    CAccordionItem,
    CAccordionHeader,
    CAccordionBody,
} from '@coreui/react'
import { FaArrowLeft } from 'react-icons/fa'
import { ImageUrl } from 'src/config/config';
import avatar1 from 'src/assets/images/avatars/1.jpg'
import orderStatusImage from 'src/assets/images/orderStatusImage.png'

function Readytodeliverorder() {
    const { id } = useParams();
    const { orderId } = useParams();
    const [placedOrderDetails, setPlacedOrderDetails] = useState();
    const [orderItemsList, setOrderItemsList] = useState([]);
    const [orderQR, setOrderQR] = useState([]);
    const services = [];


    const getPlacedOrderInfo = () => {
        get(`admin/placed-order/view-singlepacket-details?_id=${atob(id)}&orderId=${atob(orderId)}`).then((response) => {
            setPlacedOrderDetails(response?.data[0])
            setOrderQR(response?.orderQR)
        })
    };

    const fetchOrderItemsList = () => {
        get(`/admin/placed-order/get-examin-item-details?order_id=${atob(orderId)}`).then((res) => {
            setOrderItemsList(res?.data)
        })
    }
    const showpandingService = (data) => {
        const pendingServiceArr = data?.map((i, k) => {
            return i?.serviceName_EN;
        })
        return pendingServiceArr.join(',')
    }
    const showadditionalService = (data) => {
        const additionalServiceArr = data?.map((i, k) => {
            return i?.serviceName_EN;
        })
        return additionalServiceArr?.join(',')
    }
    const orderCheck = () => {
        get(`/admin/placed-order/ready-to-deliver-order?order_id=${atob(orderId)}`).then((res) => {
            console.log(res, "order is ready to delivery or not");
            // setOrderItemsList(res?.data)
        })
    }
    useEffect(() => {
        getPlacedOrderInfo();
        fetchOrderItemsList();
        orderCheck();
    }, []);
    // console.log(orderQR, "orderQR")
    // console.log(placedOrderDetails, "placedOrderDetails")
    return (
        <>
            <CContainer fluid>
                <CRow>
                    <CCol>
                        <CCardGroup>
                            <CCard className="p-4">
                                <CHeader>
                                    <CRow>
                                        <CCol>
                                            {' '}
                                            {/* <Link style={{ color: '#000' }} to={{ pathname: `/admin/placed-order` }}>
                                                <FaArrowLeft />
                                            </Link> */}
                                            <span>{' '}Order Detail</span>
                                        </CCol>
                                    </CRow>
                                </CHeader>
                                <CCardBody>
                                    <center><i><b>Order is ready to deliver or not</b></i></center>
                                    <CRow className="mt-4">
                                        <CCol>
                                            <div className="card">
                                                <div className="card-body">
                                                    <strong>User Information</strong>
                                                    <hr />
                                                    <CRow>
                                                        <CCol md={10}>
                                                            {/* <span><i> 🙂 order detail  🙂</i></span> */}
                                                            <CRow>
                                                                <CCol md={2}>
                                                                    <strong>User Name :-</strong>
                                                                </CCol>
                                                                <CCol>
                                                                    <span>{placedOrderDetails?.userId[0]?.username}</span>
                                                                </CCol>
                                                            </CRow>
                                                            <CRow>
                                                                <CCol md={2}>
                                                                    <strong>User Email :-</strong>
                                                                </CCol>
                                                                <CCol>
                                                                    <span>{placedOrderDetails?.userId[0]?.email}</span>
                                                                </CCol>
                                                            </CRow>
                                                            <CRow>
                                                                <CCol md={2}>
                                                                    <strong>User Mobile NO. :-</strong>
                                                                </CCol>
                                                                <CCol>
                                                                    <span>{placedOrderDetails?.userId[0]?.mobile}</span>
                                                                </CCol>
                                                            </CRow>
                                                        </CCol>

                                                        <CCol md={2}>
                                                            <CRow>
                                                                <CCol>
                                                                    <div className="myImges row">

                                                                        <div className="col-md-10">
                                                                            <figure>
                                                                                <img src={`${ImageUrl}${placedOrderDetails?.userId[0]?.image}`} alt='img' />
                                                                                <figcaption>
                                                                                    {/* <button type="button"><FaTrash onClick={() => deleteParticularFile(index)} style={{ color: 'red' }} /></button> */}
                                                                                </figcaption>
                                                                            </figure>
                                                                        </div>
                                                                    </div>
                                                                </CCol>
                                                            </CRow>
                                                        </CCol>
                                                    </CRow>
                                                </div>
                                            </div>
                                        </CCol>
                                    </CRow>
                                </CCardBody>
                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </>
    )
}

export default Readytodeliverorder