import { CCol, CContainer, CRow } from '@coreui/react'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const OrderList = () => {
  const [orderListData, setOrderListData] = useState([])
  return (
    <CContainer>
      <CRow>
        <CCol xs={2}>
          <Link
            to="/admin/offline-order-add"
            style={{
              textDecoration: 'none',
              color: 'black',
              fontSize: '55px',
              fontWeight: 'lighter',
              boxShadow: '1px 1px 3px black',
            }}
          >
            <div
              style={{
                padding: '30px',
                border: '1px solid black',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              +
            </div>
          </Link>
        </CCol>
        {orderListData.length > 0 &&
          orderListData.map(() => (
            <CCol xs={2}>
              <Link to="/admin/offline-order-add">
                <div></div>
              </Link>
            </CCol>
          ))}
      </CRow>
    </CContainer>
  )
}

export default OrderList
