import React, { useEffect, useState, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { get, post, deleteApi } from '../../utils/apiManager'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CCardSubtitle,
  CTable,
  CRow,
  CImage,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import { DefaultInputWithIcon } from 'src/components/common/input'
import Pagination from 'react-js-pagination'
import { FaRegEye, FaReply } from 'react-icons/fa'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

function index() {
  const navigate = useNavigate()
  const [introList, setIntroList] = useState([])
  const [replyId, setReplyId] = useState()
  const [enquiryDataList, setEnquiryDataList] = useState([])
  const [imageUrl, setimageUrl] = useState('')
  const [totalPages, settotalPages] = useState(0)
  const [pageData, setpageData] = useState({
    page: 1,
    perPage: 10,
    searchItem: '',
  })
  const [searchItem, setsearchItem] = useState('')

  const inputRef = useRef(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const onClickUserReply = async (value) => {
    setShow(true);
    // console.log(value._id)
    // console.log(replyId)
    // { replyId: value }
    setReplyId(value?._id);
  }

  const getEnquiryData = () => {
    get(`/admin/ContactUS/enquiry-list?page=${pageData?.page || 1}&perPage=${pageData?.perPage || 10}&searchItem=${pageData?.searchItem || ''
      }`).then((response) => {
        handleResponse(response)
      })
  }
  const handleResponse = (response) => {
    if (!response?.status) toast.success(response?.message, response?.status)
    else {
      setEnquiryDataList([...response?.data])
      settotalPages(response?.pages)
      setimageUrl(response?.imageUrl)
    }
  }
  const changePage = (index) => {
    setpageData({
      ...pageData,
      page: index,
    })
  }
  const onSearchCLick = () => {
    setpageData({
      ...pageData,
      searchItem: searchItem,
    })
  }
  const onClickUserView = async (value) => {
    navigate('/admin/enquiry/enquiry-view', { state: value })
  }
  const onclickReply = async () => {

    let msg = inputRef?.current?.value
    let message = {
      id: replyId,
      adminReply: msg,
    }
    console.log(message)
    post(`admin/ContactUS/mail-replay`, message).then((data) => mailResponse(data))
  }
  const mailResponse = (data) => {
    // console.log(data)
    if (data?.status == true) {
      toast.success(data?.message)
      setShow(false)
    } else {
      toast.error(data?.message)
    }
  }

  // const deleteData = (id) => {
  //   if (confirm("Are you sure")) {
  //     let formData = {
  //       _id: id,
  //     };
  //     deleteApi(`/admin/Cms/delete?_id=${id}`).then((data) => {
  //       handleDelete(data);
  //     });
  //   }
  // };    
  // const handleDelete = (data) => {
  //     toast.success(data.message, data.status);
  //     getBlogData();
  //   };
  const executeOnClick = async (isExpanded) => {
    // console.log(isExpanded);
  }
  useEffect(() => {
    getEnquiryData()
  }, [pageData])
  return (
    <>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <CCardSubtitle as="h4">Enquiry List</CCardSubtitle>
                  <CRow>
                    <CCol md="8">
                      <DefaultInputWithIcon
                        type={'text'}
                        placeholder="Search"
                        customType={'search'}
                        value={searchItem}
                        onClick={onSearchCLick}
                        onChange={(e) => {
                          onSearchCLick()
                          setsearchItem(e?.target?.value)
                        }}
                      />
                    </CCol>
                    {/* <CCol md="2"></CCol> */}
                    {/* <CCol md="4">
                      <Link to={{ pathname: '/admin/add-blog' }} className="btn btn-dark">
                        <CIcon icon={cilPlus} />
                        Add
                      </Link>
                    </CCol> */}
                  </CRow>
                </CHeader>

                <CCardBody>
                  <CTable bordered hover responsive>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell>S. No</CTableHeaderCell>
                        <CTableHeaderCell>Name</CTableHeaderCell>
                        <CTableHeaderCell>Email</CTableHeaderCell>
                        <CTableHeaderCell>Phone</CTableHeaderCell>
                        <CTableHeaderCell>Action</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {enquiryDataList &&
                        enquiryDataList?.map((data, key) => {
                          const values = data?.description;
                          const objectValues = { ...values }
                          return (
                            <CTableRow key={key}>
                              <CTableDataCell>{pageData?.perPage * (pageData?.page - 1) + key + 1}</CTableDataCell>
                              <CTableDataCell>{`${data?.name}`}</CTableDataCell>
                              <CTableDataCell>{data?.email}</CTableDataCell>
                              <CTableDataCell>{data?.mobile}</CTableDataCell>
                              <CTableDataCell >
                                <span className="mx-2" onClick={() => onClickUserReply(data)} style={{ fontSize: '20px', color: '#d3dc40', cursor: 'pointer' }}><FaReply /></span>
                                <span className="mx-2" onClick={() => onClickUserView(data)} style={{ fontSize: '20px', color: '#d3dc40', padding: '5px', cursor: 'pointer' }}><FaRegEye /></span>
                                {/* <span onClick={() => onClickUserView(ele)} style={{color:'#d3dc40',padding:'5px'}}><FaRegEye/></span> */}
                              </CTableDataCell>
                            </CTableRow>
                          )
                        })}
                    </CTableBody>
                  </CTable>
                  {!enquiryDataList?.length && (
                    <>
                      <h1>No Data</h1>
                    </>
                  )}
                  {enquiryDataList?.length ? <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={pageData?.page}
                    itemsCountPerPage={pageData?.perPage}
                    totalItemsCount={totalPages * 10}
                    pageRangeDisplayed={5}
                    onChange={changePage}
                  /> : ''}
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>

        <Modal show={show} onHide={handleClose} centered={true}>
          <Modal.Header closeButton>
            {/* <Modal.Title>Modal heading</Modal.Title> */}
            <p>Woohoo, you're reading this text in a modal!</p>
            {/* {replyId} */}
          </Modal.Header>
          <Modal.Body>
            {/* <input type= "text" value={replyId} ref={inputRef}></input> */}
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label className="fw-bold">Example textarea</Form.Label>
                <Form.Control as="textarea" rows={2} autoFocus ref={inputRef} />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={() => onclickReply()}>
              Reply
            </Button>
          </Modal.Footer>
        </Modal>
      </CContainer>
    </>
  )
}

export default index