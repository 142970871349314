import { CCard, CCardBody, CCardGroup, CCol, CContainer, CHeader, CRow } from '@coreui/react'
import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { ImageUrl, ImageUrl2, baseURL } from 'src/config/config'
import { deleteApi, get, post, put } from 'src/utils/apiManager'
import Barcode from 'src/assets/images/barcode-scan.png'
import ImagePreview from './previewImage'
import ItemImagePreview from './itemImagePreview'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import {
  clearGarmentData,
  setItem,
  setBrand,
  setColors,
  setDescription,
  setPicture,
  setStain,
  setStainImage,
  setWashType,
} from 'src/reduxToolkit/slice/garmentShorting'
import { toast } from 'react-toastify'

const ItemDetails = () => {
  const { bagId, itemId } = useParams()

  const [itemDetail, setItemDetails] = useState(null)
  const [items, setItems] = useState([])
  const [orderId, setOrderId] = useState([])
  const [selectedItem, setSelectedItem] = useState('')
  const [barcodeValue, setBarcodeValue] = useState('')
  const [selectedBarCode, setSelectedBarCode] = useState('')
  const [visible, setVisible] = useState(false)
  const [visiblePreview, setVisiblePreview] = useState(false)
  const [itemDetailOrder, setItemDetailOrder] = useState(null)
  const [itemCode, setItemCode] = useState('')
  const [unit, setUnit] = useState('')
  const [itemLength, setItemLength] = useState('')
  const [unitValue, setUnitValue] = useState('')
  const inputRef = useRef()
  const navigate = useNavigate()
  const { state, search } = useLocation()


  const dispatch = useDispatch()
  useEffect(() => {
    setSelectedBarCode(itemDetail?.item?.itemCode)
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [itemDetail])

  const handleClickOutside = (event) => {
    // if (ref.current && !ref.current.contains(event.target)) {
    // }

    // console.log(inputRef)
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  const handleKeyPress = async (event) => {
    // if(event.charCode)
    if (event?.charCode === 13 && barcodeValue?.length > 0) {
      // navigate(`/admin/offline-order/bag/add-item/${bagId}/${barcodeValue}`)
      const { data, status, message } = await get(
        `admin/offline-order/get-items/${bagId}/${barcodeValue}`,
      )
      if (!status) {
        toast.error(message)
      } else if (data) {
        setItemDetails(data)
        setSelectedItem(data?.item?.itemId)
      } else {
        navigate(`/admin/offline-order/bag/add-item/${bagId}/${barcodeValue}`)
      }
    }
  }

  useEffect(() => {
    get(`/admin/offline-order/bag-item-details/${bagId}/${itemId}`).then((data) => {
      if (data.status) {
        setItemDetails({ ...data.data[0] })
        if (search) {
          let newStr = search.replace("?itemcode=", "")
          getItemDetail(newStr)
        } else {
          getItemDetail(data.data[0].item?.itemCode)

        }
      }
    })
  }, [itemId, bagId])



  useEffect(() => {
    get(`/admin/offline-order/get-order-bag/${bagId}`).then((data) => {
      console.log({ bag: data })
      if (data.status) {
        get(`/admin/unit/get-unit/${data.data[0]?.service?.unit}`).then((res) => {
          setUnit(res?.data?.title_EN)
        })
        setUnitValue(data?.data[0]?.unitValue)
        setItemLength(data.data[0].item.length)

        setItems([...data.data[0].item])
        setOrderId(data.data[0].orders?.[0]?._id)
      }
    })
  }, [bagId])

  const handleItemClick = (id) => {
    // get(`/admin/offline-order/bag-item-details/${bagId}/${itemId}`).then((data) => {
    //   console.log('chala+++++->', data, 'Id', id)
    //   if (data.status) {
    //     setItemDetails({ ...data.data[0] })
    //   }
    // })
    setSelectedItem(id)
    navigate(`/admin/offline-order/bag-item/${bagId}/${id}`)
  }

  const handleItem = (action) => {
    const item_details = { ...itemDetail };
    // const item_details = {
    //   "item": { "draftItem": false }
    // };

    item_details["item"]["draftItem"] = !item_details.item.draftItem
    setItemDetails({ ...item_details })
    if (action == 'remove') {
      post(`/admin/offline-order/draft-item`, { itemId }).then((res2) => {


        if (res2?.status) {
          toast.success(res2?.message)
          // getItemDetail(itemCode)
          navigate(`/admin/offline-order/bag-item/${bagId}/${selectedItem}`)
          // navigate('/admin/offline-order')
          // dispatch(clearGarmentData())
        }
      })
    } else {
      put(`/admin/offline-order/bag-item-details/${bagId}/${itemId}`, { action }).then((data) => {
        if (data?.status) {
          get(`/admin/offline-order/get-order-bag/${bagId}`).then((res) => {
            if (res?.status) {
              setItems([...res.data[0].item])
              setOrderId(res.data[0].orders[0]._id)
            }
          })
          get(`/admin/offline-order/bag-item-details/${bagId}/${itemId}`).then((data) => {
            if (data.status) {
              setItemDetails({ ...data.data[0] })
            }
          })
        }
      })
    }

  }

  const changeStatusButton = () => {
    // let checkAddItem = items.every((item) => item.status == "active");
    // if (!checkAddItem) {
    //   toast.error("First add all items inside the bag !")
    //   return
    // }
    setSelectedItem('')
  }


  const handleBag = () => {
    let checkAddItem = items.every((item) => item.status == "active");
    if (!checkAddItem) {
      toast.error("Add first all item inside the bag !")
      return
      // Swal.fire({
      //   title: 'Add first all item inside the bag !',
      //   // showCancelButton: true,
      //   confirmButtonText: 'Yes',
      //   customClass: {
      //     actions: 'my-actions',
      //     confirmButton: 'order-2',
      //   },
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     // Swal.fire('Completed!', '', 'success')
      //     // api()
      //   }
      // })
    } else {
      Swal.fire({
        title: 'Are you sure to complete the bag?',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          actions: 'my-actions',
          confirmButton: 'order-2',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          // Swal.fire('Completed!', '', 'success')
          api()
        }
      })
    }


    const api = () =>
      put(`/admin/offline-order/bag/edit-bag-status/${bagId}`, {}).then((data) => {
        if (data?.status) {
          navigate(`/admin/offline-order/bags/${orderId}`)
        } else {
          toast.error(data?.message)
        }
      })
  }
  const handleEdit = (step) => {
    dispatch(clearGarmentData())
    // console.log('itemDetail', itemDetail)
    // if (itemDetail?.status) {
    //   // setEditData(data)
    //   dispatch(setItem(itemDetail?.item?.itemId))
    //   dispatch(setBrand(itemDetail?.item?.brand))
    //   dispatch(setColors(itemDetail?.item?.colorIds))
    //   dispatch(setWashType(itemDetail?.item?.process))
    //   // itemDetail?.item?.stain.split(',').map((v) => {
    //   //   dispatch(setStain(v))
    //   // })
    //   dispatch(setDescription(itemDetail?.item?.description))
    //   // setInput(itemDetail?.item?.description)
    //   dispatch(setStainImage(itemDetail?.item?.image))
    //   dispatch(setPicture(itemDetail?.item?.picture))
    //   // setImgUrl(itemDetail?.item?.image)
    //   // setPicture(ImageUrl2 + itemDetail?.item?.picture)
    //   // setPicUrl(itemDetail?.item?.picture)
    //   // if (itemDetail?.item && itemDetail?.item?.image.length > 0) {
    //   //   const img = itemDetail?.item?.image.map((file) => {
    //   //     return ImageUrl2 + file
    //   //   })
    //   //   setFiles(img)
    //   // }
    // }
    navigate(`/admin/offline-order/bag/add-item/${bagId}/${selectedBarCode}?step=${step}&edit=true`)
  }

  const getItemDetail = (itemCode) => {
    setItemCode(itemCode)
    get(`/admin/placed-order/item-details?itemCode=${itemCode}`).then((data) => {
      if (data.status) {
        setSelectedItem(data.data._id)
        setItemDetailOrder({ ...data.data })
      }
    })
  }

  const viewOrder = () => {
    navigate(`/admin/search-bags/${state.orderData.data[0].orders[0].orderId}`)
  }

  console.log("lk;sdflnasl;fnsla;n")

  const deleteItem = (id) => {
    Swal.fire({
      title: 'Are you sure you want to delete item?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      customClass: {
        actions: 'my-actions',
        confirmButton: 'order-2',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        post(`admin/offline-order/item-delete?`, {
          bagId: bagId,
          itemId: id,
        })
          .then((res) => {
            if (res) {
              get(`/admin/offline-order/get-order-bag/${bagId}`).then((res) => {
                if (res?.status) {
                  setItems([...res.data[0].item])
                  setOrderId(res.data[0].orders[0]._id)
                  // if (action == 'remove') {
                  //   navigate('/admin/offline-order')
                  //   dispatch(clearGarmentData())
                  // }
                }
              })
            }
          })
          .catch(() => { })
      }
    })
  }

  const handlePreviewClick = (index) => {
    setVisiblePreview(visiblePreview === index ? null : index);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <CCard style={{ flex: '0.2' }}>
        <CRow className="justify-content-center" style={{ padding: '0px 2px' }}>
          <CCol xs={11}>
            <CRow>
              <CCol
                xs={12}
                style={{
                  borderBottom: '1px solid #ccc',
                  width: '100%',
                  padding: '8px 5px',
                }}
              >
                Name : {itemDetailOrder?.user?.username} <br />
                Service Name : {itemDetailOrder?.service?.serviceName_EN} <br />
                Order Id : {itemDetailOrder?.order?.orderId} <br />
                Item Code : {itemCode} <br />
              </CCol>
            </CRow>
            {items.length > 0 &&
              items.map((_item, index) => (
                <>
                  {/* {_item.status !== 'inactive' && ( */}
                  <CRow>
                    <CCol
                      xs={12}
                      style={{
                        borderBottom: '1px solid #ccc',
                        width: '100%',
                        padding: '8px 5px',
                        backgroundColor: selectedItem === _item._id ? 'green' : 'white',
                        color: selectedItem === _item._id ? 'white' : '#000',
                      }}
                      onClick={() => {
                        handleItemClick(_item._id), getItemDetail(_item?.itemCode)
                      }}
                    >
                      Item {index + 1}
                      <span style={{ float: 'Right' }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill={selectedItem === _item._id ? 'white' : '#000'}
                        >
                          <path d="M7.293 4.707 14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z" />
                        </svg>
                      </span>
                      <span
                        onClick={(e) => {
                          e.stopPropagation()
                          deleteItem(_item._id)
                        }}
                        class="mx-2 text-danger"
                        style={{
                          float: 'right',
                          fontSize: '20px',
                          padding: '5px',
                          cursor: 'pointer',
                          alignSelf: 'end',
                          marginTop: '-6px',
                        }}
                      >
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          viewBox="0 0 448 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"></path>
                        </svg>
                      </span>
                    </CCol>
                  </CRow>
                  {/* )} */}
                </>
              ))}
            {/* {selectedItem ? (
              <button
                style={{
                  marginTop: '20px',
                  width: '100%',
                  background: '#d3dc40',
                  border: '#d3dc40',
                  padding: '10px 0',
                }}
                onClick={() => changeStatusButton()}
              >
                Scan QR
              </button> */}

            <button
              style={{
                marginTop: '20px',
                width: '100%',
                background: '#d3dc40',
                border: '#d3dc40',
                padding: '10px 0',
              }}
              onClick={() => handleBag()}
            >
              Complete bag
            </button>

          </CCol>
        </CRow>
      </CCard>
      {
        selectedItem ? (
          <CCard style={{ flex: '0.6' }}>
            <CCardGroup>
              <CCardBody>
                {itemDetail && Object.keys(itemDetail)?.length > 0 ? (
                  <>
                    <CHeader className="mb-3">
                      <h4 className="mb-3">Item summary page</h4>
                      <CRow>
                        <CCol md="12">
                          <button
                            className="btn btn-success"
                            style={{
                              width: '100%',
                              background: '#d3dc40',
                              border: '#d3dc40',
                            }}
                            onClick={viewOrder}
                          >
                            View Order Detail
                          </button>
                        </CCol>
                      </CRow>
                    </CHeader>
                    <CRow className="justify-content-center mb-2 progress-list">
                      <CCol
                        xs={2}
                        className="align-items-center"
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <div style={{ position: 'relative' }}>
                          <div className="icon-box" onClick={() => handleEdit('itemType')}>
                            <div>
                              <i class="fa-solid fa-shirt"></i>
                            </div>
                            <div>{itemDetail.items && <i class="fa-solid fa-check"></i>}</div>
                          </div>
                          <p style={{ textAlign: 'center', fontWeight: '600' }}>Type</p>
                        </div>
                        <div>
                          {/* <img src={`${ImageUrl2}/${itemDetail.items.image}`} /> */}
                          {itemDetail?.items && itemDetail?.items?.itemName_EN}
                        </div>
                      </CCol>
                      <CCol
                        xs={2}
                        className="align-items-center"
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <div style={{ position: 'relative' }}>
                          <div className="icon-box" onClick={() => handleEdit('brand')}>
                            <div>
                              <i class="fa-solid fa-shirt"></i>
                            </div>
                            <div>{itemDetail?.item?.brand && <i class="fa-solid fa-check"></i>}</div>
                          </div>
                          <p style={{ textAlign: 'center', fontWeight: '600' }}>Brand</p>
                        </div>
                        <div>{itemDetail?.item?.brand && itemDetail?.item?.brand}</div>
                      </CCol>
                      <CCol
                        xs={2}
                        className="align-items-center"
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <div style={{ position: 'relative' }}>
                          <div className="icon-box" onClick={() => handleEdit('color')}>
                            <div>
                              <i class="fa-solid fa-shirt"></i>
                            </div>
                            <div>
                              {itemDetail?.colors?.length > 0 && <i class="fa-solid fa-check"></i>}
                            </div>
                          </div>
                          <p style={{ textAlign: 'center', fontWeight: '600' }}>Colors</p>
                        </div>
                        <CCol cs={6}>
                          {itemDetail?.colors?.length > 0 &&
                            itemDetail?.colors?.map((_color) => {
                              return (
                                <div
                                  style={{
                                    height: '50px',
                                    width: '50px',
                                    borderRadius: '50%',
                                    // border: '1px solid black',
                                    backgroundColor: _color?.colorCode,
                                    cursor: 'pointer',
                                    boxShadow: '0 1px 3px rgb(0 0 0 / 36%)',
                                    margin: '4px',
                                  }}
                                ></div>
                              )
                            })}
                        </CCol>
                      </CCol>
                      <CCol
                        xs={2}
                        className="align-items-center"
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <div style={{ position: 'relative' }}>
                          <div className="icon-box" onClick={() => handleEdit('process')}>
                            <div>
                              <i class="fa-solid fa-shirt"></i>
                            </div>
                            <div>
                              <i class="fa-solid fa-check"></i>
                            </div>
                          </div>
                          <p style={{ textAlign: 'center', fontWeight: '600' }}>Washing Programs</p>
                        </div>
                        <div>{itemDetail?.processes?.processName_EN}</div>
                      </CCol>
                      <CCol
                        xs={2}
                        className="align-items-center"
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <div style={{ position: 'relative' }}>
                          <div className="icon-box" onClick={() => handleEdit('itemImage')}>
                            <div>
                              <i class="fa-solid fa-shirt"></i>
                            </div>
                            <div>
                              {/* {itemDetail?.item?.image[0]?.length > 0 && (
                              <i class="fa-solid fa-check"></i>
                            )} */}
                              {itemDetail?.item?.picture && (
                                <i class="fa-solid fa-check"></i>
                              )}
                            </div>
                          </div>
                          <p style={{ textAlign: 'center', fontWeight: '600' }}>Image</p>
                        </div>
                        {itemDetail?.item?.picture && (
                          <>
                            <img
                              src={ImageUrl + itemDetail.item.picture}
                              style={{ cursor: 'pointer', width: '50%', height: '100px' }}
                              onClick={() => setVisible(!visible)}
                            // data-coreui-toggle="modal"
                            // data-coreui-target="#staticBackdrop"
                            />
                            <ImagePreview
                              visible={visible}
                              setVisible={setVisible}
                              image={ImageUrl + itemDetail.item.picture}
                            />
                          </>
                        )}
                      </CCol>
                      <CCol
                        xs={2}
                        className="align-items-center"
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <div>
                          {Array.isArray(itemDetail.item.stain) && itemDetail.item.stain.length > 0 && <div style={{ position: 'relative' }}>
                            <div className="icon-box" onClick={() => handleEdit('stain')}>
                              <div>
                                <i class="fa-solid fa-shirt"></i>
                              </div>
                              <div> <i class="fa-solid fa-check"></i> </div>
                            </div>
                            <p style={{ textAlign: 'center', fontWeight: '600' }}>Stain</p>
                          </div>}
                          <div>
                            {Array.isArray(itemDetail.item.stain) && itemDetail.item.stain.map((data, i) => {
                              return (
                                <div key={i}>
                                  <div>
                                    {data?.stainName}
                                    <p onClick={() => handlePreviewClick(i)}>
                                      {data.images && data.images.length > 0 ? (
                                        <i className="fa-solid fa-images"></i>
                                      ) : (
                                        ''
                                      )}
                                    </p>
                                  </div>
                                  {visiblePreview === i && (
                                    <ItemImagePreview
                                      visiblePreview={visiblePreview === i}
                                      setVisiblePreview={() => handlePreviewClick(i)}
                                      image={data.images}
                                      safeMessage={data?.safeMessage}
                                    />
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        {/* <div>{itemDetail.item.stain}</div> */}

                      </CCol>
                    </CRow>
                    <CRow>
                      <h5>Instructions:</h5>
                      <div>{itemDetail.item.description}</div>
                    </CRow>
                    {/* <CRow className="justify-content-center mt-2">
                  {itemDetail.item.image &&
                    itemDetail.item.image.length > 0 &&
                    itemDetail.item.image.map((_img, index) => (
                      <CCol xs={2}>
                        <div
                          style={{
                            border: '1px solid black',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            boxShadow: '1px 1px 3px black',
                            border: 'none',
                          }}
                        >
                          <img src={ImageUrl2 + _img} style={{ width: '100%', height: '150px' }} />
                        </div>
                      </CCol>
                    ))}
                </CRow> */}
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '72vh',
                        // overflowY: 'scroll',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <img src={Barcode} alt="barcode" style={{ maxWidth: '200px' }} />
                      <h4 className="mt-3">Scan OR code for item</h4>
                      {console.log({ unit, itemLength, unitValue })}
                      {
                        unit === 'pcs' && Number(itemLength) >= Number(unitValue) ? null : (
                          <input
                            autoFocus={true}
                            // disabled
                            // hidden
                            // style={{ border: 'none', outline: 'none', widht: '0', height: '0' }}
                            ref={inputRef}
                            name="barcodeValue"
                            id="barcodeValue"
                            value={barcodeValue}
                            onChange={(event) => setBarcodeValue(event.target.value)}
                            onKeyPress={handleKeyPress}
                          // onClick={console.log('running')}
                          />
                        )
                      }

                    </div>
                  </>
                )}

                {itemDetail &&
                  Object.keys(itemDetail)?.length > 0 &&
                  // itemDetail.item.status === 'inactive' && (

                  (<CRow className="justify-content-between p-3">
                    <CCol xs={3} className="justify-content-end">
                      {itemDetail?.item?.draftItem ? <button
                        style={{
                          marginTop: '20px',
                          width: '100%',
                          background: '#d3dc40',
                          border: '#d3dc40',
                          padding: '10px 0',
                        }}
                        onClick={() => handleItem('remove')}
                      >
                        Remove from draft
                      </button> : <button
                        style={{
                          marginTop: '20px',
                          width: '100%',
                          background: '#d3dc40',
                          border: '#d3dc40',
                          padding: '10px 0',
                        }}
                        onClick={() => handleItem('remove')}
                      >
                        Draft Item
                      </button>}
                    </CCol>

                    {
                      itemLength < Number(unitValue) ? (
                        <>
                          {
                            itemDetail?.item?.draftItem ? (
                              <CCol xs={2} className="justify-content-end">
                                <button
                                  style={{
                                    marginTop: '20px',
                                    width: '100%',
                                    background: '#d3dc40',
                                    border: '#d3dc40',
                                    padding: '10px 0',
                                  }}
                                  onClick={() => { handleItem('add'), changeStatusButton() }}
                                >
                                  Add more
                                </button>
                              </CCol>
                            ) : (
                              <CCol xs={2} className="justify-content-end">
                                <button
                                  style={{
                                    marginTop: '20px',
                                    width: '100%',
                                    background: '#d3dc40',
                                    border: '#d3dc40',
                                    padding: '10px 0',
                                  }}
                                  onClick={() => { handleItem('add'), changeStatusButton() }}
                                >
                                  Confirm
                                </button>
                              </CCol>
                            )
                          }
                        </>
                      ) :

                        <CCol xs={2} className="justify-content-end">
                          <button
                            style={{
                              marginTop: '20px',
                              width: '100%',
                              background: '#d3dc40',
                              border: '#d3dc40',
                              padding: '10px 0',
                            }}
                            onClick={() => { handleItem('add'), changeStatusButton() }}
                          >
                            Confirm
                          </button>
                        </CCol>
                    }


                  </CRow>
                  )}
              </CCardBody>
            </CCardGroup>
          </CCard>
        ) : (
          <>
            {' '}
            <CCard style={{ flex: '0.6' }}>
              <CCardGroup>
                <CCardBody>
                  {console.log({ itemDetail })}
                  {itemDetail && itemDetail !== null &&
                    Object.keys(itemDetail)?.length > 0 &&
                    itemDetail?.item?.status === 'inactive' ? (
                    <>
                      <CHeader className="mb-3">
                        <h4 className="mb-3">Item summary page</h4>
                        <CRow>
                          <CCol md="12">
                            <button
                              className="btn btn-success"
                              style={{
                                width: '100%',
                                background: '#d3dc40',
                                border: '#d3dc40',
                              }}
                              onClick={viewOrder}
                            >
                              View Order Detail
                            </button>
                          </CCol>
                        </CRow>
                      </CHeader>
                      <CRow className="justify-content-center mb-2 progress-list">
                        <CCol
                          xs={2}
                          className="align-items-center"
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <div style={{ position: 'relative' }}>
                            <div className="icon-box" onClick={() => handleEdit('itemType')}>
                              <div>
                                <i class="fa-solid fa-shirt"></i>
                              </div>
                              <div>{itemDetail.items && <i class="fa-solid fa-check"></i>}</div>
                            </div>
                            <p style={{ textAlign: 'center', fontWeight: '600' }}>Type</p>
                          </div>
                          <div>
                            {/* <img src={`${ImageUrl2}/${itemDetail.items.image}`} /> */}
                            {itemDetail.items && itemDetail.items.itemName_EN}
                          </div>
                        </CCol>
                        <CCol
                          xs={2}
                          className="align-items-center"
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <div style={{ position: 'relative' }}>
                            <div className="icon-box" onClick={() => handleEdit('brand')}>
                              <div>
                                <i class="fa-solid fa-shirt"></i>
                              </div>
                              <div>{itemDetail.item.brand && <i class="fa-solid fa-check"></i>}</div>
                            </div>
                            <p style={{ textAlign: 'center', fontWeight: '600' }}>Brand</p>
                          </div>
                          <div>{itemDetail.item.brand && itemDetail.item.brand}</div>
                        </CCol>
                        <CCol
                          xs={2}
                          className="align-items-center"
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <div style={{ position: 'relative' }}>
                            <div className="icon-box" onClick={() => handleEdit('color')}>
                              <div>
                                <i class="fa-solid fa-shirt"></i>
                              </div>
                              <div>
                                {itemDetail?.colors?.length > 0 && <i class="fa-solid fa-check"></i>}
                              </div>
                            </div>
                            <p style={{ textAlign: 'center', fontWeight: '600' }}>Colors</p>
                          </div>
                          <CCol cs={6}>
                            {itemDetail?.colors?.length > 0 &&
                              itemDetail?.colors?.map((_color) => {
                                return (
                                  <div
                                    style={{
                                      height: '50px',
                                      width: '50px',
                                      borderRadius: '50%',
                                      // border: '1px solid black',
                                      backgroundColor: _color?.colorCode,
                                      cursor: 'pointer',
                                      boxShadow: '0 1px 3px rgb(0 0 0 / 36%)',
                                      margin: '4px',
                                    }}
                                  ></div>
                                )
                              })}
                          </CCol>
                        </CCol>
                        <CCol
                          xs={2}
                          className="align-items-center"
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <div style={{ position: 'relative' }}>
                            <div className="icon-box" onClick={() => handleEdit('process')}>
                              <div>
                                <i class="fa-solid fa-shirt"></i>
                              </div>
                              <div>
                                <i class="fa-solid fa-check"></i>
                              </div>
                            </div>
                            <p style={{ textAlign: 'center', fontWeight: '600' }}>Washing Programs</p>
                          </div>
                          <div>{itemDetail?.processes?.processName_EN}</div>
                        </CCol>
                        <CCol
                          xs={2}
                          className="align-items-center"
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <div style={{ position: 'relative' }}>
                            <div className="icon-box" onClick={() => handleEdit('itemImage')}>
                              <div>
                                <i class="fa-solid fa-shirt"></i>
                              </div>
                              <div>
                                {itemDetail.item.picture && <i class="fa-solid fa-check"></i>}
                              </div>
                            </div>
                            <p style={{ textAlign: 'center', fontWeight: '600' }}>Image</p>
                          </div>
                          {itemDetail.item.picture && (
                            <>
                              <img
                                src={ImageUrl2 + itemDetail.item.picture}
                                style={{ cursor: 'pointer', width: '50%', height: '100px' }}
                                onClick={() => setVisible(!visible)}
                              // data-coreui-toggle="modal"
                              // data-coreui-target="#staticBackdrop"
                              />
                              <ImagePreview
                                visible={visible}
                                setVisible={setVisible}
                                image={ImageUrl2 + itemDetail.item.picture}
                              />
                            </>
                          )}
                        </CCol>
                        <CCol
                          xs={2}
                          className="align-items-center"
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <div style={{ position: 'relative' }}>
                            <div className="icon-box" onClick={() => handleEdit('stain')}>
                              <div>
                                <i class="fa-solid fa-shirt"></i>
                              </div>
                              <div>{itemDetail.item.stain && <i class="fa-solid fa-check"></i>}</div>
                            </div>
                            <p style={{ textAlign: 'center', fontWeight: '600' }}>Stain</p>
                          </div>
                          <div>{Array.isArray(itemDetail.item.stain) && itemDetail.item.stain.map((data, i) => {
                            return <div>
                              {data.stainName}
                              <p
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                  data.image &&
                                    data.length > 0 &&
                                    !data.image.includes('')
                                    ? setVisiblePreview(!visiblePreview)
                                    : ''
                                }
                              >
                                {data.image &&
                                  data.length > 0 &&
                                  !data.includes('') ? (
                                  <i class="fa-solid fa-images"></i>
                                ) : (
                                  ''
                                )}
                              </p>
                              <ItemImagePreview
                                visiblePreview={visiblePreview}
                                setVisiblePreview={setVisiblePreview}
                                image={data.image}
                              />
                            </div>
                          })}</div>

                        </CCol>
                      </CRow>
                      <CRow>
                        <h5>Instructions:</h5>
                        <div>{itemDetail.item.description}</div>
                      </CRow>
                      {/* <CRow className="justify-content-center mt-2">
                  {itemDetail.item.image &&
                    itemDetail.item.image.length > 0 &&
                    itemDetail.item.image.map((_img, index) => (
                      <CCol xs={2}>
                        <div
                          style={{
                            border: '1px solid black',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            boxShadow: '1px 1px 3px black',
                            border: 'none',
                          }}
                        >
                          <img src={ImageUrl2 + _img} style={{ width: '100%', height: '150px' }} />
                        </div>
                      </CCol>
                    ))}
                </CRow> */}
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          height: '72vh',
                          // overflowY: 'scroll',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <img src={Barcode} alt="barcode" style={{ maxWidth: '200px' }} />
                        <h4 className="mt-3">Scan OR code for item</h4>
                        {
                          unit === 'pcs' && Number(itemLength) === Number(unitValue) ? null : (
                            <input
                              autoFocus={true}
                              // disabled
                              // hidden
                              // style={{ border: 'none', outline: 'none', widht: '0', height: '0' }}
                              ref={inputRef}
                              name="barcodeValue"
                              id="barcodeValue"
                              value={barcodeValue}
                              onChange={(event) => setBarcodeValue(event.target.value)}
                              onKeyPress={handleKeyPress}
                            // onClick={console.log('running')}
                            />
                          )
                        }

                      </div>
                    </>
                  )}
                  {itemDetail &&
                    Object.keys(itemDetail)?.length > 0 &&
                    itemDetail?.item?.status === 'inactive' && (
                      <CRow className="justify-content-between p-3">
                        <CCol xs={3} className="justify-content-end">
                          <button
                            style={{
                              marginTop: '20px',
                              width: '100%',
                              background: '#d3dc40',
                              border: '#d3dc40',
                              padding: '10px 0',
                            }}
                            onClick={() => handleItem('remove')}
                          >
                            Draft Item
                          </button>
                        </CCol>
                        <CCol xs={2} className="justify-content-end">
                          <button
                            style={{
                              marginTop: '20px',
                              width: '100%',
                              background: '#d3dc40',
                              border: '#d3dc40',
                              padding: '10px 0',
                            }}
                            onClick={() => handleItem('add')}
                          >
                            Add Item
                          </button>
                        </CCol>
                      </CRow>
                    )}
                </CCardBody>
              </CCardGroup>
            </CCard>
          </>
        )
      }
    </div >
  )
}

export default ItemDetails
