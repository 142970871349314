import React, { useEffect, useRef, useState } from 'react'
import { Formik } from 'formik'
import { Link, useNavigate } from 'react-router-dom'
import { post, get, put } from 'src/utils/apiManager'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { CCard, CCardBody, CCardGroup, CCol, CContainer, CRow, CFormLabel, CImage } from '@coreui/react'
import { DefaultInput, DefaultTextarea } from 'src/components/common/input'
import { validateAbout } from 'src/utils/validator'
import { Ckeditor } from '../Template/Ckeditor'

function about() {
  const navigate = useNavigate()
  const formRef = useRef(null)
  const fileRef = useRef(null)
  const state = {}
  const [iD, setID] = useState('')
  const [isDisable, setIsDisable] = useState(false);
  const [invalidTitleImage, setInvalidTitleImage] = useState('')
  const [advanceLaundaryProcessImage, setAdvanceLaundaryProcessImage] = useState('')
  const [personaliseLaundaryImage, setPersonaliseLaundaryImage] = useState('')
  const [invalidmachineChemicalImage, setInvalidmachineChemicalImage] = useState('')
  const [titleImgUrl, setTitleImgUrl] = useState('')
  const [advanceLaundaryImgUrl, setAdvanceLaundaryImgUrl] = useState('')
  const [personaliseLaundaryImgUrl, setPersonaliseLaundaryImgUrl] = useState('')
  const [machineChemicalImgUrl, setMachineChemicalImgUrl] = useState('')
  const [aboutText1_EN, setAboutText1_EN] = useState([])
  const [aboutText1_TH, setAboutText1_TH] = useState([])
  const [aboutText2_EN, setAboutText2_EN] = useState([])
  const [aboutText2_TH, setAboutText2_TH] = useState([])
  const [description_EN, setdescription_EN] = useState([])
  const [description_TH, setdescription_TH] = useState([])
  const [advanceLaundaryProcessDescription_EN, setadvanceLaundaryProcessDescription_EN] = useState([])
  const [advanceLaundaryProcessDescription_TH, setadvanceLaundaryProcessDescription_TH] = useState([])
  const [personaliseLaundaryDecription_EN, setpersonaliseLaundaryDecription_EN] = useState([])
  const [personaliseLaundaryDecription_TH, setpersonaliseLaundaryDecription_TH] = useState([])
  const [machineChemicalTeachnologyDescription_EN, setmachineChemicalTeachnologyDescription_EN] = useState([])
  const [machineChemicalTeachnologyDescription_TH, setmachineChemicalTeachnologyDescription_TH] = useState([])
  const [titleImagePreview, setTitleImagePreview] = useState();
  const [advanceLaundaryImagePreview, setAdvanceLaundaryImagePreview] = useState();
  const [personaliseLaundaryImagePreview, setPersonaliseLaundaryImagePreview] = useState();
  const [invalidmachineChemicalImagePreview, setInvalidmachineChemicalImagePreview] = useState();
  useEffect(() => {
    getAboutData();
  }, [])
  const getAboutData = () => {
    get(`/admin/Aboutus/about-data`).then((response) => {
      if (response?.status == true) {
        handleSingleData(response)
      }
    });
  };
  const handleSingleData = (response) => {
    if (!response?.status) toast.success(response?.message, response?.status);
    formRef.current.setFieldValue("titleKey_EN", response?.data?.titleKey_EN);
    formRef.current.setFieldValue("titleKey_TH", response?.data?.titleKey_TH);
    formRef.current.setFieldValue("title_EN", response?.data?.title_EN);
    formRef.current.setFieldValue("title_TH", response?.data?.title_TH);
    formRef.current.setFieldValue("aboutText1_EN", response?.data?.aboutText1_EN);
    formRef.current.setFieldValue("aboutText1_TH", response?.data?.aboutText1_TH);
    formRef.current.setFieldValue("aboutText2_EN", response?.data?.aboutText2_EN);
    formRef.current.setFieldValue("aboutText2_TH", response?.data?.aboutText2_TH);
    formRef.current.setFieldValue("titleDescription_EN", response?.data?.titleDescription_EN);
    formRef.current.setFieldValue("titleDescription_TH", response?.data?.titleDescription_TH);
    formRef.current.setFieldValue("description_EN", response?.data?.description_EN);
    formRef.current.setFieldValue("description_TH", response?.data?.description_TH);
    formRef.current.setFieldValue("advanceLaundaryProcessDescription_EN", response?.data?.advanceLaundaryProcessDescription_EN);
    formRef.current.setFieldValue("advanceLaundaryProcessDescription_TH", response?.data?.advanceLaundaryProcessDescription_TH);
    formRef.current.setFieldValue("personaliseLaundaryDecription_EN", response?.data?.personaliseLaundaryDecription_EN);
    formRef.current.setFieldValue("personaliseLaundaryDecription_TH", response?.data?.personaliseLaundaryDecription_TH);
    formRef.current.setFieldValue("machineChemicalTeachnologyDescription_EN", response?.data?.machineChemicalTeachnologyDescription_EN);
    formRef.current.setFieldValue("machineChemicalTeachnologyDescription_TH", response?.data?.machineChemicalTeachnologyDescription_TH);
    if (response?.data?.titleImage) setTitleImgUrl(`${response?.imageUrl}${response?.data?.titleImage}`)
    if (response?.data?.advanceLaundaryProcessImage) setAdvanceLaundaryImgUrl(`${response?.imageUrl}${response?.data?.advanceLaundaryProcessImage}`)
    if (response?.data?.personaliseLaundaryImage) setPersonaliseLaundaryImgUrl(`${response?.imageUrl}${response?.data?.personaliseLaundaryImage}`)
    if (response?.data?.machineChemicalTeachnologyImage) setMachineChemicalImgUrl(`${response?.imageUrl}${response?.data?.machineChemicalTeachnologyImage}`)
    if (response?.data?._id) setID(`${response?.data?._id}`)
  }
  const submit = async (values) => {
    let data = {
      id: iD,
      titleKey_EN: values?.titleKey_EN,
      titleKey_TH: values?.titleKey_TH,
      title_EN: values?.title_EN,
      title_TH: values?.title_TH,
      aboutText1_EN: values?.aboutText1_EN,
      aboutText1_TH: values?.aboutText1_TH,
      aboutText2_EN: values?.aboutText2_EN,
      aboutText2_TH: values?.aboutText2_TH,
      titleImage: values?.titleImage,
      titleDescription_EN: values?.titleDescription_EN,
      titleDescription_TH: values?.titleDescription_TH,
      description_EN: values?.description_EN,
      description_TH: values?.description_TH,
      advanceLaundaryProcessDescription_EN: values?.advanceLaundaryProcessDescription_EN,
      advanceLaundaryProcessDescription_TH: values?.advanceLaundaryProcessDescription_TH,
      advanceLaundaryProcessImage: values?.advanceLaundaryProcessImage,
      personaliseLaundaryDecription_EN: values?.personaliseLaundaryDecription_EN,
      personaliseLaundaryDecription_TH: values?.personaliseLaundaryDecription_TH,
      personaliseLaundaryImage: values?.personaliseLaundaryImage,
      machineChemicalTeachnologyDescription_EN: values?.machineChemicalTeachnologyDescription_EN,
      machineChemicalTeachnologyDescription_TH: values?.machineChemicalTeachnologyDescription_TH,
      machineChemicalTeachnologyImage: values?.machineChemicalTeachnologyImage,
    }
    if (iD) {
      put(`admin/AboutUs/update-about`, data, 1).then((response) => handleResponse(response))
    } else {
      post(`admin/AboutUs/add-about`, data, 1).then((response) => handleResponse(response))
    }
  }
  const handleResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      getAboutData();
    }
  }
  return (
    <CContainer >
      <CRow>
        <CCol>
          <CCardGroup>
            <CCard className="p-4">
              <CCol xs={12}>
                <CCardGroup>
                  <CCardBody>
                    <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                    <h1>AboutUs</h1>
                    <Formik
                      initialValues={{
                        titleKey_EN: '',
                        titleKey_TH: '',
                        title_EN: '',
                        title_TH: '',
                        aboutText1_EN: '',
                        aboutText1_TH: '',
                        aboutText2_EN: '',
                        aboutText2_TH: '',
                        titleImage: '',
                        titleDescription_EN: '',
                        titleDescription_TH: '',
                        description_EN: '',
                        description_TH: '',
                        advanceLaundaryProcessDescription_EN: '',
                        advanceLaundaryProcessDescription_TH: '',
                        advanceLaundaryProcessImage: '',
                        personaliseLaundaryDecription_EN: '',
                        personaliseLaundaryDecription_TH: '',
                        personaliseLaundaryImage: '',
                        machineChemicalTeachnologyDescription_EN: '',
                        machineChemicalTeachnologyDescription_TH: '',
                        machineChemicalTeachnologyImage: '',

                      }}
                      validationSchema={validateAbout}
                      onSubmit={(values, { setSubmitting }) => {
                        submit(values)
                        setSubmitting(false)
                      }}
                      innerRef={formRef}
                    >
                      {(props) => (
                        <form onSubmit={props?.handleSubmit}>
                          <CRow>
                            <CCol md="6">
                              <CFormLabel>TitleKey (EN)</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>*</span>
                              <DefaultInput
                                type={'text'}
                                placeholder={'TitleKey (EN)'}
                                // name={'TitleKey (EN)'}
                                id={'titleKey_EN'}
                                value={props?.values?.titleKey_EN}
                                onChange={props?.handleChange('titleKey_EN')}
                                onKeyUp={() => {
                                  if (state?.titleKey_EN == props?.values?.titleKey_EN) setIsDisable(false)
                                  else setIsDisable(true)
                                }}
                                onBlur={() => props.setFieldTouched('titleKey_EN', true)}
                                error={props?.touched?.titleKey_EN && props?.errors?.titleKey_EN}
                              />
                            </CCol>
                            <CCol className="mb-4">
                              <CFormLabel>TitleKey (TH)</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>*</span>
                              <DefaultInput
                                type={'text'}
                                placeholder={'TitleKey TH'}
                                // name={'TitleKey (TH)'}
                                id={'titleKey_TH'}
                                value={props?.values?.titleKey_TH}
                                onChange={props.handleChange('titleKey_TH')}
                                onKeyUp={() => {
                                  if (state?.titleKey_TH == props?.values?.titleKey_TH) setIsDisable(false)
                                  else setIsDisable(true)
                                }}
                                onBlur={() => props?.setFieldTouched('titleKey_TH', true)}
                                error={props?.touched?.titleKey_TH && props?.errors?.titleKey_TH}
                              />
                            </CCol>
                            <CCol md="6">
                              <CFormLabel>Title (EN)</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>*</span>
                              <DefaultInput
                                type={'text'}
                                placeholder={'Title (EN)'}
                                // name={'Title EN'}
                                id={'title_EN'}
                                value={props?.values?.title_EN}
                                onChange={props?.handleChange('title_EN')}
                                onKeyUp={() => {
                                  if (state?.title_EN == props?.values?.title_EN) setIsDisable(false)
                                  else setIsDisable(true)
                                }}
                                onBlur={() => props?.setFieldTouched('title_EN', true)}
                                error={props?.touched?.title_EN && props?.errors?.title_EN}
                              />
                            </CCol>
                            <CCol className="mb-4">
                              <CFormLabel>Title (TH)</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>*</span>
                              <DefaultInput
                                type={'text'}
                                placeholder={'Title (TH)'}
                                // name={'Title TH'}
                                id={'title_TH'}
                                value={props?.values?.title_TH}
                                onChange={props.handleChange('title_TH')}
                                onKeyUp={() => {
                                  if (state?.title_TH == props?.values?.title_TH) setIsDisable(false)
                                  else setIsDisable(true)
                                }}
                                onBlur={() => props?.setFieldTouched('title_TH', true)}
                                error={props?.touched?.title_TH && props?.errors?.title_TH}
                              />
                            </CCol>
                            <CCol md="6">
                              <CFormLabel>About Text1 Description (EN)</CFormLabel>
                              <Ckeditor
                                setAboutText1_EN={setAboutText1_EN}
                                aboutText1_EN={aboutText1_EN}
                                value={props?.values?.aboutText1_EN}
                                onChange={(val) => {
                                  setAboutText1_EN(val)
                                  if (state?.aboutText1_EN == val) setIsDisable(false)
                                  else setIsDisable(true)
                                  props?.setFieldValue('aboutText1_EN', val)
                                }}
                                onBlur={() => props?.setFieldTouched('aboutText1_EN', true)}
                                error={props?.touched?.aboutText1_EN && props?.errors?.aboutText1_EN}
                              />
                            </CCol>
                            <CCol md="6" className="mb-4">
                              <CFormLabel>About Text1 Description (TH)</CFormLabel>
                              <Ckeditor
                                setAboutText1_TH={setAboutText1_TH}
                                aboutText1_TH={aboutText1_TH}
                                value={props?.values?.aboutText1_TH}
                                onChange={(val) => {
                                  setAboutText1_TH(val)
                                  if (state?.aboutText1_TH == val) setIsDisable(false)
                                  else setIsDisable(true)
                                  props.setFieldValue('aboutText1_TH', val)
                                }}
                                onBlur={() => props?.setFieldTouched('aboutText1_TH', true)}
                                error={props?.touched?.aboutText1_TH && props?.errors?.aboutText1_TH}
                              />
                            </CCol>
                            <CCol md="6">
                              <CFormLabel>About Text2 Description (EN)</CFormLabel>
                              <Ckeditor
                                setAboutText2_EN={setAboutText2_EN}
                                aboutText1_EN={aboutText2_EN}
                                value={props?.values?.aboutText2_EN}
                                onChange={(val) => {
                                  setAboutText2_EN(val)
                                  if (state?.aboutText2_EN == val) setIsDisable(false)
                                  else setIsDisable(true)
                                  props?.setFieldValue('aboutText2_EN', val)
                                }}
                                onBlur={() => props?.setFieldTouched('aboutText2_EN', true)}
                                error={props?.touched?.aboutText2_EN && props?.errors?.aboutText2_EN}
                              />
                            </CCol>
                            <CCol md="6" className="mb-4">
                              <CFormLabel>About Text2 Description (TH)</CFormLabel>
                              <Ckeditor
                                setAboutText2_TH={setAboutText2_TH}
                                aboutText2_TH={aboutText2_TH}
                                value={props?.values?.aboutText2_TH}
                                onChange={(val) => {
                                  setAboutText2_TH(val)
                                  if (state?.aboutText2_TH == val) setIsDisable(false)
                                  else setIsDisable(true)
                                  props?.setFieldValue('aboutText2_TH', val)
                                }}
                                onBlur={() => props?.setFieldTouched('aboutText2_TH', true)}
                                error={props?.touched?.aboutText2_TH && props?.errors?.aboutText2_TH}
                              />
                            </CCol>
                            <CCol md="6">
                              <DefaultTextarea
                                placeholder={"Some Text"}
                                name={"Title Description (EN)"}
                                id={"Title Description EN"}
                                value={props?.values?.titleDescription_EN}
                                onChange={props?.handleChange("titleDescription_EN")}
                                onKeyUp={() => {
                                  if (state?.titleDescription_EN == props?.values?.titleDescription_EN) setIsDisable(false)
                                  else setIsDisable(true)
                                }}
                                onBlur={() =>
                                  props?.setFieldTouched("titleDescription_EN", true)
                                }
                                error={props?.touched?.titleDescription_EN && props?.errors?.titleDescription_EN}
                              />
                            </CCol>
                            <CCol md="6" className="mb-4">
                              <DefaultTextarea
                                placeholder={"Some Text"}
                                name={"Title Description (TH)"}
                                id={"Title Description TH"}
                                value={props?.values?.titleDescription_TH}
                                onChange={props?.handleChange("titleDescription_TH")}
                                onKeyUp={() => {
                                  if (state?.titleDescription_TH == props?.values?.titleDescription_TH) setIsDisable(false)
                                  else setIsDisable(true)
                                }}
                                onBlur={() =>
                                  props?.setFieldTouched("titleDescription_TH", true)
                                }
                                error={props?.touched?.titleDescription_TH && props?.errors?.titleDescription_TH}
                              />
                            </CCol>
                            <CCol md="6" className="mb-4">
                              <CFormLabel> Description (EN)</CFormLabel>
                              <Ckeditor
                                setdescription_EN={setdescription_EN}
                                description_EN={description_EN}
                                value={props?.values?.description_EN}
                                onChange={(val) => {
                                  setdescription_EN(val)
                                  if (state?.description_EN == val) setIsDisable(false)
                                  else setIsDisable(true)
                                  props?.setFieldValue('description_EN', val)
                                }}
                                onBlur={() => props?.setFieldTouched('description_EN', true)}
                                error={props?.touched?.description_EN && props?.errors?.description_EN}
                              />
                            </CCol>
                            <CCol md="6" className="mb-4">
                              <CFormLabel>Description (TH)</CFormLabel>
                              <Ckeditor
                                setdescription_TH={setdescription_TH}
                                description_TH={description_TH}
                                value={props?.values?.description_TH}
                                onChange={(val) => {
                                  setdescription_TH(val)
                                  if (state?.description_TH == val) setIsDisable(false)
                                  else setIsDisable(true)
                                  props?.setFieldValue('description_TH', val)
                                }}
                                onBlur={() => props?.setFieldTouched('description_TH', true)}
                                error={props?.touched?.description_TH && props?.errors?.description_TH}
                              />
                            </CCol>
                            <CCol md="6" className="mb-4">
                              <CFormLabel>Title Image</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>*</span>
                              <DefaultInput
                                type={'file'}
                                placeholder={'titleImage'}
                                // name={'Title Image'}
                                accept="image/*"
                                refr={fileRef}
                                onChange={(e) => {
                                  const imageFile = e?.target?.files[0];
                                  if (titleImgUrl == imageFile?.name) setIsDisable(false)
                                  else setIsDisable(true)
                                  if (!imageFile?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
                                    setInvalidTitleImage("Please select valid image")
                                  }
                                  else {
                                    setInvalidTitleImage('')
                                  }
                                  //for image preview
                                  const reader = new FileReader();
                                  reader?.addEventListener("load", () => setTitleImagePreview(reader?.result));
                                  reader?.readAsDataURL(e?.target?.files[0]);
                                  //for image preview
                                  props?.setFieldValue('titleImage', e?.target?.files[0])
                                }}
                                onBlur={() => props?.setFieldTouched('titleImage', true)}
                                error={props?.touched?.titleImage && props?.errors?.titleImage || invalidTitleImage}
                              />
                              {/* for image preview */}
                              {titleImagePreview?.length > 0 ?
                                <>
                                  <CCol>
                                  <a target='_blank' href={`${titleImagePreview}`}>
                                    <CImage
                                      rounded
                                      thumbnail
                                      className="mt-2"
                                      src={`${titleImagePreview}`}
                                      style={{
                                        maxHeight: '80px',
                                        maxWidth: '80px',
                                        alignItems: 'center',
                                      }}
                                    />
                                    </a>
                                  </CCol>
                                </>
                                : ''}
                              {/* for image preview */}
                              {titleImgUrl && (
                                <a target='_blank' href={`${titleImgUrl}`}>
                                <CImage
                                  rounded
                                  thumbnail
                                  className='mt-2'
                                  src={`${titleImgUrl}`}
                                  style={{
                                    maxHeight: "80px",
                                    maxWidth: "80px",
                                    alignItems: "center",
                                  }}
                                />
                                </a>
                              )}
                            </CCol>
                            <CCol md="6" className="mb-4">
                            <CFormLabel>=Advance Laundary Process Image</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>*</span>
                              <DefaultInput
                                type={'file'}
                                placeholder={'advanceLaundaryProcessImage'}
                                // name={'Advance Laundary Process Image'}
                                accept="image/*"
                                refr={fileRef}
                                onChange={(e) => {
                                  const imageFile = e?.target?.files[0];
                                  if (advanceLaundaryImgUrl == imageFile?.name) setIsDisable(false)
                                  else setIsDisable(true)
                                  if (!imageFile?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
                                    setAdvanceLaundaryProcessImage("Please select valid image")
                                  }
                                  else {
                                    setAdvanceLaundaryProcessImage('')
                                  }
                                  //for image preview
                                  const reader = new FileReader();
                                  reader?.addEventListener("load", () => setAdvanceLaundaryImagePreview(reader?.result));
                                  reader?.readAsDataURL(e?.target?.files[0]);
                                  //for image preview
                                  props?.setFieldValue('advanceLaundaryProcessImage', e?.target?.files[0])
                                }}
                                onBlur={() => props.setFieldTouched('advanceLaundaryProcessImage', true)}
                                error={props?.touched?.advanceLaundaryProcessImage && props?.errors?.advanceLaundaryProcessImage || advanceLaundaryProcessImage}
                              />
                              {/* for image preview */}
                              {advanceLaundaryImagePreview?.length > 0 ?
                                <>
                                  <CCol>
                                  <a target='_blank' href={`${advanceLaundaryImagePreview}`}>
                                    <CImage
                                      rounded
                                      thumbnail
                                      className="mt-2"
                                      src={`${advanceLaundaryImagePreview}`}
                                      style={{
                                        maxHeight: '80px',
                                        maxWidth: '80px',
                                        alignItems: 'center',
                                      }}
                                    />
                                    </a>
                                  </CCol>
                                </>
                                : ''}
                              {/* for image preview */}
                              {advanceLaundaryImgUrl && (
                                <a target='_blank' href={`${advanceLaundaryImgUrl}`}>
                                <CImage
                                  rounded
                                  thumbnail
                                  className='mt-2'
                                  src={`${advanceLaundaryImgUrl}`}
                                  style={{
                                    maxHeight: "80px",
                                    maxWidth: "80px",
                                    alignItems: "center",
                                  }}
                                />
                                </a>
                              )}
                            </CCol>
                            <CCol md="6" className="mb-4">
                              <CFormLabel>Advance Laundry Process (EN)</CFormLabel>
                              <Ckeditor
                                setadvanceLaundaryProcessDescription_EN={setadvanceLaundaryProcessDescription_EN}
                                advanceLaundaryProcessDescription_EN={advanceLaundaryProcessDescription_EN}
                                value={props?.values?.advanceLaundaryProcessDescription_EN}
                                onChange={(val) => {
                                  setadvanceLaundaryProcessDescription_EN(val)
                                  if (state?.advanceLaundaryProcessDescription_EN == val) setIsDisable(false)
                                  else setIsDisable(true)
                                  props?.setFieldValue('advanceLaundaryProcessDescription_EN', val)
                                }}
                                onBlur={() => props?.setFieldTouched('advanceLaundaryProcessDescription_EN', true)}
                                error={props?.touched?.advanceLaundaryProcessDescription_EN && props?.errors?.advanceLaundaryProcessDescription_EN}
                              />
                            </CCol>
                            <CCol md="6" className="mb-4">
                              <CFormLabel>Advance Laundry Process (TH)</CFormLabel>
                              <Ckeditor
                                setadvanceLaundaryProcessDescription_TH={setadvanceLaundaryProcessDescription_TH}
                                advanceLaundaryProcessDescription_TH={advanceLaundaryProcessDescription_TH}
                                value={props?.values?.advanceLaundaryProcessDescription_TH}
                                onChange={(val) => {
                                  setadvanceLaundaryProcessDescription_TH(val)
                                  if (state?.advanceLaundaryProcessDescription_TH == val) setIsDisable(false)
                                  else setIsDisable(true)
                                  props?.setFieldValue('advanceLaundaryProcessDescription_TH', val)
                                }}
                                onBlur={() => props?.setFieldTouched('advanceLaundaryProcessDescription_TH', true)}
                                error={props?.touched?.advanceLaundaryProcessDescription_TH && props?.errors?.advanceLaundaryProcessDescription_TH}
                              />
                            </CCol>
                            <CCol md="6" className="mb-4">
                              <CFormLabel>Personalize Laundry (EN)</CFormLabel>
                              <Ckeditor
                                setpersonaliseLaundaryDecription_EN={setpersonaliseLaundaryDecription_EN}
                                personaliseLaundaryDecription_EN={personaliseLaundaryDecription_EN}
                                value={props?.values?.personaliseLaundaryDecription_EN}
                                onChange={(val) => {
                                  setpersonaliseLaundaryDecription_EN(val)
                                  if (state?.personaliseLaundaryDecription_EN == val) setIsDisable(false)
                                  else setIsDisable(true)
                                  props?.setFieldValue('personaliseLaundaryDecription_EN', val)
                                }}
                                onBlur={() => props?.setFieldTouched('personaliseLaundaryDecription_EN', true)}
                                error={props?.touched?.personaliseLaundaryDecription_EN && props?.errors?.personaliseLaundaryDecription_EN}
                              />
                            </CCol>
                            <CCol md="6" className="mb-4">
                              <CFormLabel>Personalize Laundry (TH)</CFormLabel>
                              <Ckeditor
                                setpersonaliseLaundaryDecription_TH={setpersonaliseLaundaryDecription_TH}
                                personaliseLaundaryDecription_TH={personaliseLaundaryDecription_TH}
                                value={props?.values?.personaliseLaundaryDecription_TH}
                                onChange={(val) => {
                                  setpersonaliseLaundaryDecription_TH(val)
                                  if (state?.personaliseLaundaryDecription_TH == val) setIsDisable(false)
                                  else setIsDisable(true)
                                  props?.setFieldValue('personaliseLaundaryDecription_TH', val)
                                }}
                                onBlur={() => props?.setFieldTouched('personaliseLaundaryDecription_TH', true)}
                                error={props?.touched?.personaliseLaundaryDecription_TH && props?.errors?.personaliseLaundaryDecription_TH}
                              />
                            </CCol>
                            <CCol md="6">
                              <CFormLabel>Personalize Laundary Image</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>*</span>
                              <DefaultInput
                                type={'file'}
                                placeholder={'personaliseLaundaryImage'}
                                // name={'Personalise Laundary Image'}
                                accept="image/*"
                                refr={fileRef}
                                onChange={(e) => {
                                  const imageFile = e?.target?.files[0];
                                  if (personaliseLaundaryImgUrl == imageFile?.name) setIsDisable(false)
                                  else setIsDisable(true)
                                  if (!imageFile?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
                                    setPersonaliseLaundaryImage("Please select valid image")
                                  }
                                  else {
                                    setPersonaliseLaundaryImage('')
                                  }
                                  //for image preview
                                  const reader = new FileReader();
                                  reader?.addEventListener("load", () => setPersonaliseLaundaryImagePreview(reader?.result));
                                  reader?.readAsDataURL(e?.target?.files[0]);
                                  //for image preview
                                  props?.setFieldValue('personaliseLaundaryImage', e?.target?.files[0])
                                }}
                                onBlur={() => props?.setFieldTouched('personaliseLaundaryImage', true)}
                                error={props?.touched?.personaliseLaundaryImage && props?.errors?.personaliseLaundaryImage || personaliseLaundaryImage}
                              />
                              {/* for image preview */}
                              {personaliseLaundaryImagePreview?.length > 0 ?
                                <>
                                  <CCol>
                                  <a target='_blank' href={`${personaliseLaundaryImagePreview}`}>
                                    <CImage
                                      rounded
                                      thumbnail
                                      className="mt-2"
                                      src={`${personaliseLaundaryImagePreview}`}
                                      style={{
                                        maxHeight: '80px',
                                        maxWidth: '80px',
                                        alignItems: 'center',
                                      }}
                                    />
                                    </a>
                                  </CCol>
                                </>
                                : ''}
                              {/* for image preview */}
                              {personaliseLaundaryImgUrl && (
                                <a target='_blank' href={`${personaliseLaundaryImgUrl}`}>
                                <CImage
                                  rounded
                                  thumbnail
                                  className='mt-2'
                                  src={`${personaliseLaundaryImgUrl}`}
                                  style={{
                                    maxHeight: "80px",
                                    maxWidth: "80px",
                                    alignItems: "center",
                                  }}
                                />
                                </a>
                              )}
                            </CCol>
                            <CCol md="6" className="mb-4">
                            <CFormLabel>Machine Chemical Technology Image</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>*</span>
                              <DefaultInput
                                type={'file'}
                                placeholder={'machineChemicalTeachnologyImage'}
                                // name={'Machine Chemical Teachnology Image'}
                                accept="image/*"
                                refr={fileRef}
                                onChange={(e) => {
                                  const imageFile = e?.target?.files[0];
                                  if (machineChemicalImgUrl == imageFile?.name) setIsDisable(false)
                                  else setIsDisable(true)
                                  if (!imageFile?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
                                    setInvalidmachineChemicalImage("Please select valid image")
                                  }
                                  else {
                                    setInvalidmachineChemicalImage('')
                                  }
                                  //for image preview
                                  const reader = new FileReader();
                                  reader?.addEventListener("load", () => setInvalidmachineChemicalImagePreview(reader?.result));
                                  reader?.readAsDataURL(e?.target?.files[0]);
                                  //for image preview
                                  props?.setFieldValue('machineChemicalTeachnologyImage', e.target.files[0])
                                }}
                                onBlur={() => props?.setFieldTouched('machineChemicalTeachnologyImage', true)}
                                error={props?.touched?.machineChemicalTeachnologyImage && props?.errors?.machineChemicalTeachnologyImage || invalidmachineChemicalImage}
                              />
                              {/* for image preview */}
                              {invalidmachineChemicalImagePreview?.length > 0 ?
                                <>
                                  <CCol>
                                  <a target='_blank' href={`${invalidmachineChemicalImagePreview}`}>
                                    <CImage
                                      rounded
                                      thumbnail
                                      className="mt-2"
                                      src={`${invalidmachineChemicalImagePreview}`}
                                      style={{
                                        maxHeight: '80px',
                                        maxWidth: '80px',
                                        alignItems: 'center',
                                      }}
                                    />
                                    </a>
                                  </CCol>
                                </>
                                : ''}
                              {/* for image preview */}
                              {machineChemicalImgUrl && (
                                <a target='_blank' href={`${machineChemicalImgUrl}`}>
                                <CImage
                                  rounded
                                  thumbnail
                                  className='mt-2'
                                  src={`${machineChemicalImgUrl}`}
                                  style={{
                                    maxHeight: "80px",
                                    maxWidth: "80px",
                                    alignItems: "center",
                                  }}
                                />
                                </a>
                              )}
                            </CCol>
                            <CCol md="6" className="mb-4">
                              <CFormLabel> Machine And Chemical Technology (EN)</CFormLabel>
                              <Ckeditor
                                setmachineChemicalTeachnologyDescription_EN={setmachineChemicalTeachnologyDescription_EN}
                                machineChemicalTeachnologyDescription_EN={machineChemicalTeachnologyDescription_EN}
                                value={props?.values?.machineChemicalTeachnologyDescription_EN}
                                onChange={(val) => {
                                  setmachineChemicalTeachnologyDescription_EN(val)
                                  if (state?.machineChemicalTeachnologyDescription_EN == val) setIsDisable(false)
                                  else setIsDisable(true)
                                  props?.setFieldValue('machineChemicalTeachnologyDescription_EN', val)
                                }}
                                onBlur={() => props?.setFieldTouched('machineChemicalTeachnologyDescription_EN', true)}
                                error={props?.touched?.machineChemicalTeachnologyDescription_EN && props?.errors?.machineChemicalTeachnologyDescription_EN}
                              />
                            </CCol>
                            <CCol md="6" className="mb-4">
                              <CFormLabel>Machine And Chemical Technology (TH)</CFormLabel>
                              <Ckeditor
                                setmachineChemicalTeachnologyDescription_TH={setmachineChemicalTeachnologyDescription_TH}
                                machineChemicalTeachnologyDescription_TH={machineChemicalTeachnologyDescription_TH}
                                value={props?.values?.machineChemicalTeachnologyDescription_TH}
                                onChange={(val) => {
                                  setmachineChemicalTeachnologyDescription_TH(val)
                                  if (state?.machineChemicalTeachnologyDescription_TH == val) setIsDisable(false)
                                  else setIsDisable(true)
                                  props?.setFieldValue('machineChemicalTeachnologyDescription_TH', val)
                                }}
                                onBlur={() => props?.setFieldTouched('machineChemicalTeachnologyDescription_TH', true)}
                                error={props?.touched?.machineChemicalTeachnologyDescription_TH && props?.errors?.machineChemicalTeachnologyDescription_TH}
                              />
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol md="2">
                              <button type="submit" className={'btn btn-success mt-2'} disabled={!isDisable || props?.isSubmitting}>Submit</button>
                            </CCol>
                            <CCol md="2">
                              <button
                                type="button"
                                className={'btn btn-warning mt-2'}
                                onClick={() => navigate('/admin/dashboard')}
                              >
                                Cancel
                              </button>
                            </CCol>
                            <CCol md="3"></CCol>
                          </CRow>
                        </form>
                      )}
                    </Formik>
                  </CCardBody>
                </CCardGroup>
              </CCol>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  )
}

export default about