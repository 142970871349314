import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { post, get, deleteApi } from '../../utils/apiManager'
import CIcon from '@coreui/icons-react'
import { cilPen, cilPlus, cilTrash } from '@coreui/icons'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CCardSubtitle,
  CTable,
  CRow,
  CImage,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CButton,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import { DefaultInputWithIcon } from 'src/components/common/input'
import Pagination from 'react-js-pagination'
import { FaRegEye, FaEdit, FaTrash } from 'react-icons/fa'

function index() {
  const navigate = useNavigate();
  const [unitData, setUnitData] = useState([]);
  const [totalPages, settotalPages] = useState(0);
  const [searchItem, setsearchItem] = useState("");
  const [pageData, setpageData] = useState({
    page: 1,
    perPage: 10,
    searchItem: "",
  });
  useEffect(() => {
    getData();
  }, [pageData]);

  const getData = () => {
    get(
      `/admin/unit/get-unit?page=${pageData?.page || 1}&perPage=${pageData?.perPage || 10
      }&searchItem=${pageData?.searchItem || ""}`
    ).then((data) => handleResponse(data));
  };
  const handleResponse = (data) => {
    if (!data?.status) toast.success(data?.message, data?.status);
    else {
      setUnitData([...data?.data]);
      settotalPages(data?.pages);
    }
  };
  const changePage = (index) => {
    setpageData({
      ...pageData,
      page: index,
    });
  };
  const statusChange = (status, id) => {
    let default_status = ''
    if (status == 0) default_status = "Active"
    else default_status = "Inactive"
    if (confirm(`Are you sure you want to ${default_status} the units`)) {
      let formData = {
        _id: id,
      };
      post(`${status ? "/admin/unit/inactive" : "/admin/unit/active"}`, formData).then(
        (data) => {
          handleStatusChange(data);
        }
      );
    }
  };
  const handleStatusChange = (data) => {
    toast.success(data?.message, data?.status);
    getData();
  };
  const onClickUserEdit = async (value) => {
    navigate('/admin/unit/edit', { state: value })
  }

  const onSearchCLick = () => {
    setpageData({
      ...pageData,
      searchItem: searchItem,
    });
  };
  return (
    <>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <CCardSubtitle as="h4">Units</CCardSubtitle>
                  <CRow>
                    <CCol md="8">
                      <DefaultInputWithIcon
                        type={'text'}
                        placeholder="Search"
                        customType={'search'}
                        value={searchItem}
                        onClick={onSearchCLick}
                        onChange={(e) => {
                          onSearchCLick()
                          setsearchItem(e?.target?.value)
                        }}
                      />
                    </CCol>
                    <CCol md="4">
                      <Link to={{ pathname: '/admin/unit/add' }} className="btn btn-dark">
                        <CIcon icon={cilPlus} />
                        Add
                      </Link>
                    </CCol>
                  </CRow>
                </CHeader>

                <CCardBody>
                  <CTable bordered hover responsive>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell>S. No</CTableHeaderCell>
                        <CTableHeaderCell>Title (EN)</CTableHeaderCell>
                        <CTableHeaderCell>Title (TH)</CTableHeaderCell>
                        <CTableHeaderCell>Status</CTableHeaderCell>
                        <CTableHeaderCell>Action</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {unitData?.map((ele, index) => {
                        // console.log(ele)
                        return (
                          <CTableRow key={index}>
                            <CTableDataCell>{pageData?.perPage * (pageData?.page - 1) + index + 1}</CTableDataCell>
                            <CTableDataCell >{ele?.title_EN}</CTableDataCell>
                            <CTableDataCell >{ele?.title_TH}</CTableDataCell>
                            <CTableDataCell>
                              <CButton
                                size="sm"
                                outline={ele?.status ? "1" : "0"}
                                style={{ backgroundColor: ele?.status ? '#00796B' : '#D32F2F', borderColor: ele?.status ? '#00796B' : '#D32F2F' }}
                                onClick={() =>
                                  statusChange(ele?.status, ele?._id)
                                }
                              >
                                {ele?.status ? "Active" : "Inactive"}
                              </CButton>
                            </CTableDataCell>
                            <CTableDataCell>
                              <span className="mx-2" onClick={() => onClickUserEdit(ele)} style={{ fontSize: '20px', color: '#d3dc40', cursor: 'pointer' }}><FaEdit /></span>
                              {/* <span onClick={() => deleteData(ele?._id)} className="text-danger"style={{padding: '5px',cursor:'pointer'}}><FaTrash/></span> */}
                            </CTableDataCell>
                          </CTableRow>
                        );
                      })}
                    </CTableBody>
                  </CTable>
                  {!unitData?.length && (
                    <>
                      <h1>No Data</h1>
                    </>
                  )}
                  {unitData?.length ? <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={pageData?.page}
                    itemsCountPerPage={pageData?.perPage}
                    totalItemsCount={totalPages * 10}
                    pageRangeDisplayed={5}
                    onChange={changePage}
                  /> : ''}
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default index