import { CButton, CCard, CCardBody, CCardGroup, CCardSubtitle, CCol, CContainer, CHeader, CRow } from '@coreui/react'
import { get } from 'src/utils/apiManager'
import React, { useEffect, useRef, useState } from 'react'
import { FaArrowLeft } from 'react-icons/fa';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import ReactToPrint from 'react-to-print';

function printqr() {
    const { state } = useLocation()
    const [logo, setLogo] = useState()
    const printRef = useRef()
    const navigate = useNavigate();
    const getLogo = () => {
        get(`/admin/global/global-setting`).then((response) => {
            if (response?.status == true) {
                if (response?.data?.logoImage) setLogo(`${response?.imageUrl}${response?.data?.logoImage}`)
            }
        })
    }
    useEffect(() => {
        getLogo()
    }, [])
    return (
        <>
            <CContainer fluid >
                <CRow >
                    <CCol>
                        <CCardGroup>
                            <CCard className="p-4" >
                                <CHeader>
                                    <CCardSubtitle as="h4">{' '}
                                        <Link style={{ color: '#000' }}
                                        // onClick={() => navigate(-1)}
                                        // to={{ pathname: `/admin/QR-code-genration` }}
                                        >
                                            <FaArrowLeft onClick={() => navigate(-1)} />
                                        </Link>
                                        <span> QR Code For Attach Order</span>
                                    </CCardSubtitle>
                                    <CRow>
                                        <CCol md="8">
                                        </CCol>
                                    </CRow>
                                </CHeader>
                                <div ref={printRef}>
                                    <CCardBody >
                                        <center>
                                            <h1 style={{ marginTop: '50px' }}><img src={logo} alt="logo" />{' '} Cue Laundary</h1>
                                            <img src={state?.QrImage} width='400px' height='400px' />
                                            <p><b>Bulk Order QR:{state?._id}</b></p>
                                            <br />

                                        </center>
                                    </CCardBody>
                                </div>

                                <center>
                                    <CRow>
                                        <CCol>
                                            <ReactToPrint
                                                trigger={() => <button type='button' className='btn btn-primary btn-m'>print</button>}
                                                content={() => printRef?.current}
                                            />
                                        </CCol>
                                    </CRow>
                                </center>
                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </>
    )
}

export default printqr