import React from 'react'

// const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const Colors = React.lazy(() => import('./views/theme/colors/Colors'))
const Typography = React.lazy(() => import('./views/theme/typography/Typography'))

// Base
const Accordion = React.lazy(() => import('./views/base/accordion/Accordion'))
const Breadcrumbs = React.lazy(() => import('./views/base/breadcrumbs/Breadcrumbs'))
const Cards = React.lazy(() => import('./views/base/cards/Cards'))
const Carousels = React.lazy(() => import('./views/base/carousels/Carousels'))
const Collapses = React.lazy(() => import('./views/base/collapses/Collapses'))
const ListGroups = React.lazy(() => import('./views/base/list-groups/ListGroups'))
const Navs = React.lazy(() => import('./views/base/navs/Navs'))
const Paginations = React.lazy(() => import('./views/base/paginations/Paginations'))
const Placeholders = React.lazy(() => import('./views/base/placeholders/Placeholders'))
const Popovers = React.lazy(() => import('./views/base/popovers/Popovers'))
const Progress = React.lazy(() => import('./views/base/progress/Progress'))
const Spinners = React.lazy(() => import('./views/base/spinners/Spinners'))
const Tables = React.lazy(() => import('./views/base/tables/Tables'))
const Tooltips = React.lazy(() => import('./views/base/tooltips/Tooltips'))

// Buttons
const Buttons = React.lazy(() => import('./views/buttons/buttons/Buttons'))
const ButtonGroups = React.lazy(() => import('./views/buttons/button-groups/ButtonGroups'))
const Dropdowns = React.lazy(() => import('./views/buttons/dropdowns/Dropdowns'))

//Forms
const ChecksRadios = React.lazy(() => import('./views/forms/checks-radios/ChecksRadios'))
const FloatingLabels = React.lazy(() => import('./views/forms/floating-labels/FloatingLabels'))
const FormControl = React.lazy(() => import('./views/forms/form-control/FormControl'))
const InputGroup = React.lazy(() => import('./views/forms/input-group/InputGroup'))
const Layout = React.lazy(() => import('./views/forms/layout/Layout'))
const Range = React.lazy(() => import('./views/forms/range/Range'))
const Select = React.lazy(() => import('./views/forms/select/Select'))
const Validation = React.lazy(() => import('./views/forms/validation/Validation'))

const Charts = React.lazy(() => import('./views/charts/Charts'))

// Icons
const CoreUIIcons = React.lazy(() => import('./views/icons/coreui-icons/CoreUIIcons'))
const Flags = React.lazy(() => import('./views/icons/flags/Flags'))
const Brands = React.lazy(() => import('./views/icons/brands/Brands'))

// Notifications
const Alerts = React.lazy(() => import('./views/notifications/alerts/Alerts'))
const Badges = React.lazy(() => import('./views/notifications/badges/Badges'))
const Modals = React.lazy(() => import('./views/notifications/modals/Modals'))
const Toasts = React.lazy(() => import('./views/notifications/toasts/Toasts'))

const Widgets = React.lazy(() => import('./views/widgets/Widgets'))

// Auth
import Login from './views/pages/auth/Login'
import ForgotPassword from './views/pages/auth/ForgotPassword'
import ResetPassword from './views/pages/auth/ResetPassword'
// const Login = React.lazy(()=>{ import ('./views/pages/auth/Login')})
// const ForgotPassword = React.lazy(()=> import ('./views/pages/auth/ForgotPassword'))
// const ResetPassword = React.lazy(()=> import ('./views/pages/auth/ResetPassword'))

// MainRoutes
import Dashboard from './views/dashboard/Dashboard'
import Profile from './views/pages/profile/Profile'
import ChangePassword from './views/pages/profile/ChangePassword'
import userIndex from './views/user/userIndex'
import addUser from './views/user/addUser'
import editUser from './views/user/editUser'
import UserDetail from './views/user/UserDetail'
import TempList from './views/Template/TempList'
import DescriptionList from './views/DescriptionList/DescriptionList'
import AddDescription from './views/DescriptionList/AddDescription'
import EditTemplate from './views/Template/EditTemplate'
import { Ckeditor } from './views/Template/Ckeditor'
import TemplateDetail from './views/Template/TemplateDetail'
import globalSetting from './views/globalSetting/index'
import index from './views/offlineUser/index'
import add from './views/offlineUser/add'
import edit from './views/offlineUser/edit'
import IntroList from './views/intro/index'
import addIntro from './views/intro/addIntro'
import editIntro from './views/intro/editIntro'
import introDetail from './views/intro/introDetail'
import blog from './views/blogs/index'
import AddBlog from './views/blogs/AddBlog'
import EditBlog from './views/blogs/EditBlog'
import BlogDetail from './views/blogs/BlogDetail'
import Cms from './views/cms/index'
import EditCms from './views/cms/edit'
import ViewDetail from './views/cms/viewDetail'
import Faq from './views/faq/index'
import AddFaq from './views/faq/addFaq'
import EditFaq from './views/faq/editFaq'
import FaqDetail from './views/faq/faqDetail'
import Area from './views/area/index'
import AddArea from './views/area/addArea'
import EditArea from './views/area/editArea'
import Van from './views/van/index'
import AddVan from './views/van/addVan'
import EditVan from './views/van/editVan'
import Driver from './views/driver/index'
import AddDriver from './views/driver/addDriver'
import EditDriver from './views/driver/editDriver'
import ViewDriver from './views/driver/viewDriver'
import socialSetting from './views/socialSetting/index'
import AddsocialSetting from './views/socialSetting/add'
import EditsocialSetting from './views/socialSetting/edit'
import Enquiry from './views/enquiry/index'
import enquiryView from './views/enquiry/enquiryView'
import frontSlider from './views/frontSlider/index'
import addSlider from './views/frontSlider/add'
import editSlider from './views/frontSlider/edit'
import about from './views/about/about'
import ourPartner from './views/ourPartners/index'

import Service from './views/services/index'
import addService from './views/services/add'
import editService from './views/services/edit'
import ServiceView from './views/services/view'
import addOnService from './views/addOnServices/index'
import addAdditionalService from './views/addOnServices/add'
import editAdditionalService from './views/addOnServices/edit'
import viewAdditionalService from './views/addOnServices/view'
import unit from './views/unit/index'
import addUnit from './views/unit/add'
import editUnit from './views/unit/edit'
import itemSize from './views/itemSize/index'
import addItemSize from './views/itemSize/add'
import editItemSize from './views/itemSize/edit'
import items from './views/items/index'
import addItems from './views/items/add'
import editItems from './views/items/edit'
import viewItem from './views/items/view'
import itemCategory from './views/itemCategory/index'
import addItemCategory from './views/itemCategory/add'
import editItemCategory from './views/itemCategory/edit'
import packageDuration from './views/PackageDuration/index'
import addPackageDuration from './views/PackageDuration/add'
import editPackageDuration from './views/PackageDuration/edit'
import RegularSubscription from './views/RegularSubscriptionPlan/index'
import PackageSubscription from './views/PackageSubscriptionPlan/index'
import addPackageSubscription from './views/PackageSubscriptionPlan/add'
import editPackageSubscription from './views/PackageSubscriptionPlan/edit'
import viewPackageSubscription from './views/PackageSubscriptionPlan/view'
import AddState from './views/state/addState'
import EditState from './views/state/editState'
import State from './views/state/index'
import AddCity from './views/city/addCity'
import EditCity from './views/city/editCity'
import City from './views/city/index'
import Address from './views/address/index'
import addAddress from './views/address/add'
import editAddress from './views/address/edit'
import viewAddress from './views/address/view'
import ourServices from './views/ourServices/index'
// import AllAreas from './views/allarea/index'
import workingArea from './views/area/workingArea'

// App Start
import Help from './views/help/index'
import addHelp from './views/help/add'
import addAppHomeSlider from './views/appHomeSlider/add'
import appHomeSlider from './views/appHomeSlider/index'
import appHomeAboutUs from './views/appHomeAboutUs/index'
import addAppHomeAboutUs from './views/appHomeAboutUs/add'

// App End

import subadmin from './views/subAdmin/index'
import addSubadmin from './views/subAdmin/add'
import editSubadmin from './views/subAdmin/edit'
import viewSubadmin from './views/subAdmin/view'

import offerList from './views/offerManagement/index'
import addOffer from './views/offerManagement/add'
import editOffer from './views/offerManagement/edit'
import viewOffer from './views/offerManagement/view'

import orderNoteList from './views/orderNote/index'
import addOrderNote from './views/orderNote/add'
import editOrderNote from './views/orderNote/edit'
import viewOrderNote from './views/orderNote/view'

import placedOrderList from './views/placedOrder/index'
import placedOrderPrint from './views/placedOrder/print'
import examinItems from './views/placedOrder/ExaminItems'
import viewPlacedOrder from './views/placedOrder/view'
import Readytodeliverorder from './views/placedOrder/Readytodeliverorder'
import Itemdetail from './views/placedOrder/Itemdetail'
import allOrders from './views/orderStatus/allOrders'
import newOrders from './views/orderStatus/newOrders'
import inProcessOrders from './views/orderStatus/inProcessOrders'
import pickUpOrders from './views/orderStatus/pickUpOrders'
import pendingOrders from './views/orderStatus/PendingOrders'
import holdOrders from './views/orderStatus/HoldOrders'
import readyToDeliverOrders from './views/orderStatus/ReadyToDeliverOrders'
import deliveredOrders from './views/orderStatus/DeliveredOrders'
import deliverRejected from './views/orderStatus/deliverRejected'
import pickupRejected from './views/orderStatus/pickupRejected'
import customerRejectedOrders from './views/orderStatus/CustomerCancelOrders'

import customerRejectedOrdersList from './views/orderStatus/CustomerCancelOrdersList'
import pickupRejectedList from './views/orderStatus/pickupRejectedList'
import deliverRejectedList from './views/orderStatus/deliverRejectedList'
// import reasonsList from './views/reason/index';
import reasonList from './views/reasons/index'
import addReasons from './views/reasons/add'
import editReasons from './views/reasons/edit'
import generateRandomQR from './views/randomQR/add'
import assignQr from './views/randomQR/assignQr'
// import listRandomQR from './views/randomQR/index';
import PrintRandomQR from './views/randomQR/printqr'
import rateAndReview from './views/rateAndReview/index'
import raiseAnIssue from './views/raiseAnIssue/index'
import packageExaminItems from './views/placedOrder/PackageExaminItems'

//Report Routes
import UsersReport from './views/reportsSection/UsersReport'
import OrdersReport from './views/reportsSection/OrdersReport'
import CouponUsedReport from './views/reportsSection/CouponUsedReport'
import LogInWithReferralIdReport from './views/reportsSection/LogInWithReferralIdReport'

//Attribute-Option routes
import AttributeList from './views/attribute/index'
import AttributeAdd from './views/attribute/add'
import AttributeEdit from './views/attribute/edit'
import AttributeDetails from './views/attribute/view'

import OptionList from './views/option/index'
import OptionAdd from './views/option/add'
import OptionEdit from './views/option/edit'
import OptionDetails from './views/option/view'
import { intro } from './utils/validator'
import OfflineOrderAdd from './views/offlineOrder/add'
import OfflineOrderEdit from './views/offlineOrder/edit'
import OfflineOrderList from './views/offlineOrder'
import OfflineUserBagsList from './views/offlineUserBags'
import OfflineUserBagItem from './views/offlineUserBags/add'
import AddColor from './views/color/add'
import ColorList from './views/color'
import OfflineOrderIntro from './views/offlineOrder/intro'
import OrderList from './views/offlineOrder/list'
import UserList from './views/offlineOrder/userList'
import Bags from './views/offlineOrder/bag'
import AddItem from './views/offlineOrder/addItem'
import BagDetails from './views/offlineOrder/bagDetails'
import EditBagDetails from './views/offlineOrder/editBagDetail'
import ItemDetails from './views/offlineOrder/itemDetails'
import AddItemService from './views/offlineOrder/service'
import OnlineUserList from './views/offlineOrder/onlineUserList'
import Payment from './views/payment/list'
import ChatScreen from './views/orderStatus/chatScreen'
import WashingProcess from './views/washingProcess/index'
import WashingProcessAdd from './views/washingProcess/create'
import WashingProcessEdit from './views/washingProcess/edit'
import ServiceProcess from './views/serviceProcess/index'
import SerivceProcessAdd from './views/serviceProcess/create'
import SerivceProcessEdit from './views/serviceProcess/edit'
import ServiceProcessDetail from './views/serviceProcess/serviceProcessDetail'

import OfflineOrderListing from './views/offlineOrder/view/orderList'
import OfflineOrderBagListing from './views/offlineOrder/view/bagList'
import OfflineOrderItemListing from './views/offlineOrder/view/itemList'
import { element } from 'prop-types'
import orderView from './views/offlineOrder/view/orderView'
import OrderScanner from './views/offlineOrder/view/orderScanner'
import ScanOrder from './views/offlineOrder/scanOrder'
import ViewOrderDetails from './views/offlineOrder/viewOrderDetails'
import bulkQR from './views/bulkOrderQR/add'
import generateBulkQR from './views/bulkOrderQR/addQrCode'
import PrintBulkQR from './views/bulkOrderQR/printqr'

import CheckOutOrderListing from './views/checkoutOrder/view/orderList'
import CheckOutOrderBagListing from './views/checkoutOrder/view/bagList'
import CheckOutOrderScanner from './views/checkoutOrder/scanOrder'
import CheckinOrder from './views/offlineOrder/checkinOrder'
import SearchBag from './views/offlineOrder/SearchBag'
import SearchItem from './views/offlineOrder/SearchItem'
import SearchBagDetail from './views/offlineOrder/bagDetail'
import EditDescription from './views/DescriptionList/EditDescription'
import DescriptionDetail from './views/DescriptionList/DescriptionDetail'

// const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
// const Profile = React.lazy(()=> import('./views/pages/profile/Profile'))
// const ChangePassword = React.lazy(()=> import('./views/pages/profile/ChangePassword'))
// const userIndex = React.lazy(()=> import('./views/user/userIndex'))
// const addUser = React.lazy(()=> import ('./views/user/addUser'))
// const editUser = React.lazy(()=> import('./views/user/editUser'))
// const UserDetail = React.lazy(()=> import ('./views/user/UserDetail'))
// const TempList =React.lazy(()=> import ('./views/Template/TempList'))
// const EditTemplate = React.lazy(()=>import ('./views/Template/EditTemplate'))
// const Ckeditor = React.lazy(()=> import('./views/Template/Ckeditor'))
// const TemplateDetail = React.lazy(()=> import('./views/Template/TemplateDetail'))
// const globalSetting = React.lazy(()=> import ('./views/settingManegement/index'))
// const IntroList = React.lazy(()=> import ('./views/intro/index'))
// const addIntro = React.lazy(()=> import ('./views/intro/addIntro'))
// const editIntro = React.lazy(()=> import ('./views/intro/editIntro'))
// const introDetail = React.lazy(()=> import ('./views/intro/introDetail'))
// const blog = React.lazy(()=> import ('./views/blogs/index'))
// const AddBlog = React.lazy(()=> import ('./views/blogs/AddBlog'))
// const EditBlog = React.lazy(()=> import ('./views/blogs/EditBlog'))
// const BlogDetail = React.lazy(()=> import ('./views/blogs/BlogDetail'))
// const Cms = React.lazy(()=> import ('./views/cms/index'))
// const EditCms = React.lazy(()=> import ('./views/cms/edit'))
// const Faq = React.lazy(() => import ('./views/faq/index'))
// const AddFaq = React.lazy(() => import ('./views/faq/addFaq'))
// const EditFaq = React.lazy(() => import ('./views/faq/editFaq'))
// const FaqDetail = React.lazy(() => import ('./views/faq/faqDetail'))
// const Area = React.lazy(()=> import ('./views/area/index'))
// const AddArea = React.lazy(()=> import ('./views/area/addArea'))
// const EditArea = React.lazy(()=> import ('./views/area/editArea'))
// const Van = React.lazy(() => import ('./views/van/index'))
// const AddVan = React.lazy(()=> import ('./views/van/addVan'))
// const EditVan = React.lazy(()=> import ('./views/van/editVan'))
// const Driver = React.lazy(() => import ('./views/driver/index'))
// const AddDriver = React.lazy(()=> import ('./views/driver/addDriver'))
// const EditDriver = React.lazy(()=> import ('./views/driver/editDriver'))
// const socialSetting = React.lazy(()=> import ('./views/globalSetting/index'))
// const AddsocialSetting = React.lazy(()=> import ('./views/globalSetting/add'))
// const EditsocialSetting = React.lazy(()=> import ('./views/globalSetting/edit'))
// const Enquiry = React.lazy(() => import ('./views/enquiry/index'))
// const about= React.lazy(() => import ('./views/Template/about'))

const routes = [
  { path: '/', exact: true, name: 'Home' },
  // { path: '/dashboard', name: 'Dashboard', element: Dashboard },
  { path: '/theme', name: 'Theme', element: Colors, exact: true },
  { path: '/theme/colors', name: 'Colors', element: Colors },
  { path: '/theme/typography', name: 'Typography', element: Typography },
  { path: '/base', name: 'Base', element: Cards, exact: true },
  { path: '/base/accordion', name: 'Accordion', element: Accordion },
  { path: '/base/breadcrumbs', name: 'Breadcrumbs', element: Breadcrumbs },
  { path: '/base/cards', name: 'Cards', element: Cards },
  { path: '/base/carousels', name: 'Carousel', element: Carousels },
  { path: '/base/collapses', name: 'Collapse', element: Collapses },
  { path: '/base/list-groups', name: 'List Groups', element: ListGroups },
  { path: '/base/navs', name: 'Navs', element: Navs },
  { path: '/base/paginations', name: 'Paginations', element: Paginations },
  { path: '/base/placeholders', name: 'Placeholders', element: Placeholders },
  { path: '/base/popovers', name: 'Popovers', element: Popovers },
  { path: '/base/progress', name: 'Progress', element: Progress },
  { path: '/base/spinners', name: 'Spinners', element: Spinners },
  { path: '/base/tables', name: 'Tables', element: Tables },
  { path: '/base/tooltips', name: 'Tooltips', element: Tooltips },
  { path: '/buttons', name: 'Buttons', element: Buttons, exact: true },
  { path: '/buttons/buttons', name: 'Buttons', element: Buttons },
  { path: '/buttons/dropdowns', name: 'Dropdowns', element: Dropdowns },
  { path: '/buttons/button-groups', name: 'Button Groups', element: ButtonGroups },
  { path: '/charts', name: 'Charts', element: Charts },
  { path: '/forms', name: 'Forms', element: FormControl, exact: true },
  { path: '/forms/form-control', name: 'Form Control', element: FormControl },
  { path: '/forms/select', name: 'Select', element: Select },
  { path: '/forms/checks-radios', name: 'Checks & Radios', element: ChecksRadios },
  { path: '/forms/range', name: 'Range', element: Range },
  { path: '/forms/input-group', name: 'Input Group', element: InputGroup },
  { path: '/forms/floating-labels', name: 'Floating Labels', element: FloatingLabels },
  { path: '/forms/layout', name: 'Layout', element: Layout },
  { path: '/forms/validation', name: 'Validation', element: Validation },
  { path: '/icons', exact: true, name: 'Icons', element: CoreUIIcons },
  { path: '/icons/coreui-icons', name: 'CoreUI Icons', element: CoreUIIcons },
  { path: '/icons/flags', name: 'Flags', element: Flags },
  { path: '/icons/brands', name: 'Brands', element: Brands },
  { path: '/notifications', name: 'Notifications', element: Alerts, exact: true },
  { path: '/notifications/alerts', name: 'Alerts', element: Alerts },
  { path: '/notifications/badges', name: 'Badges', element: Badges },
  { path: '/notifications/modals', name: 'Modals', element: Modals },
  { path: '/notifications/toasts', name: 'Toasts', element: Toasts },
  { path: '/widgets', name: 'Widgets', element: Widgets },
]

export default routes

export const authRoutes = [
  { path: 'admin/login', name: 'Login', element: Login },
  { path: 'admin/forgot-password', name: 'ForgotPassword', element: ForgotPassword },
  { path: 'admin/reset-password/:id', name: 'ResetPassword', element: ResetPassword },
]

export const mainRoutes = [
  { path: 'admin/dashboard', name: 'Dashboard', element: Dashboard },
  // { path: '/formik', name: 'Formik', element: Formik },
  { path: 'admin/profile', name: 'Profile', element: Profile },
  { path: 'admin/change-password', name: 'ChangePassword', element: ChangePassword },
  { path: 'admin/user', name: 'userIndex', element: userIndex },
  { path: 'admin/add-user', name: 'addUser', element: addUser },
  { path: 'admin/edit-user', name: 'editUser', element: editUser },
  { path: 'admin/user-detail/:id', name: 'UserDetail', element: UserDetail },
  { path: 'admin/template', name: 'TempList', element: TempList },
  { path: 'admin/safeDescription', name: 'safe description', element: DescriptionList },
  { path: 'admin/safeDescription/add', name: 'add safe description', element: AddDescription },
  { path: 'admin/safeDescription/edit', name: 'add safe description', element: EditDescription },
  { path: 'admin/safeDescription/safeDescription-detail/:id', name: 'add safe description', element: DescriptionDetail },
  { path: 'admin/template/edit-template', name: 'EditTemplate', element: EditTemplate },
  { path: 'admin/template/template-detail/:id', name: 'TemplateDetail', element: TemplateDetail },
  { path: 'admin/global-setting', name: 'globalSetting', element: globalSetting },
  { path: 'admin/global-setting/:id', name: 'globalSetting', element: globalSetting },
  { path: 'admin/search', name: 'index', element: CheckinOrder },
  { path: 'admin/offline-order', name: 'index', element: OfflineOrderIntro },
  { path: 'admin/offline-user-list', name: 'index', element: UserList },
  { path: 'admin/online-user-list', name: 'index', element: OnlineUserList },
  { path: 'admin/offline-order-list', name: 'index', element: OrderList },
  { path: 'admin/offline-order-add', name: 'add', element: OfflineOrderAdd },
  { path: 'admin/offline-user/edit', name: 'edit', element: edit },
  { path: 'admin/offline-user-add', name: 'add', element: add },
  { path: 'admin/offline-order/:customerId', name: 'index', element: OfflineOrderList },
  { path: 'admin/offline-order/add/:customerId', name: 'add', element: OfflineOrderAdd },
  { path: 'admin/offline-order/edit', name: 'edit', element: OfflineOrderEdit },
  { path: 'admin/offline-bags/:orderId/:userId', name: 'detail', element: OfflineUserBagsList },
  { path: 'admin/offline-bags/scan', name: 'detail', element: ScanOrder },
  { path: 'admin/offline-bags/scan/:orderId', name: 'view', element: ViewOrderDetails },
  { path: 'admin/search-bags/:orderId', name: 'view', element: SearchBag },
  { path: 'admin/search-item/:itemId', name: 'view', element: SearchItem },
  { path: 'admin/search-bags/detail/:bagId', name: 'view', element: SearchBagDetail },

  {
    path: 'admin/offline-order/bags/:orderId',
    name: 'detail',
    element: Bags,
  },
  {
    path: 'admin/offline-order/bag/:bagId/:orderId',
    name: 'detail',
    element: BagDetails,
  },
  {
    path: 'admin/offline-order/bag/edit/:bagId',
    name: 'detail',
    element: EditBagDetails,
  },
  {
    path: 'admin/offline-order/bag-item/:bagId/:itemId',
    name: 'detail',
    element: ItemDetails,
  },
  {
    path: 'admin/offline-order/bag/add-item/:bagId/:itemCode',
    name: 'detail',
    element: AddItem,
  },
  {
    path: 'admin/offline-order/bag/add-item-service/:bagId/:orderId',
    name: 'detail',
    element: AddItemService,
  },
  {
    path: 'admin/payment',
    name: 'detail',
    element: Payment,
  },
  {
    path: 'admin/wash-process',
    name: 'detail',
    element: WashingProcess,
  },
  {
    path: 'admin/wash-process/add',
    name: 'add',
    element: WashingProcessAdd,
  },
  {
    path: 'admin/wash-process/edit',
    name: 'edit',
    element: WashingProcessEdit,
  },
  // component service process
  {
    path: 'admin/service-process',
    name: 'detail',
    element: ServiceProcess,
  },
  {
    path: 'admin/service-process/add',
    name: 'add',
    element: SerivceProcessAdd,
  },
  {
    path: 'admin/service-process/edit',
    name: 'edit',
    element: SerivceProcessEdit,
  },
  {
    path: 'admin/service-process/service-process-detail/:id',
    name: 'ServiceProcessDetail',
    element: ServiceProcessDetail,
  },
  // { path: 'admin/offline-order/edit/:id', name: 'edit', element: edit },

  { path: 'admin/intro', name: 'IntroList', element: IntroList },
  { path: 'admin/intro/add-intro', name: 'addIntro', element: addIntro },
  { path: 'admin/intro/edit-intro', name: 'editIntro', element: editIntro },
  { path: 'admin/intro/intro-detail/:id', name: 'introDetail', element: introDetail },
  { path: 'admin/blog', name: 'blog', element: blog },
  { path: 'admin/blog/add-blog', name: 'AddBlog', element: AddBlog },
  { path: 'admin/blog/edit-blog', name: 'EditBlog', element: EditBlog },
  { path: 'admin/blog/blog-detail/:id', name: 'BlogDetail', element: BlogDetail },
  { path: 'admin/cms-pages', name: 'Cms', element: Cms },
  { path: 'admin/cms-pages/edit-cms', name: 'EditCms', element: EditCms },
  { path: 'admin/cms-pages/view/:id', name: 'ViewDetail', element: ViewDetail },
  { path: 'admin/faq', name: 'Faq', element: Faq },
  { path: 'admin/faq/add-faq', name: 'AddFaq', element: AddFaq },
  { path: 'admin/faq/edit-faq', name: 'EditFaq', element: EditFaq },
  { path: 'admin/faq/faq-detail/:id', name: 'FaqDetail', element: FaqDetail },
  { path: 'admin/area', name: 'Area', element: Area },
  { path: 'admin/area/add-area', name: 'AddArea', element: AddArea },
  { path: 'admin/area/edit-area', name: 'EditArea', element: EditArea },
  { path: 'admin/van', name: 'Van', element: Van },
  { path: 'admin/van/add-van', name: 'AddVan', element: AddVan },
  { path: 'admin/van/edit-van', name: 'EditVan', element: EditVan },
  { path: 'admin/driver', name: 'Driver', element: Driver },
  { path: 'admin/driver/add-driver', name: 'AddDriver', element: AddDriver },
  { path: 'admin/driver/edit-driver', name: 'EditDriver', element: EditDriver },
  { path: 'admin/driver/view-driver/:id', name: 'ViewDriver', element: ViewDriver },
  { path: 'admin/social-setting', name: 'socialSetting', element: socialSetting },
  { path: 'admin/social-setting/add', name: 'AddsocialSetting', element: AddsocialSetting },
  { path: 'admin/social-setting/edit', name: 'EditsocialSetting', element: EditsocialSetting },
  { path: 'admin/enquiry', name: 'Enquiry', element: Enquiry },
  { path: 'admin/enquiry/enquiry-view', name: 'enquiryView', element: enquiryView },
  { path: 'admin/slider', name: 'frontSlider', element: frontSlider },
  { path: 'admin/slider/add-slider', name: 'addSlider', element: addSlider },
  { path: 'admin/slider/edit-slider', name: 'editSlider', element: editSlider },
  { path: 'admin/about-us', name: 'about', element: about },
  { path: 'admin/our-partners', name: 'ourPartner', element: ourPartner },
  { path: 'admin/services', name: 'Service', element: Service },
  { path: 'admin/services/add', name: 'addService', element: addService },
  { path: 'admin/services/edit', name: 'editService', element: editService },
  { path: 'admin/services/view/:id', name: 'ServiceView', element: ServiceView },
  { path: 'admin/add-on-service', name: 'addOnService', element: addOnService },
  { path: 'admin/add-on-service/add', name: 'addAdditionalService', element: addAdditionalService },
  {
    path: 'admin/add-on-service/edit',
    name: 'editAdditionalService',
    element: editAdditionalService,
  },
  {
    path: 'admin/add-on-service/view/:id',
    name: 'viewAdditionalService',
    element: viewAdditionalService,
  },
  { path: 'admin/unit', name: 'unit', element: unit },
  { path: 'admin/unit/add', name: 'addUnit', element: addUnit },
  { path: 'admin/unit/edit', name: 'editUnit', element: editUnit },
  { path: 'admin/item/item-size', name: 'itemSize', element: itemSize },
  { path: 'admin/item/item-size/add', name: 'addItemSize', element: addItemSize },
  { path: 'admin/item/item-size/edit', name: 'editItemSize', element: editItemSize },
  { path: 'admin/items', name: 'items', element: items },
  { path: 'admin/items/add', name: 'addItems', element: addItems },
  { path: 'admin/items/edit', name: 'editItems', element: editItems },
  { path: 'admin/items/view/:id', name: 'viewItem', element: viewItem },
  { path: 'admin/item/item-category', name: 'itemCategory', element: itemCategory },
  { path: 'admin/item/item-category/add', name: 'addItemCategory', element: addItemCategory },
  { path: 'admin/item/item-category/edit', name: 'editItemCategory', element: editItemCategory },

  { path: 'admin/package-duration', name: 'packageDuration', element: packageDuration },
  { path: 'admin/package-duration/add', name: 'addPackageDuration', element: addPackageDuration },
  {
    path: 'admin/package-duration/edit',
    name: 'editPackageDuration',
    element: editPackageDuration,
  },
  {
    path: 'admin/subscription-plan/regular',
    name: 'RegularSubscription',
    element: RegularSubscription,
  },
  {
    path: 'admin/subscription-plan/package',
    name: 'PackageSubscription',
    element: PackageSubscription,
  },
  {
    path: 'admin/subscription-plan/package/add',
    name: 'addPackageSubscription',
    element: addPackageSubscription,
  },
  {
    path: 'admin/subscription-plan/package/edit',
    name: 'editPackageSubscription',
    element: editPackageSubscription,
  },
  {
    path: 'admin/subscription-plan/package/view/:id',
    name: 'viewPackageSubscription',
    element: viewPackageSubscription,
  },
  { path: 'admin/help', name: 'help', element: Help },
  { path: 'admin/help/add', name: 'addHelp', element: addHelp },
  { path: 'admin/appHomeSlider', name: 'homeSlider', element: appHomeSlider },
  { path: 'admin/appHomeSlider/add', name: 'addHomeSlider', element: addAppHomeSlider },
  { path: 'admin/appHomeAboutUs', name: 'homeSlider', element: appHomeAboutUs },
  { path: 'admin/appHomeAboutUs/add', name: 'addHomeSlider', element: addAppHomeAboutUs },

  { path: 'admin/help/add', name: 'help', element: addHelp },
  { path: 'admin/sub-admin', name: 'subadmin', element: subadmin },
  { path: 'admin/sub-admin/add', name: 'addSubadmin', element: addSubadmin },
  { path: 'admin/sub-admin/edit', name: 'editSubadmin', element: editSubadmin },
  { path: 'admin/sub-admin/view/:id', name: 'viewSubadmin', element: viewSubadmin },

  { path: 'admin/state', name: 'State', element: State },
  { path: 'admin/state/add-state', name: 'AddState', element: AddState },
  { path: 'admin/state/edit-state', name: 'EditState', element: EditState },

  { path: 'admin/city', name: 'City', element: City },
  { path: 'admin/city/add-city', name: 'AddCity', element: AddCity },
  { path: 'admin/city/edit-city', name: 'EditCity', element: EditCity },
  { path: 'admin/address', name: 'Address', element: Address },
  { path: 'admin/address/add', name: 'addAddress', element: addAddress },
  { path: 'admin/address/edit', name: 'editAddress', element: editAddress },
  { path: 'admin/address/view/:id', name: 'viewAddress', element: viewAddress },
  { path: 'admin/our-services', name: 'ourServices', element: ourServices },
  { path: 'admin/working-area', name: 'workingArea', element: workingArea },

  { path: 'admin/offer', name: 'offerList', element: offerList },
  { path: 'admin/offer/add', name: 'addOffer', element: addOffer },
  { path: 'admin/offer/edit/:id/:service_type', name: 'editOffer', element: editOffer },
  { path: 'admin/offer/view/:id/:service_type', name: 'viewOffer', element: viewOffer },

  { path: 'admin/order-note', name: 'addOrderNote', element: addOrderNote },
  { path: 'admin/order-note/add', name: 'addOrderNote', element: addOrderNote },
  { path: 'admin/order-note/edit/:id', name: 'editOrderNote', element: editOrderNote },
  { path: 'admin/order-note/view', name: 'viewOrderNote', element: viewOrderNote },

  { path: 'admin/placed-order', name: 'placedOrderList', element: placedOrderList },
  {
    path: 'admin/placed-order/print/:id/:orderId',
    name: 'placedOrderPrint',
    element: placedOrderPrint,
  },
  {
    path: 'admin/placed-order/examin/:id/:orderId/:userId',
    name: 'examinItems',
    element: examinItems,
  },
  {
    path: 'admin/placed-order/examin-package-items/:id/:orderId/:userId',
    name: 'packageExaminItems',
    element: packageExaminItems,
  },
  {
    path: 'admin/placed-order/view/:id/:orderId',
    name: 'viewPlacedOrder',
    element: viewPlacedOrder,
  },
  { path: 'admin/placed-order/item-detail/:id/:orderId', name: 'Itemdetail', element: Itemdetail },
  {
    path: 'admin/placed-order/ready-to-deliver-order/:id/:orderId',
    name: 'Readytodeliverorder',
    element: Readytodeliverorder,
  },
  { path: 'admin/order/all-orders', name: 'allOrders', element: allOrders },
  { path: 'admin/order/new-orders', name: 'newOrders', element: newOrders },
  { path: 'admin/order/inprocess-orders', name: 'inProcessOrders', element: inProcessOrders },
  { path: 'admin/order/pickup-orders', name: 'pickUpOrders', element: pickUpOrders },
  { path: 'admin/order/pending', name: 'pendingOrders', element: pendingOrders },
  { path: 'admin/order/hold', name: 'holdOrders', element: holdOrders },
  { path: 'admin/order/chat/:orderId', name: 'orderChat', element: ChatScreen },

  {
    path: 'admin/order/ready-to-delivery',
    name: 'readyToDeliverOrders',
    element: readyToDeliverOrders,
  },
  { path: 'admin/order/deliverd', name: 'deliveredOrders', element: deliveredOrders },
  { path: 'admin/order/pickup-rejected', name: 'pickupRejected', element: pickupRejected },
  { path: 'admin/order/deliver-rejected', name: 'deliverRejected', element: deliverRejected },
  {
    path: 'admin/order/customer-rejected',
    name: 'customerRejectedOrders',
    element: customerRejectedOrders,
  },
  {
    path: 'admin/order/pickup-rejected-list',
    name: 'pickupRejectedList',
    element: pickupRejectedList,
  },
  {
    path: 'admin/order/deliver-rejected-list',
    name: 'deliverRejectedList',
    element: deliverRejectedList,
  },
  {
    path: 'admin/order/customer-rejected-list',
    name: 'customerRejectedOrdersList',
    element: customerRejectedOrdersList,
  },

  { path: 'admin/reasons', name: 'reasonList', element: reasonList },
  { path: 'admin/reasons/add', name: 'addReasons', element: addReasons },
  { path: 'admin/reasons/edit', name: 'editReasons', element: editReasons },

  { path: 'admin/QR-code-genration', name: 'generateRandomQR', element: generateRandomQR },
  { path: 'admin/assign-QR', name: 'assignQr', element: assignQr },
  // { path: 'admin/random-QR', name: 'listRandomQR', element: listRandomQR },
  { path: 'admin/print', name: 'PrintRandomQR', element: PrintRandomQR },

  { path: 'admin/rate-and-review', name: 'rateAndReview', element: rateAndReview },
  { path: 'admin/raise-an-issue', name: 'raiseAnIssue', element: raiseAnIssue },

  { path: 'admin/users-report', name: 'UsersReport', element: UsersReport },
  { path: 'admin/orders-report', name: 'OrdersReport', element: OrdersReport },
  { path: 'admin/coupons-report', name: 'CouponUsedReport', element: CouponUsedReport },
  {
    path: 'admin/reffral-login-report',
    name: 'LogInWithReferralIdReport',
    element: LogInWithReferralIdReport,
  },

  { path: 'admin/attribute', name: AttributeList, element: AttributeList },
  { path: 'admin/attribute/add', name: AttributeAdd, element: AttributeAdd },
  { path: 'admin/attribute/edit/:id', name: AttributeEdit, element: AttributeEdit },
  { path: 'admin/attribute/view/:id', name: AttributeDetails, element: AttributeDetails },

  { path: 'admin/option', name: OptionList, element: OptionList },
  { path: 'admin/option/add', name: OptionAdd, element: OptionAdd },
  { path: 'admin/option/edit/:id', name: OptionEdit, element: OptionEdit },
  { path: 'admin/option/view/:id', name: OptionDetails, element: OptionDetails },

  { path: 'admin/color/add', name: 'colorAdd', element: AddColor },
  { path: 'admin/colors', name: 'colorList', element: ColorList },
  { path: 'admin/offline-orders', name: 'offlineOrder', element: OfflineOrderListing },
  { path: 'admin/offline-orders/bags', name: 'offlineOrderBag', element: OfflineOrderBagListing },
  {
    path: 'admin/offline-orders/items',
    name: 'offlineOrderItem',
    element: OfflineOrderItemListing,
  },
  { path: 'admin/offline-orders/view', name: 'offlineOrderView', element: orderView },
  { path: 'admin/offline-orders/scan', name: 'offlineOrderScan', element: OrderScanner },

  { path: 'admin/bulk-qr-code', name: 'bulkQR', element: bulkQR },
  { path: 'admin/generate-qr-code', name: 'generateBulkQR', element: generateBulkQR },
  { path: 'admin/print-bulk-qr', name: 'PrintBulkQR', element: PrintBulkQR },

  { path: 'admin/checkout-orders', name: 'checkoutOrder', element: CheckOutOrderListing },
  {
    path: 'admin/checkout-orders/bags',
    name: 'checkoutOrderBag',
    element: CheckOutOrderBagListing,
  },
  { path: 'admin/checkout-orders/scan', name: 'checkoutOrderScan', element: CheckOutOrderScanner },
]
