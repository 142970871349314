import React, { useState, useEffect } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { get } from 'src/utils/apiManager';
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CRow,
  CImage,
} from '@coreui/react'
import { ImageUrl } from 'src/config/config'
import { FaArrowLeft } from 'react-icons/fa'

function view() {
  const { state } = useLocation()
  const { id } = useParams()
  const [itemdata, setItemData] = useState([]);
  const [itemSizeArr, setItemSizeArr] = useState([]);

  const [categoryName, setCategoryName] = useState('');
  const size = [];
  const getitem = () => {

    get(`admin/items/view-items?_id=${atob(id)}`).then((data) => {
      if (data.data !== null) {
        const tempData = data.data.map((item, key) => {
          setItemData(item)
          var sizeArr = [];
          var itemCategory = [];
          sizeArr?.push(item?.itemSize)
          setItemSizeArr(sizeArr)
          //FOR GET THE ITEM CATEGORY NAME
          itemCategory?.push(item?.itemCategory)
          // console.log(itemCategory)
          itemCategory?.forEach((ele, index) => {
            // console.log(ele.itemName_EN)
            ele?.forEach((value, key) => {
              setCategoryName(value?.itemName_EN)
            })
          })
          //FOR GET THE ITEM CATEGORY NAME
        })
      }
    })
  }

  useEffect(() => {
    getitem();
  }, [state])
  return (
    <>
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <CRow>
                    <CCol >
                      {' '}
                      <Link style={{ color: '#000' }} to={{ pathname: `/admin/items` }}>
                        <FaArrowLeft />
                      </Link>
                      <span>  Item Information</span>
                    </CCol>
                  </CRow>
                </CHeader>
                <CCardBody>
                  <CRow>
                    <CCol md={2}>
                      <strong>Item Name (EN) :-</strong>
                    </CCol>
                    <CCol>
                      <span>{itemdata?.itemName_EN}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Item Name (TH) :-</strong>
                    </CCol>
                    <CCol>
                      <span>{itemdata?.itemName_TH}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Price :-</strong>
                    </CCol>
                    <CCol>
                      <span>{itemdata?.price}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Offer :-</strong>
                    </CCol>
                    <CCol>
                      <span>{itemdata?.offer} %</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Item Quota :-</strong>
                    </CCol>
                    <CCol>
                      <span>{itemdata?.itemQuota}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Item Categories(type) :-</strong>
                    </CCol>
                    <CCol>
                      <span>{categoryName}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Item Size :-</strong>
                    </CCol>
                    <CCol>
                      {itemSizeArr?.map((data, key) => {
                        data?.forEach((ele, index) => {
                          size?.push(ele?.itemSize_EN)
                        })
                      })}
                      <span>{size.join(',')}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Image:-</strong>
                    </CCol>
                    <CCol>
                      <div
                        style={{
                          marginTop: '10px',
                          justifyContent: 'center',
                        }}
                      >
                       <a target='_blank' href={`${ImageUrl}${itemdata?.image}`}>
                        <CImage
                          rounded
                          src={`${ImageUrl}${itemdata?.image}`}
                          style={{
                            maxHeight: '100px',
                            maxWidth: '100px',
                            borderRadius: 50,
                            alignItems: 'center',
                          }}
                        />
                        </a>
                      </div>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default view