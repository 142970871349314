import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CCardSubtitle,
  CRow,
  CButton,
} from '@coreui/react'
import { FaArrowLeft } from 'react-icons/fa'

function enquiryView() {
    const { state } = useLocation()
    // console.log(state)
  return (
    <>
        <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <CRow>
                    <CCol >
                      {' '}
                      <Link style={{ color: '#000' }} to={{ pathname: `/admin/enquiry` }}>
                        <FaArrowLeft />
                      </Link>
                      <span> Enquiry Information</span>
                    </CCol>
                  </CRow>
                </CHeader>
                <CCardBody>
                  <CRow>
                    <CCol md={2}>
                      <strong>Name:-</strong>
                    </CCol>
                    <CCol>
                      <span>{state?.name}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Email:-</strong>
                    </CCol>
                    <CCol>
                      <span>{state?.email}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Contact No. :-</strong>
                    </CCol>
                    <CCol>
                        <span>{state?.mobile}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Message:-</strong>
                    </CCol>
                    <CCol>
                        <span dangerouslySetInnerHTML={{__html: state?.message}}></span>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default enquiryView