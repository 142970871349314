import { CCard, CCardBody, CCardGroup, CCol, CContainer, CRow } from '@coreui/react'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { get } from 'src/utils/apiManager'

const UserList = () => {
  const [userListData, setUserListData] = useState([])

  useEffect(() => {
    get(`/admin/offline-order/offline-list?userType=offline`).then((res) => {
      if (res?.data !== null) {
        console.log(res.data)
        setUserListData([...res.data])
        // res?.data?.map((item, key) => {
        //   setUserListData()
        // })
      }
    })
  }, [])

  // const fetchStateID = () => {

  // }
  return (
    <CContainer>
      <CCard>
        <CCardGroup>
          <CCardBody>
            <CRow className="p-2 align-items-center">
              <CCol>
                <h5 style={{ textTransform: 'uppercase' }}>Offline Users</h5>
              </CCol>
              <CCol md="1">
                <Link
                  className={'btn btn-success'}
                  style={{ background: '#d3dc40', border: '#d3dc40' }}
                  to={{ pathname: `/admin/offline-order` }}
                >
                  Back
                </Link>
              </CCol>
            </CRow>
            <hr />
            <CRow className="p-2">
              <CCol xs={2} className='d-flex' >
                <Link className='card p-4 w-100 d-flex align-items-center justify-content-center text-center my-2' style={{wordBreak: 'break-all'}}
                  to="/admin/offline-user-add"
                > Add +
                </Link>
              </CCol>
              {userListData.length > 0 &&
                userListData.map((data) => { 
                  if(data?.username==''){
                  return null}
                  return(
                  <CCol  className='d-flex' xs={2} key={data._id}>
                    <Link  className='card p-4 w-100 d-flex align-items-center justify-content-center text-center my-2' style={{wordBreak: 'break-all'}}
                      to={`/admin/offline-order/add/${data._id}`}
                    >
                      {data?.username}
                    </Link>
                  </CCol>
                )})}
            </CRow>
          </CCardBody>
        </CCardGroup>
      </CCard>
    </CContainer>
  )
}

export default UserList
