import React, { useEffect, useState } from 'react'
import { google_api_key } from '../../config/config';
import { get } from 'src/utils/apiManager'
import GoogleMapComponent from './googleMap'
import { CCard, CCardBody, CCardGroup, CCol, CContainer, CHeader, CRow } from '@coreui/react';

function workingArea() {
    const [pageData, setpageData] = useState({
        page: 1,
        perPage: 10,
        searchItem: "",
    });
    const [coordinates, setCoordinates] = useState({
        lat: null,
        lng: null
    });
    const [marker, setMarker] = useState(true);
    const [suburbList, setSuburbList] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        get(
            `/admin/Area/get?page=${pageData?.page || 1}&perPage=${pageData?.perPage || 10
            }&searchItem=${pageData?.searchItem || ""}`
        ).then((data) => handleResponse(data));
    };

    const handleResponse = (data) => {
        let createSuburListData = [];
        if (!data?.status) toast.success(data?.message, data?.status);
        else {
            data?.data?.map((item) => {

                const removeExtraSpace =
                    item?.lat_long.trim().split(/, +/).join(' ');
                const a = removeExtraSpace?.replace(/ /g, ",");
                const arr = a.split(',');                           //split through (,) and convert in array
                let lat = '';
                let lng = '';
                const temp = [];

                let centroid_of_lat = [];
                let centroid_of_lng = [];
                arr?.map((item, index) => {
                    //index % 2 is true then stored in longitude else in latitude
                    if (index % 2 == 0) {
                        lng = item
                    } else {
                        lat = item;
                    }

                    // every odd index i.e. index % 2 != 0 then the object created and push in temp array
                    if (index % 2 != 0) {
                        const dataObject = {
                            lat: lat,
                            lng: lng
                        }
                        temp.push(dataObject);
                        // centroid_of_lat is array of all latitude values
                        centroid_of_lat?.push(dataObject?.lat);
                        // centroid_of_lng is array of all longitude values
                        centroid_of_lng?.push(dataObject?.lng);
                    }
                    // setPolyGonCoords(temp);                       //set temp array in polyGonCoords state       
                })

                // sum total of latitude and longitude array
                const sumlat = centroid_of_lat?.reduce((partialSum, a) => partialSum + parseFloat(a), 0);
                const sumlng = centroid_of_lng?.reduce((partialSum, a) => partialSum + parseFloat(a), 0);

                // average of sumlat and sumlng
                const latx = sumlat / centroid_of_lat?.length;
                const lngy = sumlng / centroid_of_lng?.length;

                //set finally coordinates in coordinates state

                setCoordinates({
                    ...coordinates,
                    lat: latx,
                    lng: lngy
                })

                createSuburListData.push({
                    "locality": item?.areaName_EN,
                    "suburbId": 1,
                    "suburbGeoBoundaries": temp
                    ,
                    "suburbCenter": {
                        "lat": latx,
                        "lng": lngy
                    }
                })

            })
            setSuburbList(createSuburListData)
        }
    };
    return (
        <div>
            <CContainer fluid>
                <CRow>
                    <CCol>
                        <CCardGroup>
                            <CCard className="p-4">
                                <CHeader>
                                    <CRow>
                                        {/* <CCol > */}
                                        {' '}
                                        {/* <Link style={{ color: '#000' }} to={{ pathname: `/admin/subscription-plan/package` }}>
                                                <FaArrowLeft />
                                            </Link> */}
                                        <strong><center>All Working Area In One Frame </center></strong>
                                        {/* </CCol> */}
                                    </CRow>
                                </CHeader>
                                <CCardBody>
                                    <GoogleMapComponent
                                        isMarkerShown={true}
                                        isPostcodeHighlighted={true}
                                        isSuburbHighlighted={true}
                                        showTrafficLayer={false}
                                        defaultZoom={8}
                                        loadingElement={<div style={{ height: '100vh' }} />}
                                        containerElement={<div style={{ height: '100vh', width: "100%" }} />}
                                        mapElement={<div style={{ height: '100vh', width: "100%" }} />}
                                        tilt={45}
                                        defaultCentre={coordinates}
                                        polygons={
                                            suburbList?.map((p, i) => {
                                                return ({
                                                    boundaries: p?.suburbGeoBoundaries,
                                                    suburbCentre: p?.suburbCenter,
                                                    metaData: {
                                                        name: `${p?.locality}`,
                                                        suburbId: `${p?.suburbId}`
                                                    }
                                                })
                                            })
                                        }
                                        selectedMarker={marker}
                                        //   onClick={(e) => handleClick(e)}
                                        googleMapURL={google_api_key}
                                    >

                                    </GoogleMapComponent>
                                </CCardBody>
                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>

        </div>
    )
}

export default workingArea