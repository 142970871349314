import React, { useRef, useEffect, useState } from 'react'
import { Formik } from 'formik'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import { put, get } from 'src/utils/apiManager'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { CCard, CCardBody, CCardGroup, CCol, CContainer, CFormLabel, CRow } from '@coreui/react'
import { DefaultInput, DefaultTextarea } from 'src/components/common/input'
import { validateEditedArea } from 'src/utils/validator'
import Select from 'react-select';

function editArea() {
  const navigate = useNavigate()
  const { state } = useLocation()
  const formRef = useRef(null)
  const [isDisable, setIsDisable] = useState(false)
  // const [selecteCityOption, setSelecteCityOption] = useState([]);
  // const [cityData, setCityData] = useState(null);

  // const getCities = () => {
  //   get(`admin/Area/city-list`).then((data) => {
  //     if (data.data !== null) {
  //       const tempData = data.data.map((item, key) => {
  //         if (state.city_id?._id == item._id) {
  //           selecteCityOption.push({ label: item?.cityName_EN, value: item?._id })
  //         }
  //         return { label: item?.cityName_EN, value: item?._id }
  //       })
  //       setCityData(tempData)
  //     }
  //   })
  // }

  const submit = async (values) => {
    // if (values?.zipcode <= 0 || values?.zipcode.length < 6) {
    //   toast.error("Enter correct zipcode")
    //   formRef.current.setFieldValue('zipcode', '')
    // } else {
    let areaData = {
      areaName_EN: values?.areaName_EN,
      areaName_TH: values?.areaName_TH,
      // city: values?.city,
      // zipcode: values?.zipcode,
      lat_long: values?.lat_long,
    }
    put(`admin/Area/update?_id=${state?._id}`, areaData).then((data) => {
      handleResponse(data)
    })
    // }
  }
  const handleResponse = (data) => {
    // console.log(data)
    if (data.status == true) {
      toast.success(data.message)
      setTimeout(() => {
        navigate('/admin/area')
      }, 800)
    } else {
      toast.error(data.message)
    }
    formRef.current.resetForm()
    // setSelecteCityOption('');
  }
  useEffect(() => {
    // getCities();
    if (state != null) {
      formRef.current.setFieldValue('areaName_EN', state?.areaName_EN)
      formRef.current.setFieldValue('areaName_TH', state?.areaName_TH)
      // formRef.current.setFieldValue('city', state?.city)
      // formRef.current.setFieldValue('zipcode', state?.zipcode)
      formRef.current.setFieldValue('lat_long', state?.lat_long)
    }
  }, [state])
  return (
    <>
      <CContainer>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <CRow>
                        <CCol md="11">
                          <h1>Edit Area</h1>
                        </CCol>
                        <CCol md="1">
                          <Link
                            className={'btn btn-success mt-2'}
                            style={{ background: '#d3dc40', border: '#d3dc40' }}
                            to={{ pathname: `/admin/area` }}
                          >
                            Back
                          </Link>
                        </CCol>
                      </CRow>
                      {/* <p className="text-medium-emphasis">Add new FAQ</p> */}
                      <Formik
                        initialValues={{
                          areaName_EN: '',
                          areaName_TH: '',
                          // zipcode: '',
                          lat_long: '',
                        }}
                        validationSchema={validateEditedArea}
                        onSubmit={(values, { setSubmitting }) => {
                          submit(values)
                          setSubmitting(false)
                        }}
                        innerRef={formRef}
                      >
                        {(props) => (
                          <form onSubmit={props?.handleSubmit}>
                            <CRow >
                              <CCol md={6}>
                              <CFormLabel>Area Name (EN)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Area Name'}
                                  // name={'Area Name (EN)'}
                                  // maxLength={30}
                                  value={props?.values?.areaName_EN}
                                  onChange={props?.handleChange('areaName_EN')}
                                  onKeyUp={() => {
                                    if (state?.areaName_EN == props?.values?.areaName_EN)
                                      setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                  onBlur={() => props?.setFieldTouched('areaName_EN', true)}
                                  error={props?.touched?.areaName_EN && props?.errors?.areaName_EN}
                                />
                                {/* <CFormLabel>City</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <Select
                                  defaultValue={selecteCityOption}
                                  onChange={(val) => {
                                    setSelecteCityOption(val)
                                    // console.log(val)
                                    if (val == selecteCityOption) setIsDisable(false)
                                    else setIsDisable(true)
                                    props.setFieldValue("city", val.value)
                                  }}
                                  options={cityData}
                                  value={selecteCityOption}
                                  placeholder={"Select city"}
                                />
                                {selecteCityOption == undefined ? <span className='text-danger'>{props?.errors?.city}</span> : ''}
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Zipcode'}
                                  maxLength={6}
                                  name={'Zipcode'}
                                  value={props?.values?.zipcode === null ? '' : props?.values?.zipcode}
                                  onChange={props?.handleChange('zipcode')}
                                  onKeyUp={() => {
                                    if (state.zipcode == props?.values?.zipcode) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                  onBlur={() => props?.setFieldTouched('zipcode', true)}
                                  error={props?.touched?.zipcode && props?.errors?.zipcode}
                                /> */}
                                <DefaultTextarea
                                  placeholder={'Area Range'}
                                  name={'Area Range'}
                                  id={'lat_long'}
                                  value={props?.values?.lat_long}
                                  onChange={props?.handleChange('lat_long')}
                                  onBlur={() => props?.setFieldTouched('lat_long', true)}
                                  onKeyUp={() => {
                                    if (state.lat_long == props?.values?.lat_long) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                  error={props?.touched?.lat_long && props?.errors?.lat_long}
                                />
                                <span><strong>Note:- {''}</strong>Through the help of the given Google My Maps link, you may create a new Map by selecting various locations area-wise for the user addresses.<br /><a href="https://www.google.com/maps/d/" target="_blank">https://www.google.com/maps/d/</a>  </span>
                              </CCol>
                              <CCol md={6}>
                              <CFormLabel>Area Name (TH)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Area Name'}
                                  // name={'Area Name (TH)'}
                                  // maxLength={30}
                                  value={props?.values?.areaName_TH}
                                  onChange={props?.handleChange('areaName_TH')}
                                  onKeyUp={() => {
                                    if (state.areaName_TH == props?.values?.areaName_TH)
                                      setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                  onBlur={() => props?.setFieldTouched('areaName_TH', true)}
                                  error={props?.touched?.areaName_TH && props?.errors?.areaName_TH}
                                />
                                {/* <DefaultTextarea
                                  placeholder={'Area Range'}
                                  name={'Area Range'}
                                  id={'lat_long'}
                                  value={props?.values?.lat_long}
                                  onChange={props?.handleChange('lat_long')}
                                  onBlur={() => props?.setFieldTouched('lat_long', true)}
                                  onKeyUp={() => {
                                    if (state.lat_long == props?.values?.lat_long) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                  error={props?.touched?.lat_long && props?.errors?.lat_long}
                                />
                                <span><strong>Note:- {''}</strong>Through the help of the given Google My Maps link, you may create a new Map by selecting various locations area-wise for the user addresses.<br /><a href="https://www.google.com/maps/d/" target="_blank">https://www.google.com/maps/d/</a>  </span> */}
                              </CCol>
                            </CRow>




                            <CRow>
                              <CCol md="2">
                                <button
                                  type="submit"
                                  className={'btn btn-success mt-2'}
                                  disabled={!isDisable || props.isSubmitting}
                                >
                                  Submit
                                </button>
                              </CCol>
                              <CCol md="2">
                                <button
                                  type="button"
                                  className={'btn btn-warning mt-2'}
                                  onClick={() => navigate('/admin/area')}
                                >
                                  Cancel
                                </button>
                              </CCol>
                              <CCol md="3"></CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default editArea
