import React, { useEffect, useState } from 'react'
import { CCard, CCardBody, CCardGroup, CCol, CContainer, CRow } from '@coreui/react'
import { Link, useParams } from 'react-router-dom'
import { post, get } from 'src/utils/apiManager'
import { toast } from 'react-toastify'

const SearchBag = () => {
    const { orderId } = useParams()
    const [orderData, setOrderData] = useState([])
    const [bags, setBags] = useState([])
    const getBagData = () => {
        get(`/admin/offline-order/get-order-id/${orderId}?checkValue=true`)
            .then((res) => {
                if (!res?.status) {
                    toast.error(res?.message);
                    return
                }
                console.log({ res });
                get(`/admin/offline-order/bag-list/${res?.order}`).then((response) => {
                    setBags([...response?.data[0]?.bags])

                    let newData = response?.data?.map((d) => {
                        return { ...d, addOnServiceDetail: d?.addOnServiceDetail ? JSON.parse(d?.addOnServiceDetail) : [] }
                    })
                    setOrderData(newData[0])
                })
            })
    }
    useEffect(() => {
        getBagData()
    }, [orderId])




    return (
        <CContainer>
            <CCard>
                <CCardGroup>
                    <CCardBody>
                        <CCol>
                            <h5 >Order Details</h5>
                        </CCol>
                        <CRow className="p-2">

                            <CCol md="6">
                                {orderData?.orderId && (<p className="mb-1"><b>OrderId:</b> {orderData.orderId}</p>)}

                                {orderData?.name && (<p className="mb-1"> <b>Order Name:</b>  {orderData.name}</p>)}

                                {orderData.orderStatus && (<p className="mb-1"><b>Order Status:</b> {(orderData.orderStatus).toUpperCase()}</p>)}


                                {orderData?.orderType && (<p className="mb-1"><b>Order Type:</b> {(orderData?.orderType).toUpperCase()}</p>)}

                                {orderData && orderData?.QR && orderData?.QR[0] && (
                                    <p className="mb-1"><b>QR Code ID:</b> {orderData.QR[0].QrId}</p>
                                )}


                                {orderData?.customer?.[0] && (<p className="mb-1"><b>Customer Name:</b> {orderData?.customer[0]?.username}</p>)}


                                {orderData?.customer?.[0] && (<p className="mb-1"><b>Customer Phone:</b> {orderData?.customer[0]?.mobile}</p>)}

                                {orderData && orderData?.addressId && orderData?.addressId[0] && (
                                    <p className="mb-1"><b>Customer Address:</b> {orderData.addressId[0].address}</p>
                                )}


                                {orderData?.addOnServiceDetail?.length > 0 ? <p className="mb-1"><b>Addons:</b> </p> : ""}
                                {orderData?.addOnServiceDetail?.map((addon, k) => (
                                    <div className=''>
                                        <span> <b>Name:  </b>  {addon?.serviceName_EN} </span>,
                                        <span> <b>Price:  </b> {addon?.price} </span>,
                                        <span> <b>Quantity:  </b> {addon?.quantity} </span>
                                    </div>
                                ))}
                            </CCol>
                        </CRow>

                        <CRow className="p-2 align-items-center">
                            <CCol>
                                <h5 style={{ textTransform: 'uppercase' }}>Order summary page</h5>
                            </CCol>
                        </CRow>
                        <hr />


                        <div className="bag-list-box" style={{ maxHeight: "520px", overflowX: "auto", overflow: "auto" }}>
                            <CRow className="p-2 align-items-center">

                                {bags.length > 0 &&
                                    bags.map((data, index) => (
                                        <CCol xs={4} key={index} className="p-3">
                                            <Link
                                                to={`/admin/search-bags/detail/${data?._id}`}
                                                style={{
                                                    textDecoration: 'none',
                                                    color: 'black',
                                                }}
                                            >
                                                <div className="card p-3 text-center">
                                                    <i class="fa-solid fa-bag-shopping" style={{ fontSize: '30px' }}></i>
                                                    <p style={{ margin: 0 }}>bag {index + 1}</p>
                                                    <p style={{ margin: 0 }}>
                                                        {data.item.length}:{' '}
                                                        <span
                                                            style={{
                                                                fontWeight: 'bold',
                                                            }}
                                                        >
                                                            item
                                                        </span>
                                                    </p>
                                                    <p style={{ margin: 0 }}>
                                                        {!data.service
                                                            ? 'No service type available.'
                                                            : data.service.serviceName_EN}
                                                    </p>

                                                </div>
                                            </Link>
                                        </CCol>
                                    ))}
                            </CRow>
                        </div>

                    </CCardBody>
                </CCardGroup>
            </CCard>
        </CContainer>
    )
}

export default SearchBag