import React from "react";
import {
    GoogleMap,
    Marker,
    Polygon,
    withScriptjs,
    withGoogleMap,
    TrafficLayer,
    InfoWindow
} from "react-google-maps";
import compose from "recompose/compose";
import withProps from "recompose/withProps";

const parseCoordinates = (coordinates) => {
    var result = [];
    for (var index = 0; index < coordinates?.length - 1; index++) {
        result.push({
            lat: Number(coordinates[index]?.lat),
            lng: Number(coordinates[index]?.lng),
        });
    }
    return result;
};

const GoogleMapComponent = function Map() {

    return (compose(
        withProps((props) => ({
            googleMapURL:
                "https://maps.googleapis.com/maps/api/js?key=" +
                props?.googleMapURL +
                "&v=3.exp&libraries=geometry,drawing,places",
            loadingElement: props?.loadingElement,
            containerElement: props?.containerElement,
            mapElement: props?.mapElement
        })),
        withScriptjs,
        withGoogleMap
    )((props) => (

        props?.polygons?.length > 1 ?
            <GoogleMap
                // defaultCenter={{
                //     lat: parseFloat(props?.defaultCentre?.lat),
                //     lng: parseFloat(props?.defaultCentre?.lng),
                // }}
                defaultCenter={{
                    lat: 13.755876311111113,
                    lng: 100.51149406666667,
                }}
                //note different spellings of centre and center.
                {...props?.centre && { center: props?.centre }}
                defaultZoom={props?.defaultZoom}
                zoomControl={false}
                tilt={props?.tilt}
            >
                {props?.isMarkerShown && (
                    props?.polygons &&
                    props?.polygons.map((poly, i) => {
                        console.log('polyyyyyyyyyyyyyyyyy', poly)
                        return (<>
                            {console.log('props?.defaultCentre', props?.defaultCentre)}
                            <Marker
                                position={{
                                    lat: parseFloat(poly?.suburbCentre?.lat),
                                    lng: parseFloat(poly?.suburbCentre?.lng),
                                }}
                                labelAnchor={new window.google.maps.Point(0, 0)}
                                labelStyle={{
                                    backgroundColor: "white",
                                    fontSize: "12px",
                                    padding: "8px",
                                    borderRadius: "5px"
                                }}
                                labelClass="my-custom-class-for-label"    // the CSS class for the label
                                labelInBackground={true}
                            >
                                <InfoWindow >
                                    <div>{poly?.metaData.name}</div>
                                </InfoWindow>
                            </Marker>

                        </>);
                    }
                    ))
                }
                {/* {props.individualGeoCode &&
            <Marker
                position={{
                    lat: props.defaultCentre.lat,
                    lng: props.defaultCentre.lng,
                }}
                labelAnchor={new window.google.maps.Point(0, 0)}
                labelStyle={{
                    backgroundColor: "white",
                    fontSize: "12px",
                    padding: "8px",
                    borderRadius: "5px"
                }}
            >
                 <InfoWindow >
                    <div>{props.markerTag}</div>
                </InfoWindow>
            </Marker>
        } */}

                {props?.showTrafficLayer && <TrafficLayer autoUpdate />}

                {(props?.isSuburbHighlighted && props?.polygons) && (
                    props?.polygons.map((poly, i) => {

                        return (
                            <Polygon
                                path={parseCoordinates(poly?.boundaries)}
                                key={i}
                                options={{
                                    fillColor: "yellow",
                                    fillOpacity: 0.4,
                                    strokeColor: "#d35400",
                                    strokeOpacity: 0.8,
                                    strokeWeight: 3
                                }}
                            />
                        );
                    })
                )}

            </GoogleMap>
            :
            <GoogleMap
                // defaultCenter={{
                //     lat: parseFloat(props?.defaultCentre?.lat),
                //     lng: parseFloat(props?.defaultCentre?.lng),
                // }}
                defaultCenter={{
                    lat: 13.755876311111113,
                    lng: 100.51149406666667,
                }}
                //note different spellings of centre and center.
                {...props?.centre && { center: props?.centre }}
                defaultZoom={props?.defaultZoom}
                zoomControl={false}
                tilt={props?.tilt}
                onClick={(e) => {
                    props?.onClick(e)
                }}
            >
                {props?.isMarkerShown && (
                    props?.polygons &&
                    props?.polygons.map((poly, i) => {
                        return (<>
                            <Marker
                                position={{
                                    lat: parseFloat(poly?.suburbCentre?.lat),
                                    lng: parseFloat(poly?.suburbCentre?.lng),
                                }}
                                labelAnchor={new window.google.maps.Point(0, 0)}
                                labelStyle={{
                                    backgroundColor: "white",
                                    fontSize: "12px",
                                    padding: "8px",
                                    borderRadius: "5px"
                                }}
                                labelClass="my-custom-class-for-label"    // the CSS class for the label
                                labelInBackground={true}
                            >
                                <InfoWindow >
                                    <div>{poly?.metaData.name}</div>
                                </InfoWindow>
                            </Marker>

                        </>);
                    }
                    ))
                }

                {props?.showTrafficLayer && <TrafficLayer autoUpdate />}

                {(props?.isSuburbHighlighted && props?.polygons) && (
                    props?.polygons.map((poly, i) => {
                        const onClick = (e) => {
                            props?.onClick(e)
                        }

                        return (
                            <Polygon
                                path={parseCoordinates(poly?.boundaries)}
                                key={i}
                                options={{
                                    fillColor: "yellow",
                                    fillOpacity: 0.4,
                                    strokeColor: "#d35400",
                                    strokeOpacity: 0.8,
                                    strokeWeight: 3
                                }}
                                onClick={(e) => {
                                    onClick(e)
                                }}
                            />
                        );
                    })
                )}

            </GoogleMap>
    )));
}()

export default GoogleMapComponent;