import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CCardSubtitle,
  CRow,
  CButton,
} from '@coreui/react'
import { FaArrowLeft } from 'react-icons/fa'

function viewDetail() {
    const { state } = useLocation()
    // console.log(state)
  return (
    <>
          <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <CRow>
                    <CCol >
                      {' '}
                      <Link style={{ color: '#000' }} to={{ pathname: `/admin/cms-pages` }}>
                        <FaArrowLeft />
                      </Link>
                      <span> Cms Information</span>
                    </CCol>
                  </CRow>
                </CHeader>
                <CCardBody>
                  <CRow>
                    <CCol md={2}>
                      <strong>Title (EN) :-</strong>
                    </CCol>
                    <CCol>
                      <span>{state?.title_EN }</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Title (TH) :-</strong>
                    </CCol>
                    <CCol>
                      <span>{state?.title_TH}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Description (EN) :-</strong>
                    </CCol>
                    <CCol>
                    <span dangerouslySetInnerHTML={{__html: state?.description_EN}}></span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Description (TH):-</strong>
                    </CCol>
                    <CCol>
                        <span dangerouslySetInnerHTML={{__html: state?.description_TH}}></span>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default viewDetail