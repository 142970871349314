import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { post, get, deleteApi } from '../../utils/apiManager'
import CIcon from '@coreui/icons-react'
import { cilPlus } from '@coreui/icons'
import {
    CCard, CCardBody, CCardGroup, CCol, CContainer, CHeader, CCardSubtitle, CTable, CRow, CTableHead, CTableRow, CTableHeaderCell, CTableBody, CTableDataCell, CButton,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import { FaEdit, FaTrash } from 'react-icons/fa'

function index() {
    const navigate = useNavigate();
    const [notDeliveryReasons, setNotDeliverReasons] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        get(
            `/admin/reasons/get-reason`
        ).then((data) => handleResponse(data));
    };
    const handleResponse = (data) => {
        if (!data?.status) toast.success(data?.message, data?.status);
        else {
            // console.log(data?.data)
            // data.data.forEach((item) => {
            //     console.log(item.reasonType, "item ");
            //     if (item.reasonType == 'driver') {

            //     }
            // })
            setNotDeliverReasons(data?.data);
        }
    };

    const onClickUserEdit = async (value) => {
        navigate('/admin/reasons/edit', { state: value })
    };

    const statusChange = (status, id) => {
        let default_status = ''
        if (status == 0) default_status = "Active"
        else default_status = "Inactive"
        if (confirm(`Are you sure you want to ${default_status} the status`)) {
            let formData = {
                _id: id,
                status: status == '1' ? 0 : 1
            };
            post('/admin/reasons/changeStatus', formData).then(
                (data) => {
                    handleStatusChange(data);
                }
            );
        }
    };

    const handleStatusChange = (data) => {
        if (data?.status) {
            toast.success(data?.message, data?.status);
            getData();
        }
        else {
            toast.error(data?.message);
        }

    };

    const deleteData = (id) => {
        if (confirm("Are you sure you want to delete")) {
            deleteApi(`/admin/reasons/delete?id=${id}`).then((data) => {
                handleDelete(data);
            });
        }
    };

    const handleDelete = (data) => {
        if (data?.status == true) {
            toast.success(data?.message)
        } else {
            toast.error(data?.message)
        }
        getData();
    };


    return (
        <>
            <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
            <CContainer fluid>
                <CRow>
                    <CCol>
                        <CCardGroup>
                            <CCard className="p-4">
                                <CHeader>
                                    <CCardSubtitle as="h4">Reasons for cancel order</CCardSubtitle>
                                    <CRow>
                                        {/* <CCol md="8">
                      <DefaultInputWithIcon
                        type={'text'}
                        placeholder="search"
                        customType={'search'}
                        value={pageData?.searchItem}
                        onClick={onSearchCLick}
                        onChange={(e) => {
                          onSearchCLick(e.target.value)
                        }}
                      />
                    
                      </CCol> */}
                                        <CCol >
                                            <Link to={{ pathname: '/admin/reasons/add' }} className="btn btn-dark">
                                                <CIcon icon={cilPlus} />
                                                Add
                                            </Link>
                                        </CCol>
                                    </CRow>
                                </CHeader>

                                <CCardBody>
                                    <CTable bordered hover responsive>
                                        <CTableHead>
                                            <CTableRow>
                                                <CTableHeaderCell>S. No</CTableHeaderCell>
                                                <CTableHeaderCell>Reason Type</CTableHeaderCell>
                                                <CTableHeaderCell>Reason</CTableHeaderCell>
                                                {/* <CTableHeaderCell>Status</CTableHeaderCell> */}
                                                <CTableHeaderCell>Action</CTableHeaderCell>
                                            </CTableRow>
                                        </CTableHead>
                                        <CTableBody>
                                            {notDeliveryReasons?.map((ele, index) => {
                                                return (
                                                    <CTableRow key={index}>
                                                        <CTableDataCell>{index + 1}</CTableDataCell>
                                                        <CTableDataCell >
                                                            {ele?.reasonType == 'driver' ?
                                                                <CButton size="sm" style={{ backgroundColor: '#E9B00B', border: '#E9B00B' }} outline={ele?.reasonType}>
                                                                    <span><strong>Driver</strong></span>
                                                                </CButton>
                                                                : ele?.reasonType == 'customer' ? <CButton size="sm" style={{ backgroundColor: '#80861C', border: '#80861C' }} outline={ele?.reasonType}>
                                                                    <span><strong>Customer</strong></span>
                                                                </CButton> : ''}
                                                        </CTableDataCell>
                                                        <CTableDataCell >{ele?.reason}</CTableDataCell>
                                                        {/* <CTableDataCell>
                                                            <CButton
                                                                size="sm"
                                                                outline={ele?.status ? "1" : "0"}
                                                                onClick={() =>
                                                                    statusChange(ele?.status, ele?._id)
                                                                }
                                                            >
                                                                {ele?.status ? "Active" : "Inactive"}
                                                            </CButton>
                                                        </CTableDataCell> */}
                                                        <CTableDataCell>
                                                            <span className="mx-2" onClick={() => onClickUserEdit(ele)} style={{ fontSize: '20px', color: '#d3dc40', cursor: 'pointer' }}><FaEdit /></span>
                                                            <span className="mx-2 text-danger" onClick={() => deleteData(ele?._id)} style={{ fontSize: '20px', padding: '5px', cursor: 'pointer' }}><FaTrash /></span>
                                                        </CTableDataCell>
                                                    </CTableRow>
                                                );
                                            })}
                                        </CTableBody>
                                    </CTable>
                                    {!notDeliveryReasons?.length && (
                                        <>
                                            <h1>No Data</h1>
                                        </>
                                    )}
                                </CCardBody>
                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </>
    )
}

export default index