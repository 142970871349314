import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import { useLocation, useNavigate } from 'react-router-dom'
import { ImageUrl } from 'src/config/config'
import { put } from 'src/utils/apiManager'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { CCard, CCardBody, CCardGroup, CCol, CContainer, CRow, CImage, CFormLabel } from '@coreui/react'
import { DefaultMobileInput, DefaultInput } from 'src/components/common/input'
import { edit } from 'src/utils/validator'
import 'react-phone-number-input/style.css'
import { ErrorText } from 'src/components/common/commonError'

export default function editUser() {

  const navigate = useNavigate()
  const { state } = useLocation()
  const formRef = useRef(null)
  const fileRef = useRef(null)
  const [imageUrl, setimageUrl] = useState()
  const [invalidImage, setInvalidImage] = useState('')
  const [isDisable, setIsDisable] = useState(false);
  const [userImagePreview, setUserImagePreview] = useState();

  const [passVisibility, setPassVisibility] = useState(false)
  const [newPassVisibility, setNewPassVisibility] = useState(false)
  const [confrmNewPassVisibility, setConfrmNewPassVisibility] = useState(false)
  const [passwordType, setPasswordType] = useState({
    currentPassType: 'password',
    newPassType: 'password',
    confrmNewPassType: 'password'
  })

  const handlePasswordVisibility = (e, type) => {
    e.preventDefault();
    if (type == "currentPassword") {
      setPassVisibility(!passVisibility)
    } else if (type == "NewPassword") {
      setNewPassVisibility(!newPassVisibility)
    } else {
      setConfrmNewPassVisibility(!confrmNewPassVisibility)
    }
  }
  useEffect(() => {
    if (passVisibility == false) {
      setPasswordType({ ...passwordType, currentPassType: "password" })
    } else {
      setPasswordType({ ...passwordType, currentPassType: "text" })
    }
  }, [passVisibility])

  useEffect(() => {
    if (newPassVisibility == false) {
      setPasswordType({ ...passwordType, newPassType: "password" })
    } else {
      setPasswordType({ ...passwordType, newPassType: "text" })
    }
  }, [newPassVisibility])

  useEffect(() => {
    if (confrmNewPassVisibility == false) {
      setPasswordType({ ...passwordType, confrmNewPassType: "password" })
    } else {
      setPasswordType({ ...passwordType, confrmNewPassType: "text" })
    }
  }, [confrmNewPassVisibility])
  useEffect(() => {
    if (state != null) {
      formRef.current.setFieldValue('username', state?.username)
      formRef.current.setFieldValue('email', state?.email)
      formRef.current.setFieldValue('mobile', state?.mobile)
      if (state?.image) {
        setimageUrl(`${state?.ImageUrl}/${state?.image}`)

      }
    }
  }, [state])
  const submit = async (values) => {
    console.log(values, "submit values", invalidImage.length);
    if (invalidImage?.length === 0) {
      const formData = new FormData()
      for (var ele in values) {
        formData?.append(ele, values[ele])
      }
      formData.append('id', state?._id)
      const Url = `/admin/User/edit-user`
      put(Url, formData, 1).then((data) => {
        handleResponse(data)
      })
    } else {
      return
    }
  }
  const handleResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      setTimeout(() => {
        navigate('/admin/user')
      }, 1000)
    }
    formRef.current.resetForm()
    fileRef.current.value = null
  }
  return (
    <>
      <CContainer >
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <CRow>
                        <CCol md="11">
                          <h1>Edit User</h1>
                        </CCol>
                        <CCol md="1">
                          <Link className={'btn btn-success mt-2'} style={{ background: '#d3dc40', border: '#d3dc40' }} to={{ pathname: `/admin/user` }}>
                            Back
                          </Link>
                        </CCol>
                      </CRow>
                      <Formik
                        initialValues={{
                          username: '',
                          email: '',
                          image: '',
                          mobile: '',
                          newPassword: "",
                          confirmNewPassword: "",
                        }}
                        validationSchema={edit}
                        onSubmit={(values, { setSubmitting }) => {
                          submit(values)
                          setSubmitting(false)
                        }}
                        innerRef={formRef}
                      >
                        {(props) => (
                          <form onSubmit={props?.handleSubmit}>
                            <CRow xs={12}>
                              <CCol md={6}>
                                <CFormLabel>Name</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Name'}
                                  // name={'Name'}
                                  id={'username'}
                                  value={props?.values?.username}
                                  onChange={props?.handleChange('username')}
                                  onKeyUp={(value) => {
                                    if (state?.username == props?.values?.username) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                  onBlur={() => props?.setFieldTouched('username', true)}
                                  error={props?.touched?.username && props?.errors?.username}
                                />
                              </CCol>
                              <CCol md={6}>
                                <CFormLabel>Email</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  disabled={true}
                                  type={'email'}
                                  placeholder={'Email'}
                                  id={'email'}
                                  value={props?.values?.email}
                                  // value={this.state?.name}
                                  onChange={props?.handleChange('email')}
                                  onBlur={() => props?.setFieldTouched('email', true)}
                                  error={props?.touched?.email && props?.errors?.email}
                                />
                              </CCol>
                            </CRow>
                            <CRow xs={12}>
                              <CCol md={6}>
                                <CFormLabel>New Password</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <div className="input-group">
                                  <input
                                    type={passwordType?.newPassType}
                                    autoComplete="off"
                                    className="form-control"
                                    placeholder={"New Password"}
                                    id={"NewPassword"}
                                    value={props?.values?.newPassword}
                                    onKeyUp={(e) => {
                                      setIsDisable(e.target.value ? true : false)
                                    }}
                                    onChange={props?.handleChange("newPassword")}
                                    onBlur={() => props?.setFieldTouched("newPassword", true)}
                                  />
                                  <button
                                    className="btn btn-outline-secondary"
                                    type="button"
                                    onClick={(e) => handlePasswordVisibility(e, 'NewPassword')}
                                    style={{ backgroundColor: '#d8dbe0' }}
                                  >
                                    {passwordType?.newPassType == "password" ? (
                                      <i style={{ color: '#2C384A' }} className="fa-solid fa-eye-slash"></i>
                                    ) : <i style={{ color: '#2C384A' }} className="fa-solid fa-eye"></i>}
                                  </button>
                                </div>
                                {props?.touched?.newPassword && props?.errors?.newPassword ? <ErrorText title={props?.errors?.newPassword} /> : null}
                                <DefaultMobileInput
                                  type={'text'}
                                  placeholder={'Mobile'}
                                  name={'Mobile'}
                                  id={'mobile'}
                                  value={props?.values?.mobile}
                                  onChange={(val) => props?.setFieldValue('mobile', val || '')}
                                  onKeyUp={(value) => {
                                    if (state?.mobile == props?.values?.mobile) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                  onBlur={() => props?.setFieldTouched('mobile', true)}
                                  error={props?.touched?.mobile && props?.errors?.mobile}
                                />

                              </CCol>
                              <CCol md={6}>
                                <CFormLabel>Confirm New Password</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <div className="input-group">
                                  <input
                                    type={passwordType?.confrmNewPassType}
                                    autoComplete="off"
                                    className="form-control"
                                    placeholder={"Confirm New Password"}
                                    id={"confirmNewPassword"}
                                    value={props?.values?.confirmNewPassword}
                                    onChange={props?.handleChange("confirmNewPassword")}
                                    onBlur={() =>
                                      props?.setFieldTouched("confirmNewPassword", true)
                                    }
                                  />
                                  <button
                                    className="btn btn-outline-secondary"
                                    type="button"
                                    onClick={(e) => handlePasswordVisibility(e, 'confirmNewPassword')}
                                    style={{ backgroundColor: '#d8dbe0' }}
                                  // onClick={() => setConfrmNewPassVisibility(!confrmNewPassVisibility)}
                                  >
                                    {passwordType?.confrmNewPassType == "password" ? (
                                      <i style={{ color: '#2C384A' }} className="fa-solid fa-eye-slash"></i>
                                    ) : <i style={{ color: '#2C384A' }} className="fa-solid fa-eye"></i>}
                                  </button>
                                </div>
                                {props?.touched?.confirmNewPassword && props?.errors?.confirmNewPassword ? <ErrorText title={props?.errors?.confirmNewPassword} /> : null}

                                <CFormLabel>Image</CFormLabel>
                                {/* <span className="text-danger" style={{ marginLeft: '5px' }}>*</span> */}
                                <DefaultInput
                                  type={'file'}
                                  placeholder={'Image'}
                                  // className="hidden"
                                  // hidden
                                  // id="image"
                                  // name={'Image'}
                                  accept="image/*"
                                  refr={fileRef}
                                  MIN_FILE_SIZE={1024}
                                  MAX_FILE_SIZE={5120}
                                  size={5}
                                  file={props?.values?.image}
                                  // defaultValue={state?.image}
                                  // fileReader={}
                                  onChange={(e) => {
                                    const imageFile = e?.target?.files[0];
                                    if (state?.image == imageFile?.name) setIsDisable(false)
                                    else setIsDisable(true)
                                    if (!imageFile?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
                                      setInvalidImage("Please select valid image")
                                    }
                                    else {
                                      setInvalidImage('')
                                    }

                                    //for image preview
                                    const reader = new FileReader();
                                    reader?.addEventListener("load", () => setUserImagePreview(reader?.result));
                                    reader?.readAsDataURL(e?.target?.files[0]);
                                    //for image preview

                                    props?.setFieldValue('image', e?.target?.files[0])
                                  }}
                                  onBlur={() => props?.setFieldTouched('image', true)}
                                  error={(props?.touched?.image && props?.errors?.image) || invalidImage}
                                />
                                {/* <CRow>
                                  {isDisable ? <CCol>A</CCol> : <CCol>B</CCol>}
                                </CRow> */}
                                {/* for image preview */}
                                {userImagePreview?.length > 0 ?
                                  <>
                                    <CCol>
                                      <CImage
                                        rounded
                                        thumbnail
                                        // className="mt-2"
                                        src={`${userImagePreview}`}
                                        style={{
                                          maxHeight: '80px',
                                          maxWidth: '80px',
                                          alignItems: 'center',
                                        }}
                                      />
                                    </CCol>
                                  </>
                                  : ''}
                                {/* for image preview */}
                              </CCol>
                              {/* <input type="file"
                                hidden id="image"
                              />
                              <label htmlFor="image">Select File</label> */}
                            </CRow>
                            <CRow xs={12}>
                              <CCol md={6}>
                                {/* <PhoneInput placeholder="Enter phone number" value={value} onChange={setValue} /> */}
                              </CCol>
                              <CCol md={6} className="mt-2">
                                {imageUrl && (
                                  <CImage
                                    rounded
                                    thumbnail
                                    src={ImageUrl + state?.image}
                                    style={{
                                      maxHeight: '80px',
                                      maxWidth: '80px',
                                      alignItems: 'center',
                                    }}
                                  />
                                )}
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol md="2">
                                <button type="submit" className={'btn btn-success mt-2'} disabled={!isDisable || props?.isSubmitting}>Submit</button>
                                {/* {isDisable == true ? 
                            <button type="submit"className={'btn btn-success mt-2'}disabled={props?.isSubmitting}>Submit</button>
                             : <button  type="submit" className={'btn btn-success mt-2'}disabled={true}>Submit</button>} */}

                              </CCol>
                              <CCol md="2">
                                <button
                                  type="button"
                                  className={'btn btn-warning mt-2'}
                                  onClick={() => navigate('/admin/user')}
                                >
                                  Cancel
                                </button>
                              </CCol>
                              <CCol md="3"></CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer >



      {/* <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md={8}>
              <CCardGroup>
                <CCard className="p-4">
                  <CCardBody>
                    <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                    <h1>Edit User</h1>
                    <Formik
                      innerRef={formRef}
                      initialValues={{
                        // id:state?._id,
                        username: '',
                        email: '',
                        image: '',
                        mobile: '',
                      }}
                      validationSchema={edit}
                      onSubmit={(values, { setSubmitting }) => {
                        submit(values)
                        setSubmitting(false)
                      }}
                    >
                      {(props) => (
                        <form>
                          <DefaultInput
                            type={'text'}
                            placeholder={'User Name'}
                            name={'Name'}
                            id={'username'}
                            value={props?.values?.username}
                            onChange={props?.handleChange('username')}
                            onBlur={() => props?.setFieldTouched('username', true)}
                            error={props?.touched?.username && props?.errors?.username}
                          />
                          <DefaultInput
                            type={'email'}
                            placeholder={'Email'}
                            name={'Email'}
                            id={'email'}
                            disabled
                            value={props?.values?.email}
                            onChange={props?.handleChange('email')}
                            onBlur={() => props?.setFieldTouched('email', true)}
                            error={props?.touched?.email && props?.errors?.email}
                          />
                          <DefaultMobileInput
                            type={'text'}
                            placeholder={'Mobile'}
                            name={'mobile'}
                            id={'mobile'}
                            value={props?.values?.mobile}
                            onChange={(val) => props?.setFieldValue('mobile', val || '')}
                            onBlur={() => props?.setFieldTouched('mobile', true)}
                            error={props?.touched?.mobile && props?.errors?.mobile}
                          />
                          <DefaultInput
                            type={'file'}
                            placeholder={'Image'}
                            name={'image'}
                            accept="image/*"
                            refr={fileRef}
                            onChange={(e) => {
                              props?.setFieldValue('image', e?.target?.files[0])
                            }}
                            onBlur={() => props?.setFieldTouched('image', true)}
                            error={props?.touched?.image && props?.errors?.image}
                          />
                          <CRow>
                            <div
                              style={{
                                marginTop: '10px',
                                justifyContent: 'center',
                              }}
                            >
                              {imageUrl && (
                                <CImage
                                  rounded
                                  thumbnail
                                  src={ImageUrl + state?.image}
                                  style={{
                                    maxHeight: '80px',
                                    maxWidth: '80px',
                                    alignItems: 'center',
                                  }}
                                />
                              )}
                            </div>
                            <CCol md="2">
                              <button
                                type="button"
                                onClick={props?.handleSubmit}
                                className={'btn btn-success mt-2'}
                                disabled={props?.isSubmitting}
                              >
                                Submit
                              </button>
                            </CCol>
                            <CCol md="2">
                              <button
                                type="button"
                                className={'btn btn-warning mt-2'}
                                onClick={() => navigate('/admin/user')}
                              >
                                Go Back
                              </button>
                            </CCol>
                            <CCol md="3"></CCol>
                          </CRow>
                        </form>
                      )}
                    </Formik>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div> */}
    </>
  )
}
