import React, { useEffect, useRef, useState } from 'react'
import { Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { post, get, put } from 'src/utils/apiManager'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CFormLabel,
  CRow,
  CImage,
} from '@coreui/react'
import { DefaultInput, DefaultMobileInput, DefaultTextarea } from 'src/components/common/input'
import { settingsData } from 'src/utils/validator'
import { Ckeditor } from '../Template/Ckeditor'
import ImageCrop from './ImageCrop';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

function index(props) {
  const navigate = useNavigate()
  const formRef = useRef(null)
  const fileRef = useRef(null)
  const deliveryBannerFileRef = useRef(null)
  const [metaDescription, setMetaDescription] = useState([])
  const [imageUrl, setImageUrl] = useState('')
  const [bannerImageUrl, setBannerImageUrl] = useState('')
  const [deliveryBannerImageUrl, setDeliveryBannerImageUrl] = useState('')
  const [bannerImageUrlNew, setBannerImageUrlNew] = useState('')
  const [regularImageUrl, setRegularImageUrl] = useState('')
  const [packageImageUrl, setPackageImageUrl] = useState('')
  const [packageImageENUrl, setPackageImageENUrl] = useState('')
  const [packageImageTHUrl, setPackageImageTHUrl] = useState('')
  const [diveryBannerImagePreview, setDeliveryBannerImagePreview] = useState("");
  const [howToUseServiceImageENUrl, setHowToUseServiceImageENUrl] = useState('')
  const [howToUseServiceImageTHUrl, setHowToUseServiceImageTHUrl] = useState('')
  const [placeOrderImageENUrl, setPlaceOrderImageENUrl] = useState('')
  const [placeOrderImageTHUrl, setPlaceOrderImageTHUrl] = useState('')
  const [iD, setID] = useState('')
  const [isDisable, setIsDisable] = useState(false)
  const [invalidImage, setInvalidImage] = useState('')
  const [invalidBannerImage, setInvalidBannerImage] = useState('')
  const [invalidBannerImageNew, setInvalidBannerImageNew] = useState('')
  const [invalidDeliveryBannerImageNew, setInvalidDeliveryBannerImageNew] = useState('')
  const [invalidRegularImage, setInvalidRegularImage] = useState('')
  const [invalidPackageImage, setInvalidPackageImage] = useState('')
  const [invalidPackageImageEN, setInvalidPackageImageEN] = useState('')
  const [invalidPackageImageTH, setInvalidPackageImageTH] = useState('')
  const [invalidHowToUseServiceImageEN, setInvalidHowToUseServiceImageEN] = useState('')
  const [invalidHowToUseServiceImageTH, setInvalidHowToUseServiceImageTH] = useState('')
  const [invalidPlaceOrderImageEN, setInvalidPlaceOrderImageEN] = useState('')
  const [invalidPlaceOrderImageTH, setInvalidPlaceOrderImageTH] = useState('')
  const state = {}
  const [bannerImage, setBannerImage] = useState(null);
  const [bannerImageBlob, setBannerImageBlob] = useState(null);
  const [croppedBannerImage, setCroppedBannerImage] = useState("");
  const [open, setOpen] = useState(true);
  const [logoPreview, setLogoPreview] = useState();
  const [placeOrderENImagePreview, setPlaceOrderENImagePreview] = useState();
  const [placeOrderTHImagePreview, setPlaceOrderTHImagePreview] = useState();
  const [howUseENImagePreview, setHowUseENImagePreview] = useState();
  const [howUseTHImagePreview, setHowUseTHImagePreview] = useState();
  const [plan1ImagePreview, setPlan1ImagePreview] = useState();
  const [plan2ImagePreview, setPlan2ImagePreview] = useState();
  const [plan3ImagePreview, setPlan3ImagePreview] = useState();
  const [packageImageENPreview, setPackageImageENPreview] = useState();
  const [packageImageTHPreview, setPackageImageTHPreview] = useState();
  const [bannerPreview, setBannerPreview] = useState();
  const [nextpickuptime, setNextPickupTime] = React.useState(null);

  // const state = {
  // 	socialAddress: [{socialImage: "", socialLink: ""}]
  // };
  const handleClickOpen = () => {
    setOpen(true);
  };
  // console.log(imageBlog);
  const handleClose = (type) => {
    console.log(type, "image type")
    setOpen(false);

    if (type.toLowerCase() === "image") {
      setBannerImage(null);
    }
    //  else {
    //   setBackgroundImage(null);
    // }
  };
  const handleDoneClose = (croppedImageUrl, type) => {
    // if (type.toLowerCase() === "image") {
    setCroppedBannerImage(croppedImageUrl);
    setBannerImage(null);
    // }
    // else {
    // setCroppedBannerImage(croppedImageUrl);
    //   setBackgroundImage(null);
    // }
    setOpen(false);
  };


  useEffect(() => {
    getSettings()
  }, [])
  // after getting id for edit section
  const getSettings = () => {
    get(`/admin/global/global-setting`).then((response) => {
      if (response?.status == true) {
        handleSingleData(response)
      }
    })
  }
  const handleSingleData = (response) => {
    // console.log(response?.data)
    if (!response?.status) toast.success(response?.message, response?.status)
    formRef.current.setFieldValue('title', response?.data?.title)
    formRef.current.setFieldValue('slug', response?.data?.slug)
    formRef.current.setFieldValue('metaTitle', response?.data?.metaTitle)
    formRef.current.setFieldValue('email', response?.data?.email)
    formRef.current.setFieldValue('home_title_aboutUs_EN', response?.data?.home_title_aboutUs_EN)
    formRef.current.setFieldValue('home_title_aboutUs_TH', response?.data?.home_title_aboutUs_TH)
    formRef.current.setFieldValue('mobile', response?.data?.mobile)
    formRef.current.setFieldValue('address', response?.data?.address)
    formRef.current.setFieldValue('footerDescription_EN', response?.data?.footerDescription_EN)
    formRef.current.setFieldValue('footerDescription_TH', response?.data?.footerDescription_TH)
    formRef.current.setFieldValue('metaDescription', response?.data?.metaDescription)
    formRef.current.setFieldValue('googlePlayStore', response?.data?.googlePlayStore)
    formRef.current.setFieldValue('appStore', response?.data?.appStore)
    formRef.current.setFieldValue('refer_user_point', response?.data?.refer_user_point)
    formRef.current.setFieldValue('referral_user_point', response?.data?.referral_user_point)
    formRef.current.setFieldValue('regularTitle_EN', response?.data?.regularTitle_EN)
    formRef.current.setFieldValue('regularTitle_TH', response?.data?.regularTitle_TH)
    formRef.current.setFieldValue('regularSubTitle_EN', response?.data?.regularSubTitle_EN)
    formRef.current.setFieldValue('regularSubTitle_TH', response?.data?.regularSubTitle_TH)
    formRef.current.setFieldValue('packageTitle_EN', response?.data?.packageTitle_EN)
    formRef.current.setFieldValue('packageTitle_TH', response?.data?.packageTitle_TH)
    formRef.current.setFieldValue('packageSubTitle_EN', response?.data?.packageSubTitle_EN)
    formRef.current.setFieldValue('packageSubTitle_TH', response?.data?.packageSubTitle_TH)
    formRef.current.setFieldValue('startPickUpTime', response?.data?.startPickUpTime)
    formRef.current.setFieldValue('endPickUpTime', response?.data?.endPickUpTime)
    formRef.current.setFieldValue('nextPickupTime', response?.data?.nextPickupTime)
    formRef.current.setFieldValue('packageItemOff', response?.data?.packageItemOff)
    formRef.current.setFieldValue('deliveryFee', response?.data?.deliveryFee)
    formRef.current.setFieldValue('damageItemTime', response?.data?.damageItemTime)
    formRef.current.setFieldValue('minimumOrderPrice', response?.data?.minimumOrderPrice)
    formRef.current.setFieldValue('deliveryStartTime', response?.data?.deliveryStartTime)
    formRef.current.setFieldValue('deliveryEndTime', response?.data?.deliveryEndTime)

    setNextPickupTime(response?.data?.nextPickupTime)

    if (response?.data?.logoImage) setImageUrl(`${response.imageUrl}${response?.data?.logoImage}`)
    if (response?.data?.bannerImage)
      setBannerImageUrl(`${response.imageUrl}${response?.data?.bannerImage}`)
    if (response?.data?.feactoryLaundryClosed)
      setBannerImageUrlNew(`${response.imageUrl}${response?.data?.feactoryLaundryClosed}`)
    if (response?.data?.regularImage)
      setRegularImageUrl(`${response.imageUrl}${response?.data?.regularImage}`)
    if (response?.data?.packageImage)
      setPackageImageUrl(`${response.imageUrl}${response?.data?.packageImage}`)
    if (response?.data?.packageImageEN)
      setPackageImageENUrl(`${response.imageUrl}${response?.data?.packageImageEN}`)
    if (response?.data?.packageImageTH)
      setPackageImageTHUrl(`${response.imageUrl}${response?.data?.packageImageTH}`)
    if (response?.data?.how_to_use_our_service_imageEN)
      setHowToUseServiceImageENUrl(`${response.imageUrl}${response?.data?.how_to_use_our_service_imageEN}`)
    if (response?.data?.how_to_use_our_service_imageTH)
      setHowToUseServiceImageTHUrl(`${response.imageUrl}${response?.data?.how_to_use_our_service_imageTH}`)
    if (response?.data?.deliveryBanner) {
      setDeliveryBannerImagePreview(`${response.imageUrl}${response?.data?.deliveryBanner}`)
    }
    if (response?.data?.place_order_imageEN)
      setPlaceOrderImageENUrl(`${response.imageUrl}${response?.data?.place_order_imageEN}`)
    if (response?.data?.place_order_imageTH)
      setPlaceOrderImageTHUrl(`${response.imageUrl}${response?.data?.place_order_imageTH}`)
    if (response?.data?._id) setID(`${response?.data?._id}`)
  }

  const submit = async (values) => {
    // console.log(values?.refer_user_point <= 0, values.refer_user_point >= 100, "picup")
    if (values?.startPickUpTime <= 0 || values?.endPickUpTime <= 0 ||
      (values?.refer_user_point < 0 || values.refer_user_point >= 100) ||
      (values?.referral_user_point < 0 || values?.referral_user_point >= 100)
    ) {
      // console.log("enter in iff")
      toast.error("Enter correct values")

      values?.startPickUpTime <= 0 ?
        formRef.current.setFieldValue('startPickUpTime', '')
        : values?.endPickUpTime <= 0
          ? formRef.current.setFieldValue('endPickUpTime', '')
          : (values?.refer_user_point < 0 || values.refer_user_point >= 100)
            ? formRef.current.setFieldValue('refer_user_point', '')
            : (values?.referral_user_point < 0 || values?.referral_user_point >= 100)
              ? formRef.current.setFieldValue('referral_user_point', '')
              : ''
    } else {
      let data = {
        id: iD,
        title: values?.title,
        slug: values?.slug,
        logoImage: values?.logoImage,
        // bannerImage: values?.bannerImage,
        bannerImage: bannerImageBlob,
        metaTitle: values?.metaTitle,
        email: values?.email,
        home_title_aboutUs_EN: values?.home_title_aboutUs_EN,
        home_title_aboutUs_TH: values?.home_title_aboutUs_TH,
        mobile: values?.mobile,
        address: values?.address,
        footerDescription_EN: values?.footerDescription_EN,
        footerDescription_TH: values?.footerDescription_TH,
        metaDescription: values?.metaDescription,
        googlePlayStore: values?.googlePlayStore,
        appStore: values?.appStore,
        refer_user_point: values?.refer_user_point,
        referral_user_point: values?.referral_user_point,
        regularTitle_EN: values?.regularTitle_EN,
        regularTitle_TH: values?.regularTitle_TH,
        regularSubTitle_EN: values?.regularSubTitle_EN,
        regularSubTitle_TH: values?.regularSubTitle_TH,
        packageTitle_EN: values?.packageTitle_EN,
        packageTitle_TH: values?.packageTitle_TH,
        packageSubTitle_EN: values?.packageSubTitle_EN,
        packageSubTitle_TH: values?.packageSubTitle_TH,
        regularImage: values?.regularImage,
        packageImage: values?.packageImage,
        packageImageEN: values?.packageImageEN,
        packageImageTH: values?.packageImageTH,
        how_to_use_our_service_imageEN: values?.how_to_use_our_service_imageEN,
        how_to_use_our_service_imageTH: values?.how_to_use_our_service_imageTH,
        place_order_imageEN: values?.place_order_imageEN,
        place_order_imageTH: values?.place_order_imageTH,
        startPickUpTime: values?.startPickUpTime,
        endPickUpTime: values?.endPickUpTime,
        nextPickupTime: values?.nextPickupTime,
        feactoryLaundryClosed: values?.bannerImageNew,
        deliveryFee: values?.deliveryFee,
        packageItemOff: values?.packageItemOff,
        damageItemTime: values?.damageItemTime,
        minimumOrderPrice: values?.minimumOrderPrice,
        deliveryStartTime: values?.deliveryStartTime,
        deliveryEndTime: values?.deliveryEndTime,
        deliveryBanner: values?.deliveryBanner
      }
      if (iD) {
        put(`admin/global/update-setting`, data, 1).then((response) => {
          handleResponse(response)
        })
      } else {
        post(`admin / global / add - setting`, data, 1).then((response) => {
          handleResponse(response)
        })
      }
    }
  }
  const handleResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      getSettings();
    }
  }

  return (
    <>
      <CContainer>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <h1>Global Settings</h1>
                      <Formik
                        initialValues={{
                          title: '',
                          slug: '',
                          logoImage: '',
                          bannerImage: '',
                          bannerImageNew: '',
                          metaTitle: '',
                          email: '',
                          mobile: '',
                          address: '',
                          footerDescription_EN: '',
                          footerDescription_TH: '',
                          metaDescription: '',
                          googlePlayStore: '',
                          appStore: '',
                          refer_user_point: '',
                          referral_user_point: '',
                          regularTitle_EN: '',
                          regularTitle_TH: '',
                          regularSubTitle_EN: '',
                          regularSubTitle_TH: '',
                          regularImage: '',
                          packageTitle_EN: '',
                          packageTitle_TH: '',
                          packageSubTitle_EN: '',
                          packageSubTitle_TH: '',
                          packageImage: '',
                          packageImageEN: '',
                          packageImageTH: '',
                          how_to_use_our_service_imageEN: '',
                          how_to_use_our_service_imageTH: '',
                          place_order_imageEN: '',
                          place_order_imageTH: '',
                          startPickUpTime: '',
                          deliveryStartTime: '',
                          deliveryEndTime: '',
                          nextPickupTime: '',
                          packageItemOff: "",
                          deliveryFee: "",
                          damageItemTime: "",
                          minimumOrderPrice: "",
                          deliveryBanner: ""
                        }}
                        validationSchema={settingsData}
                        onSubmit={(values, { setSubmitting }) => {
                          submit(values)
                          setSubmitting(false)
                        }}
                        innerRef={formRef}
                      >
                        {(props) => (
                          <form onSubmit={props?.handleSubmit}>
                            {/* {console.log(props)} */}
                            <input type="hidden" name="hidden" value={iD} />
                            <CRow>
                              <CCol md={6}>
                                <CFormLabel>Title</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Title'}
                                  // name={'Title'}
                                  id={'title'}
                                  value={props?.values?.title}
                                  maxLength="80"
                                  onChange={props?.handleChange('title')}
                                  onBlur={() => props?.setFieldTouched('title', true)}
                                  error={props?.touched?.title && props?.errors?.title}
                                  onKeyUp={() => {
                                    if (state?.title == props?.values?.title) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                              </CCol>
                              <CCol md={6}>
                                <CFormLabel>Slug</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Slug'}
                                  // name={'Slug'}
                                  id={'slug'}
                                  maxLength="80"
                                  value={props?.values?.slug}
                                  onChange={props?.handleChange('slug')}
                                  onBlur={() => props?.setFieldTouched('slug', true)}
                                  error={props?.touched?.slug && props?.errors?.slug}
                                  onKeyUp={() => {
                                    if (state?.slug == props?.values?.slug) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                              </CCol>
                              <CCol md={6}>
                                <CFormLabel>Meta Title</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Meta Title'}
                                  // name={'Meta Title'}
                                  maxLength="80"
                                  id={'metaTitle'}
                                  value={props?.values?.metaTitle}
                                  onChange={props?.handleChange('metaTitle')}
                                  onBlur={() => props?.setFieldTouched('metaTitle', true)}
                                  error={props?.touched?.metaTitle && props?.errors?.metaTitle}
                                  onKeyUp={() => {
                                    if (state?.metaTitle == props?.values?.metaTitle)
                                      setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                              </CCol>
                              <CCol md={6}>
                                <CFormLabel>Email</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'email'}
                                  placeholder={'Email'}
                                  // name={'Email'}
                                  id={'email'}
                                  value={props?.values?.email}
                                  onChange={props?.handleChange('email')}
                                  onBlur={() => props?.setFieldTouched('email', true)}
                                  error={props?.touched?.email && props?.errors?.email}
                                  onKeyUp={() => {
                                    if (state?.email == props?.values?.email) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                              </CCol>
                              <CCol md={6}>
                                <CFormLabel>Home Title(EN)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Home Title'}
                                  // name={'Home Title(EN)'}
                                  id={'home_title_aboutUs_EN'}
                                  value={props?.values?.home_title_aboutUs_EN}
                                  onChange={props?.handleChange('home_title_aboutUs_EN')}
                                  onBlur={() =>
                                    props?.setFieldTouched('home_title_aboutUs_EN', true)
                                  }
                                  error={
                                    props?.touched?.home_title_aboutUs_EN &&
                                    props?.errors?.home_title_aboutUs_EN
                                  }
                                  onKeyUp={() => {
                                    if (
                                      state?.home_title_aboutUs_EN ==
                                      props?.values?.home_title_aboutUs_EN
                                    )
                                      setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                              </CCol>
                              <CCol md={6}>
                                <CFormLabel>Home Title(TH)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Home Title'}
                                  // name={'Home Title(TH)'}
                                  id={'home_title_aboutUs_TH'}
                                  value={props?.values?.home_title_aboutUs_TH}
                                  onChange={props?.handleChange('home_title_aboutUs_TH')}
                                  onBlur={() =>
                                    props?.setFieldTouched('home_title_aboutUs_TH', true)
                                  }
                                  error={
                                    props?.touched?.home_title_aboutUs_TH &&
                                    props?.errors?.home_title_aboutUs_TH
                                  }
                                  onKeyUp={() => {
                                    if (
                                      state?.home_title_aboutUs_TH ==
                                      props?.values?.home_title_aboutUs_TH
                                    )
                                      setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                              </CCol>
                              <CCol md={6}>
                                <DefaultMobileInput
                                  type={'text'}
                                  placeholder={'Mobile'}
                                  name={'Mobile'}
                                  id={'mobile'}
                                  value={props?.values?.mobile}
                                  onChange={(val) => props?.setFieldValue('mobile', val || '')}
                                  onBlur={() => props?.setFieldTouched('mobile', true)}
                                  error={props?.touched?.mobile && props?.errors?.mobile}
                                  style={{ border: "none" }}
                                  onKeyUp={() => {
                                    if (state?.mobile == props?.values?.mobile) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                              </CCol>
                              <CCol md={6}>
                                <DefaultTextarea
                                  placeholder={'Address'}
                                  name={'Address'}
                                  id={'address'}
                                  maxLength={50}
                                  value={props?.values?.address}
                                  onChange={props?.handleChange('address')}
                                  onBlur={() => props?.setFieldTouched('address', true)}
                                  error={props?.touched?.address && props?.errors?.address}
                                  onKeyUp={() => {
                                    if (state?.address == props?.values?.address) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                              </CCol>
                              <CCol md={6}>
                                <CFormLabel>Image</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'file'}
                                  placeholder={'Image'}
                                  // name={'Image'}
                                  accept="image/*"
                                  refr={fileRef}
                                  onChange={(e) => {
                                    const imageFile = e?.target?.files[0]
                                    if (imageUrl == imageFile.name) setIsDisable(false)
                                    else setIsDisable(true)
                                    if (!imageFile?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
                                      setInvalidImage('Please select valid image')
                                    } else { setInvalidImage('') }
                                    //for image preview
                                    const reader = new FileReader();
                                    reader?.addEventListener("load", () => setLogoPreview(reader?.result));
                                    reader?.readAsDataURL(e?.target?.files[0]);
                                    //for image preview
                                    props?.setFieldValue('logoImage', e?.target?.files[0])
                                  }}
                                  onBlur={() => props?.setFieldTouched('logoImage', true)}
                                  error={
                                    (props?.touched?.logoImage && props?.errors?.logoImage) ||
                                    invalidImage
                                  }
                                />
                                {/* for image preview */}
                                {logoPreview?.length > 0 ?
                                  <>
                                    <CCol>
                                      <a target='_blank' href={`${logoPreview}`}>
                                        <CImage
                                          rounded
                                          thumbnail
                                          className="mt-2"
                                          src={`${logoPreview}`}
                                          style={{
                                            maxHeight: '80px',
                                            maxWidth: '80px',
                                            alignItems: 'center',
                                          }}
                                        />
                                      </a>
                                    </CCol>
                                  </>
                                  : ''}
                                {/* for image preview */}
                                {/* for backend image preview */}
                                {imageUrl && (
                                  <a target='_blank' href={`${imageUrl}`}>
                                    <CImage
                                      rounded
                                      thumbnail
                                      className="mt-2"
                                      src={`${imageUrl}`}
                                      style={{
                                        maxHeight: '80px',
                                        maxWidth: '80px',
                                        alignItems: 'center',
                                      }}
                                    />
                                  </a>
                                )}
                                {/* for backend image preview */}
                              </CCol>
                              <CCol md={6}>
                                <CFormLabel>Banner Image</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'file'}
                                  placeholder={'Image'}
                                  // name={'Banner Image'}
                                  accept="image/png"
                                  refr={fileRef}
                                  onChange={(e) => {
                                    setBannerImage("")
                                    const imageFile = e?.target?.files[0]
                                    if (imageUrl == imageFile.name) setIsDisable(false)
                                    else setIsDisable(true)
                                    if (!imageFile?.name?.match(/\.(png)$/)) {
                                      setInvalidBannerImage('You can upload only png image')
                                    } else {
                                      setInvalidBannerImage('')
                                    }
                                    if (e?.target?.files && e?.target?.files.length > 0) {
                                      const reader = new FileReader();
                                      reader?.addEventListener("load", () => setBannerImage(reader?.result));
                                      reader?.readAsDataURL(e?.target?.files[0]);
                                    }
                                    props?.setFieldValue('bannerImage', e?.target?.files[0])
                                    setOpen(true)
                                  }}
                                  onBlur={() => props?.setFieldTouched('bannerImage', true)}
                                  error={
                                    (props?.touched?.bannerImage && props?.errors?.bannerImage) ||
                                    invalidBannerImage
                                  }
                                />
                                {/* for image preview */}
                                {/* {console.log(croppedBannerImage)} */}
                                {croppedBannerImage && (
                                  <>
                                    <CCol>
                                      <a target='_blank' href={`${croppedBannerImage}`}>
                                        <CImage
                                          rounded
                                          thumbnail
                                          className="mt-2"
                                          src={`${croppedBannerImage}`}
                                          style={{
                                            maxHeight: '80px',
                                            maxWidth: '80px',
                                            alignItems: 'center',
                                          }}
                                        />
                                      </a>
                                    </CCol>
                                  </>
                                )
                                }
                                {/* for image preview */}

                                {/* for backend image preview */}
                                {bannerImageUrl && (
                                  <a target='_blank' href={`${bannerImageUrl}`}>
                                    <CImage
                                      rounded
                                      thumbnail
                                      className="mt-2"
                                      src={`${bannerImageUrl}`}
                                      style={{
                                        maxHeight: '80px',
                                        maxWidth: '80px',
                                        alignItems: 'center',
                                      }}
                                    />
                                  </a>
                                )}
                                {/* for backend image preview */}

                                {/* Image Upload crop code start here */}

                                {/* {console.log(props?.values?.bannerImage)} */}
                                {open && bannerImage && (
                                  <ImageCrop
                                    type="image"
                                    file={bannerImage}
                                    // imageValue={thisUser.image}
                                    setImage={setBannerImage}
                                    handleClickOpen={handleClickOpen}
                                    handleClose={handleClose}
                                    open={open}
                                    setOpen={setOpen}
                                    handleDoneClose={handleDoneClose}
                                    setBannerImageBlob={setBannerImageBlob}
                                  />
                                )}

                                {/* Image Upload crop code start here */}
                              </CCol>
                              <CCol md={6}>
                                <DefaultTextarea
                                  placeholder={'Footer Description EN'}
                                  name={'Footer Description (EN)'}
                                  id={'footerDescription_EN'}
                                  maxLength={200}
                                  value={props?.values?.footerDescription_EN}
                                  onChange={props?.handleChange('footerDescription_EN')}
                                  onBlur={() => props?.setFieldTouched('footerDescription_EN', true)}
                                  error={
                                    props?.touched?.footerDescription_EN &&
                                    props?.errors?.footerDescription_EN
                                  }
                                  onKeyUp={() => {
                                    if (
                                      state?.footerDescription_EN ==
                                      props?.values?.footerDescription_EN
                                    )
                                      setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                                <CFormLabel>Meta Description</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>
                                <Ckeditor
                                  // setDescription={setMetaDescription}
                                  // metaDescription={metaDescription}
                                  value={props?.values?.metaDescription}
                                  onChange={(val) => {
                                    setMetaDescription(val)
                                    if (metaDescription.length <= 0) setIsDisable(false)
                                    else setIsDisable(true)
                                    //  console.log(val,"DESCRIPT");
                                    props?.setFieldValue('metaDescription', val)
                                  }}
                                  onBlur={() => props?.setFieldTouched('metaDescription', true)}
                                  error={
                                    props?.touched?.metaDescription && props?.errors?.metaDescription
                                  }
                                />
                              </CCol>
                              <CCol md={6}>
                                <DefaultTextarea
                                  placeholder={'Footer Description TH'}
                                  name={'Footer Description (TH)'}
                                  id={'footerDescription_TH'}
                                  maxLength={200}
                                  value={props?.values?.footerDescription_TH}
                                  onChange={props?.handleChange('footerDescription_TH')}
                                  onBlur={() => props?.setFieldTouched('footerDescription_TH', true)}
                                  error={
                                    props?.touched?.footerDescription_TH &&
                                    props?.errors?.footerDescription_TH
                                  }
                                  onKeyUp={() => {
                                    if (
                                      state?.footerDescription_TH ==
                                      props?.values?.footerDescription_TH
                                    )
                                      setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                                <CFormLabel>Google Playstore</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Google Playstore'}
                                  // name={'Google Playstore '}
                                  value={props?.values?.googlePlayStore}
                                  onChange={props?.handleChange('googlePlayStore')}
                                  onBlur={() => props?.setFieldTouched('googlePlayStore', true)}
                                  error={
                                    props?.touched?.googlePlayStore && props?.errors?.googlePlayStore
                                  }
                                  onKeyUp={() => {
                                    if (state?.googlePlayStore == props?.values?.googlePlayStore)
                                      setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                                <CFormLabel>App Store</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'App Store'}
                                  // name={'App Store '}
                                  value={props?.values?.appStore}
                                  onChange={props?.handleChange('appStore')}
                                  onBlur={() => props?.setFieldTouched('appStore', true)}
                                  error={props?.touched?.appStore && props?.errors?.appStore}
                                  onKeyUp={() => {
                                    if (state?.appStore == props?.values?.appStore) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                              </CCol>
                              <CCol md="6"></CCol>
                              <CCol md="6"></CCol>
                            </CRow>
                            <hr />
                            <CFormLabel> <strong>For Mobile Use</strong></CFormLabel>
                            <CRow>
                              <CCol md={6}>
                                <CFormLabel>Refer User Point</CFormLabel>
                                <DefaultInput
                                  type={"number"}
                                  placeholder={'Refer User Points'}
                                  // name={'App Store '}
                                  value={props?.values?.refer_user_point}
                                  onChange={props?.handleChange('refer_user_point')}
                                  onBlur={() => props?.setFieldTouched('refer_user_point', true)}
                                  error={props?.touched?.refer_user_point && props?.errors?.refer_user_point}
                                  onKeyUp={() => {
                                    if (state?.refer_user_point == props?.values?.refer_user_point) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                                <CFormLabel>How To Use Our Service Image (EN)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'file'}
                                  placeholder={'Image'}
                                  // name={'How To Use Our Service Image (EN)'}
                                  accept="image/*"
                                  refr={fileRef}
                                  onChange={(e) => {
                                    const imageFile = e?.target?.files[0]
                                    // console.log(imageFile)
                                    if (howToUseServiceImageENUrl == imageFile.name) setIsDisable(false)
                                    else setIsDisable(true)
                                    if (!imageFile?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
                                      setInvalidHowToUseServiceImageEN('Please select valid image')
                                    } else {
                                      setInvalidHowToUseServiceImageEN('')
                                    }
                                    //for image preview
                                    const reader = new FileReader();
                                    reader?.addEventListener("load", () => setHowUseENImagePreview(reader?.result));
                                    reader?.readAsDataURL(e?.target?.files[0]);
                                    //for image preview
                                    props?.setFieldValue('how_to_use_our_service_imageEN', e?.target?.files[0])
                                  }}
                                  onBlur={() => props?.setFieldTouched('how_to_use_our_service_imageEN', true)}
                                  error={
                                    (props?.touched?.how_to_use_our_service_imageEN && props?.errors?.how_to_use_our_service_imageEN) ||
                                    invalidHowToUseServiceImageEN
                                  }
                                />
                                {/* for image preview */}
                                {howUseENImagePreview?.length > 0 ?
                                  <>
                                    <CCol>
                                      <a target='_blank' href={`${howUseENImagePreview}`}>
                                        <CImage
                                          rounded
                                          thumbnail
                                          className="mt-2"
                                          src={`${howUseENImagePreview}`}
                                          style={{
                                            maxHeight: '80px',
                                            maxWidth: '80px',
                                            alignItems: 'center',
                                          }}
                                        />
                                      </a>
                                    </CCol>
                                  </>
                                  : ''}
                                {/* for image preview */}
                                {howToUseServiceImageENUrl && (
                                  <a target='_blank' href={`${howToUseServiceImageENUrl}`}>
                                    <CImage
                                      rounded
                                      thumbnail
                                      className="mt-2"
                                      src={`${howToUseServiceImageENUrl}`}
                                      style={{
                                        maxHeight: '80px',
                                        maxWidth: '80px',
                                        alignItems: 'center',
                                      }}
                                    />
                                  </a>
                                )}
                              </CCol>
                              <CCol md={6}>
                                <CFormLabel>Referral User Points</CFormLabel>
                                <DefaultInput
                                  type={"number"}
                                  placeholder={'Referral User Points'}
                                  value={props?.values?.referral_user_point}
                                  onChange={props?.handleChange('referral_user_point')}
                                  onBlur={() => props?.setFieldTouched('referral_user_point', true)}
                                  error={props?.touched?.referral_user_point && props?.errors?.referral_user_point}
                                  onKeyUp={() => {
                                    if (state?.referral_user_point == props?.values?.referral_user_point) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                                <CFormLabel>How To Use Our Service Image (TH)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'file'}
                                  placeholder={'Image'}
                                  // name={'How To Use Our Service Image (TH)'}
                                  accept="image/*"
                                  refr={fileRef}
                                  onChange={(e) => {
                                    const imageFile = e?.target?.files[0]
                                    // console.log(imageFile)
                                    if (howToUseServiceImageTHUrl == imageFile.name) setIsDisable(false)
                                    else setIsDisable(true)
                                    if (!imageFile?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
                                      setInvalidHowToUseServiceImageTH('Please select valid image')
                                    } else {
                                      setInvalidHowToUseServiceImageTH('')
                                    }
                                    //for image preview
                                    const reader = new FileReader();
                                    reader?.addEventListener("load", () => setHowUseTHImagePreview(reader?.result));
                                    reader?.readAsDataURL(e?.target?.files[0]);
                                    //for image preview
                                    props?.setFieldValue('how_to_use_our_service_imageTH', e?.target?.files[0])
                                  }}
                                  onBlur={() => props?.setFieldTouched('how_to_use_our_service_imageTH', true)}
                                  error={
                                    (props?.touched?.how_to_use_our_service_imageTH && props?.errors?.how_to_use_our_service_imageTH) ||
                                    invalidHowToUseServiceImageTH
                                  }
                                />
                                {/* for image preview */}
                                {howUseTHImagePreview?.length > 0 ?
                                  <>
                                    <CCol>
                                      <a target='_blank' href={`${howUseTHImagePreview}`}>
                                        <CImage
                                          rounded
                                          thumbnail
                                          className="mt-2"
                                          src={`${howUseTHImagePreview}`}
                                          style={{
                                            maxHeight: '80px',
                                            maxWidth: '80px',
                                            alignItems: 'center',
                                          }}
                                        />
                                      </a>
                                    </CCol>
                                  </>
                                  : ''}
                                {/* for image preview */}
                                {howToUseServiceImageTHUrl && (
                                  <a target='_blank' href={`${howToUseServiceImageTHUrl}`}>
                                    <CImage
                                      rounded
                                      thumbnail
                                      className="mt-2"
                                      src={`${howToUseServiceImageTHUrl}`}
                                      style={{
                                        maxHeight: '80px',
                                        maxWidth: '80px',
                                        alignItems: 'center',
                                      }}
                                    />
                                  </a>
                                )}
                              </CCol>
                              <CCol md={6}>
                                <CFormLabel>Place Order Image (EN)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  // value="123"
                                  type={'file'}
                                  placeholder={'Image'}
                                  // name={'Place Order Image (EN)'}
                                  accept="image/*"
                                  refr={fileRef}
                                  onChange={(e) => {
                                    const imageFile = e?.target?.files[0]
                                    // console.log(imageFile)
                                    if (placeOrderImageENUrl == imageFile.name) setIsDisable(false)
                                    else setIsDisable(true)
                                    if (!imageFile?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
                                      setInvalidPlaceOrderImageEN('Please select valid image')
                                    } else {
                                      setInvalidPlaceOrderImageEN('')
                                    }
                                    //for image preview
                                    const reader = new FileReader();
                                    reader?.addEventListener("load", () => setPlaceOrderENImagePreview(reader?.result));
                                    reader?.readAsDataURL(e?.target?.files[0]);
                                    //for image preview
                                    props?.setFieldValue('place_order_imageEN', e?.target?.files[0])
                                  }}
                                  onBlur={() => props?.setFieldTouched('place_order_imageEN', true)}
                                  error={
                                    (props?.touched?.place_order_imageEN && props?.errors?.place_order_imageEN) ||
                                    invalidHowToUseServiceImageEN
                                  }
                                />
                                {/* for image preview */}
                                {placeOrderENImagePreview?.length > 0 ?
                                  <>
                                    <CCol>
                                      <a target='_blank' href={`${placeOrderENImagePreview}`}>
                                        <CImage
                                          rounded
                                          thumbnail
                                          className="mt-2"
                                          src={`${placeOrderENImagePreview}`}
                                          style={{
                                            maxHeight: '80px',
                                            maxWidth: '80px',
                                            alignItems: 'center',
                                          }}
                                        />
                                      </a>
                                    </CCol>
                                  </>
                                  : ''}
                                {/* for image preview */}
                                {placeOrderImageENUrl && (
                                  <a target='_blank' href={`${placeOrderImageENUrl}`}>
                                    <CImage
                                      rounded
                                      thumbnail
                                      className="mt-2"
                                      src={`${placeOrderImageENUrl}`}
                                      style={{
                                        maxHeight: '80px',
                                        maxWidth: '80px',
                                        alignItems: 'center',
                                      }}
                                    />
                                  </a>
                                )}
                              </CCol>
                              <CCol md={6}>
                                <CFormLabel>Place Order Image (TH)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'file'}
                                  placeholder={'Image'}
                                  // name={'Place Order Image (TH)'}
                                  accept="image/*"
                                  refr={fileRef}
                                  onChange={(e) => {
                                    const imageFile = e?.target?.files[0]
                                    // console.log(imageFile)
                                    if (placeOrderImageTHUrl == imageFile.name) setIsDisable(false)
                                    else setIsDisable(true)
                                    if (!imageFile?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
                                      setInvalidPlaceOrderImageTH('Please select valid image')
                                    } else {
                                      setInvalidPlaceOrderImageTH('')
                                    }
                                    //for image preview
                                    const reader = new FileReader();
                                    reader?.addEventListener("load", () => setPlaceOrderTHImagePreview(reader?.result));
                                    reader?.readAsDataURL(e?.target?.files[0]);
                                    //for image preview
                                    props?.setFieldValue('place_order_imageTH', e?.target?.files[0])
                                  }}
                                  onBlur={() => props?.setFieldTouched('place_order_imageTH', true)}
                                  error={
                                    (props?.touched?.place_order_imageTH && props?.errors?.place_order_imageTH) ||
                                    invalidHowToUseServiceImageTH
                                  }
                                />
                                {/* for image preview */}
                                {placeOrderTHImagePreview?.length > 0 ?
                                  <>
                                    <CCol>
                                      <a target='_blank' href={`${placeOrderTHImagePreview}`}>
                                        <CImage
                                          rounded
                                          thumbnail
                                          className="mt-2"
                                          src={`${placeOrderTHImagePreview}`}
                                          style={{
                                            maxHeight: '80px',
                                            maxWidth: '80px',
                                            alignItems: 'center',
                                          }}
                                        />
                                      </a>
                                    </CCol>
                                  </>
                                  : ''}
                                {/* for image preview */}
                                {placeOrderImageTHUrl && (
                                  <a target='_blank' href={`${placeOrderImageTHUrl}`}>
                                    <CImage
                                      rounded
                                      thumbnail
                                      className="mt-2"
                                      src={`${placeOrderImageTHUrl}`}
                                      style={{
                                        maxHeight: '80px',
                                        maxWidth: '80px',
                                        alignItems: 'center',
                                      }}
                                    />
                                  </a>
                                )}
                              </CCol>


                              <CCol md={6}>
                                <CFormLabel>Start PickUp Time</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Start PickUp Time'}
                                  // name={'Start PickUp Time'}
                                  id={'startPickUpTime'}
                                  value={props?.values?.startPickUpTime}
                                  onChange={props?.handleChange('startPickUpTime')}
                                  onBlur={() => props?.setFieldTouched('startPickUpTime', true)}
                                  error={
                                    props?.touched?.startPickUpTime && props?.errors?.startPickUpTime
                                  }
                                />
                              </CCol>
                              <CCol md={6}>
                                <CFormLabel>End PickUp Time</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'End PickUp Time'}
                                  // name={'End PickUp Time'}
                                  id={'endPickUpTime'}
                                  value={props?.values?.endPickUpTime}
                                  onChange={props?.handleChange('endPickUpTime')}
                                  onBlur={() => props?.setFieldTouched('endPickUpTime', true)}
                                  error={
                                    props?.touched?.endPickUpTime && props?.errors?.endPickUpTime
                                  }
                                />
                              </CCol>
                              <CCol md={6}>
                                <CFormLabel>Package Image (EN)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  // value="123"
                                  type={'file'}
                                  placeholder={'Image'}
                                  // name={'Place Order Image (EN)'}
                                  accept="image/*"
                                  refr={fileRef}
                                  onChange={(e) => {
                                    const imageFile = e?.target?.files[0]
                                    // console.log(imageFile)
                                    if (packageImageENUrl == imageFile.name) setIsDisable(false)
                                    else setIsDisable(true)
                                    if (!imageFile?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
                                      setInvalidPackageImageEN('Please select valid image')
                                    } else {
                                      setInvalidPackageImageEN('')
                                    }
                                    //for image preview
                                    const reader = new FileReader();
                                    reader?.addEventListener("load", () => setPackageImageENPreview(reader?.result));
                                    reader?.readAsDataURL(e?.target?.files[0]);
                                    //for image preview
                                    props?.setFieldValue('packageImageEN', e?.target?.files[0])
                                  }}
                                  onBlur={() => props?.setFieldTouched('packageImageEN', true)}
                                  error={
                                    (props?.touched?.packageImageEN && props?.errors?.packageImageEN) ||
                                    invalidPackageImageEN
                                  }
                                />
                                {/* for image preview */}
                                {packageImageENPreview?.length > 0 ?
                                  <>
                                    <CCol>
                                      <a target='_blank' href={`${packageImageENPreview}`}>
                                        <CImage
                                          rounded
                                          thumbnail
                                          className="mt-2"
                                          src={`${packageImageENPreview}`}
                                          style={{
                                            maxHeight: '80px',
                                            maxWidth: '80px',
                                            alignItems: 'center',
                                          }}
                                        />
                                      </a>
                                    </CCol>
                                  </>
                                  : ''}
                                {/* for image preview */}
                                {packageImageENUrl && (
                                  <a target='_blank' href={`${packageImageENUrl}`}>
                                    <CImage
                                      rounded
                                      thumbnail
                                      className="mt-2"
                                      src={`${packageImageENUrl}`}
                                      style={{
                                        maxHeight: '80px',
                                        maxWidth: '80px',
                                        alignItems: 'center',
                                      }}
                                    />
                                  </a>
                                )}
                              </CCol>
                              <CCol md={6}>
                                <CFormLabel>Package Image (TH)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'file'}
                                  placeholder={'Image'}
                                  // name={'Place Order Image (TH)'}
                                  accept="image/*"
                                  refr={fileRef}
                                  onChange={(e) => {
                                    const imageFile = e?.target?.files[0]
                                    // console.log(imageFile)
                                    if (placeOrderImageTHUrl == imageFile.name) setIsDisable(false)
                                    else setIsDisable(true)
                                    if (!imageFile?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
                                      setInvalidPackageImageTH('Please select valid image')
                                    } else {
                                      setInvalidPackageImageTH('')
                                    }
                                    //for image preview
                                    const reader = new FileReader();
                                    reader?.addEventListener("load", () => setPackageImageTHPreview(reader?.result));
                                    reader?.readAsDataURL(e?.target?.files[0]);
                                    //for image preview
                                    props?.setFieldValue('packageImageTH', e?.target?.files[0])
                                  }}
                                  onBlur={() => props?.setFieldTouched('packageImageTH', true)}
                                  error={
                                    (props?.touched?.packageImageTH && props?.errors?.packageImageTH) ||
                                    invalidPackageImageTH
                                  }
                                />
                                {/* for image preview */}
                                {packageImageTHPreview?.length > 0 ?
                                  <>
                                    <CCol>
                                      <a target='_blank' href={`${packageImageTHPreview}`}>
                                        <CImage
                                          rounded
                                          thumbnail
                                          className="mt-2"
                                          src={`${packageImageTHPreview}`}
                                          style={{
                                            maxHeight: '80px',
                                            maxWidth: '80px',
                                            alignItems: 'center',
                                          }}
                                        />
                                      </a>
                                    </CCol>
                                  </>
                                  : ''}
                                {/* for image preview */}
                                {packageImageTHUrl && (
                                  <a target='_blank' href={`${packageImageTHUrl}`}>
                                    <CImage
                                      rounded
                                      thumbnail
                                      className="mt-2"
                                      src={`${packageImageTHUrl}`}
                                      style={{
                                        maxHeight: '80px',
                                        maxWidth: '80px',
                                        alignItems: 'center',
                                      }}
                                    />
                                  </a>
                                )}
                              </CCol>
                              <CFormLabel className="mb-4">
                                <strong>Plan 1:-</strong>
                              </CFormLabel>
                              <CCol md={6}>

                                <CFormLabel>Title (EN)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'ex: Regular'}
                                  // name={'Title (EN)'}
                                  id={'regularTitle_EN'}
                                  value={props?.values?.regularTitle_EN}
                                  onChange={props?.handleChange('regularTitle_EN')}
                                  onBlur={() => props?.setFieldTouched('regularTitle_EN', true)}
                                  error={
                                    props?.touched?.regularTitle_EN && props?.errors?.regularTitle_EN
                                  }
                                />
                                <CFormLabel>Sub Title (EN)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'ex: Regular'}
                                  // name={'Sub Title (EN)'}
                                  id={'regularSubTitle_EN'}
                                  value={props?.values?.regularSubTitle_EN}
                                  onChange={props?.handleChange('regularSubTitle_EN')}
                                  onBlur={() => props?.setFieldTouched('regularSubTitle_EN', true)}
                                  error={
                                    props?.touched?.regularSubTitle_EN &&
                                    props?.errors?.regularSubTitle_EN
                                  }
                                />
                                <CFormLabel>Image</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'file'}
                                  placeholder={'Image'}
                                  // name={'Image'}
                                  accept="image/*"
                                  refr={fileRef}
                                  onChange={(e) => {
                                    const imageFile = e?.target?.files[0]
                                    // console.log(imageFile)
                                    if (regularImageUrl == imageFile.name) setIsDisable(false)
                                    else setIsDisable(true)
                                    if (!imageFile?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
                                      setInvalidRegularImage('Please select valid image')
                                    } else {
                                      setInvalidRegularImage('')
                                    }
                                    //for image preview
                                    const reader = new FileReader();
                                    reader?.addEventListener("load", () => setPlan1ImagePreview(reader?.result));
                                    reader?.readAsDataURL(e?.target?.files[0]);
                                    //for image preview
                                    props?.setFieldValue('regularImage', e?.target?.files[0])
                                  }}
                                  onBlur={() => props?.setFieldTouched('regularImage', true)}
                                  error={
                                    (props?.touched?.regularImage && props?.errors?.regularImage) ||
                                    invalidRegularImage
                                  }
                                />
                                {/* for image preview */}
                                {plan1ImagePreview?.length > 0 ?
                                  <>
                                    <CCol>
                                      <a target='_blank' href={`${plan1ImagePreview}`}>
                                        <CImage
                                          rounded
                                          thumbnail
                                          className="mt-2"
                                          src={`${plan1ImagePreview}`}
                                          style={{
                                            maxHeight: '80px',
                                            maxWidth: '80px',
                                            alignItems: 'center',
                                          }}
                                        />
                                      </a>
                                    </CCol>
                                  </>
                                  : ''}
                                {/* for image preview */}
                                {regularImageUrl && (
                                  <a target='_blank' href={`${regularImageUrl}`}>
                                    <CImage
                                      rounded
                                      thumbnail
                                      className="mt-2"
                                      src={`${regularImageUrl}`}
                                      style={{
                                        maxHeight: '80px',
                                        maxWidth: '80px',
                                        alignItems: 'center',
                                      }}
                                    />
                                  </a>
                                )}
                              </CCol>
                              <CCol md={6}>

                                <CFormLabel>Title (TH)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'ex: Regular'}
                                  // name={'Title (TH)'}
                                  id={'regularTitle_TH'}
                                  value={props?.values?.regularTitle_TH}
                                  onChange={props?.handleChange('regularTitle_TH')}
                                  onBlur={() => props?.setFieldTouched('regularTitle_TH', true)}
                                  error={
                                    props?.touched?.regularTitle_TH && props?.errors?.regularTitle_TH
                                  }
                                />
                                <CFormLabel>Sub Title (TH)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'ex: Regular '}
                                  // name={'Sub Title (TH)'}
                                  id={'regularSubTitle_TH'}
                                  value={props?.values?.regularSubTitle_TH}
                                  onChange={props?.handleChange('regularSubTitle_TH')}
                                  onBlur={() => props?.setFieldTouched('regularSubTitle_TH', true)}
                                  error={
                                    props?.touched?.regularSubTitle_TH &&
                                    props?.errors?.regularSubTitle_TH
                                  }
                                />
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <div><CFormLabel>Next Pickup Time</CFormLabel>
                                    <span className='text-danger' style={{ marginLeft: '5px' }}>*</span></div>
                                  <TimePicker
                                    // label="Basic example"
                                    name="time"
                                    style={{ height: '68px', height: '595px' }}
                                    value={nextpickuptime}
                                    onChange={(newValue) => {
                                      // console.log('newwwwwwww', newValue)
                                      props?.setFieldValue('nextPickupTime', newValue?.$d)
                                      setNextPickupTime(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} style={{ marginTop: '5px' }} />}
                                  />
                                </LocalizationProvider>
                                {nextpickuptime == null ? <p className='text-danger mt-2'>{props?.errors?.nextPickupTime}</p> : ''}
                              </CCol>
                            </CRow>
                            <hr />
                            <CRow>
                              <CFormLabel className="mb-4">
                                <strong>Plan 2:-</strong>
                              </CFormLabel>
                              <CCol md={6}>
                                <CFormLabel>Title (EN)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'ex: Package'}
                                  // name={'Title (EN)'}
                                  id={'packageTitle_EN'}
                                  value={props?.values?.packageTitle_EN}
                                  onChange={props?.handleChange('packageTitle_EN')}
                                  onBlur={() => props?.setFieldTouched('packageTitle_EN', true)}
                                  error={props?.touched?.packageTitle_EN && props?.errors?.packageTitle_EN}
                                />
                                <CFormLabel>Sub Title (EN)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'ex: Package'}
                                  // name={'Sub Title (EN)'}
                                  id={'packageSubTitle_EN'}
                                  value={props?.values?.packageSubTitle_EN}
                                  onChange={props?.handleChange('packageSubTitle_EN')}
                                  onBlur={() => props?.setFieldTouched('packageSubTitle_EN', true)}
                                  error={props?.touched?.packageSubTitle_EN && props?.errors?.packageSubTitle_EN}
                                />
                                <CFormLabel>Image</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'file'}
                                  placeholder={'Image'}
                                  // name={'Image'}
                                  accept="image/*"
                                  refr={fileRef}
                                  onChange={(e) => {
                                    const imageFile = e?.target?.files[0]
                                    // console.log(imageFile)
                                    if (packageImageUrl == imageFile.name) setIsDisable(false)
                                    else setIsDisable(true)
                                    if (!imageFile?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
                                      setInvalidPackageImage('Please select valid image')
                                    } else {
                                      setInvalidPackageImage('')
                                    }
                                    //for image preview
                                    const reader = new FileReader();
                                    reader?.addEventListener("load", () => setPlan2ImagePreview(reader?.result));
                                    reader?.readAsDataURL(e?.target?.files[0]);
                                    //for image preview
                                    props?.setFieldValue('packageImage', e?.target?.files[0])
                                  }}
                                  onBlur={() => props?.setFieldTouched('packageImage', true)}
                                  error={
                                    (props?.touched?.packageImage && props?.errors?.packageImage) ||
                                    invalidPackageImage
                                  }
                                />
                                {/* for image preview */}
                                {plan2ImagePreview?.length > 0 ?
                                  <>
                                    <CCol>
                                      <a target='_blank' href={`${plan2ImagePreview}`}>
                                        <CImage
                                          rounded
                                          thumbnail
                                          className="mt-2"
                                          src={`${plan2ImagePreview}`}
                                          style={{
                                            maxHeight: '80px',
                                            maxWidth: '80px',
                                            alignItems: 'center',
                                          }}
                                        />
                                      </a>
                                    </CCol>
                                  </>
                                  : ''}
                                {/* for image preview */}
                                {packageImageUrl && (
                                  <a target='_blank' href={`${packageImageUrl}`}>
                                    <CImage
                                      rounded
                                      thumbnail
                                      className="mt-2"
                                      src={`${packageImageUrl}`}
                                      style={{
                                        maxHeight: '80px',
                                        maxWidth: '80px',
                                        alignItems: 'center',
                                      }}
                                    />
                                  </a>
                                )}
                              </CCol>
                              <CCol md={6}>
                                <CFormLabel>Title (TH)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'ex: Package'}
                                  // name={'Title (TH)'}
                                  id={'packageTitle_TH'}
                                  value={props?.values?.packageTitle_TH}
                                  onChange={props?.handleChange('packageTitle_TH')}
                                  onBlur={() => props?.setFieldTouched('packageTitle_TH', true)}
                                  error={props?.touched?.packageTitle_TH && props?.errors?.packageTitle_TH}
                                />
                                <CFormLabel>Sub Title (TH)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'ex: Package'}
                                  // name={'Sub Title (TH)'}
                                  id={'packageSubTitle_TH'}
                                  value={props?.values?.packageSubTitle_TH}
                                  onChange={props?.handleChange('packageSubTitle_TH')}
                                  onBlur={() => props?.setFieldTouched('packageSubTitle_TH', true)}
                                  error={props?.touched?.packageSubTitle_TH && props?.errors?.packageSubTitle_TH}
                                />

                                <CFormLabel>Factory Laundry Closed</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'file'}
                                  placeholder={'Image'}
                                  // name={'Image'}
                                  accept="image/*"
                                  refr={fileRef}
                                  onChange={(e) => {
                                    const imageFile = e?.target?.files[0]
                                    // console.log(imageFile)
                                    if (bannerImageUrl == imageFile.name) setIsDisable(false)
                                    else setIsDisable(true)
                                    if (!imageFile?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
                                      setInvalidBannerImageNew('Please select valid image')
                                    } else {
                                      setInvalidBannerImageNew('')
                                    }
                                    //for image preview
                                    const reader = new FileReader();
                                    reader?.addEventListener("load", () => setPlan3ImagePreview(reader?.result));
                                    reader?.readAsDataURL(e?.target?.files[0]);
                                    //for image preview
                                    props?.setFieldValue('bannerImageNew', e?.target?.files[0])
                                  }}
                                  onBlur={() => props?.setFieldTouched('bannerImageNew', true)}
                                  error={
                                    (props?.touched?.bannerImageNew && props?.errors?.bannerImageNew) ||
                                    invalidBannerImageNew
                                  }
                                />
                                {/* for image preview */}
                                {plan3ImagePreview?.length > 0 ?
                                  <>
                                    <CCol>
                                      <a target='_blank' href={`${plan3ImagePreview}`}>
                                        <CImage
                                          rounded
                                          thumbnail
                                          className="mt-2"
                                          src={`${plan3ImagePreview}`}
                                          style={{
                                            maxHeight: '80px',
                                            maxWidth: '80px',
                                            alignItems: 'center',
                                          }}
                                        />
                                      </a>
                                    </CCol>
                                  </>
                                  : ''}
                                {/* for image preview */}
                                {bannerImageUrlNew && (
                                  <a target='_blank' href={`${bannerImageUrlNew}`}>
                                    <CImage
                                      rounded
                                      thumbnail
                                      className="mt-2"
                                      src={`${bannerImageUrlNew}`}
                                      style={{
                                        maxHeight: '80px',
                                        maxWidth: '80px',
                                        alignItems: 'center',
                                      }}
                                    />
                                  </a>
                                )}
                              </CCol>
                              <CCol md={6}>
                                <CFormLabel>Package item off (%)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'ex: 10'}
                                  // name={'Title (EN)'}
                                  id={'packageItemOff'}
                                  value={props?.values?.packageItemOff}
                                  onChange={props?.handleChange('packageItemOff')}
                                  onBlur={() => props?.setFieldTouched('packageItemOff', true)}
                                  error={props?.touched?.packageItemOff && props?.errors?.packageItemOff}
                                />
                              </CCol>

                              {/* Add Input  */}
                              <CCol md={6}>
                                <CFormLabel>Delivery Price</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'ex: 100'}
                                  // name={'Sub Title (EN)'}
                                  id={'deliveryFee'}
                                  value={props?.values?.deliveryFee}
                                  onChange={props?.handleChange('deliveryFee')}
                                  onBlur={() => props?.setFieldTouched('deliveryFee', true)}
                                  error={props?.touched?.deliveryFee && props?.errors?.deliveryFee}
                                />
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol md={6}>
                                <CFormLabel>Damage/Stain Item Time (in Minutes)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'ex: 60'}
                                  // name={'Sub Title (EN)'}
                                  id={'damageItemTime'}
                                  value={props?.values?.damageItemTime}
                                  onChange={props?.handleChange('damageItemTime')}
                                  onBlur={() => props?.setFieldTouched('damageItemTime', true)}
                                  error={props?.touched?.damageItemTime && props?.errors?.damageItemTime}
                                />
                              </CCol>
                              <CCol md={6}>
                                <CFormLabel>Minimum order price (For Regular Order)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={''}
                                  // name={'Sub Title (EN)'}
                                  id={'minimumOrderPrice'}
                                  value={props?.values?.minimumOrderPrice}
                                  onChange={props?.handleChange('minimumOrderPrice')}
                                  onBlur={() => props?.setFieldTouched('minimumOrderPrice', true)}
                                  error={props?.touched?.minimumOrderPrice && props?.errors?.minimumOrderPrice}
                                />
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol md={6}>
                                <CFormLabel>Delivery Start Time</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'time'}
                                  placeholder={''}
                                  // name={'Sub Title (EN)'}
                                  id={'deliveryStartTime'}
                                  value={props?.values?.deliveryStartTime}
                                  onChange={props?.handleChange('deliveryStartTime')}
                                  onBlur={() => props?.setFieldTouched('deliveryStartTime', true)}
                                  error={props?.touched?.deliveryStartTime && props?.errors?.deliveryStartTime}
                                />
                              </CCol>
                              <CCol md={6}>
                                <CFormLabel>Delivery End Time</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'time'}
                                  placeholder={''}
                                  // name={'Sub Title (EN)'}
                                  id={'deliveryEndTime'}
                                  value={props?.values?.deliveryEndTime}
                                  onChange={props?.handleChange('deliveryEndTime')}
                                  onBlur={() => props?.setFieldTouched('deliveryEndTime', true)}
                                  error={props?.touched?.deliveryEndTime && props?.errors?.deliveryEndTime}
                                />
                              </CCol>

                            </CRow>
                            <CRow>
                              <CCol md={6}>
                                <CFormLabel>Delivery Banner</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'file'}
                                  placeholder={'Image'}
                                  accept="image/*"
                                  refr={deliveryBannerFileRef}
                                  onChange={(e) => {
                                    const imageFile = e?.target?.files[0]
                                    if (deliveryBannerImageUrl == imageFile.name) setIsDisable(false)
                                    else setIsDisable(true)
                                    if (!imageFile?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
                                      setInvalidDeliveryBannerImageNew('Please select valid image')
                                    } else {
                                      setInvalidDeliveryBannerImageNew('')
                                    }
                                    const reader = new FileReader();
                                    reader?.addEventListener("load", () => setDeliveryBannerImagePreview(reader?.result));
                                    reader?.readAsDataURL(e?.target?.files[0]);
                                    props?.setFieldValue('deliveryBanner', e?.target?.files[0])
                                  }}
                                  onBlur={() => props?.setFieldTouched('deliveryBanner', true)}
                                  error={
                                    (props?.touched?.deliveryBanner && props?.errors?.deliveryBanner) ||
                                    invalidDeliveryBannerImageNew
                                  }
                                />
                                {console.log("diveryBannerImagePreview", diveryBannerImagePreview)}
                                {diveryBannerImagePreview?.length > 0 ?
                                  <>
                                    <CCol>
                                      <a target='_blank' href={`${diveryBannerImagePreview}`}>
                                        <CImage
                                          rounded
                                          thumbnail
                                          className="mt-2"
                                          src={`${diveryBannerImagePreview}`}
                                          style={{
                                            maxHeight: '80px',
                                            maxWidth: '80px',
                                            alignItems: 'center',
                                          }}
                                        />
                                      </a>
                                    </CCol>
                                  </>
                                  : ''}
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol md="2">
                                <button
                                  type="submit"
                                  className={'btn btn-success mt-2'}
                                  // disabled={!isDisable || props.isSubmitting}
                                  disabled={props?.isSubmitting}
                                >
                                  Submit
                                </button>
                                {/* <button
                                type="submit"
                                className={'btn btn-success mt-2'}
                                disabled={props.isSubmitting}
                              >
                                Submit
                              </button> */}
                              </CCol>
                              <CCol md="2">
                                <button
                                  type="button"
                                  className={'btn btn-warning mt-2'}
                                  onClick={() => navigate('/admin/dashboard')}
                                >
                                  Cancel
                                </button>
                              </CCol>
                              <CCol md="3"></CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer >
    </>
  )
}

export default index
