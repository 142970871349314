import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { post, get, deleteApi } from '../../utils/apiManager'
import CIcon from '@coreui/icons-react'
import { cilPlus } from '@coreui/icons'
import {
  CCard, CCardBody, CCardGroup, CCol, CContainer, CHeader, CCardSubtitle, CTable, CRow, CImage, CTableHead, CTableRow, CTableHeaderCell, CTableBody, CTableDataCell, CButton,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import { DefaultInputWithIcon } from 'src/components/common/input'
import Pagination from 'react-js-pagination'
import { FaEdit, FaTrash } from 'react-icons/fa'
import { ImageUrl } from 'src/config/config'

function index() {
  const navigate = useNavigate();
  const [raiseAndIssueData, setRaiseAndIssueData] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [count, setCount] = useState()
  const [pageData, setPageData] = useState({
    page: 1,
    perPage: 10,
    searchItem: "",
    totalItemsCount: '',
  });

  const onSearchCLick = () => {
    setPageData({
      ...pageData,
      searchItem: searchItem,
    });
  }

  const changePage = (index) => {
    setPageData({
      ...pageData,
      page: index,
    });
  };

  useEffect(() => {
    getData();
  }, [pageData]);

  const getData = () => {
    get(
      `/admin/raise-n-issue/raise-issue-list?page=${pageData?.page || 1}&perPage=${pageData?.perPage || 10
      }&searchItem=${pageData?.searchItem || ""}`
    ).then((data) => handleResponse(data));
  };
  const handleResponse = (data) => {
    if (!data?.status) toast.success(data?.message, data?.status);
    else {
      setRaiseAndIssueData(data?.data[0]?.data);
      setTotalPages(data?.data[0]?.metadata[0]?.page);
      setCount(data?.data[0]?.metadata[0]?.total)
    }
  };
  const deleteData = (id) => {
    if (confirm("Are you sure you want to delete")) {
      deleteApi(`/admin/raise-n-issue/delete?id=${id}`).then((data) => {
        handleDelete(data);
      });
    }
  };

  const handleDelete = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
    } else {
      toast.error(data?.message)
    }
    getData();
  };


  return (
    <>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <CCardSubtitle as="h4">Raise An Issue</CCardSubtitle>
                  <CRow>
                    <CCol md="8">
                      <DefaultInputWithIcon
                        type={'text'}
                        placeholder="search"
                        customType={'search'}
                        value={searchItem}
                        onClick={onSearchCLick}
                        onChange={(e) => {
                          onSearchCLick()
                          setSearchItem(e?.target?.value)
                        }}
                      />

                    </CCol>
                    {/* <CCol >
                      <Link to={{ pathname: '/admin/not-deliver-reason/add' }} className="btn btn-dark">
                        <CIcon icon={cilPlus} />
                        Add
                      </Link>
                    </CCol> */}
                  </CRow>
                </CHeader>

                <CCardBody>
                  <CTable bordered hover responsive>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell>S. No</CTableHeaderCell>
                        <CTableHeaderCell>User Name</CTableHeaderCell>
                        <CTableHeaderCell>Order ID</CTableHeaderCell>
                        <CTableHeaderCell>Raise Images</CTableHeaderCell>
                        <CTableHeaderCell>Raised Issue</CTableHeaderCell>
                        <CTableHeaderCell>Action</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {raiseAndIssueData?.map((ele, index) => {
                        return (
                          <CTableRow key={index}>
                            <CTableDataCell>{index + 1}</CTableDataCell>
                            <CTableDataCell>
                              {ele?.user_id.map((i, k) => {
                                return <span>{i.username}</span>
                              })}</CTableDataCell>
                            <CTableDataCell>{ele?.order_id}</CTableDataCell>
                            <CTableDataCell>
                              {ele?.media?.map((i, index) => {
                                return (
                                  <a target='_blank' href={`${ImageUrl}${i}`}>
                                    <CImage
                                      rounded
                                      src={`${ImageUrl}${i}`}
                                      className="mx-2"
                                      style={{
                                        maxHeight: '80px',
                                        maxWidth: '80px',
                                        alignItems: 'center',
                                      }}
                                    />
                                  </a>
                                )
                              })}
                            </CTableDataCell>
                            <CTableDataCell>{ele?.raiseIssue}</CTableDataCell>
                            <CTableDataCell>
                              <span className="mx-2 text-danger" onClick={() => deleteData(ele?._id)} style={{ fontSize: '20px', padding: '5px', cursor: 'pointer' }}><FaTrash /></span>
                            </CTableDataCell>
                          </CTableRow>
                        );
                      })}
                    </CTableBody>
                  </CTable>
                  {!raiseAndIssueData?.length && (
                    <>
                      <h1>No Data</h1>
                    </>
                  )}
                  {raiseAndIssueData?.length ? <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={pageData?.page}
                    itemsCountPerPage={pageData?.perPage}
                    totalItemsCount={count}
                    pageRangeDisplayed={5}
                    onChange={changePage}
                  /> : ''}
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default index