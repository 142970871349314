import React, { Suspense } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Auth from './layout/Auth'
import Admin from './layout/Admin'

import './scss/style.scss'
import { ToastContainer } from 'react-toastify'
// import './style.css'
import 'react-toastify/dist/ReactToastify.css';

const AuthStack = () => {
  return (
    <Routes>
      <Route path="/*" element={< Auth />} />
    </Routes>
  )
}

export default function App() {
  const { token, userId } = useSelector((state) => state.auth)
  // console.log("Token not get", token)
  if (!token && !userId) {
    return (
      // console.log("not get")
      <Suspense fallback={<div>Loading...</div>}>
        <AuthStack />
      </Suspense>
    )
  }

  return (
    // console.log("get token")
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/*" element={<Admin />} />
      </Routes>
      <ToastContainer />
    </Suspense>
  )
}










