import React,{useEffect, useRef, useState} from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom'
import { post,get,put } from 'src/utils/apiManager';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CFormLabel,
    CRow,
    CImage,
} from '@coreui/react'
import {DefaultInput,DefaultTextarea } from 'src/components/common/input'



function index(props) {
    const navigate= useNavigate()
    const formRef = useRef(null)
    const fileRef = useRef(null)
    const [imageUrl1, setImageUrl1]= useState('')
    const [imageUrl2, setImageUrl2]= useState('')
    const [imageUrl3, setImageUrl3]= useState('')
    const [imageUrl4, setImageUrl4]= useState('')
    const [imageUrl5, setImageUrl5]= useState('')
    const [iD, setID]= useState('')
    const [isDisable, setIsDisable] = useState(false);
    const [invalidImage ,setInvalidImage] = useState('')
    const state={}
    
    useEffect(()=>{
        getOurServiceData();      
  },[])
    // after getting id for edit section
    const  getOurServiceData = ()=>{
      get(`/admin/our-service/get-our-service`).then((response)=>{
        if(response.status == true){
            handleSingleData(response)
          }
        });
      };
    const handleSingleData = (response) => {
        if (!response.status) toast.success(response.message, response.status);
        formRef.current.setFieldValue("titleStep1_EN", response?.data?.titleStep1_EN);
        formRef.current.setFieldValue("description1_EN", response?.data?.description1_EN);
        formRef.current.setFieldValue("titleStep1_TH", response?.data?.titleStep1_TH);
        formRef.current.setFieldValue("description1_TH", response?.data?.description1_TH);
        formRef.current.setFieldValue("titleStep2_EN", response?.data?.titleStep2_EN);
        formRef.current.setFieldValue("description2_EN", response?.data?.description2_EN);
        formRef.current.setFieldValue("titleStep2_TH", response?.data?.titleStep2_TH);
        formRef.current.setFieldValue("description2_TH", response?.data?.description2_TH);
        formRef.current.setFieldValue("titleStep3_EN", response?.data?.titleStep3_EN);
        formRef.current.setFieldValue("description3_EN", response?.data?.description3_EN);
        formRef.current.setFieldValue("titleStep3_TH", response?.data?.titleStep3_TH);
        formRef.current.setFieldValue("description3_TH", response?.data?.description3_TH);
        formRef.current.setFieldValue("titleStep4_EN", response?.data?.titleStep4_EN);
        formRef.current.setFieldValue("description4_EN", response?.data?.description4_EN);
        formRef.current.setFieldValue("titleStep4_TH", response?.data?.titleStep4_TH);
        formRef.current.setFieldValue("description4_TH", response?.data?.description4_TH);
        formRef.current.setFieldValue("titleStep5_EN", response?.data?.titleStep5_EN);
        formRef.current.setFieldValue("description5_EN", response?.data?.description5_EN);
        formRef.current.setFieldValue("titleStep5_TH", response?.data?.titleStep5_TH);
        formRef.current.setFieldValue("description5_TH", response?.data?.description5_TH);
        if (response?.data?.image1) setImageUrl1(`${response.imageUrl}${response?.data?.image1}`)
        if (response?.data?.image2) setImageUrl2(`${response.imageUrl}${response?.data?.image2}`)
        if (response?.data?.image3) setImageUrl3(`${response.imageUrl}${response?.data?.image3}`)
        if (response?.data?.image4) setImageUrl4(`${response.imageUrl}${response?.data?.image4}`)
        if (response?.data?.image5) setImageUrl5(`${response.imageUrl}${response?.data?.image5}`)
        if (response?.data?._id) setID(`${response?.data?._id}`)
      }    
    const submit = async (values) => { 
        let data = {
          id: iD,
          titleStep1_EN: values?.titleStep1_EN,
          description1_EN: values?.description1_EN,
          image1: values?.image1,
          titleStep1_TH: values?.titleStep1_TH,
          description1_TH: values?.description1_TH,
          titleStep2_EN: values?.titleStep2_EN,
          description2_EN: values?.description2_EN,
          image2: values?.image2,
          titleStep2_TH: values?.titleStep2_TH,
          description2_TH: values?.description2_TH,
          titleStep3_EN: values?.titleStep3_EN,
          description3_EN: values?.description3_EN,
          image3: values?.image3,
          titleStep3_TH: values?.titleStep3_TH,
          description3_TH: values?.description3_TH,
          titleStep4_EN: values?.titleStep4_EN,
          description4_EN: values?.description4_EN,
          image4: values?.image4,
          titleStep4_TH: values?.titleStep4_TH,
          description4_TH: values?.description4_TH,
          titleStep5_EN: values?.titleStep5_EN,
          description5_EN: values?.description5_EN,
          image5: values?.image5,
          titleStep5_TH: values?.titleStep5_TH,
          description5_TH: values?.description5_TH,
        }
        // console.log(data)
        if(iD){
          put(`admin/our-service/update-service-steps`, data, 1).then((response) => {
            handleResponse(response)
          })
        }else{
          post(`admin/our-service/add-service-steps`, data, 1).then((response) => {
            handleResponse(response)
          })
        }
    }
    const handleResponse = (data) => {
      if (data?.status == true) {
        toast.success(data?.message)
        getOurServiceData();
      }
      }  
    
  return (
      <>
        <CContainer >
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                <CCardGroup>
                    <CCardBody>
                    <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                    <h2>How To Use Our Services</h2>
                    <hr className='mb-4'/>
                    <Formik
                      initialValues={{
                        titleStep1_EN:'',
                        description1_EN:'',
                        image1:'',
                        titleStep1_TH:'',
                        description1_TH:'',
                        titleStep2_EN:'',
                        description2_EN:'',
                        image2:'',
                        titleStep2_TH:'',
                        description2_TH:'',
                        titleStep3_EN:'',
                        description3_EN:'',
                        image3:'',
                        titleStep3_TH:'',
                        description3_TH:'',
                        titleStep4_EN:'',
                        description4_EN:'',
                        image4:'',
                        titleStep4_TH:'',
                        description4_TH:'',
                        titleStep5_EN:'',
                        description5_EN:'',
                        image5:'',
                        titleStep5_TH:'',
                        description5_TH:'',
                      }}
                    //   validationSchema={settingsData}
                      onSubmit={(values, { setSubmitting }) => {
                        submit(values)
                        setSubmitting(false);  
                      }}
                      innerRef={formRef}
                    >
                      {(props) => (
                        <form onSubmit={props?.handleSubmit}>
                          <input type="hidden"name="hidden" value={iD} />
                          <CRow xs={12}>
                            <CFormLabel className='mb-4'><strong>Step 1:-</strong></CFormLabel>
                            <CCol md={6}>
                            <CFormLabel>Title (EN)</CFormLabel>
                            <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                            <DefaultInput
                            type={'text'}
                            placeholder={'Title'}
                            id={'titleStep1_EN'}
                            value={props?.values?.titleStep1_EN}
                            maxLength = "80"
                            onChange={props?.handleChange('titleStep1_EN')}
                            onBlur={() => props?.setFieldTouched('titleStep1_EN', true)}
                            error={props?.touched?.titleStep1_EN && props?.errors?.titleStep1_EN}
                            onKeyUp={()=>{
                              if(state?.titleStep1_EN == props?.values?.titleStep1_EN) setIsDisable(false)
                              else setIsDisable(true)
                            }}
                            />  
                            <DefaultTextarea
                              placeholder={"Description EN"}
                              name={"Description (EN)"}
                              id={"description1_EN"}
                              maxLength={200}
                              value={props?.values?.description1_EN}
                              onChange={props?.handleChange("description1_EN")}
                              onBlur={() =>
                                props?.setFieldTouched("description1_EN", true)
                              }
                              error={ props?.touched?.description1_EN && props?.errors?.description1_EN}
                              onKeyUp={()=>{
                                if(state?.description1_EN == props?.values?.description1_EN) setIsDisable(false)
                                else setIsDisable(true)
                              }}
                              />
                            <CFormLabel>Image</CFormLabel>
                            <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                            <DefaultInput
                              type={'file'}
                              placeholder={'Image'}
                              accept="image/*"
                              refr={fileRef}
                              onChange={(e) => {
                                const imageFile = e?.target?.files[0];
                                if(imageUrl1 == imageFile?.name) setIsDisable(false)
                                  else setIsDisable(true)                        
                                if (!imageFile?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
                                  setInvalidImage("Please select valid image")
                                }
                                else{
                                  setInvalidImage('')
                                }
                                props?.setFieldValue('image1', e?.target?.files[0])
                              }}
                              onBlur={() => props?.setFieldTouched('image1', true)}
                              error={(props?.touched?.image1 && props?.errors?.image1) || invalidImage}
                              />
                              {imageUrl1 && (
                                <CImage
                                  rounded
                                  thumbnail 
                                  className='mt-2'
                                  src={`${imageUrl1}`}
                                  style={{
                                    maxHeight: "80px",
                                    maxWidth: "80px",
                                    alignItems: "center",
                                  }}
                                />
                              )}  
                            </CCol>
                            <CCol md={6}>
                            <CFormLabel>Title (TH)</CFormLabel>
                            <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                            <DefaultInput
                            type={'text'}
                            placeholder={'Title'}
                            id={'titleStep1_TH'}
                            value={props?.values?.titleStep1_TH}
                            maxLength = "80"
                            onChange={props?.handleChange('titleStep1_TH')}
                            onBlur={() => props?.setFieldTouched('titleStep1_TH', true)}
                            error={props?.touched?.titleStep1_TH && props?.errors?.titleStep1_TH}
                            onKeyUp={()=>{
                              if(state?.titleStep1_TH == props?.values?.titleStep1_TH) setIsDisable(false)
                              else setIsDisable(true)
                            }}
                            />  
                            <DefaultTextarea
                              placeholder={"Description TH"}
                              name={"Description (TH)"}
                              id={"description1_TH"}
                              maxLength={200}
                              value={props?.values?.description1_TH}
                              onChange={props?.handleChange("description1_TH")}
                              onBlur={() =>
                                props?.setFieldTouched("description1_TH", true)
                              }
                              error={ props?.touched?.description1_TH && props?.errors?.description1_TH}
                              onKeyUp={()=>{
                                if(state?.description1_TH == props?.values?.description1_TH) setIsDisable(false)
                                else setIsDisable(true)
                              }}
                              />
                            </CCol>
                          </CRow>
                          <hr className='mt-4 mb-2'/>
                          <CRow xs={12}>
                            <CFormLabel className='mb-4'><strong>Step 2:-</strong></CFormLabel>
                            <CCol md={6}>
                            <CFormLabel>Title (EN)</CFormLabel>
                            <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                            <DefaultInput
                            type={'text'}
                            placeholder={'Title'}
                            id={'titleStep2_EN'}
                            value={props?.values?.titleStep2_EN}
                            maxLength = "80"
                            onChange={props?.handleChange('titleStep2_EN')}
                            onBlur={() => props?.setFieldTouched('titleStep2_EN', true)}
                            error={props?.touched?.titleStep2_EN && props?.errors?.titleStep2_EN}
                            onKeyUp={()=>{
                              if(state?.titleStep2_EN == props?.values?.titleStep2_EN) setIsDisable(false)
                              else setIsDisable(true)
                            }}
                            />  
                            <DefaultTextarea
                              placeholder={"Description EN"}
                              name={"Description (EN)"}
                              id={"description2_EN"}
                              maxLength={200}
                              value={props?.values?.description2_EN}
                              onChange={props?.handleChange("description2_EN")}
                              onBlur={() =>
                                props?.setFieldTouched("description2_EN", true)
                              }
                              error={ props?.touched?.description2_EN && props?.errors?.description2_EN}
                              onKeyUp={()=>{
                                if(state?.description2_EN == props?.values?.description2_EN) setIsDisable(false)
                                else setIsDisable(true)
                              }}
                              />
                            <CFormLabel>Image</CFormLabel>
                            <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                            <DefaultInput
                              type={'file'}
                              placeholder={'Image'}
                              accept="image/*"
                              refr={fileRef}
                              onChange={(e) => {
                                const imageFile = e?.target?.files[0];
                                if(imageUrl2 == imageFile?.name) setIsDisable(false)
                                  else setIsDisable(true)                        
                                if (!imageFile?.name.match(/\.(jpg|jpeg|png|gif)$/)) {
                                  setInvalidImage("Please select valid image")
                                }
                                else{
                                  setInvalidImage('')
                                }
                                props?.setFieldValue('image2', e?.target?.files[0])
                              }}
                              onBlur={() => props?.setFieldTouched('image2', true)}
                              error={(props?.touched?.image2 && props?.errors?.image2) || invalidImage}
                              />
                              {imageUrl2 && (
                                <CImage
                                  rounded
                                  thumbnail 
                                  className='mt-2'
                                  src={`${imageUrl2}`}
                                  style={{
                                    maxHeight: "80px",
                                    maxWidth: "80px",
                                    alignItems: "center",
                                  }}
                                />
                              )}  
                            </CCol>
                            <CCol md={6}>
                            <CFormLabel>Title (TH)</CFormLabel>
                            <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                            <DefaultInput
                            type={'text'}
                            placeholder={'Title'}
                            id={'titleStep2_TH'}
                            value={props?.values?.titleStep2_TH}
                            maxLength = "80"
                            onChange={props?.handleChange('titleStep2_TH')}
                            onBlur={() => props?.setFieldTouched('titleStep2_TH', true)}
                            error={props?.touched?.titleStep2_TH && props?.errors?.titleStep2_TH}
                            onKeyUp={()=>{
                              if(state?.titleStep2_TH == props?.values?.titleStep2_TH) setIsDisable(false)
                              else setIsDisable(true)
                            }}
                            />  
                            <DefaultTextarea
                              placeholder={"Description TH"}
                              name={"Description (TH)"}
                              id={"description2_TH"}
                              maxLength={200}
                              value={props?.values?.description2_TH}
                              onChange={props?.handleChange("description2_TH")}
                              onBlur={() =>
                                props?.setFieldTouched("description2_TH", true)
                              }
                              error={ props?.touched?.description2_TH && props?.errors?.description2_TH}
                              onKeyUp={()=>{
                                if(state?.description2_TH == props?.values?.description2_TH) setIsDisable(false)
                                else setIsDisable(true)
                              }}
                              />
                            </CCol>
                          </CRow>
                          <hr className='mt-4 mb-2'/>
                          <CRow xs={12}>
                            <CFormLabel className='mb-4'><strong>Step 3:-</strong></CFormLabel>
                            <CCol md={6}>
                            <CFormLabel>Title (EN)</CFormLabel>
                              <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                            <DefaultInput
                            type={'text'}
                            placeholder={'Title'}
                            // name={'Title (EN)'}
                            id={'titleStep3_EN'}
                            value={props?.values?.titleStep3_EN}
                            maxLength = "80"
                            onChange={props?.handleChange('titleStep3_EN')}
                            onBlur={() => props?.setFieldTouched('titleStep3_EN', true)}
                            error={props?.touched?.titleStep3_EN && props?.errors?.titleStep3_EN}
                            onKeyUp={()=>{
                              if(state?.titleStep3_EN == props?.values?.titleStep3_EN) setIsDisable(false)
                              else setIsDisable(true)
                            }}
                            />  
                            <DefaultTextarea
                              placeholder={"Description EN"}
                              name={"Description (EN)"}
                              id={"description3_EN"}
                              maxLength={200}
                              value={props?.values?.description3_EN}
                              onChange={props?.handleChange("description3_EN")}
                              onBlur={() =>
                                props?.setFieldTouched("description3_EN", true)
                              }
                              error={ props?.touched?.description3_EN && props?.errors?.description3_EN}
                              onKeyUp={()=>{
                                if(state?.description3_EN == props?.values?.description3_EN) setIsDisable(false)
                                else setIsDisable(true)
                              }}
                              />
                            <CFormLabel>Image</CFormLabel>
                            <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                            <DefaultInput
                              type={'file'}
                              placeholder={'Image'}
                              accept="image/*"
                              refr={fileRef}
                              onChange={(e) => {
                                const imageFile = e?.target?.files[0];
                                if(imageUrl3 == imageFile?.name) setIsDisable(false)
                                  else setIsDisable(true)                        
                                if (!imageFile?.name.match(/\.(jpg|jpeg|png|gif)$/)) {
                                  setInvalidImage("Please select valid image")
                                }
                                else{
                                  setInvalidImage('')
                                }
                                props?.setFieldValue('image3', e?.target?.files[0])
                              }}
                              onBlur={() => props?.setFieldTouched('image3', true)}
                              error={(props?.touched?.image3 && props?.errors?.image3) || invalidImage}
                              />
                              {imageUrl3 && (
                                <CImage
                                  rounded
                                  thumbnail 
                                  className='mt-2'
                                  src={`${imageUrl3}`}
                                  style={{
                                    maxHeight: "80px",
                                    maxWidth: "80px",
                                    alignItems: "center",
                                  }}
                                />
                              )}  
                            </CCol>
                            <CCol md={6}>
                            <CFormLabel>Title (TH)</CFormLabel>
                            <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                            <DefaultInput
                            type={'text'}
                            placeholder={'Title'}
                            id={'titleStep3_TH'}
                            value={props?.values?.titleStep3_TH}
                            maxLength = "80"
                            onChange={props?.handleChange('titleStep3_TH')}
                            onBlur={() => props?.setFieldTouched('titleStep3_TH', true)}
                            error={props?.touched?.titleStep3_TH && props?.errors?.titleStep3_TH}
                            onKeyUp={()=>{
                              if(state?.titleStep3_TH == props?.values?.titleStep3_TH) setIsDisable(false)
                              else setIsDisable(true)
                            }}
                            />  
                            <DefaultTextarea
                              placeholder={"Description TH"}
                              name={"Description (TH)"}
                              id={"description3_TH"}
                              maxLength={200}
                              value={props?.values?.description3_TH}
                              onChange={props?.handleChange("description3_TH")}
                              onBlur={() =>
                                props?.setFieldTouched("description3_TH", true)
                              }
                              error={ props?.touched?.description3_TH && props?.errors?.description3_TH}
                              onKeyUp={()=>{
                                if(state?.description3_TH == props?.values?.description3_TH) setIsDisable(false)
                                else setIsDisable(true)
                              }}
                              />
                            </CCol>
                          </CRow>
                          <hr className='mt-4 mb-2'/>
                          <CRow xs={12}>
                            <CFormLabel className='mb-4'><strong>Step 4:-</strong></CFormLabel>
                            <CCol md={6}>
                            <CFormLabel>Title (EN)</CFormLabel>
                            <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                            <DefaultInput
                            type={'text'}
                            placeholder={'Title'}
                            id={'titleStep4_EN'}
                            value={props?.values?.titleStep4_EN}
                            maxLength = "80"
                            onChange={props?.handleChange('titleStep4_EN')}
                            onBlur={() => props?.setFieldTouched('titleStep4_EN', true)}
                            error={props?.touched?.titleStep4_EN && props?.errors?.titleStep4_EN}
                            onKeyUp={()=>{
                              if(state?.titleStep4_EN == props?.values?.titleStep4_EN) setIsDisable(false)
                              else setIsDisable(true)
                            }}
                            />  
                            <DefaultTextarea
                              placeholder={"Description EN"}
                              name={"Description (EN)"}
                              id={"description4_EN"}
                              maxLength={200}
                              value={props?.values?.description4_EN}
                              onChange={props?.handleChange("description4_EN")}
                              onBlur={() =>
                                props?.setFieldTouched("description4_EN", true)
                              }
                              error={ props?.touched?.description4_EN && props?.errors?.description4_EN}
                              onKeyUp={()=>{
                                if(state?.description4_EN == props?.values?.description4_EN) setIsDisable(false)
                                else setIsDisable(true)
                              }}
                              />
                            <CFormLabel>Image</CFormLabel>
                            <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                            <DefaultInput
                              type={'file'}
                              placeholder={'Image'}
                              accept="image/*"
                              refr={fileRef}
                              onChange={(e) => {
                                const imageFile = e?.target?.files[0];
                                if(imageUrl4 == imageFile?.name) setIsDisable(false)
                                  else setIsDisable(true)                        
                                if (!imageFile?.name.match(/\.(jpg|jpeg|png|gif)$/)) {
                                  setInvalidImage("Please select valid image")
                                }
                                else{
                                  setInvalidImage('')
                                }
                                props?.setFieldValue('image4', e?.target?.files[0])
                              }}
                              onBlur={() => props?.setFieldTouched('image4', true)}
                              error={(props?.touched?.image4 && props?.errors?.image4) || invalidImage}
                              />
                              {imageUrl4 && (
                                <CImage
                                  rounded
                                  thumbnail 
                                  className='mt-2'
                                  src={`${imageUrl4}`}
                                  style={{
                                    maxHeight: "80px",
                                    maxWidth: "80px",
                                    alignItems: "center",
                                  }}
                                />
                              )}  
                            </CCol>
                            <CCol md={6}>
                            <CFormLabel>Title (TH)</CFormLabel>
                            <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                            <DefaultInput
                            type={'text'}
                            placeholder={'Title'}
                            id={'titleStep4_TH'}
                            value={props?.values?.titleStep4_TH}
                            maxLength = "80"
                            onChange={props?.handleChange('titleStep4_TH')}
                            onBlur={() => props?.setFieldTouched('titleStep4_TH', true)}
                            error={props?.touched?.titleStep4_TH && props?.errors?.titleStep4_TH}
                            onKeyUp={()=>{
                              if(state?.titleStep4_TH == props?.values?.titleStep4_TH) setIsDisable(false)
                              else setIsDisable(true)
                            }}
                            />  
                            <DefaultTextarea
                              placeholder={"Description TH"}
                              name={"Description (TH)"}
                              id={"description4_TH"}
                              maxLength={200}
                              value={props?.values?.description4_TH}
                              onChange={props?.handleChange("description4_TH")}
                              onBlur={() =>
                                props?.setFieldTouched("description4_TH", true)
                              }
                              error={ props?.touched?.description4_TH && props?.errors?.description4_TH}
                              onKeyUp={()=>{
                                if(state?.description4_TH == props?.values?.description4_TH) setIsDisable(false)
                                else setIsDisable(true)
                              }}
                              />
                            </CCol>
                          </CRow>
                          <hr className='mt-4 mb-2'/>
                          <CRow xs={12}>
                            <CFormLabel className='mb-4'><strong>Step 5:-</strong></CFormLabel>
                            <CCol md={6}>
                            <CFormLabel>Title (EN)</CFormLabel>
                            <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                            <DefaultInput
                            type={'text'}
                            placeholder={'Title'}
                            id={'titleStep5_EN'}
                            value={props?.values?.titleStep5_EN}
                            maxLength = "80"
                            onChange={props?.handleChange('titleStep5_EN')}
                            onBlur={() => props?.setFieldTouched('titleStep5_EN', true)}
                            error={props?.touched?.titleStep5_EN && props?.errors?.titleStep5_EN}
                            onKeyUp={()=>{
                              if(state?.titleStep5_EN == props?.values?.titleStep5_EN) setIsDisable(false)
                              else setIsDisable(true)
                            }}
                            />  
                            <DefaultTextarea
                              placeholder={"Description EN"}
                              name={"Description (EN)"}
                              id={"description5_EN"}
                              maxLength={200}
                              value={props?.values?.description5_EN}
                              onChange={props?.handleChange("description5_EN")}
                              onBlur={() =>
                                props?.setFieldTouched("description5_EN", true)
                              }
                              error={ props?.touched?.description5_EN && props?.errors?.description5_EN}
                              onKeyUp={()=>{
                                if(state?.description5_EN == props?.values?.description5_EN) setIsDisable(false)
                                else setIsDisable(true)
                              }}
                              />
                            <CFormLabel>Image</CFormLabel>
                            <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                            <DefaultInput
                              type={'file'}
                              placeholder={'Image'}
                              accept="image/*"
                              refr={fileRef}
                              onChange={(e) => {
                                const imageFile = e?.target?.files[0];
                                if(imageUrl5 == imageFile?.name) setIsDisable(false)
                                  else setIsDisable(true)                        
                                if (!imageFile?.name.match(/\.(jpg|jpeg|png|gif)$/)) {
                                  setInvalidImage("Please select valid image")
                                }
                                else{
                                  setInvalidImage('')
                                }
                                props?.setFieldValue('image5', e?.target?.files[0])
                              }}
                              onBlur={() => props?.setFieldTouched('image5', true)}
                              error={(props?.touched?.image5 && props?.errors?.image5) || invalidImage}
                              />
                              {imageUrl5 && (
                                <CImage
                                  rounded
                                  thumbnail 
                                  className='mt-2'
                                  src={`${imageUrl5}`}
                                  style={{
                                    maxHeight: "80px",
                                    maxWidth: "80px",
                                    alignItems: "center",
                                  }}
                                />
                              )}  
                            </CCol>
                            <CCol md={6}>
                            <CFormLabel>Title (TH)</CFormLabel>
                            <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                            <DefaultInput
                            type={'text'}
                            placeholder={'Title'}
                            id={'titleStep5_TH'}
                            value={props?.values?.titleStep5_TH}
                            maxLength = "80"
                            onChange={props?.handleChange('titleStep5_TH')}
                            onBlur={() => props?.setFieldTouched('titleStep5_TH', true)}
                            error={props?.touched?.titleStep5_TH && props?.errors?.titleStep5_TH}
                            onKeyUp={()=>{
                              if(state?.titleStep5_TH == props?.values?.titleStep5_TH) setIsDisable(false)
                              else setIsDisable(true)
                            }}
                            />  
                            <DefaultTextarea
                              placeholder={"Description TH"}
                              name={"Description (TH)"}
                              id={"description5_TH"}
                              maxLength={200}
                              value={props?.values?.description5_TH}
                              onChange={props?.handleChange("description5_TH")}
                              onBlur={() =>
                                props?.setFieldTouched("description5_TH", true)
                              }
                              error={ props?.touched?.description5_TH && props?.errors?.description5_TH}
                              onKeyUp={()=>{
                                if(state?.description5_TH == props?.values?.description5_TH) setIsDisable(false)
                                else setIsDisable(true)
                              }}
                              />
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol md="2">
                            <button type="submit"className={'btn btn-success mt-2'}disabled={!isDisable || props.isSubmitting}>Submit</button>
                              {/* <button
                                type="submit"
                                className={'btn btn-success mt-2'}
                                disabled={props.isSubmitting}
                              >
                                Submit
                              </button> */}
                            </CCol>
                            <CCol md="2">
                              <button
                                type="button"
                                className={'btn btn-warning mt-2'}
                                onClick={() => navigate('/admin/dashboard')}
                              >
                                Cancel
                              </button>
                            </CCol>
                            <CCol md="3"></CCol>
                          </CRow>
                        </form>
                      )}
                    </Formik>
                    </CCardBody>
                </CCardGroup>
                </CCol>               
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>

        
      </>
  )
}

export default index