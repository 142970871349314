import React,{useState,useEffect} from 'react'
import {
  CAvatar,
  CBadge,
  CButton,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
import {
  cilBell,
  cilCreditCard,
  cilCommentSquare,
  cilEnvelopeOpen,
  cilFile,
  cilLockLocked,
  cilSettings,
  cilTask,
  cilUser,
  cilAccountLogout,
  cilPencil,
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'

import avatar8 from './../../assets/images/avatars/8.jpg'
import avaitorUser from './../../assets/images/avaitor.png'
import { useDispatch, useSelector } from "react-redux";
import { LogoutDetails } from 'src/reduxToolkit/slice/auth';
import { ImageUrl } from 'src/config/config';
import { get } from 'src/utils/apiManager';
import { Skeleton } from "@mui/material";
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify'

const AppHeaderDropdown = () => {
  const [userInfo,setUserInfo]=useState();
  const dispatch = useDispatch()
  const onclickLogout = async () => {
   dispatch(LogoutDetails(
    {token:null,userId:null,email:null, sliderShow: true}
  ))
  // navigate('/login');
  };
  async function getUserInfo() {
    const url= `admin/get-users`
    get(url).then((response) =>{

      handleResponse(response)
        // console.log(response)
      //  console.log(response.imageUrl+"/"+response.data.image)
    }
     )
  }
  const handleResponse = (response) => {
    // console.log(response)
    if (!response.status) toast.success(response.message, response.status);
    else {
      setUserInfo(response?.data)
    }
  };

  useEffect(()=>{
    getUserInfo();
  },[])
  // },[userInfo])

  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        {/* <CAvatar src={avatar8} size="md" /> */}
        {userInfo!== undefined  ? <CAvatar src={`${userInfo.image?ImageUrl + userInfo.image:avaitorUser}`} size="md" />:<Skeleton variant="circular" width={40} height={40} />}
        {/* avaitor */}
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        {/* <CDropdownHeader className="bg-light fw-semibold py-2">Account</CDropdownHeader>
        <CDropdownItem href="#">
          <CIcon icon={cilBell} className="me-2" />
          Updates
          <CBadge color="info" className="ms-2">
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownItem href="#">
          <CIcon icon={cilEnvelopeOpen} className="me-2" />
          Messages
          <CBadge color="success" className="ms-2">
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownItem href="#">
          <CIcon icon={cilTask} className="me-2" />
          Tasks
          <CBadge color="danger" className="ms-2">
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownItem href="#">
          <CIcon icon={cilCommentSquare} className="me-2" />
          Comments
          <CBadge color="warning" className="ms-2">
            42
          </CBadge>
        </CDropdownItem> */}
        <CDropdownHeader className="bg-light fw-semibold py-2">Settings</CDropdownHeader>
        {/* <CDropdownItem >
          <a href="/admin/profile" style={{textDecoration: 'none',color:'#4f5d73'}}>
          <CIcon icon={cilUser} className="me-2" />
          Profile
          </a>
        </CDropdownItem> */}
          <Link to="/admin/profile" style={{textDecoration: 'none',color:'#4f5d73'}}>
          <CDropdownItem>
            <CIcon icon={cilUser} className="me-2" />
            Profile
          </CDropdownItem>
          </Link>
          <Link to="/admin/change-password" style={{textDecoration: 'none',color:'#4f5d73'}}>
          <CDropdownItem>
            <CIcon icon={cilPencil} className="me-2" />
            Change Password
          </CDropdownItem>
          </Link>         
        <CDropdownDivider />
        {/* <CDropdownItem onClick={onclickLogout}>
          <CIcon icon={cilAccountLogout} className="me-2" />
          logout
        </CDropdownItem> */}
        <CDropdownItem style={{cursor:'pointer'}}>
          <span onClick={onclickLogout}>
          <CIcon icon={cilAccountLogout} className="me-2" />
          Logout
          </span>
        </CDropdownItem>
        {/* <CDropdownItem href="#">
          <CIcon icon={cilLockLocked} className="me-2" />
          Lock Account
        </CDropdownItem> */}
      </CDropdownMenu>
    </CDropdown>
  )
}

export default AppHeaderDropdown
