import React, { useRef, useState, useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigate, Link } from 'react-router-dom'
import { post, get } from 'src/utils/apiManager';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CFormLabel,
  CImage,
  CRow,
} from '@coreui/react'
import { DefaultInput } from 'src/components/common/input'
import { validateItemCategory } from 'src/utils/validator'
function add() {
  const navigate = useNavigate()
  const formRef = useRef(null)
  const fileRef = useRef(null)
  const [disable, setDisable] = useState(false)
  const [categoryIconUrl, setCategoryIconUrl] = useState('')
  const [invalidServiceIconImage, setInvalidServiceIconImage] = useState('')
  const [categoryIconPreview, setCategoryIconPreview] = useState();
  const submit = async (values) => {
    setDisable(true)
    let formData = new FormData()
    formData.append('itemName_EN', values?.itemName_EN)
    formData.append('itemName_TH', values?.itemName_TH)
    formData.append('category_Icon', values?.category_Icon)
    post(`admin/item-category/add-item-category`, formData, 1).then((data) => handleResponse(data))
  }
  const handleResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      setTimeout(() => {
        navigate('/admin/item/item-category')
      }, 800)
    }
    else {
      setDisable(false)
      toast.error(data?.message)
    }
    formRef.current.resetForm()
  }
  const onClickReset = () => {
    console.log("this")
    console.log(this)
    // Reset Button Function
    // formRef.current.setFieldValue('vanNo', '')    
  };

  return (
    <>
      <CContainer >
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <CRow>
                        <CCol md="11">
                          <h1>New Item Category</h1>
                        </CCol>
                        <CCol md="1">
                          <Link className={'btn btn-success mt-2'} style={{ background: '#d3dc40', border: '#d3dc40' }} to={{ pathname: `/admin/item/item-category` }}>
                            Back
                          </Link>
                        </CCol>
                      </CRow>
                      <Formik
                        initialValues={{
                          itemName_EN: '',
                          itemName_TH: '',
                        }}
                        validationSchema={validateItemCategory}
                        onSubmit={(values, { setSubmitting }) => {
                          submit(values)
                          setSubmitting(false)

                        }}
                        innerRef={formRef}
                      >
                        {(props) => (
                          <form onSubmit={props?.handleSubmit}>
                            <CRow xs={12}>
                              <CCol md={6}>
                                <CFormLabel>Item Name (EN)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={"text"}
                                  placeholder={"Item Name (EN)"}
                                  value={props?.values?.itemName_EN}
                                  onChange={props?.handleChange("itemName_EN")}
                                  onBlur={() => props?.setFieldTouched("itemName_EN", true)}
                                  error={props?.touched?.itemName_EN && props?.errors?.itemName_EN}
                                />
                                <CFormLabel>Category Icon</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'file'}
                                  placeholder={'Image'}
                                  accept="image/*"
                                  refr={fileRef}
                                  onChange={(e) => {
                                    const imageFile = e?.target?.files[0]
                                    if (!imageFile.name.match(/\.(jpg|jpeg|png|gif)$/)) {
                                      setInvalidServiceIconImage('Please select valid image')
                                    } else {
                                      setInvalidServiceIconImage('')
                                    }
                                    //for image preview
                                    const reader = new FileReader();
                                    reader?.addEventListener("load", () => setCategoryIconPreview(reader?.result));
                                    reader?.readAsDataURL(e?.target?.files[0]);
                                    //for image preview
                                    props?.setFieldValue('category_Icon', e?.target?.files[0])
                                  }}
                                  onBlur={() => props?.setFieldTouched('category_Icon', true)}
                                  error={
                                    (props?.touched?.category_Icon && props?.errors?.category_Icon) || invalidServiceIconImage
                                  }
                                />
                                {/* for image preview */}
                                {categoryIconPreview?.length > 0 ?
                                  <>
                                    <CCol>
                                      <CImage
                                        rounded
                                        thumbnail
                                        className="mt-2"
                                        src={`${categoryIconPreview}`}
                                        style={{
                                          maxHeight: '80px',
                                          maxWidth: '80px',
                                          alignItems: 'center',
                                        }}
                                      />
                                    </CCol>
                                  </>
                                  : ''}
                                {/* for image preview */}
                              </CCol>
                              <CCol md="6">
                                <CFormLabel>Item Name (TH)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={"text"}
                                  placeholder={"Item Name (TH)"}
                                  value={props?.values?.itemName_TH}
                                  onChange={props?.handleChange("itemName_TH")}
                                  onBlur={() => props?.setFieldTouched("itemName_TH", true)}
                                  error={props?.touched?.itemName_TH && props?.errors?.itemName_TH}
                                />


                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol md="2">
                                <button
                                  type="submit"

                                  className={'btn btn-success mt-2'}
                                  disabled={disable || props?.isSubmitting}
                                >
                                  Submit
                                </button>
                              </CCol>
                              <CCol md="2">
                                <button
                                  type="button"
                                  className={'btn btn-warning mt-2'}
                                  onClick={() => onClickReset(props?.isSubmitting)}
                                >
                                  Reset
                                </button>
                              </CCol>
                              <CCol md="3"></CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default add