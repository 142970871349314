import React, { useRef, useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useLocation, useNavigate, Link } from 'react-router-dom'
import { put, get, post } from 'src/utils/apiManager';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CFormLabel,
  CRow,
} from '@coreui/react'
import { DefaultInput } from 'src/components/common/input'
import { validateAddress } from 'src/utils/validator'
import Select from 'react-select'

const edit = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const formRef = useRef(null)
  const [isDisable, setIsDisable] = useState(false);
  const [stateOptions, setStateOptions] = useState([]);
  const [stateList, setStateList] = useState(null);
  const [cityOptions, setCityOptions] = useState([]);
  const [cityList, setCityList] = useState(null);
  const [areaOptions, setAreaOptions] = useState([]);
  const [areaList, setAreaList] = useState(null);
  const [lat_long, setLatLong] = useState();
  const [zipCode, setZipCode] = useState();
  const [addressType, setAddressType] = useState();
  const [address, setAddress] = useState("");
  const typeOptions = [
    { value: "home", label: "Home" },
    { value: "office", label: "Office" },
    { value: "others", label: "Others" },
  ]
  const fetchStateList = () => {
    get(`/admin/address/state-list`).then((res) => {
      if (res?.data !== null) {
        const tempData = res?.data?.map((item, key) => {
          if (state?.state_id?._id == item?._id) {
            stateOptions?.push({ label: item?.stateName_EN, value: item?._id })
          }
          return { label: item?.stateName_EN, value: item?._id }
        })
        setStateList(tempData)
      }
    })
  };

  const fetchCityList = (_id) => {
    const data = {
      state_id: _id
    }
    post(`/admin/address/city-list`, data).then((res) => {
      if (res?.data !== null) {
        const tempData = res?.data?.map((item, key) => {
          if (state?.city_id?._id == item?._id) {
            cityOptions?.push({ label: item?.cityName_EN, value: item?._id })
          }
          return { label: item?.cityName_EN, value: item?._id }
        })
        setCityList(tempData)
      }
    })
  };

  const fetchAreaList = (_id) => {
    const data = { city_id: _id };
    post(`/admin/address/area-list`, data).then((res) => {
      if (res?.data !== null) {
        const tempData = res?.data?.map((item, key) => {
          if (state?.area_id?._id == item?._id) {
            areaOptions?.push({ label: item?.areaName_EN, value: item?._id })
            setZipCode(item?.zipcode)
            typeOptions?.map((item, key) => {
              if (item?.value == state?.type) return setAddressType(item)
            })
            setAddress(state?.address)
            setLatLong(state?.location)
          }
          return { label: item?.areaName_EN, value: item?._id, zipcode: item?.zipcode }
        })
        setAreaList(tempData)
      }
    })
  };

  const submit = async (values) => {
    let Data = {
      user_id: state?.user_id?._id,
      location: lat_long,
      address: values?.address,
      type: addressType?.value,
      state_id: values?.state_id,
      city_id: values?.city_id,
      default: state?.default,
      area_id: values?.area_id,
    }
    put(`admin/address/update?_id=${state?._id}`, Data).then((res) => {
      handleResponse(res)
    })

  };

  const handleResponse = (res) => {
    if (res?.status == true) {
      toast.success(res?.message)
      setTimeout(() => {
        navigate('/admin/address')
      }, 800)
    }
  };

  useEffect(() => {
    fetchStateList();
    fetchCityList(state?.state_id?._id);
    fetchAreaList(state?.city_id?._id);
    if (state != null) {
      formRef.current.setFieldValue('state_id', state?.state_id)
      formRef.current.setFieldValue('city_id', state?.city_id)
      formRef.current.setFieldValue('area_id', state?.area_id)
      formRef.current.setFieldValue('type', state?.type)
      formRef.current.setFieldValue('zipcode', state?.area_id?.zipcode)
      formRef.current.setFieldValue('address', state?.address)
    }
  }, [state]);


  return (
    <>
      <CContainer>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <CRow>
                        <CCol md="11">
                          <h1>Edit Address</h1>
                        </CCol>
                        <CCol md="1">
                          <Link
                            className={'btn btn-success mt-2'}
                            style={{ background: '#d3dc40', border: '#d3dc40' }}
                            to={{ pathname: `/admin/address` }}
                          >
                            Back
                          </Link>
                        </CCol>
                      </CRow>
                      <Formik
                        initialValues={{
                          address: '',
                          city_id: '',
                          state_id: '',
                          area_id: '',
                          type: '',
                          zipcode: '',
                        }}
                        validationSchema={validateAddress}
                        onSubmit={(values, { setSubmitting }) => {
                          submit(values)
                          setSubmitting(false)
                        }}
                        innerRef={formRef}
                      >
                        {(props) => (
                          <form onSubmit={props?.handleSubmit}>
                            <CRow >
                              <CCol md={6}>
                                {/* <CFormLabel>State Name</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <Select
                                  defaultValue={stateOptions}
                                  onChange={(val) => {
                                    setCityOptions([])
                                    setAreaOptions([])
                                    setZipCode('')
                                    formRef.current.setFieldValue('address', '')
                                    if (val == stateOptions) setIsDisable(false)
                                          else setIsDisable(true)
                                    setStateOptions(val)
                                    console.log('val')
                                    props?.setFieldValue("state_id", val.value)
                                    fetchCityList(val.value)
                                  }}
                                  options={stateList}
                                  value={stateOptions}
                                  placeholder={"Select State"}
                                  name={"State Name (EN)"}
                                />
                                {stateOptions == undefined  ? <span className='text-danger'>{props?.errors?.state_id}</span> : '' } */}

                                <CFormLabel>Area Name</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <Select
                                  defaultValue={areaOptions}
                                  onChange={(val) => {
                                    if (val == areaOptions) setIsDisable(false)
                                    else setIsDisable(true)
                                    setAreaOptions(val)
                                    props?.setFieldValue("area_id", val.value)
                                    setZipCode(val?.zipcode)
                                  }}
                                  options={areaList}
                                  value={areaOptions}
                                  placeholder={"Select Area"}
                                  name={"Area Name"}
                                />
                                {areaOptions == undefined ? <span className='text-danger'>{props?.errors?.area_id}</span> : ''}

                                {/* <DefaultInput
                                  defaultValue={zipCode}
                                  type={'text'}
                                  placeholder={'Zipcode'}
                                  name={'Zipcode'}
                                  value={zipCode}
                                  maxLength={6}
                                  onChange={props?.handleChange('zipcode')}
                                  onBlur={() => props?.setFieldTouched('zipcode', true)}
                                  error={props?.touched?.zipcode && props?.errors?.zipcode}
                                /> */}
                                <CFormLabel>Address</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  // defaultValue={address}
                                  type={'text'}
                                  placeholder={'Address'}
                                  // name={'Address'}
                                  maxLength={50}
                                  value={props?.values?.address}
                                  onChange={props?.handleChange('address')}
                                  onKeyUp={() => {
                                    if (state.address == props?.values?.address)
                                      setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                  onBlur={() => props?.setFieldTouched('address', true)}
                                  error={props?.touched?.address && props?.errors?.address}
                                />
                              </CCol>
                              <CCol md={6}>
                                {/* <CFormLabel>City Name</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <Select
                                  defaultValue={cityOptions}
                                  onChange={(val) => {
                                    if (val == cityOptions) setIsDisable(false)
                                    else setIsDisable(true)
                                    setCityOptions(val)
                                    props?.setFieldValue("city_id", val.value)
                                    fetchAreaList(val.value)
                                  }}
                                  options={cityList}
                                  value={cityOptions}
                                  placeholder={"Select City"}
                                  name={"City Name"}
                                />
                                {cityOptions == undefined ? <span className='text-danger'>{props?.errors?.city_id}</span> : ''} */}



                                {/* <CFormLabel>Address</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span> */}
                                {/* <GooglePlacesAutocomplete
                                    query={{
                                      key: google_api_key,
                                      language: 'en', // language of the results
                                    }}
                                    selectProps={{
                                      placeholder: "Address",
                                      name: "address",
                                      onChange: (data, details = null) => {
                                        console.log('data******', data)
                                        const place_id = data.value.place_id
                                        console.log('place_iddddddd', place_id)
                                        // setFieldValue('streetAddress', description);
                                        axios
                                          .get(
                                            `https://maps.googleapis.com/maps/api/geocode/json?place_id=${place_id}&key=${google_api_key}`,
                                          )
                                          .then((res) => {
                                            let latlong = res?.data?.results[0]?.geometry?.location;
                                            console.log('streetAddress===', latlong);
                                            // setLatlng(latlong);
                                          });
                                      }

                                    }}
                                  /> */}

                                {/* {stateOptions == undefined  ? <span className='text-danger'>{props?.errors?.type}</span> : '' } */}

                                <CFormLabel>Address Type</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <Select
                                  defaultValue={addressType}
                                  onChange={(val) => {
                                    if (val == addressType) setIsDisable(false)
                                    else setIsDisable(true)
                                    setAddressType(val)
                                    props?.setFieldValue("type", val.value)
                                  }}
                                  options={typeOptions}
                                  value={addressType}
                                  placeholder={"Select Type"}
                                  name={"Address Type"}

                                />
                                {addressType == undefined ? <span className='text-danger'>{props?.errors?.type}</span> : ''}

                              </CCol>
                            </CRow>

                            <CRow>
                              <CCol md="2">
                                <button
                                  type="submit"
                                  className={'btn btn-success mt-2'}
                                  disabled={!isDisable || props?.isSubmitting}
                                >
                                  Submit
                                </button>
                              </CCol>
                              <CCol md="2">
                                <button
                                  type="button"
                                  className={'btn btn-warning mt-2'}
                                  onClick={() => navigate('/admin/address')}
                                >
                                  Cancel
                                </button>
                              </CCol>
                              <CCol md="3"></CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default edit;