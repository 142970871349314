import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { get, deleteApi } from '../../utils/apiManager'
import CIcon from '@coreui/icons-react'
import { cilPen, cilPlus, cilTrash } from '@coreui/icons'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CCardSubtitle,
  CTable,
  CRow,
  CImage,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import { DefaultInputWithIcon } from 'src/components/common/input'
import Pagination from 'react-js-pagination'
import { FaRegEye, FaEdit, FaTrash } from 'react-icons/fa'
import ShowMoreText from 'react-show-more-text'
import { ImageUrl } from 'src/config/config'

function index() {
  const navigate = useNavigate()
  const [serivceProcessList, setSerivceProcessList] = useState([])
  const [imageUrl, setimageUrl] = useState(ImageUrl)
  const [totalPages, settotalPages] = useState(0)
  const [pageData, setpageData] = useState({
    page: 1,
    perPage: 10,
    searchItem: '',
  })
  const [searchItem, setsearchItem] = useState('')

  useEffect(() => {
    getSerivceProcessData()
  }, [pageData])

  const getSerivceProcessData = () => {
    get(
      `/admin/SerivceProcess/get-service-process?page=${pageData?.page || 1}&perPage=${
        pageData?.perPage || 10
      }&searchItem=${pageData?.searchItem || ''}`,
    ).then((response) => {
      handleResponse(response)
    })
  }
  const handleResponse = (response) => {
    if (!response?.status) toast.success(response?.message, response?.status)
    else {
      setSerivceProcessList([...response?.data])
      settotalPages(response?.pages)
      // setimageUrl(response?.imageUrl)
    }
  }
  const changePage = (index) => {
    setpageData({
      ...pageData,
      page: index,
    })
  }
  const onSearchCLick = () => {
    setpageData({
      ...pageData,
      searchItem: searchItem,
    })
  }
  const onClickUserEdit = async (value) => {
    navigate('/admin/service-process/edit', { state: value })
  }
  const onClickUserView = (value) => {
    navigate(`/admin/service-process/service-process-detail/${value?._id}`, { state: value })
  }
  const deleteData = (id) => {
    if (confirm('Are you sure')) {
      let formData = {
        _id: id,
      }
      deleteApi(`/admin/SerivceProcess/delete?_id=${id}`).then((data) => {
        handleDelete(data)
      })
    }
  }
  const handleDelete = (data) => {
    toast.success(data?.message, data?.status)
    getSerivceProcessData()
  }
  const executeOnClick = async (isExpanded) => {
    // console.log(isExpanded);
  }

  return (
    <>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <CCardSubtitle as="h4">Service Process</CCardSubtitle>
                  <CRow>
                    <CCol md="8">
                      <DefaultInputWithIcon
                        type={'text'}
                        placeholder="Search"
                        customType={'search'}
                        value={searchItem}
                        onClick={onSearchCLick}
                        onChange={(e) => {
                          onSearchCLick()
                          setsearchItem(e?.target?.value)
                        }}
                      />
                    </CCol>
                    {/* <CCol md="2"></CCol> */}
                    <CCol md="4">
                      <Link
                        to={{ pathname: '/admin/service-process/add' }}
                        className="btn btn-dark"
                      >
                        <CIcon icon={cilPlus} />
                        Add
                      </Link>
                    </CCol>
                  </CRow>
                </CHeader>

                <CCardBody>
                  <CTable bordered hover responsive>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell>S. No</CTableHeaderCell>
                        <CTableHeaderCell>Title</CTableHeaderCell>
                        {/* <CTableHeaderCell>Title (English)</CTableHeaderCell> */}
                        {/* <CTableHeaderCell>Title (Thai)</CTableHeaderCell> */}
                        <CTableHeaderCell>Image</CTableHeaderCell>
                        <CTableHeaderCell>Description</CTableHeaderCell>
                        {/* <CTableHeaderCell>Description (English)</CTableHeaderCell> */}
                        {/* <CTableHeaderCell>Description (Thai)</CTableHeaderCell> */}
                        <CTableHeaderCell>Action</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {serivceProcessList &&
                        serivceProcessList?.map((data, key) => {
                          return (
                            <CTableRow key={key}>
                              <CTableDataCell className="col col-md-1">
                                {pageData?.perPage * (pageData?.page - 1) + key + 1}
                              </CTableDataCell>
                              <CTableDataCell className="col col-md-3">
                                {data.title_en}
                              </CTableDataCell>
                              {/* <CTableDataCell className="col col-md-1">
                                {data.title_th}
                              </CTableDataCell> */}

                              <CTableDataCell className="col col-md-2">
                                <CImage
                                  rounded
                                  src={`${imageUrl}${data?.image}`}
                                  // onError={addDefaultSrc}
                                  style={{
                                    // display: 'flex',
                                    // justifyContent: 'center',
                                    // alignItems: 'center',
                                    maxHeight: '100px',
                                    maxWidth: '100px',
                                    borderRadius: 50,
                                  }}
                                />
                              </CTableDataCell>

                              <CTableDataCell className="col col-md-3">
                                <ShowMoreText
                                  lines={1}
                                  more="Show more"
                                  less="Show less"
                                  className="content-css"
                                  anchorClass="my-anchor-css-class"
                                  onClick={executeOnClick}
                                  expanded={false}
                                  truncatedEndingComponent={'... '}
                                >
                                  <p dangerouslySetInnerHTML={{ __html: data.description_en }}></p>
                                </ShowMoreText>
                              </CTableDataCell>

                              {/* <CTableDataCell className="col col-md-3">
                                <ShowMoreText
                                  lines={1}
                                  more="Show more"
                                  less="Show less"
                                  className="content-css"
                                  anchorClass="my-anchor-css-class"
                                  onClick={executeOnClick}
                                  expanded={false}
                                  truncatedEndingComponent={'... '}
                                >
                                  <p dangerouslySetInnerHTML={{ __html: data.description_th }}></p>
                                </ShowMoreText>
                              </CTableDataCell> */}

                              <CTableDataCell className="col col-md-2">
                                <span
                                  className="mx-2"
                                  onClick={() => onClickUserEdit(data)}
                                  style={{ fontSize: '20px', color: '#d3dc40', cursor: 'pointer' }}
                                >
                                  <FaEdit />
                                </span>
                                <span
                                  className="mx-2 text-danger"
                                  onClick={() => deleteData(data?._id)}
                                  style={{ fontSize: '20px', padding: '5px', cursor: 'pointer' }}
                                >
                                  <FaTrash />
                                </span>
                                <span
                                  className="mx-2"
                                  onClick={() => onClickUserView(data)}
                                  style={{
                                    fontSize: '20px',
                                    color: '#d3dc40',
                                    padding: '5px',
                                    cursor: 'pointer',
                                  }}
                                >
                                  <FaRegEye />
                                </span>
                              </CTableDataCell>
                            </CTableRow>
                          )
                        })}
                    </CTableBody>
                  </CTable>
                  {!serivceProcessList?.length && (
                    <>
                      <h1>No Data</h1>
                    </>
                  )}
                  {serivceProcessList?.length ? (
                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={pageData?.page}
                      itemsCountPerPage={pageData?.perPage}
                      totalItemsCount={totalPages * 10}
                      pageRangeDisplayed={5}
                      onChange={changePage}
                    />
                  ) : (
                    ''
                  )}
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default index
