import React, { useEffect, useState } from 'react'
import { CCard, CCardBody, CCardGroup, CCol, CContainer, CRow } from '@coreui/react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { get } from 'src/utils/apiManager'

const ViewOrderDetails = () => {
  const { orderId } = useParams()
  const [orderData, setOrderData] = useState([])
  const navigate = useNavigate()

  const getData = () => {
    get(`/admin/offline-order/bag-list/${orderId}`).then((res) => {
      console.log(res)
      let data = {
        ...res?.data[0],
        addOnServiceDetail: res?.data[0]?.addOnServiceDetail
          ? JSON.parse(res?.data[0]?.addOnServiceDetail)
          : [],
      }
      setOrderData(data)
    })
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <CContainer>
      <CCard>
        <CCardGroup>
          <CCardBody>
            <CRow className="p-2">
              <CCol>
                <h5>Order Details</h5>
              </CCol>
              <CCol md="12" style={{ marginLeft: '10px' }}>
                {orderData && orderData.orderId && (
                  <p className="mb-1">
                    <b>OrderId:</b> {orderData.orderId}
                  </p>
                )}
                {orderData && orderData.orderStatus && (
                  <p className="mb-1">
                    <b>Order Status:</b> {orderData.orderStatus.toUpperCase()}
                  </p>
                )}
                {orderData && orderData.orderType && (
                  <p className="mb-1">
                    <b>Order Type:</b> {orderData.orderType.toUpperCase()}
                  </p>
                )}
                {orderData && orderData.QR && orderData.QR[0] && (
                  <p className="mb-1">
                    <b>QR Code ID:</b> {orderData.QR[0].QrId}
                  </p>
                )}
                {orderData && orderData.customer && orderData.customer[0] && (
                  <p className="mb-1">
                    <b>Customer Name:</b> {orderData.customer[0].username}
                  </p>
                )}
                {orderData && orderData.customer && orderData.customer[0] && (
                  <p className="mb-1">
                    <b>Customer Phone:</b> {orderData.customer[0].mobile}
                  </p>
                )}

                {orderData && orderData.addressId && orderData.addressId[0] && (
                  <p className="mb-1">
                    <b>Customer Address:</b> {orderData.addressId[0].address}
                  </p>
                )}
                {orderData?.addOnServiceDetail?.length > 0 ? (
                  <p className="mb-1">
                    <b>Addons:</b>{' '}
                  </p>
                ) : (
                  ''
                )}
                {orderData?.addOnServiceDetail?.map((addon, k) => (
                  <div className="">
                    <span>
                      {' '}
                      <b>Name: </b> {addon?.serviceName_EN}{' '}
                    </span>
                    ,
                    <span>
                      {' '}
                      <b>Price: </b> {addon?.price}{' '}
                    </span>
                    ,
                    <span>
                      {' '}
                      <b>Quantity: </b> {addon?.quantity}{' '}
                    </span>
                  </div>
                ))}
                {orderData && orderData.noteToGarmentExpert && (
                  <p className="mb-1">
                    <b>Addtional Comments:{orderData.noteToGarmentExpert}</b>{' '}
                  </p>
                )}
              </CCol>
            </CRow>

            <CRow className="p-2 align-items-center">
              <CCol>
                <h5 style={{ textTransform: 'uppercase' }}>Order summary page</h5>
              </CCol>
            </CRow>
            <hr />

            <div
              className="bag-list-box"
              style={{ maxHeight: '520px', overflowX: 'auto', overflow: 'auto' }}
            >
              <CRow className="p-2 align-items-center">
                {orderData?.bags?.length > 0 &&
                  orderData?.bags.map((data, index) => (
                    <CCol xs={4} key={index} className="p-3">
                      <div
                        className="card p-3 text-center"
                        onClick={() =>
                          navigate(`/admin/offline-orders/view`, {
                            state: { item: { _id: data?._id }, orderData },
                          })
                        }
                      >
                        <i class="fa-solid fa-bag-shopping" style={{ fontSize: '30px' }}></i>
                        <p style={{ margin: 0 }}>bag {index + 1}</p>
                        <p style={{ margin: 0 }}>
                          {data.item.length}:{' '}
                          <span
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            item
                          </span>
                        </p>
                        <p style={{ margin: 0 }}>
                          {!data.service
                            ? 'No service type available.'
                            : data.service.serviceName_EN}
                        </p>
                      </div>
                    </CCol>
                  ))}
              </CRow>
            </div>
          </CCardBody>
        </CCardGroup>
      </CCard>
    </CContainer>
  )
}

export default ViewOrderDetails
