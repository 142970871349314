import React, { useRef, useState } from 'react'
import { CCard, CCardBody, CCardGroup, CCol, CContainer, CRow } from '@coreui/react'
import { Link, useNavigate } from 'react-router-dom'
import Barcode from 'src/assets/images/barcode-scan.png'
import QRCodeIMG from 'src/assets/images/qrimage.png'
import { ToastContainer, toast } from 'react-toastify'
import { get } from 'src/utils/apiManager';


const CheckinOrder = () => {
    const [barcodeValue, setBarcodeValue] = useState('')
    const [qrValue, setQRValue] = useState('')
    const inputRef = useRef()
    const inputQRRef = useRef()
    const navigate = useNavigate()
    const isValidObjectId = (id) => {
        const hexRegex = /^[0-9a-fA-F]{24}$/;
        return hexRegex.test(id);
    }

    const handleQrCodePress = (event) => {
        const CheckId = isValidObjectId(qrValue)

        if (CheckId) {
            if (event.charCode === 13 && qrValue.length > 0) {
                get(`/admin/offline-order/get-order-id/${qrValue}`)
                    .then((res) => {
                        if (!res?.status) {
                            toast.error(res?.message)
                        } else {
                            navigate(`/admin/search-bags/${qrValue}`)
                        }
                    })
            }
        } else {
            if (event.charCode === 13 && qrValue.length > 0) {
                get(`/admin/placed-order/item-details?itemCode=${qrValue}`)
                    .then((res) => {
                        // console.log(res,"res")
                        // setItemDetail(res?.data);
                        // toast.error(res?.data?.data?.message)
                        if (res?.data) {
                            navigate(`/admin/search-item/${qrValue}`)
                        }
                        toast.error(res?.response?.data?.message)

                    })
                    .catch((error) => {
                        console.log(error)
                        toast.error(res.message)
                    })
            }
        }

        // if (event.charCode === 13 && qrValue.length > 0) {
        //     // const findOrderUniqueId = (qrValue.split('/')[1])
        //     navigate(`/admin/search-bags/${qrValue}`)
        // }
    }
    // const handlBarCodePress = (event) => {
    //     if (event.charCode === 13 && barcodeValue.length > 0) {
    //         get(`/admin/placed-order/item-details?itemCode=${barcodeValue}`)
    //             .then((res) => {
    //                 // console.log(res,"res")
    //                 // setItemDetail(res?.data);
    //                 // toast.error(res?.data?.data?.message)
    //                 if (res?.data) {
    //                     navigate(`/admin/search-item/${barcodeValue}`)
    //                 }
    //                 toast.error(res?.response?.data?.message)

    //             })
    //             .catch((error) => {
    //                 console.log(error)
    //                 toast.error(res.message)
    //             })
    //     }
    // }
    return (
        <CContainer>
            <CRow>
                <CCol xs={12} className="d-flex" onClick={() => inputQRRef.current.focus()}>
                    <CCardGroup className="w-100">
                        <CCard className="p-4">
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '70vh',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            >
                                <img
                                    src={QRCodeIMG}
                                    alt="qrCode" style={{ maxWidth: '200px' }}
                                />
                                <h4 className='mt-3'>Scan QR code for Order</h4>
                                <input
                                    // style={{ border: 'none', outline: 'none', widht: '0', height: '0' }}
                                    ref={inputQRRef}
                                    name="qrValue"
                                    id="qrValue"
                                    value={qrValue}
                                    onChange={(event) => setQRValue(event.target.value)}
                                    onKeyPress={(e) => handleQrCodePress(e)}
                                />
                            </div>

                        </CCard>
                    </CCardGroup>
                </CCol>

                {/*  <CCol xs={6} className="d-flex" onClick={() => inputRef.current.focus()} >
                    <CCardGroup className="w-100">
                        <CCard>

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '70vh',

                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <img
                                    src={Barcode}
                                    alt="barcode" style={{ maxWidth: '200px', marginTop: '40px' }}
                                />
                                <h4 className='mt-3'>Scan Bar code for item</h4>
                                <input

                                    ref={inputRef}
                                    name="barcodeValue"
                                    id="barcodeValue"
                                    value={barcodeValue}
                                    onChange={(event) => setBarcodeValue(event.target.value)}
                                    onKeyPress={(e) => handlBarCodePress(e)}
                                />
                            </div>
                        </CCard>
                    </CCardGroup>
                </CCol> */}



            </CRow>
        </CContainer>
    )
}

export default CheckinOrder