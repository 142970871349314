import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { post, get, deleteApi } from '../../utils/apiManager'
import CIcon from '@coreui/icons-react'
import { cilPlus } from '@coreui/icons'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CCardSubtitle,
  CTable,
  CRow,
  CImage,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CButton,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import { DefaultInputWithIcon } from 'src/components/common/input'
import Pagination from 'react-js-pagination'
import { FaRegEye, FaEdit, FaTrash, FaPrint } from 'react-icons/fa'
import ShowMoreText from 'react-show-more-text'

function assignQr() {
  const navigate = useNavigate()
  const [areaData, setAreaData] = useState([])
  const [assignedQR, setAssignedQR] = useState([])
  const [searchItem, setsearchItem] = useState('')
  const [totalPages, setTotalPages] = useState(0)
  const [count, setCount] = useState()
  const [pageData, setPageData] = useState({
    page: 1,
    perPage: 10,
    searchItem: '',
  })
  const [deletedArray, setDeletedArray] = useState([])
  useEffect(() => {
    getData()
  }, [pageData])

  const getData = () => {
    get(
      `/admin/genrate-QR/get-QR-code?page=${pageData?.page || 1}&perPage=${pageData?.perPage || 10
      }&searchItem=${pageData?.searchItem || ''}`,
    ).then((data) => handleResponse(data))
  }
  const handleResponse = (data) => {
    if (!data?.status) toast.success(data?.message, data?.status)
    else {
      setAssignedQR(data?.data?.esixtQrCode[0]?.data)
      setTotalPages(data?.data?.esixtQrCode[0]?.metadata[0]?.page)
      setCount(data?.data?.esixtQrCode[0]?.metadata[0]?.total)
    }
  }

  const changePage = (index) => {
    setPageData({
      ...pageData,
      page: index,
    })
  }
  const deleteData = (id) => {
    if (confirm('Are you sure')) {
      deleteApi(`/admin/genrate-QR/delete-qr/${id}`).then((data) => {
        handleDelete(data)
      })
    }
  }
  const handleDelete = (data) => {
    if (data.status) {
      toast.success(data?.message)
      getData()
    }
  }
  const generateQR = () => {
    post(`/admin/genrate-QR/QR-code-genrate`).then((res) => {
      handleGenerateQR(res)
    })
  }
  const handleGenerateQR = (res) => {
    if (res?.status) {
      toast.success(res?.message)
      getData()
    }
  }
  const onSearchCLick = () => {
    setPageData({
      ...pageData,
      searchItem: searchItem,
    })
  }
  const printData = (data) => {
    navigate(`/admin/print`, { state: data })
  }

  const handleCheck = (id) => {
    let findValue = deletedArray.includes(id)
    if (findValue) {
      let filterData = deletedArray?.filter((item) => item != id)
      setDeletedArray(filterData)
    } else {
      setDeletedArray((prev) => [...prev, id])
    }
  }

  const deleteOrders = () => {
    post(`admin/genrate-QR/bulk-deleted-qr`, { qrIds: deletedArray }).then((data) =>
      handleDeletedResponse(data),
    )
  }

  const handleDeletedResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      setDeletedArray([])
      getData()
    }
  }

  return (
    <>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <div className="d-flex" style={{ flexDirection: 'column' }}>
                    <CCardSubtitle as="h4">Assigned QR's </CCardSubtitle>
                    {deletedArray.length > 0 && (
                      <button className="btn btn-danger mt-2 text-white" onClick={deleteOrders}>
                        Delete
                      </button>
                    )}
                  </div>
                  <CCol md="4"></CCol>
                  <CCol md="2">
                    <DefaultInputWithIcon
                      type={'text'}
                      placeholder="Search"
                      customType={'search'}
                      value={searchItem}
                      onKeyPress={onSearchCLick}
                      onChange={(e) => {
                        onSearchCLick()
                        setsearchItem(e?.target?.value)
                      }}
                    />
                  </CCol>
                </CHeader>

                <CCardBody>
                  <CTable bordered hover responsive>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell>S. No</CTableHeaderCell>
                        <CTableHeaderCell>QR ID</CTableHeaderCell>
                        <CTableHeaderCell style={{ textAlign: 'center' }}>
                          QR Image
                        </CTableHeaderCell>
                        <CTableHeaderCell>Order ID</CTableHeaderCell>
                        <CTableHeaderCell>Action</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {assignedQR?.map((ele, index) => {
                        return (
                          <CTableRow key={index} >
                            <CTableDataCell onClick={() => handleCheck(ele._id)} >
                              {pageData?.perPage * (pageData?.page - 1) + index + 1}
                              <div class="form-check" >
                                <input
                                  class="form-check-input"
                                  style={{ marginLeft: '2px', marginTop: '-20px' }}
                                  type="checkbox"
                                  value=""
                                  id="flexCheckDefault"
                                  checked={deletedArray?.includes(ele._id)}
                                // onChange={() => handleCheck(ele._id)}
                                />
                              </div>
                            </CTableDataCell>
                            <CTableDataCell>{ele?._id}</CTableDataCell>
                            <CTableDataCell style={{ textAlign: 'center' }}>
                              <img src={ele?.QrImage} style={{ width: '40px' }} />
                              <br></br>
                              <p style={{ fontSize: '10px' }}>{ele?._id}</p>
                            </CTableDataCell>
                            <CTableDataCell>#{ele?.order_id}</CTableDataCell>
                            <CTableDataCell>
                              <span
                                className="mx-2"
                                onClick={() => printData(ele)}
                                style={{ fontSize: '20px', color: '#000', cursor: 'pointer' }}
                              >
                                <FaPrint />
                              </span>
                              {
                                <span
                                  className="mx-2 text-danger"
                                  onClick={() => deleteData(ele?._id)}
                                  style={{ fontSize: '20px', padding: '5px', cursor: 'pointer' }}
                                >
                                  <FaTrash />
                                </span>
                              }
                            </CTableDataCell>
                          </CTableRow>
                        )
                      })}
                    </CTableBody>
                  </CTable>
                  {!assignedQR?.length && (
                    <>
                      <h1>No Data</h1>
                    </>
                  )}
                  {assignedQR?.length ? (
                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={pageData?.page}
                      itemsCountPerPage={pageData?.perPage}
                      totalItemsCount={count}
                      pageRangeDisplayed={5}
                      onChange={changePage}
                    />
                  ) : (
                    ''
                  )}
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default assignQr
