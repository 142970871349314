import React, { useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import { useNavigate, Link } from 'react-router-dom'
import { post } from 'src/utils/apiManager';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import {
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CFormLabel,
    CRow,
} from '@coreui/react'
import { DefaultInput } from 'src/components/common/input'
import { validateState } from 'src/utils/validator'

const AddState = () => {
    const navigate = useNavigate()
    const formRef = useRef(null)
    const [disable, setDisable] = useState(false)

    const submit = async (values) => {

        setDisable(true)
        let StateData = {
            stateName_EN: values?.stateName_EN,
            stateName_TH: values?.stateName_TH
        }
        post(`admin/state/insert`, StateData).then((res) => {
            handleResponse(res);
        })
    };

    const handleResponse = (res) => {
        if (res?.status == true) {
            toast.success(res?.message)
            setTimeout(() => {
                navigate('/admin/state')
            }, 800)
        } else {
            setDisable(false)
            toast.error(res?.message)
        }
        formRef.current.resetForm()
    };

    const onClickReset = () => {
        formRef.current.setFieldValue('stateName_EN', '')
        formRef.current.setFieldValue('stateName_TH', '')
    };

    return (
        <>
            <CContainer >
                <CRow>
                    <CCol>
                        <CCardGroup>
                            <CCard className="p-4">
                                <CCol xs={12}>
                                    <CCardGroup>
                                        <CCardBody>
                                            <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                                            <CRow>
                                                <CCol md="11">
                                                    <h1>New State</h1>
                                                </CCol>
                                                <CCol md="1">
                                                    <Link className={'btn btn-success mt-2'} style={{ background: '#d3dc40', border: '#d3dc40' }} to={{ pathname: `/admin/state` }}>
                                                        Back
                                                    </Link>
                                                </CCol>
                                            </CRow>
                                            <Formik
                                                initialValues={{
                                                    stateName_EN: '',
                                                    stateName_TH: '',
                                                }}
                                                validationSchema={validateState}
                                                onSubmit={(values, { setSubmitting }) => {
                                                    submit(values)
                                                    setSubmitting(false)
                                                }}
                                                innerRef={formRef}
                                            >
                                                {(props) => (
                                                    <form onSubmit={props?.handleSubmit}>
                                                        <CFormLabel>State Name (EN)</CFormLabel>
                                                        <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                                        <DefaultInput
                                                            type={"text"}
                                                            placeholder={"State Name (EN)"}
                                                            // maxLength={50}
                                                            value={props?.values?.stateName_EN}
                                                            onChange={props?.handleChange("stateName_EN")}
                                                            onBlur={() => props?.setFieldTouched("stateName_EN", true)}
                                                            error={props?.touched?.stateName_EN && props?.errors?.stateName_EN}
                                                        />

                                                        <CFormLabel>State Name (TH)</CFormLabel>
                                                        <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                                        <DefaultInput
                                                            type={"text"}
                                                            placeholder={"State Name (TH)"}
                                                            // maxLength={50}
                                                            value={props?.values?.stateName_TH}
                                                            onChange={props?.handleChange("stateName_TH")}
                                                            onBlur={() => props?.setFieldTouched("stateName_TH", true)}
                                                            error={props?.touched?.stateName_TH && props?.errors?.stateName_TH}
                                                        />

                                                        <CRow>
                                                            <CCol md="2">
                                                                <button
                                                                    type="submit"

                                                                    className={'btn btn-success mt-2'}
                                                                    disabled={disable}
                                                                >
                                                                    Submit
                                                                </button>
                                                            </CCol>
                                                            <CCol md="2">
                                                                <button
                                                                    type="button"
                                                                    className={'btn btn-warning mt-2'}
                                                                    onClick={onClickReset}
                                                                >
                                                                    Reset
                                                                </button>
                                                            </CCol>
                                                            <CCol md="3"></CCol>
                                                        </CRow>
                                                    </form>
                                                )}
                                            </Formik>
                                        </CCardBody>
                                    </CCardGroup>
                                </CCol>
                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </>
    )
}

export default AddState