export const ConstantMessage = {
    // adminLogin: {
    //   email: "Please enter email",
    //   emailInvalid: "Please enter valid email",
    //   password: "Please enter password",
    // },
    // adminResetPassword: {
    //   password: "Please enter password",
    //   strongPassword:
    //     "Password should contain lower & uppercase, a number, and a special character with minimum 6 character",
    //   confirmPassword: "Please enter confirm password",
    //   passmisMatched: "password and confirm password do not match",
    // },
    profile: {
      Name: "Please enter name",
      mobile: "Mobile is required",
    },
    changePassword: {
      currentPassword: "Please enter current password",
      newPassword: "Please enter new password",
      confirmNewPassword: "Please enter confirm password",
      strongPassword:
        "Password should contain lower & uppercase, a number, and a special character with minimum 6 character and maximum 20 charcter",
      passmisMatched: "password and confirm password do not match",
    },
  };
  // export function checkIfFilesAreTooBig(files?: [File]): boolean {
  //   let valid = true
  //   if (files) {
  //     files.map(file => {
  //       const size = file.size / 1024 / 1024
  //       if (size > 10) {
  //         valid = false
  //       }
  //     })
  //   }
  //   return valid
  // }
  
  // export function checkIfFilesAreCorrectType(files?: [File]): boolean {
  //   let valid = true
  //   if (files) {
  //     files.map(file => {
  //       if (!['application/pdf', 'image/jpeg', 'image/png'].includes(file.type)) {
  //         valid = false
  //       }
  //     })
  //   }
  //   return valid
  // }
  