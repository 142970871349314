import { CButton, CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter, CRow, CCol } from '@coreui/react'
import { ImageUrl2 } from 'src/config/config';
const Description = ({ visibleDescription, setVisibleDescription, description }) => {
    return (
        <>
            <CModal
                visible={visibleDescription}
                alignment="center"
                onClose={() => setVisibleDescription(false)}
                aria-labelledby="LiveDemoExampleLabel"
                size='xl'
            >
                <CModalHeader onClose={() => setVisibleDescription(false)}>
                    <CModalTitle id="LiveDemoExampleLabel">Description</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    {description}
                </CModalBody>
            </CModal>
        </>
    )
}

export default Description;