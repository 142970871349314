import React, { useEffect, useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { get } from 'src/utils/apiManager'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CRow,
  CHeader,
  CCardSubtitle,
} from '@coreui/react'
import { ImageUrl2 } from 'src/config/config'
import ImagePreview from './previewImage'

const SearchItem = () => {
  const [itemDetail, setItemDetail] = useState({})
  const { itemId } = useParams()
  const navigate = useNavigate()
  const [visible, setVisible] = useState(false)

  const getData = () => {
    get(`/admin/placed-order/item-details?itemCode=${itemId}`)
      .then((res) => {
        setItemDetail(res?.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    getData()
  }, [itemId])

  const viewOrder = () => {
    navigate(`/admin/search-bags/${itemDetail?.order?.orderId}`)
  }
  return (
    <>
      {' '}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CCard style={{ flex: '0.2' }}>
          <CRow className="justify-content-center" style={{ padding: '0px 2px' }}>
            <CCol xs={11}>
              <CRow>
                <CCol
                  xs={12}
                  style={{
                    borderBottom: '1px solid #ccc',
                    width: '100%',
                    padding: '8px 5px',
                  }}
                >
                  Name : {itemDetail?.user?.username} <br />
                  Service Name : {itemDetail?.service?.serviceName_EN} <br />
                  Order Id : {itemDetail?.order?.orderId} <br />
                  Item Code : {itemDetail?.itemCode} <br />
                </CCol>
              </CRow>
              <CRow>
                <CCol
                  xs={12}
                  style={{
                    borderBottom: '1px solid #ccc',
                    width: '100%',
                    padding: '8px 5px',
                    cursor: 'pointer',
                    backgroundColor: 'green',
                    color: 'white',
                  }}
                >
                  Item 1
                  <span style={{ float: 'Right' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white">
                      <path d="M7.293 4.707 14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z" />
                    </svg>
                  </span>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCard>

        <CCard style={{ flex: '0.6' }}>
          <CCardGroup>
            <CCardBody>
              {itemDetail && (
                <>
                  <CHeader className="mb-3">
                    <h4 className="mb-3">Item summary page</h4>
                    {/* <CCardSubtitle as="h4">Item summary page SCQ</CCardSubtitle> */}
                    <CRow>
                      <CCol md="12">
                        <button
                          className="btn btn-success"
                          style={{
                            width: '100%',
                            background: '#d3dc40',
                            border: '#d3dc40',
                          }}
                          onClick={viewOrder}
                        >
                          View Order Detail
                        </button>
                      </CCol>
                    </CRow>
                  </CHeader>
                  <CRow className="justify-content-center mb-2 progress-list mt-2">
                    <CCol
                      xs={2}
                      className="align-items-center"
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <div style={{ position: 'relative' }}>
                        <div className="icon-box">
                          <div>
                            <i class="fa-solid fa-shirt"></i>
                          </div>
                          <div>{itemDetail?.itemId && <i class="fa-solid fa-check"></i>}</div>
                        </div>
                        <p style={{ textAlign: 'center', fontWeight: '600' }}>Type</p>
                      </div>
                      <div>{itemDetail?.itemId?.itemName_EN}</div>
                    </CCol>

                    <CCol
                      xs={2}
                      className="align-items-center"
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <div style={{ position: 'relative' }}>
                        <div className="icon-box">
                          <div>
                            <i class="fa-solid fa-shirt"></i>
                          </div>
                          <div>{itemDetail?.brand && <i class="fa-solid fa-check"></i>}</div>
                        </div>
                        <p style={{ textAlign: 'center', fontWeight: '600' }}>Brand</p>
                      </div>
                      <div>{itemDetail?.brand}</div>
                    </CCol>

                    <CCol
                      xs={2}
                      className="align-items-center"
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <div style={{ position: 'relative' }}>
                        <div className="icon-box">
                          <div>
                            <i class="fa-solid fa-shirt"></i>
                          </div>
                          <div>
                            {itemDetail?.colorIds?.length > 0 && <i class="fa-solid fa-check"></i>}
                          </div>
                        </div>
                        <p style={{ textAlign: 'center', fontWeight: '600' }}>Colors</p>
                      </div>
                      <CCol cs={6}>
                        {itemDetail?.colorIds?.map((_color, id) => {
                          return (
                            <div
                              style={{
                                height: '50px',
                                width: '50px',
                                borderRadius: '50%',
                                // border: '1px solid black',
                                backgroundColor: _color?.colorCode,
                                cursor: 'pointer',
                                boxShadow: '0 1px 3px rgb(0 0 0 / 36%)',
                                margin: '4px',
                              }}
                              key={id}
                            ></div>
                          )
                        })}
                      </CCol>
                    </CCol>

                    <CCol
                      xs={2}
                      className="align-items-center"
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <div style={{ position: 'relative' }}>
                        <div className="icon-box">
                          <div>
                            <i class="fa-solid fa-shirt"></i>
                          </div>
                          <div>
                            <i class="fa-solid fa-check"></i>
                          </div>
                        </div>
                        <p style={{ textAlign: 'center', fontWeight: '600' }}>Washing Programs</p>
                      </div>
                      <div>{itemDetail?.process?.processName_EN}</div>
                    </CCol>

                    <CCol
                      xs={2}
                      className="align-items-center"
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <div style={{ position: 'relative' }}>
                        <div className="icon-box">
                          <div>
                            <i class="fa-solid fa-shirt"></i>
                          </div>
                          <div>
                            {itemDetail?.item?.picture && <i class="fa-solid fa-check"></i>}
                          </div>
                        </div>
                        <p style={{ textAlign: 'center', fontWeight: '600' }}>Image</p>
                      </div>
                      {itemDetail?.picture && (
                        <>
                          <img
                            src={ImageUrl2 + itemDetail?.picture}
                            style={{ cursor: 'pointer', width: '50%', height: '100px' }}
                          />
                          <ImagePreview
                            visible={visible}
                            setVisible={setVisible}
                            image={ImageUrl2 + itemDetail?.picture}
                          />
                        </>
                      )}
                    </CCol>

                    <CCol
                      xs={2}
                      className="align-items-center"
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <div style={{ position: 'relative' }}>
                        <div className="icon-box">
                          <div>
                            <i class="fa-solid fa-shirt"></i>
                          </div>
                          <div>{itemDetail?.stain && <i class="fa-solid fa-check"></i>}</div>
                        </div>
                        <p style={{ textAlign: 'center', fontWeight: '600' }}>Stain</p>
                      </div>
                      {itemDetail?.stain?.length > 0 && itemDetail?.stain?.map((item) => {
                        return (

                          <div> {item?.stainName} </div>
                        )
                      })}
                    </CCol>
                  </CRow>

                  <CRow>
                    <h5>Instructions:</h5>
                    <div>{itemDetail?.description}</div>
                  </CRow>
                </>
              )}
            </CCardBody>
          </CCardGroup>
        </CCard>
      </div>
    </>
  )
}

export default SearchItem
