import { CButton, CCard, CCardBody, CCardGroup, CCardSubtitle, CCol, CContainer, CHeader, CRow } from '@coreui/react'
import { get } from 'src/utils/apiManager'
import React, { useEffect, useRef, useState } from 'react'
import { FaArrowLeft } from 'react-icons/fa';
import { Link, useLocation, useParams } from 'react-router-dom'
import ReactToPrint from 'react-to-print';

function print() {
    const { id } = useParams();
    const { orderId } = useParams();
    const [logo, setLogo] = useState()
    const [orderQR, setOrderQR] = useState([]);
    const printRef = useRef()
    const [isQr, setIsQr] = useState(false);

    const getLogo = () => {
        get(`/admin/global/global-setting`).then((response) => {
            if (response?.status == true) {
                // handleSingleData(response)
                if (response?.data?.logoImage) setLogo(`${response?.imageUrl}${response?.data?.logoImage}`)
                // setLogo(response?.data.logoImage)
            }
        })
    }
    const getPlacedOrderInfo = () => {
        get(`admin/placed-order/view-singlepacket-details?_id=${atob(id)}&orderId=${atob(orderId)}`).then((response) => {
            if (response?.orderQR?.length > 0) {
                setIsQr(true)
                setOrderQR(response?.orderQR[0])
            }
            else {
                setIsQr(false)
            }
        })
    };
    // console.log(orderQR.QrImage, "order QR")
    useEffect(() => {
        getLogo();
        getPlacedOrderInfo();
    }, [])
    return (
        <>
            <CContainer fluid >
                <CRow >
                    <CCol>
                        <CCardGroup>
                            <CCard className="p-4" >
                                <CHeader>
                                    <CCardSubtitle as="h4">{' '} <Link style={{ color: '#000' }} to={{ pathname: `/admin/placed-order` }}>
                                        <FaArrowLeft />
                                    </Link>
                                        <span> QR Code Of Order No.{atob(orderId)}</span>
                                    </CCardSubtitle>
                                    <CRow>
                                        <CCol md="8">
                                        </CCol>
                                    </CRow>
                                </CHeader>
                                {isQr ? (<>
                                    <div ref={printRef}>
                                        <CCardBody >
                                            <center>
                                                {console.log(orderQR)}
                                                <h1 style={{ marginTop: '50px' }}><img src={logo} alt="pooja" />{' '} Cue Laundary</h1>
                                                <img src={orderQR.QrImage} width='400px' height='400px' />
                                                <p><b>{orderQR?._id}</b></p>
                                                <br />

                                            </center>
                                        </CCardBody>
                                    </div>

                                    <center>
                                        <CRow>
                                            <CCol>
                                                <ReactToPrint
                                                    trigger={() => <button type='button' className='btn btn-primary btn-m'>print</button>}
                                                    content={() => printRef?.current}
                                                />
                                            </CCol>
                                        </CRow>
                                    </center>
                                </>) : (<strong className="text-danger mt-4"><center>QR Code Is Not Connected Yet</center></strong>)}
                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </>
    )
}

export default print