import { CCard, CCardBody, CCardGroup, CCol, CContainer, CRow } from '@coreui/react'
import React, { useRef, useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Barcode from 'react-barcode'
import { get, post } from 'src/utils/apiManager'
import { ToastContainer, toast } from 'react-toastify'
// import  from ''
import QRCodeIMG from 'src/assets/images/qrimage.png'

const OfflineOrderIntro = () => {
  const inputRef = useRef()
  const [qrValue, setQRValue] = useState('')
  const navigate = useNavigate()
  const [orderId, setOrderId] = useState('')

  // const handleScan = (_data) => {
  //   if (_data) {
  //     console.log(_data)
  //     setData(_data)
  //   }
  // }

  // const handleError = (err) => {
  //   console.error(err)
  // }
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  const handleClickOutside = (event) => {
    // if (ref.current && !ref.current.contains(event.target)) {
    inputRef.current.focus()
    // }
  }
  const handleKeyPress = (event) => {
    if ((event.charCode === 13 || event.type == "click") && qrValue.length > 0) {
      // const findOrderUniqueId = atob(qrValue.split('/')[1])
      get(`/admin/offline-order/get-order-id/${qrValue}?checkValue=true`).then((data) => {

        if (data.status) {
          setOrderId(data.order)
          if (data.order) {
            // console.log({ orderId })
            post(
              `admin/offline-order/create-new-order`,
              { order_id: data.order, orderType: 'online' },
              0,
            ).then((data) => {
              if (data.status) {
                // console.log({ data: data.order._id })
                navigate(`/admin/offline-order/bags/${data.order._id}`)
              } else {
                toast.error(data.message)
              }
            })
          }
        } else {
          toast.error(data.message)
        }
      })
      // navigate(`/admin/online-user-list/${}`)
    }
  }

  useEffect(async () => {
    if (orderId) {
      // console.log({ orderId })
      post(
        `admin/offline-order/create-new-order`,
        { order_id: orderId, orderType: 'online' },
        0,
      ).then((data) => {
        if (data.status) {
          // console.log({ data: data.order._id })
          navigate(`/admin/offline-order/bags/${data.order._id}`)
        } else {
          toast.error(data.message)
        }
      })
    }
  }, [orderId])

  return (
    <CContainer>
      <CRow>
        <CCol xs={6}>
          <CCardGroup>
            <CCard className="p-4">
              <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
              <CCardGroup className="text-center">
                <CCardBody style={{ padding: '30px', textAlign: 'center' }}>
                  <div className="card-details">
                    <h4>Scan order</h4>
                    <p>For customer’s app order</p>
                  </div>
                  <img src={QRCodeIMG} alt="barcode" />
                  <div>  <input
                    autoFocus={true}
                    // disabled
                    // hidden
                    style={{
                      // border: 'none',
                      // outline: 'none',
                      // width: '0px', height: '0px'
                    }}
                    ref={inputRef}
                    name="qrValue"
                    id="qrValue"
                    value={qrValue}
                    onChange={(event) => setQRValue(event.target.value)}
                    onKeyPress={handleKeyPress}
                  // onClick={console.log('running')}
                  /></div>

                  <br />
                  <button
                    onClick={handleKeyPress}
                    // to="/admin/offline-bags/scan"
                    className="btn btn-success"
                    style={{ background: 'rgb(211, 220, 64)', border: 'rgb(211, 220, 64)' }}
                  >
                    Scan
                  </button>
                </CCardBody>
              </CCardGroup>
            </CCard>
          </CCardGroup>
        </CCol>
        <CCol xs={6} className="d-flex">
          <CCardGroup className="w-100">
            <CCard className="p-4">
              <CCardGroup>
                <CCardBody style={{ padding: '30px', textAlign: 'center' }}>
                  <div className="card-details">
                    <h4>Create new order</h4>
                    <p>For offline order</p>
                  </div>
                  <Link
                    to="/admin/offline-user-list"
                    className="btn btn-success"
                    style={{ background: 'rgb(211, 220, 64)', border: 'rgb(211, 220, 64)' }}
                  >
                    Create New offline order
                  </Link>
                </CCardBody>
              </CCardGroup>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  )
}

export default OfflineOrderIntro

