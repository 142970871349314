import React, { useState, useRef, useEffect } from 'react'
import { Formik } from 'formik'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { baseURL, ImageUrl } from 'src/config/config'
import { put } from 'src/utils/apiManager'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CImage,
  CFormLabel,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilImage, cilLockLocked, cilPhone, cilUser } from '@coreui/icons'
import { DefaultInput } from 'src/components/common/input'
import { editTemplate } from 'src/utils/validator'
import { Ckeditor } from './Ckeditor'
import { FormLabel } from '@mui/material'
import { ErrorDesc } from 'src/components/common/commonError'

function EditTemplate() {
  const navigate = useNavigate()
  const { state } = useLocation()
  // console.log(state)
  const formRef = useRef(null)
  const fileRef = useRef(null)
  const [description, setDescription] = useState('')
  const [isDisable, setIsDisable] = useState(false)

  useEffect(() => {
    if (state != null) {
      formRef.current.setFieldValue('title', state?.title)
      formRef.current.setFieldValue('subject', state?.subject)
      formRef.current.setFieldValue('description', state?.description)
    }
  }, [state])


  const submit = async (values) => {
    //  console.log("my values",values)
  const Url = `/admin/Template/update-temp`
  let data = {
    id: state?._id,
    description: values?.description,
    subject: values?.subject,
    title: values?.title
  }
  put(Url,data).then(data => {
      handleResponse(data)
    // console.log(data)
  })
  }
  const handleResponse = (data) => {
    // console.log(data)
    if (data?.status == true) {
      toast.success(data?.message)
      setTimeout(() => {
        navigate('/admin/template')
      }, 1000)
    }
    // ShowToast(data?.message, data?.status);
    formRef.current.resetForm()
    // fileRef.current.value = null
  }

  return (
    <>
      <CContainer >
            <CRow>
            <CCol>
                <CCardGroup>
                <CCard className="p-4">
                    <CCol xs={12}>
                    <CCardGroup>
                        <CCardBody>
                        <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                    <CRow>
                          <CCol md="11">
                    <h1>Edit Template</h1>
                          </CCol>                    
                          <CCol md="1">
                            <Link className={'btn btn-success mt-2'} style={{background:'#d3dc40',border: '#d3dc40' }} to={{ pathname: `/admin/template` }}>
                                Back
                            </Link>
                          </CCol>
                        </CRow>
                    <Formik
                      innerRef={formRef}
                      initialValues={{
                        title: '',
                        subject: '',
                        description: '',
                      }}
                      validationSchema={editTemplate}
                      onSubmit={(values, { setSubmitting }) => {
                        submit(values)
                        setSubmitting(false)
                      }}
                    >
                      {(props) => (
                        <form onSubmit={props?.handleSubmit}>
                         <CFormLabel>Title</CFormLabel>
                          <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                          <DefaultInput
                            type={'text'}
                            placeholder={'Title'}
                            id={'title'}
                            value={props?.values?.title}
                            onChange={props?.handleChange('title')}
                            onBlur={() => props?.setFieldTouched('title', true)}
                            error={props?.touched?.title && props?.errors?.title}
                          />
                          <CFormLabel>Subject</CFormLabel>
                          <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                          <DefaultInput
                            type={'text'}
                            placeholder={'Subject'}
                            id={'subject'}
                            value={props?.values?.subject}
                            onChange={props?.handleChange('subject')}
                            onBlur={() => props?.setFieldTouched('subject', true)}
                            error={props?.touched?.subject && props?.errors?.subject}
                          />
                          <FormLabel> Description</FormLabel>
                          <span className='text-danger' style={{marginLeft:'5px'}}>*</span>
                          <Ckeditor
                            setDescription={setDescription}
                            description={description}
                            value={props?.values?.description}
                            onChange={(val) => {
                              setDescription(val)
                              // console.log(val,"DESCRIPT");
                              if(state?.description == val) setIsDisable(false)
                              else setIsDisable(true)
                              props?.setFieldValue('description',val)
                            }}
                            onBlur={() => props?.setFieldTouched('description', true)}
                            error={props?.touched?.description && props?.errors?.description}
                          />
                          <CRow>
                            <CCol md="2">
                              <button
                                type="submit"
                                className={'btn btn-success mt-2'}
                                disabled={!isDisable || props?.isSubmitting}
                              >
                                Submit
                              </button>
                            </CCol>
                            <CCol md="2">
                              <button
                                type="button"
                                className={'btn btn-warning mt-2'}
                                onClick={() => navigate('/admin/template')}
                              >
                                Cancel
                              </button>
                            </CCol>
                            <CCol md="3"></CCol>
                          </CRow>
                        </form>
                      )}
                    </Formik>
                        </CCardBody>
                    </CCardGroup>
                    </CCol>               
                </CCard>
                </CCardGroup>
            </CCol>
            </CRow>
      </CContainer>       
    </>
  )
}

export default EditTemplate
