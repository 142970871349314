import React, { Suspense } from 'react'
import { authRoutes } from 'src/routes'
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom'

export default function Auth() {
  const navigate = useNavigate()
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      // console.log(prop)
      return <Route path={prop.path} element={<prop.element />} key={prop.name} />
    })
  }
  return (
    <>
      <Routes>
        {getRoutes(authRoutes)}
        <Route path="/*" element={<Navigate to="admin/login" replace />} />
      </Routes>
    </>
  )
}
