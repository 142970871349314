import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CCardSubtitle,
  CRow,
} from '@coreui/react'
import { FaArrowLeft } from 'react-icons/fa'

export default function TemplateDetail() {
  const { state } = useLocation()
  // console.log(state)
  return (
    <>
      {/* <div>TemplateDetail</div> */}
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <CRow>
                    <CCol>
                      {' '}
                      <Link style={{ color: '#000' }} to={{ pathname: `/admin/template` }}>
                        <FaArrowLeft />
                      </Link>
                      <span> Template Detail</span>
                    </CCol>
                  </CRow>
                </CHeader>
                <CCardBody>
                  <CRow>
                    <CCol md={1}>
                      <strong>Title:-</strong>
                    </CCol>
                    <CCol>
                      <span>{state?.title}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={1}>
                      <strong>Subject:-</strong>
                    </CCol>
                    <CCol>
                      <span>{state?.subject}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={1}>
                      <strong>Description:-</strong>
                    </CCol>
                    <CCol>
                      <span dangerouslySetInnerHTML={{ __html: state?.description }}></span>
                    </CCol>
                  </CRow>                  
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}
