import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { post, get, deleteApi } from '../../utils/apiManager'
import CIcon from '@coreui/icons-react'
import { cilPen, cilPlus, cilTrash } from '@coreui/icons'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CCardSubtitle,
  CTable,
  CRow,
  CImage,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CButton,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import { DefaultInputWithIcon } from 'src/components/common/input'
import Pagination from 'react-js-pagination'
import { FaRegEye, FaEdit, FaTrash } from 'react-icons/fa'
import ShowMoreText from 'react-show-more-text'

const Index = () => {
  const navigate = useNavigate();
  const [cityData, setCityData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [searchItem, setSearchItem] = useState("");
  const [pageData, setPageData] = useState({
    page: 1,
    perPage: 10,
    searchItem: "",
  });

  useEffect(() => {
    getData();
  }, [pageData]);

  const getData = () => {
    get(
      `/admin/city/get?page=${pageData?.page || 1}&perPage=${pageData?.perPage || 10
      }&searchItem=${pageData?.searchItem || ""}`
    ).then((res) => handleResponse(res));
  };

  const handleResponse = (res) => {
    if (!res.status) toast.success(res.message, res.status);
    else {
      setCityData([...res?.data]);
      setTotalPages(res?.pages);
    }
  };

  const changePage = (index) => {
    setPageData({
      ...pageData,
      page: index,
    });
  };

  const statusChange = (status, id) => {
    let default_status = ''
    if (status == 0) default_status = "Active"
    else default_status = "Inactive"

    if (confirm(`Are you sure you want to ${default_status} the city`)) {
      let formData = {
        _id: id,
      };
      post(`${status ? "/admin/city/inactive" : "/admin/city/active"}`, formData).then(
        (data) => {
          handleStatusChange(data);
        });
    }
  };

  const handleStatusChange = (data) => {
    toast.success(data.message, data.status);
    getData();
  };

  const onClickUserEdit = async (value) => {
    navigate('/admin/city/edit-city', { state: value });
  };

  const deleteData = (id) => {
    if (confirm("Are you sure you want to delete?")) {
      deleteApi(`/admin/city/delete?_id=${id}`).then((res) => {
        handleDelete(res);
      });
    }
  };

  const handleDelete = (data) => {
    if (data.status == true) {
      toast.success(data.message)
    } else {
      toast.error(data.message)
    }
    getData();
  };

  const onSearchCLick = () => {
    setPageData({
      ...pageData,
      searchItem: searchItem,
    });
  };

  return (
    <>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <CCardSubtitle as="h4">City </CCardSubtitle>
                  <CRow>
                    <CCol md="8">
                      <DefaultInputWithIcon
                        type={'text'}
                        placeholder="Search"
                        customType={'search'}
                        value={searchItem}
                        onClick={onSearchCLick}
                        onChange={(e) => {
                          onSearchCLick()
                          setSearchItem(e.target.value)
                        }}
                      />
                    </CCol>
                    <CCol md="4">
                      <Link to={{ pathname: '/admin/city/add-city' }} className="btn btn-dark">
                        <CIcon icon={cilPlus} />
                        Add
                      </Link>
                    </CCol>
                  </CRow>
                </CHeader>

                <CCardBody>
                  <CTable bordered hover responsive>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell>S. No</CTableHeaderCell>
                        <CTableHeaderCell>State Name (EN)</CTableHeaderCell>
                        {/* <CTableHeaderCell>State Name (TH)</CTableHeaderCell> */}
                        <CTableHeaderCell>City Name (EN)</CTableHeaderCell>
                        {/* <CTableHeaderCell>City Name (TH)</CTableHeaderCell> */}
                        <CTableHeaderCell>Status</CTableHeaderCell>
                        <CTableHeaderCell>Action</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {cityData.map((item, index) => {
                        return (
                          <CTableRow key={index}>
                            <CTableDataCell>{pageData?.perPage * (pageData?.page - 1) + index + 1}</CTableDataCell>
                            <CTableDataCell>{item?.state_id?.stateName_EN}</CTableDataCell>
                            {/* <CTableDataCell>{item?.state_id?.stateName_TH}</CTableDataCell> */}
                            <CTableDataCell>{item?.cityName_EN}</CTableDataCell>
                            {/* <CTableDataCell>{item?.cityName_TH}</CTableDataCell> */}
                            <CTableDataCell>
                              <CButton
                                size="sm"
                                outline={item?.status ? "1" : "0"}
                                onClick={() =>
                                  statusChange(item?.status, item?._id)
                                }
                              >
                                {item?.status ? "Active" : "Inactive"}
                              </CButton>
                            </CTableDataCell>
                            <CTableDataCell>
                              <span className="mx-2" onClick={() => onClickUserEdit(item)} style={{ fontSize: '20px', color: '#d3dc40', cursor: 'pointer' }}><FaEdit /></span>
                              <span className="mx-2 text-danger" onClick={() => deleteData(item?._id)} style={{ fontSize: '20px', padding: '5px', cursor: 'pointer' }}><FaTrash /></span>
                            </CTableDataCell>
                          </CTableRow>
                        );
                      })}
                    </CTableBody>
                  </CTable>
                  {!cityData.length && (
                    <>
                      <h1>No Data</h1>
                    </>
                  )}
                  {cityData?.length ? <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={pageData?.page}
                    itemsCountPerPage={pageData?.perPage}
                    totalItemsCount={totalPages * 10}
                    pageRangeDisplayed={5}
                    onChange={changePage}
                  /> : ''}
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default Index