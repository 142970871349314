import React, { useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom';
import { baseURL } from 'src/config/config';
import { post } from "../../../utils/apiManager";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilEnvelopeClosed } from '@coreui/icons'
import { useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import logo from 'src/assets/images/logo.png'

export default function ResetPassword() {

  const params = useParams();
  const navigate = useNavigate();
  const [paramsData, setparamsData] = useState({});
  const { register, handleSubmit, formState: { errors: errors }, reset } = useForm();
  const [newPassVisibility, setNewPassVisibility] = useState(false)
  const [confrmNewPassVisibility, setConfrmNewPassVisibility] = useState(false)
  const [passwordType, setPasswordType] = useState({
    newPassword: 'password',
    confirmPassword: 'password'
  })

  useEffect(() => {
    let data = atob(params?.id);
    data = data?.split(":");
    setparamsData({ _id: data[0], otp: data[1] });
    let formData = {
      _id: data[0],
      otp: data[1],
    };
    post(`${baseURL}/verifyOTP`, formData).then((data) => handleResponse(data));
  }, []);
  const handleResponse = (data) => {
  };

  const handlePasswordVisibility = (e, type) => {
    e.preventDefault();
    // confirmNewPassword
    // newPassword
    if (type == 'newPassword') {
      setNewPassVisibility(!newPassVisibility)
    } else {
      setConfrmNewPassVisibility(!confrmNewPassVisibility)
    }
  }

  useEffect(() => {
    if (newPassVisibility) {
      setPasswordType({ ...passwordType, newPassword: "text" })
    } else {
      setPasswordType({ ...passwordType, newPassword: "password" })
    }
  }, [newPassVisibility]);

  useEffect(() => {
    if (confrmNewPassVisibility) {
      setPasswordType({ ...passwordType, confirmPassword: "text" })
    } else {
      setPasswordType({ ...passwordType, confirmPassword: "password" })
    }
  }, [confrmNewPassVisibility]);

  const onClickResetPassword = (value) => {
    if (value?.newpassword == value?.confirmpassword) {
      let formData = {
        _id: paramsData?._id,
        password: value?.newpassword,
      };
      console.log(formData)
      post("/admin/reset-password", formData).then((data) =>
        handleSubmit(data)
      );
      const handleSubmit = (data) => {
        console.log("data", data)
        if (data?.status == true) {
          toast.success(data?.message)

          setTimeout(() => {
            navigate("/admin/login");
          }, 500)
        }
      };
    } else {
      toast.error("New Password and Confirm Password are not match")
      reset({
        newpassword: '',
        confirmpassword: ''
      })
    }
  };

  return (
    <>
      <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md={6}>
              <CCardGroup>
                <CCard className="p-4">
                  <CCardBody>
                    <ToastContainer
                      position={toast.POSITION.TOP_RIGHT}
                      autoClose={2000}
                    />
                    <CForm onSubmit={handleSubmit(onClickResetPassword)}>
                      <CRow>
                        <CCol>
                          <h2>Reset Password</h2>
                        </CCol>
                        <CCol md={2}><img src={logo} alt="LOGO" /></CCol>
                      </CRow>

                      {/* <p className="text-medium-emphasis">Forgot Password</p> */}
                      <CFormLabel>New Password</CFormLabel>
                      <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                      <CInputGroup className="mb-4">
                        <CInputGroupText>
                          <CIcon icon={cilLockLocked} />
                        </CInputGroupText>
                        <CFormInput
                          type={passwordType?.newPassword}
                          placeholder="New Password"
                          autoComplete="current-password"
                          name="password" {...register('newpassword', {
                            required: 'Password is required.',
                            // pattern: {
                            //   value: /^(?=.*[A-Z].*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8}$/,
                            //   message: "Password should contain lower & uppercase, a number, and a special character with minimum 8 character",
                            // }  
                          })}
                        />
                        <CInputGroupText style={{ cursor: 'pointer' }} onClick={(e) => handlePasswordVisibility(e, 'newPassword')}>
                          {passwordType?.newPassword == "password" ?
                            <i className="fa-solid fa-eye-slash"></i> : <i className="fa-solid fa-eye"></i>}
                        </CInputGroupText>
                      </CInputGroup>
                      <div className="text-danger mb-2" style={{ marginTop: "-15px" }}>
                        <ErrorMessage errors={errors} name="newpassword" />
                      </div>

                      <CFormLabel>Confirm Password</CFormLabel>
                      <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                      <CInputGroup className="mb-4">
                        <CInputGroupText>
                          <CIcon icon={cilLockLocked} />
                        </CInputGroupText>
                        <CFormInput
                          type={passwordType?.confirmPassword}
                          placeholder="Confirm Password"
                          autoComplete="current-password"
                          name="password" {...register('confirmpassword',
                            {
                              required: 'Confirm Password is required.',
                              // pattern:  /^(?=.*[A-Z].*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8}$/
                            })}
                        />
                        <CInputGroupText style={{ cursor: 'pointer' }} onClick={(e) => handlePasswordVisibility(e, 'confirmNewPassword')}>
                          {passwordType?.confirmPassword == "password" ?
                            <i className="fa-solid fa-eye-slash"></i> : <i className="fa-solid fa-eye"></i>}
                        </CInputGroupText>
                      </CInputGroup>
                      <div className="text-danger mb-2" style={{ marginTop: "-15px" }}>
                        <ErrorMessage errors={errors} name="confirmpassword" />
                      </div>
                      <CRow>
                        <CCol xs={6}>
                          <CButton type="submit" color="primary" className="px-4" >
                            Submit
                          </CButton>
                        </CCol>
                      </CRow>
                    </CForm>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </>
  )
}
