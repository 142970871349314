import React, { useRef, useState } from 'react';
import { Formik } from 'formik';
import { useNavigate, Link } from 'react-router-dom'
import { post } from 'src/utils/apiManager';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CFormLabel,
  CRow,
} from '@coreui/react'
import { DefaultInput } from 'src/components/common/input'
import { validateVan } from 'src/utils/validator'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import moment from 'moment';

function addVan() {
  const navigate = useNavigate()
  const formRef = useRef(null)
  const [insuranceValidity, setInsuranceValidity] = useState(null);
  const [registartionValidity, setRegistartionValidity] = useState(null);
  const [isReset, setIsReset] = useState(true)
  const [startMinDate, setStartMinDate] = useState(moment().format())
  const e = moment(startMinDate).add(+1, 'days')

  const handleDateChange = (event, type) => {
    if (type == 'insuranceValidity') {
      setInsuranceValidity(event?.$d)
    } else {
      setRegistartionValidity(event?.$d)
    }
  }

  const submit = async (values) => {
    const insuranceDate = (moment(values?.insuranceValidity)).format("YYYY-MM-DDTHH:mm:ssZ")
    const registartionDate = (moment(values?.registartionValidity)).format("YYYY-MM-DDTHH:mm:ssZ")
    let VanData = {
      vanNo: values?.vanNo,
      registrationNo: values?.registrationNo,
      vehicleModal: values?.vehicleModal,
      insuranceValidity: insuranceDate,
      registrationValidity: registartionDate,
    }
    post(`admin/Van/insert`, VanData).then((data) => {
      handleResponse(data)
    })
  }
  const handleResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      setTimeout(() => {
        navigate('/admin/van')
      }, 800)
    }
    else {
      toast.error(data?.message)
    }
    formRef.current.resetForm()
  }
  const onClickReset = () => {
    console.log("this")
    console.log(this)
    // Reset Button Function
    // formRef.current.setFieldValue('vanNo', '')    
  };

  return (
    <>
      <CContainer >
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <CRow>
                        <CCol md="11">
                          <h1>New Van</h1>
                        </CCol>
                        <CCol md="1">
                          <Link className={'btn btn-success mt-2'} style={{ background: '#d3dc40', border: '#d3dc40' }} to={{ pathname: `/admin/van` }}>
                            Back
                          </Link>
                        </CCol>
                      </CRow>
                      <Formik
                        initialValues={{
                          vanNo: '',
                          registrationNo: '',
                          vehicleModal: '',
                          insuranceValidity: '',
                          registartionValidity: ''
                        }}
                        validationSchema={validateVan}
                        onSubmit={(values, { setSubmitting }) => {
                          submit(values)
                          setSubmitting(false)
                        }}
                        innerRef={formRef}
                      >
                        {(props) => (
                          <form onSubmit={props?.handleSubmit}>
                            <CRow>
                              <CCol md={6}>
                                <CFormLabel>Registration plate</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={"text"}
                                  placeholder={"Van Number"}
                                  // maxLength={6}
                                  value={props?.values?.vanNo}
                                  onChange={props?.handleChange("vanNo")}
                                  onBlur={() => props?.setFieldTouched("vanNo", true)}
                                  error={props?.touched?.vanNo && props?.errors?.vanNo}
                                />
                                <CFormLabel>Registration Number</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={"text"}
                                  placeholder={"Registration Number"}
                                  // maxLength={6}
                                  value={props?.values?.registrationNo}
                                  onChange={props?.handleChange("registrationNo")}
                                  onBlur={() => props?.setFieldTouched("registrationNo", true)}
                                  error={props?.touched?.registrationNo && props?.errors?.registrationNo}
                                />
                                <CFormLabel>Vehicle Modal</CFormLabel>
                                {/* <span className='text-danger' style={{ marginLeft: '5px' }}>*</span> */}
                                <DefaultInput
                                  type={"text"}
                                  placeholder={"Vehicle Modal"}
                                  // maxLength={6}
                                  value={props?.values?.vehicleModal}
                                  onChange={props?.handleChange("vehicleModal")}
                                  onBlur={() => props?.setFieldTouched("vehicleModal", true)}
                                  error={props?.touched?.vehicleModal && props?.errors?.vehicleModal}
                                />
                              </CCol>
                              <CCol md={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <div><CFormLabel>Insurance Validity</CFormLabel>
                                    {/* <span className='text-danger' style={{ marginLeft: '5px' }}>*</span> */}
                                    </div>
                                  <DesktopDatePicker
                                    inputFormat="DD/MM/YYYY"
                                    name="insuranceValidity"
                                    style={{ height: '68px', height: '595px' }}
                                    value={insuranceValidity}
                                    minDate={e?._d}
                                    // minDate={moment()}
                                    // maxDate={fromMax}
                                    onChange={(e) => {
                                      handleDateChange(e, 'insuranceValidity')
                                      props?.setFieldValue('insuranceValidity', e?.$d)
                                    }}
                                    renderInput={(params) => <TextField style={{ marginTop: '5px' }}
                                      name="insuranceValidity" {...params}
                                    />}
                                  />
                                </LocalizationProvider>

                                {insuranceValidity == null && props?.touched?.insuranceValidity == true ? <p className='text-danger mt-2'>{props?.errors?.insuranceValidity}</p> : ''}

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <div><CFormLabel>Registartion Validity</CFormLabel>
                                    {/* <span className='text-danger' style={{ marginLeft: '5px' }}>*</span> */}
                                    </div>
                                  <DesktopDatePicker
                                    inputFormat="DD/MM/YYYY"
                                    name="registartionValidity"
                                    style={{ height: '68px', height: '595px' }}
                                    value={registartionValidity}
                                    minDate={e?._d}
                                    onChange={(e) => {
                                      handleDateChange(e, 'registartionValidity')
                                      props?.setFieldValue('registartionValidity', e?.$d)
                                    }}
                                    renderInput={(params) => <TextField style={{ marginTop: '5px' }}
                                      name="registartionValidity" {...params}
                                    />}
                                  />
                                </LocalizationProvider>

                                {registartionValidity == null && props?.touched?.registartionValidity == true ? <p className='text-danger mt-2'>{props?.errors?.registartionValidity}</p> : ''}

                              </CCol>
                            </CRow>



                            <CRow>
                              <CCol md="2">
                                <button
                                  type="submit"

                                  className={'btn btn-success mt-2'}
                                  disabled={props?.isSubmitting}
                                >
                                  Submit
                                </button>
                              </CCol>
                              <CCol md="2">
                                <button
                                  type="button"
                                  className={'btn btn-warning mt-2'}
                                  onClick={() => onClickReset(props?.isSubmitting)}
                                >
                                  Reset
                                </button>
                              </CCol>
                              <CCol md="3"></CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default addVan