import React,{useState,useEffect} from 'react'
import { Link, useParams } from 'react-router-dom'
import { get } from 'src/utils/apiManager';
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CRow
} from '@coreui/react'
import { FaArrowLeft } from 'react-icons/fa'

function AttributeView() {
  const { id } = useParams()
  const [attributeData, setAttributeData] = useState();

  const getAttributeDetails = ()=>{
    get(
      `/admin/attribute/get-single?_id=${atob(id)}`
    ).then((response) => {
      if (response?.status) {
        setAttributeData(response?.data)
      }
    })
  
  }
    useEffect(() => {
      getAttributeDetails();
    }, []);

  return (
    <>
    <CContainer fluid>
      <CRow>
        <CCol>
          <CCardGroup>
            <CCard className="p-4">
              <CHeader>
                <CRow>
                  <CCol >
                    {' '}
                    <Link style={{ color: '#000' }} to={{ pathname: `/admin/attribute` }}>
                      <FaArrowLeft />
                    </Link>
                    <span>  Attribute Information</span>
                  </CCol>
                </CRow>
              </CHeader>
              <CCardBody>
                <CRow>
                  <CCol md={3}>
                    <strong>Attribute Name (EN) :-</strong>
                  </CCol>
                  <CCol>
                    <span>{attributeData?.attributeName_EN}</span>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md={3}>
                    <strong>Attribute Name (TH) :-</strong>
                  </CCol>
                  <CCol>
                    <span>{attributeData?.attributeName_TH}</span>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </>
  )
}

export default AttributeView
