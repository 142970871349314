import React, { useRef, useState } from 'react';
import { Formik } from 'formik';
import { useNavigate, Link } from 'react-router-dom'
import { post } from 'src/utils/apiManager';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CFormLabel,
  CRow,
  CFormInput,
  CForm,
  CFormSelect,
  CFormCheck,
  CButton,
  CImage
} from '@coreui/react'
import { DefaultInput } from 'src/components/common/input'
import { validateGlobalSetting } from 'src/utils/validator'

function add() {
  const navigate = useNavigate()
  const formRef = useRef(null)
  const fileRef = useRef(null)
  const [invalidImage, setInvalidImage] = useState('')
  const [socialImagePreview, setSocialImagePreview] = useState('')
  const submit = async (values) => {
    if (invalidImage?.length === 0) {
      let FaqData = {
        socialLink: values?.socialLink,
        image: values?.image,
      }
      post(`admin/social/insert`, FaqData, 1).then((data) => {
        handleResponse(data)
      })
    } else {
      return
    }
  }
  const handleResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      setTimeout(() => {
        navigate('/admin/social-setting')
      }, 800)
    }
    formRef.current.resetForm()
  }
  const onClickReset = () => {
    // Reset Button Function
    formRef.current.setFieldValue('socialLink', '')
    fileRef.current.value = null

  };
  return (
    <>
      <CContainer >
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <CRow>
                        <CCol md="11">
                          <h1>New Social Setting</h1>
                        </CCol>
                        <CCol md="1">
                          <Link className={'btn btn-success mt-2'} style={{ background: '#d3dc40', border: '#d3dc40' }} to={{ pathname: `/admin/social-setting` }}>
                            Back
                          </Link>
                        </CCol>
                      </CRow>
                      <Formik
                        initialValues={{
                          socialLink: '',
                          image: '',
                        }}
                        validationSchema={validateGlobalSetting}
                        onSubmit={(values, { setSubmitting }) => {
                          submit(values)
                          setSubmitting(false)

                        }}
                        innerRef={formRef}
                      >
                        {(props) => (
                          <form onSubmit={props?.handleSubmit}>
                            <CFormLabel>Social Link</CFormLabel>
                            <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                            <DefaultInput
                              type={"text"}
                              placeholder={"Social Link"}
                              value={props?.values?.socialLink}
                              onChange={props?.handleChange("socialLink")}
                              onBlur={() => props?.setFieldTouched("socialLink", true)}
                              error={props?.touched?.socialLink && props?.errors?.socialLink}
                            />

                            <CFormLabel>Image</CFormLabel>
                            <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                            <DefaultInput
                              type={'file'}
                              placeholder={'Image'}
                              accept="image/*"
                              refr={fileRef}
                              onChange={(e) => {
                                const imageFile = e?.target?.files[0];
                                if (!imageFile?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
                                  setInvalidImage("Please select valid image")
                                }
                                else {
                                  setInvalidImage('')
                                }
                                //for image preview 
                                const reader = new FileReader();
                                reader?.addEventListener("load", () => setSocialImagePreview(reader?.result));
                                reader?.readAsDataURL(e?.target?.files[0]);
                                //for image preview
                                props?.setFieldValue('image', e?.target?.files[0])
                              }}
                              onBlur={() => props?.setFieldTouched('image', true)}
                              error={(props?.touched?.image && props?.errors?.image) || invalidImage}
                            />
                            {/* for image preview */}
                            {socialImagePreview?.length > 0 ?
                              <>
                                <CCol>
                                  <CImage
                                    rounded
                                    thumbnail
                                    className="mt-2"
                                    src={`${socialImagePreview}`}
                                    style={{
                                      maxHeight: '80px',
                                      maxWidth: '80px',
                                      alignItems: 'center',
                                    }}
                                  />
                                </CCol>
                              </>
                              : ''}
                            {/* for image preview */}
                            <CRow>
                              <CCol md="2">
                                <button
                                  type="submit"

                                  className={'btn btn-success mt-2'}
                                  disabled={props?.isSubmitting}
                                >
                                  Submit
                                </button>
                              </CCol>
                              <CCol md="2">
                                <button
                                  type="button"
                                  className={'btn btn-warning mt-2'}
                                  onClick={onClickReset}
                                >
                                  Reset
                                </button>
                              </CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default add