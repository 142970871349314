import React,{useRef,useEffect,useState} from 'react';
import { Formik } from 'formik';
import { useLocation,useNavigate,Link } from 'react-router-dom'
import { put } from 'src/utils/apiManager';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import {
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CFormLabel,
    CRow,
  } from '@coreui/react'
  import { DefaultInput } from 'src/components/common/input'
import { validateItemSize } from 'src/utils/validator'

function edit() {
    const navigate = useNavigate()
    const { state } = useLocation()
    const [isDisable, setIsDisable] = useState(false)
    const formRef = useRef(null)
    const submit = async (values) => {
        let ItemData = {
          itemSize_EN: values?.itemSize_EN,
          itemSize_TH: values?.itemSize_TH,
          }
      put(`admin/ItemSize/update?_id=${state?._id}`, ItemData).then((data) => handleResponse(data))
    }
    const handleResponse = (data) => {
      if (data.status == true) {
        toast.success(data.message)
        setTimeout(() => {
          navigate('/admin/item/item-size')
        }, 800)
      }
      formRef.current.resetForm()
    }
    useEffect(() => {
      if (state != null) {
        formRef.current.setFieldValue('itemSize_EN', state?.itemSize_EN)
        formRef.current.setFieldValue('itemSize_TH', state?.itemSize_TH)
      }
    }, [state])
  return (
    <>
    <CContainer >
     <CRow>
       <CCol>
         <CCardGroup>
           <CCard className="p-4">
             <CCol xs={12}>
             <CCardGroup>
                 <CCardBody>
                 <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                     <CRow>
                       <CCol md="11">
                     <h1>Edit Item Size</h1>
                       </CCol>                    
                       <CCol md="1">
                         <Link className={'btn btn-success mt-2'} style={{background:'#d3dc40',border: '#d3dc40' }} to={{ pathname: `/admin/item/item-size` }}>
                             Back
                         </Link>
                       </CCol>
                     </CRow>
                     <Formik
                     initialValues={{
                      itemSize_EN: '',
                        itemSize_TH: '',
                    }}
                     validationSchema={validateItemSize}
                     onSubmit={(values, { setSubmitting }) => {
                         submit(values)
                         setSubmitting(false)
                         
                     }}
                     innerRef={formRef}
                     >
                     {(props) => (
                         <form onSubmit={props?.handleSubmit}>
                            <CRow xs={12}>
                                <CCol md={6}>
                                    <CFormLabel>Title (EN)</CFormLabel>
                                    <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                    <DefaultInput
                                        type={"text"}
                                        placeholder={"Title (EN)"}
                                        value={props?.values?.itemSize_EN}
                                        onChange={props?.handleChange("itemSize_EN")}
                                        onKeyUp={() => {
                                            if (state?.itemSize_EN == props?.values?.itemSize_EN) setIsDisable(false)
                                            else setIsDisable(true)
                                          }}
                                        onBlur={() => props?.setFieldTouched("itemSize_EN", true)}
                                        error={props?.touched?.itemSize_EN && props?.errors?.itemSize_EN}
                                    />
                                </CCol>
                                <CCol md="6">
                                    <CFormLabel>Tilte (TH)</CFormLabel>
                                    <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                    <DefaultInput
                                        type={"text"}
                                        placeholder={"Tilte (TH)"}
                                        value={props?.values?.itemSize_TH}
                                        onChange={props?.handleChange("itemSize_TH")}
                                        onKeyUp={() => {
                                            if (state?.itemSize_TH == props?.values?.itemSize_TH) setIsDisable(false)
                                            else setIsDisable(true)
                                          }}
                                        onBlur={() => props?.setFieldTouched("itemSize_TH", true)}
                                        error={props?.touched?.itemSize_TH && props?.errors?.itemSize_TH}
                                    />
                                </CCol>
                            </CRow>

                         <CRow>
                             <CCol md="11">
                             <button
                               type="submit"
                               className={'btn btn-success mt-2'}
                               disabled={!isDisable || props?.isSubmitting}
                             >
                               Submit
                             </button>
                             </CCol>
                             <CCol md="1">
                             <button
                                 type="button"
                                 className={'btn btn-warning mt-2'}
                                 onClick={() => navigate('/admin/item/item-size')}
                             >
                                 Cancel
                             </button>
                             </CCol>
                             <CCol md="3"></CCol>
                         </CRow>
                         </form>
                     )}
                     </Formik>
                 </CCardBody>
             </CCardGroup>
             </CCol>               
           </CCard>
         </CCardGroup>
       </CCol>
     </CRow>
   </CContainer> 
 </>
  )
}

export default edit