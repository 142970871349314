import React from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import Indent from '@ckeditor/ckeditor5-build-classic/build/ckeditor'
// import ImageInsert from '@ckeditor/ckeditor5-image/src/imageinsert';
import { ErrorDesc } from 'src/components/common/commonError'
import { uploadAdapterPlugin } from 'src/utils/imageUpload'

export const Ckeditor = (props) => {

  // console.log(props)
  return (
    <>
      <CKEditor
        editor={ClassicEditor}
        data={props.value}
        config={{
          extraPlugins: [uploadAdapterPlugin],
          // Plugin: [SimpleUploadAdapter],
          // toolbar: ['bold', 'italic']
          // toolbar: ['exportPDF', 'exportWord', '|',
          //   'findAndReplace', 'selectAll', '|',
          //   'heading', '|',
          //   'bold', 'italic', 'strikethrough', 'underline', 'code', 'subscript', 'superscript', 'removeFormat', '|',
          //   'bulletedList', 'numberedList', 'todoList', '|',
          //   'outdent', 'indent', '|',
          //   'undo', 'redo',
          //   '-',
          //   'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor', 'highlight', '|',
          //   'alignment', '|',
          //   'link', 'insertImage', 'blockQuote', 'insertTable', 'mediaEmbed', 'codeBlock', 'htmlEmbed', '|',
          //   'specialCharacters', 'horizontalLine', 'pageBreak', '|',
          //   'textPartLanguage', '|',
          //   'sourceEditing']
          filebrowserUploadUrl: ("https://ckeditor.com/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Files"),
        }}
        // onBlur={props.onBlur}
        // onChange={props.onChange}
        onReady={(editor) => {
          uploadAdapterPlugin(editor)
          // You can store the "editor" and use when it is needed.
          //  console.log('Editor is ready to use!', editor)
        }}
        onChange={(event, editor) => {
          const data = editor.getData()
          // console.log(data)
          //  console.log({ event, editor, data},"CK EDITORRR")
          props?.onChange(data)
        }}
        onBlur={(event, editor) => {
          const data = editor.getData()
          props?.onChange(data)
          //  console.log('Blur.', editor,data)
        }}
        onFocus={(event, editor) => {
          // console.log('Focus.', editor)
        }}
        onKeyUp={(event, editor) => {
          // console.log(editor)
        }}
      />
      {props.error ? <ErrorDesc description_EN={props.error} /> : null}
      {/* {props.error ? <ErrorDesc description_TH={props.error} /> : null} */}
    </>
  )
}
