import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { post, get, deleteApi } from '../../utils/apiManager'
import CIcon from '@coreui/icons-react'
import { cilPlus } from '@coreui/icons'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CCardSubtitle,
  CTable,
  CRow,
  CImage,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CButton,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import { DefaultInputWithIcon } from 'src/components/common/input'
import Pagination from 'react-js-pagination'
import { FaRegEye, FaEdit, FaTrash } from 'react-icons/fa'
import ShowMoreText from 'react-show-more-text'

function index() {
  const navigate = useNavigate()
  const [areaData, setAreaData] = useState([])
  const [servicesData, serServicesData] = useState([])
  const [totalPages, settotalPages] = useState(0)
  const [searchItem, setsearchItem] = useState('')
  const [deletedArray, setDeletedArray] = useState([])
  const [pageData, setpageData] = useState({
    page: 1,
    perPage: 10,
    searchItem: '',
  })
  useEffect(() => {
    getData()
  }, [pageData])

  const getData = () => {
    get(
      `/admin/services/get-service?page=${pageData?.page || 1}&perPage=${pageData?.perPage || 10
      }&searchItem=${pageData?.searchItem || ''}`,
    ).then((data) => handleResponse(data))
  }
  const handleResponse = (data) => {
    if (!data?.status) toast.success(data?.message, data?.status)
    else {
      // setAreaData([...data?.data]);
      serServicesData([...data?.data])
      settotalPages(data?.pages)
    }
  }
  const changePage = (index) => {
    setpageData({
      ...pageData,
      page: index,
    })
  }
  const statusChange = (status, id) => {
    let default_status = ''
    if (status == 0) default_status = 'Active'
    else default_status = 'Inactive'
    if (confirm(`Are you sure you want to ${default_status} the services`)) {
      let formData = {
        _id: id,
      }
      post(`${status ? '/admin/services/inactive' : '/admin/services/active'}`, formData).then(
        (data) => {
          handleStatusChange(data)
        },
      )
    }
  }
  const handleStatusChange = (data) => {
    toast.success(data?.message, data?.status)
    getData()
  }
  const onClickUserEdit = async (value) => {
    // console.log(value)
    navigate('/admin/services/edit', { state: value })
  }
  const onClickUserView = (value) => {
    navigate(`/admin/services/view/${btoa(value?._id)}`, { state: value })
  }
  const deleteData = (id) => {
    if (confirm('Are you sure')) {
      let formData = {
        _id: id,
      }
      deleteApi(`/admin/services/delete?_id=${id}`).then((data) => {
        handleDelete(data)
      })
    }
  }
  const handleDelete = (data) => {
    toast.success(data?.message, data?.status)
    getData()
  }
  const onSearchCLick = () => {
    setpageData({
      ...pageData,
      searchItem: searchItem,
    })
  }

  const handleCheck = (id) => {
    let findValue = deletedArray.includes(id)
    if (findValue) {
      let filterData = deletedArray?.filter((item) => item != id)
      setDeletedArray(filterData)
    } else {
      setDeletedArray((prev) => [...prev, id])
    }
  }

  const deleteOrders = () => {
    post(`admin/services/bulk-delete`, { ids: deletedArray }).then((data) =>
      handleDeletedResponse(data),
    )
  }

  const handleDeletedResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      setDeletedArray([])
      getData()
    }
  }

  return (
    <>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <div className="d-flex" style={{ flexDirection: 'column' }}>
                    <CCardSubtitle as="h4">Services </CCardSubtitle>
                    {deletedArray.length > 0 && (
                      <button className="btn btn-danger mt-2 text-white" onClick={deleteOrders}>
                        Delete
                      </button>
                    )}
                  </div>
                  <CRow>
                    <CCol md="8">
                      <DefaultInputWithIcon
                        type={'text'}
                        placeholder="Search"
                        customType={'search'}
                        value={searchItem}
                        onClick={onSearchCLick}
                        onChange={(e) => {
                          onSearchCLick()
                          setsearchItem(e?.target?.value)
                        }}
                      />
                    </CCol>
                    <CCol md="4">
                      <Link to={{ pathname: '/admin/services/add' }} className="btn btn-dark">
                        <CIcon icon={cilPlus} />
                        Add
                      </Link>
                    </CCol>
                  </CRow>
                </CHeader>

                <CCardBody>
                  <CTable bordered hover responsive>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell>S. No</CTableHeaderCell>
                        <CTableHeaderCell>Service Name (EN)</CTableHeaderCell>
                        <CTableHeaderCell>Service Name (TH)</CTableHeaderCell>
                        <CTableHeaderCell>Status</CTableHeaderCell>
                        <CTableHeaderCell>Action</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {/* {servicesData.map((ele, index) => {
                        console.log(ele)
                        })} */}
                      {servicesData?.map((ele, index) => {
                        // console.log(ele)
                        return (
                          <CTableRow key={index} >
                            <CTableDataCell onClick={() => handleCheck(ele._id)}>
                              {pageData?.perPage * (pageData?.page - 1) + index + 1}
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  style={{ marginLeft: '2px', marginTop: '-20px' }}
                                  type="checkbox"
                                  value=""
                                  id="flexCheckDefault"
                                  checked={deletedArray?.includes(ele._id)}
                                // onChange={() => handleCheck(ele._id)}
                                />
                              </div>
                            </CTableDataCell>
                            <CTableDataCell>{ele?.serviceName_EN}</CTableDataCell>
                            <CTableDataCell>{ele?.serviceName_TH}</CTableDataCell>
                            <CTableDataCell>
                              <CButton
                                size="sm"
                                outline={ele?.status ? '1' : '0'}
                                style={{
                                  backgroundColor: ele?.status ? '#00796B' : '#D32F2F',
                                  borderColor: ele?.status ? '#00796B' : '#D32F2F',
                                }}
                                onClick={() => statusChange(ele?.status, ele?._id)}
                              >
                                {ele?.status ? 'Active' : 'Inactive'}
                              </CButton>
                            </CTableDataCell>
                            <CTableDataCell>
                              <span
                                className="mx-2"
                                onClick={() => onClickUserEdit(ele)}
                                style={{ fontSize: '20px', color: '#d3dc40', cursor: 'pointer' }}
                              >
                                <FaEdit />
                              </span>
                              <span
                                className="mx-2 text-danger"
                                onClick={() => deleteData(ele?._id)}
                                style={{ fontSize: '20px', padding: '5px', cursor: 'pointer' }}
                              >
                                <FaTrash />
                              </span>
                              <span
                                className="mx-2"
                                onClick={() => onClickUserView(ele)}
                                style={{
                                  fontSize: '20px',
                                  color: '#d3dc40',
                                  padding: '5px',
                                  cursor: 'pointer',
                                }}
                              >
                                <FaRegEye />
                              </span>
                            </CTableDataCell>
                          </CTableRow>
                        )
                      })}
                    </CTableBody>
                  </CTable>
                  {!servicesData?.length && (
                    <>
                      <h1>No Data</h1>
                    </>
                  )}
                  {servicesData?.length ? (
                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={pageData?.page}
                      itemsCountPerPage={pageData?.perPage}
                      totalItemsCount={totalPages * 10}
                      pageRangeDisplayed={5}
                      onChange={changePage}
                    />
                  ) : (
                    ''
                  )}
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default index
