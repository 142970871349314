import { CCard, CCardBody, CCardGroup, CCardSubtitle, CCol, CContainer, CHeader, CRow, CTable, CTableBody, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react';
import React from 'react'
import Pagination from 'react-js-pagination';
import { ToastContainer, toast } from 'react-toastify';

function PendingOrders() {
    return (
        <>
            <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
            <CContainer fluid>
                <CRow>
                    <CCol>
                        <CCardGroup>
                            <CCard className="p-4">
                                <CHeader>
                                    <CCardSubtitle as="h4">Pending Orders</CCardSubtitle>
                                    <CRow>
                                        <CCol md="8">
                                            {/* <DefaultInputWithIcon
                        type={'text'}
                        placeholder="Search"
                        customType={'search'}
                        value={searchItem}
                        onClick={onSearchCLick}
                        onChange={(e) => {
                          onSearchCLick()
                          setSearchItem(e.target.value)
                        }}
                      /> */}
                                        </CCol>
                                        {/* <CCol md="4">
                      <Link to={{ pathname: '/admin/offer/add' }} className="btn btn-dark">
                        <CIcon icon={cilPlus} />
                        Add
                      </Link>
                    </CCol> */}
                                    </CRow>
                                </CHeader>
                                <CCardBody>
                                    <CTable bordered hover responsive>
                                        <CTableHead>
                                            <CTableRow>
                                                <CTableHeaderCell>S. No</CTableHeaderCell>
                                                <CTableHeaderCell>Order ID</CTableHeaderCell>
                                                <CTableHeaderCell>No. of Packets</CTableHeaderCell>
                                                <CTableHeaderCell>OfferData</CTableHeaderCell>
                                                {/* <CTableHeaderCell>Status</CTableHeaderCell> */}
                                                <CTableHeaderCell>QR</CTableHeaderCell>
                                                <CTableHeaderCell>Item Status</CTableHeaderCell>
                                                <CTableHeaderCell>Action</CTableHeaderCell>
                                            </CTableRow>
                                        </CTableHead>
                                        <CTableBody>
                                            {/* {placedOrderList?.map((item, index) => {
                                                return (

                                                    <CTableRow key={index}>
                                                        <CTableDataCell>{pageData?.perPage * (pageData?.page - 1) + index + 1}</CTableDataCell>
                                                        <CTableDataCell >#{item?.orderId}</CTableDataCell>
                                                        <CTableDataCell >{item?.noOfPacket}</CTableDataCell>
                                                        <CTableDataCell >{item?.offerData}</CTableDataCell>
                                                        <CTableDataCell ><img src={item?.orderQR} style={{ width: '40px' }} /></CTableDataCell>
                                                        <CTableDataCell >
                                                            {(item?.itemStatus == 'rejected') ? <span className="text-danger">Rejected</span>
                                                                : (item?.itemStatus == 'pending') ? <span className="text-info">Pending</span>
                                                                    : (item?.itemStatus == 'none') ? <span className="text-success">Approved</span>
                                                                        : ''}
                                                        </CTableDataCell>
                                                        
                                                        <CTableDataCell>
                                                           
                                                            <span className="mx-2" onClick={() => printData(item)} style={{ fontSize: '20px', color: '#000', cursor: 'pointer' }}><FaPrint /></span>
                                                            <span className="mx-2" onClick={() => navigate(`/admin/placed-order/examin/${btoa(item._id)}`, { state: item })} style={{ fontSize: '20px', color: '#d3dc40', cursor: 'pointer', fontSize: '20px' }}><FaSearchengin /></span>
                                                            <span className="mx-2" onClick={() => navigate(`/admin/placed-order/view/${btoa(item._id)}`, { state: item })} style={{ fontSize: '20px', color: '#d3dc40', cursor: 'pointer' }}><FaRegEye /></span>
                                                           
                                                        </CTableDataCell>
                                                    </CTableRow>
                                                );
                                            })} */}
                                        </CTableBody>
                                    </CTable>
                                    {/* {!placedOrderList?.length && (
                                        <>
                                            <h1>No Data</h1>
                                        </>
                                    )}
                                    {placedOrderList?.length ? <Pagination
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        activePage={pageData?.page}
                                        itemsCountPerPage={pageData?.perPage}
                                        totalItemsCount={totalPages * 10}
                                        pageRangeDisplayed={5}
                                        onChange={changePage}
                                    /> : ''} */}
                                </CCardBody>
                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </>
    )
}

export default PendingOrders