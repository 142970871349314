import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { get, post, deleteApi } from '../../utils/apiManager'
import CIcon from '@coreui/icons-react'
import { cibElectron, cilPen, cilPlus, cilTrash } from '@coreui/icons'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CCardSubtitle,
  CTable,
  CRow,
  CImage,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CButton,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import { DefaultInputWithIcon } from 'src/components/common/input'
import Pagination from 'react-js-pagination'
import {
  FaRegEye,
  FaEdit,
  FaTrash,
  FaTrashAlt,
  FaPrint,
  FaSearchengin,
  FaTruckMoving,
  FaRegAddressCard,
  FaShoppingCart,
} from 'react-icons/fa'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

export default function ColorList() {
  const [show, setShow] = useState(false)
  // const [users, setUsers] = useState(true);
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const navigate = useNavigate()
  const [userData, setUserData] = useState([])
  const [colorData, setColorData] = useState([])
  const [imageUrl, setimageUrl] = useState('')
  const [totalPages, settotalPages] = useState(0)
  const [pageData, setpageData] = useState({
    page: 1,
    perPage: 10,
    searchItem: '',
  })
  const [searchItem, setsearchItem] = useState('')
  const getColorData = async () => {
    const colorData = await get(
      `admin/items/color/list?page=${pageData?.page || 1}&perPage=${pageData?.perPage || 10
      }&searchItem=${pageData?.searchItem || ''}`,
    )

    if (!colorData?.status) toast.success(colorData?.message, colorData?.status)

    setColorData([...colorData.data])
    settotalPages(colorData.pages)
  }
  const changePage = (index) => {
    setpageData({
      ...pageData,
      page: index,
    })
  }
  const onSearchCLick = () => {
    setpageData({
      ...pageData,
      searchItem: searchItem,
    })
  }
  useEffect(() => {
    ; (async () => await getColorData())()
  }, [pageData])
  const deleteColor = async (id) => {
    const deleteColorData = await deleteApi('admin/items/color/delete/' + id);
    if (deleteColorData) {
      handleResponse(deleteColorData)
    }
  }
  const handleResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      getColorData();
      // navigate('/admin/offline-order')
    } else {
      toast.error(data?.message)
    }
  }
  return (
    <>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <CCardSubtitle as="h4">Colors</CCardSubtitle>
                  <CRow>
                    <CCol md="7">
                      <DefaultInputWithIcon
                        type={'text'}
                        placeholder="Search"
                        customType={'search'}
                        value={searchItem}
                        onClick={onSearchCLick}
                        onChange={(e) => {
                          onSearchCLick()
                          setsearchItem(e?.target?.value)
                        }}
                      />
                    </CCol>
                    {/* <CCol md="2"></CCol> */}
                    <CCol md="5">
                      <Link to={{ pathname: '/admin/color/add' }} className="btn btn-dark">
                        <CIcon icon={cilPlus} />
                        Add Color
                      </Link>
                    </CCol>
                  </CRow>
                </CHeader>
                {/* {users !== true ? ( */}
                <CCardBody>
                  <CTable bordered hover responsive>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell>S. No</CTableHeaderCell>
                        <CTableHeaderCell>Color Name</CTableHeaderCell>
                        <CTableHeaderCell>Color Code</CTableHeaderCell>
                        <CTableHeaderCell>Color </CTableHeaderCell>
                        <CTableHeaderCell>Action</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {colorData.map((item, index) => {
                        return (
                          <CTableRow key={index}>
                            <CTableDataCell>
                              {pageData?.perPage * (pageData?.page - 1) + index + 1}
                            </CTableDataCell>
                            <CTableDataCell>{item?.name}</CTableDataCell>
                            <CTableDataCell>{item?.colorCode}</CTableDataCell>
                            <CTableDataCell>   <div
                              style={{
                                height: '50px',
                                width: '50px',
                                borderRadius: '50%',
                                // border: '1px solid black',
                                backgroundColor: item.colorCode,
                                cursor: 'pointer',
                                boxShadow: '0 1px 3px rgb(0 0 0 / 36%)',
                                margin: "4px"
                              }}
                            ></div></CTableDataCell>
                            <CTableDataCell>
                              <Link
                                className="mx-2"
                                // onClick={() => onClickUser(item)}
                                to={`/admin/offline-order/${item._id}`}
                                style={{ fontSize: '20px', color: '#d3dc40', cursor: 'pointer' }}
                              >
                                <FaShoppingCart />

                              </Link>
                              <span
                                className="mx-2 text-danger"
                                onClick={() => deleteColor(item?._id)}
                                style={{ fontSize: '20px', padding: '5px', cursor: 'pointer' }}
                              >
                                <FaTrash />
                              </span>
                            </CTableDataCell>
                          </CTableRow>
                        )
                      })}
                    </CTableBody>
                  </CTable>
                  {!colorData?.length && (
                    <>
                      <h1>No Data</h1>
                    </>
                  )}
                  {colorData?.length ? (
                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={pageData?.page}
                      itemsCountPerPage={pageData?.perPage}
                      totalItemsCount={totalPages * 10}
                      pageRangeDisplayed={5}
                      onChange={changePage}
                    />
                  ) : (
                    ''
                  )}
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}
