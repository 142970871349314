import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { post, get, deleteApi } from '../../utils/apiManager'
import CIcon from '@coreui/icons-react'
import { cilPlus } from '@coreui/icons'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CCardSubtitle,
  CTable,
  CRow,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CButton,
  CImage,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import { DefaultInputWithIcon } from 'src/components/common/input'
import Pagination from 'react-js-pagination'
import { FaEdit, FaTrash } from 'react-icons/fa'
import { ImageUrl } from 'src/config/config'

function index() {
  const navigate = useNavigate()
  const [itemSizeData, setItemSizeData] = useState([])
  const [totalPages, settotalPages] = useState(0)
  const [searchItem, setsearchItem] = useState('')
  const [pageData, setpageData] = useState({
    page: 1,
    perPage: 10,
    searchItem: '',
  })
  const [deletedArray, setDeletedArray] = useState([])
  const getData = () => {
    get(
      `/admin/item-category/get-item-category?page=${pageData?.page || 1}&perPage=${
        pageData?.perPage || 10
      }&searchItem=${pageData?.searchItem || ''}`,
    ).then((data) => handleResponse(data))
  }
  const handleResponse = (data) => {
    if (!data?.status) toast.success(data?.message, data?.status)
    else {
      setItemSizeData([...data?.data])
      settotalPages(data?.pages)
    }
  }
  const changePage = (index) => {
    setpageData({
      ...pageData,
      page: index,
    })
  }
  const onSearchCLick = () => {
    setpageData({
      ...pageData,
      searchItem: searchItem,
    })
  }
  const statusChange = (status, id) => {
    let default_status = ''
    if (status == 0) default_status = 'Active'
    else default_status = 'Inactive'
    if (confirm(`Are you sure you want to ${default_status} the item category`)) {
      let formData = {
        _id: id,
      }
      post(
        `${status ? '/admin/item-category/inactive' : '/admin/item-category/active'}`,
        formData,
      ).then((data) => {
        handleStatusChange(data)
      })
    }
  }
  const handleStatusChange = (data) => {
    toast.success(data?.message, data?.status)
    getData()
  }
  const onClickUserEdit = async (value) => {
    navigate('/admin/item/item-category/edit', { state: value })
  }
  const deleteData = (id) => {
    if (confirm('Are you sure')) {
      let formData = {
        _id: id,
      }
      deleteApi(`/admin/item-category/delete?_id=${id}`).then((data) => {
        handleDelete(data)
      })
    }
  }
  const handleDelete = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
    } else {
      toast.error(data?.message)
    }
    // toast.success(data.message, data.status);
    getData()
  }
  const onClickUserView = (value) => {
    navigate(`/admin/item/item-category/view/${btoa(value?._id)}`, { state: value })
  }
  useEffect(() => {
    getData()
  }, [pageData])

  const handleCheck = (id) => {
    let findValue = deletedArray.includes(id)
    if (findValue) {
      let filterData = deletedArray?.filter((item) => item != id)
      setDeletedArray(filterData)
    } else {
      setDeletedArray((prev) => [...prev, id])
    }
  }

  const deleteOrders = () => {
    // post(`admin/placed-order/deleted-order`, deletedArray).then((data) =>
    //   handleDeletedResponse(data),
    // )
  }

  const handleDeletedResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      setDeletedArray([])
      // get(
      //   `/admin/placed-order/order-placed-list?page=${pageData?.page || 1}&perPage=${
      //     pageData?.perPage || 10
      //   }&searchItem=${pageData?.searchItem || ''}&from_date=${searchDate ? searchDate : ''}`,
      // ).then((res) => handleResponse(res))
    }
  }

  return (
    <>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <div className="d-flex" style={{ flexDirection: 'column' }}>
                    <CCardSubtitle as="h4">Items Categories</CCardSubtitle>
                    {deletedArray.length > 0 && (
                      <button className="btn btn-danger mt-2 text-white" onClick={deleteOrders}>
                        Delete
                      </button>
                    )}
                  </div>
                  <CRow>
                    <CCol md="8">
                      <DefaultInputWithIcon
                        type={'text'}
                        placeholder="search"
                        customType={'search'}
                        value={searchItem}
                        onClick={onSearchCLick}
                        onChange={(e) => {
                          onSearchCLick()
                          setsearchItem(e.target.value)
                        }}
                      />
                    </CCol>
                    <CCol md="4">
                      <Link
                        to={{ pathname: '/admin/item/item-category/add' }}
                        className="btn btn-dark"
                      >
                        <CIcon icon={cilPlus} />
                        Add
                      </Link>
                    </CCol>
                  </CRow>
                </CHeader>
                <CCardBody>
                  <CTable bordered hover responsive>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell>S. No</CTableHeaderCell>
                        <CTableHeaderCell>Item Category Name (EN)</CTableHeaderCell>
                        <CTableHeaderCell>Item Category Name (TH)</CTableHeaderCell>
                        <CTableHeaderCell>Category Icon</CTableHeaderCell>
                        <CTableHeaderCell>Status</CTableHeaderCell>
                        <CTableHeaderCell>Action</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {itemSizeData?.map((ele, index) => {
                        return (
                          <CTableRow key={index}>
                            <CTableDataCell  >
                              {pageData?.perPage * (pageData?.page - 1) + index + 1}
                              {/* <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    style={{ marginLeft: '2px', marginTop: '-20px' }}
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                    checked={deletedArray?.includes(ele._id)}
                                    onChange={() => handleCheck(ele._id)}
                                  />
                                </div> */}
                            </CTableDataCell>
                            <CTableDataCell>{ele?.itemName_EN}</CTableDataCell>
                            <CTableDataCell>{ele?.itemName_TH}</CTableDataCell>
                            <CTableDataCell>
                              <CImage
                                rounded
                                src={`${ImageUrl}${ele?.category_Icon}`}
                                // onError={addDefaultSrc}
                                style={{
                                  maxHeight: '50px',
                                  maxWidth: '50px',
                                  borderRadius: 50,
                                  alignItems: 'center',
                                }}
                              />
                            </CTableDataCell>
                            <CTableDataCell>
                              <CButton
                                size="sm"
                                outline={ele?.status ? '1' : '0'}
                                style={{
                                  backgroundColor: ele?.status ? '#00796B' : '#D32F2F',
                                  borderColor: ele?.status ? '#00796B' : '#D32F2F',
                                }}
                                onClick={() => statusChange(ele?.status, ele?._id)}
                              >
                                {ele?.status ? 'Active' : 'Inactive'}
                              </CButton>
                            </CTableDataCell>
                            <CTableDataCell>
                              <span
                                className="mx-2"
                                onClick={() => onClickUserEdit(ele)}
                                style={{ fontSize: '20px', color: '#d3dc40', cursor: 'pointer' }}
                              >
                                <FaEdit />
                              </span>
                              <span
                                className="text-danger mx-2"
                                onClick={() => deleteData(ele?._id)}
                                style={{ fontSize: '20px', padding: '5px', cursor: 'pointer' }}
                              >
                                <FaTrash />
                              </span>
                              {/* <span onClick={() => onClickUserView(ele)} style={{color:'#d3dc40',padding:'5px',cursor:'pointer'}}><FaRegEye/></span> */}
                            </CTableDataCell>
                          </CTableRow>
                        )
                      })}
                    </CTableBody>
                  </CTable>
                  {!itemSizeData?.length && (
                    <>
                      <h1>No Data</h1>
                    </>
                  )}
                  {itemSizeData?.length ? (
                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={pageData?.page}
                      itemsCountPerPage={pageData?.perPage}
                      totalItemsCount={totalPages * 10}
                      pageRangeDisplayed={5}
                      onChange={changePage}
                    />
                  ) : (
                    ''
                  )}
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default index
