import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react'
import CIcon from '@coreui/icons-react'

import { AppSidebarNav } from './AppSidebarNav'

import { logoNegative } from 'src/assets/brand/logo-negative'
import { sygnet } from 'src/assets/brand/sygnet'

import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

// sidebar nav config
import navigationAdmin from '../_nav_admin'
import navigation from '../_nav'
import { get } from 'src/utils/apiManager'





const AppSidebar = () => {
  const dispatch = useDispatch()
  const [permissionData, setPermissionData] = useState([])
  const [ready, setReady] = useState(false)

  const unfoldable = useSelector((state) => state.sidebarUnfoldable)
  const sidebarShow = useSelector((state) => {
    // console.log('stateeeeeeeeee', state?.auth)
    return state.auth.sidebarShow
  })
  console.log("🚀 ~ sidebarShow ~ sidebarShow:", sidebarShow)
  useEffect(() => {
    permission()
  }, [])
  const permission = () => {
    get(`admin/sidebarMenu/sidebar-menu`).then((res) => {
      if (res?.status == true) {
        setReady(true)
        setPermissionData(res?.data)
      }

    }).catch((error) => {
      console.log(error, "erre");
    })
  }
  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
    // onVisibleChange={(visible) => {
    //   // console.log(visible)
    //   dispatch({ type: 'set', sidebarShow: visible })
    // }}
    >
      <CSidebarBrand className="d-none d-md-flex" to="/">
        <img src="../../img/logo.png" />
        <CIcon className="sidebar-brand-full" height={35} ></CIcon>
        <CIcon className="sidebar-brand-narrow" icon={sygnet} height={35} />
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          {ready && <AppSidebarNav items={navigation} permissionData={permissionData} />}
        </SimpleBar>
      </CSidebarNav>
      {/* side toggle code */}
      {/* <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => dispatch({ type: 'set', sidebarUnfoldable: !unfoldable })}
      /> */}
      {/* side toggle code */}
    </CSidebar>
  )
}

export default React.memo(AppSidebar)
