import React, { useRef, useState, useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigate, Link, useParams } from 'react-router-dom'
import { put, get } from 'src/utils/apiManager';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CFormLabel,
  CRow,
} from '@coreui/react'
import { DefaultInput } from 'src/components/common/input'
import { attributeValidation } from 'src/utils/validator'

function EditAttribute() {

  const { id } = useParams()
  const navigate = useNavigate()
  const formRef = useRef(null)
  const [attributeData, setAttributeData] = useState()
  const [isDisable, setIsDisable] = useState(false);

  const getData = () => {
    get(
      `/admin/attribute/get-single?_id=${atob(id)}`
    ).then((res) => {
      if (res?.status) {
        setAttributeData(res?.data)
      }
    })
  }

  useEffect(() => {
    formRef.current.setFieldValue('attributeName_EN', attributeData?.attributeName_EN)
    formRef.current.setFieldValue('attributeName_TH', attributeData?.attributeName_TH)
  }, [attributeData]);

  const submit = async (values) => {
    setIsDisable(false)
   const temp = {
    attributeName_EN: values?.attributeName_EN,
    attributeName_TH: values?.attributeName_TH
   }
   put(
    `/admin/attribute/update?_id=${atob(id)}`, temp
   ).then((response) => {
    if (response?.status) {
      toast.success(response?.message)
      navigate('/admin/attribute')
    } else {
      toast.error(response?.message)
      setIsDisable(true)
    }
   })
  }

  useEffect(() => {
    getData();
  },[]);

  return (
    <>
      <CContainer >
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <CRow>
                        <CCol md="11">
                          <h1>Edit Attribute</h1>
                        </CCol>
                        <CCol md="1">
                          <Link className={'btn btn-success mt-2'} style={{ background: '#d3dc40', border: '#d3dc40' }} to={{ pathname: `/admin/attribute` }}>
                            Back
                          </Link>
                        </CCol>
                      </CRow>
                      <Formik
                        initialValues={{
                          attributeName_EN: '',
                          attributeName_TH: '',
                        }}
                        validationSchema={attributeValidation}
                        onSubmit={(values, { setSubmitting }) => {
                          submit(values)
                          setSubmitting(false)
                        }}
                        innerRef={formRef}
                      >
                        {(props) => (
                          <form onSubmit={props.handleSubmit}>
                            <CRow>
                              <CCol md={6} >
                                <CFormLabel>Attribute Name (EN)</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={"text"}
                                  placeholder={"Attribute Name (EN)"}
                                  value={props?.values?.attributeName_EN}
                                  onChange={props?.handleChange("attributeName_EN")}
                                  onBlur={() => props?.setFieldTouched("attributeName_EN", true)}
                                  onKeyUp={() => {
                                    if (attributeData?.attributeName_EN == props?.values?.attributeName_EN) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                  error={props?.touched?.attributeName_EN && props?.errors?.attributeName_EN}
                                />
                             
                              </CCol>
                              <CCol md="6">
                                <CFormLabel>Attribute Name (TH)</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={"text"}
                                  placeholder={"Attribute Name (TH)"}
                                  value={props?.values?.attributeName_TH}
                                  onChange={props?.handleChange("attributeName_TH")}
                                  onBlur={() => props?.setFieldTouched("attributeName_TH", true)}
                                  error={props?.touched?.attributeName_TH && props?.errors?.attributeName_TH}
                                  onKeyUp={() => {
                                    if (attributeData?.attributeName_TH == props?.values?.attributeName_TH) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol md="2">
                                <button
                                  type="submit"
                                  className={'btn btn-success mt-2'}
                                  disabled={!isDisable || props?.isSubmitting}
                                >
                                  Submit
                                </button>
                              </CCol>
                              <CCol md="2">
                                <button
                                  type="button"
                                  className={'btn btn-warning mt-2'}
                                  onClick={() => navigate('/admin/attribute')}
                                >
                                  Cancel
                                </button>
                              </CCol>
                              <CCol md="3"></CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default EditAttribute
