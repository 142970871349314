import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { get, deleteApi } from '../../utils/apiManager'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CCardSubtitle,
  CTable,
  CRow,
  CImage,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import { DefaultInputWithIcon } from 'src/components/common/input'
import Pagination from 'react-js-pagination'
import { FaRegEye, FaEdit } from 'react-icons/fa'
import ShowMoreText from 'react-show-more-text'

function index() {
  const navigate = useNavigate()
  const [introList, setIntroList] = useState([])
  const [cmsDataList, setCmsDataList] = useState([])
  const [imageUrl, setimageUrl] = useState('')
  const [totalPages, settotalPages] = useState(0)
  const [pageData, setpageData] = useState({
    page: 1,
    perPage: 10,
    searchItem: '',
  })
  const [searchItem, setsearchItem] = useState('')

  const getcmsData = () => {
    get(`/admin/Cms/cms-list?page=${pageData?.page || 1}&perPage=${pageData?.perPage || 10}&searchItem=${pageData?.searchItem || ''
      }`).then((response) => {
        handleResponse(response)
      })
  }
  const handleResponse = (response) => {
    if (!response?.status) toast.success(response?.message, response?.status)
    else {
      setCmsDataList([...response?.data])
      settotalPages(response?.pages)
      setimageUrl(response?.imageUrl)
    }
  }
  const changePage = (index) => {
    setpageData({
      ...pageData,
      page: index,
    })
  }
  const onSearchCLick = () => {
    setpageData({
      ...pageData,
      searchItem: searchItem,
    })
  }
  const onClickUserEdit = async (value) => {
    navigate('/admin/cms-pages/edit-cms', { state: value })
  }
  const onClickUserView = (value) => {
    navigate(`/admin/cms-pages/view/${value?._id}`, { state: value })
  }
  // const deleteData = (id) => {
  //   if (confirm("Are you sure")) {
  //     let formData = {
  //       _id: id,
  //     };
  //     deleteApi(`/admin/Cms/delete?_id=${id}`).then((data) => {
  //       handleDelete(data);
  //     });
  //   }
  // };    
  // const handleDelete = (data) => {
  //     toast.success(data.message, data.status);
  //     getBlogData();
  //   };
  useEffect(() => {
    //   getintroData();
    getcmsData()
  }, [pageData])

  return (
    <>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <CCardSubtitle as="h4">Cms List's</CCardSubtitle>
                  <CRow>
                    <CCol md="8">
                      <DefaultInputWithIcon
                        type={'text'}
                        placeholder="Search"
                        customType={'search'}
                        value={searchItem}
                        onClick={onSearchCLick}
                        onChange={(e) => {
                          onSearchCLick()
                          setsearchItem(e?.target?.value)
                        }}
                      />
                    </CCol>
                  </CRow>
                </CHeader>

                <CCardBody>
                  <CTable bordered hover responsive>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell>S. No</CTableHeaderCell>
                        <CTableHeaderCell>Title (EN)</CTableHeaderCell>
                        <CTableHeaderCell>Title (TH)</CTableHeaderCell>
                        {/* <CTableHeaderCell>Description</CTableHeaderCell> */}
                        <CTableHeaderCell>Action</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {cmsDataList &&
                        cmsDataList?.map((data, key) => {
                          return (
                            <CTableRow key={key}>
                              <CTableDataCell>
                                {pageData?.perPage * (pageData?.page - 1) + key + 1}
                              </CTableDataCell>
                              <CTableDataCell>
                                {data?.title_EN}
                              </CTableDataCell>
                              <CTableDataCell>
                                {data?.title_TH}
                              </CTableDataCell>
                              <CTableDataCell >
                                <span className="mx-2" onClick={() => onClickUserEdit(data)} style={{ fontSize: '20px', color: '#d3dc40', cursor: 'pointer' }}><FaEdit /></span>
                                {/* <span onClick={() => deleteData(data?._id)} className="text-danger"style={{padding: '5px'}}><FaTrash/></span> */}
                                <span className="mx-2" onClick={() => onClickUserView(data)} style={{ fontSize: '20px', color: '#d3dc40', cursor: 'pointer', padding: '5px' }}><FaRegEye /></span>
                              </CTableDataCell>
                            </CTableRow>
                          )
                        })}
                    </CTableBody>
                  </CTable>
                  {!cmsDataList?.length && (
                    <>
                      <h1>No Data</h1>
                    </>
                  )}
                  {cmsDataList?.length ? <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={pageData?.page}
                    itemsCountPerPage={pageData?.perPage}
                    totalItemsCount={totalPages * 10}
                    pageRangeDisplayed={5}
                    onChange={changePage}
                  /> : ''}
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default index