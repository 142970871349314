import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { post, get, deleteApi } from '../../utils/apiManager'
import CIcon from '@coreui/icons-react'
import { cilPen, cilPlus, cilTrash } from '@coreui/icons'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CCardSubtitle,
  CTable,
  CRow,
  CImage,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CButton,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import { DefaultInputWithIcon } from 'src/components/common/input'
import Pagination from 'react-js-pagination'
import { FaRegEye, FaEdit, FaTrash } from 'react-icons/fa'
import ShowMoreText from 'react-show-more-text'
import { cilLocationPin } from '@coreui/icons'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { google_api_key } from '../../config/config'
import GoogleMapComponent from './googleMap'

function index() {
  const navigate = useNavigate()
  const [areaData, setAreaData] = useState([])
  const [totalPages, settotalPages] = useState(0)
  const [searchItem, setsearchItem] = useState('')
  const [pageData, setpageData] = useState({
    page: 1,
    perPage: 10,
    searchItem: '',
  })
  const [show, setShow] = useState(false)
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  })
  const [marker, setMarker] = useState(true)
  const [polygonCoords, setPolyGonCoords] = useState()
  const [buttonDisable, setButtonDisable] = useState(false)
  const [label, setLabel] = useState()
  const [deletedArray, setDeletedArray] = useState([])
  //array of plygon coordinates and label details
  const suburbList = [
    {
      locality: label,
      suburbId: 1,
      suburbGeoBoundaries: polygonCoords,
      suburbCenter: {
        lat: coordinates?.lat,
        lng: coordinates?.lng,
      },
    },
  ]

  useEffect(() => {
    getData()
  }, [pageData])

  const getData = () => {
    get(
      `/admin/Area/get?page=${pageData?.page || 1}&perPage=${pageData?.perPage || 10}&searchItem=${pageData?.searchItem || ''
      }`,
    ).then((data) => handleResponse(data))
  }
  const handleResponse = (data) => {
    if (!data?.status) toast.success(data?.message, data?.status)
    else {
      setAreaData([...data?.data])
      settotalPages(data?.pages)
    }
  }
  const changePage = (index) => {
    setpageData({
      ...pageData,
      page: index,
    })
  }
  const statusChange = (status, id) => {
    let default_status = ''
    if (status == 0) default_status = 'Active'
    else default_status = 'Inactive'
    if (confirm(`Are you sure you want to ${default_status} the area`)) {
      let formData = {
        _id: id,
      }
      post(`${status ? '/admin/Area/inactive' : '/admin/Area/active'}`, formData).then((data) => {
        handleStatusChange(data)
      })
    }
  }
  const handleStatusChange = (data) => {
    toast.success(data?.message, data?.status)
    getData()
  }
  const onClickUserEdit = async (value) => {
    navigate('/admin/area/edit-area', { state: value })
  }

  const deleteData = (id) => {
    if (confirm('Are you sure')) {
      let formData = {
        _id: id,
      }
      deleteApi(`/admin/Area/delete?_id=${id}`).then((data) => {
        handleDelete(data)
      })
    }
  }
  const handleDelete = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
    } else {
      toast.error(data?.message)
    }

    getData()
  }
  const onSearchCLick = () => {
    setpageData({
      ...pageData,
      searchItem: searchItem,
    })
  }

  const openModal = (item, id) => {
    // console.log('idddddddddddd', id)
    // console.log('item+++++++++++++++++++++++', item)
    //remove extra space from polygon coordinates area comming from backend
    const removeExtraSpace = item?.lat_long.trim().split(/, +/).join(' ')
    const a = removeExtraSpace?.replace(/ /g, ',')
    const arr = a.split(',') //split through (,) and convert in array
    let lat = ''
    let lng = ''
    const temp = []
    let centroid_of_lat = []
    let centroid_of_lng = []
    arr?.map((item, index) => {
      //index % 2 is true then stored in longitude else in latitude
      if (index % 2 == 0) {
        lng = item
      } else {
        lat = item
      }

      // every odd index i.e. index % 2 != 0 then the object created and push in temp array
      if (index % 2 != 0) {
        const dataObject = {
          lat: lat,
          lng: lng,
        }
        temp.push(dataObject)
        // centroid_of_lat is array of all latitude values
        centroid_of_lat?.push(dataObject?.lat)
        // centroid_of_lng is array of all longitude values
        centroid_of_lng?.push(dataObject?.lng)
      }
      setPolyGonCoords(temp) //set temp array in polyGonCoords state
    })

    // sum total of latitude and longitude array
    const sumlat = centroid_of_lat?.reduce((partialSum, a) => partialSum + parseFloat(a), 0)
    const sumlng = centroid_of_lng?.reduce((partialSum, a) => partialSum + parseFloat(a), 0)

    // average of sumlat and sumlng
    const latx = sumlat / centroid_of_lat?.length
    const lngy = sumlng / centroid_of_lng?.length

    //set finally coordinates in coordinates state
    setCoordinates({
      ...coordinates,
      lat: latx,
      lng: lngy,
    })
    setLabel(item.areaName_EN)
    setShow(true)
  }

  const executeOnClick = async (isExpanded) => {
    // console.log(isExpanded);
  }

  const handleClose = () => {
    setShow(false)
  }

  const handleClick = (e) => {
    //set new coordinates on selecting new location
    // console.log('e.latLng.lat()', e.latLng.lat())
    // console.log('e.latLng.lng().............', e.latLng.lng())
    setCoordinates({
      ...coordinates,
      lat: e?.latLng?.lat(),
      lng: e?.latLng?.lng(),
    })
    setMarker(true)

    // if new location is out of polygon range area then show error and user can't save that location
    if (e?.pixel != undefined || e?.placeId) {
      setButtonDisable(true)
      toast.error('Out of Area Range')
    } else {
      setButtonDisable(false)
    }
  }

  const handleCheck = (id) => {
    let findValue = deletedArray.includes(id)
    if (findValue) {
      let filterData = deletedArray?.filter((item) => item != id)
      setDeletedArray(filterData)
    } else {
      setDeletedArray((prev) => [...prev, id])
    }
  }

  const deletedAreas = () => {
    post(`admin/Area/deleted-area`, { ids: deletedArray }).then((data) =>
      handleDeletedResponse(data),
    )
  }

  const handleDeletedResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      setDeletedArray([])
      getData()
    }
  }

  return (
    <>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <div className="d-flex" style={{ flexDirection: 'column' }}>
                    <CCardSubtitle as="h4">Area </CCardSubtitle>
                    {deletedArray.length > 0 && (
                      <button className="btn btn-danger mt-2 text-white" onClick={deletedAreas}>
                        Delete
                      </button>
                    )}
                  </div>
                  <CRow>
                    <CCol md="8">
                      <DefaultInputWithIcon
                        type={'text'}
                        placeholder="Search"
                        customType={'search'}
                        value={searchItem}
                        onClick={onSearchCLick}
                        onChange={(e) => {
                          onSearchCLick()
                          setsearchItem(e?.target?.value)
                        }}
                      />
                    </CCol>
                    <CCol md="4">
                      <Link to={{ pathname: '/admin/area/add-area' }} className="btn btn-dark">
                        <CIcon icon={cilPlus} />
                        Add
                      </Link>
                    </CCol>
                  </CRow>
                </CHeader>

                <CCardBody>
                  <CTable bordered hover responsive>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell>S. No</CTableHeaderCell>
                        <CTableHeaderCell>Area Name (EN)</CTableHeaderCell>
                        {/* <CTableHeaderCell>City Name (EN)</CTableHeaderCell> */}
                        <CTableHeaderCell>Area Range</CTableHeaderCell>
                        {/* <CTableHeaderCell>Zipcode</CTableHeaderCell> */}
                        <CTableHeaderCell>Status</CTableHeaderCell>
                        <CTableHeaderCell>Action</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {areaData?.map((ele, index) => {
                        return (
                          <CTableRow
                            key={index}
                          >
                            <CTableDataCell onClick={() => handleCheck(ele._id)}>
                              {pageData?.perPage * (pageData?.page - 1) + index + 1}
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  style={{ marginLeft: '2px', marginTop: '-20px' }}
                                  type="checkbox"
                                  value=""
                                  id="flexCheckDefault"
                                  checked={deletedArray?.includes(ele._id)}
                                // onChange={() => handleCheck(ele._id)}
                                />
                              </div>

                            </CTableDataCell>
                            <CTableDataCell className="col-2">{ele?.areaName_EN}</CTableDataCell>
                            {/* <CTableDataCell className="col-2">{ele?.city_id?.cityName_EN}</CTableDataCell> */}
                            <CTableDataCell className="col-6">
                              <ShowMoreText
                                /* Default options */
                                lines={1}
                                more="Show more"
                                less="Show less"
                                className="content-css"
                                anchorClass="my-anchor-css-class"
                                onClick={executeOnClick}
                                expanded={false}
                                // width={280}
                                truncatedEndingComponent={'... '}
                              >
                                {ele?.lat_long}
                              </ShowMoreText>
                            </CTableDataCell>
                            {/* <CTableDataCell className='text-center'>{ele.zipcode === null ? '--' : ele?.zipcode}</CTableDataCell> */}
                            <CTableDataCell>
                              <CButton
                                size="sm"
                                outline={ele?.status ? '1' : '0'}
                                style={{
                                  backgroundColor: ele?.status ? '#00796B' : '#D32F2F',
                                  borderColor: ele?.status ? '#00796B' : '#D32F2F',
                                }}
                                onClick={() => statusChange(ele?.status, ele?._id)}
                              >
                                {ele?.status ? 'Active' : 'Inactive'}
                              </CButton>
                            </CTableDataCell>
                            <CTableDataCell>
                              <span
                                className="mx-2"
                                onClick={() => onClickUserEdit(ele)}
                                style={{ fontSize: '20px', color: '#d3dc40', cursor: 'pointer' }}
                              >
                                <FaEdit />
                              </span>
                              <span
                                className="mx-2 text-danger"
                                onClick={() => deleteData(ele?._id)}
                                style={{ fontSize: '20px', padding: '5px', cursor: 'pointer' }}
                              >
                                <FaTrash />
                              </span>
                              <span
                                className="mx-2"
                                onClick={() => openModal(ele, ele?._id)}
                                style={{
                                  fontSize: '20px',
                                  color: '#d3dc40',
                                  padding: '5px',
                                  cursor: 'pointer',
                                }}
                              >
                                <CIcon icon={cilLocationPin} />
                              </span>
                              {/* <span onClick={() => onClickUserView(ele)} style={{color:'#d3dc40',padding:'5px'}}><FaRegEye/></span> */}
                            </CTableDataCell>
                          </CTableRow>
                        )
                      })}
                    </CTableBody>
                  </CTable>
                  {!areaData?.length && (
                    <>
                      <h1>No Data</h1>
                    </>
                  )}
                  {areaData?.length ? (
                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={pageData?.page}
                      itemsCountPerPage={pageData?.perPage}
                      totalItemsCount={totalPages * 10}
                      pageRangeDisplayed={5}
                      onChange={changePage}
                    />
                  ) : (
                    ''
                  )}
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>

      <Modal show={show} size="lg" animation={false} centered>
        <Modal.Header>
          <Modal.Title>Select Address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ height: '50vh', width: '100%' }}>
            <GoogleMapComponent
              isMarkerShown={true}
              isPostcodeHighlighted={true}
              isSuburbHighlighted={true}
              showTrafficLayer={false}
              defaultZoom={16}
              loadingElement={<div style={{ height: '50vh' }} />}
              containerElement={<div style={{ height: '50vh', width: '100%' }} />}
              mapElement={<div style={{ height: '50vh', width: '100%' }} />}
              tilt={45}
              defaultCentre={coordinates}
              polygons={suburbList?.map((p, i) => {
                return {
                  boundaries: p?.suburbGeoBoundaries,
                  suburbCentre: p?.suburbCenter,
                  metaData: {
                    name: `${p?.locality}`,
                    suburbId: `${p?.suburbId}`,
                  },
                }
              })}
              selectedMarker={marker}
              onClick={(e) => handleClick(e)}
              googleMapURL={google_api_key}
            ></GoogleMapComponent>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* <Button disabled={buttonDisable} variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default index
