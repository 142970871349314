import React,{useRef,useState,useEffect} from 'react';
import { Formik } from 'formik';
import { useNavigate,Link} from 'react-router-dom'
import { post } from 'src/utils/apiManager';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import {
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CFormLabel,
    CRow,
} from '@coreui/react'
import { DefaultInput } from 'src/components/common/input'
import { validatePackageDuration } from 'src/utils/validator'

function add() {
    const navigate = useNavigate()
    const formRef = useRef(null)

    const submit = async (values) => {
      if (values?.duration <= 0) {
        toast.error("Enter correct time duration")
        formRef.current.setFieldValue('duration', '')
      } else {
      let durationData = {
        title_EN: values?.title_EN,
        title_TH: values?.title_TH,
        duration: values?.duration,
  
      }
      // let formData = new FormData();
        
      //   formData.append('title_EN', values?.title_EN);
      //   formData.append('title_TH', values?.title_TH);
      //   formData.append('duration', values?.duration);
        post(`admin/subscribe-duration/add`, durationData).then((data) =>handleResponse(data))
      }
    }
      const handleResponse = (data) => {
        if (data.status == true) {
          toast.success(data.message)
          setTimeout(() => {
            navigate('/admin/package-duration')
          }, 800)
        }
        else{
          toast.error(data.message)
        }
        formRef.current.resetForm()
      }
      const onClickReset = () => {
        console.log("this")
        console.log(this)
        formRef.current.resetForm()
        // Reset Button Function
        // formRef.current.setFieldValue('vanNo', '')    
      };

  return (
    <>
        <CContainer >
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                <CCardGroup>
                    <CCardBody>
                    <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                    <CRow>
                          <CCol md="11">
                    <h1>Package Duration</h1>
                          </CCol>                    
                          <CCol md="1">
                            <Link className={'btn btn-success mt-2'} style={{background:'#d3dc40',border: '#d3dc40' }} to={{ pathname: `/admin/package-duration` }}>
                                Back
                            </Link>
                          </CCol>
                        </CRow>
                    <Formik
                    initialValues={{
                      title_EN: '',
                      title_TH: '',
                      duration:'',
                    }}
                    validationSchema={validatePackageDuration}
                    onSubmit={(values, { setSubmitting }) => {
                        submit(values)
                        setSubmitting(false)
                    }}
                    innerRef={formRef}
                    >
                    {(props) => (
                        <form onSubmit={props?.handleSubmit}>
                            <CRow xs={12}>
                                <CCol md={6}>
                                    <CFormLabel>Title Name (EN)</CFormLabel>
                                    <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                    <DefaultInput
                                        type={"text"}
                                        placeholder={"Title Name (EN)"}
                                        value={props?.values?.title_EN}
                                        onChange={props?.handleChange("title_EN")}
                                        onBlur={() => props?.setFieldTouched("title_EN", true)}
                                        error={props?.touched?.title_EN && props?.errors?.title_EN}
                                    />
                                    <CFormLabel>Duration</CFormLabel>
                                    <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                    <DefaultInput
                                        type={"number"}
                                        placeholder={"No of Days"}
                                        value={props?.values?.duration}
                                        onChange={props?.handleChange("duration")}
                                        onBlur={() => props?.setFieldTouched("duration", true)}
                                        error={props?.touched?.duration && props?.errors?.duration}
                                    />
                                </CCol>
                                <CCol md="6">
                                    <CFormLabel>Title Name (TH)</CFormLabel>
                                    <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                    <DefaultInput
                                        type={"text"}
                                        placeholder={"Title Name (TH)"}
                                        value={props?.values?.title_TH}
                                        onChange={props?.handleChange("title_TH")}
                                        onBlur={() => props?.setFieldTouched("title_TH", true)}
                                        error={props?.touched?.title_TH && props?.errors?.title_TH}
                                    />
                                </CCol>
                            </CRow>
                        <CRow>
                            <CCol md="2">
                            <button
                                type="submit"
                                
                                className={'btn btn-success mt-2'}
                                disabled={props?.isSubmitting}
                            >
                                Submit
                            </button>
                            </CCol>
                            <CCol md="2">
                            <button
                                type="button"
                                className={'btn btn-warning mt-2'}
                                onClick={()=>onClickReset(props?.isSubmitting)}
                                >
                                  Reset
                            </button>
                            </CCol>
                            <CCol md="3"></CCol>
                        </CRow>
                        </form>
                    )}
                    </Formik>
                    </CCardBody>
                </CCardGroup>
                </CCol>               
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer> 
    </>
  )
}

export default add