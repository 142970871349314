import { CCard, CCardBody, CCardGroup, CCol, CContainer, CFormLabel, CRow } from '@coreui/react';
import { Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { DefaultInput, DefaultMobileInput, DefaultTextarea } from 'src/components/common/input';
import { baseURL } from 'src/config/config'
import { post, get, put } from 'src/utils/apiManager';
import 'react-toastify/dist/ReactToastify.css'
import Select from 'react-select'

function edit() {
    const navigate = useNavigate()
    const { state } = useLocation()
    const formRef = useRef(null)
    const fileRef = useRef(null)
    const [categoryIconUrl, setCategoryIconUrl] = useState('')
    const [invalidServiceIconImage, setInvalidServiceIconImage] = useState('')
    const [categoryIconPreview, setCategoryIconPreview] = useState();

    const [invalidImage, setInvalidImage] = useState('')
    const [userImagePreview, setUserImagePreview] = useState();
    const [isDisable, setIsDisable] = useState(false);
    // const [stateOptions, setStateOptions] = useState([]);
    const [stateID, setStateID] = useState(null);
    const [cityID, setCityID] = useState(null);
    const [areaOptions, setAreaOptions] = useState([]);
    const [areaList, setAreaList] = useState(null);
    const [selectService, setSelectService] = useState([])
    const [addOnServiceList, setAddOnServiceList] = useState(null);
    const addOnServiceOptions = []
    const [imageFile, setImageFile] = useState(null);
    var getAddress = JSON.parse(state.addressDetail)
    useEffect(() => {
        fetchAddOnServices();
        if (state != null) {
            console.log(state, "state 649c180b3953184a50c0f34e", getAddress?.area_id)
            formRef.current.setFieldValue('username', state?.user?.username)
            formRef.current.setFieldValue('email', state?.user?.email)
            formRef.current.setFieldValue('mobile', state?.user?.mobile)
            formRef.current.setFieldValue('address', getAddress?.address)
            formRef.current.setFieldValue('noOfPacket', state?.noOfPacket)
            if (state?.image) {
                setimageUrl(`${state?.ImageUrl}/${state?.user?.image}`)
            }
        }
    }, [state])


    useEffect(() => {
        fetchStateID();
        if (stateID != null) {
            fetchCityList();
        }
        if (cityID != null) {
            fetchAreaList();
        }
    }, [stateID, cityID])
    //get the area
    const fetchStateID = () => {
        get(`/admin/address/state-list`).then((res) => {
            if (res?.data !== null) {
                res?.data?.map((item, key) => {
                    setStateID(item?._id)
                })
            }
        })
    };
    const fetchCityList = () => {
        const data = {
            state_id: stateID
        }
        post(`/admin/address/city-list`, data).then((res) => {
            if (res?.data !== null) {
                res?.data?.map((item, key) => {
                    setCityID(item?._id)
                })
            }
        })
    };
    const fetchAreaList = () => {
        const data = { city_id: cityID };
        post(`/admin/address/area-list`, data).then((res) => {
            if (res?.data !== null) {
                const tempData = res?.data?.map((item, key) => {
                    if (getAddress.area_id == item?._id) {
                        areaOptions.push({ label: item?.areaName_EN, value: item?._id })
                    }
                    return { label: item?.areaName_EN, value: item?._id }
                })
                setAreaList(tempData)
            }
        })
    };
    //get the area
    //get the add on services data 
    const fetchAddOnServices = () => {
        get(`/admin/offine-order/addOnService-list`).then((data) => {
            // console.log(data, "data");
            if (data?.data !== null) {
                const tempData = data?.data?.map((item, key) => {
                    var ExistAddOnServiceId = Object.values(state?.addOnServiceId)
                    if (ExistAddOnServiceId?.find((element) => element == item?._id)) {
                        selectService?.push({ label: item?.serviceName_EN, value: item?._id })
                    }
                    return { label: item?.serviceName_EN, value: item?._id }
                })
                setAddOnServiceList(tempData)
            }
        })
    }
    const submit = async (values) => {
        console.log(values, "valuessssss", areaList?.value, addOnServiceList);
        var SERVICE = []
        if (values?.addOnServiceId?.length > 0) {
            values?.addOnServiceId?.forEach((item, key) => {
                SERVICE?.push(item?.value)
            })
        }
        // console.log(SERVICE, "fsyfsfsfy");
        let formData = new FormData();
        formData.append('orderId', state?.orderId)
        formData.append('username', values.username);
        formData.append('email', values.email);
        formData.append('image', imageFile);
        formData.append('mobile', values.mobile);
        formData.append('areaName', values?.areaName == '' ? getAddress?.area_id : values?.areaName);
        formData.append('address', values.address);
        formData.append('addOnServiceId', values?.addOnServiceId == undefined || values?.addOnServiceId?.length == 0 ? state?.addOnServiceId : SERVICE);
        formData.append('noOfPacket', values.noOfPacket);
        put(`admin/offine-order/update?_id=${state?._id}`, formData, 1).then((data) => {
            handleResponse(data)
        })
    }
    const handleResponse = (data) => {
        if (data?.status == true) {
            toast.success(data?.message)
            setTimeout(() => {
                navigate('/admin/offline-order');
            }, 500)
        }
        else {
            toast.error(data?.message)
        }
        formRef.current.resetForm()
        fileRef.current.value = null
        // setUserImagePreview('')
    }

    return (
        <>
            <CContainer>
                <CRow>
                    <CCol>
                        <CCardGroup>
                            <CCard className="p-4" >
                                <CCol xs={12}>
                                    <CCardGroup>
                                        <CCardBody>
                                            <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                                            <CRow>
                                                <CCol md="11">
                                                    <h1>Edit User Order</h1>
                                                </CCol>
                                                <CCol md="1">
                                                    <Link className={'btn btn-success mt-2'}
                                                        style={{ background: '#d3dc40', border: '#d3dc40' }}
                                                        to={{ pathname: `/admin/offline-order` }}>
                                                        Back
                                                    </Link>
                                                </CCol>
                                            </CRow>
                                            <Formik
                                                initialValues={{
                                                    //store in user coll
                                                    username: '',
                                                    email: '',
                                                    image: '',
                                                    mobile: '',
                                                    //store in address coll
                                                    areaName: '',
                                                    address: '',
                                                    //store in place order
                                                    addOnServiceId: '',
                                                    noOfPacket: '',
                                                }}
                                                // validationSchema={signUp}
                                                onSubmit={(values, { setSubmitting }) => {
                                                    console.log("submit")
                                                    submit(values)
                                                    setSubmitting(false)

                                                }}
                                                innerRef={formRef}
                                            >
                                                {(props) => (
                                                    <form onSubmit={props?.handleSubmit}>
                                                        {/* {console.log(props)} */}
                                                        <CRow>
                                                            <CCol md={6}>
                                                                <CFormLabel>Name</CFormLabel>
                                                                <span className="text-danger" style={{ marginLeft: '5px' }}>*</span>
                                                                <DefaultInput
                                                                    type={'text'}
                                                                    placeholder={'Name'}
                                                                    // name={'Name'}
                                                                    id={'username'}
                                                                    value={props?.values?.username}
                                                                    onChange={props?.handleChange('username')}
                                                                    onKeyUp={(value) => {
                                                                        if (state?.username == props?.values?.username) setIsDisable(false)
                                                                        else setIsDisable(true)
                                                                    }}
                                                                    onBlur={() => props?.setFieldTouched('username', true)}
                                                                    error={props?.touched?.username && props?.errors?.username}
                                                                />
                                                            </CCol>
                                                            <CCol md={6}>
                                                                <CFormLabel>Email</CFormLabel>
                                                                <span className="text-danger" style={{ marginLeft: '5px' }}>*</span>
                                                                <DefaultInput
                                                                    disabled={true}
                                                                    type={'email'}
                                                                    placeholder={'Email'}
                                                                    id={'email'}
                                                                    value={props?.values?.email}
                                                                    // value={this.state.name}
                                                                    onChange={props?.handleChange('email')}
                                                                    onBlur={() => props?.setFieldTouched('email', true)}
                                                                    error={props?.touched?.email && props?.errors?.email}
                                                                />
                                                            </CCol>
                                                        </CRow>
                                                        <CRow>
                                                            <CCol md={6}>
                                                                <DefaultMobileInput
                                                                    type={'text'}
                                                                    placeholder={'Mobile'}
                                                                    name={'Mobile'}
                                                                    id={'mobile'}
                                                                    minLength={7}
                                                                    maxLength={15}
                                                                    value={props?.values?.mobile}
                                                                    onChange={(val) => props?.setFieldValue('mobile', val || '')}
                                                                    onBlur={() => props?.setFieldTouched('mobile', true)}
                                                                    error={props?.touched?.mobile && props?.errors?.mobile}
                                                                />

                                                            </CCol>
                                                            <CCol md={6}>
                                                                <CFormLabel>Area Name</CFormLabel>
                                                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                                                <Select

                                                                    defaultValue={areaOptions}
                                                                    onChange={(val) => {
                                                                        setAreaOptions(val)
                                                                        props?.setFieldValue("areaName", val?.value)
                                                                        // props?.setFieldValue("areaName", val)
                                                                    }}
                                                                    options={areaList}
                                                                    placeholder={"Select Area Name"}
                                                                    value={areaOptions}
                                                                    name="Area Name" />
                                                                {areaOptions == undefined ? <span className='text-danger'>{props?.errors?.areaName}</span> : ''}
                                                                <br />
                                                            </CCol>
                                                        </CRow>
                                                        <CRow>
                                                            <CCol md={6}>
                                                                <CFormLabel>Image</CFormLabel>
                                                                <span className="text-danger" style={{ marginLeft: '5px' }}>*</span>
                                                                <DefaultInput
                                                                    type={'file'}
                                                                    placeholder={'Image'}
                                                                    // MIN_FILE_SIZE={1024}
                                                                    // MAX_FILE_SIZE={5120}
                                                                    title={"1024 X 1024"}
                                                                    size={5}
                                                                    // file={props?.values?.image}
                                                                    // accept="image/*"
                                                                    accept="image/png, image/gif, image/jpeg, image/jpg"
                                                                    // refr={fileRef}

                                                                    onChange={(e) => {
                                                                        setImageFile(e?.target?.files[0]);
                                                                    }}
                                                                    // if (!imageFile?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
                                                                    //     setInvalidImage("Please select valid image")
                                                                    // }
                                                                    //     else {
                                                                    //         setInvalidImage('')
                                                                    //     }
                                                                    //     //for image preview
                                                                    //     const reader = new FileReader();
                                                                    //     reader?.addEventListener("load", () => setUserImagePreview(reader?.result));
                                                                    //     reader?.readAsDataURL(e?.target?.files[0]);
                                                                    //     //for image preview
                                                                    //     props?.setFieldValue('image', e?.target?.files[0])
                                                                    //     // console.log(imageFile, "imageFile")
                                                                    // }}
                                                                    onBlur={() => props?.setFieldTouched('image', true)}
                                                                    error={(props?.touched?.image && props?.errors?.image) || invalidImage}
                                                                />
                                                                {/* for image preview */}
                                                                {/* {userImagePreview?.length > 0 ?
                                                                    <>
                                                                        <CCol>
                                                                            <CImage
                                                                                rounded
                                                                                thumbnail
                                                                                className="mt-2"
                                                                                src={`${userImagePreview}`}
                                                                                style={{
                                                                                    maxHeight: '80px',
                                                                                    maxWidth: '80px',
                                                                                    alignItems: 'center',
                                                                                }}
                                                                            />
                                                                        </CCol>
                                                                    </>
                                                                    : ''} */}
                                                                {/* for image preview */}
                                                            </CCol>
                                                            <CCol md={6}>
                                                                <DefaultTextarea
                                                                    placeholder={"Address"}
                                                                    name={"address"}
                                                                    id={"address"}
                                                                    maxLength={50}
                                                                    value={props?.values?.address}
                                                                    onChange={props?.handleChange("address")}
                                                                    onBlur={() =>
                                                                        props?.setFieldTouched("address", true)
                                                                    }
                                                                    error={props?.touched?.address && props?.errors?.address}
                                                                />

                                                            </CCol>
                                                        </CRow>
                                                        <hr />
                                                        <CRow>
                                                            <CCol>
                                                                <CFormLabel>Add on Services</CFormLabel>
                                                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                                                <Select
                                                                    isMulti={true}
                                                                    defaultValue={selectService}
                                                                    onChange={(val) => {
                                                                        setSelectService(val)
                                                                        props?.setFieldValue("addOnServiceId", val)
                                                                        // props?.setFieldValue("areaName", val)
                                                                    }}
                                                                    options={addOnServiceList}
                                                                    value={selectService}
                                                                    placeholder={"Select Add on Service"}
                                                                    name="Add on Sevices" />
                                                                {selectService?.length == 0 ? (
                                                                    <span className="text-danger">Add on Service required</span>
                                                                ) : (
                                                                    ''
                                                                )}
                                                                <br />
                                                            </CCol>
                                                            <CCol>
                                                                <CFormLabel>No of Packets</CFormLabel>
                                                                <span className="text-danger" style={{ marginLeft: '5px' }}>*</span>
                                                                <DefaultInput
                                                                    type={'text'}
                                                                    placeholder={'No Of Packet'}
                                                                    id={'noOfPacket'}
                                                                    maxLength="70"
                                                                    value={props?.values?.noOfPacket}
                                                                    onChange={props?.handleChange('noOfPacket')}
                                                                    onBlur={() => props?.setFieldTouched('noOfPacket', true)}
                                                                    error={props?.touched?.noOfPacket && props?.errors?.noOfPacket}
                                                                />
                                                            </CCol>
                                                        </CRow>

                                                        <CRow>
                                                            <CCol md="2">
                                                                <button
                                                                    type="submit"

                                                                    className={'btn btn-success mt-2'}
                                                                // disabled={props?.isSubmitting}
                                                                >
                                                                    Submit
                                                                </button>
                                                            </CCol>
                                                        </CRow>
                                                    </form>
                                                )}
                                            </Formik>
                                        </CCardBody>
                                    </CCardGroup>
                                </CCol>
                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </>
    )
}

export default edit