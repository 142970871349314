import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { get, post, deleteApi } from '../../utils/apiManager'
import CIcon from '@coreui/icons-react'
import { cibElectron, cilPen, cilPlus, cilTrash } from '@coreui/icons'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CCardSubtitle,
  CTable,
  CRow,
  CImage,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CButton,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import { DefaultInputWithIcon } from 'src/components/common/input'
import Pagination from 'react-js-pagination'
import {
  FaRegEye,
  FaEdit,
  FaTrash,
  FaTrashAlt,
  FaPrint,
  FaSearchengin,
  FaTruckMoving,
  FaRegAddressCard,
  FaShoppingCart,
} from 'react-icons/fa'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

export default function OfflineUserBagsList() {
  const [show, setShow] = useState(false)
  // const [users, setUsers] = useState(true);
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const navigate = useNavigate()
  const [userData, setUserData] = useState([])
  const [orderData, setOrderData] = useState([])
  const [bagData, setBagData] = useState([])
  const [imageUrl, setimageUrl] = useState('')
  const [totalPages, settotalPages] = useState(0)
  const [pageData, setpageData] = useState({
    page: 1,
    perPage: 10,
    searchItem: '',
  })
  const [searchItem, setsearchItem] = useState('')
  const [selectedBag, setSelectedBag] = useState(null)

  const { orderId, userId } = useParams()
  const getUserData = () => {
    get(
      `admin/offline-order/bag-list/${orderId}?page=${pageData?.page || 1}&perPage=${
        pageData?.perPage || 10
      }&searchItem=${pageData?.searchItem || ''}`,
    )
      .then(
        (response) => handleResponse(response),
        //  console.log(response)
      )
      .catch((error) => {
        console.log(error)
      })
  }
  const handleResponse = (response) => {
    if (!response?.status) toast.success(response?.message, response?.status)
    else {
      // setTimeout(() => {
      //   setUsers(false);
      // }, 1500);
      setOrderData([...response?.data])
      settotalPages(response?.pages)
      setimageUrl(response?.imageUrl)
    }
  }

  useEffect(() => {
    if (selectedBag) {
      get(
        `admin/offline-order/get-examin-item-details-offline?bagId=${selectedBag}&order_id=${orderId}&user_id=${userId}`,
      )
        .then(
          (response) => handleBagResponse(response),
          //  console.log(response)
        )
        .catch((error) => {
          console.log(error)
        })
    }
  }, [selectedBag])

  const handleBagResponse = (response) => {
    console.log({ response })
    if (!response?.status) toast.success(response?.message, response?.status)
    else {
      // setTimeout(() => {
      //   setUsers(false);
      // }, 1500);
      setBagData([...response?.data])
    }
  }

  const changePage = (index) => {
    setpageData({
      ...pageData,
      page: index,
    })
  }
  const onSearchCLick = () => {
    setpageData({
      ...pageData,
      searchItem: searchItem,
    })
  }
  const statusChange = (status, id) => {
    // console.log(status,id)
    let default_status = ''
    if (status == 0) default_status = 'Active'
    else default_status = 'Inactive'
    if (confirm(`Are you sure you want to ${default_status} the user`)) {
      let formData = {
        _id: id,
      }
      post(`${status ? '/admin/User/inactive' : '/admin/User/active'}`, formData).then((data) => {
        handleStatusChange(data)
      })
    }
  }
  const handleStatusChange = (data) => {
    // console.log(data)
    toast.success(data?.message, data?.status)
    getUserData()
  }
  const onClickUserEdit = async (value) => {
    navigate(`/admin/offline-order/edit`, { state: value })
    // navigate(`/admin/offline-order/edit/${value?._id}`, { state: value })
  }
  const onClickUserView = (value) => {
    navigate(`/admin/user-detail/${value?._id}`, { state: value })
  }

  const deleteData = (id) => {
    if (confirm('Are you sure')) {
      let formData = {
        _id: id,
      }
      deleteApi(`/admin/User/delete?_id=${id}`).then((data) => {
        handleDelete(data)
      })
    }
  }
  const handleDelete = (data) => {
    toast.success(data?.message, data?.status)
    getUserData()
  }
  const addDefaultSrc = (ev) => {
    ev.target.src = 'img/no-image.png'
  }
  useEffect(() => {
    getUserData()
  }, [pageData])

  return (
    <>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <CCardSubtitle as="h4">Offline Bags</CCardSubtitle>
                  <CRow>
                    <CCol md="6">
                      <DefaultInputWithIcon
                        type={'text'}
                        placeholder="Search"
                        customType={'search'}
                        value={searchItem}
                        onClick={onSearchCLick}
                        onChange={(e) => {
                          onSearchCLick()
                          setsearchItem(e?.target?.value)
                        }}
                      />
                    </CCol>
                    {/* <CCol md="2"></CCol> */}
                    <CCol md="3">
                      <Link to={{ pathname: '/admin/offline-order/add' }} className="btn btn-dark">
                        <CIcon icon={cilPlus} />
                        Add
                      </Link>
                    </CCol>
                    <CCol md="3">
                      <Link
                        className={'btn btn-success'}
                        style={{ background: '#d3dc40', border: '#d3dc40' }}
                        to={{ pathname: `/admin/offline-order/${userId}` }}
                      >
                        Back
                      </Link>
                    </CCol>
                  </CRow>
                </CHeader>
                <CRow>
                  <CCol md="4">
                    {orderData.length > 0 &&
                      orderData[0].bagsDetails &&
                      orderData[0].bagsDetails.map((_order, index) => (
                        <CRow key={_order._id}>
                          <div
                            style={{
                              padding: '30px',
                              border: '1px solid black',
                              textAlign: 'center',
                              margin: '10px',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setSelectedBag(_order._id)
                            }}
                          >
                            bag {index + 1}
                          </div>
                        </CRow>
                      ))}
                  </CCol>
                  <CCol
                    md="8"
                    style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
                  >
                    {!selectedBag && (
                      <>
                        <h1>No bag selected</h1>
                        {/* <Link
                          to={{
                            pathname: `/admin/offline-bags/${orderId}/${userId}/${selectedBag}`,
                          }}
                          className={'btn btn-success mt-2'}
                        >
                          Add Item
                        </Link> */}
                      </>
                    )}

                    {selectedBag && bagData && bagData.length === 0 && (
                      <>
                        <h1>No Item exits in this bag.</h1>
                        <Link
                          to={{
                            pathname: `/admin/offline-bags/${orderId}/${userId}/${selectedBag}`,
                          }}
                          className={'btn btn-success mt-2'}
                        >
                          Add Item
                        </Link>
                      </>
                    )}
                    {selectedBag && bagData && bagData.length > 0 && (
                      <CCardBody>
                        <CTable bordered hover responsive>
                          <CTableHead>
                            <CTableRow>
                              <CTableHeaderCell>S. No</CTableHeaderCell>
                              {/* <CTableHeaderCell>Order ID</CTableHeaderCell>
                        <CTableHeaderCell>Order Status</CTableHeaderCell> */}
                              {/* <CTableHeaderCell>Order Type</CTableHeaderCell> */}
                              <CTableHeaderCell>Item Name</CTableHeaderCell>
                              <CTableHeaderCell>Color Name</CTableHeaderCell>
                              {/* <CTableHeaderCell>Action</CTableHeaderCell> */}
                            </CTableRow>
                          </CTableHead>
                          <CTableBody>
                            {bagData.map((item, index) => {
                              return (
                                <CTableRow key={index}>
                                  <CTableDataCell>
                                    {pageData?.perPage * (pageData?.page - 1) + index + 1}
                                  </CTableDataCell>
                                  <CTableDataCell>{item?.itemName?.itemName_EN}</CTableDataCell>
                                  <CTableDataCell>{item?.color.name}</CTableDataCell>
                                  {/* <CTableDataCell>
                                    <Link
                                      className="mx-2"
                                      // onClick={() => onClickUser(item)}
                                      to={`/admin/offline-order/${item._id}`}
                                      style={{
                                        fontSize: '20px',
                                        color: '#d3dc40',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      <FaShoppingCart />
                                    </Link>
                                  </CTableDataCell> */}
                                </CTableRow>
                              )
                            })}
                          </CTableBody>
                        </CTable>
                        {!bagData?.length && (
                          <>
                            <h1>No Data</h1>
                          </>
                        )}
                        {bagData?.length ? (
                          <Pagination
                            itemClass="page-item"
                            linkClass="page-link"
                            activePage={pageData?.page}
                            itemsCountPerPage={pageData?.perPage}
                            totalItemsCount={totalPages * 10}
                            pageRangeDisplayed={5}
                            onChange={changePage}
                          />
                        ) : (
                          ''
                        )}
                      </CCardBody>
                    )}
                  </CCol>
                </CRow>
                {/* {users !== true ? ( */}
                <CCardBody>
                  {!orderData?.length && (
                    <>
                      <h1>No Data</h1>
                    </>
                  )}
                  {/* {orderData?.length ? <Pagination
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        activePage={pageData?.page}
                                        itemsCountPerPage={pageData?.perPage}
                                        // totalItemsCount={count}
                                        pageRangeDisplayed={5}
                                        onChange={changePage}
                                    /> : ''} */}
                </CCardBody>
                {/* )
                   : (<div className="lodderClass" style={{ width: '50%', marginLeft: '20%' }}>

                     <Lottie animationData={groovyWalkAnimation} />
                   </div>)} */}
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
      {/* {userData && userData.map((data, key) => {
        console.log(data)
        console.log(data?.status)
        return(
                    <>
                      <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>Are you sure</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button style={{background:'#d3dc40',border:'#d3dc40'}} onClick={() =>Changestatus(data?.status, data?._id)}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
                    </>
                  )
                })} */}
    </>
  )
}
