import React, { useState, useEffect } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { get } from 'src/utils/apiManager';
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CCardSubtitle,
  CRow,
  CFormLabel,
  CImage,
} from '@coreui/react'
import { ImageUrl } from 'src/config/config'
import { FaArrowLeft } from 'react-icons/fa'

function view() {
  const { state } = useLocation()
  const { id } = useParams()
  const [unitData, setUnitData] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [serviceData, setServiceData] = useState([]);

  const singleService = () => {
    get(`admin/services/view-service?_id=${atob(id)}`).then((data) => {
      if (data?.data !== null) {
        const tempData = data?.data?.map((item, key) => {
          setServiceData(item)
          item?.unit?.map((data, key) => {
            setUnitData(data)
          })
          item?.item?.map((data, key) => {
            setItemData(data)
          })
        })
      }
    })
  }
  useEffect(() => {
    singleService();
  }, [state])
  return (
    <>
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <CRow>
                    <CCol >
                      {' '}
                      <Link style={{ color: '#000' }} to={{ pathname: `/admin/services` }}>
                        <FaArrowLeft />
                      </Link>
                      <span>  Service Information</span>
                    </CCol>
                  </CRow>
                </CHeader>
                <CCardBody>
                  <CRow>
                    <CCol md={2}>
                      <strong>Service Name :-</strong>
                    </CCol>
                    <CCol>
                      <span>{serviceData?.serviceName_EN}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Sub Title :-</strong>
                    </CCol>
                    <CCol>
                      <span>{serviceData?.subTitle_EN}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Price :-</strong>
                    </CCol>
                    <CCol>
                      <span>{serviceData?.price}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Unit :-</strong>
                    </CCol>
                    <CCol>
                      <span>{unitData?.title_EN}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Service Quota :-</strong>
                    </CCol>
                    <CCol>
                      <span>{serviceData?.serviceQuota}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Items :-</strong>
                    </CCol>
                    <CCol>
                      <span>{itemData?.itemName_EN}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Description :-</strong>
                    </CCol>
                    <CCol>
                      <span dangerouslySetInnerHTML={{ __html: serviceData?.description_EN }}></span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Image:-</strong>
                    </CCol>
                    <CCol>
                      <div
                        style={{
                          marginTop: '10px',
                          justifyContent: 'center',
                        }}
                      >
                        <a target='_blank' href={`${ImageUrl}${state?.image}`}>
                          <CImage
                            rounded
                            src={`${ImageUrl}${state?.image}`}
                            style={{
                              maxHeight: '100px',
                              maxWidth: '100px',
                              borderRadius: 50,
                              alignItems: 'center',
                            }}
                          />
                        </a>
                      </div>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default view