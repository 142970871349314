import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { post, get } from '../../utils/apiManager'
import CIcon from '@coreui/icons-react'
import { cilCloudDownload } from '@coreui/icons'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CCardSubtitle,
  CTable,
  CRow,
  CImage,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CButton,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import Pagination from 'react-js-pagination'
import moment from 'moment'
import { DefaultInputWithIcon } from 'src/components/common/input'
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { FaSearchengin } from 'react-icons/fa'

function OrdersReport() {
  const navigate = useNavigate();
  const [orderList, setPlacedOrderList] = useState([]);
  const [totalPages, settotalPages] = useState(0);
  const [searchItem, setsearchItem] = useState('');
  const [fromDate, setFromDate] = useState(moment().subtract(1, 'year'))
  const [toDate, setToDate] = useState(moment())
  const [toMinimum, setToMinimum] = useState(moment().subtract(1, 'year'));
  const [fromMax, setFromMax] = useState(null);
  const [pageData, setpageData] = useState({
    page: 1,
    perPage: 10,
    searchItem: "",
  });

  const from_date = (moment(fromDate)).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
  const to_date = (moment(toDate)).format("YYYY-MM-DDTHH:mm:ss[Z]")

  useEffect(() => {
    getData();
  }, [pageData, fromDate, toDate]);

  const getData = () => {
    get(
      `/admin/placed-order/order-placed-list?page=${pageData?.page || 1}&perPage=${pageData?.perPage || 10
      }&searchItem=${pageData?.searchItem || ""}&from_date=${from_date}&to_date=${to_date}`
    ).then((res) => handleResponse(res));
  };

  const handleResponse = (res) => {
    setPlacedOrderList(res?.data[0]?.data)
    settotalPages(res?.data[0]?.metadata[0]?.page);
  };

  const downloadUsersData = () => {
    const temp = {
      to_date: to_date,
      from_date: from_date,
      queryData: pageData
    }

    post(`/admin/report/order-csv-download`, temp).then((res) => {
      if (res?.status) {
        const linkurl = res?.url
        const link = document.createElement("a");
        link.download = linkurl;
        link.href = linkurl;
        link.click();
        toast.success("CSV Downloaded Successfully")
      }
    })
  }

  const onSearchCLick = () => {
    setpageData({
      ...pageData,
      searchItem: searchItem,
    })
  }

  const changePage = (index) => {
    setpageData({
      ...pageData,
      page: index,
    });
  };

  const handleDateChange = (event, type) => {
    if (type == 'fromDate') {
      setFromDate(event?.$d)
      setToMinimum(event?.$d)
    } else {
      setToDate(event?.$d)
      setFromMax(event?.$d)
    }
  }

  return (
    <>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <CCardSubtitle as="h4">Orders Report</CCardSubtitle>
                  <CRow>
                    <CCol md="3">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          inputFormat="DD/MM/YYYY"
                          name="fromDate"
                          value={fromDate}
                          maxDate={fromMax == null ? moment() : fromMax}
                          onChange={(e) => {
                            handleDateChange(e, 'fromDate')
                          }}
                          renderInput={(params) => <TextField style={{ marginTop: '5px' }} name="fromDate" {...params} />}
                        />
                      </LocalizationProvider>
                    </CCol>
                    <CCol md="3">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          inputFormat="DD/MM/YYYY"
                          name="toDate"
                          value={toDate}
                          minDate={toMinimum}
                          maxDate={moment()}
                          onChange={(e) => {
                            handleDateChange(e, 'toDate')
                          }}
                          renderInput={(params) => <TextField style={{ marginTop: '5px' }} name="toDate" {...params} />}
                        />
                      </LocalizationProvider>
                    </CCol>
                    <CCol md="3">
                      <DefaultInputWithIcon
                        type={'text'}
                        placeholder="Search"
                        customType={'search'}
                        value={searchItem}
                        onClick={onSearchCLick}
                        onChange={(e) => {
                          onSearchCLick()
                          setsearchItem(e?.target?.value)
                        }}
                      />
                    </CCol>
                    {/* <CCol md="2"></CCol> */}
                    <CCol md="3" align={'right'}>
                      <Link onClick={() => downloadUsersData()} className="btn btn-dark">
                        <CIcon icon={cilCloudDownload} />
                        <span style={{ marginLeft: '2px' }}>Export CSV</span>
                      </Link>
                    </CCol>
                  </CRow>
                </CHeader>

                <CCardBody>
                  <CTable bordered hover responsive>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell>S. No</CTableHeaderCell>
                        <CTableHeaderCell>Order ID</CTableHeaderCell>
                        <CTableHeaderCell>Order Status</CTableHeaderCell>
                        <CTableHeaderCell>Order Type</CTableHeaderCell>
                        <CTableHeaderCell>Package/Services</CTableHeaderCell>
                        <CTableHeaderCell>User Name</CTableHeaderCell>
                        <CTableHeaderCell>User Conatct No.</CTableHeaderCell>
                        <CTableHeaderCell>Driver Name</CTableHeaderCell>
                        <CTableHeaderCell>Driver Contact No.</CTableHeaderCell>
                        <CTableHeaderCell>Driver Status</CTableHeaderCell>
                        <CTableHeaderCell>Order Placed Date</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {orderList?.map((ele, index) => {
                        return (
                          <CTableRow key={index}>
                            {console.log('ele', ele)}
                            <CTableDataCell>{pageData?.perPage * (pageData?.page - 1) + index + 1}</CTableDataCell>
                            <CTableDataCell>#{ele?.orderId}</CTableDataCell>
                            {/* <CTableDataCell>{ele?.orderStatus}</CTableDataCell> */}
                            <CTableDataCell >
                              {
                                ele?.orderStatus == 'order_place' ?
                                  <CButton size="sm" style={{ backgroundColor: '#d3dc40', border: '#d3dc40' }} outline={ele?.orderStatus}>
                                    <span><strong>Order Place</strong></span>
                                  </CButton>
                                  : ele?.orderStatus == 'cancel(request)' ?
                                    <CButton size="sm" style={{ backgroundColor: '#BDBDBD', border: '#BDBDBD' }} outline={ele?.orderStatus}>
                                      <span><strong>Customer Request </strong></span>
                                    </CButton>
                                    : ele?.orderStatus == 'cancel(customer)' ?
                                      <CButton size="sm" style={{ backgroundColor: '#757575', border: '#757575' }} outline={ele?.orderStatus}>
                                        <span><strong>Cancel By Customer </strong></span>
                                      </CButton>
                                      : ele?.orderStatus == 'picked_up' ?
                                        <CButton size="sm" style={{ backgroundColor: '#FBC02D', border: '#FBC02D' }} outline={ele?.orderStatus}>
                                          <span><strong>Picked Up</strong></span>
                                        </CButton>
                                        : ele?.orderStatus == 'inprocess(Examine clothes)' ?
                                          <CButton size="sm" style={{ backgroundColor: '#757575', border: '#757575' }} outline={ele?.orderStatus}>
                                            <span><strong>In Process {' '}<FaSearchengin /></strong></span>
                                          </CButton>
                                          : ele?.orderStatus == 'inprocess(service process)' ?
                                            <CButton size="sm" style={{ backgroundColor: '#BDBDBD', border: '#BDBDBD' }} outline={ele?.orderStatus}>
                                              <span><strong>In Process {' '}<CIcon icon={cibElectron} customClassName="nav-icon" style={{ height: '20px' }} /></strong></span>
                                            </CButton>
                                            : ele?.orderStatus == 'inprocess(payment success)' ?
                                              <CButton size="sm" style={{ backgroundColor: '#BDBDBD', border: '#BDBDBD' }} outline={ele?.orderStatus}>
                                                <span><strong>Payment Process {ele?.orderStatus}</strong></span>
                                              </CButton>




                                              : ele?.orderStatus == 'cancel' ?
                                                <CButton size="sm" style={{ backgroundColor: '#FF0000', border: '#FF0000' }} outline={ele?.orderStatus}>
                                                  <span><strong>Cancel</strong></span>
                                                </CButton>

                                                : ele?.orderStatus == 'hold' ?
                                                  <CButton size="sm" style={{ backgroundColor: '#2196F3', border: '#2196F3' }} outline={ele?.orderStatus}>
                                                    <span><strong>Hold</strong></span>
                                                  </CButton>
                                                  : ele?.orderStatus == 'delivery' ?
                                                    <CButton size="sm" style={{ backgroundColor: '#689F38', border: '#689F38' }} outline={ele?.orderStatus}>
                                                      <span><strong>Ready to Deliver</strong></span>
                                                    </CButton>
                                                    : ele?.orderStatus == 'out_for_delivery' ?
                                                      <CButton size="sm" style={{ backgroundColor: '#689F38', border: '#689F38' }} outline={ele?.orderStatus}>
                                                        <span><strong>Out for deliver</strong></span>
                                                      </CButton>
                                                      : ele?.orderStatus == 'deliverd' ?
                                                        <CButton size="sm" style={{ backgroundColor: '#2E7D32', border: '#2E7D32' }} outline={ele?.orderStatus}>
                                                          <span><strong>Deliverd</strong></span>
                                                        </CButton>
                                                        : 'In Process'
                              }
                            </CTableDataCell>
                            <CTableDataCell style={{
                              color: ele?.packageUser?.length > 0 ? '#A79B46' : '#2196F3',
                              fontWeight: ele?.packageUser?.length > 0 ? 500 : 500,
                            }}>
                              {ele?.packageUser?.length > 0 ? "PACKAGE USER" : "REGULER USER"}
                            </CTableDataCell>
                            <CTableDataCell>{ele?.packageDetails.length > 0 ? ele?.packageDetails[0]?.packageName_EN : '-'}</CTableDataCell>
                            <CTableDataCell>{ele?.user[0]?.username}</CTableDataCell>
                            <CTableDataCell>{ele?.user[0]?.mobile}</CTableDataCell>
                            <CTableDataCell>{ele?.driverDetails[0]?.username}</CTableDataCell>
                            <CTableDataCell>{ele?.driverDetails[0]?.mobile}</CTableDataCell>
                            <CTableDataCell >
                              {
                                ele?.driverStatus == 'order_place' ?
                                  <CButton size="sm" style={{ backgroundColor: '#d3dc40', border: '#d3dc40' }} outline={ele?.driverStatus}>
                                    <span><strong>Order Place</strong></span>
                                  </CButton>
                                  : ele?.driverStatus == 'cancel(request)' ?
                                    <CButton size="sm" style={{ backgroundColor: '#BDBDBD', border: '#BDBDBD' }} outline={ele?.driverStatus}>
                                      <span><strong>Customer Request </strong></span>
                                    </CButton>
                                    : ele?.driverStatus == 'cancel(customer)' ?
                                      <CButton size="sm" style={{ backgroundColor: '#757575', border: '#757575' }} outline={ele?.driverStatus}>
                                        <span><strong>Cancel By Customer </strong></span>
                                      </CButton>
                                      : ele?.driverStatus == 'picked_up' ?
                                        <CButton size="sm" style={{ backgroundColor: '#FBC02D', border: '#FBC02D' }} outline={ele?.driverStatus}>
                                          <span><strong>Picked Up</strong></span>
                                        </CButton>
                                        : ele?.driverStatus == 'inprocess(Examine clothes)' ?
                                          <CButton size="sm" style={{ backgroundColor: '#757575', border: '#757575' }} outline={ele?.driverStatus}>
                                            <span><strong>In Process {' '}<FaSearchengin /></strong></span>
                                          </CButton>
                                          : ele?.driverStatus == 'inprocess(service process)' ?
                                            <CButton size="sm" style={{ backgroundColor: '#BDBDBD', border: '#BDBDBD' }} outline={ele?.driverStatus}>
                                              <span><strong>In Process {' '}<CIcon icon={cibElectron} customClassName="nav-icon" style={{ height: '20px' }} /></strong></span>
                                            </CButton>
                                            : ele?.driverStatus == 'inprocess(payment success)' ?
                                              <CButton size="sm" style={{ backgroundColor: '#BDBDBD', border: '#BDBDBD' }} outline={ele?.driverStatus}>
                                                <span><strong>Payment Process {ele?.driverStatus}</strong></span>
                                              </CButton>
                                              : ele?.driverStatus == 'cancel' ?
                                                <CButton size="sm" style={{ backgroundColor: '#FF0000', border: '#FF0000' }} outline={ele?.driverStatus}>
                                                  <span><strong>Cancel</strong></span>
                                                </CButton>

                                                : ele?.driverStatus == 'hold' ?
                                                  <CButton size="sm" style={{ backgroundColor: '#2196F3', border: '#2196F3' }} outline={ele?.driverStatus}>
                                                    <span><strong>Hold</strong></span>
                                                  </CButton>
                                                  : ele?.driverStatus == 'delivery' ?
                                                    <CButton size="sm" style={{ backgroundColor: '#689F38', border: '#689F38' }} outline={ele?.driverStatus}>
                                                      <span><strong>Ready to Deliver</strong></span>
                                                    </CButton>
                                                    : ele?.driverStatus == 'out_for_delivery' ?
                                                      <CButton size="sm" style={{ backgroundColor: '#689F38', border: '#689F38' }} outline={ele?.driverStatus}>
                                                        <span><strong>Out for deliver</strong></span>
                                                      </CButton>
                                                      : ele?.driverStatus == 'deliverd' ?
                                                        <CButton size="sm" style={{ backgroundColor: '#2E7D32', border: '#2E7D32' }} outline={ele?.driverStatus}>
                                                          <span><strong>Deliverd</strong></span>
                                                        </CButton>
                                                        : 'In Process'
                              }
                            </CTableDataCell>
                            <CTableDataCell>{moment(ele?.createdAt).format('DD MMMM YYYY h:mm:ss a')}</CTableDataCell>

                          </CTableRow>
                        );
                      })}
                    </CTableBody>
                  </CTable>
                  {!orderList?.length && (
                    <>
                      <h1>No Data</h1>
                    </>
                  )}
                  {orderList?.length ? <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={pageData?.page}
                    itemsCountPerPage={pageData?.perPage}
                    totalItemsCount={totalPages * 10}
                    pageRangeDisplayed={5}
                    onChange={changePage}
                  /> : ''}
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default OrdersReport