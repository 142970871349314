import { CCard, CCardBody, CCardGroup, CCol, CContainer, CRow } from '@coreui/react'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { get, post } from 'src/utils/apiManager'

const OnlineUserList = () => {
  const [userListData, setUserListData] = useState([])
  const [newOrderId, setNewOrderId] = useState('')

  const navigate = useNavigate()

  useEffect(() => {
    get(`/admin/offline-order/offline-list?userType=online`).then((res) => {
      if (res?.data !== null) {
        // console.log(res.data)
        setUserListData([...res.data])
        // res?.data?.map((item, key) => {
        //   setUserListData()
        // })
      }
    })
  }, [])

  const handleClick = (customerId) => {
    post(`admin/offline-order/create/${customerId}`, {}, 0).then((data) => {
      if (data.status) {
        setNewOrderId(data?.newOrder?._id)
        navigate(`/admin/offline-order/bags/${data?.newOrder?._id}`)
      }
    })
  }

  // console.log({ newOrderId })

  // const fetchStateID = () => {

  // }
  return (
    <CContainer>
      <CCard>
        <CCardGroup>
          <CCardBody>
            <CRow className="p-2 align-items-center">
              <CCol>
                <h5 style={{ textTransform: 'uppercase' }}>Online Users</h5>
              </CCol>
              <CCol md="1">
                <Link
                  className={'btn btn-success'}
                  style={{ background: '#d3dc40', border: '#d3dc40' }}
                  to={{ pathname: `/admin/offline-order` }}
                >
                  Back
                </Link>
              </CCol>
            </CRow>
            <hr />
            <CRow className="p-2 align-items-center">
              {/* <CCol xs={2}>
                <Link
                  to="/admin/offline-user-add"
                  style={{
                    textDecoration: 'none',
                    color: 'black',
                    fontSize: '55px',
                    fontWeight: 'lighter',
                    boxShadow: '1px 1px 3px black',
                  }}
                >
                  <div
                    style={{
                      border: '1px solid black',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      boxShadow: '1px 1px 3px black',
                      border: 'none',
                    }}
                  >
                    +
                  </div>
                </Link>
              </CCol> */}
              {userListData.length > 0 &&
                userListData.map((data) => (
                  <CCol xs={2} key={data._id}>
                    {/* <div
                      to={`/admin/offline-order/${data._id}`}
                      style={{
                        textDecoration: 'none',
                        color: 'black',
                      }}
                    > */}
                    <div
                      onClick={() => handleClick(data._id)}
                      style={{
                        padding: '30px',
                        border: '1px solid black',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxShadow: '1px 1px 3px black',
                        border: 'none',
                      }}
                    >
                      {data.username}
                    </div>
                    {/* </div> */}
                  </CCol>
                ))}
            </CRow>
          </CCardBody>
        </CCardGroup>
      </CCard>
    </CContainer>
  )
}

export default OnlineUserList
