import React, { useEffect, useState } from 'react'
import { CCard, CCardBody, CCardGroup, CCol, CContainer, CHeader, CRow } from '@coreui/react'
import { ImageUrl2, baseURL } from 'src/config/config'
import ImagePreview from '../previewImage'
import ItemImagePreview from '../itemImagePreview'
import { useDispatch } from 'react-redux'
import { clearGarmentData } from 'src/reduxToolkit/slice/garmentShorting'
import { useLocation } from 'react-router-dom'
import { get, post } from '../../../utils/apiManager'
import { Link, useNavigate, useParams } from 'react-router-dom'

const OrderView = () => {
  const navigate = useNavigate()
  const [itemDetail, setItemDetail] = useState({})
  const [itemDetailOrder, setItemDetailOrder] = useState(null)
  const [selectedItem, setSelectedItem] = useState('')
  const [itemCode, setItemCode] = useState('')
  const { state } = useLocation()
  const { item } = state
  const { orderData } = state
  const [allItems, setAllItems] = useState([])

  const getData = () => {
    get(`/admin/offline-order/bag-details/items/${item._id}`).then((res) => {
      setItemDetail(res?.data[0])
      setAllItems(res?.data)
      if (res?.data[0]?.item?.itemCode) {
        getItemDetail(res?.data[0]?.item?.itemCode)
      }
    })
  }

  useEffect(() => {
    getData()
  }, [])
  const viewOrder = () => {
    navigate(`/admin/search-bags/${orderData?.orderId}`)
  }
  const getItemDetail = (itemCode) => {
    setItemCode(itemCode)
    get(`/admin/placed-order/item-details?itemCode=${itemCode}`).then((data) => {
      if (data.status) {
        setSelectedItem(data.data._id)
        setItemDetailOrder({ ...data.data })
      }
    })
  }
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CCard style={{ flex: '0.2' }}>
          <CRow className="justify-content-center" style={{ padding: '0px 2px' }}>
            <CCol xs={11}>
              {itemDetail?.hasOwnProperty("item") && <CRow>
                <CCol
                  xs={12}
                  style={{
                    borderBottom: '1px solid #ccc',
                    width: '100%',
                    padding: '8px 5px',
                  }}
                >
                  Name : {itemDetailOrder?.user?.username} <br />
                  Service Name : {itemDetailOrder?.service?.serviceName_EN} <br />
                  Order Id : {itemDetailOrder?.order.orderId} <br />
                  Item Code : {itemCode} <br />
                </CCol>
              </CRow>}
              {allItems?.map((_item, index) => (
                _item.hasOwnProperty("item") && (<CRow key={index}>
                  <CCol
                    xs={12}
                    style={{
                      borderBottom: '1px solid #ccc',
                      //   borderBottom: `${allItems.length == index + 1 ? '' : '1px solid #ccc'}`,
                      width: '100%',
                      padding: '8px 5px',
                      cursor: 'pointer',
                      backgroundColor: _item.hasOwnProperty("item") && selectedItem === _item?.item?._id ? 'green' : 'white',
                      color: _item.hasOwnProperty("item") && selectedItem === _item?.item?._id ? 'white' : '#000',
                    }}
                    onClick={() => {
                      getItemDetail(_item?.item.itemCode)
                      setItemDetail(allItems[index])
                      setSelectedItem(_item?.item?._id)
                    }}
                  >
                    Item {index + 1}
                    <span style={{ float: 'Right' }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill={_item.hasOwnProperty("item") && selectedItem === _item?.item?._id ? 'white' : '#000'}
                      >
                        <path d="M7.293 4.707 14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z" />
                      </svg>
                    </span>
                  </CCol>
                </CRow>)
              ))}
            </CCol>
          </CRow>
        </CCard>

        <CCard style={{ flex: '0.6' }}>
          <CCardGroup>
            <CCardBody>
              {itemDetail && (
                <>
                  <CHeader className="mb-3">
                    <h4 className="mb-3">Item summary page</h4>
                    {/* <CCardSubtitle as="h4">Item summary page SCQ</CCardSubtitle> */}
                    <CRow>
                      <CCol md="12">
                        <button
                          className="btn btn-success"
                          style={{
                            width: '100%',
                            background: '#d3dc40',
                            border: '#d3dc40',
                          }}
                          onClick={viewOrder}
                        >
                          View Order Detail
                        </button>
                      </CCol>
                    </CRow>
                  </CHeader>

                  {/* <hr /> */}
                  {itemDetail?.hasOwnProperty("item") ? <>
                    <CRow className="justify-content-center mb-2 progress-list">
                      <CCol
                        xs={2}
                        className="align-items-center"
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <div style={{ position: 'relative' }}>
                          <div className="icon-box">
                            <div>
                              <i class="fa-solid fa-shirt"></i>
                            </div>
                            <div>{itemDetail?.items && <i class="fa-solid fa-check"></i>}</div>
                          </div>
                          <p style={{ textAlign: 'center', fontWeight: '600' }}>Type</p>
                        </div>
                        <div>
                          {/* <img src={`${ImageUrl2}/${itemDetail.items.image}`} /> */}
                          {itemDetail?.items && itemDetail?.items?.itemName_EN}
                        </div>
                      </CCol>

                      <CCol
                        xs={2}
                        className="align-items-center"
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <div style={{ position: 'relative' }}>
                          <div className="icon-box">
                            <div>
                              <i class="fa-solid fa-shirt"></i>
                            </div>
                            <div>{itemDetail?.item?.brand && <i class="fa-solid fa-check"></i>}</div>
                          </div>
                          <p style={{ textAlign: 'center', fontWeight: '600' }}>Brand</p>
                        </div>
                        <div>{itemDetail?.item?.brand && itemDetail?.item?.brand}</div>
                      </CCol>

                      <CCol
                        xs={2}
                        className="align-items-center"
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <div style={{ position: 'relative' }}>
                          <div className="icon-box">
                            <div>
                              <i class="fa-solid fa-shirt"></i>
                            </div>
                            <div>
                              {itemDetail?.colors?.length > 0 && <i class="fa-solid fa-check"></i>}
                            </div>
                          </div>
                          <p style={{ textAlign: 'center', fontWeight: '600' }}>Colors</p>
                        </div>
                        <CCol cs={6}>
                          {itemDetail?.colors?.length > 0 &&
                            itemDetail?.colors?.map((_color, id) => {
                              return (
                                <div
                                  style={{
                                    height: '50px',
                                    width: '50px',
                                    borderRadius: '50%',
                                    // border: '1px solid black',
                                    backgroundColor: _color?.colorCode,
                                    cursor: 'pointer',
                                    boxShadow: '0 1px 3px rgb(0 0 0 / 36%)',
                                    margin: '4px',
                                  }}
                                  key={id}
                                ></div>
                              )
                            })}
                        </CCol>
                      </CCol>

                      <CCol
                        xs={2}
                        className="align-items-center"
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <div style={{ position: 'relative' }}>
                          <div className="icon-box">
                            <div>
                              <i class="fa-solid fa-shirt"></i>
                            </div>
                            <div>
                              <i class="fa-solid fa-check"></i>
                            </div>
                          </div>
                          <p style={{ textAlign: 'center', fontWeight: '600' }}>Washing Programs</p>
                        </div>
                        <div>{itemDetail?.processes?.processName_EN}</div>
                      </CCol>

                      <CCol
                        xs={2}
                        className="align-items-center"
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <div style={{ position: 'relative' }}>
                          <div className="icon-box">
                            <div>
                              <i class="fa-solid fa-shirt"></i>
                            </div>
                            <div>
                              {itemDetail?.item?.picture && <i class="fa-solid fa-check"></i>}
                            </div>
                          </div>
                          <p style={{ textAlign: 'center', fontWeight: '600' }}>Image</p>
                        </div>
                        {itemDetail?.item?.picture && (
                          <>
                            <img
                              src={ImageUrl2 + itemDetail?.item?.picture}
                              style={{ cursor: 'pointer', width: '50%', height: '100px' }}
                            />
                            <ImagePreview
                              visible={visible}
                              setVisible={setVisible}
                              image={ImageUrl2 + itemDetail?.item?.picture}
                            />
                          </>
                        )}
                      </CCol>

                      <CCol
                        xs={2}
                        className="align-items-center"
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <div style={{ position: 'relative' }}>
                          <div className="icon-box">
                            <div>
                              <i class="fa-solid fa-shirt"></i>
                            </div>
                            <div>{itemDetail?.item?.stain && <i class="fa-solid fa-check"></i>}</div>
                          </div>
                          <p style={{ textAlign: 'center', fontWeight: '600' }}>Stain</p>
                        </div>
                        <div>{itemDetail?.item?.stain}</div>
                      </CCol>
                    </CRow>
                    <CRow>
                      <h5>Instructions:</h5>
                      <div>{itemDetail?.item?.description}</div>
                    </CRow>
                  </> : <h5>No item found.</h5>}
                </>
              )}
            </CCardBody>
          </CCardGroup>
        </CCard>
      </div>
    </>
  )
}

export default OrderView
