import React, { useRef, useState, useEffect } from 'react'
import { connect, Formik } from 'formik'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import { post, get, put } from 'src/utils/apiManager'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Select from 'react-select'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CFormLabel,
  CRow,
  CTabs,
  CNavItem,
  CNav,
  CTabContent,
  CTabPane,
  CNavLink,
  CImage,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from '@coreui/react'
import { DefaultInput } from 'src/components/common/input'
import { validateEditPackagePlan } from 'src/utils/validator'
import { ImageUrl } from 'src/config/config'
import { FormLabel } from 'react-bootstrap'
import { Ckeditor } from '../Template/Ckeditor'
// import { element } from 'prop-types'

function edit() {
  const navigate = useNavigate()
  const { state } = useLocation()

  const formRef = useRef(null)
  const fileRef = useRef(null)
  const fileRefSmall = useRef(null)
  const fileRefMedium = useRef(null)
  const fileRefLarge = useRef(null)


  const [isDisable, setIsDisable] = useState(false)
  const [invalidImage, setInvalidImage] = useState('')
  const [smallInvalidImage, setSmallInvalidImage] = useState("")
  const [mediumInvalidImage, setMediumInvalidImage] = useState("")
  const [largeInvalidImage, setLargeInvalidImage] = useState("")
  const [imageUrl, setimageUrl] = useState()
  const [mediumImageUrl, setMediumImageUrl] = useState()
  const [smallImageUrl, setSmallImageUrl] = useState()
  const [largeImageUrl, setLargeImageUrl] = useState()

  const [activeKey, setActiveKey] = useState(0)
  const [subscriptionType, setSubscriptionType] = useState('1')
  const [smallPackageSize, setSmallPackageSize] = useState('S')
  const [mediumPackageSize, setMediumPackageSize] = useState('M')
  const [largePackageSize, setLargePackageSize] = useState('L')

  const [description_EN, setDescription_EN] = useState('')
  const [description_TH, setDescription_TH] = useState('')
  const [small_pack_description_EN, setSmall_pack_description_EN] = useState('')
  const [small_pack_description_TH, setSmall_pack_description_TH] = useState('')
  const [medium_pack_description_EN, setMedium_pack_description_EN] = useState('')
  const [medium_pack_description_TH, setMedium_pack_description_TH] = useState('')
  const [large_pack_description_EN, setLarge_pack_description_EN] = useState('')
  const [large_pack_description_TH, setLarge_pack_description_TH] = useState('')

  const [selectService, setSelectService] = useState([])
  const [selectServiceM, setSelectServiceM] = useState([])
  const [selectServiceL, setSelectServiceL] = useState([])
  const [selectStatus, setSelectStatus] = useState({ label: 'Inactive', value: 'inactive' })
  const [selectStatus2, setSelectStatus2] = useState({ label: 'Inactive', value: 'inactive' })
  const [selectStatus3, setSelectStatus3] = useState({ label: 'Inactive', value: 'inactive' })
  const [serviceData, setServiceData] = useState([])
  const [serviceDataM, setServiceDataM] = useState([])
  const [serviceDataL, setServiceDataL] = useState([])

  // const [selectOffers, setSelectOffers] = useState([])
  // const [offerData, setOfferData] = useState(null)

  // const serviceId = [];
  const [selectItem, setSelectItem] = useState([])
  const [itemOptions, setItemOptions] = useState(null)
  // const [selectItem, setSelectItem] = useState(null)
  // const [itemOptions, setItemOptions] = useState([])

  const [selectDuration, setSelectDuration] = useState([])
  const [durationData, setDurationData] = useState(null)
  const [remaningServiceQuota, setRemainingServiceQuota] = useState([])
  const [remaningServiceQuotaM, setRemainingServiceQuotaM] = useState([])
  const [remaningServiceQuotaL, setRemainingServiceQuotaL] = useState([])
  const [allServices, setAllServices] = useState(null)
  const remaningQuota = []
  const [packageImagePreview, setPackageImagePreview] = useState()
  const [packageImagePreviewSmall, setPackageImagePreviewSmall] = useState()
  const [packageImagePreviewMedium, setPackageImagePreviewMedium] = useState()
  const [packageImagePreviewLarge, setPackageImagePreviewLarge] = useState()




  const getServices = () => {
    get(`admin/subscription/services`).then((data) => {
      // console?.log('services data_+_++', data)
      if (data?.data !== null) {
        let tempSelcetedData = []

        // const tempData = data?.data?.map((item, key) => {
        //   // console?.log('services data', item, typeof (state?.services))

        //   var ExistService = Object.values(state?.services)
        //   const isEqual = ExistService?.includes(item?._id)
        //   console?.log('isEqual', isEqual, selectService)
        //   if (isEqual) {
        //     tempSelcetedData?.push({ label: item?.serviceName_EN, value: item?._id })
        //   }
        //   return { label: item?.serviceName_EN, value: item?._id }
        // })
        let smallSelectedArray = []
        let largeSelectedArray = []

        let mediumSelectedArray = []
        let smallSelectedArrayRemaning = []
        let largeSelectedArrayRemaning = []
        let mediumSelectedArrayRemaning = []

        const tempData = data?.data?.map((item, key) => {
          let findSmall = state?.services?.s?.find((newItem) => newItem.serviceId == item._id)
          let findLarge = state?.services?.l?.find((newItem) => newItem.serviceId == item._id)
          let findMedium = state?.services?.m?.find((newItem) => newItem.serviceId == item._id)

          if (findSmall) {
            smallSelectedArray.push({
              label: item?.serviceName_EN,
              value: findSmall?.serviceId,
              quota: findSmall?.quota,
            })
            smallSelectedArrayRemaning.push({
              serviceName: item?.serviceName_EN,
              value: findSmall?.serviceId,
              quota: findSmall?.quota,
            })
          }
          if (findLarge) {
            largeSelectedArray.push({ label: item?.serviceName_EN, value: findLarge?.serviceId })
            largeSelectedArrayRemaning.push({
              serviceName: item?.serviceName_EN,
              value: findLarge?.serviceId,
              quota: findLarge?.quota,
            })
          }
          if (findMedium) {
            mediumSelectedArray.push({ label: item?.serviceName_EN, value: findMedium?.serviceId })
            mediumSelectedArrayRemaning.push({
              serviceName: item?.serviceName_EN,
              value: findMedium?.serviceId,
              quota: findMedium?.quota,
            })
          }
        })
        setRemainingServiceQuota(smallSelectedArrayRemaning)
        setRemainingServiceQuotaM(mediumSelectedArrayRemaning)
        setRemainingServiceQuotaL(largeSelectedArrayRemaning)

        setSelectService(smallSelectedArray)
        setSelectServiceM(mediumSelectedArray)
        setSelectServiceL(largeSelectedArray)

        // setSelectService([...tempSelcetedData])
        // get ka baad me dekhte hai
        // setServiceData([...tempData])
        setServiceData(
          data?.data?.map((newItem) => ({ value: newItem?._id, label: newItem.serviceName_EN })),
        )
        setServiceDataM(
          data?.data?.map((newItem) => ({ value: newItem?._id, label: newItem.serviceName_EN })),
        )
        setServiceDataL(
          data?.data?.map((newItem) => ({ value: newItem?._id, label: newItem.serviceName_EN })),
        )
        // setAllServices(data?.data)
      }
    })
  }
  const getItemData = (serviceId) => {
    get(`admin/subscription/get-item?_id=${serviceId?.toString()}`).then((data) => {
      let tempArr = []
      let selectedItemArr = []
      const tempData = data?.data?.forEach((data, key) => {
        var ExistItem = Object.values(state?.item)
        const itemArr = data?.item?.map((item, key) => {
          if (ExistItem?.find((element) => element == item?._id)) {
            selectedItemArr?.push({ label: item?.itemName_EN, value: item?._id })
          }
          return { label: item?.itemName_EN, value: item?._id }
        })

        setSelectItem([...selectedItemArr])
        // const itemArr = data?.item?.map((item, key) => {
        //   return { label: item?.itemName_EN, value: item?._id }
        // })

        tempArr.push.apply(tempArr, itemArr)
      })
      setItemOptions([...tempArr])
    })
  }
  // const getOffers = () => {
  //   get(`admin/subscription/offers`).then((data) => {
  //     console.log(data, "data")
  //     if (data?.data !== null) {
  //       let tempSelcetedData = []
  //       const tempData = data?.data?.map((item, key) => {
  //         var ExistOfferId = Object.values(state?.ApplyOffers)
  //         const isEqual = ExistOfferId?.includes(item?._id)
  //         // console?.log('isEqual', isEqual, selectService)
  //         if (isEqual) {
  //           tempSelcetedData?.push({ label: item?.title_EN, value: item?._id })
  //         }
  //         return { label: item?.title_EN, value: item?._id }

  //         // if (ExistOfferId?.find((element) => element == item?._id)) {
  //         //   selectItem?.push({ label: item?.title_EN, value: item?._id })
  //         // }
  //         // return { label: item?.title_EN, value: item?._id }
  //       })
  //       setSelectOffers([...tempSelcetedData])
  //       setOfferData([...tempData])
  //     }
  //   })
  // }
  useEffect(() => {
    if (selectService?.length > 0) {
      //for get service id which one is selected
      const selected = selectService?.map((i) => i?.value)
      // getItemData(selected);
    }
  }, [selectService])

  const getPlanDuration = () => {
    get(`admin/subscription/plan-duration`).then((data) => {
      if (data?.data !== null) {
        const tempData = data?.data?.map((item, key) => {
          if (state?.subcribeDuration == item?._id) {
            selectDuration.push({ label: item?.title_EN, value: item?._id })
          }
          return { label: item?.title_EN, value: item?._id }
        })
        setDurationData(tempData)
      }
    })
  }

  useEffect(() => {
    getServices()
    // getOffers()
    getPlanDuration()
    if (state != null) {
      formRef.current.setFieldValue('packageName_EN', state?.packageName_EN)
      formRef.current.setFieldValue('packageName_TH', state?.packageName_TH)
      formRef.current.setFieldValue('subcribeDuration', state?.subcribeDuration)
      formRef.current.setFieldValue('description_EN', state?.description_EN)
      formRef.current.setFieldValue('description_TH', state?.description_TH)
      formRef.current.setFieldValue('small_pack_description_EN', state?.small_pack_description_EN)
      formRef.current.setFieldValue('small_pack_description_TH', state?.small_pack_description_TH)
      formRef.current.setFieldValue('medium_pack_description_EN', state?.medium_pack_description_EN)
      formRef.current.setFieldValue('medium_pack_description_TH', state?.medium_pack_description_TH)
      formRef.current.setFieldValue('large_pack_description_EN', state?.large_pack_description_EN)
      formRef.current.setFieldValue('large_pack_description_TH', state?.large_pack_description_TH)
      formRef.current.setFieldValue('small_pack_title_EN', state?.small_pack_title_EN)
      formRef.current.setFieldValue('small_pack_title_TH', state?.small_pack_title_TH)
      formRef.current.setFieldValue('medium_pack_title_EN', state?.medium_pack_title_EN)
      formRef.current.setFieldValue('medium_pack_title_TH', state?.medium_pack_title_TH)
      formRef.current.setFieldValue('large_pack_title_EN', state?.large_pack_title_EN)
      formRef.current.setFieldValue('large_pack_title_TH', state?.large_pack_title_TH)

      formRef.current.setFieldValue('S_price', state?.S_price)
      formRef.current.setFieldValue('M_price', state?.M_price)
      formRef.current.setFieldValue('L_price', state?.L_price)
      formRef.current.setFieldValue('S_quantity', state?.S_quantity)
      formRef.current.setFieldValue('M_quantity', state?.M_quantity)
      formRef.current.setFieldValue('L_quantity', state?.L_quantity)
      formRef.current.setFieldValue('S_delivery', state?.S_delivery)
      formRef.current.setFieldValue('M_delivery', state?.M_delivery)
      formRef.current.setFieldValue('L_delivery', state?.L_delivery)
      setSelectStatus(
        state.packageStatus.s == "active" ? { label: 'Active', value: 'active' } : { label: 'Inactive', value: 'inactive' }
      )
      setSelectStatus2(
        state.packageStatus.m == "active" ? { label: 'Active', value: 'active' } : { label: 'Inactive', value: 'inactive' }
      )
      setSelectStatus3(
        state.packageStatus.l == "active" ? { label: 'Active', value: 'active' } : { label: 'Inactive', value: 'inactive' }
      )
      // setRemainingServiceQuota(state?.services?.s)
      // setRemainingServiceQuotaM(state?.services?.m)
      // setRemainingServiceQuotaL(state?.services?.l)

      if (state?.image) setimageUrl(`${ImageUrl}${state?.image}`)
      if (state?.small_pack_image) setSmallImageUrl(`${ImageUrl}${state?.small_pack_image}`)
      if (state?.medium_pack_image) setMediumImageUrl(`${ImageUrl}${state?.medium_pack_image}`)
      if (state?.large_pack_image) setLargeImageUrl(`${ImageUrl}${state?.large_pack_image}`)

    }
  }, [state])

  const submit = async (values) => {
    if (values?.services?.length == 0 || values?.item?.length == 0) {
      if (values?.services?.length == 0) toast.error('Services are required')
      // else toast.error('Items are required')
    } else {
      // if (values.services.length > 0 && values.item.length > 0) {
      let existServices = selectService?.map((item, key) => {
        return item?.value
      })
      const NewSelectedServices = []
      if (values?.services?.length != 0 && values?.services != undefined) {
        values?.services.forEach((item, key) => {
          NewSelectedServices?.push(item?.value)
        })
      }

      // let existOffers = selectOffers?.map((item, key) => {
      //   return item?.value
      // })
      // const NewSelectedOffers = []
      // if (values?.ApplyOffers?.length != 0 && values?.ApplyOffers != undefined) {
      //   values?.ApplyOffers.forEach((item, key) => {
      //     NewSelectedOffers?.push(item?.value)
      //   })
      // }
      // let existItem = selectItem.map((item, key) => {
      //   return item?.value
      // })
      // const NewITEM = []
      // if (values?.item?.length != 0 && values?.item != undefined) {
      //   values?.item?.forEach((item, key) => {
      //     NewITEM?.push(item?.value)
      //   })
      // }

      // console.log(remaningServiceQuota)
      var REMAINQUOTA = []
      remaningServiceQuota?.forEach((item, key) => {
        REMAINQUOTA?.push(item)
      })

      let formData = new FormData()
      formData.append('id', state?._id)
      formData.append('subcribe_type', subscriptionType)
      formData.append('S_package_size', smallPackageSize)
      formData.append('M_package_size', mediumPackageSize)
      formData.append('L_package_size', largePackageSize)
      formData.append('image', values?.image)
      formData.append('packageName_EN', values?.packageName_EN)
      formData.append('packageName_TH', values?.packageName_TH)
      // formData.append(
      //   'services',
      //   values?.item?.services == 0 || values?.services == undefined
      //     ? state?.services
      //     : NewSelectedServices,
      // )
      // formData.append('services', JSON.stringify{
      //   s: remaningServiceQuota.filter((item)),
      //   m: [],
      //   l: [],
      // })

      let smallArray = []
      let mediumArray = []
      let largeArray = []
      remaningServiceQuota.map((item) => {
        smallArray.push({ serviceId: item?.value, quota: item?.quota })
      })
      remaningServiceQuotaM.map((item) => {
        mediumArray.push({ serviceId: item?.value, quota: item?.quota })
      })
      remaningServiceQuotaL.map((item) => {
        largeArray.push({ serviceId: item?.value, quota: item?.quota })
      })

      formData.append('services', JSON.stringify({ s: smallArray, l: largeArray, m: mediumArray }))
      // console.log('HUHUHUHUHUHUHUHHUH', remaningServiceQuota)
      formData.append(
        'packageStatus',
        JSON.stringify({
          s: selectStatus?.value,
          m: selectStatus2?.value,
          l: selectStatus3?.value,
        }),
      )

      // formData.append('ApplyOffers', values?.item?.ApplyOffers == 0 || values?.ApplyOffers == undefined ? state?.ApplyOffers : NewSelectedServices)
      // formData.append('remainingServicesQuota', JSON.stringify(REMAINQUOTA))
      // formData.append('item', values?.item?.length == 0 || values?.item == undefined ? state?.item : NewITEM)
      formData.append('subcribeDuration', values?.subcribeDuration)
      formData.append('description_EN', values?.description_EN)
      formData.append('description_TH', values?.description_TH)
      formData.append('small_pack_description_EN', values?.small_pack_description_EN)
      formData.append('small_pack_description_TH', values?.small_pack_description_TH)
      formData.append('medium_pack_description_EN', values?.medium_pack_description_EN)
      formData.append('medium_pack_description_TH', values?.medium_pack_description_TH)
      formData.append('large_pack_description_EN', values?.large_pack_description_EN)
      formData.append('large_pack_description_TH', values?.large_pack_description_TH)
      formData.append('S_price', values?.S_price)
      formData.append('M_price', values?.M_price)
      formData.append('small_pack_title_EN', values?.small_pack_title_EN)
      formData.append('small_pack_title_TH', values?.small_pack_title_TH)
      formData.append('small_pack_image', values?.small_pack_image)
      formData.append('medium_pack_title_EN', values?.medium_pack_title_EN)
      formData.append('medium_pack_title_TH', values?.medium_pack_title_TH)
      formData.append('medium_pack_image', values?.medium_pack_image)
      formData.append('large_pack_title_EN', values?.large_pack_title_EN)
      formData.append('large_pack_title_TH', values?.large_pack_title_TH)
      formData.append('large_pack_image', values?.large_pack_image)
      formData.append('L_price', values?.L_price)
      formData.append('S_quantity', values?.S_quantity)
      formData.append('M_quantity', values?.M_quantity)
      formData.append('L_quantity', values?.L_quantity)
      formData.append('S_delivery', values?.S_delivery)
      formData.append('M_delivery', values?.M_delivery)
      formData.append('L_delivery', values?.L_delivery)
      put(`admin/subscription/update?_id=${state?._id}`, formData, 1).then((data) =>
        handleResponse(data),
      )
    }
    // } else {
    //   toast.error("Fill the required fields")
    // }
  }

  const handleResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      setTimeout(() => {
        navigate('/admin/subscription-plan/package')
      }, 800)
    } else {
      toast.error(data?.message)
    }
    formRef.current.resetForm()
  }
  const onClickReset = () => {
    setSelectService('')
    setSelectDuration('')
    // Reset Button Function
    // formRef.current.setFieldValue('vanNo', '')
  }

  const [status, setStatus] = useState('Active')

  const handleSelect = (status) => {
    setStatus(status)
  }
  const handleChangeValue = (e) => {
    // setSelectedValue(e)
    setSelectService(e)
    setRemainingServiceQuota(e)
  }
  const handleChangeValueM = (e) => {
    setSelectServiceM(e)
    setRemainingServiceQuotaM(e)
  }
  const handleChangeValueL = (e) => {
    setSelectServiceL(e)
    setRemainingServiceQuotaL(e)
  }
  const handleSelectChange = (e, valuess) => {
    const updatedQuotas = remaningServiceQuota.map((item) => {
      if (valuess.value == item.value) {
        item.quota = Number(e.target.value)
      }
      return item
    })
    setRemainingServiceQuota(updatedQuotas)
  }

  const handleSelectChangeM = (e, valuess) => {
    const updatedQuotas = remaningServiceQuotaM.map((item) => {
      if (valuess.value == item.value) {
        item.quota = Number(e.target.value)
      }
      return item
    })
    setRemainingServiceQuotaM(updatedQuotas)
  }

  const handleSelectChangeL = (e, valuess) => {
    const updatedQuotas = remaningServiceQuotaL.map((item) => {
      if (valuess.value == item.value) {
        item.quota = Number(e.target.value)
      }
      return item
    })
    setRemainingServiceQuotaL(updatedQuotas)
  }
  return (
    <>
      <CContainer>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <CRow>
                        <CCol md="11">
                          <h1>Edit Package Plan</h1>
                        </CCol>
                        <CCol md="1">
                          <Link
                            className={'btn btn-success mt-2'}
                            style={{ background: '#d3dc40', border: '#d3dc40' }}
                            to={{ pathname: `/admin/subscription-plan/package` }}
                          >
                            Back
                          </Link>
                        </CCol>
                      </CRow>
                      <Formik
                        initialValues={{
                          packageName_EN: '',
                          packageName_TH: '',
                          subcribeDuration: '',
                          S_price: '',
                          S_quantity: '',
                          S_delivery: '',
                          // S_unit: '',
                          M_price: '',
                          M_quantity: '',
                          M_delivery: '',
                          // M_unit: '',
                          L_price: '',
                          L_quantity: '',
                          L_delivery: '',
                          // L_unit: '',
                          // services: '',
                          description_EN: '',
                          description_TH: '',
                          small_pack_description_EN: '',
                          small_pack_description_TH: '',
                          medium_pack_description_EN: '',
                          medium_pack_description_TH: '',
                          large_pack_description_EN: '',
                          large_pack_description_TH: '',
                          // ApplyOffers: '',
                        }}
                        validationSchema={validateEditPackagePlan}
                        onSubmit={(values, { setSubmitting }) => {
                          submit(values)
                          setSubmitting(false)
                        }}
                        innerRef={formRef}
                      >
                        {(props) => (
                          <form onSubmit={props?.handleSubmit}>
                            <CRow>
                              <CCol>
                                <CNav variant="pills" role="tablist" className="mt-2">
                                  <CNavItem>
                                    <CNavLink
                                      href="javascript:void(0);"
                                      active={activeKey === 0}
                                      onClick={() => setActiveKey(0)}
                                    >
                                      Global
                                    </CNavLink>
                                  </CNavItem>
                                  <CNavItem>
                                    <CNavLink
                                      href="javascript:void(0);"
                                      active={activeKey === 1}
                                      onClick={() => setActiveKey(1)}
                                    >
                                      Small
                                    </CNavLink>
                                  </CNavItem>
                                  <CNavItem>
                                    <CNavLink
                                      href="javascript:void(0);"
                                      active={activeKey === 2}
                                      onClick={() => setActiveKey(2)}
                                    >
                                      Medium
                                    </CNavLink>
                                  </CNavItem>
                                  <CNavItem>
                                    <CNavLink
                                      href="javascript:void(0);"
                                      active={activeKey === 3}
                                      onClick={() => setActiveKey(3)}
                                    >
                                      Large
                                    </CNavLink>
                                  </CNavItem>
                                </CNav>
                                <CTabContent>
                                  <CTabPane
                                    className="p-3 preview"
                                    role="tabpanel"
                                    aria-labelledby="home-tab"
                                    visible={activeKey === 0}
                                  >
                                    <CRow>
                                      <CCol md={6}>
                                        <input
                                          type="hidden"
                                          name="subcribe_type"
                                          value={subscriptionType}
                                        />
                                        <input
                                          type="hidden"
                                          name="S_package_size"
                                          value={smallPackageSize}
                                        />
                                        <CFormLabel>Package Name (EN)</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <DefaultInput
                                          type={'text'}
                                          placeholder={'Package Name (EN)'}
                                          value={props?.values?.packageName_EN}
                                          onChange={props?.handleChange('packageName_EN')}
                                          onBlur={() =>
                                            props?.setFieldTouched('packageName_EN', true)
                                          }
                                          error={
                                            props?.touched?.packageName_EN &&
                                            props?.errors?.packageName_EN
                                          }
                                          onKeyUp={() => {
                                            if (
                                              state?.packageName_EN == props?.values?.packageName_EN
                                            )
                                              setIsDisable(false)
                                            else setIsDisable(true)
                                          }}
                                        />
                                        <FormLabel> Description (EN)</FormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <Ckeditor
                                          setDescription_EN={setDescription_EN}
                                          description_EN={description_EN}
                                          value={props?.values?.description_EN}
                                          onChange={(val) => {
                                            setDescription_EN(val)
                                            if (state?.description_EN == val) setIsDisable(false)
                                            else setIsDisable(true)
                                            props?.setFieldValue('description_EN', val)
                                          }}
                                          onBlur={() =>
                                            props?.setFieldTouched('description_EN', true)
                                          }
                                          error={
                                            props?.touched?.description_EN &&
                                            props?.errors?.description_EN
                                          }
                                        />
                                        {/* Service */}
                                        {/* <CFormLabel>Services</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <Select
                                          isMulti={true}
                                          defaultValue={selectService}
                                          onChange={(val) => {
                                            setSelectService(val)
                                            if (val == selectService) setIsDisable(false)
                                            else setIsDisable(true)
                                            if (Object.keys(val).length == 0) {
                                              setSelectItem([])
                                              setItemOptions([])
                                              setRemainingServiceQuota([])
                                            }
                                            // setSelectItem([])

                                            allServices?.find((element) => {
                                              val?.map((item) => {
                                                if (element?._id == item?.value) {
                                                  remaningQuota?.push({
                                                    quota: element?.serviceQuota,
                                                    value: element?._id,
                                                    serviceName: element?.serviceName_EN,
                                                  })
                                                  setRemainingServiceQuota(remaningQuota)
                                                }
                                              })
                                            })
                                            props?.setFieldValue('services', val)
                                          }}
                                          options={serviceData}
                                          value={selectService}
                                          placeholder={'Select services'}
                                          name="Services"
                                        />

                                        {selectService.length == 0 ? (
                                          <span className="text-danger">Service is required</span>
                                        ) : (
                                          ''
                                        )} */}
                                        {/* <br /> */}
                                        {/* <CFormLabel>Item</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <Select
                                          isMulti={true}
                                          defaultValue={selectItem}
                                          onChange={(val) => {
                                            if (val == selectItem) setIsDisable(false)
                                            else setIsDisable(true)
                                            setSelectItem(val)
                                            props?.setFieldValue('item', val)
                                          }}
                                          options={itemOptions}
                                          value={selectItem}
                                          placeholder={'Select items'}
                                          name="Items"
                                        />
                                        {selectItem.length == 0 ? (
                                          <span className="text-danger">Item is required</span>
                                        ) : (
                                          ''
                                        )} */}
                                        {/* <br /> */}
                                        {/* {selectItem == undefined ? (<span className="text-danger">{props?.errors?.item}</span>) : ('')} */}
                                        <CFormLabel>Image</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <DefaultInput
                                          type={'file'}
                                          placeholder={'Image'}
                                          accept="image/*"
                                          refr={fileRef}
                                          onChange={(e) => {
                                            const imageFile = e?.target?.files[0]
                                            if (imageUrl == imageFile.name) setIsDisable(false)
                                            else setIsDisable(true)
                                            if (!imageFile?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
                                              setInvalidImage('Please select valid image')
                                            } else {
                                              setInvalidImage('')
                                            }
                                            //for image preview
                                            const reader = new FileReader()
                                            reader?.addEventListener('load', () =>
                                              setPackageImagePreview(reader?.result),
                                            )
                                            reader?.readAsDataURL(e?.target?.files[0])
                                            //for image preview
                                            props?.setFieldValue('image', e?.target?.files[0])
                                          }}
                                          onBlur={() => props?.setFieldTouched('image', true)}
                                          error={
                                            (props?.touched?.image && props?.errors?.image) ||
                                            invalidImage
                                          }
                                        />
                                        {/* for image preview */}
                                        {packageImagePreview?.length > 0 ? (
                                          <>
                                            <CCol>
                                              <CImage
                                                rounded
                                                thumbnail
                                                className="mt-2"
                                                src={`${packageImagePreview}`}
                                                style={{
                                                  maxHeight: '80px',
                                                  maxWidth: '80px',
                                                  alignItems: 'center',
                                                }}
                                              />
                                            </CCol>
                                          </>
                                        ) : (
                                          ''
                                        )}
                                        {/* for image preview */}
                                        {imageUrl && (
                                          <CImage
                                            rounded
                                            thumbnail
                                            className="mt-2"
                                            src={`${imageUrl}`}
                                            style={{
                                              maxHeight: '80px',
                                              maxWidth: '80px',
                                              alignItems: 'center',
                                            }}
                                          />
                                        )}
                                      </CCol>
                                      <CCol md="6">
                                        <CFormLabel>Package Name (TH)</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <DefaultInput
                                          type={'text'}
                                          placeholder={'Package Name (TH)'}
                                          value={props?.values?.packageName_TH}
                                          onChange={props?.handleChange('packageName_TH')}
                                          onBlur={() =>
                                            props?.setFieldTouched('packageName_TH', true)
                                          }
                                          error={
                                            props?.touched?.packageName_TH &&
                                            props?.errors?.packageName_TH
                                          }
                                          onKeyUp={() => {
                                            if (
                                              state?.packageName_TH == props?.values?.packageName_TH
                                            )
                                              setIsDisable(false)
                                            else setIsDisable(true)
                                          }}
                                        />

                                        <FormLabel> Description (TH)</FormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <Ckeditor
                                          setDescription_TH={setDescription_TH}
                                          description_TH={description_TH}
                                          value={props?.values?.description_TH}
                                          onChange={(val) => {
                                            setDescription_TH(val)
                                            if (state?.description_TH == val) setIsDisable(false)
                                            else setIsDisable(true)
                                            props?.setFieldValue('description_TH', val)
                                          }}
                                          onBlur={() =>
                                            props?.setFieldTouched('description_TH', true)
                                          }
                                          error={
                                            props?.touched?.description_TH &&
                                            props?.errors?.description_TH
                                          }
                                        />

                                        <CFormLabel>Plan Duration</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <Select
                                          // isMulti={true}
                                          defaultValue={selectDuration}
                                          onChange={(val) => {
                                            setSelectDuration(val)
                                            props?.setFieldValue('subcribeDuration', val.value)
                                          }}
                                          options={durationData}
                                          placeholder={'Select plan duration'}
                                          value={selectDuration}
                                        />
                                        {selectDuration == undefined ? (
                                          <span className="text-danger">
                                            {props?.errors?.subcribeDuration}
                                          </span>
                                        ) : (
                                          ''
                                        )}
                                        <br />

                                        {/* <CFormLabel>Apply Offers</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <Select
                                          isMulti={true}
                                          defaultValue={selectOffers}
                                          onChange={(val) => {
                                            setSelectOffers(val)
                                            if (val == selectOffers) setIsDisable(false)
                                            else setIsDisable(true)
                                            props?.setFieldValue('ApplyOffers', val)
                                          }}
                                          options={offerData}
                                          value={selectOffers}
                                          placeholder={'Select offers'}
                                          name="Offers"
                                        />
                                        {selectOffers == undefined ? (<span className="text-danger">{props?.errors?.ApplyOffers}</span>) : ('')} */}

                                        {/* remaining service Quota */}
                                        {/* {remaningServiceQuota?.length > 0
                                          ? Object.values(remaningServiceQuota).map(
                                              (item, index) => {
                                                return (
                                                  <CCol style={{ marginBottom: '5px' }}>
                                                    <CFormLabel>
                                                      {item?.serviceName} (Remain Quota)
                                                    </CFormLabel>
                                                    <span
                                                      className="text-danger"
                                                      style={{ marginLeft: '5px' }}
                                                    >
                                                      *
                                                    </span>
                                                    <DefaultInput
                                                      type="number"
                                                      disabled={true}
                                                      placeholder={'Remain Service Quota'}
                                                      value={item?.quota}
                                                      onChange={props?.handleChange(
                                                        'servicesQuota',
                                                      )}
                                                    />
                                                  </CCol>
                                                )
                                              },
                                            )
                                          : ''} */}
                                      </CCol>
                                    </CRow>
                                  </CTabPane>
                                  <CTabPane
                                    className="p-3 preview"
                                    role="tabpanel"
                                    aria-labelledby="home-tab"
                                    visible={activeKey === 1}
                                  >
                                    <CRow>
                                      <CCol md={6}>
                                        <input
                                          type="hidden"
                                          name="subcribe_type"
                                          value={subscriptionType}
                                        />
                                        <input
                                          type="hidden"
                                          name="S_package_size"
                                          value={smallPackageSize}
                                        />

                                        <CFormLabel>Title (EN)</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <DefaultInput
                                          type={'text'}
                                          placeholder={'Title (EN)'}
                                          value={props?.values?.small_pack_title_EN}
                                          onChange={props?.handleChange('small_pack_title_EN')}
                                          onBlur={() =>
                                            props?.setFieldTouched('small_pack_title_EN', true)
                                          }
                                          error={
                                            props?.touched?.small_pack_title_EN &&
                                            props?.errors?.small_pack_title_EN
                                          }
                                        />

                                        <FormLabel>Small Pack Description (EN)</FormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <Ckeditor
                                          setSmall_pack_description_EN={
                                            setSmall_pack_description_EN
                                          }
                                          small_pack_description_EN={small_pack_description_EN}
                                          value={props?.values?.small_pack_description_EN}
                                          onChange={(val) => {
                                            setSmall_pack_description_EN(val)
                                            if (state?.small_pack_description_EN == val)
                                              setIsDisable(false)
                                            else setIsDisable(true)
                                            props?.setFieldValue('small_pack_description_EN', val)
                                          }}
                                          onBlur={() =>
                                            props?.setFieldTouched(
                                              'small_pack_description_EN',
                                              true,
                                            )
                                          }
                                          error={
                                            props?.touched?.small_pack_description_EN &&
                                            props?.errors?.small_pack_description_EN
                                          }
                                        />
                                        <CFormLabel>Package Price</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <DefaultInput
                                          type={'text'}
                                          placeholder={'Small Package Price'}
                                          value={props?.values?.S_price}
                                          onChange={props?.handleChange('S_price')}
                                          onBlur={() => props?.setFieldTouched('S_price', true)}
                                          error={props?.touched?.S_price && props?.errors?.S_price}
                                          onKeyUp={() => {
                                            if (state?.S_price == props?.values?.S_price)
                                              setIsDisable(false)
                                            else setIsDisable(true)
                                          }}
                                        />

                                        <CFormLabel>Quantity</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <DefaultInput
                                          type={'text'}
                                          placeholder={'Small Package Quantity'}
                                          value={props?.values?.S_quantity}
                                          onChange={props?.handleChange('S_quantity')}
                                          onBlur={() => props?.setFieldTouched('S_quantity', true)}
                                          error={
                                            props?.touched?.S_quantity && props?.errors?.S_quantity
                                          }
                                          onKeyUp={() => {
                                            if (state?.S_quantity == props?.values?.S_quantity)
                                              setIsDisable(false)
                                            else setIsDisable(true)
                                          }}
                                        />
                                        <CFormLabel>Image</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <DefaultInput
                                          type={'file'}
                                          placeholder={'Image'}
                                          accept="image/*"
                                          refr={fileRefSmall}
                                          onChange={(e) => {
                                            const imageFile = e?.target?.files[0]
                                            if (smallImageUrl == imageFile.name) setIsDisable(false)
                                            else setIsDisable(true)
                                            if (!imageFile?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
                                              setSmallInvalidImage('Please select valid image')
                                            } else {
                                              setSmallInvalidImage('')
                                            }
                                            //for image preview
                                            const reader = new FileReader()
                                            reader?.addEventListener('load', () =>
                                              setPackageImagePreviewSmall(reader?.result),
                                            )
                                            reader?.readAsDataURL(e?.target?.files[0])
                                            //for image preview
                                            props?.setFieldValue('small_pack_image', e?.target?.files[0])
                                          }}
                                          onBlur={() => props?.setFieldTouched('small_pack_image', true)}
                                          error={
                                            (props?.touched?.small_pack_image && props?.errors?.small_pack_image) ||
                                            smallInvalidImage
                                          }
                                        />
                                        {/* for image preview */}
                                        {packageImagePreviewSmall?.length > 0 ? (
                                          <>
                                            <CCol>
                                              <CImage
                                                rounded
                                                thumbnail
                                                className="mt-2"
                                                src={`${packageImagePreviewSmall}`}
                                                style={{
                                                  maxHeight: '80px',
                                                  maxWidth: '80px',
                                                  alignItems: 'center',
                                                }}
                                              />
                                            </CCol>
                                          </>
                                        ) : (
                                          ''
                                        )}
                                        {/* for image preview */}
                                        {smallImageUrl && (
                                          <CImage
                                            rounded
                                            thumbnail
                                            className="mt-2"
                                            src={`${smallImageUrl}`}
                                            style={{
                                              maxHeight: '80px',
                                              maxWidth: '80px',
                                              alignItems: 'center',
                                            }}
                                          />
                                        )}

                                        <br />
                                      </CCol>
                                      <CCol md="6">

                                        <CFormLabel>Title (TH)</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <DefaultInput
                                          type={'text'}
                                          placeholder={'Title (TH)'}
                                          value={props?.values?.small_pack_title_TH}
                                          onChange={props?.handleChange('small_pack_title_TH')}
                                          onBlur={() =>
                                            props?.setFieldTouched('small_pack_title_TH', true)
                                          }
                                          error={
                                            props?.touched?.small_pack_title_TH &&
                                            props?.errors?.small_pack_title_TH
                                          }
                                        />

                                        <FormLabel>Small Pack Description (TH)</FormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <Ckeditor
                                          setSmall_pack_description_TH={
                                            setSmall_pack_description_TH
                                          }
                                          small_pack_description_TH={small_pack_description_TH}
                                          value={props?.values?.small_pack_description_TH}
                                          onChange={(val) => {
                                            setSmall_pack_description_TH(val)
                                            if (state?.small_pack_description_TH == val)
                                              setIsDisable(false)
                                            else setIsDisable(true)
                                            props?.setFieldValue('small_pack_description_TH', val)
                                          }}
                                          onBlur={() =>
                                            props?.setFieldTouched(
                                              'small_pack_description_TH',
                                              true,
                                            )
                                          }
                                          error={
                                            props?.touched?.small_pack_description_TH &&
                                            props?.errors?.small_pack_description_TH
                                          }
                                        />
                                        <CFormLabel>Free Pickup And Delivery</CFormLabel>
                                        {/* <span className='text-danger' style={{ marginLeft: '5px' }}>*</span> */}
                                        <DefaultInput
                                          type={'text'}
                                          placeholder={'Small Package Delivery'}
                                          value={props?.values?.S_delivery}
                                          onChange={props?.handleChange('S_delivery')}
                                          onBlur={() => props?.setFieldTouched('S_delivery', true)}
                                          error={
                                            props?.touched?.S_delivery && props?.errors?.S_delivery
                                          }
                                          onKeyUp={() => {
                                            if (state?.S_delivery == props?.values?.S_delivery)
                                              setIsDisable(false)
                                            else setIsDisable(true)
                                          }}
                                        />
                                        <CFormLabel>Status</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <Select
                                          // isMulti={true}
                                          onChange={(val) => {
                                            setSelectStatus(val)
                                            props?.setFieldValue('status', val)
                                          }}
                                          options={[
                                            { label: 'Active', value: 'active' },
                                            { label: 'Inactive', value: 'inactive' },
                                          ]}
                                          value={selectStatus}
                                          placeholder={'Select status'}
                                          name="Status"
                                        />
                                        <CFormLabel>Services (small)</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <Select
                                          isMulti={true}
                                          defaultValue={selectService}
                                          onChange={(val) => {
                                            handleChangeValue(val)
                                            // setSelectService(val)
                                            if (val == selectService) setIsDisable(false)
                                            else setIsDisable(true)
                                            if (Object.keys(val)?.length == 0) {
                                              setSelectItem([])
                                              setItemOptions([])
                                              setRemainingServiceQuota([])
                                            }
                                            // setSelectItem([])

                                            allServices?.find((element) => {
                                              val?.map((item, index) => {
                                                // if (element?._id == item?.value) {
                                                //   remaningQuota?.push({
                                                //     quota: element?.serviceQuota,
                                                //     value: element?._id,
                                                //     serviceName: element?.serviceName_EN,
                                                //   })
                                                //   // setRemainingServiceQuota(remaningQuota)
                                                // }
                                              })
                                            })
                                            props?.setFieldValue('services', val)
                                          }}
                                          options={serviceData}
                                          value={selectService}
                                          placeholder={'Select services'}
                                          name="Services"
                                        />

                                        {selectService.length == 0 ? (
                                          <span className="text-danger">Service is required</span>
                                        ) : (
                                          ''
                                        )}
                                        {remaningServiceQuota?.length > 0
                                          ? Object.values(remaningServiceQuota).map(
                                            (item, index) => {
                                              return (
                                                <CCol style={{ marginBottom: '5px' }}>
                                                  <CFormLabel>
                                                    {item.serviceName || item?.label} (Remain
                                                    Quota)
                                                    {/* {item?.serviceName} (Remain Quota) */}
                                                    {/* {item?.label} (Remain Quota) */}
                                                  </CFormLabel>
                                                  <span
                                                    className="text-danger"
                                                    style={{ marginLeft: '5px' }}
                                                  >
                                                    *
                                                  </span>
                                                  <DefaultInput
                                                    type="number"
                                                    // disabled={true}
                                                    placeholder={'Remain Service Quota'}
                                                    value={remaningServiceQuota[index].quota}
                                                    // onChange={(e) => {
                                                    //   const itemIndex =
                                                    //     remaningServiceQuota.findIndex(
                                                    //       (data) => data.quota === item.quota,
                                                    //     )
                                                    //   if (itemIndex === -1) {
                                                    //     console.error('Item not found')
                                                    //     return
                                                    //   }
                                                    //   // Create a new array with the updated item
                                                    //   const updatedItems = [
                                                    //     ...remaningServiceQuota.slice(
                                                    //       0,
                                                    //       itemIndex,
                                                    //     ), // all items before the updated item
                                                    //     {
                                                    //       ...remaningServiceQuota[itemIndex],
                                                    //       quota: e.target.value,
                                                    //     }, // updated item
                                                    //     ...remaningServiceQuota.slice(
                                                    //       itemIndex + 1,
                                                    //     ), // all items after the updated item
                                                    //   ]
                                                    //   // Update the state with the new array
                                                    //   setRemainingServiceQuota(updatedItems)
                                                    // }}
                                                    onChange={(e) => handleSelectChange(e, item)}

                                                  // onChange={(e)=>setRemainingServiceQuota(remaningServiceQuota[index].quota=e.target.value)}
                                                  // onChange={props?.handleChange(
                                                  //   'servicesQuota',
                                                  // )}
                                                  />
                                                </CCol>
                                              )
                                            },
                                          )
                                          : ''}
                                        {/* <CFormLabel>Apply Offers</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <Select
                                          isMulti={true}
                                          defaultValue={selectOffers}
                                          onChange={(val) => {
                                            setSelectOffers(val)
                                            if (val == selectOffers) setIsDisable(false)
                                            else setIsDisable(true)
                                            props?.setFieldValue('ApplyOffers', val)
                                          }}
                                          options={offerData}
                                          value={selectOffers}
                                          placeholder={'Select offers'}
                                          name="Offers"
                                        />
                                        {selectOffers == undefined ? (<span className="text-danger">{props?.errors?.ApplyOffers}</span>) : ('')} */}
                                      </CCol>
                                    </CRow>
                                  </CTabPane>

                                  <CTabPane
                                    className="p-3 preview"
                                    role="tabpanel"
                                    aria-labelledby="profile-tab"
                                    visible={activeKey === 2}
                                  >
                                    <CRow>
                                      <CCol md={6}>
                                        <input
                                          type="hidden"
                                          name="M_package_size"
                                          value={mediumPackageSize}
                                        />

                                        <CFormLabel>Title (EN)</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <DefaultInput
                                          type={'text'}
                                          placeholder={'Title (EN)'}
                                          value={props?.values?.medium_pack_title_EN}
                                          onChange={props?.handleChange('medium_pack_title_EN')}
                                          onBlur={() =>
                                            props?.setFieldTouched('medium_pack_title_EN', true)
                                          }
                                          error={
                                            props?.touched?.medium_pack_title_EN &&
                                            props?.errors?.medium_pack_title_EN
                                          }
                                        />

                                        <FormLabel>Medium Pack Description (EN)</FormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <Ckeditor
                                          setMedium_pack_description_EN={
                                            setMedium_pack_description_EN
                                          }
                                          medium_pack_description_EN={medium_pack_description_EN}
                                          value={props?.values?.medium_pack_description_EN}
                                          onChange={(val) => {
                                            setMedium_pack_description_EN(val)
                                            if (state?.description_EN == val) setIsDisable(false)
                                            else setIsDisable(true)
                                            props?.setFieldValue('medium_pack_description_EN', val)
                                          }}
                                          onBlur={() =>
                                            props?.setFieldTouched(
                                              'medium_pack_description_EN',
                                              true,
                                            )
                                          }
                                          error={
                                            props?.touched?.medium_pack_description_EN &&
                                            props?.errors?.medium_pack_description_EN
                                          }
                                        />
                                        {/* <CFormLabel>Approximate Price</CFormLabel> */}
                                        <CFormLabel>Package Price</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <DefaultInput
                                          type={'text'}
                                          placeholder={'Medium Package Price'}
                                          value={props?.values?.M_price}
                                          onChange={props?.handleChange('M_price')}
                                          onBlur={() => props?.setFieldTouched('M_price', true)}
                                          error={props?.touched?.M_price && props?.errors?.M_price}
                                          onKeyUp={() => {
                                            if (state?.M_price == props?.values?.M_price)
                                              setIsDisable(false)
                                            else setIsDisable(true)
                                          }}
                                        />

                                        <CFormLabel>Quantity</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <DefaultInput
                                          type={'text'}
                                          placeholder={'Medium Package Quantity'}
                                          value={props?.values?.M_quantity}
                                          onChange={props?.handleChange('M_quantity')}
                                          onBlur={() => props?.setFieldTouched('M_quantity', true)}
                                          error={
                                            props?.touched?.M_quantity && props?.errors?.M_quantity
                                          }
                                          onKeyUp={() => {
                                            if (state?.M_quantity == props?.values?.M_quantity)
                                              setIsDisable(false)
                                            else setIsDisable(true)
                                          }}
                                        />
                                        <CFormLabel>Image</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <DefaultInput
                                          type={'file'}
                                          placeholder={'Image'}
                                          accept="image/*"
                                          refr={fileRefMedium}
                                          onChange={(e) => {
                                            const imageFile = e?.target?.files[0]
                                            if (mediumImageUrl == imageFile.name) setIsDisable(false)
                                            else setIsDisable(true)
                                            if (!imageFile?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
                                              setMediumInvalidImage('Please select valid image')
                                            } else {
                                              setMediumInvalidImage('')
                                            }
                                            //for image preview
                                            const reader = new FileReader()
                                            reader?.addEventListener('load', () =>
                                              setPackageImagePreviewMedium(reader?.result),
                                            )
                                            reader?.readAsDataURL(e?.target?.files[0])
                                            //for image preview
                                            props?.setFieldValue('medium_pack_image', e?.target?.files[0])
                                          }}
                                          onBlur={() => props?.setFieldTouched('medium_pack_image', true)}
                                          error={
                                            (props?.touched?.medium_pack_image && props?.errors?.medium_pack_image) ||
                                            mediumInvalidImage
                                          }
                                        />
                                        {/* for image preview */}
                                        {packageImagePreviewMedium?.length > 0 ? (
                                          <>
                                            <CCol>
                                              <CImage
                                                rounded
                                                thumbnail
                                                className="mt-2"
                                                src={`${packageImagePreviewMedium}`}
                                                style={{
                                                  maxHeight: '80px',
                                                  maxWidth: '80px',
                                                  alignItems: 'center',
                                                }}
                                              />
                                            </CCol>
                                          </>
                                        ) : (
                                          ''
                                        )}
                                        {/* for image preview */}
                                        {mediumImageUrl && (
                                          <CImage
                                            rounded
                                            thumbnail
                                            className="mt-2"
                                            src={`${mediumImageUrl}`}
                                            style={{
                                              maxHeight: '80px',
                                              maxWidth: '80px',
                                              alignItems: 'center',
                                            }}
                                          />
                                        )}

                                      </CCol>
                                      <CCol md="6">
                                        <CFormLabel>Title (TH)</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <DefaultInput
                                          type={'text'}
                                          placeholder={'Title (TH)'}
                                          value={props?.values?.medium_pack_title_TH}
                                          onChange={props?.handleChange('medium_pack_title_TH')}
                                          onBlur={() =>
                                            props?.setFieldTouched('medium_pack_title_TH', true)
                                          }
                                          error={
                                            props?.touched?.medium_pack_title_TH &&
                                            props?.errors?.medium_pack_title_TH
                                          }
                                        />

                                        <FormLabel>Medium Pack Description (TH)</FormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <Ckeditor
                                          setMedium_pack_description_TH={
                                            setMedium_pack_description_TH
                                          }
                                          medium_pack_description_TH={medium_pack_description_TH}
                                          value={props?.values?.medium_pack_description_TH}
                                          onChange={(val) => {
                                            setMedium_pack_description_TH(val)
                                            if (state?.medium_pack_description_TH == val)
                                              setIsDisable(false)
                                            else setIsDisable(true)
                                            props?.setFieldValue('medium_pack_description_TH', val)
                                          }}
                                          onBlur={() =>
                                            props?.setFieldTouched(
                                              'medium_pack_description_TH',
                                              true,
                                            )
                                          }
                                          error={
                                            props?.touched?.medium_pack_description_TH &&
                                            props?.errors?.medium_pack_description_TH
                                          }
                                        />
                                        <CFormLabel>Free Pickup And Delivery</CFormLabel>
                                        {/* <span className='text-danger' style={{ marginLeft: '5px' }}>*</span> */}
                                        <DefaultInput
                                          type={'text'}
                                          placeholder={'Medium Package Delivery'}
                                          value={props?.values?.M_delivery}
                                          onChange={props?.handleChange('M_delivery')}
                                          onBlur={() => props?.setFieldTouched('M_delivery', true)}
                                          error={
                                            props?.touched?.M_delivery && props?.errors?.M_delivery
                                          }
                                          onKeyUp={() => {
                                            if (state?.M_delivery == props?.values?.M_delivery)
                                              setIsDisable(false)
                                            else setIsDisable(true)
                                          }}
                                        />

                                        <CFormLabel>Status</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <Select
                                          // isMulti={true}
                                          defaultValue={selectStatus2}
                                          onChange={(val) => {
                                            setSelectStatus2(val)
                                            props?.setFieldValue('status', val)
                                          }}
                                          options={[
                                            { label: 'Active', value: 'active' },
                                            { label: 'Inactive', value: 'inactive' },
                                          ]}
                                          value={selectStatus2}
                                          placeholder={'Select status'}
                                          name="Status"
                                        />

                                        <CFormLabel>Services (medium)</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <Select
                                          isMulti={true}
                                          defaultValue={selectServiceM}
                                          onChange={(val) => {
                                            handleChangeValueM(val)
                                            // // setSelectServiceM(val)
                                            // if (val == selectServiceM) setIsDisable(false)
                                            // else setIsDisable(true)
                                            // if (Object.keys(val).length == 0) {
                                            //   setSelectItem([])
                                            //   setItemOptions([])
                                            //   setRemainingServiceQuotaM([])
                                            // }
                                            // // setSelectItem([])

                                            // props?.setFieldValue('services', val)
                                          }}
                                          options={serviceDataM}
                                          value={selectServiceM}
                                          placeholder={'Select services'}
                                          name="Services"
                                        />

                                        {selectServiceM.length == 0 ? (
                                          <span className="text-danger">Service is required</span>
                                        ) : (
                                          ''
                                        )}
                                        {remaningServiceQuotaM?.length > 0
                                          ? Object.values(remaningServiceQuotaM).map(
                                            (item, index) => {
                                              return (
                                                <CCol style={{ marginBottom: '5px' }} key={index}>
                                                  <CFormLabel>
                                                    {item.serviceName || item?.label} (Remain
                                                    Quota)
                                                    {/* {item?.serviceName} (Remain Quota) */}
                                                  </CFormLabel>
                                                  <span
                                                    className="text-danger"
                                                    style={{ marginLeft: '5px' }}
                                                  >
                                                    *
                                                  </span>
                                                  <DefaultInput
                                                    type="number"
                                                    // disabled={true}
                                                    placeholder={'Remain Service Quota'}
                                                    value={remaningServiceQuotaM[index].quota}
                                                    // onChange={(e) => {
                                                    //   const itemIndex =
                                                    //     remaningServiceQuotaM.findIndex(
                                                    //       (data) => data.quota === item.quota,
                                                    //     )
                                                    //   if (itemIndex === -1) {
                                                    //     console.error('Item not found')
                                                    //     return
                                                    //   }
                                                    //   // Create a new array with the updated item
                                                    //   const updatedItems = [
                                                    //     ...remaningServiceQuotaM.slice(
                                                    //       0,
                                                    //       itemIndex,
                                                    //     ), // all items before the updated item
                                                    //     {
                                                    //       ...remaningServiceQuotaM[itemIndex],
                                                    //       quota: e.target.value,
                                                    //     }, // updated item
                                                    //     ...remaningServiceQuotaM.slice(
                                                    //       itemIndex + 1,
                                                    //     ), // all items after the updated item
                                                    //   ]
                                                    //   // Update the state with the new array
                                                    //   setRemainingServiceQuotaM(updatedItems)
                                                    // }}
                                                    onChange={(e) => handleSelectChangeM(e, item)}
                                                  // onChange={props?.handleChange(
                                                  //   'servicesQuota',
                                                  // )}
                                                  />
                                                </CCol>
                                              )
                                            },
                                          )
                                          : ''}
                                      </CCol>
                                    </CRow>
                                  </CTabPane>
                                  <CTabPane
                                    className="p-3 preview"
                                    role="tabpanel"
                                    aria-labelledby="contact-tab"
                                    visible={activeKey === 3}
                                  >
                                    <CRow>
                                      <CCol md={6}>
                                        <input
                                          type="hidden"
                                          name="L_package_size"
                                          value={largePackageSize}
                                        />

                                        <CFormLabel>Title (EN)</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <DefaultInput
                                          type={'text'}
                                          placeholder={'Title (EN)'}
                                          value={props?.values?.large_pack_title_EN}
                                          onChange={props?.handleChange('large_pack_title_EN')}
                                          onBlur={() =>
                                            props?.setFieldTouched('large_pack_title_EN', true)
                                          }
                                          error={
                                            props?.touched?.large_pack_title_EN &&
                                            props?.errors?.large_pack_title_EN
                                          }
                                        />

                                        <FormLabel>Large Pack Description (EN)</FormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <Ckeditor
                                          setLarge_pack_description_EN={
                                            setLarge_pack_description_EN
                                          }
                                          large_pack_description_EN={large_pack_description_EN}
                                          value={props?.values?.large_pack_description_EN}
                                          onChange={(val) => {
                                            setLarge_pack_description_EN(val)
                                            if (state?.large_pack_description_EN == val)
                                              setIsDisable(false)
                                            else setIsDisable(true)
                                            props?.setFieldValue('large_pack_description_EN', val)
                                          }}
                                          onBlur={() =>
                                            props?.setFieldTouched(
                                              'large_pack_description_EN',
                                              true,
                                            )
                                          }
                                          error={
                                            props?.touched?.large_pack_description_EN &&
                                            props?.errors?.large_pack_description_EN
                                          }
                                        />
                                        {/* <CFormLabel>Approximate Price</CFormLabel> */}
                                        <CFormLabel>Package Price</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <DefaultInput
                                          type={'text'}
                                          placeholder={'Large Package Price'}
                                          value={props?.values?.L_price}
                                          onChange={props?.handleChange('L_price')}
                                          onBlur={() => props?.setFieldTouched('L_price', true)}
                                          error={props?.touched?.L_price && props?.errors?.L_price}
                                          onKeyUp={() => {
                                            if (state?.L_price == props?.values?.L_price)
                                              setIsDisable(false)
                                            else setIsDisable(true)
                                          }}
                                        />
                                        <CFormLabel>Quantity</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <DefaultInput
                                          type={'text'}
                                          placeholder={'Large Package Quantity'}
                                          value={props?.values?.L_quantity}
                                          onChange={props?.handleChange('L_quantity')}
                                          onBlur={() => props?.setFieldTouched('L_quantity', true)}
                                          error={
                                            props?.touched?.L_quantity && props?.errors?.L_quantity
                                          }
                                          onKeyUp={() => {
                                            if (state?.L_quantity == props?.values?.L_quantity)
                                              setIsDisable(false)
                                            else setIsDisable(true)
                                          }}
                                        />
                                        <CFormLabel>Image</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <DefaultInput
                                          type={'file'}
                                          placeholder={'Image'}
                                          accept="image/*"
                                          refr={fileRefLarge}
                                          onChange={(e) => {
                                            const imageFile = e?.target?.files[0]
                                            if (mediumImageUrl == imageFile.name) setIsDisable(false)
                                            else setIsDisable(true)
                                            if (!imageFile?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
                                              setLargeInvalidImage('Please select valid image')
                                            } else {
                                              setLargeInvalidImage('')
                                            }
                                            //for image preview
                                            const reader = new FileReader()
                                            reader?.addEventListener('load', () =>
                                              setPackageImagePreviewLarge(reader?.result),
                                            )
                                            reader?.readAsDataURL(e?.target?.files[0])
                                            //for image preview
                                            props?.setFieldValue('large_pack_image', e?.target?.files[0])
                                          }}
                                          onBlur={() => props?.setFieldTouched('large_pack_image', true)}
                                          error={
                                            (props?.touched?.large_pack_image && props?.errors?.large_pack_image) ||
                                            largeInvalidImage
                                          }
                                        />
                                        {/* for image preview */}
                                        {packageImagePreviewLarge?.length > 0 ? (
                                          <>
                                            <CCol>
                                              <CImage
                                                rounded
                                                thumbnail
                                                className="mt-2"
                                                src={`${packageImagePreviewLarge}`}
                                                style={{
                                                  maxHeight: '80px',
                                                  maxWidth: '80px',
                                                  alignItems: 'center',
                                                }}
                                              />
                                            </CCol>
                                          </>
                                        ) : (
                                          ''
                                        )}
                                        {/* for image preview */}
                                        {largeImageUrl && (
                                          <CImage
                                            rounded
                                            thumbnail
                                            className="mt-2"
                                            src={`${largeImageUrl}`}
                                            style={{
                                              maxHeight: '80px',
                                              maxWidth: '80px',
                                              alignItems: 'center',
                                            }}
                                          />
                                        )}

                                      </CCol>
                                      <CCol md="6">

                                        <CFormLabel>Title (TH)</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <DefaultInput
                                          type={'text'}
                                          placeholder={'Title (TH)'}
                                          value={props?.values?.large_pack_title_TH}
                                          onChange={props?.handleChange('large_pack_title_TH')}
                                          onBlur={() =>
                                            props?.setFieldTouched('large_pack_title_TH', true)
                                          }
                                          error={
                                            props?.touched?.large_pack_title_TH &&
                                            props?.errors?.large_pack_title_TH
                                          }
                                        />

                                        <FormLabel>Large Pack Description (TH)</FormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <Ckeditor
                                          setLarge_pack_description_TH={
                                            setLarge_pack_description_TH
                                          }
                                          large_pack_description_TH={large_pack_description_TH}
                                          value={props?.values?.large_pack_description_TH}
                                          onChange={(val) => {
                                            setLarge_pack_description_TH(val)
                                            if (state?.large_pack_description_TH == val)
                                              setIsDisable(false)
                                            else setIsDisable(true)
                                            props?.setFieldValue('large_pack_description_TH', val)
                                          }}
                                          onBlur={() =>
                                            props?.setFieldTouched(
                                              'large_pack_description_TH',
                                              true,
                                            )
                                          }
                                          error={
                                            props?.touched?.large_pack_description_TH &&
                                            props?.errors?.large_pack_description_TH
                                          }
                                        />
                                        <CFormLabel>Free Pickup And Delivery</CFormLabel>
                                        {/* <span className='text-danger' style={{ marginLeft: '5px' }}>*</span> */}
                                        <DefaultInput
                                          type={'text'}
                                          placeholder={'Large Package Delivery'}
                                          value={props?.values?.L_delivery}
                                          onChange={props?.handleChange('L_delivery')}
                                          onBlur={() => props?.setFieldTouched('L_delivery', true)}
                                          error={
                                            props?.touched?.L_delivery && props?.errors?.L_delivery
                                          }
                                          onKeyUp={() => {
                                            if (state?.L_delivery == props?.values?.L_delivery)
                                              setIsDisable(false)
                                            else setIsDisable(true)
                                          }}
                                        />

                                        <CFormLabel>Status</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <Select
                                          // isMulti={true}
                                          defaultValue={selectStatus3}
                                          onChange={(val) => {
                                            setSelectStatus3(val)
                                            props?.setFieldValue('status', val)
                                          }}
                                          options={[
                                            { label: 'Active', value: 'active' },
                                            { label: 'Inactive', value: 'inactive' },
                                          ]}
                                          value={selectStatus3}
                                          placeholder={'Select status'}
                                          name="Status"
                                        />

                                        <CFormLabel>Services (Large)</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <Select
                                          isMulti={true}
                                          defaultValue={selectServiceL}
                                          onChange={(val) => {
                                            handleChangeValueL(val)
                                            // setSelectServiceL(val)
                                            if (val == selectServiceL) setIsDisable(false)
                                            else setIsDisable(true)
                                            if (Object.keys(val).length == 0) {
                                              setSelectItem([])
                                              setItemOptions([])
                                              setRemainingServiceQuotaL([])
                                            }
                                            // setSelectItem([])

                                            allServices?.find((element) => {
                                              val?.map((item) => {
                                                //   if (element?._id == item?.value) {
                                                //     remaningQuota?.push({
                                                //       quota: element?.serviceQuota,
                                                //       value: element?._id,
                                                //       serviceName: element?.serviceName_EN,
                                                //     })
                                                //     setRemainingServiceQuotaL(remaningQuota)
                                                //   }
                                              })
                                            })
                                            props?.setFieldValue('services', val)
                                          }}
                                          options={serviceDataL}
                                          value={selectServiceL}
                                          placeholder={'Select services'}
                                          name="Services"
                                        />

                                        {selectServiceL.length == 0 ? (
                                          <span className="text-danger">Service is required</span>
                                        ) : (
                                          ''
                                        )}
                                        {remaningServiceQuotaL?.length > 0
                                          ? Object.values(remaningServiceQuotaL).map(
                                            (item, index) => {
                                              return (
                                                <CCol style={{ marginBottom: '5px' }}>
                                                  <CFormLabel>
                                                    {item.serviceName || item?.label} (Remain
                                                    Quota)
                                                    {/* {item?.serviceName} (Remain Quota) */}
                                                  </CFormLabel>
                                                  <span
                                                    className="text-danger"
                                                    style={{ marginLeft: '5px' }}
                                                  >
                                                    *
                                                  </span>
                                                  <DefaultInput
                                                    type="number"
                                                    // disabled={true}
                                                    placeholder={'Remain Service Quota'}
                                                    // value={item?.quota}
                                                    value={remaningServiceQuotaL[index].quota}
                                                    // onChange={(e) => {
                                                    //   const itemIndex =
                                                    //     remaningServiceQuotaL.findIndex(
                                                    //       (data) => data.quota === item.quota,
                                                    //     )
                                                    //   if (itemIndex === -1) {
                                                    //     console.error('Item not found')
                                                    //     return
                                                    //   }
                                                    //   // Create a new array with the updated item
                                                    //   const updatedItems = [
                                                    //     ...remaningServiceQuotaL.slice(
                                                    //       0,
                                                    //       itemIndex,
                                                    //     ), // all items before the updated item
                                                    //     {
                                                    //       ...remaningServiceQuotaL[itemIndex],
                                                    //       quota: e.target.value,
                                                    //     }, // updated item
                                                    //     ...remaningServiceQuotaL.slice(
                                                    //       itemIndex + 1,
                                                    //     ), // all items after the updated item
                                                    //   ]
                                                    //   // Update the state with the new array
                                                    //   setRemainingServiceQuotaL(updatedItems)
                                                    // }}
                                                    onChange={(e) => handleSelectChangeL(e, item)}
                                                  // onChange={props?.handleChange(
                                                  //   'servicesQuota',
                                                  // )}
                                                  />
                                                </CCol>
                                              )
                                            },
                                          )
                                          : ''}
                                      </CCol>
                                    </CRow>
                                  </CTabPane>
                                </CTabContent>
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol md="2">
                                <button
                                  type="submit"
                                  className={'btn btn-success mt-2'}
                                // disabled={!isDisable || props?.isSubmitting}
                                >
                                  Submit
                                </button>
                              </CCol>
                              <CCol md="2">
                                <button
                                  type="button"
                                  className={'btn btn-warning mt-2'}
                                  onClick={() => navigate('/admin/subscription-plan/package')}
                                >
                                  Reset
                                </button>
                              </CCol>
                              <CCol md="3"></CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default edit
