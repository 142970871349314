import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CCardSubtitle,
  CRow,
  CFormLabel,
  CImage,
} from '@coreui/react'
import { ImageUrl } from 'src/config/config'
import { FaArrowLeft } from 'react-icons/fa'

function BlogDetail() {
  const { state } = useLocation()
  return (
    <>
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <CRow>
                    <CCol >
                      {' '}
                      <Link style={{ color: '#000' }} to={{ pathname: `/admin/blog` }}>
                        <FaArrowLeft />
                      </Link>
                      <span>  Blog Information</span>
                    </CCol>
                  </CRow>
                </CHeader>
                <CCardBody>
                  <CRow>
                    <CCol md={2}>
                      <strong>Title (EN):-</strong>
                    </CCol>
                    <CCol>
                      <span>{state?.title_EN}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Title (TH):-</strong>
                    </CCol>
                    <CCol>
                      <span>{state?.title_TH}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Meta Title (EN):-</strong>
                    </CCol>
                    <CCol>
                      <span>{state?.metaTitle_EN}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Meta Title (TH):-</strong>
                    </CCol>
                    <CCol>
                      <span>{state?.metaTitle_TH}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Meta Key (EN):-</strong>
                    </CCol>
                    <CCol>
                      <span>{state?.metaKey_EN}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Meta Key (TH):-</strong>
                    </CCol>
                    <CCol>
                      <span>{state?.metaKey_TH}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Img Text (EN):-</strong>
                    </CCol>
                    <CCol>
                      <span dangerouslySetInnerHTML={{ __html: state?.imgtext_EN }}></span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Img Text (TH):-</strong>
                    </CCol>
                    <CCol>
                      <span dangerouslySetInnerHTML={{ __html: state?.imgtext_TH }}></span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Author Name (EN):-</strong>
                    </CCol>
                    <CCol>
                      <span>{state?.blogWriter_EN}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Author Name (TH):-</strong>
                    </CCol>
                    <CCol>
                      <span>{state?.blogWriter_TH}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Description (EN):-</strong>
                    </CCol>
                    <CCol>
                      <span dangerouslySetInnerHTML={{__html: state?.description_EN}}></span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Description (TH):-</strong>
                    </CCol>
                    <CCol>
                      <span dangerouslySetInnerHTML={{__html: state?.description_TH}}></span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Meta Description (EN):-</strong>
                    </CCol>
                    <CCol>
                      <span dangerouslySetInnerHTML={{__html: state?.metaDescription_EN}}></span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Meta Description (TH):-</strong>
                    </CCol>
                    <CCol>
                      <span dangerouslySetInnerHTML={{__html: state?.metaDescription_TH}}></span>
                    </CCol>
                  </CRow>
                  <CRow>
                  <CCol md={2}>
                      <strong>Image:-</strong>
                    </CCol>
                    <CCol>
                    <div
                      style={{
                        marginTop: '10px',
                        justifyContent: 'center',
                      }}
                    >
                     <a target='_blank' href={`${ImageUrl}${state?.image}`}>
                      <CImage
                        rounded
                        src={`${ImageUrl}${state?.image}`}
                        style={{
                          maxHeight: '100px',
                          maxWidth: '100px',
                          borderRadius: 50,
                          alignItems: 'center',
                        }}
                      />
                      </a>
                    </div>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default BlogDetail
