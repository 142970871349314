import React from 'react'
import { Link } from 'react-router-dom';
import { baseURL } from 'src/config/config';
import { post } from "../../../utils/apiManager";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilEnvelopeClosed } from '@coreui/icons'
import { useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { LoginDetails } from 'src/reduxToolkit/slice/auth';
import { useDispatch, useSelector } from "react-redux";
import logo from 'src/assets/images/logo.png'

export default function ForgotPassword() {
  const dispatch = useDispatch();
  // dispatch(LoginDetails(
  //   {token:"data?.token",userId:"data?.data?._id",email:"data?.data?.email"}
  // ))
  const { register, handleSubmit, formState: { errors: errors } } = useForm();

  // console.log(baseURL,adminBaseUrl)
  const onSubmit = (value) => {

    // const LIVE3000=`https://cuelaundry.devtechnosys.tech/admin/forgot-password`;
    // console.log("LIVE3000LIVE3000",LIVE3000)
    // const Admin3006=`https://cuelaundryadmin.devtechnosys.tech/admin/forgot-password`;
    // console.log("Admin3006Admin3006Admin3006",Admin3006)
    // const Url=`https://cuelaundry.devtechnosys.tech/admin/forgot-password`;
    // console.log(Url)

    const data = { email: value?.email }
    console.log(data)
    const Url = `${baseURL}/admin/forgot-password`
    post(Url, data).then((data) => handleLogin(data));
    const handleLogin = (data) => {
      if (data?.status) {
        toast.success(data?.message);
      }
      else {
        toast.error(data?.message)
      }
    };
  }

  return (
    <>
      <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md={6}>
              <CCardGroup>
                <CCard className="p-4">
                  <CCardBody>
                    <ToastContainer
                      position={toast.POSITION.TOP_RIGHT}
                      autoClose={2000}
                    />
                    <CForm onSubmit={handleSubmit(onSubmit)}>

                      <CRow>
                        <CCol>
                          <h2>Forgot Password</h2>
                        </CCol>
                        <CCol md={2}><img src={logo} alt="LOGO" /></CCol>
                      </CRow>
                      <CFormLabel>Email</CFormLabel>
                      <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                      <CInputGroup className="mb-3">
                        <CInputGroupText>
                          <CIcon icon={cilEnvelopeClosed} />
                        </CInputGroupText>
                        <CFormInput placeholder="Email" autoComplete="email" name="email" {...register("email", {
                          required: "Enter Email Address",
                          pattern: {
                            value:
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Invalid Email Type ",
                          },
                        })} />
                      </CInputGroup>
                      <div className='text-danger mb-2' style={{ marginTop: '-10px' }}><ErrorMessage errors={errors} name="email" /></div>

                      <CRow className="mt-4">
                        <CCol xs={7}>
                          <CButton type="submit" color="primary" className="px-4" >
                            Submit
                          </CButton>
                        </CCol>
                        <CCol xs={5} className="text-right">
                          <span>Have an account? </span>
                          <Link to="/admin/login">
                            <CButton color="link" className="px-0">
                              Login
                            </CButton>
                          </Link>
                        </CCol>
                      </CRow>
                    </CForm>
                  </CCardBody>
                </CCard>
                {/* <CCard className="text-white bg-primary py-5" style={{ width: '44%' }}>
                  <CCardBody className="text-center">
                    <div>
                      <h2>Sign In</h2>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua.
                      </p>
                      <Link to="/admin/register">
                        <CButton color="primary" className="mt-3" active tabIndex={-1}>
                          Register Now!
                        </CButton>
                      </Link>
                    </div>
                  </CCardBody>
                </CCard> */}
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </>
  )
}
