import { CCard, CCardBody, CCardGroup, CCol, CContainer, CHeader, CRow } from '@coreui/react'
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ImageUrl2, baseURL } from 'src/config/config'
import { get, put } from 'src/utils/apiManager'
import Barcode from 'src/assets/images/barcode-scan.png'
import ImagePreview from './previewImage'
import ItemImagePreview from './itemImagePreview'
import { useDispatch } from 'react-redux'
import { clearGarmentData } from 'src/reduxToolkit/slice/garmentShorting'
import ItemDetails from './itemDetails'
import Itemdetail from '../placedOrder/Itemdetail'

const BagDetails = () => {
  const { bagId, itemId } = useParams()
  const [bagDetail, setBagDetail] = useState(null)
  const [itemDetail, setItemDetails] = useState(null)
  const [items, setItems] = useState([])
  const [orderId, setOrderId] = useState([])
  const [selectedItem, setSelectedItem] = useState('')
  const [barcodeValue, setBarcodeValue] = useState('')
  const [selectedBarCode, setSelectedBarCode] = useState('')
  const [visible, setVisible] = useState(false)
  const [visiblePreview, setVisiblePreview] = useState(false)
  const [itemCode, setItemCode] = useState('')
  const inputRef = useRef()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  useEffect(() => {
    get(`/admin/offline-order/bag-item-details/${bagId}/${itemId}`).then((data) => {
      if (data.status) {
        setItemDetails({ ...data.data[0] })
      }
    })
  }, [itemId, bagId])

  useEffect(() => {
    get(`/admin/offline-order/get-order-bag/${bagId}`).then((data) => {
      if (data.status) {
        setBagDetail(data.data[0])
        setItems([...data.data[0].item])
        setOrderId(data.data[0].orders[0]._id)
        let itemCode =
          data.data[0].item && data.data[0].item.length > 0 ? data.data[0].item[0].itemCode : null
        if (itemCode) {
          getItemDetail(data.data[0].item[0].itemCode)
        } else {
          navigate(`/admin/search-bags/${data.data[0].orders[0].orderId}`)
        }
      }
    })
  }, [bagId])

  const getItemDetail = (itemCode) => {
    console.log('itemCode======>>', itemCode)
    setItemCode(itemCode)
    get(`/admin/placed-order/item-details?itemCode=${itemCode}`).then((data) => {
      if (data.status) {
        console.log('HIHIHIHIH===>>>>', data)
        setSelectedItem(data.data._id)
        setItemDetails({ ...data.data })
      }
    })
  }

  const handleItemClick = (id) => {
    get(`/admin/offline-order/bag-item-details/${bagId}/${itemId}`).then((data) => {
      if (data.status) {
        setItemDetails({ ...data.data[0] })
      }
    })
    setSelectedItem(id)
    navigate(`/admin/offline-order/bag-item/${bagId}/${id}`)
  }

  const handleItem = (action) => {
    put(`/admin/offline-order/bag-item-details/${bagId}/${itemId}`, { action }).then((data) => {
      if (data?.status) {
        get(`/admin/offline-order/get-order-bag/${bagId}`).then((res) => {
          if (res?.status) {
            console.log('response ------>>>>', res)
            setItems([...res.data[0].item])
            setOrderId(res.data[0].orders[0]._id)
            if (action == 'remove') {
              navigate('/admin/offline-order')
              dispatch(clearGarmentData())
            }
          }
        })
        get(`/admin/offline-order/bag-item-details/${bagId}/${itemId}`).then((data) => {
          if (data.status) {
            setItemDetails({ ...data.data[0] })
          }
        })
      }
    })
  }
  const viewOrder = () => {
    navigate(`/admin/search-bags/${itemDetail?.order?.orderId}`)
  }
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <CCard style={{ flex: '0.2' }}>
        <CRow className="justify-content-center" style={{ padding: '0px 2px' }}>
          <CRow>
            <CCol
              xs={12}
              style={{
                borderBottom: '1px solid #ccc',
                width: '100%',
                padding: '8px 5px',
              }}
            >
              Name : {itemDetail?.user?.username} <br />
              Service Name : {itemDetail?.service?.serviceName_EN} <br />
              Order Id : {itemDetail?.order?.orderId} <br />
              Item Code : {itemCode} <br />
            </CCol>
          </CRow>

          <CCol xs={11}>
            {items.length > 0 &&
              items.map((_item, index) => (
                <>
                  <CRow>
                    <CCol
                      xs={12}
                      key={_item.itemCode}
                      style={{
                        borderBottom: '1px solid #ccc',
                        width: '100%',
                        padding: '8px 5px',
                        backgroundColor: selectedItem === _item._id ? 'green' : 'white',
                        color: selectedItem === _item._id ? 'white' : '#000',
                        // backgroundColor:itemCode === _item.itemCode  ? "green" :"-"
                      }}
                      // className={itemCode === _item.itemCode && 'alert alert-success'}
                      onClick={() => getItemDetail(_item.itemCode)}
                    >
                      Item {index + 1}
                      <span style={{ float: 'Right' }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill={selectedItem === _item._id ? 'white' : '#000'}
                        >
                          <path d="M7.293 4.707 14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z" />
                        </svg>
                      </span>
                    </CCol>
                  </CRow>
                </>
              ))}
          </CCol>
        </CRow>
      </CCard>
      {selectedItem ? (
        <CCard style={{ flex: '0.6' }}>
          <CCardGroup>
            <CCardBody>
              {itemDetail && Object.keys(itemDetail)?.length > 0 ? (
                <>
                  <CHeader className="mb-3">
                    <h4 className="mb-3">Item summary page</h4>
                    <CRow>
                      <CCol md="12">
                        <button
                          className="btn btn-success"
                          style={{
                            width: '100%',
                            background: '#d3dc40',
                            border: '#d3dc40',
                          }}
                          onClick={viewOrder}
                        >
                          View Order Detail
                        </button>
                      </CCol>
                    </CRow>
                  </CHeader>
                  {/* <hr /> */}
                  <CRow className="justify-content-center mb-2 progress-list">
                    <CCol
                      xs={2}
                      className="align-items-center"
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <div style={{ position: 'relative' }}>
                        <div className="icon-box">
                          <div>
                            <i class="fa-solid fa-shirt"></i>
                          </div>
                          <div>{itemDetail?.itemId && <i class="fa-solid fa-check"></i>}</div>
                        </div>
                        <p style={{ textAlign: 'center', fontWeight: '600' }}>Type</p>
                      </div>
                      <div>{itemDetail?.itemId && itemDetail?.itemId?.itemName_EN}</div>
                    </CCol>
                    <CCol
                      xs={2}
                      className="align-items-center"
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <div style={{ position: 'relative' }}>
                        <div className="icon-box">
                          <div>
                            <i class="fa-solid fa-shirt"></i>
                          </div>
                          <div>{itemDetail?.brand && <i class="fa-solid fa-check"></i>}</div>
                        </div>
                        <p style={{ textAlign: 'center', fontWeight: '600' }}>Brand</p>
                      </div>
                      <div>{itemDetail?.brand && itemDetail?.brand}</div>
                    </CCol>
                    <CCol
                      xs={2}
                      className="align-items-center"
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <div style={{ position: 'relative' }}>
                        <div className="icon-box">
                          <div>
                            <i class="fa-solid fa-shirt"></i>
                          </div>
                          <div>
                            {itemDetail?.colorIds?.length > 0 && <i class="fa-solid fa-check"></i>}
                          </div>
                        </div>
                        <p style={{ textAlign: 'center', fontWeight: '600' }}>Colors</p>
                      </div>
                      <CCol cs={6}>
                        {itemDetail?.colorIds?.length > 0 &&
                          itemDetail?.colorIds?.map((_color) => {
                            return (
                              <div
                                style={{
                                  height: '50px',
                                  width: '50px',
                                  borderRadius: '50%',
                                  // border: '1px solid black',
                                  backgroundColor: _color?.colorCode,
                                  cursor: 'pointer',
                                  boxShadow: '0 1px 3px rgb(0 0 0 / 36%)',
                                  margin: '4px',
                                }}
                              ></div>
                            )
                          })}
                      </CCol>
                    </CCol>
                    <CCol
                      xs={2}
                      className="align-items-center"
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <div style={{ position: 'relative' }}>
                        <div className="icon-box">
                          <div>
                            <i class="fa-solid fa-shirt"></i>
                          </div>
                          <div>
                            <i class="fa-solid fa-check"></i>
                          </div>
                        </div>
                        <p style={{ textAlign: 'center', fontWeight: '600' }}>Washing Programs</p>
                      </div>
                      <div>{itemDetail?.processes?.processName_EN}</div>
                    </CCol>
                    <CCol
                      xs={2}
                      className="align-items-center"
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <div style={{ position: 'relative' }}>
                        <div className="icon-box">
                          <div>
                            <i class="fa-solid fa-shirt"></i>
                          </div>
                          <div>
                            {itemDetail?.image?.length > 0 && <i class="fa-solid fa-check"></i>}
                          </div>
                        </div>
                        <p style={{ textAlign: 'center', fontWeight: '600' }}>Image</p>
                      </div>
                      {itemDetail?.picture && (
                        <>
                          <img
                            src={ImageUrl2 + itemDetail.picture}
                            style={{ cursor: 'pointer', width: '50%', height: '100px' }}
                            onClick={() => setVisible(!visible)}
                            // data-coreui-toggle="modal"
                            // data-coreui-target="#staticBackdrop"
                          />
                          <ImagePreview
                            visible={visible}
                            setVisible={setVisible}
                            image={ImageUrl2 + itemDetail.picture}
                          />
                        </>
                      )}
                    </CCol>
                    <CCol
                      xs={2}
                      className="align-items-center"
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <div style={{ position: 'relative' }}>
                        <div className="icon-box">
                          <div>
                            <i class="fa-solid fa-shirt"></i>
                          </div>
                          <div>
                            {itemDetail?.stain.length > 0 ? <i class="fa-solid fa-check"></i> : ''}
                          </div>
                        </div>
                        <p style={{ textAlign: 'center', fontWeight: '600' }}>Stain</p>
                      </div>
                      {itemDetail.stain.map((value) => {
                        return (
                          <>
                            <div>
                             {value.stainName}
                            </div>
                            <p
                              onClick={() =>
                                value.images &&
                                value.images.length > 0 &&
                                !value.images.includes('')
                                  ? setVisiblePreview(!visiblePreview)
                                  : ''
                              }
                            >
                              {value.images &&
                              value.images.length > 0 &&
                              !value.images.includes('') ? (
                                <i class="fa-solid fa-images"></i>
                              ) : (
                                ''
                              )}
                            </p>
                            <ItemImagePreview
                              visiblePreview={visiblePreview}
                              setVisiblePreview={setVisiblePreview}
                              image={value.images}
                            />
                          </>
                        )
                      })}
                      
                      {/* <div>{(itemDetail.stain.map((values)=>values.stainName)).join(",")}</div>
                      <p
                        onClick={() =>
                          itemDetail?.stain.image &&
                          itemDetail?.stain.image.length > 0 &&
                          !itemDetail?.stain.image.includes('')
                            ? setVisiblePreview(!visiblePreview)
                            : ''
                        }
                      >
                        {itemDetail?.stain.image &&
                        itemDetail?.stain.image.length > 0 &&
                        !itemDetail?.stain.image.includes('') ? (
                          <i class="fa-solid fa-images"></i>
                        ) : (
                          ''
                        )}
                      </p>
                      <ItemImagePreview
                        visiblePreview={visiblePreview}
                        setVisiblePreview={setVisiblePreview}
                        image={itemDetail?.image}
                      /> */}
                    </CCol>
                  </CRow>
                  <CRow>
                    <h5>Instructions:</h5>
                    <div>{itemDetail.description}</div>
                  </CRow>
                </>
              ) : (
                <></>
              )}
            </CCardBody>
          </CCardGroup>
        </CCard>
      ) : (
        <>
          {' '}
          <CCard style={{ flex: '0.6' }}></CCard>
        </>
      )}
    </div>
  )
}

export default BagDetails
