/**
 * FYI: This is a demo for class components
 * To use simple-keyboard with React Hooks / Function components, check out:
 * https://simple-keyboard.com/react/demo/hooks
 */
import React, { Component } from 'react'
import { render } from 'react-dom'
import Keyboard from 'react-simple-keyboard'

// Instead of the default import, you can also use this:
// import { KeyboardReact as Keyboard } from "react-simple-keyboard"

import 'react-simple-keyboard/build/css/index.css'
import { DefaultInput } from 'src/components/common/input'
// import './index.css'

class CustomKeyboard extends Component {
  // state = {
  //   layoutName: 'default',
  //   input: '',
  // }

  onChange = (input) => {
    // this.setState({ input })
    this.props.setInput(input)
    console.log('Input changed', input)
  }

  onKeyPress = (button) => {
    console.log('Button pressed', button)

    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === '{shift}' || button === '{lock}') this.handleShift()
  }

  handleShift = () => {
    const layoutName = this.props.layoutName === 'default' ? 'shift' : 'default'
    this.props.setLayoutName(layoutName)
  }

  onChangeInput = (event) => {
    const input = event.target.value
    // this.setState({ input })
    this.props.setInput(input)
    this.keyboard.setInput(input)
  }

  render() {
    return (
      <div>
        {/* <input
          value={this.props.input}
          placeholder={'Tap on the virtual keyboard to start'}
          onChange={this.onChangeInput}
        /> */}
        <DefaultInput
          type="text"
          value={this.props.input}
          onChange={this.onChangeInput}
          name="description"
          id="description"
          style={{ margin: '30px 0px' }}
        />
        <Keyboard
          keyboardRef={(r) => (this.keyboard = r)}
          layoutName={this.props.layoutName}
          onChange={this.onChange}
          onKeyPress={this.onKeyPress}
        />
      </div>
    )
  }
}

// render(<App />, document.getElementById("root"));
export default CustomKeyboard
