import React,{useRef,useEffect,useState} from 'react';
import { Formik } from 'formik';
import { useLocation,useNavigate,Link } from 'react-router-dom'
import { put } from 'src/utils/apiManager';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import {
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CFormLabel,
    CRow,
  } from '@coreui/react'
import { DefaultInput } from 'src/components/common/input'
import { validateState } from 'src/utils/validator'

const EditState = () => {    const navigate = useNavigate()
    const { state } = useLocation()
    const formRef = useRef(null)
    const [isDisable, setIsDisable] = useState(false);

    const submit = async (values) => {
        let StateData = {
            stateName_EN: values?.stateName_EN,
            stateName_TH: values?.stateName_TH
        }
        put(`admin/state/update?_id=${state?._id}`, StateData).then((res) => {
            handleResponse(res)
        })
    };

    const handleResponse = (res) => {
        if (res?.status == true) {
            toast.success(res?.message)
            setTimeout(() => {
                navigate('/admin/state')
            }, 800)
        } else {
          toast.error(res?.message)
        }
        formRef.current.resetForm()
    };

    useEffect(() => {
      if (state != null) {
        formRef.current.setFieldValue('stateName_EN', state?.stateName_EN)
        formRef.current.setFieldValue('stateName_TH', state?.stateName_TH)
      }
    }, [state])

  return (
    <>
      <CContainer >
            <CRow>
            <CCol>
                <CCardGroup>
                <CCard className="p-4">
                    <CCol xs={12}>
                    <CCardGroup>
                        <CCardBody>
                        <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                        <CRow>
                          <CCol md="11">
                            <h1>Edit State</h1>
                          </CCol>                    
                          <CCol md="1">
                            <Link className={'btn btn-success mt-2'} style={{background:'#d3dc40',border: '#d3dc40' }} to={{ pathname: `/admin/state` }}>
                                Back
                            </Link>
                          </CCol>
                        </CRow>
                        <Formik
                        initialValues={{
                            stateName_EN: '',
                            stateName_TH: '',
                        }}
                        validationSchema={validateState}
                        onSubmit={(values, { setSubmitting }) => {
                            submit(values)
                            setSubmitting(false)
                            
                        }}
                        innerRef={formRef}
                        >
                        {(props) => (
                            <form onSubmit={props?.handleSubmit}>
                            <CFormLabel>State Name (EN)</CFormLabel>
                            <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                            <DefaultInput
                                type={"text"}
                                placeholder={"State Name"}
                                // maxLength={50}
                                value={props?.values?.stateName_EN}
                                onChange={props?.handleChange("stateName_EN")}
                                onKeyUp={()=>{
                                    if(state?.stateName_EN == props?.values?.stateName_EN) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                onBlur={() => props?.setFieldTouched("stateName_EN", true)}
                                error={props?.touched?.stateName_EN && props?.errors?.stateName_EN}
                            />

                            <CFormLabel>State Name (TH)</CFormLabel>
                            <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                            <DefaultInput
                                type={"text"}
                                placeholder={"State Name (TH)"}
                                // maxLength={50}
                                value={props?.values?.stateName_TH}
                                onChange={props?.handleChange("stateName_TH")}
                                onKeyUp={()=>{
                                    if(state?.stateName_TH == props?.values?.stateName_TH) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                onBlur={() => props?.setFieldTouched("stateName_TH", true)}
                                error={props?.touched?.stateName_TH && props?.errors?.stateName_TH}
                            />

                            <CRow>
                                <CCol md="2">
                                <button type="submit"className={'btn btn-success mt-2'}disabled={!isDisable || props?.isSubmitting}>Submit</button>
                                </CCol>
                                <CCol md="2">
                                <button
                                    type="button"
                                    className={'btn btn-warning mt-2'}
                                    onClick={() => navigate('/admin/state')}
                                >
                                    Cancel
                                </button>
                                </CCol>
                                <CCol md="3"></CCol>
                            </CRow>
                            </form>
                        )}
                        </Formik>
                        </CCardBody>
                    </CCardGroup>
                    </CCol>               
                </CCard>
                </CCardGroup>
            </CCol>
            </CRow>
        </CContainer>
    </>
  )
}

export default EditState;