import React, { useRef, useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useLocation, useNavigate, Link } from 'react-router-dom'
import { put } from 'src/utils/apiManager';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CFormLabel,
  CRow,
} from '@coreui/react'
import { DefaultInput } from 'src/components/common/input'
import { validateEditVan } from 'src/utils/validator'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import { TextField } from '@mui/material';

function editVan() {
  const navigate = useNavigate()
  const { state } = useLocation()
  const formRef = useRef(null)
  const [isDisable, setIsDisable] = useState(false)
  const [insuranceValidity, setInsuranceValidity] = useState(null);
  const [registartionValidity, setRegistartionValidity] = useState(null);
  const [startMinDate, setStartMinDate] = useState(moment().format())
  const e = moment(startMinDate).add(+1, 'days')
  const [defaultInsuranceValidity, setDefaultInsuranceValidity] = useState();
  const [defaultRegistartionValidity, setDefaultRegistartionValidity] = useState();
  // console.log(e._d)
  // console.log(state, "statte data")

  const handleDateChange = (event, type) => {
    if (type == 'insuranceValidity') {
      setInsuranceValidity(event?.$d)
    } else {
      setRegistartionValidity(event?.$d)
    }
  }
  const submit = async (values) => {
    const insuranceDate = (moment(values?.insuranceValidity)).format("YYYY-MM-DDTHH:mm:ssZ")
    const registartionDate = (moment(values?.registartionValidity)).format("YYYY-MM-DDTHH:mm:ssZ")
    let vanData = {
      id: state?._id,
      vanNo: values?.vanNo,
      registrationNo: values?.registrationNo,
      vehicleModal: values?.vehicleModal,
      insuranceValidity: values?.insuranceValidity != undefined ? insuranceDate : state?.insuranceValidity,
      registrationValidity: values?.registartionValidity != undefined ? registartionDate : state?.registartionValidity,
    }
    put(`admin/Van/update?_id=${state?._id}`, vanData).then((data) => {
      handleResponse(data)
    })
  }
  const handleResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      setTimeout(() => {
        navigate('/admin/van')
      }, 800)
    }
    formRef.current.resetForm()
  }
  useEffect(() => {
    if (state != null) {
      setDefaultInsuranceValidity(state?.insuranceValidity)
      setDefaultRegistartionValidity(state?.registrationValidity)
      formRef.current.setFieldValue('vanNo', state?.vanNo)
      formRef.current.setFieldValue('registrationNo', state?.registrationNo)
      formRef.current.setFieldValue('vehicleModal', state?.vehicleModal)
    }
  }, [state])

  return (
    <>
      <CContainer >
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <CRow>
                        <CCol md="11">
                          <h1>Edit Van</h1>
                        </CCol>
                        <CCol md="1">
                          <Link className={'btn btn-success mt-2'} style={{ background: '#d3dc40', border: '#d3dc40' }} to={{ pathname: `/admin/van` }}>
                            Back
                          </Link>
                        </CCol>
                      </CRow>
                      <Formik
                        initialValues={{
                          vanNo: '',
                          registrationNo: '',
                          vehicleModal: ''
                        }}
                        validationSchema={validateEditVan}
                        onSubmit={(values, { setSubmitting }) => {
                          submit(values)
                          setSubmitting(false)

                        }}
                        innerRef={formRef}
                      >
                        {(props) => (
                          <form onSubmit={props?.handleSubmit}>
                            <CRow>
                              <CCol md={6}>
                                <CFormLabel>Registration plate</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={"text"}
                                  placeholder={"Van Number"}
                                  value={props?.values?.vanNo}
                                  onChange={props?.handleChange("vanNo")}
                                  onKeyUp={() => {
                                    if (state?.vanNo == props?.values?.vanNo) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                  onBlur={() => props?.setFieldTouched("vanNo", true)}
                                  error={props?.touched?.vanNo && props?.errors?.vanNo}
                                />
                                <CFormLabel>Registration Number</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={"text"}
                                  placeholder={"Registration Number"}
                                  // maxLength={6}
                                  value={props?.values?.registrationNo}
                                  onKeyUp={() => {
                                    if (state?.registrationNo == props?.values?.registrationNo) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                  onChange={props?.handleChange("registrationNo")}
                                  onBlur={() => props?.setFieldTouched("registrationNo", true)}
                                  error={props?.touched?.registrationNo && props?.errors?.registrationNo}
                                />
                                <CFormLabel>Vehicle Modal</CFormLabel>
                                {/* <span className='text-danger' style={{ marginLeft: '5px' }}>*</span> */}
                                <DefaultInput
                                  type={"text"}
                                  placeholder={"Vehicle Modal"}
                                  // maxLength={6}
                                  value={props?.values?.vehicleModal}
                                  onKeyUp={() => {
                                    if (state?.vehicleModal == props?.values?.vehicleModal) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                  onChange={props?.handleChange("vehicleModal")}
                                  onBlur={() => props?.setFieldTouched("vehicleModal", true)}
                                  error={props?.touched?.vehicleModal && props?.errors?.vehicleModal}
                                />
                              </CCol>
                              <CCol md={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <div><CFormLabel>Insurance Validity</CFormLabel>
                                    {/* <span className='text-danger' style={{ marginLeft: '5px' }}>*</span> */}
                                    </div>
                                  <DesktopDatePicker
                                    inputFormat="DD/MM/YYYY"
                                    name="insuranceValidity"
                                    style={{ height: '68px', height: '595px' }}
                                    minDate={e?._d}
                                    value={insuranceValidity == null ? defaultInsuranceValidity : insuranceValidity}
                                    // minDate={moment()}
                                    // maxDate={fromMax}
                                    onChange={(e) => {
                                      if (e?.$d) setIsDisable(true)
                                      else setIsDisable(false)
                                      // console.log(e, "eeeeeeeeee", moment())
                                      handleDateChange(e, 'insuranceValidity')
                                      props?.setFieldValue('insuranceValidity', e?.$d)
                                    }}
                                    renderInput={(params) => <TextField style={{ marginTop: '5px' }}
                                      name="insuranceValidity" {...params}
                                    />}
                                  />
                                </LocalizationProvider>

                                {/* {insuranceValidity == null && props?.touched?.insuranceValidity == true ? <p className='text-danger mt-2'>{props?.errors?.insuranceValidity}</p> : ''} */}

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <div><CFormLabel>Registartion Validity</CFormLabel>
                                    {/* <span className='text-danger' style={{ marginLeft: '5px' }}>*</span> */}
                                    </div>
                                  <DesktopDatePicker
                                    inputFormat="DD/MM/YYYY"
                                    name="registartionValidity"
                                    style={{ height: '68px', height: '595px' }}
                                    // value={registartionValidity}
                                    value={registartionValidity == null ? defaultRegistartionValidity : registartionValidity}
                                    minDate={e._d}
                                    onChange={(e) => {
                                      if (e?.$d) setIsDisable(true)
                                      else setIsDisable(false)
                                      handleDateChange(e, 'registartionValidity')
                                      props?.setFieldValue('registartionValidity', e?.$d)
                                    }}
                                    renderInput={(params) => <TextField style={{ marginTop: '5px' }}
                                      name="registartionValidity" {...params}
                                    />}
                                  />
                                </LocalizationProvider>

                                {/* {registartionValidity == null && props?.touched?.registartionValidity == true ? <p className='text-danger mt-2'>{props?.errors?.registartionValidity}</p> : ''} */}

                              </CCol>
                            </CRow>


                            <CRow>
                              <CCol md="2">
                                <button
                                  type="submit"
                                  className={'btn btn-success mt-2'}
                                  disabled={!isDisable || props?.isSubmitting}
                                >
                                  Submit
                                </button>
                              </CCol>
                              <CCol md="2">
                                <button
                                  type="button"
                                  className={'btn btn-warning mt-2'}
                                  onClick={() => navigate('/admin/van')}
                                >
                                  Cancel
                                </button>
                              </CCol>
                              <CCol md="3"></CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default editVan