import React, { useEffect, useState } from 'react'
import {
  CRow,
  CCol,
  CDropdown,
  CDropdownMenu,
  CDropdownItem,
  CDropdownToggle,
  CWidgetStatsA,
} from '@coreui/react'
import { getStyle } from '@coreui/utils'
import { CChartBar, CChartLine } from '@coreui/react-chartjs'
import CIcon from '@coreui/icons-react'
import { cilArrowBottom, cilArrowTop, cilOptions } from '@coreui/icons'
import { get } from 'src/utils/apiManager'
import { FaCartArrowDown, FaMoneyBill, FaTruck, FaUserCog, FaUserFriends, FaUsers, FaDollarSign } from 'react-icons/fa'

const WidgetsDropdown = ({ filterTrigger, ranges, homeData }) => {

  const [totalUser, setTotalUser] = useState([])
  const [totalDriver, setTotalDriver] = useState([])
  const [totalVan, setTotalVan] = useState([])
  const [totalSubAdmin, setTotalSubAdmin] = useState([])
  const [totalPackageOrder, setTotalPackageOrder] = useState([])
  const [totalRegularOrder, setTotalRegularOrder] = useState([])

  const [customerData, setCustomerData] = useState();
  const [driverData, setDriverData] = useState();
  const [vanData, setVanData] = useState();
  const [subAdmin, setSubAdminData] = useState();
  const [PackageOrderData, setPackageOrderData] = useState();
  const [regularOrderData, setRegularOrderData] = useState();
  const [chartLabel, setChartLabel] = useState();
  const [totalRevenue, setTotalRevenue] = useState()

  const getGraphData = () => {
    const role = 'customer,driver,subAdmin,van';
    let url = `admin/dashboard/charts?role=${role}`
    if (ranges?.startDate && ranges?.endDate) {
      url = `admin/dashboard/charts?role=${role}&start_date=${formatDateToYMD(ranges?.startDate)}&end_date=${formatDateToYMD(ranges?.endDate)}`
    }
    get(url).then((res) => {
      setCustomerData(res?.data?.customerData[0]?.data ? Object.values(res?.data?.customerData[0]?.data) : [])
      setDriverData(res?.data?.driverData[0]?.data ? Object.values(res?.data?.driverData[0]?.data) : [])
      setVanData(res?.data?.vanData[0]?.data ? Object.values(res?.data?.vanData[0]?.data) : [])
      setSubAdminData(res?.data?.subAdminData[0]?.data ? Object.values(res?.data?.subAdminData[0]?.data) : [])
      setRegularOrderData(res?.data?.RegularOrders[0]?.data ? Object.values(res?.data?.RegularOrders[0]?.data) : [])
      setPackageOrderData(res?.data?.PackageOrders[0]?.data ? Object.values(res?.data?.PackageOrders[0]?.data) : [])
      setChartLabel(typeof res?.data?.customerData[0]?.data === "object" && Object.keys(res?.data?.customerData[0]?.data))
    })
  }

  useEffect(() => {
    if (homeData) {
      setTotalUser(homeData.data.user)
      setTotalDriver(homeData.data.driver)
      setTotalVan(homeData.data.vans)
      setTotalSubAdmin(homeData.data.subadmin)
      setTotalPackageOrder(homeData.data.packageOrder)
      setTotalRegularOrder(homeData.data.regularOrder)
      setTotalRevenue(homeData.data.revenue)
    }
  }, [homeData])

  useEffect(() => {
    getGraphData();
  }, [filterTrigger]);

  function formatDateToYMD(date) {
    if (!date) return
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(date.getDate()).padStart(2, '0'); // Pad single-digit days with leading zero
    return `${year}-${month}-${day}`;
  }

  return (
    <CRow>
      <CCol sm={6} lg={4}>
        <CWidgetStatsA
          className="mb-4"
          // color="primary"
          style={{ color: '#fff', backgroundColor: 'rgba(210, 220, 35, 1)' }}
          value={
            <>
              {totalPackageOrder}{' '}
              <span className="fs-6 fw-normal">
                {/* (-12.4% <CIcon icon={cilArrowBottom} />) */}
              </span>
            </>
          }
          // icon={<FaCartArrowDown className="float-end" />}
          title="Package Orders"
          action={
            <FaCartArrowDown className="float-end" style={{ fontSize: '50px' }} />
            // <CDropdown alignment="end">
            //   <CDropdownToggle color="transparent" caret={false} className="p-0">
            //     <CIcon icon={cilOptions} className="text-high-emphasis-inverse" />
            //   </CDropdownToggle>
            //   <CDropdownMenu>
            //     <CDropdownItem>Action</CDropdownItem>
            //     <CDropdownItem>Another action</CDropdownItem>
            //     <CDropdownItem>Something else here...</CDropdownItem>
            //     <CDropdownItem disabled>Disabled action</CDropdownItem>
            //   </CDropdownMenu>
            // </CDropdown>
          }
          chart={
            <CChartLine
              className=" mx-3 "
              height={85}
              data={{
                labels: chartLabel,
                datasets: [
                  {
                    label: 'Package Order',
                    backgroundColor: 'transparent',
                    borderColor: 'rgba(255,255,255,.55)',
                    pointBackgroundColor: 'rgba(210, 220, 35, 1)',
                    // borderColor: 'rgba(255,255,255,.55)',
                    // pointBackgroundColor: getStyle('--cui-primary'),
                    // data: [1, 18, 9, 17, 34, 22, 11, 1, 2, 3, 4, 5, 6],
                    // data: driverData,
                    data: PackageOrderData,
                  },
                ],
              }}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                maintainAspectRatio: false,
                scales: {
                  x: {
                    grid: {
                      display: false,
                      drawBorder: false,
                    },
                    ticks: {
                      display: false,
                    },
                  },
                  y: {
                    min: -10,
                    max: 89,
                    display: false,
                    grid: {
                      display: false,
                    },
                    ticks: {
                      display: false,
                    },
                  },
                },
                elements: {
                  line: {
                    borderWidth: 1,
                    tension: 0.4,
                  },
                  point: {
                    radius: 4,
                    hitRadius: 10,
                    hoverRadius: 4,
                  },
                },
              }}
            />
          }
        />
      </CCol>
      <CCol sm={6} lg={4}>
        <CWidgetStatsA
          className="mb-4"
          // color="primary" rgba(30, 136, 229, 0.1)
          style={{ color: '#fff', backgroundColor: 'rgba(30, 136, 229,0.5)' }}
          value={
            <>
              {totalRegularOrder}{' '}
              <span className="fs-6 fw-normal">
                {/* (-12.4% <CIcon icon={cilArrowBottom} />) */}
              </span>
            </>
          }
          title="Regular Orders"
          action={
            <FaCartArrowDown className="float-end" style={{ fontSize: '50px' }} />
            // <CDropdown alignment="end">
            //   <CDropdownToggle color="transparent" caret={false} className="p-0">
            //     <CIcon icon={cilOptions} className="text-high-emphasis-inverse" />
            //   </CDropdownToggle>
            //   <CDropdownMenu>
            //     <CDropdownItem>Action</CDropdownItem>
            //     <CDropdownItem>Another action</CDropdownItem>
            //     <CDropdownItem>Something else here...</CDropdownItem>
            //     <CDropdownItem disabled>Disabled action</CDropdownItem>
            //   </CDropdownMenu>
            // </CDropdown>
          }
          chart={
            <CChartLine
              className=" mx-3"
              height={85}
              data={{
                labels: chartLabel,
                datasets: [
                  {
                    label: 'Regular Order',
                    backgroundColor: 'transparent',
                    borderColor: 'rgba(30, 136, 229,0.2)',
                    pointBackgroundColor: "rgba(30, 136, 229, 0.5)",
                    // borderColor: 'rgba(255,255,255,.55)',
                    // pointBackgroundColor: getStyle('--cui-primary'),
                    // data: [1, 18, 9, 17, 34, 22, 11, 1, 2, 3, 4, 5, 6],
                    // data: driverData,
                    data: regularOrderData,
                  },
                ],
              }}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                maintainAspectRatio: false,
                scales: {
                  x: {
                    grid: {
                      display: false,
                      drawBorder: false,
                    },
                    ticks: {
                      display: false,
                    },
                  },
                  y: {
                    min: -10,
                    max: 89,
                    display: false,
                    grid: {
                      display: false,
                    },
                    ticks: {
                      display: false,
                    },
                  },
                },
                elements: {
                  line: {
                    borderWidth: 1,
                    tension: 0.4,
                  },
                  point: {
                    radius: 4,
                    hitRadius: 10,
                    hoverRadius: 4,
                  },
                },
              }}
            />
          }
        />
      </CCol>

      <CCol sm={6} lg={4}>
        <CWidgetStatsA
          className="mb-4"
          color="primary"
          value={
            <>
              {totalUser}{' '}
              <span className="fs-6 fw-normal">
                {/* (-12.4% <CIcon icon={cilArrowBottom} />) */}
              </span>
            </>
          }
          title="Users"
          action={
            <FaUserCog className="float-end" style={{ fontSize: '50px' }} />
            // <CDropdown alignment="end">
            //   <CDropdownToggle color="transparent" caret={false} className="p-0">
            //     <CIcon icon={cilOptions} className="text-high-emphasis-inverse" />
            //   </CDropdownToggle>
            //   <CDropdownMenu>
            //     <CDropdownItem>Action</CDropdownItem>
            //     <CDropdownItem>Another action</CDropdownItem>
            //     <CDropdownItem>Something else here...</CDropdownItem>
            //     <CDropdownItem disabled>Disabled action</CDropdownItem>
            //   </CDropdownMenu>
            // </CDropdown>
          }
          chart={
            <CChartLine
              className=" mx-3"
              height={85}
              data={{
                labels: chartLabel,
                datasets: [
                  {
                    label: 'User',
                    backgroundColor: 'transparent',
                    borderColor: 'rgba(255,255,255,.55)',
                    pointBackgroundColor: getStyle('--cui-primary'),
                    // data: [1, 18, 9, 17, 34, 22, 11, 1, 2, 3, 4, 5, 6],
                    // data: driverData,
                    data: customerData,
                  },
                ],
              }}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                maintainAspectRatio: false,
                scales: {
                  x: {
                    grid: {
                      display: false,
                      drawBorder: false,
                    },
                    ticks: {
                      display: false,
                    },
                  },
                  y: {
                    min: -10,
                    max: 89,
                    display: false,
                    grid: {
                      display: false,
                    },
                    ticks: {
                      display: false,
                    },
                  },
                },
                elements: {
                  line: {
                    borderWidth: 1,
                    tension: 0.4,
                  },
                  point: {
                    radius: 4,
                    hitRadius: 10,
                    hoverRadius: 4,
                  },
                },
              }}
            />
          }
        />
      </CCol>
      <CCol sm={6} lg={4}>
        <CWidgetStatsA
          className="mb-4"
          color="info"
          value={
            <>
              {totalDriver}{' '}
              <span className="fs-6 fw-normal">
                {/* (40.9% <CIcon icon={cilArrowTop} />) */}
              </span>
            </>
          }
          title="Van Drivers"
          action={
            <FaUsers className="float-end" style={{ fontSize: '50px' }} />
            // <CDropdown alignment="end">
            //   <CDropdownToggle color="transparent" caret={false} className="p-0">
            //     <CIcon icon={cilOptions} className="text-high-emphasis-inverse" />
            //   </CDropdownToggle>
            //   <CDropdownMenu>
            //     <CDropdownItem>Action</CDropdownItem>
            //     <CDropdownItem>Another action</CDropdownItem>
            //     <CDropdownItem>Something else here...</CDropdownItem>
            //     <CDropdownItem disabled>Disabled action</CDropdownItem>
            //   </CDropdownMenu>
            // </CDropdown>
          }
          chart={
            <CChartLine
              className=" mx-3"
              // height={50}
              style={{ height: '85px' }}
              data={{
                // labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                labels: chartLabel,
                datasets: [
                  {
                    label: 'Van Driver',
                    backgroundColor: 'transparent',
                    borderColor: 'rgba(255,255,255,.55)',
                    pointBackgroundColor: getStyle('--cui-info'),
                    // data: [1, 18, 9, 17, 34, 22, 11, 1, 2, 3, 4, 5, 6],
                    data: driverData,
                    // data: customerData,
                  },
                ],
              }}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                maintainAspectRatio: false,
                scales: {
                  x: {
                    grid: {
                      display: false,
                      drawBorder: false,
                    },
                    ticks: {
                      display: false,
                    },
                  },
                  y: {
                    min: -9,
                    max: 39,
                    display: false,
                    grid: {
                      display: false,
                    },
                    ticks: {
                      display: false,
                    },
                  },
                },
                elements: {
                  line: {
                    borderWidth: 1,
                  },
                  point: {
                    radius: 4,
                    hitRadius: 10,
                    hoverRadius: 4,
                  },
                },
              }}
            />
          }
        />
      </CCol>
      <CCol sm={6} lg={4}>
        <CWidgetStatsA
          className="mb-4"
          color="warning"
          value={
            <>
              {totalVan}{' '}
              <span className="fs-6 fw-normal">
                {/* (84.7% <CIcon icon={cilArrowTop} />) */}
              </span>
            </>
          }
          title="Vans"
          action={
            <FaTruck className="float-end" style={{ fontSize: '50px' }} />
            // <CDropdown alignment="end">
            //   <CDropdownToggle color="transparent" caret={false} className="p-0">
            //     <CIcon icon={cilOptions} className="text-high-emphasis-inverse" />
            //   </CDropdownToggle>
            //   <CDropdownMenu>
            //     <CDropdownItem>Action</CDropdownItem>
            //     <CDropdownItem>Another action</CDropdownItem>
            //     <CDropdownItem>Something else here...</CDropdownItem>
            //     <CDropdownItem disabled>Disabled action</CDropdownItem>
            //   </CDropdownMenu>
            // </CDropdown>
          }
          chart={
            <CChartLine
              className="mt-3"
              style={{ height: '70px' }}
              data={{
                // labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                labels: chartLabel,
                datasets: [
                  {
                    label: 'Van',
                    backgroundColor: 'rgba(255,255,255,.2)',
                    borderColor: 'rgba(255,255,255,.55)',
                    data: vanData,
                    fill: true,
                  },
                ],
              }}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                maintainAspectRatio: false,
                scales: {
                  x: {
                    display: false,
                  },
                  y: {
                    display: false,
                  },
                },
                elements: {
                  line: {
                    borderWidth: 2,
                    tension: 0.4,
                  },
                  point: {
                    radius: 0,
                    hitRadius: 10,
                    hoverRadius: 4,
                  },
                },
              }}
            />
          }
        />
      </CCol>
      <CCol sm={6} lg={4}>
        <CWidgetStatsA
          className="mb-4"
          color="danger"
          value={
            <>
              {totalSubAdmin}{' '}
              <span className="fs-6 fw-normal">
                {/* (-23.6% <CIcon icon={cilArrowBottom} />) */}
              </span>
            </>
          }
          title="Sub Admin"
          action={
            <FaUserFriends className="float-end" style={{ fontSize: '50px' }} />
            // <CDropdown alignment="end">
            //   <CDropdownToggle color="transparent" caret={false} className="p-0">
            //     <CIcon icon={cilOptions} className="text-high-emphasis-inverse" />
            //   </CDropdownToggle>
            //   <CDropdownMenu>
            //     <CDropdownItem>Action</CDropdownItem>
            //     <CDropdownItem>Another action</CDropdownItem>
            //     <CDropdownItem>Something else here...</CDropdownItem>
            //     <CDropdownItem disabled>Disabled action</CDropdownItem>
            //   </CDropdownMenu>
            // </CDropdown>
          }
          chart={
            <CChartBar
              className="mt-3 mx-3"
              style={{ height: '70px' }}
              data={{
                labels: chartLabel,
                datasets: [
                  {
                    label: 'Sub Admin',
                    backgroundColor: 'rgba(255,255,255,.2)',
                    borderColor: 'rgba(255,255,255,.55)',
                    data: subAdmin,
                    barPercentage: 0.6,
                  },
                ],
              }}
              options={{
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                scales: {
                  x: {
                    grid: {
                      display: false,
                      drawTicks: false,
                    },
                    ticks: {
                      display: false,
                    },
                  },
                  y: {
                    grid: {
                      display: false,
                      drawBorder: false,
                      drawTicks: false,
                    },
                    ticks: {
                      display: false,
                    },
                  },
                },
              }}
            />
          }
        />
      </CCol>
      <CCol sm={6} lg={4}>
        <CWidgetStatsA
          className="mb-4"
          color="danger"
          value={
            <>
              {totalRevenue}{' '}
              <span className="fs-6 fw-normal">
                {/* (-23.6% <CIcon icon={cilArrowBottom} />) */}
              </span>
            </>
          }
          title="Revenue"
          action={
            <FaDollarSign className="float-end" style={{ fontSize: '50px' }} />
          }
          chart={
            <CChartBar
              className="mt-3 mx-3"
              style={{ height: '70px' }}
              data={{
                labels: chartLabel,
                datasets: [
                  {
                    label: 'Sub Admin',
                    backgroundColor: 'rgba(255,255,255,.2)',
                    borderColor: 'rgba(255,255,255,.55)',
                    data: [],
                    barPercentage: 0.6,
                  },
                ],
              }}
              options={{
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                scales: {
                  x: {
                    grid: {
                      display: false,
                      drawTicks: false,
                    },
                    ticks: {
                      display: false,
                    },
                  },
                  y: {
                    grid: {
                      display: false,
                      drawBorder: false,
                      drawTicks: false,
                    },
                    ticks: {
                      display: false,
                    },
                  },
                },
              }}
            />
          }
        />
      </CCol>
      <CCol sm={6} lg={4}>
        <CWidgetStatsA
          className="mb-4"
          color="info"
          value={
            <>
              {homeData?.data?.uniqueUsers}{' '}
              <span className="fs-6 fw-normal">
                {/* (40.9% <CIcon icon={cilArrowTop} />) */}
              </span>
            </>
          }
          title="Unique Users"
          action={
            <FaUsers className="float-end" style={{ fontSize: '50px' }} />
            // <CDropdown alignment="end">
            //   <CDropdownToggle color="transparent" caret={false} className="p-0">
            //     <CIcon icon={cilOptions} className="text-high-emphasis-inverse" />
            //   </CDropdownToggle>
            //   <CDropdownMenu>
            //     <CDropdownItem>Action</CDropdownItem>
            //     <CDropdownItem>Another action</CDropdownItem>
            //     <CDropdownItem>Something else here...</CDropdownItem>
            //     <CDropdownItem disabled>Disabled action</CDropdownItem>
            //   </CDropdownMenu>
            // </CDropdown>
          }
          chart={
            <CChartLine
              className=" mx-3"
              // height={50}
              style={{ height: '85px' }}
              data={{
                // labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                labels: chartLabel,
                datasets: [
                  {
                    label: 'Van Driver',
                    backgroundColor: 'transparent',
                    borderColor: 'rgba(255,255,255,.55)',
                    pointBackgroundColor: getStyle('--cui-info'),
                    // data: [1, 18, 9, 17, 34, 22, 11, 1, 2, 3, 4, 5, 6],
                    data:  [],
                    // data: customerData,
                  },
                ],
              }}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                maintainAspectRatio: false,
                scales: {
                  x: {
                    grid: {
                      display: false,
                      drawBorder: false,
                    },
                    ticks: {
                      display: false,
                    },
                  },
                  y: {
                    min: -9,
                    max: 39,
                    display: false,
                    grid: {
                      display: false,
                    },
                    ticks: {
                      display: false,
                    },
                  },
                },
                elements: {
                  line: {
                    borderWidth: 1,
                  },
                  point: {
                    radius: 4,
                    hitRadius: 10,
                    hoverRadius: 4,
                  },
                },
              }}
            />
          }
        />
      </CCol>

      <CCol sm={6} lg={4}>
        <CWidgetStatsA
          className="mb-4"
          color="danger"
          value={
            <>
              {homeData?.data?.repeatUsers}{' '}
              <span className="fs-6 fw-normal">
                {/* (-23.6% <CIcon icon={cilArrowBottom} />) */}
              </span>
            </>
          }
          title="Repeat Users"
          action={
            <FaUserFriends className="float-end" style={{ fontSize: '50px' }} />
          }
          chart={
            <CChartBar
              className="mt-3 mx-3"
              style={{ height: '70px' }}
              data={{
                labels: chartLabel,
                datasets: [
                  {
                    label: 'Sub Admin',
                    backgroundColor: 'rgba(255,255,255,.2)',
                    borderColor: 'rgba(255,255,255,.55)',
                    data: [],
                    barPercentage: 0.6,
                  },
                ],
              }}
              options={{
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                scales: {
                  x: {
                    grid: {
                      display: false,
                      drawTicks: false,
                    },
                    ticks: {
                      display: false,
                    },
                  },
                  y: {
                    grid: {
                      display: false,
                      drawBorder: false,
                      drawTicks: false,
                    },
                    ticks: {
                      display: false,
                    },
                  },
                },
              }}
            />
          }
        />
      </CCol>

    </CRow>
  )
}

export default WidgetsDropdown
