import React, { useRef, useState } from 'react';
import { Formik } from 'formik';
import { useNavigate, Link } from 'react-router-dom'
import { post } from 'src/utils/apiManager';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CFormLabel,
  CImage,
  CRow,
} from '@coreui/react'
import { DefaultInput } from 'src/components/common/input'
import { validateUnit } from 'src/utils/validator'
function add() {
  const navigate = useNavigate()
  const formRef = useRef(null)
  const fileRef = useRef(null)
  const [isReset, setIsReset] = useState(true)
  const [invalidImage, setInvalidImage] = useState('')
  const [imageUrl, setimageUrl] = useState()
  const [unitImagePreview, setUnitImagePreview] = useState();
  const submit = async (values) => {
    let unitData = {
      title_EN: values?.title_EN,
      title_TH: values?.title_TH,
    }
    post(`admin/unit/add-unit`, unitData).then((data) => handleResponse(data))
  }
  const handleResponse = (data) => {
    if (data.status == true) {
      toast.success(data.message)
      setTimeout(() => {
        navigate('/admin/unit')
      }, 800)
    }
    else {
      toast.error(data.message)
    }
    formRef.current.resetForm()
  }
  const onClickReset = () => {
    console.log("this")
    console.log(this)
    // Reset Button Function
    // formRef.current.setFieldValue('vanNo', '')    
  };

  return (
    <>
      <CContainer >
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <CRow>
                        <CCol md="11">
                          <h1>New unit</h1>
                        </CCol>
                        <CCol md="1">
                          <Link className={'btn btn-success mt-2'} style={{ background: '#d3dc40', border: '#d3dc40' }} to={{ pathname: `/admin/unit` }}>
                            Back
                          </Link>
                        </CCol>
                      </CRow>
                      <Formik
                        initialValues={{
                          title_EN: '',
                          title_TH: '',
                          image: ''
                        }}
                        validationSchema={validateUnit}
                        onSubmit={(values, { setSubmitting }) => {
                          submit(values)
                          setSubmitting(false)

                        }}
                        innerRef={formRef}
                      >
                        {(props) => (
                          <form onSubmit={props?.handleSubmit}>
                            <CRow xs={12}>
                              <CCol md={6}>
                              <CFormLabel>Title (EN)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={"text"}
                                  placeholder={"Title (EN)"}
                                  value={props?.values?.title_EN}
                                  onChange={props?.handleChange("title_EN")}
                                  onBlur={() => props?.setFieldTouched("title_EN", true)}
                                  error={props?.touched?.title_EN && props?.errors?.title_EN}
                                />
                                <CFormLabel>Image</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'file'}
                                  placeholder={'Image'}
                                  accept="image/*"
                                  refr={fileRef}
                                  onChange={(e) => {
                                    const imageFile = e?.target?.files[0];
                                    if (!imageFile?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
                                      setInvalidImage("Please select valid image")
                                    }
                                    else {
                                      setInvalidImage('')
                                    }
                                    //for image preview
                                    const reader = new FileReader();
                                    reader?.addEventListener("load", () => setUnitImagePreview(reader?.result));
                                    reader?.readAsDataURL(e?.target?.files[0]);
                                    //for image preview
                                    props?.setFieldValue('image', e?.target?.files[0])
                                  }}
                                  onBlur={() => props?.setFieldTouched('image', true)}
                                  error={(props?.touched?.image && props?.errors?.image) || invalidImage}
                                />
                                {/* for image preview */}
                                {unitImagePreview?.length > 0 ?
                                  <>
                                    <CCol>
                                      <CImage
                                        rounded
                                        thumbnail
                                        className="mt-2"
                                        src={`${unitImagePreview}`}
                                        style={{
                                          maxHeight: '80px',
                                          maxWidth: '80px',
                                          alignItems: 'center',
                                        }}
                                      />
                                    </CCol>
                                  </>
                                  : ''}
                                {/* for image preview */}
                              </CCol>
                              <CCol md="6">
                              <CFormLabel>Tilte (TH)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={"text"}
                                  placeholder={"Tilte (TH)"}
                                  value={props?.values?.title_TH}
                                  onChange={props?.handleChange("title_TH")}
                                  onBlur={() => props?.setFieldTouched("title_TH", true)}
                                  error={props?.touched?.title_TH && props?.errors?.title_TH}
                                />
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol md="2">
                                <button
                                  type="submit"

                                  className={'btn btn-success mt-2'}
                                  disabled={props?.isSubmitting}
                                >
                                  Submit
                                </button>
                              </CCol>
                              <CCol md="2">
                                <button
                                  type="button"
                                  className={'btn btn-warning mt-2'}
                                  onClick={() => onClickReset(props?.isSubmitting)}
                                >
                                  Reset
                                </button>
                              </CCol>
                              <CCol md="3"></CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default add