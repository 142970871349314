import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { addDays, subDays } from "date-fns";
import { useState } from "react";

export default function DateRange({ state, setState, onChange, setFilterTrigger, filterTrigger }) {


  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    onChange(selection);
    setState([selection]);
  };

  const handleSubmit = () => {
    setFilterTrigger(!filterTrigger)

  };

  return (
    <div style={{ "position": "fixed", zIndex: "2221", right: "16px", top: "110px"   }}>
      <DateRangePicker
        onChange={handleOnChange}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={state}
        direction="horizontal"
      />
      <div style={{ "textAlign": "end", background: "white" }}>
        <button  className={'btn btn-success mt-2'} style={{ padding: "6px", margin: '12px 15px'}}  onClick={handleSubmit} >Filter</button>
      </div>
    </div>
  );
};
