import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { get, post, deleteApi } from '../../utils/apiManager'
import CIcon from '@coreui/icons-react'
import { cilPen, cilPlus, cilTrash } from '@coreui/icons'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CCardSubtitle,
  CTable,
  CRow,
  CImage,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CButton,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import { DefaultInputWithIcon } from 'src/components/common/input'
import Pagination from 'react-js-pagination'
import { FaRegEye, FaEdit, FaTrash, FaTrashAlt } from 'react-icons/fa'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { red } from '@mui/material/colors'
// import Lottie from 'lottie-react'
// import groovyWalkAnimation from "./groovyWalk.json"

export default function userIndex() {
  const [show, setShow] = useState(false)
  // const [users, setUsers] = useState(true);
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const navigate = useNavigate()
  const [userData, setUserData] = useState([])
  const [imageUrl, setimageUrl] = useState('')
  const [totalPages, settotalPages] = useState(0)
  const [deletedArray, setDeletedArray] = useState([])
  const [pageData, setpageData] = useState({
    page: 1,
    perPage: 10,
    searchItem: '',
  })
  const [sortItem, setSortItem] = useState('')
  const [searchItem, setsearchItem] = useState('')
  const getUserData = () => {
    get(
      `admin/User/user-list?page=${pageData?.page || 1}&perPage=${
        pageData?.perPage || 10
      }&sort=${sortItem}&searchItem=${pageData?.searchItem || ''}`,
    ).then(
      (response) => handleResponse(response),
      //  console.log(response)
    )
  }

  const handleSort = (field) => {
    if (!sortItem || sortItem.length === 0) {
      setSortItem(field)
    } else {
      if (sortItem === 'username' && field === 'username') {
        setSortItem('-username')
      } else if (sortItem === '-username' && field === 'username') {
        setSortItem('username')
      } else if (sortItem === 'email' && field === 'email') {
        setSortItem('-email')
      } else if (sortItem === '-email' && field === 'email') {
        setSortItem('email')
      } else if (sortItem === 'status' && field === 'status') {
        setSortItem('-status')
      } else if (sortItem === '-status' && field === 'status') {
        setSortItem('status')
      } else {
        setSortItem(field)
      }
    }
  }

  const handleResponse = (response) => {
    if (!response?.status) toast.success(response?.message, response?.status)
    else {
      // setTimeout(() => {
      //   setUsers(false);
      // }, 1500);
      setUserData([...response?.data])
      settotalPages(response?.pages)
      setimageUrl(response?.imageUrl)
    }
  }
  const changePage = (index) => {
    setpageData({
      ...pageData,
      page: index,
    })
  }
  const onSearchCLick = () => {
    setpageData({
      ...pageData,
      searchItem: searchItem,
    })
  }
  const statusChange = (status, id) => {
    // console.log(status,id)
    let default_status = ''
    if (status == 0) default_status = 'Active'
    else default_status = 'Inactive'
    if (confirm(`Are you sure you want to ${default_status} the user`)) {
      let formData = {
        _id: id,
      }
      post(`${status ? '/admin/User/inactive' : '/admin/User/active'}`, formData).then((data) => {
        handleStatusChange(data)
      })
    }
  }
  const handleStatusChange = (data) => {
    // console.log(data)
    toast.success(data?.message, data?.status)
    getUserData()
  }
  const onClickUserEdit = async (value) => {
    navigate('/admin/edit-user', { state: value })
  }
  const onClickUserView = (value) => {
    navigate(`/admin/user-detail/${btoa(value?._id)}`)
  }

  const deleteData = (id) => {
    if (confirm('Are you sure')) {
      let formData = {
        _id: id,
      }
      deleteApi(`/admin/User/delete?_id=${id}`).then((data) => {
        handleDelete(data)
      })
    }
  }
  const handleDelete = (data) => {
    toast.success(data?.message, data?.status)
    getUserData()
  }
  const addDefaultSrc = (ev) => {
    ev.target.src = 'img/no-image.png'
  }
  useEffect(() => {
    getUserData()
  }, [pageData, sortItem])

  const handleCheck = (id) => {
    let findValue = deletedArray.includes(id)
    if (findValue) {
      let filterData = deletedArray?.filter((item) => item != id)
      setDeletedArray(filterData)
    } else {
      setDeletedArray((prev) => [...prev, id])
    }
  }

  const deleteOrders = () => {
    post(`admin/User/bulk-delete`, { ids: deletedArray }).then((data) =>
      handleDeletedResponse(data),
    )
  }

  const handleDeletedResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      setDeletedArray([])
      getUserData()
    }
  }

  return (
    <>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <div className="d-flex" style={{ flexDirection: 'column' }}>
                    <CCardSubtitle as="h4">Users</CCardSubtitle>
                    {deletedArray.length > 0 && (
                      <button className="btn btn-danger mt-2 text-white" onClick={deleteOrders}>
                        Delete
                      </button>
                    )}
                  </div>
                  <CRow>
                    <CCol md="8">
                      <DefaultInputWithIcon
                        type={'text'}
                        placeholder="Search"
                        customType={'search'}
                        value={searchItem}
                        onClick={onSearchCLick}
                        onChange={(e) => {
                          onSearchCLick()
                          setsearchItem(e?.target?.value)
                        }}
                      />
                    </CCol>
                    {/* <CCol md="2"></CCol> */}
                    <CCol md="4">
                      <Link to={{ pathname: '/admin/add-user' }} className="btn btn-dark">
                        <CIcon icon={cilPlus} />
                        Add
                      </Link>
                    </CCol>
                  </CRow>
                </CHeader>
                {/* {users !== true ? ( */}
                <CCardBody>
                  <CTable bordered hover responsive>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell>S. No</CTableHeaderCell>
                        <CTableHeaderCell>Image</CTableHeaderCell>
                        <CTableHeaderCell onClick={() => handleSort('username')}>
                          Name <i class="fa-solid fa-arrows-up-down"></i>
                        </CTableHeaderCell>
                        <CTableHeaderCell
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleSort('email')}
                        >
                          Email <i class="fa-solid fa-arrows-up-down"></i>
                        </CTableHeaderCell>
                        <CTableHeaderCell
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleSort('status')}
                        >
                          Status <i class="fa-solid fa-arrows-up-down"></i>
                        </CTableHeaderCell>
                        <CTableHeaderCell
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleSort('status')}
                        >
                          User Verified <i class="fa-solid fa-arrows-up-down"></i>
                        </CTableHeaderCell>
                        <CTableHeaderCell>Action</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {userData &&
                        userData?.map((data, key) => {
                          // console.log(data, "user data ");
                          return (
                            <CTableRow key={key}>
                              <CTableDataCell onClick={() => handleCheck(data._id)}>
                                {pageData?.perPage * (pageData?.page - 1) + key + 1}
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    style={{ marginLeft: '2px', marginTop: '-20px' }}
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                    checked={deletedArray?.includes(data._id)}
                                    // onChange={() => handleCheck(data._id)}
                                  />
                                </div>
                              </CTableDataCell>
                              <CTableDataCell>
                                <CImage
                                  rounded
                                  src={`${imageUrl}${data?.image}`}
                                  // onError={addDefaultSrc}
                                  style={{
                                    maxHeight: '50px',
                                    maxWidth: '50px',
                                    borderRadius: 50,
                                    alignItems: 'center',
                                  }}
                                />
                              </CTableDataCell>
                              <CTableDataCell>{data?.username}</CTableDataCell>
                              <CTableDataCell>{data?.email}</CTableDataCell>
                              {/* {
                                  ele?.orderStatus == 'order_place' ?
                                  <CButton size="sm" style={{ backgroundColor: '#d3dc40', border: '#d3dc40' }} outline={ele?.orderStatus}>
                                    <span><strong>Order Place</strong></span>
                                  </CButton>:''
                                  } */}
                              <CTableDataCell>
                                <CButton
                                  size="sm"
                                  style={{
                                    backgroundColor: data?.status ? '#00796B' : '#D32F2F',
                                    borderColor: data?.status ? '#00796B' : '#D32F2F',
                                  }}
                                  outline={data?.status ? '1' : '0'}
                                  onClick={() => statusChange(data?.status, data?._id)}
                                >
                                  {data?.status ? 'Active' : 'Inactive'}
                                </CButton>
                              </CTableDataCell>
                              <CTableDataCell>
                                <b>
                                  <span
                                    className={data.otp == null && data?.emailVerify ? 'text-success' : 'text-danger'}
                                  >
                                    {data.otp == null && data?.emailVerify
                                      ? 'Verify'
                                      : 'Not Verify'}
                                  </span>
                                </b>
                              </CTableDataCell>
                              <CTableDataCell>
                                <span
                                  className="mx-2"
                                  onClick={() => onClickUserEdit(data)}
                                  style={{ fontSize: '20px', color: '#d3dc40', cursor: 'pointer' }}
                                >
                                  <FaEdit />
                                </span>
                                <span
                                  className="mx-2 text-danger"
                                  onClick={() => deleteData(data?._id)}
                                  style={{ fontSize: '20px', padding: '5px', cursor: 'pointer' }}
                                >
                                  <FaTrash />
                                </span>
                                <span
                                  className="mx-2"
                                  onClick={() => onClickUserView(data)}
                                  style={{
                                    fontSize: '20px',
                                    color: '#d3dc40',
                                    padding: '5px',
                                    cursor: 'pointer',
                                  }}
                                >
                                  <FaRegEye />
                                </span>
                              </CTableDataCell>
                            </CTableRow>
                          )
                        })}
                    </CTableBody>
                  </CTable>
                  {!userData?.length && (
                    <>
                      <h1>NO Data</h1>
                    </>
                  )}

                  {userData?.length ? (
                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={pageData?.page}
                      itemsCountPerPage={pageData?.perPage}
                      totalItemsCount={totalPages * 10}
                      pageRangeDisplayed={5}
                      onChange={changePage}
                    />
                  ) : (
                    ''
                  )}
                </CCardBody>
                {/* )
                   : (<div className="lodderClass" style={{ width: '50%', marginLeft: '20%' }}>

                     <Lottie animationData={groovyWalkAnimation} />
                   </div>)} */}
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
      {/* {userData && userData.map((data, key) => {
        console.log(data)
        console.log(data?.status)
        return(
                    <>
                      <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>Are you sure</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button style={{background:'#d3dc40',border:'#d3dc40'}} onClick={() =>Changestatus(data?.status, data?._id)}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
                    </>
                  )
                })} */}
    </>
  )
}
