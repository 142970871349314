import { CCard, CCardBody, CCardGroup, CCol, CContainer, CRow } from '@coreui/react'
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ImageUrl2, baseURL } from 'src/config/config'
import { get, put } from 'src/utils/apiManager'
import Barcode from 'src/assets/images/barcode-scan.png'
import ImagePreview from './previewImage'
import ItemImagePreview from './itemImagePreview'
import { useDispatch } from 'react-redux'
import { clearGarmentData } from 'src/reduxToolkit/slice/garmentShorting'
import ItemDetails from './itemDetails'
import Itemdetail from '../placedOrder/Itemdetail'

const EditBagDetails = () => {
  const { bagId, itemId } = useParams()
  const [bagDetail, setBagDetail] = useState(null)
  const [itemDetail, setItemDetails] = useState(null)
  const [items, setItems] = useState([])
  const [orderId, setOrderId] = useState([])
  const [selectedItem, setSelectedItem] = useState('')
  const [barcodeValue, setBarcodeValue] = useState('')
  const [selectedBarCode, setSelectedBarCode] = useState('')
  const [visible, setVisible] = useState(false)
  const [visiblePreview, setVisiblePreview] = useState(false)
  const inputRef = useRef()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  console.log('bagId', bagId)
  useEffect(() => {
    get(`/admin/offline-order/bag-item-details/${bagId}/${itemId}`).then((data) => {
      if (data.status) {
        setItemDetails({ ...data.data[0] })
      }
    })
  }, [itemId, bagId])

  useEffect(() => {
    get(`/admin/offline-order/get-order-bag/${bagId}`).then((data) => {
      if (data.status) {
        setBagDetail(data.data[0])
        setItems([...data.data[0].item])
        setOrderId(data.data[0].orders[0]._id)
        getItemDetail(data.data[0].item[0].itemCode)
      }
    })
  }, [bagId])

  const getItemDetail = (itemCode) => {
    get(`/admin/placed-order/item-details?itemCode=${itemCode}`).then((data) => {
      if (data.status) {
        setSelectedItem(data.data._id)
        setItemDetails({ ...data.data })
      }
    })
  }

  const handleItemClick = (id) => {
    get(`/admin/offline-order/bag-item-details/${bagId}/${itemId}`).then((data) => {
      if (data.status) {
        setItemDetails({ ...data.data[0] })
      }
    })
    setSelectedItem(id)
    navigate(`/admin/offline-order/bag-item/${bagId}/${id}`)
  }

  const changeStatusButton = () => {
    setSelectedItem('')
  }

  const handleItem = (action) => {
    put(`/admin/offline-order/bag-item-details/${bagId}/${itemId}`, { action }).then((data) => {
      if (data?.status) {
        get(`/admin/offline-order/get-order-bag/${bagId}`).then((res) => {
          if (res?.status) {
            console.log('response ------>>>>', res)
            setItems([...res.data[0].item])
            setOrderId(res.data[0].orders[0]._id)
            if (action == 'remove') {
              navigate('/admin/offline-order')
              dispatch(clearGarmentData())
            }
          }
        })
        get(`/admin/offline-order/bag-item-details/${bagId}/${itemId}`).then((data) => {
          if (data.status) {
            setItemDetails({ ...data.data[0] })
          }
        })
      }
    })
  }

  const handleKeyPress = async (event) => {
    if (event?.charCode === 13 && barcodeValue?.length > 0) {
      const { data } = await get(`admin/offline-order/get-items/${bagId}/${barcodeValue}`)
      if (data) {
        setItemDetails(data)
        setSelectedItem(data.item.itemId)
      } else {
        navigate(`/admin/offline-order/bag/add-item/${bagId}/${barcodeValue}`)
      }
    }
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <CCard style={{ flex: '0.2' }}>
        <CRow className="justify-content-center" style={{ padding: '0px 2px' }}>
          <CCol
            xs={12}
            style={{
              width: '100%',
              padding: '10px 30px',
            }}
          >
            Name : {itemDetail?.user?.username} <br />
            Service Name : {itemDetail?.service?.serviceName_EN} <br />
            Order Id : {itemDetail?.order?.orderId} <br />
            Item Code : {itemDetail?.itemCode} <br />
          </CCol>
          <CCol xs={11}>
            <hr />
            {items.length > 0 &&
              items.map((_item, index) => (
                <>
                  <CRow>
                    <CCol
                      xs={12}
                      className={selectedItem === _item._id && 'alert alert-success'}
                      style={{
                        borderBottom: '1px solid #ccc',
                        width: '100%',
                        padding: '8px 5px',
                        cursor: 'pointer',
                      }}
                      onClick={() => getItemDetail(_item.itemCode)}
                    >
                      Item {index + 1}
                    </CCol>
                  </CRow>
                </>
              ))}

            {selectedItem ? (
              <button
                style={{
                  marginTop: '20px',
                  width: '100%',
                  background: '#d3dc40',
                  border: '#d3dc40',
                  padding: '10px 0',
                }}
                onClick={() => changeStatusButton()}
              >
                Scan QR
              </button>
            ) : (
              <button
                style={{
                  marginTop: '20px',
                  width: '100%',
                  background: '#d3dc40',
                  border: '#d3dc40',
                  padding: '10px 0',
                }}
              // onClick={() => handleBag()}
              >
                Complete bag
              </button>
            )}
          </CCol>
        </CRow>
      </CCard>
      {selectedItem ? (
        <CCard style={{ flex: '0.6' }}>
          <CCardGroup>
            <CCardBody>
              {itemDetail && Object.keys(itemDetail)?.length > 0 ? (
                <>
                  <h4 className="mb-3">Item summary page </h4>
                  <hr />
                  <CRow className="justify-content-center mb-2 progress-list">
                    <CCol
                      xs={2}
                      className="align-items-center"
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <div style={{ position: 'relative' }}>
                        <div className="icon-box">
                          <div>
                            <i class="fa-solid fa-shirt"></i>
                          </div>
                          <div>{itemDetail?.itemId && <i class="fa-solid fa-check"></i>}</div>
                        </div>
                        <p style={{ textAlign: 'center', fontWeight: '600' }}>Type</p>
                      </div>
                      <div>{itemDetail?.itemId && itemDetail?.itemId?.itemName_EN}</div>
                    </CCol>
                    <CCol
                      xs={2}
                      className="align-items-center"
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <div style={{ position: 'relative' }}>
                        <div className="icon-box">
                          <div>
                            <i class="fa-solid fa-shirt"></i>
                          </div>
                          <div>{itemDetail?.brand && <i class="fa-solid fa-check"></i>}</div>
                        </div>
                        <p style={{ textAlign: 'center', fontWeight: '600' }}>Brand</p>
                      </div>
                      <div>{itemDetail?.brand && itemDetail?.brand}</div>
                    </CCol>
                    <CCol
                      xs={2}
                      className="align-items-center"
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <div style={{ position: 'relative' }}>
                        <div className="icon-box">
                          <div>
                            <i class="fa-solid fa-shirt"></i>
                          </div>
                          <div>
                            {itemDetail?.colorIds?.length > 0 && <i class="fa-solid fa-check"></i>}
                          </div>
                        </div>
                        <p style={{ textAlign: 'center', fontWeight: '600' }}>Colors</p>
                      </div>
                      <CCol cs={6}>
                        {itemDetail?.colorIds?.length > 0 &&
                          itemDetail?.colorIds?.map((_color) => {
                            return (
                              <div
                                style={{
                                  height: '50px',
                                  width: '50px',
                                  borderRadius: '50%',
                                  // border: '1px solid black',
                                  backgroundColor: _color?.colorCode,
                                  cursor: 'pointer',
                                  boxShadow: '0 1px 3px rgb(0 0 0 / 36%)',
                                  margin: '4px',
                                }}
                              ></div>
                            )
                          })}
                      </CCol>
                    </CCol>
                    <CCol
                      xs={2}
                      className="align-items-center"
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <div style={{ position: 'relative' }}>
                        <div className="icon-box">
                          <div>
                            <i class="fa-solid fa-shirt"></i>
                          </div>
                          <div>
                            <i class="fa-solid fa-check"></i>
                          </div>
                        </div>
                        <p style={{ textAlign: 'center', fontWeight: '600' }}>Washing Programs</p>
                      </div>
                      <div>{itemDetail?.processes?.processName_EN}</div>
                    </CCol>
                    <CCol
                      xs={2}
                      className="align-items-center"
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <div style={{ position: 'relative' }}>
                        <div className="icon-box">
                          <div>
                            <i class="fa-solid fa-shirt"></i>
                          </div>
                          <div>
                            {itemDetail?.image?.length > 0 && <i class="fa-solid fa-check"></i>}
                          </div>
                        </div>
                        <p style={{ textAlign: 'center', fontWeight: '600' }}>Image</p>
                      </div>
                      {itemDetail?.picture && (
                        <>
                          <img
                            src={ImageUrl2 + itemDetail.picture}
                            style={{ cursor: 'pointer', width: '50%', height: '100px' }}
                            onClick={() => setVisible(!visible)}
                          // data-coreui-toggle="modal"
                          // data-coreui-target="#staticBackdrop"
                          />
                          <ImagePreview
                            visible={visible}
                            setVisible={setVisible}
                            image={ImageUrl2 + itemDetail.picture}
                          />
                        </>
                      )}
                    </CCol>
                    <CCol
                      xs={2}
                      className="align-items-center"
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <div style={{ position: 'relative' }}>
                        <div className="icon-box">
                          <div>
                            <i class="fa-solid fa-shirt"></i>
                          </div>
                          <div>{itemDetail.stain && <i class="fa-solid fa-check"></i>}</div>
                        </div>
                        <p style={{ textAlign: 'center', fontWeight: '600' }}>Stain</p>
                      </div>
                      <div>{itemDetail.stain}</div>
                      <p
                        onClick={() =>
                          itemDetail.image &&
                            itemDetail.image.length > 0 &&
                            !itemDetail.image.includes('')
                            ? setVisiblePreview(!visiblePreview)
                            : ''
                        }
                      >
                        {itemDetail.image &&
                          itemDetail.image.length > 0 &&
                          !itemDetail.image.includes('') ? (
                          <i class="fa-solid fa-images"></i>
                        ) : (
                          ''
                        )}
                      </p>
                      <ItemImagePreview
                        visiblePreview={visiblePreview}
                        setVisiblePreview={setVisiblePreview}
                        image={itemDetail.image}
                      />
                    </CCol>
                  </CRow>
                  <CRow>
                    <h5>Instructions:</h5>
                    <div>{itemDetail.description}</div>
                  </CRow>
                </>
              ) : (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '72vh',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img src={Barcode} alt="barcode" style={{ maxWidth: '200px' }} />
                    <h4 className="mt-3">Scan OR code for item</h4>
                    <input
                      autoFocus={true}
                      // style={{ border: 'none', outline: 'none', widht: '0', height: '0' }}
                      ref={inputRef}
                      name="barcodeValue"
                      id="barcodeValue"
                      value={barcodeValue}
                      onChange={(event) => setBarcodeValue(event.target.value)}
                      onKeyPress={handleKeyPress}
                    />
                  </div>
                </>
              )}
            </CCardBody>
          </CCardGroup>
        </CCard>
      ) : (
        <>
          {' '}
          <CCard style={{ flex: '0.6' }}>
            <CCardGroup>
              <CCardBody>
                {itemDetail &&
                  Object.keys(itemDetail)?.length > 0 &&
                  itemDetail?.item?.status === 'inactive' ? (
                  <>
                    <h4 className="mb-3">Item summary page</h4>
                    <hr />
                    <CRow className="justify-content-center mb-2 progress-list">
                      <CCol
                        xs={2}
                        className="align-items-center"
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <div style={{ position: 'relative' }}>
                          <div className="icon-box" onClick={() => handleEdit('itemType')}>
                            <div>
                              <i class="fa-solid fa-shirt"></i>
                            </div>
                            <div>{itemDetail.items && <i class="fa-solid fa-check"></i>}</div>
                          </div>
                          <p style={{ textAlign: 'center', fontWeight: '600' }}>Type</p>
                        </div>
                        <div>
                          {/* <img src={`${ImageUrl2}/${itemDetail.items.image}`} /> */}
                          {itemDetail.items && itemDetail.items.itemName_EN}
                        </div>
                      </CCol>
                      <CCol
                        xs={2}
                        className="align-items-center"
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <div style={{ position: 'relative' }}>
                          <div className="icon-box" onClick={() => handleEdit('brand')}>
                            <div>
                              <i class="fa-solid fa-shirt"></i>
                            </div>
                            <div>{itemDetail.item.brand && <i class="fa-solid fa-check"></i>}</div>
                          </div>
                          <p style={{ textAlign: 'center', fontWeight: '600' }}>Brand</p>
                        </div>
                        <div>{itemDetail.item.brand && itemDetail.item.brand}</div>
                      </CCol>
                      <CCol
                        xs={2}
                        className="align-items-center"
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <div style={{ position: 'relative' }}>
                          <div className="icon-box" onClick={() => handleEdit('color')}>
                            <div>
                              <i class="fa-solid fa-shirt"></i>
                            </div>
                            <div>
                              {itemDetail?.colors?.length > 0 && <i class="fa-solid fa-check"></i>}
                            </div>
                          </div>
                          <p style={{ textAlign: 'center', fontWeight: '600' }}>Colors</p>
                        </div>
                        <CCol cs={6}>
                          {itemDetail?.colors?.length > 0 &&
                            itemDetail?.colors?.map((_color) => {
                              return (
                                <div
                                  style={{
                                    height: '50px',
                                    width: '50px',
                                    borderRadius: '50%',
                                    // border: '1px solid black',
                                    backgroundColor: _color?.colorCode,
                                    cursor: 'pointer',
                                    boxShadow: '0 1px 3px rgb(0 0 0 / 36%)',
                                    margin: '4px',
                                  }}
                                ></div>
                              )
                            })}
                        </CCol>
                      </CCol>
                      <CCol
                        xs={2}
                        className="align-items-center"
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <div style={{ position: 'relative' }}>
                          <div className="icon-box" onClick={() => handleEdit('process')}>
                            <div>
                              <i class="fa-solid fa-shirt"></i>
                            </div>
                            <div>
                              <i class="fa-solid fa-check"></i>
                            </div>
                          </div>
                          <p style={{ textAlign: 'center', fontWeight: '600' }}>Washing Programs</p>
                        </div>
                        <div>{itemDetail?.processes?.processName_EN}</div>
                      </CCol>
                      <CCol
                        xs={2}
                        className="align-items-center"
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <div style={{ position: 'relative' }}>
                          <div className="icon-box" onClick={() => handleEdit('itemImage')}>
                            <div>
                              <i class="fa-solid fa-shirt"></i>
                            </div>
                            <div>
                              {itemDetail.item.picture && <i class="fa-solid fa-check"></i>}
                            </div>
                          </div>
                          <p style={{ textAlign: 'center', fontWeight: '600' }}>Image</p>
                        </div>
                        {itemDetail.item.picture && (
                          <>
                            <img
                              src={ImageUrl2 + itemDetail.item.picture}
                              style={{ cursor: 'pointer', width: '50%', height: '100px' }}
                              onClick={() => setVisible(!visible)}
                            // data-coreui-toggle="modal"
                            // data-coreui-target="#staticBackdrop"
                            />
                            <ImagePreview
                              visible={visible}
                              setVisible={setVisible}
                              image={ImageUrl2 + itemDetail.item.picture}
                            />
                          </>
                        )}
                      </CCol>
                      <CCol
                        xs={2}
                        className="align-items-center"
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <div style={{ position: 'relative' }}>
                          <div className="icon-box" onClick={() => handleEdit('stain')}>
                            <div>
                              <i class="fa-solid fa-shirt"></i>
                            </div>
                            <div>{itemDetail.item.stain && <i class="fa-solid fa-check"></i>}</div>
                          </div>
                          <p style={{ textAlign: 'center', fontWeight: '600' }}>Stain</p>
                        </div>
                        <div>{itemDetail.item.stain}</div>
                        <p
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            itemDetail.item.image &&
                              itemDetail.item.image.length > 0 &&
                              !itemDetail.item.image.includes('')
                              ? setVisiblePreview(!visiblePreview)
                              : ''
                          }
                        >
                          {itemDetail.item.image &&
                            itemDetail.item.image.length > 0 &&
                            !itemDetail.item.image.includes('') ? (
                            <i class="fa-solid fa-images"></i>
                          ) : (
                            ''
                          )}
                        </p>
                        <ItemImagePreview
                          visiblePreview={visiblePreview}
                          setVisiblePreview={setVisiblePreview}
                          image={itemDetail.item.image}
                        />
                      </CCol>
                    </CRow>
                    <CRow>
                      <h5>Instructions:</h5>
                      <div>{itemDetail.item.description}</div>
                    </CRow>
                    {/* <CRow className="justify-content-center mt-2">
                  {itemDetail.item.image &&
                    itemDetail.item.image.length > 0 &&
                    itemDetail.item.image.map((_img, index) => (
                      <CCol xs={2}>
                        <div
                          style={{
                            border: '1px solid black',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            boxShadow: '1px 1px 3px black',
                            border: 'none',
                          }}
                        >
                          <img src={ImageUrl2 + _img} style={{ width: '100%', height: '150px' }} />
                        </div>
                      </CCol>
                    ))}
                </CRow> */}
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '72vh',
                        // overflowY: 'scroll',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <img src={Barcode} alt="barcode" style={{ maxWidth: '200px' }} />
                      <h4 className="mt-3">Scan OR code for item</h4>
                      <input
                        autoFocus={true}
                        // disabled
                        // hidden
                        // style={{ border: 'none', outline: 'none', widht: '0', height: '0' }}
                        ref={inputRef}
                        name="barcodeValue"
                        id="barcodeValue"
                        value={barcodeValue}
                        onChange={(event) => setBarcodeValue(event.target.value)}
                        onKeyPress={handleKeyPress}
                      // onClick={console.log('running')}
                      />
                    </div>
                  </>
                )}
                {itemDetail &&
                  Object.keys(itemDetail)?.length > 0 &&
                  itemDetail?.item?.status === 'inactive' && (
                    <CRow className="justify-content-between p-3">
                      <CCol xs={3} className="justify-content-end">
                        <button
                          style={{
                            marginTop: '20px',
                            width: '100%',
                            background: '#d3dc40',
                            border: '#d3dc40',
                            padding: '10px 0',
                          }}
                          onClick={() => handleItem('remove')}
                        >
                          Draft Item
                        </button>
                      </CCol>
                      <CCol xs={2} className="justify-content-end">
                        <button
                          style={{
                            marginTop: '20px',
                            width: '100%',
                            background: '#d3dc40',
                            border: '#d3dc40',
                            padding: '10px 0',
                          }}
                          onClick={() => handleItem('add')}
                        >
                          Add Item
                        </button>
                      </CCol>
                    </CRow>
                  )}
              </CCardBody>
            </CCardGroup>
          </CCard>
        </>
      )}
    </div>
  )
}

export default EditBagDetails
