import { createSlice } from '@reduxjs/toolkit'

const sliderShowSlice = createSlice({
  initialState: {
    sidebarShow: true,
    filterShow: false
  },
  name: 'sidebarShow',
  reducers: {
    // changeState: (state,action) => {
    //     console.log('action.payload', state)

    // state.sidebarShow = action.payload.sidebarShow
    // console.log(state.sidebarShow);

    // switch (state.sidebarShow.type){
    //     case 'set': return {...state, rest }
    //     default: return state
    // }

    // },

    toggleVisible: (state) => {
      state.sidebarShow = true
      // console.log("state.toggle 1",state.sidebarShow)
    },
    toggleInvisible: (state) => {
      // console.log('state....', state)
      state.sidebarShow = false
      // console.log("state.toggle 2",state.sidebarShow)
    },
    toggleFilterVisible: (state) => {
      // console.log('state....', state)
      state.filterShow =  true
      // console.log("state.toggle 2",state.sidebarShow)
    },
    toggleFilterInvisible: (state) => {
      // console.log('state....', state)
      state.filterShow =  false
      // console.log("state.toggle 2",state.sidebarShow)
    },

  }
});

// export const { changeState } = sliderShowSlice.actions;
export const { toggleVisible, toggleInvisible, toggleFilterVisible, toggleFilterInvisible } = sliderShowSlice.actions;
export default sliderShowSlice.reducer


