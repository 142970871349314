import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { baseURL } from 'src/config/config';
import { post } from "../../../utils/apiManager";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilBurger, cilEnvelopeClosed, cilLockLocked, cilUser } from '@coreui/icons'
import { useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'

import { useDispatch, useSelector } from "react-redux";
import { LoginDetails } from 'src/reduxToolkit/slice/auth';
import logo from 'src/assets/images/logo.png'

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { register, handleSubmit, formState: { errors: errors }, reset } = useForm();
  const [passVisibility, setPassVisibility] = useState(false)
  const [newPasswordType, setNewPasswordType] = useState("password")

  useEffect(() => {
    if (passVisibility) {
      setNewPasswordType("text")
    } else {
      setNewPasswordType("password")
    }
  }, [passVisibility]);

  const onClickLogin = (value) => {

    const loginUrl = `admin/login`;
    const data = { email: value?.email, password: value?.password }
    post(loginUrl, data).then((data) => handleLogin(data));
    const handleLogin = (data) => {
      console.log(data)
      //   ShowToast(data?.message, data?.status);
      if (data?.status) {
        toast.success(data?.message);
        // data store by the redux
        dispatch(LoginDetails(
          { token: data?.token, userId: data?.data?._id, email: data?.data?.email, role: data?.data?.role }
        ))
        // navigate('/admin/dashboard')
      }
      else {
        toast.error(data?.message)
        reset({
          email: '',
          password: '',
        })
      }
    };
  }
  return (
    <>
      <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md={6}>
              <CCardGroup>
                <CCard className="p-4">
                  <CCardBody>
                    <ToastContainer
                      position={toast.POSITION.TOP_RIGHT}
                      autoClose={2000}
                    />
                    <CForm onSubmit={handleSubmit(onClickLogin)}>
                      <CRow>
                        <CCol>
                          <h1>Login</h1>
                          <p className="text-medium-emphasis">Sign In to your account</p>
                        </CCol>
                        <CCol md={2}><img src={logo} alt="LOGO" /></CCol>
                      </CRow>
                      <CFormLabel>Email</CFormLabel>
                      <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                      <CInputGroup className="mb-3">
                        <CInputGroupText>
                          <CIcon icon={cilEnvelopeClosed} />
                        </CInputGroupText>
                        <CFormInput placeholder="Email" autoComplete="email" name="email" {...register("email", {
                          required: "Enter Email Address",
                          pattern: {
                            value:
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Invalid Email Type ",
                          },
                        })} />
                      </CInputGroup>
                      <div className='text-danger mb-2' style={{ marginTop: '-10px' }}><ErrorMessage errors={errors} name="email" /></div>
                      <CFormLabel>Password</CFormLabel>
                      <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                      <CInputGroup className="mb-4">
                        <CInputGroupText>
                          <CIcon icon={cilLockLocked} />
                        </CInputGroupText>
                        <CFormInput
                          type={newPasswordType}
                          placeholder="Password"
                          autoComplete="current-password"
                          name="password" {...register('password',
                            {
                              required: 'Password is required.',
                              // pattern:  /^(?=.*[A-Z].*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8}$/
                            })}
                        />
                        <CInputGroupText style={{ cursor: 'pointer' }} onClick={() => setPassVisibility(!passVisibility)}>
                          {newPasswordType == "password" ?
                            <i className="fa-solid fa-eye-slash"></i> : <i className="fa-solid fa-eye"></i>}
                        </CInputGroupText>
                      </CInputGroup>
                      <div className='text-danger mb-2' style={{ marginTop: '-20px' }}><ErrorMessage errors={errors} name="password" /></div>
                      <CRow>
                        <CCol xs={8}>
                          <CButton type="submit" color="primary" className="px-4 mt-2" >
                            Login
                          </CButton>
                        </CCol>
                        <CCol xs={4} className="text-right">
                          <Link to="/admin/forgot-password">
                            <CButton color="link" className="px-0">
                              Forgot password?
                            </CButton>
                          </Link>
                        </CCol>
                      </CRow>
                    </CForm>

                  </CCardBody>

                </CCard>
                {/* <CCard className="text-white bg-primary py-5" style={{ width: '44%' }}>
                  <CCardBody className="text-center">
                  <div>
                    <h2>Sign In</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                      tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                    <Link to="/admin/register">
                      <CButton color="primary" className="mt-3" active tabIndex={-1}>
                        Register Now!
                      </CButton>
                    </Link>
                  </div>
                </CCardBody>
                </CCard> */}
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </>
  )
}

