import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilPuzzle,
  cilSpeedometer,
  cilUser,
  cilSettings,
  cilGlobeAlt,
  cilImage,
  cilLocationPin,
  cilAddressBook,
  cilChatBubble,
  cilCarAlt,
  cilUserFemale,
  cilPhone,
  cilWallet,
  cilEnvelopeLetter,
  cilQrCode,
  cilThumbUp,
  cilThumbDown,
  cilClipboard,
  cilNotes,
  cilApplicationsSettings,
  cilOptions,
  cilEnvelopeOpen,
  cilAvTimer,
  cilUserFollow,
  cilList,
  cilBalanceScale,
  cilHistory,
  cilBell,
  cilTags,
  cibComposer,
  cilCart,
  cilBellExclamation,
  cilPeople,
  cilColorFill,
  cilPlus,
  cilGift,
  cilCommentBubble,
  cilBook,
  cilFeaturedPlaylist,
  cilTruck,
  cilScreenSmartphone,
  cilCheckCircle,
  cilCalendarCheck,
  cilReportSlash,
  cilSearch,
  cilWarning,
  cilCreditCard,
  cilPaint,
  cilNoteAdd,
  cilInbox,
  cilLibrary,
  cilMoney,
} from '@coreui/icons'
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react'
import { FaRegEye, FaEdit, FaTrash } from 'react-icons/fa'

const _nav = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/admin/dashboard',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      text: 'NEW',
    },
  },
  //Rearrange Module According To FSD
  // {
  //   component: CNavTitle,
  //   name: 'Rearrange Module According To FSD',
  // },
  {
    component: CNavGroup,
    name: 'Users Account Management',
    to: '/admin/users-account',
    icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'End Users',
        to: '/admin/user',
      },
      {
        component: CNavItem,
        name: 'Van Drivers',
        to: '/admin/driver',
      },
      {
        component: CNavItem,
        name: 'Sub-Admin',
        to: '/admin/sub-admin',
      },
    ],
  },
  // {
  //   component: CNavGroup,
  //   name: 'Order Processing',
  //   to: '/admin/order-processing',
  //   icon: <CIcon icon={cilTags} customClassName="nav-icon" />,
  //   items: [
  //   ]
  // },
  {
    component: CNavGroup,
    name: 'Orders Management',
    to: '/admin/orders-management',
    icon: <CIcon icon={cilCart} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'All Orders',
        to: '/admin/order/all-orders',
      },
      // {
      //   component: CNavItem,
      //   name: 'New Orders',
      //   to: '/admin/order/new-orders',
      // },
      // {
      //   component: CNavItem,
      //   name: 'Orders to Pick-up',
      //   to: '/admin/order/pickup-orders',
      // },
      // {
      //   component: CNavItem,
      //   name: 'In Process Orders',
      //   to: '/admin/order/inprocess-orders',
      // },

      // {
      //   component: CNavItem,
      //   name: 'Orders Ready to Deliver',
      //   to: '/admin/order/ready-to-delivery',
      // },
      // {
      //   component: CNavItem,
      //   name: 'Completed Orders',
      //   to: '/admin/order/deliverd',
      // },
      // {
      //   component: CNavItem,
      //   name: 'Orders on Hold',
      //   to: '/admin/order/hold',
      // },
      // {
      //   component: CNavGroup,
      //   name: 'Cancelled Orders List',
      //   to: '/admin/order/rejected-list',
      //   items: [
      //     {
      //       component: CNavItem,
      //       name: 'Pickup Rejected List',
      //       to: '/admin/order/pickup-rejected-list',
      //     },
      //     {
      //       component: CNavItem,
      //       name: 'Deliver Rejected List',
      //       to: '/admin/order/deliver-rejected-list',
      //     },
      //     {
      //       component: CNavItem,
      //       name: 'Customer Rejected List',
      //       to: '/admin/order/customer-rejected-list',
      //     },
      //   ],
      // },
      // {
      //   component: CNavGroup,
      //   name: 'Cancelled Orders Request',
      //   to: '/admin/order/rejected',
      //   items: [
      //     {
      //       component: CNavItem,
      //       name: 'Pickup Rejected Request',
      //       to: '/admin/order/pickup-rejected',
      //     },
      //     {
      //       component: CNavItem,
      //       name: 'Deliver Rejected Request',
      //       to: '/admin/order/deliver-rejected',
      //     },
      //     {
      //       component: CNavItem,
      //       name: 'Customer Rejected Request',
      //       to: '/admin/order/customer-rejected',
      //     },
      //   ],
      // },
    ],
  },
  {
    component: CNavGroup,
    name: 'Delivery Service Management',
    to: '/admin/delivery-sevice',
    icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Manage Service Area',
        to: '/admin/working-area',
        // icon: <CIcon icon={cilUser} customClassName="nav-icon" />
      },
      {
        component: CNavItem,
        name: 'Manage Service Zone',
        to: '/admin/area',
        // icon: <CIcon icon={cilUser} customClassName="nav-icon" />
      },
      // {
      //   component: CNavItem,
      //   name: 'Service Timing',
      //   to: '/admin/all-orders',
      //   // icon: <CIcon icon={cilUser} customClassName="nav-icon" />
      // },
      {
        component: CNavItem,
        name: 'Manage Vans',
        to: '/admin/van',
        // icon: <CIcon icon={cilUser} customClassName="nav-icon" />
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Bulk Order QR Code',
    to: '/admin/bulk-order-QR',
    icon: <CIcon icon={cilQrCode} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Bulk QR code',
        to: '/admin/bulk-qr-code',
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Services & Pricing Management',
    to: '/admin/service-pricing',
    icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Main Service Categories',
        to: '/admin/services',
        // icon: <CIcon icon={cilUser} customClassName="nav-icon" />
      },
      {
        component: CNavGroup,
        name: 'Item Management',
        to: '/admin/items',
        // icon: <CIcon icon={cilList} customClassName="nav-icon" />,
        items: [
          {
            component: CNavItem,
            name: 'Item Categories',
            to: '/admin/item/item-category',
            // icon: <CIcon icon={cilCart} customClassName="nav-icon" />,
          },
          {
            component: CNavItem,
            name: 'Item Size',
            to: '/admin/item/item-size',
            // icon: <CIcon icon={cilTags} customClassName="nav-icon" />,
          },
          {
            component: CNavItem,
            name: 'Items',
            to: '/admin/items',
            // icon: <CIcon icon={cibComposer} customClassName="nav-icon" />,
          },
        ],
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Service Packages Management',
    to: '/admin/service-package',
    icon: <CIcon icon={cilLibrary} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Service Packages',
        to: '/admin/subscription-plan/package',
        // icon: <CIcon icon={cilLibrary} customClassName="nav-icon" />
      },
      {
        component: CNavItem,
        name: 'Regular',
        to: '/admin/subscription-plan/regular',
        // icon: <CIcon icon={cilBellExclamation} customClassName="nav-icon" />,
      },
      // {
      //   component: CNavItem,
      //   name: 'Add Plan',
      //   to: '/admin/all-orders',
      //   // icon: <CIcon icon={cilUser} customClassName="nav-icon" />
      // },
    ],
  },
  // {
  //   component: `CNavGroup`,
  //   name: 'Payment Management',
  //   to: '/admin/payment',
  //   icon: <CIcon icon={cilCreditCard} customClassName="nav-icon" />,
  //   items: [
  //   ]
  // },
  {
    component: CNavGroup,
    // name: 'Luggage Bar QR Code',
    name:"QR pickup bag",
    to: '/admin/luggage-QR',
    icon: <CIcon icon={cilQrCode} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'QR code Generation',
        to: '/admin/QR-code-genration',
      },
      {
        component: CNavItem,
        name: 'QR code assignment',
        to: '/admin/assign-QR',
      },
      // {
      //   component: CNavItem,
      //   name: 'Existing Codes',
      //   to: '/admin/all-orders',
      // },
    ],
  },
  // {
  //   component: CNavItem,
  //   name: 'Washing Process Management',
  //   to: '/admin/wash-process',
  //   icon: <CIcon icon={cilGift} customClassName="nav-icon" />,
  // },
  {
    component: CNavItem,
    name: 'Offer Management',
    to: '/admin/offer',
    icon: <CIcon icon={cilGift} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Payment Management',
    to: '/admin/payment',
    icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
  },
  {
    component: CNavGroup,
    name: 'Manage Contents',
    to: '/admin/manage-contents',
    icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
    items: [
      {
        component: CNavGroup,
        name: 'Informational content management',
        to: '/admin/Information-content',
        items: [
          {
            component: CNavItem,
            name: 'Front Slider ',
            to: '/admin/slider',
            // icon: <CIcon icon={cilFeaturedPlaylist} customClassName="nav-icon" />,
          },
          {
            component: CNavItem,
            name: 'Our Partners',
            to: '/admin/our-partners',
            // icon: <CIcon icon={cilUserFollow} customClassName="nav-icon" />,
          },
          {
            component: CNavItem,
            name: 'FAQ Management ',
            to: '/admin/faq',
            // icon: <CIcon icon={cilChatBubble} customClassName="nav-icon" />,
          },
        ],
      },
      {
        component: CNavItem,
        name: 'Blogs Management',
        to: '/admin/blog',
        // icon: <CIcon icon={cilUser} customClassName="nav-icon" />
      },
    ],
  },
  {
    component: CNavItem,
    name: 'Template Management ',
    to: '/admin/template',
    icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Safe Description',
    to: '/admin/safeDescription',
    icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
  },
  {
    component: CNavGroup,
    name: 'Reports & Statistics',
    to: '/admin/reports',
    icon: <CIcon icon={cilClipboard} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Users Report',
        to: '/admin/users-report',
      },
      {
        component: CNavItem,
        name: 'Orders Report',
        to: '/admin/orders-report',
      },
      // {
      //   component: CNavItem,
      //   name: 'Coupons Report',
      //   to: '/admin/coupons-report',
      //   icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,
      // },
      // {
      //   component: CNavItem,
      //   name: 'Reffral-Login Report',
      //   to: '/admin/reffral-login-report',
      // },
      // {
      //   component: CNavItem,
      //   name: 'Orders Report',
      //   to: '/admin/orders-report',
      //   icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,
      // },
    ],
  },
  {
    component: CNavGroup,
    name: 'Content Management Module',
    to: '/admin/content-management',
    icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'AboutUs Management ',
        to: '/admin/about-us',
        // icon: <CIcon icon={cilCommentBubble} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: 'Cms Management ',
        to: '/admin/cms-pages',
        // icon: <CIcon icon={cilImage} customClassName="nav-icon" />,
      },
    ],
  },

  {
    component: CNavItem,
    name: 'Check In',
    to: '/admin/offline-order',
    icon: <CIcon icon={cilCart} customClassName="nav-icon" />,
  },
   {
    component: CNavItem,
    name: 'Washing Process Management',
    to: '/admin/wash-process',
    icon: <CIcon icon={cilGift} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'In Process',
    to: '/admin/offline-orders',
    icon: <CIcon icon={cilCart} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Search',
    to: '/admin/search',
    icon: <CIcon icon={cilCart} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Check Out',
    to: '/admin/checkout-orders',
    icon: <CIcon icon={cilCart} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Item Color Management ',
    to: '/admin/colors',
    icon: <CIcon icon={cilCart} customClassName="nav-icon" />,
  },
  //Rearrange Module According To FSD
  {
    component: CNavGroup,
    name: 'Application Management',
    to: '/admin/application',
    icon: <CIcon icon={cilApplicationsSettings} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Intro Screen ',
        to: '/admin/intro',
        // icon: <CIcon icon={cilScreenSmartphone} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: 'Home Slider',
        to: '/admin/appHomeSlider',
        // icon: <CIcon icon={cilImage} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: 'Home About Us',
        to: '/admin/appHomeAboutUs',
        // icon: <CIcon icon={cilCommentBubble} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: 'Help',
        to: '/admin/help',
        // icon: <CIcon icon={cilUserFollow} customClassName="nav-icon" />,
      },
    ],
  },
  {
    component: CNavItem,
    name: 'Service Process',
    to: '/admin/service-process',
    icon: <CIcon icon={cilGift} customClassName="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: 'Extras',
  },
  {
    component: CNavGroup,
    name: 'Setting Management',
    to: '/admin/setting',
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Global Settings',
        to: '/admin/global-setting',
        icon: <CIcon icon={cilGlobeAlt} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: 'Social Settings',
        to: '/admin/social-setting',
        icon: <CIcon icon={cilApplicationsSettings} customClassName="nav-icon" />,
      },
      // {
      //   component: CNavItem,
      //   name: 'Attribute',
      //   to: '/admin/attribute',
      //   icon: <CIcon icon={cilApplicationsSettings} customClassName="nav-icon" />,
      // },
      {
        component: CNavItem,
        name: 'Option',
        to: '/admin/option',
        icon: <CIcon icon={cilOptions} customClassName="nav-icon" />,
      },
    ],
  },
  {
    component: CNavItem,
    name: 'Add On Service',
    to: '/admin/add-on-service',
    icon: <CIcon icon={cilPlus} customClassName="nav-icon" />,
  },
  // {
  //   component: CNavItem,
  //   name: 'Unit Management',
  //   to: '/admin/unit',
  //   icon: <CIcon icon={cilBalanceScale} customClassName="nav-icon" />,
  // },
  {
    component: CNavItem,
    name: 'Packages Duration',
    to: '/admin/package-duration',
    // icon: <FaHourglassHalf />,
    icon: <CIcon icon={cilHistory} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Enquiry Management ',
    to: '/admin/enquiry',
    icon: <CIcon icon={cilPhone} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Address Management ',
    to: '/admin/address',
    icon: <CIcon icon={cilLocationPin} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Order Note',
    to: '/admin/order-note',
    icon: <CIcon icon={cilBook} customClassName="nav-icon" />,
    // icon: <FaEdit />,
  },
  {
    component: CNavItem,
    name: 'Rate And Review',
    to: '/admin/rate-and-review',
    icon: <CIcon icon={cilThumbUp} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Raise An Issue',
    to: '/admin/raise-an-issue',
    icon: <CIcon icon={cilThumbDown} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Reasons',
    to: '/admin/reasons',
    icon: <CIcon icon={cilReportSlash} customClassName="nav-icon" />,
  },
  // {
  //   component: CNavGroup,
  //   name: 'Notifications Management',
  //   to: '/admin/user-list',
  //   icon: <CIcon icon={cilBell} customClassName="nav-icon" />,
  //   items: [
  //   ]
  // },
  //Rearrange Module According To FSD
]
export default _nav
