import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CCardSubtitle,
  CRow,
  CButton,
} from '@coreui/react'
import { FaArrowLeft } from 'react-icons/fa'

function faqDetail() {
    const { state } = useLocation()
    // console.log(state)
  return (
    <>
        <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <CRow>
                    <CCol >
                      {' '}
                      <Link style={{ color: '#000' }} to={{ pathname: `/admin/faq` }}>
                        <FaArrowLeft />
                      </Link>
                      <span> FAQ Information</span>
                    </CCol>
                  </CRow>
                </CHeader>
                <CCardBody>
                  <CRow>
                    <CCol md={2}>
                      <strong>Question (EN):-</strong>
                    </CCol>
                    <CCol>
                      <span>{state?.question_EN}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Question (TH):-</strong>
                    </CCol>
                    <CCol>
                      <span>{state?.question_TH}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Answer (EN):-</strong>
                    </CCol>
                    <CCol>
                      <span>{state?.answer_EN}</span>
                    </CCol>
                  </CRow>                 
                  <CRow>
                    <CCol md={2}>
                      <strong>Answer (TH):-</strong>
                    </CCol>
                    <CCol>
                      <span>{state?.answer_TH}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Status:-</strong>
                    </CCol>
                    <CCol>
                    <CButton
                              size="sm"
                              outline={state?.status ? "1" : "0"}
                            >
                              {state?.status ? "Active" : "Inactive"}
                            </CButton>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default faqDetail