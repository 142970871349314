import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { get, post, deleteApi } from '../../utils/apiManager'
import CIcon from '@coreui/icons-react'
import { cibElectron, cilPen, cilPlus, cilTrash } from '@coreui/icons'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CCardSubtitle,
  CTable,
  CRow,
  CImage,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CButton,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import { DefaultInputWithIcon } from 'src/components/common/input'
import Pagination from 'react-js-pagination'
import {
  FaRegEye,
  FaEdit,
  FaTrash,
  FaTrashAlt,
  FaPrint,
  FaSearchengin,
  FaTruckMoving,
  FaRegAddressCard,
  FaShoppingCart,
} from 'react-icons/fa'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

export default function OfflineOrderList() {
  const [show, setShow] = useState(false)
  // const [users, setUsers] = useState(true);
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const navigate = useNavigate()
  const [userData, setUserData] = useState([])
  const [orderData, setOrderData] = useState([])
  const [imageUrl, setimageUrl] = useState('')
  const [totalPages, settotalPages] = useState(0)
  const [pageData, setpageData] = useState({
    page: 1,
    perPage: 10,
    searchItem: '',
  })

  const { customerId } = useParams()

  const [searchItem, setsearchItem] = useState('')
  const getUserData = () => {
    get(
      `admin/offline-order/offline-order-list/${customerId}?page=${pageData?.page || 1}&perPage=${
        pageData?.perPage || 10
      }&searchItem=${pageData?.searchItem || ''}`,
    )
      .then(
        (response) => handleResponse(response),
        //  console.log(response)
      )
      .catch((error) => {
        consoel.log(error)
      })
  }
  const handleResponse = (response) => {
    if (!response?.status) toast.success(response?.message, response?.status)
    else {
      // setTimeout(() => {
      //   setUsers(false);
      // }, 1500);
      console.log(response?.data)
      setOrderData([...response?.data])
      settotalPages(response?.pages)
      setimageUrl(response?.imageUrl)
    }
  }
  const changePage = (index) => {
    setpageData({
      ...pageData,
      page: index,
    })
  }
  const onSearchCLick = () => {
    setpageData({
      ...pageData,
      searchItem: searchItem,
    })
  }
  const statusChange = (status, id) => {
    // console.log(status,id)
    let default_status = ''
    if (status == 0) default_status = 'Active'
    else default_status = 'Inactive'
    if (confirm(`Are you sure you want to ${default_status} the user`)) {
      let formData = {
        _id: id,
      }
      post(`${status ? '/admin/User/inactive' : '/admin/User/active'}`, formData).then((data) => {
        handleStatusChange(data)
      })
    }
  }
  const handleStatusChange = (data) => {
    // console.log(data)
    toast.success(data?.message, data?.status)
    getUserData()
  }
  const onClickUserEdit = async (value) => {
    navigate(`/admin/offline-order/edit`, { state: value })
    // navigate(`/admin/offline-order/edit/${value?._id}`, { state: value })
  }
  const onClickUserView = (value) => {
    navigate(`/admin/user-detail/${value?._id}`, { state: value })
  }

  const deleteData = (id) => {
    if (confirm('Are you sure')) {
      let formData = {
        _id: id,
      }
      deleteApi(`/admin/User/delete?_id=${id}`).then((data) => {
        handleDelete(data)
      })
    }
  }
  const handleDelete = (data) => {
    toast.success(data?.message, data?.status)
    getUserData()
  }
  const addDefaultSrc = (ev) => {
    ev.target.src = 'img/no-image.png'
  }
  useEffect(() => {
    getUserData()
  }, [pageData])

  // console.log({ orderData })
  return (
    <>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
      <CContainer>
        <CCard>
          <CCardGroup>
            <CCardBody>
              <CRow className="p-2 align-items-center">
                <CCol>
                  <h5 style={{ textTransform: 'uppercase' }}>Offline Orders</h5>
                </CCol>
                <CCol md="1">
                  <Link
                    className={'btn btn-success'}
                    style={{ background: '#d3dc40', border: '#d3dc40' }}
                    to={{ pathname: `/admin/offline-user-list` }}
                  >
                    Back
                  </Link>
                </CCol>
              </CRow>
              <hr />
              <CRow className="p-2 align-items-center">
                <CCol xs={2}>
                  <Link
                    to={`/admin/offline-order/add/${customerId}`}
                    style={{
                      textDecoration: 'none',
                      color: 'black',
                      fontSize: '55px',
                      fontWeight: 'lighter',
                      boxShadow: '1px 1px 3px black',
                    }}
                  >
                    <div
                      style={{
                        border: '1px solid black',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxShadow: '1px 1px 3px black',
                        border: 'none',
                      }}
                    >
                      +
                    </div>
                  </Link>
                </CCol>
                {orderData.length > 0 &&
                  orderData.map((data) => (
                    <CCol xs={2} key={data._id}>
                      <Link
                        to={`/admin/offline-order/bags/${data._id}`}
                        style={{
                          textDecoration: 'none',
                          color: 'black',
                        }}
                      >
                        <div
                          style={{
                            padding: '30px',
                            border: '1px solid black',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            boxShadow: '1px 1px 3px black',
                            border: 'none',
                          }}
                        >
                          {data.name}
                        </div>
                      </Link>
                    </CCol>
                  ))}
              </CRow>
            </CCardBody>
          </CCardGroup>
        </CCard>
      </CContainer>
      {/* {userData && userData.map((data, key) => {
        console.log(data)
        console.log(data?.status)
        return(
                    <>
                      <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>Are you sure</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button style={{background:'#d3dc40',border:'#d3dc40'}} onClick={() =>Changestatus(data?.status, data?._id)}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
                    </>
                  )
                })} */}
    </>
  )
}
