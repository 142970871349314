import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { get } from 'src/utils/apiManager';
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CRow
} from '@coreui/react'
import { FaArrowLeft } from 'react-icons/fa'

function OptionView() {
  const { id } = useParams()
  const [optionData, setOptionData] = useState();

  const getOptionDetails = () => {
    get(
      `/admin/option/get-single?_id=${atob(id)}`
    ).then((response) => {
      if (response?.status) {
        setOptionData(response?.data)
      }
    })

  }
  useEffect(() => {
    getOptionDetails();
  }, []);

  return (
    <>
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <CRow>
                    <CCol >
                      {' '}
                      <Link style={{ color: '#000' }} to={{ pathname: `/admin/option` }}>
                        <FaArrowLeft />
                      </Link>
                      <span>  Option Information</span>
                    </CCol>
                  </CRow>
                </CHeader>
                <CCardBody>
                  <CRow>
                    <CCol md={3}>
                      <strong>Brand Name (EN) :-</strong>
                    </CCol>
                    <CCol>
                      <span>{optionData?.attributeName_EN}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={3}>
                      <strong>Brand Name (TH) :-</strong>
                    </CCol>
                    <CCol>
                      <span>{optionData?.attributeName_TH}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={3}>
                      <strong>Option :-</strong>
                    </CCol>
                    <CCol>
                      {optionData?.option?.map((data, i) => {
                        return (
                          <>
                            <CCardBody>
                              <CRow>
                                <CCol md={3}>
                                  <strong>Option Name (EN):-</strong>
                                </CCol>
                                <CCol>
                                  <span>{data?.optionName_EN}</span>
                                </CCol>
                              </CRow>
                              <CRow>
                                <CCol md={3}>
                                  <strong>Option Name (EN) :-</strong>
                                </CCol>
                                <CCol>
                                  <span>{data?.optionName_TH}</span>
                                </CCol>
                              </CRow>
                            </CCardBody>
                          </>)
                      })
                      }
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default OptionView
