import React, { useEffect, useRef, useState } from 'react'
import { Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { post, get, put } from 'src/utils/apiManager'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Select from 'react-select'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CFormLabel,
  CRow,
  CImage,
  CButton,
} from '@coreui/react'
import { DefaultInput, DefaultMobileInput, DefaultTextarea } from 'src/components/common/input'
import { validateRegularPlan } from 'src/utils/validator'

function index(props) {
  const navigate = useNavigate()
  const formRef = useRef(null)
  const [iD, setID] = useState('')
  const [subscriptionType, setSubscriptionType] = useState('0')
  const [selectService, setSelectService] = useState([])
  const [services, setServices] = useState([])
  const [isDisable, setIsDisable] = useState(false)

  const getServices = () => {
    get(`admin/subscription/services`).then((data) => {
      let temp = []
      if (data?.data !== null) {
        data?.data?.map((item, key) => {
          temp?.push({ label: item?.serviceName_EN, value: item?._id })
        })
        setServices(temp)
      }
    })
  }

  useEffect(() => {
    getServices()
  }, [])

  useEffect(() => {
    if (services?.length > 0) {
      getRegSubscription()
    }
  }, [services])

  // after getting id for edit section
  const getRegSubscription = (data) => {
    get(`/admin/subscription/regular-plan-list`).then((response) => {
      // console.log(response)
      if (response?.status == true) {
        handleSingleData(response)
      }
    })
  }
  const handleSingleData = (response) => {
    let temp = services?.filter((it) => response?.data?.services?.includes(it?.value))

    setSelectService(temp)
    if (response?.data?._id) {
      setID(`${response?.data?._id}`)
    }
  }

  const submit = async (values) => {
    console.log("values", values.services.length)
    if (values.services.length > 0) {
      var SERVICE = []
      values.services.forEach((item, key) => {
        SERVICE.push(item?.value)
      })
      let data = {
        id: iD,
        subcribe_type: subscriptionType,
        services: SERVICE,
      }
      if (iD) {
        put(`admin/subscription/update-regular-plan`, data).then((response) => { handleResponse(response) })
      } else {
        post(`admin/subscription/add`, data).then((response) => { handleResponse(response) })
      }
    } else {
      toast.error("Fill the required fields")
    }

  }
  const handleResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      getRegSubscription()
    }
  }

  return (
    <>
      <CContainer>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <h1>Regular Plan</h1>
                      <Formik
                        initialValues={{
                          services: '',
                        }}
                        validationSchema={validateRegularPlan}
                        onSubmit={(values, { setSubmitting }) => {
                          submit(values)
                          setSubmitting(false)
                        }}
                        innerRef={formRef}
                      >
                        {(props) => (
                          <form onSubmit={props?.handleSubmit}>
                            {/* {console.log(props)} */}
                            <input type="hidden" name="subcribe_type" value={subscriptionType} />
                            <input type="hidden" name="hidden" value={iD} />

                            <CRow xs={12}>
                              <CCol md={6}>
                                <CFormLabel>Services</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>
                                <Select
                                  isMulti={true}
                                  defaultValue={selectService}
                                  onChange={(val) => {
                                    setSelectService(val)
                                    props?.setFieldValue('services', val)
                                  }}
                                  options={services}
                                  value={selectService}
                                  placeholder={'Select size'}
                                  name="Item Size"
                                />
                                {!selectService?.length ? (<span className="text-danger">Service is required</span>) : ('')}
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol md="2">

                                <button
                                  type="submit"
                                  className={'btn btn-success mt-2'}
                                  disabled={props?.isSubmitting}
                                >
                                  Submit
                                </button>
                              </CCol>
                              <CCol md="2">
                                <button
                                  type="button"
                                  className={'btn btn-warning mt-2'}
                                  onClick={() => navigate('/admin/dashboard')}
                                >
                                  Cancel
                                </button>
                              </CCol>
                              <CCol md="3"></CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default index
