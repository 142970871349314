// import { CCard, CCardBody, CCardGroup, CCol, CContainer, CFormLabel, CRow } from '@coreui/react'
// import { Formik } from 'formik'
// import React, { useEffect, useRef, useState } from 'react'
// import { Link, useNavigate, useParams } from 'react-router-dom'
// import { ToastContainer, toast } from 'react-toastify'
// import { DefaultInput, DefaultMobileInput, DefaultTextarea } from 'src/components/common/input'
// import { baseURL } from 'src/config/config'
// import { post, get } from 'src/utils/apiManager'
// import 'react-toastify/dist/ReactToastify.css'
// import Select from 'react-select'

// function OfflineOrderAdd() {
//   const navigate = useNavigate()
//   const formRef = useRef(null)
//   const fileRef = useRef(null)
//   const [invalidImage, setInvalidImage] = useState('')
//   const [userImagePreview, setUserImagePreview] = useState()
//   const [passVisibility, setPassVisibility] = useState(false)
//   const [passwordType, setPasswordType] = useState('password')
//   // const [stateOptions, setStateOptions] = useState([]);
//   const [stateID, setStateID] = useState(null)
//   const [cityID, setCityID] = useState(null)
//   const [areaOptions, setAreaOptions] = useState([])
//   const [areaList, setAreaList] = useState(null)
//   const [selectService, setSelectService] = useState(null)
//   const [addOnServiceList, setAddOnServiceList] = useState(null)
//   const addOnServiceOptions = []
//   const [imageFile, setImageFile] = useState(null)

//   const { customerId } = useParams()

//   useEffect(() => {
//     fetchAddOnServices()
//     fetchStateID()
//     if (stateID != null) {
//       fetchCityList()
//     }
//     if (cityID != null) {
//       fetchAreaList()
//     }
//   }, [stateID, cityID])

//   useEffect(() => {
//     if (passVisibility) {
//       setPasswordType('text')
//     } else {
//       setPasswordType('password')
//     }
//   }, [passVisibility])
//   //get the area
//   const fetchStateID = () => {
//     get(`/admin/address/state-list`).then((res) => {
//       if (res?.data !== null) {
//         res?.data?.map((item, key) => {
//           setStateID(item?._id)
//         })
//       }
//     })
//   }
//   const fetchCityList = () => {
//     const data = {
//       state_id: stateID,
//     }
//     post(`/admin/address/city-list`, data).then((res) => {
//       if (res?.data !== null) {
//         res?.data?.map((item, key) => {
//           setCityID(item?._id)
//         })
//       }
//     })
//   }
//   const fetchAreaList = () => {
//     const data = { city_id: cityID }
//     post(`/admin/address/area-list`, data).then((res) => {
//       if (res?.data !== null) {
//         const tempData = res?.data?.map((item, key) => {
//           areaOptions?.push({ label: item?.areaName_EN, value: item?._id })
//           return item
//         })
//         setAreaList(tempData)
//       }
//     })
//   }
//   //get the area
//   //get the add on services data
//   const fetchAddOnServices = () => {
//     get(`/admin/offine-order/addOnService-list`).then((data) => {
//       // console.log(data?.data, "data");
//       setAddOnServiceList(data?.data)
//     })
//   }
//   if (addOnServiceList !== null) {
//     addOnServiceList?.map((item, key) => {
//       addOnServiceOptions?.push({ label: item?.serviceName_EN, value: item?._id })
//     })
//   }
//   // console.log(addOnServiceList, "get the all add on services");
//   const submit = async (values) => {
//     // console.log()
//     // console.log(values, "valuessssss", areaList?.value, addOnServiceList);
//     // var SERVICE = []
//     // values?.addOnServiceId?.forEach((item, key) => {
//     //   SERVICE?.push(item?.value)
//     // })
//     // console.log(SERVICE, "fsyfsfsfy");
//     // let formData = new FormData()
//     // formData.append('username', values.username)
//     // formData.append('email', values.email)
//     // formData.append('image', imageFile)
//     // formData.append('password', values.password)
//     // formData.append('mobile', values.mobile)
//     // formData.append('areaName', areaList?.value)
//     // formData.append('address', values.address)
//     // formData.append('addOnServiceId', JSON.stringify(addOnServiceList));
//     // formData.append('addOnServiceId', SERVICE)
//     // formData.append('noOfPacket', values.noOfPacket)
//     post(`admin/offline-order/offline-bag/create/${customerId}`, values, 0).then((data) => {
//       handleResponse(data)
//     })
//   }
//   const handleResponse = (data) => {
//     if (data?.status == true) {
//       toast.success(data?.message)
//       navigate(`/admin/offline-order/${customerId}`)

//       // setTimeout(() => {
//       //   navigate('/admin/offline-order')
//       // }, 500)
//     } else {
//       toast.error(data?.message)
//     }
//     // formRef.current.resetForm()
//     // fileRef.current.value = null
//     // setUserImagePreview('')
//   }
//   return (
//     <>
//       <CContainer>
//         <CRow>
//           <CCol>
//             <CCardGroup>
//               <CCard className="p-4">
//                 <CCol xs={12}>
//                   <CCardGroup>
//                     <CCardBody>
//                       <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
//                       <CRow>
//                         <CCol md="11">
//                           <h1>New User</h1>
//                         </CCol>
//                         <CCol md="1">
//                           <Link
//                             className={'btn btn-success mt-2'}
//                             style={{ background: '#d3dc40', border: '#d3dc40' }}
//                             to={{ pathname: `/admin/offline-order` }}
//                           >
//                             Back
//                           </Link>
//                         </CCol>
//                       </CRow>
//                       <Formik
//                         initialValues={{
//                           bagCount: '',
//                           orderName: '',
//                           //store in address coll
//                           //store in place order
//                           // addOnServiceId: '',
//                           // noOfPacket: '',
//                         }}
//                         // validationSchema={signUp}
//                         onSubmit={(values, { setSubmitting }) => {
//                           submit(values)
//                           setSubmitting(false)
//                         }}
//                         innerRef={formRef}
//                       >
//                         {(props) => (
//                           <form onSubmit={props?.handleSubmit}>
//                             {/* {console.log(props)} */}
//                             <CRow>
//                               <CCol md={6}>
//                                 <CFormLabel>Order Name</CFormLabel>
//                                 <span className="text-danger" style={{ marginLeft: '5px' }}>
//                                   *
//                                 </span>
//                                 <DefaultInput
//                                   type={'text'}
//                                   placeholder={'Name'}
//                                   id={'orderName'}
//                                   maxLength="70"
//                                   value={props?.values?.orderName}
//                                   onChange={props?.handleChange('orderName')}
//                                   onBlur={() => props?.setFieldTouched('orderName', true)}
//                                   error={props?.touched?.orderName && props?.errors?.orderName}
//                                 />
//                               </CCol>
//                               <CCol md={6}>
//                                 <CFormLabel>Bag Count</CFormLabel>
//                                 <span className="text-danger" style={{ marginLeft: '5px' }}>
//                                   *
//                                 </span>
//                                 <DefaultInput
//                                   type="number"
//                                   placeholder={'bagCount'}
//                                   id={'bagCount'}
//                                   value={props?.values?.bagCount}
//                                   // value={this.state.name}
//                                   onChange={props?.handleChange('bagCount')}
//                                   onBlur={() => props?.setFieldTouched('bagCount', true)}
//                                   error={props?.touched?.bagCount && props?.errors?.bagCount}
//                                 />
//                               </CCol>
//                             </CRow>

//                             <CRow>
//                               <CCol md="2">
//                                 <button
//                                   type="submit"
//                                   className={'btn btn-success mt-2'}
//                                   // disabled={props?.isSubmitting}
//                                 >
//                                   Submit
//                                 </button>
//                               </CCol>
//                             </CRow>
//                           </form>
//                         )}
//                       </Formik>
//                     </CCardBody>
//                   </CCardGroup>
//                 </CCol>
//               </CCard>
//             </CCardGroup>
//           </CCol>
//         </CRow>
//       </CContainer>
//     </>
//   )
// }

// export default OfflineOrderAdd

import { CCard, CCardBody, CCardGroup, CCol, CContainer, CFormLabel, CRow } from '@coreui/react'
import { Formik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { DefaultInput, DefaultMobileInput, DefaultTextarea } from 'src/components/common/input'
import { baseURL } from 'src/config/config'
import { post, get } from 'src/utils/apiManager'
import 'react-toastify/dist/ReactToastify.css'
import Select from 'react-select'
import CustomKeyboard from './KeyBoard'

function OfflineOrderAdd() {
  const navigate = useNavigate()
  const formRef = useRef(null)
  const fileRef = useRef(null)
  const [invalidImage, setInvalidImage] = useState('')
  const [userImagePreview, setUserImagePreview] = useState()
  const [passVisibility, setPassVisibility] = useState(false)
  const [passwordType, setPasswordType] = useState('password')
  // const [stateOptions, setStateOptions] = useState([]);
  const [stateID, setStateID] = useState(null)
  const [cityID, setCityID] = useState(null)
  const [areaOptions, setAreaOptions] = useState([])
  const [areaList, setAreaList] = useState(null)
  const [selectService, setSelectService] = useState(null)
  const [addOnServiceList, setAddOnServiceList] = useState(null)
  const addOnServiceOptions = []
  const [imageFile, setImageFile] = useState(null)
  const [input, setInput] = useState('')
  const [layoutName, setLayoutName] = useState('default')

  const { customerId } = useParams()

  useEffect(() => {
    fetchAddOnServices()
    fetchStateID()
    if (stateID != null) {
      fetchCityList()
    }
    if (cityID != null) {
      fetchAreaList()
    }
  }, [stateID, cityID])

  useEffect(() => {
    if (passVisibility) {
      setPasswordType('text')
    } else {
      setPasswordType('password')
    }
  }, [passVisibility])
  //get the area
  const fetchStateID = () => {
    get(`/admin/address/state-list`).then((res) => {
      if (res?.data !== null) {
        res?.data?.map((item, key) => {
          setStateID(item?._id)
        })
      }
    })
  }
  const fetchCityList = () => {
    const data = {
      state_id: stateID,
    }
    post(`/admin/address/city-list`, data).then((res) => {
      if (res?.data !== null) {
        res?.data?.map((item, key) => {
          setCityID(item?._id)
        })
      }
    })
  }
  const fetchAreaList = () => {
    const data = { city_id: cityID }
    post(`/admin/address/area-list`, data).then((res) => {
      if (res?.data !== null) {
        const tempData = res?.data?.map((item, key) => {
          areaOptions?.push({ label: item?.areaName_EN, value: item?._id })
          return item
        })
        setAreaList(tempData)
      }
    })
  }
  //get the area
  //get the add on services data
  const fetchAddOnServices = () => {
    get(`/admin/offine-order/addOnService-list`).then((data) => {
      // console.log(data?.data, "data");
      setAddOnServiceList(data?.data)
    })
  }
  if (addOnServiceList !== null) {
    addOnServiceList?.map((item, key) => {
      addOnServiceOptions?.push({ label: item?.serviceName_EN, value: item?._id })
    })
  }
  const submit = async (values) => {
    post(
      `admin/offline-order/create-new-order`,
      { ...values, customerId, orderType: 'offline' },
      0,
    ).then((data) => {
      handleResponse(data)
    })
  }
  const handleResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      console.log({ data })
      // navigate(`/admin/offline-order/${customerId}`)
      navigate(`/admin/offline-order/bags/${data.newOrder._id}`)

      // setTimeout(() => {
      //   navigate('/admin/offline-order')
      // }, 500)
    } else {
      toast.error(data?.message)
    }
  }
  return (
    <>
      <CContainer>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <CRow>
                        <CCol md="11">
                          <h1>New Order</h1>
                        </CCol>
                        <CCol md="1">
                          <Link
                            className={'btn btn-success mt-2'}
                            style={{ background: '#d3dc40', border: '#d3dc40' }}
                            to={{ pathname: `/admin/offline-order` }}
                          >
                            Back
                          </Link>
                        </CCol>
                      </CRow>
                      <Formik
                        initialValues={{
                          orderName: '',
                        }}
                        // validationSchema={signUp}
                        onSubmit={(values, { setSubmitting }) => {
                          submit({ orderName: input })
                          setSubmitting(false)
                        }}
                        innerRef={formRef}
                      >
                        {(props) => (
                          <form onSubmit={props?.handleSubmit}>
                            {/* {console.log(props)} */}
                            <CRow>
                              <CCol md={12}>
                                <CFormLabel>Order Name</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>
                                {/* <DefaultInput
                                  type={'text'}
                                  placeholder={'Name'}
                                  id={'orderName'}
                                  maxLength="70"
                                  value={props?.values?.orderName}
                                  onChange={props?.handleChange('orderName')}
                                  onBlur={() => props?.setFieldTouched('orderName', true)}
                                  error={props?.touched?.orderName && props?.errors?.orderName}
                                /> */}
                                <CustomKeyboard
                                  input={input}
                                  setInput={setInput}
                                  layoutName={layoutName}
                                  setLayoutName={setLayoutName}
                                />
                              </CCol>
                            </CRow>

                            <CRow>
                              <CCol md="2">
                                <button
                                  type="submit"
                                  className={'btn btn-success mt-2'}
                                // disabled={props?.isSubmitting}
                                >
                                  Submit
                                </button>
                              </CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default OfflineOrderAdd
