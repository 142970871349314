import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
// import { get, deleteApi } from '../../utils/apiManager'
import { get } from '../../utils/apiManager'
import { FaRegEye, FaEdit } from 'react-icons/fa'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CCardSubtitle,
  CTable,
  CRow,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
} from '@coreui/react'
import { DefaultInputWithIcon } from 'src/components/common/input'
import Pagination from 'react-js-pagination'
import ShowMoreText from "react-show-more-text"
// import bcrypt from 'bcrypt'

export default function TempList() {
  const navigate = useNavigate()
  const [templateList, setTemplateList] = useState([])
  const [totalPages, settotalPages] = useState(0)
  const [deletedArray, setDeletedArray] = useState([])
  const [pageData, setpageData] = useState({
    page: 1,
    perPage: 10,
    searchItem: '',
  })
  const [searchItem, setsearchItem] = useState('')
  const getTemplateList = () => {
    get(
      `admin/Template/templates-list?page=${pageData?.page || 1}&perPage=${pageData?.perPage || 10
      }&searchItem=${pageData?.searchItem || ''}`,
    ).then((response) => {
      handleResponse(response)
    })
  }
  const handleResponse = (response) => {
    // setTemplateList(response?.data)
    if (!response?.status) toast.success(response?.message, response?.status)
    else {
      setTemplateList([...response?.data])
      settotalPages(response?.pages)
    }
  }
  const changePage = (index) => {
    setpageData({
      ...pageData,
      page: index,
    })
  }
  const onSearchCLick = () => {
    setpageData({
      ...pageData,
      searchItem: searchItem,
    })
  }
  const onClickTemplateListEdit = (value) => {
    navigate('/admin/template/edit-template', { state: value })
  }
  const onClickViewTemp = (value) => {
    // const password = value._id
    // console.log(bcrypt.hash(password))    
    navigate(`/admin/template/template-detail/${value?._id}`, { state: value })
  }
  const executeOnClick = async (isExpanded) => {
    // console.log(isExpanded);
  }
  useEffect(() => {
    getTemplateList()
  }, [pageData])


  const handleCheck = (id) => {
    let findValue = deletedArray.includes(id)
    if (findValue) {
      let filterData = deletedArray?.filter((item) => item != id)
      setDeletedArray(filterData)
    } else {
      setDeletedArray((prev) => [...prev, id])
    }
  }

  const deleteOrders = () => {
    // post(`admin/placed-order/deleted-order`, deletedArray).then((data) =>
    //   handleDeletedResponse(data),
    // )
  }

  const handleDeletedResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      setDeletedArray([])
      // get(
      //   `/admin/placed-order/order-placed-list?page=${pageData?.page || 1}&perPage=${
      //     pageData?.perPage || 10
      //   }&searchItem=${pageData?.searchItem || ''}&from_date=${searchDate ? searchDate : ''}`,
      // ).then((res) => handleResponse(res))
    }
  }



  return (
    <>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                <div className="d-flex" style={{ flexDirection: 'column' }}>
                  <CCardSubtitle as="h4">Template's</CCardSubtitle>
                  {deletedArray.length > 0 && (
                      <button className="btn btn-danger mt-2 text-white" onClick={deleteOrders}>
                        Delete
                      </button>
                    )}
                  </div>
                  <CRow>
                    <CCol md="8">
                      <DefaultInputWithIcon
                        type={'text'}
                        placeholder="Search"
                        customType={'search'}
                        value={searchItem}
                        onClick={onSearchCLick}
                        onChange={(e) => {
                          onSearchCLick()
                          setsearchItem(e?.target?.value)
                        }}
                      />
                    </CCol>
                    <CCol md="2"></CCol>
                  </CRow>
                </CHeader>

                <CCardBody>
                  <CTable bordered hover responsive>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell>S. No</CTableHeaderCell>
                        <CTableHeaderCell>Title</CTableHeaderCell>
                        <CTableHeaderCell>Subject</CTableHeaderCell>
                        <CTableHeaderCell>Description</CTableHeaderCell>
                        <CTableHeaderCell>Action</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {/* {console.log(templateList)} */}
                      {templateList &&
                        templateList?.map((data, key) => {
                          return (
                            <CTableRow key={key}>
                              <CTableDataCell >
                                {pageData?.perPage * (pageData?.page - 1) + key + 1}
                                {/* <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    style={{ marginLeft: '2px', marginTop: '-20px' }}
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                    checked={deletedArray?.includes(data._id)}
                                    onChange={() => handleCheck(data._id)}
                                  />
                                </div> */}
                              </CTableDataCell>
                              <CTableDataCell>{data?.title}</CTableDataCell>
                              <CTableDataCell>{data?.subject}</CTableDataCell>
                              <CTableDataCell
                                className="col col-md-8"
                              >
                                <ShowMoreText
                                  /* Default options */
                                  lines={1}
                                  more="Show more"
                                  less="Show less"
                                  className="content-css"
                                  anchorClass="my-anchor-css-class"
                                  onClick={executeOnClick}
                                  expanded={false}
                                  // width={280}
                                  truncatedEndingComponent={"... "}
                                >

                                  <p dangerouslySetInnerHTML={{ __html: data?.description }}></p>
                                </ShowMoreText>


                              </CTableDataCell>
                              <CTableDataCell>
                                <span className="mx-2" onClick={() => onClickTemplateListEdit(data)} style={{ fontSize: '20px', color: '#d3dc40', cursor: 'pointer' }}><FaEdit /></span>
                                {/* <span onClick={() => deleteData(data?._id)} className="text-danger"style={{padding: '5px'}}><FaTrash/></span> */}
                                <span className="mx-2" onClick={() => onClickViewTemp(data)} style={{ fontSize: '20px', color: '#d3dc40', padding: '5px', cursor: 'pointer' }}><FaRegEye /></span>
                              </CTableDataCell>
                            </CTableRow>
                          )
                        })}
                    </CTableBody>
                  </CTable>
                  {!templateList?.length && (
                    <>
                      <h1>NO Data</h1>
                    </>
                  )}

                  {templateList?.length ? <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={pageData?.page}
                    itemsCountPerPage={pageData?.perPage}
                    totalItemsCount={totalPages * 10}
                    pageRangeDisplayed={5}
                    onChange={changePage}
                  /> : ''}
                  {/* {console.log(templateList.length)} */}
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}
