import React, { useRef, useState } from 'react'
import { Formik } from 'formik'
import { useNavigate, Link } from 'react-router-dom'
import { post } from 'src/utils/apiManager'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { CCard, CCardBody, CCardGroup, CCol, CContainer, CFormLabel, CRow } from '@coreui/react'
import { DefaultInput, DefaultTextarea } from 'src/components/common/input'
import { validateAddress } from 'src/utils/validator'
import Select from 'react-select'

function add() {
  const navigate = useNavigate()
  const formRef = useRef(null)
  const submit = async (values) => {
  }

  const handleResponse = (data) => {
  }

  const onClickReset = () => {
    // Reset Button Function
    // formRef.current.setFieldValue('areaName_EN', '')
    // formRef.current.setFieldValue('areaName_TH', '')
    // formRef.current.setFieldValue('zipcode', '')
    // formRef.current.setFieldValue('lat_long', '')
  }
  return (
    <>
      <CContainer>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <CRow>
                        <CCol md="11">
                          <h1>New Address</h1>
                        </CCol>
                        <CCol md="1">
                          <Link
                            className={'btn btn-success mt-2'}
                            style={{ background: '#d3dc40', border: '#d3dc40' }}
                            to={{ pathname: `/admin/address` }}
                          >
                            Back
                          </Link>
                        </CCol>
                      </CRow>
                      <Formik
                        initialValues={{
                          address: '',
                          city_id: '',
                          state_id: '',
                          area_id: '',
                          type: '',
                          zipcode: '',
                        }}
                        validationSchema={validateAddress}
                        onSubmit={(values, { setSubmitting }) => {
                          submit(values)
                          setSubmitting(false)
                          // console.log(values)
                        }}
                        innerRef={formRef}
                      >
                        {(props) => (
                          <form onSubmit={props.handleSubmit}>
                            <CRow xs={12}>
                              <CCol md={6}>
                              <CFormLabel>State Name</CFormLabel>
                                <span className='text-danger' style={{marginLeft:'5px'}}>*</span>
                                <Select

                                    // onChange={(val) => {setStateOptions(val)
                                    // props.setFieldValue("state_id", val.value)}}
                                    // options={StateListData}
                                    // value={stateOptions}
                                    placeholder={"Select State"} 
                                    name={"State Name (EN)"}
                                  />  
                                  {/* {stateOptions == undefined  ? <span className='text-danger'>{props.errors.state_id}</span> : '' } */}
                                
                                  <CFormLabel>Area Name</CFormLabel>
                                <span className='text-danger' style={{marginLeft:'5px'}}>*</span>
                                <Select

                                    // onChange={(val) => {setStateOptions(val)
                                    // props.setFieldValue("area_id", val.value)}}
                                    // options={StateListData}
                                    // value={stateOptions}
                                    placeholder={"Select Area"} 
                                    name={"Area Name"}
                                  />  
                                  {/* {stateOptions == undefined  ? <span className='text-danger'>{props.errors.area_id}</span> : '' } */}
                                
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Zipcode'}
                                  name={'Zipcode'}
                                  // value={props.values.zipcode}
                                  maxLength={6}
                                  // onChange={props.handleChange('zipcode')}
                                  // onBlur={() => props.setFieldTouched('zipcode', true)}
                                  // error={props.touched.zipcode && props.errors.zipcode}
                                />
                              </CCol>
                              <CCol md={6}>
                                <CFormLabel>City Name</CFormLabel>
                                  <span className='text-danger' style={{marginLeft:'5px'}}>*</span>
                                    <Select

                                      // onChange={(val) => {setStateOptions(val)
                                      // props.setFieldValue("city_id", val.value)}}
                                      // options={StateListData}
                                      // value={stateOptions}
                                      placeholder={"Select City"} 
                                      name={"City Name"}
                                      />  
                                      {/* {stateOptions == undefined  ? <span className='text-danger'>{props.errors.city_id}</span> : '' } */}
                                
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Address'}
                                  name={'Address'}
                                  maxLength={70}
                                  // value={props.values.address}
                                  // onChange={props.handleChange('address')}
                                  // onBlur={() => props.setFieldTouched('address', true)}
                                  // error={props.touched.address && props.errors.address}
                                />

                                <CFormLabel>Address Type</CFormLabel>
                                  <span className='text-danger' style={{marginLeft:'5px'}}>*</span>
                                    <Select
                                      // onChange={(val) => {setStateOptions(val)
                                      // props.setFieldValue("type", val.value)}}
                                      // options={StateListData}
                                      // value={stateOptions}
                                      placeholder={"Select Type"} 
                                      name={"Address Type"}
                                      />  
                                    {/* {stateOptions == undefined  ? <span className='text-danger'>{props.errors.type}</span> : '' } */}
                              
                              </CCol>
                            </CRow>

                            <CRow>
                              <CCol md="2">
                                <button
                                  type="submit"
                                  className={'btn btn-success mt-2'}
                                  disabled={props.isSubmitting}
                                >
                                  Submit
                                </button>
                              </CCol>
                              <CCol md="2">
                                <button
                                  type="button"
                                  className={'btn btn-warning mt-2'}
                                  onClick={onClickReset}
                                >
                                  Reset
                                </button>
                              </CCol>
                              <CCol md="3"></CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default add
