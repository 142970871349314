import React,{useRef,useState,useEffect} from 'react';
import { Formik } from 'formik';
import { useNavigate,Link,useLocation} from 'react-router-dom'
import { put } from 'src/utils/apiManager';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import {
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CFormLabel,
    CRow,
  } from '@coreui/react'
  import { DefaultInput } from 'src/components/common/input'
import { validatePackageDuration } from 'src/utils/validator'

function edit() {
  const navigate = useNavigate()
  const {state} = useLocation()
  const formRef = useRef(null)
  const [isDisable, setIsDisable] = useState(false)

  const submit = async (values) => {
    if (values?.duration <= 0) {
      toast.error("Enter correct time duration")
      formRef.current.setFieldValue('duration', '')
    } else {
      let durationData = {
        id:state?._id,
        title_EN: values?.title_EN,
        title_TH: values?.title_TH,
        duration: values?.duration,
  
      }
      // let formData = new FormData();
        
      //   formData.append('id', state?._id);
      //   formData.append('title_EN', values?.title_EN);
      //   formData.append('title_TH', values?.title_TH);
      //   formData.append('duration', values?.duration);
        put(`admin/subscribe-duration/update?_id=${state?._id}`, durationData).then((data) =>handleResponse(data))
    }
    }
    const handleResponse = (data) => {
      if (data?.status == true) {
        toast.success(data?.message)
        setTimeout(() => {
          navigate('/admin/package-duration')
        }, 800)
      }
      else{
        toast.error(data?.message)
      }
      formRef.current.resetForm()
    }
    useEffect(() => {
      if (state != null) {
        formRef.current.setFieldValue('title_EN', state?.title_EN)
        formRef.current.setFieldValue('title_TH', state?.title_TH)
        formRef.current.setFieldValue('duration', state?.duration)
      }
    }, [state])

  return (
    <>
    <CContainer >
    <CRow>
      <CCol>
        <CCardGroup>
          <CCard className="p-4">
            <CCol xs={12}>
            <CCardGroup>
                <CCardBody>
                <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                <CRow>
                      <CCol md="11">
                <h1>Package Duration</h1>
                      </CCol>                    
                      <CCol md="1">
                        <Link className={'btn btn-success mt-2'} style={{background:'#d3dc40',border: '#d3dc40' }} to={{ pathname: `/admin/package-duration` }}>
                            Back
                        </Link>
                      </CCol>
                    </CRow>
                <Formik
                initialValues={{
                  title_EN: '',
                  title_TH: '',
                  duration:'',
                }}
                validationSchema={validatePackageDuration}
                onSubmit={(values, { setSubmitting }) => {
                    submit(values)
                    setSubmitting(false)
                }}
                innerRef={formRef}
                >
                {(props) => (
                    <form onSubmit={props?.handleSubmit}>
                        <CRow xs={12}>
                            <CCol md={6}>
                                <CFormLabel>Title Name (EN)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                    type={"text"}
                                    placeholder={"Title Name (EN)"}
                                    value={props?.values?.title_EN}
                                    onChange={props?.handleChange("title_EN")}
                                    onBlur={() => props?.setFieldTouched("title_EN", true)}
                                    error={props?.touched?.title_EN && props?.errors?.title_EN}
                                    onKeyUp={() => {
                                      if (state?.title_EN == props?.values?.title_EN) setIsDisable(false)
                                      else setIsDisable(true)
                                    }}
                                />
                                <CFormLabel>Duration</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                    type={"number"}
                                    placeholder={"Per month didgit"}
                                    value={props?.values?.duration}
                                    onChange={props?.handleChange("duration")}
                                    onBlur={() => props?.setFieldTouched("duration", true)}
                                    error={props?.touched?.duration && props?.errors?.duration}
                                    onKeyUp={() => {
                                      if (state?.duration == props?.values?.duration) setIsDisable(false)
                                      else setIsDisable(true)
                                    }}
                                />
                            </CCol>
                            <CCol md="6">
                                <CFormLabel>Title Name (TH)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                    type={"text"}
                                    placeholder={"Title Name (TH)"}
                                    value={props?.values?.title_TH}
                                    onChange={props?.handleChange("title_TH")}
                                    onBlur={() => props?.setFieldTouched("title_TH", true)}
                                    error={props?.touched?.title_TH && props?.errors?.title_TH}
                                    onKeyUp={() => {
                                      if (state?.title_TH == props?.values?.title_TH) setIsDisable(false)
                                      else setIsDisable(true)
                                    }}
                                />
                            </CCol>
                        </CRow>
                    <CRow>
                        <CCol md="2">
                        <button
                            type="submit"
                            
                            className={'btn btn-success mt-2'}
                            disabled={!isDisable || props?.isSubmitting}
                        >
                            Submit
                        </button>
                        </CCol>
                        <CCol md="2">
                        <button
                            type="button"
                            className={'btn btn-warning mt-2'}
                            onClick={()=>navigate('/admin/package-duration')}
                            >
                              Reset
                        </button>
                        </CCol>
                        <CCol md="3"></CCol>
                    </CRow>
                    </form>
                )}
                </Formik>
                </CCardBody>
            </CCardGroup>
            </CCol>               
          </CCard>
        </CCardGroup>
      </CCol>
    </CRow>
  </CContainer> 
</>
  )
}

export default edit