import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { baseURL } from 'src/config/config';
import { post, get } from "../../../utils/apiManager";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilBurger, cilEnvelopeClosed, cilLockLocked, cilUser } from '@coreui/icons'
import { Formik } from 'formik';
import { DefaultInput } from 'src/components/common/input';
import { validateChangePass } from 'src/utils/validator';
import { ErrorText } from "src/components/common/commonError";

export default function ChangePassword(props) {
  const navigate = useNavigate()
  const formRef = useRef(null);
  const [passVisibility, setPassVisibility] = useState(false)
  const [newPassVisibility, setNewPassVisibility] = useState(false)
  const [confrmNewPassVisibility, setConfrmNewPassVisibility] = useState(false)
  const [passwordType, setPasswordType] = useState({
    currentPassType: 'password',
    newPassType: 'password',
    confrmNewPassType: 'password'
  })

  // useEffect(() => { }, []);

  const submit = async (values) => {
    const Url = `admin/change-password`;
    post(Url, values).then((data) =>
      handleResponse(data)
      // console.log(data)
    );
  };

  const handleResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
    }
    else {
      toast.error(data?.message)
    }
    // ShowToast(data?.message, data?.status);
    formRef.current.resetForm();
  };
  const onClickReset = () => {
    // Reset Button Function
    formRef.current.setFieldValue('currentPassword', '')
    formRef.current.setFieldValue('newPassword', '')
    formRef.current.setFieldValue('confirmNewPassword', '')

  };

  const handlePasswordVisibility = (e, type) => {
    e.preventDefault();
    if (type == "currentPassword") {
      setPassVisibility(!passVisibility)
    } else if (type == "NewPassword") {
      setNewPassVisibility(!newPassVisibility)
    } else {
      setConfrmNewPassVisibility(!confrmNewPassVisibility)
    }
  }

  useEffect(() => {
    if (passVisibility == false) {
      setPasswordType({ ...passwordType, currentPassType: "password" })
    } else {
      setPasswordType({ ...passwordType, currentPassType: "text" })
    }
  }, [passVisibility])

  useEffect(() => {
    if (newPassVisibility == false) {
      setPasswordType({ ...passwordType, newPassType: "password" })
    } else {
      setPasswordType({ ...passwordType, newPassType: "text" })
    }
  }, [newPassVisibility])

  useEffect(() => {
    if (confrmNewPassVisibility == false) {
      setPasswordType({ ...passwordType, confrmNewPassType: "password" })
    } else {
      setPasswordType({ ...passwordType, confrmNewPassType: "text" })
    }
  }, [confrmNewPassVisibility])

  return (
    <>
      <CContainer >
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <CRow>
                        <CCol md="11">
                          <h1>Change Password</h1>

                        </CCol>
                        <CCol md="1">
                          <Link className={'btn btn-success mt-2'} style={{ background: '#d3dc40', border: '#d3dc40' }} to={{ pathname: `/admin/dashboard` }}>
                            Back
                          </Link>
                        </CCol>
                      </CRow>
                      <Formik
                        initialValues={{
                          currentPassword: "",
                          newPassword: "",
                          confirmNewPassword: "",
                        }}
                        validate={validateChangePass}
                        onSubmit={(values, { setSubmitting }) => {
                          submit(values);
                          setSubmitting(false);
                        }}
                        innerRef={formRef}
                      >
                        {(props) => (
                          // console.log(props)
                          <form onSubmit={props?.handleSubmit}>

                            <CFormLabel>Current Password</CFormLabel>
                            <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                            <div className="input-group">
                              <input
                                type={passwordType?.currentPassType}
                                autoComplete="off"
                                className="form-control"
                                placeholder={"Current Password"}
                                id={"currentPassword"}
                                value={props?.values?.currentPassword}
                                onChange={props?.handleChange("currentPassword")}
                                onBlur={() =>
                                  props?.setFieldTouched("currentPassword", true)
                                }
                              />
                              <button
                                className="btn btn-outline-secondary"
                                type="button"
                                onClick={(e) => handlePasswordVisibility(e, 'currentPassword')}
                                style={{ backgroundColor: '#d8dbe0' }}
                              >
                                {passwordType?.currentPassType == "password" ? (
                                  <i style={{color: '#2C384A'}} className="fa-solid fa-eye-slash"></i>
                                ) : <i style={{color: '#2C384A'}} className="fa-solid fa-eye"></i>}
                              </button>
                            </div>
                            {props?.touched?.currentPassword && props?.errors?.currentPassword ? <ErrorText title={props?.errors?.currentPassword} /> : null}


                            <CFormLabel>New Password</CFormLabel>
                            <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                            <div className="input-group">
                              <input
                                type={passwordType?.newPassType}
                                autoComplete="off"
                                className="form-control"
                                placeholder={"New Password"}
                                id={"NewPassword"}
                                value={props?.values?.newPassword}
                                onChange={props?.handleChange("newPassword")}
                                onBlur={() =>
                                  props?.setFieldTouched("newPassword", true)
                                }
                              />
                              <button
                                className="btn btn-outline-secondary"
                                type="button"
                                onClick={(e) => handlePasswordVisibility(e, 'NewPassword')}
                                style={{ backgroundColor: '#d8dbe0' }}
                              >
                                {passwordType?.newPassType == "password" ? (
                                  <i style={{color: '#2C384A'}} className="fa-solid fa-eye-slash"></i>
                                ) : <i style={{color: '#2C384A'}} className="fa-solid fa-eye"></i>}
                              </button>
                            </div>
                            {props?.touched?.newPassword && props?.errors?.newPassword ? <ErrorText title={props?.errors?.newPassword} /> : null}


                            <CFormLabel>Confirm New Password</CFormLabel>
                            <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                            <div className="input-group">
                              <input
                                type={passwordType?.confrmNewPassType}
                                autoComplete="off"
                                className="form-control"
                                placeholder={"Confirm New Password"}
                                id={"confirmNewPassword"}
                                value={props?.values?.confirmNewPassword}
                                onChange={props?.handleChange("confirmNewPassword")}
                                onBlur={() =>
                                  props?.setFieldTouched("confirmNewPassword", true)
                                }
                              />
                              <button
                                className="btn btn-outline-secondary"
                                type="button"
                                onClick={(e) => handlePasswordVisibility(e, 'confirmNewPassword')}
                                style={{ backgroundColor: '#d8dbe0' }}
                              // onClick={() => setConfrmNewPassVisibility(!confrmNewPassVisibility)}
                              >
                                {passwordType?.confrmNewPassType == "password" ? (
                                  <i style={{color: '#2C384A'}} className="fa-solid fa-eye-slash"></i>
                                ) : <i style={{color: '#2C384A'}} className="fa-solid fa-eye"></i>}
                              </button>
                            </div>
                            {props?.touched?.confirmNewPassword && props?.errors?.confirmNewPassword ? <ErrorText title={props?.errors?.confirmNewPassword} /> : null}


                            {/* <DefaultInput
                            type={"password"}
                            placeholder={"Current Password"}
                            id={"currentPassword"}
                            value={props?.values?.currentPassword}
                            onChange={props?.handleChange("currentPassword")}
                            onBlur={() =>
                              props?.setFieldTouched("currentPassword", true)
                            }
                            error={
                              props?.touched?.currentPassword &&
                              props?.errors?.currentPassword
                            }
                          /> */}

                            {/* <CFormLabel>New Password</CFormLabel>
                            <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                            <DefaultInput
                              type={"password"}
                              placeholder={"New Password"}
                              id={"NewPassword"}
                              value={props?.values?.newPassword}
                              onChange={props?.handleChange("newPassword")}
                              onBlur={() =>
                                props?.setFieldTouched("newPassword", true)
                              }
                              error={
                                props?.touched?.newPassword &&
                                props?.errors?.newPassword
                              }
                            />

                            <CFormLabel>Confirm New Password</CFormLabel>
                            <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                            <DefaultInput
                              type={"password"}
                              placeholder={"Confirm New Password"}
                              id={"confirmNewPassword"}
                              value={props?.values?.confirmNewPassword}
                              onChange={props?.handleChange("confirmNewPassword")}
                              onBlur={() =>
                                props?.setFieldTouched("confirmNewPassword", true)
                              }
                              error={
                                props?.touched?.confirmNewPassword &&
                                props?.errors?.confirmNewPassword
                              }
                            /> */}

                            <CRow>
                              {/* <CCol md="3"></CCol> */}
                              <CCol md="2">
                                <button
                                  type="submit"
                                  className={"btn btn-success mt-2"}
                                  disabled={props?.isSubmitting}
                                >
                                  Submit
                                </button>
                              </CCol>
                              <CCol md="2">
                                <button
                                  type="button"
                                  className={'btn btn-warning mt-2'}
                                  onClick={onClickReset}
                                >
                                  Reset
                                </button>
                              </CCol>
                              <CCol md="3"></CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>

                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

