import React, { useRef, useState, useEffect, useCallback } from 'react'
import { Formik } from 'formik'
import { Link, useParams } from 'react-router-dom'
import { post, get } from 'src/utils/apiManager'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Select from 'react-select'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CFormLabel,
  CRow,
  CImage,
  CFormCheck,
  CHeader,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
  CTable,
  CCardSubtitle,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableDataCell,
  CTableBody,
  CButton,
} from '@coreui/react'
import { DefaultInput, DefaultTextarea } from 'src/components/common/input'
import { cilPlus } from '@coreui/icons'
import { FaTrash } from 'react-icons/fa'
import CIcon from '@coreui/icons-react'
import { ImageUrl } from 'src/config/config'

function PackageExaminItems() {
  const { orderId } = useParams()
  const { userId } = useParams()
  const { id } = useParams()
  const formRef = useRef(null)
  const fileRef = useRef(null)
  const [invalidImage, setInvalidImage] = useState('')
  const [files, setFiles] = useState([])
  const [imageURI, setImageURI] = useState([])
  const [status, setStatus] = useState('confirm')
  const [pendingStatus, setPendingStatus] = useState(false)
  const [rejectedStatus, setRejectedStatus] = useState(false)
  const [confirmStatus, setConfirmStatus] = useState(true)
  const [allServices, setAllServices] = useState()
  const [servicesData, setServicesData] = useState()
  const [selectService, setSelectService] = useState([])
  const [additionalServiceData, setAdditionalServiceData] = useState(null)
  const [selectAdditionalService, setSelectAdditionalService] = useState([])
  const [addedItemsList, setAddedItemsList] = useState([])
  const [listDisable, setListDisable] = useState(true)
  const [selectedItem, setSelectedItem] = useState(null)
  const [itemSizeData, setItemSizeData] = useState(null)
  const [userPakageData, setUserPackageData] = useState([])

  const [openwithSubscriptionForm, setOpenWithSubscriptionForm] = useState(false)
  const [packageSelectedServices, setPackageSelectedServices] = useState([])
  const [selectedServicesQuota, setSelectedServicesQuota] = useState([])
  const [remainingQuota, setRemainingQuota] = useState([])
  const [servicesQuotaEmpty, setServicesQuotaEmpty] = useState([])

  const [isButtonShow, setIsButtonShow] = useState(false)
  const [isClickButton, setIsClickButton] = useState(false)

  const [attributeList, setAttributeList] = useState()
  const [selectedAttribute, setSelectedAttribute] = useState(null)
  const [selectedoptionList, setSelectedOptionList] = useState()
  const [OptionsArr, setOptionsArr] = useState([])

  let servicesOptions = []
  let additionalServicesOptions = []
  let additionalServicePrice = 0
  let servicesPrice = 0
  const itemOptions = []
  let servicesQuotaData = []
  const AttributeOptions = []

  const uploadSingleFile = (e) => {
    readURI(e)
  }

  const readURI = (e) => {
    if (e?.target?.files) {
      /* Get files in array form */

      const selectedFiles = Array.from(e.target.files)
      selectedFiles?.map((item, index) => {
        if (!item?.name?.match(/\.(jpg|jpeg|png)$/)) {
          setInvalidImage('Please select valid image')
        } else {
          setInvalidImage('')
        }
      })
      setFiles([...files, ...selectedFiles])
      /* Map each file to a promise that resolves to an array of image URI's */
      Promise.all(
        selectedFiles?.map((file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader?.addEventListener('load', (ev) => {
              resolve(ev?.target?.result)
            })
            reader?.addEventListener('error', reject)
            reader?.readAsDataURL(file)
          })
        }),
      ).then(
        (images) => {
          setImageURI([...imageURI, ...images])
        },
        (error) => {
          console.error(error)
        },
      )
    }
  }

  const deleteParticularFile = (id) => {
    const s = imageURI?.filter((item, index) => index !== id)
    const extractFile = files?.filter((item, index) => index !== id)
    setFiles(extractFile)
    setImageURI(s)
  }

  const getAttributesList = () => {
    get(`/admin/placed-order/get-option-list`).then((res) => {
      if (res?.status) {
        setAttributeList(res?.data)
      } else {
        toast.error(res?.message)
      }
    })
  }

  useEffect(() => {
    getServices()
    getItems()
    getAttributesList()
    // fetchItemsList();
  }, [])

  useEffect(() => {
    if (openwithSubscriptionForm) {
      setListDisable(false)
      if (userPakageData[0]?.packageId !== null) {
        let packageServices = []
        let servicesUsed = []
        const tempData = userPakageData[0]?.packageId?.services?.find((item) => {
          // console.log('remainingQuota', remainingQuota)
          if (remainingQuota?.length > 0 && remainingQuota != undefined) {
            remainingQuota?.map((data) => {
              if (data?.value == item?._id) {
                // console.log('data', data)
                if (data?.quota != 0) {
                  packageServices?.push({ label: item?.serviceName_EN, value: item?._id })
                  // return { label: item?.serviceName_EN, value: item?._id }
                } else {
                  servicesUsed?.push({ label: item?.serviceName_EN, value: item?._id })
                }
              }
            })
          } else {
            packageServices?.push({ label: item?.serviceName_EN, value: item?._id })
          }
        })
        setPackageSelectedServices(packageServices)
        setServicesQuotaEmpty(servicesUsed)
        formRef.current.setFieldValue('packageName', userPakageData[0]?.packageId?.packageName_EN)
      }
    } else {
      setListDisable(true)
      fetchItemsList()
    }
  }, [openwithSubscriptionForm])

  if (attributeList != null) {
    attributeList?.map((item, key) => {
      AttributeOptions?.push({ label: item?.attributeName_EN, value: item?._id })
    })
  }

  const getOptionsList = (value) => {
    if (value != selectedAttribute?._id) {
      setOptionsArr([])
    }
    attributeList?.map((item, k) => {
      if (item?._id == value) {
        item?.option?.map((option, i) => {
          setOptionsArr((prevState) => [
            ...prevState,
            { label: option?.optionName_EN, value: option?._id },
          ])
        })
      }
    })
  }

  const fetchItemsList = () => {
    get(
      `/admin/placed-order/get-examin-item-details?order_id=${atob(orderId)}&user_id=${atob(
        userId,
      )}`,
    ).then((res) => {
      setAddedItemsList(res?.data)
      setUserPackageData(res?.userBuyPackage)
      setRemainingQuota(res?.servicesQuotaDetails[0]?.remainingServicesQuota)
      const filterArr = res?.data?.filter((aitem) => aitem?.itemStatus == 'pending')
      // console.log(filterArr, "fil arr")
      if (filterArr?.length == 0) {
        setIsButtonShow(true)
      }
    })
  }

  const getItems = () => {
    get(`admin/services/get-items`).then((data) => {
      setItemSizeData(data?.data)
    })
  }
  if (itemSizeData !== null) {
    itemSizeData?.map((item, key) => {
      itemOptions?.push({ label: item?.itemName_EN, value: item?._id })
    })
  }

  const getServices = () => {
    get(`admin/subscription/services`).then((data) => {
      setAllServices(data?.data)
      setServicesData(data?.data)
      setAdditionalServiceData(data?.data)
    })
  }

  if (servicesData !== null) {
    servicesData?.map((item, key) => {
      servicesOptions?.push({ label: item?.serviceName_EN, value: item?._id })
    })
  }

  const handleAdditionalOptions = (value) => {
    // console.log('vall.............',value)
    const arrcopy = Array.from(allServices)
    value?.map((item) => {
      const objWithIdIndex = arrcopy?.findIndex((obj) => obj?._id == item?.value)
      // console.log('objWithIdIndex', objWithIdIndex)
      arrcopy?.splice(objWithIdIndex, 1)
      // console.log(arrcopy)
      setAdditionalServiceData(arrcopy)
    })
  }

  if (additionalServiceData !== null) {
    additionalServiceData?.map((item, key) => {
      additionalServicesOptions?.push({ label: item?.serviceName_EN, value: item?._id })
    })
  }

  const showExccedServices = (data) => {
    const exceedQuotaArr = data?.map((i, k) => {
      return i?.label
    })
    return exceedQuotaArr?.join(',')
  }

  const openStatusInfo = (e, status) => {
    formRef.current.setFieldValue('packageServicesPrice', '')
    formRef.current.setFieldValue('packageAdditionalServicesPrice', '')
    formRef.current.setFieldValue('totalPrice', '')
    formRef.current.setFieldValue('description', '')
    formRef.current.setFieldValue('services', '')
    formRef.current.setFieldValue('additionalServices', '')
    setSelectService([])
    setSelectAdditionalService([])
    setSelectedServicesQuota([])

    if (status == 'pending') {
      setPendingStatus(true)
      setRejectedStatus(false)
      setConfirmStatus(false)
      setStatus('pending')
    } else if (status == 'rejected') {
      setPendingStatus(false)
      setRejectedStatus(true)
      setConfirmStatus(false)
      setStatus('rejected')
    } else {
      setPendingStatus(false)
      setRejectedStatus(false)
      setConfirmStatus(true)
      setStatus('confirm')
    }
  }

  const handlePackageFormSubmit = (values) => {
    let data = {}
    var OPTIONS = []
    if (values?.optionName) {
      values?.optionName?.forEach((item, k) => {
        OPTIONS?.push(item?.value)
      })
    }

    if (status == 'pending') {
      var SERVICES = []
      var ADD_SERVICES = []
      if (values?.packageServices) {
        values?.packageServices?.forEach((item, key) => {
          SERVICES?.push(item?.value)
        })
      }
      if (values?.packageAdditionalServices) {
        values?.packageAdditionalServices?.forEach((item, key) => {
          ADD_SERVICES?.push(item?.value)
        })
      }

      var REMAINQUOTA = []
      if (remainingQuota == undefined || remainingQuota?.length == 0) {
        const userPackage = userPakageData[0]?.packageId?.services
        userPackage.find((item) => {
          selectedServicesQuota.find((data) => {
            if (item?._id != data?.value && selectedServicesQuota?.length != userPackage?.length) {
              REMAINQUOTA.push({
                quota: item?.serviceQuota,
                value: item?._id,
                serviceName: item?.serviceName_EN,
              })
            }
          })
        })
      } else {
        remainingQuota?.find((item) => {
          selectedServicesQuota?.map((data) => {
            if (item?.value != data?.value) {
              REMAINQUOTA?.push({
                quota: item?.quota,
                value: item?.value,
                serviceName: item?.serviceName,
              })
            }
          })
        })
      }
      selectedServicesQuota?.forEach((item, key) => {
        REMAINQUOTA?.push(item)
      })

      data = {
        id: atob(id),
        itemName: values?.itemName,
        totalPrice: values?.totalPrice,
        attributeName: values?.attributeName,
        optionName: JSON.stringify(OPTIONS),
        servicePrice: values?.packageServicesPrice,
        additionalServicePrice: values?.packageAdditionalServicesPrice,
        additionalServices: ADD_SERVICES ? JSON.stringify(ADD_SERVICES) : [],
        services: JSON.stringify(SERVICES),
        remainServiceQuotaData: JSON.stringify(REMAINQUOTA),
        itemStatus: status,
        userId: atob(userId),
        image: files,
      }
    }

    if (status == 'confirm') {
      console.log('first confirm status')
      var SERVICES = []
      var ADD_SERVICES = []
      var REMAINQUOTA = []

      if (values?.packageServices) {
        values?.packageServices?.forEach((item, key) => {
          SERVICES.push(item?.value)
        })
      }
      if (values?.packageAdditionalServices) {
        values?.packageAdditionalServices?.forEach((item, key) => {
          ADD_SERVICES?.push(item?.value)
        })
      }
      if (remainingQuota == undefined || remainingQuota?.length == 0) {
        // console.log('remaingQuota empty', remainingQuota)
        const userPackage = userPakageData[0]?.packageId?.services
        // console.log('userPackage..............................', userPackage)
        userPackage?.find((item) => {
          // console.log('itemm/////////', item)
          // console.log('selectedServicesQuota', selectedServicesQuota)
          selectedServicesQuota.find((data) => {
            if (item?._id != data?.value && selectedServicesQuota?.length != userPackage?.length) {
              REMAINQUOTA?.push({
                quota: item?.serviceQuota,
                value: item?._id,
                serviceName: item?.serviceName_EN,
              })
            }
          })
        })
      } else {
        // console.log('remain m data h', REMAINQUOTA)
        // console.log('remain data',remainingQuota)
        remainingQuota.find((item) => {
          selectedServicesQuota.map((data) => {
            // console.log('data', data)
            if (item?.value != data?.value) {
              REMAINQUOTA?.push({
                quota: item?.quota,
                value: item?.value,
                serviceName: item?.serviceName,
              })
            }
          })
        })
      }

      selectedServicesQuota?.forEach((item, key) => {
        REMAINQUOTA?.push(item)
      })

      data = {
        id: atob(id),
        itemName: values?.itemName,
        description: values?.description,
        attributeName: values?.attributeName,
        optionName: JSON.stringify(OPTIONS),
        services: JSON.stringify(SERVICES),
        remainServiceQuotaData: JSON.stringify(REMAINQUOTA),
        servicePrice: values?.packageServicesPrice,
        additionalServicePrice: values?.packageAdditionalServicesPrice,
        additionalServices: ADD_SERVICES ? JSON.stringify(ADD_SERVICES) : [],
        totalPrice: values?.totalPrice,
        itemStatus: status,
        userId: atob(userId),
        images: files,
      }
    }

    if (status == 'rejected') {
      var SERVICES = []
      if (values?.packageServices) {
        values?.packageServices?.forEach((item, key) => {
          SERVICES?.push(item?.value)
        })
      }
      data = {
        id: atob(id),
        itemName: values?.itemName,
        services: JSON.stringify(SERVICES),
        attributeName: values?.attributeName,
        optionName: JSON.stringify(OPTIONS),
        description: values?.description,
        itemStatus: status,
        images: files,
        userId: atob(userId),
      }
    }

    if (status == 'confirm') {
      console.log(' IN confirm second confirm status')
      if (
        values?.packageServices?.length == 0 ||
        files?.length == 0 ||
        values?.itemName == '' ||
        values?.description == ''
      )
        toast.error('All fields are required')
      else {
        post(`/admin/placed-order/add-examin-items`, { ...data, orderType: 'online' }, 1).then(
          (res) => {
            handleResponse(res)
          },
        )
      }
    } else if (status == 'pending') {
      if (values?.packageServices?.length == 0 || files?.length == 0 || values?.itemName == '')
        toast.error('All fields are required')
      else {
        post(`/admin/placed-order/add-examin-items`, { ...data, orderType: 'online' }, 1).then(
          (res) => {
            handleResponse(res)
          },
        )
      }
    } else {
      if (
        values?.packageServices?.length == 0 ||
        values?.description == '' ||
        files?.length <= 0 ||
        values?.itemName == ''
      )
        toast.error('All fields are required')
      else {
        post(`/admin/placed-order/add-examin-items`, { ...data, orderType: 'online' }, 1).then(
          (res) => {
            handleResponse(res)
          },
        )
      }
    }
  }

  const handleResponse = (res) => {
    if (res?.data) {
      console.log(res?.data)
      setOpenWithSubscriptionForm(false)
      setSelectService([])
      setSelectAdditionalService([])
      setImageURI([])
      setFiles([])
      setStatus('confirm')
      setSelectedItem([])
      setSelectedServicesQuota([])
      setSelectedAttribute([])
      setSelectedOptionList([])
      // REMAINQUOTA = []
      const filterArr = res?.data?.filter((aitem) => aitem?.itemStatus == 'pending')
      // console.log(filterArr, "fil arr")
      if (filterArr?.length == 0) {
        setIsButtonShow(true)
      }
    }
  }
  const outForServices = () => {
    setIsClickButton(true)
    put(`admin/placed-order/items-out-for-services?orderType=online&orderId=${atob(orderId)}`).then((response) => {
      handleSubmitButton(response)
    })
  }
  const handleSubmitButton = (response) => {
    if (response?.status == true) {
      toast?.success(response?.message)
      setIsButtonShow(false)
      setTimeout(() => {
        navigate(`/admin/placed-order`)
      }, 2000)
    }
  }
  return (
    <>
      <CContainer>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <CRow>
                        <CCol md="10">
                          <h2>Examine Order Items With Package</h2>
                        </CCol>
                        <CCol md="2">
                          <Link
                            onClick={() => setOpenWithSubscriptionForm(true)}
                            className="btn btn-dark mx-2"
                          >
                            <CIcon icon={cilPlus} />
                            Add
                          </Link>
                          <Link
                            className={'btn btn-success'}
                            style={{ background: '#d3dc40', border: '#d3dc40' }}
                            to={{ pathname: `/admin/placed-order` }}
                          >
                            Back
                          </Link>
                        </CCol>
                      </CRow>

                      {listDisable && addedItemsList?.length ? (
                        <CAccordion flush>
                          <CAccordionItem itemKey={1}>
                            <CAccordionHeader>Examin Items List</CAccordionHeader>
                            <CAccordionBody>
                              <CRow>
                                <CCol>
                                  <CCardGroup>
                                    <CCard className="p-4">
                                      <CHeader>
                                        <CCardSubtitle as="h4">
                                          Order ID: {atob(orderId)}
                                        </CCardSubtitle>
                                        <CRow>
                                          <CCol md="8"></CCol>
                                        </CRow>
                                      </CHeader>
                                      <CCardBody>
                                        <CTable bordered hover responsive>
                                          <CTableHead>
                                            <CTableRow>
                                              <CTableHeaderCell>S. No</CTableHeaderCell>
                                              <CTableHeaderCell>Item Name</CTableHeaderCell>
                                              <CTableHeaderCell>Package Name</CTableHeaderCell>
                                              <CTableHeaderCell>Item Status</CTableHeaderCell>
                                              <CTableHeaderCell>Bar Code</CTableHeaderCell>
                                              <CTableHeaderCell>Images</CTableHeaderCell>
                                            </CTableRow>
                                          </CTableHead>
                                          <CTableBody>
                                            {addedItemsList?.map((item, index) => {
                                              return (
                                                <CTableRow key={index}>
                                                  <CTableDataCell>{index + 1}</CTableDataCell>
                                                  <CTableDataCell>
                                                    {item?.itemName[0]?.itemName_EN}
                                                  </CTableDataCell>
                                                  <CTableDataCell>
                                                    {userPakageData[0]?.packageId?.packageName_EN}
                                                  </CTableDataCell>
                                                  <CTableDataCell>
                                                    {item?.itemStatus == 'rejected' ? (
                                                      <span className="text-danger">Rejected</span>
                                                    ) : item?.itemStatus == 'pending' ? (
                                                      <span className="text-info">Pending</span>
                                                    ) : item?.itemStatus == 'confirm' ? (
                                                      <span className="text-success">Approved</span>
                                                    ) : (
                                                      ''
                                                    )}
                                                  </CTableDataCell>
                                                  <CTableDataCell>
                                                    <p
                                                      dangerouslySetInnerHTML={{
                                                        __html: item?.barCode,
                                                      }}
                                                    ></p>
                                                  </CTableDataCell>

                                                  <CTableDataCell>
                                                    {item?.image?.map((i, index) => {
                                                      return (
                                                        <a target="_blank" href={`${ImageUrl}${i}`}>
                                                          <CImage
                                                            rounded
                                                            src={`${ImageUrl}${i}`}
                                                            className="mx-2"
                                                            style={{
                                                              maxHeight: '80px',
                                                              maxWidth: '80px',
                                                              alignItems: 'center',
                                                            }}
                                                          />
                                                        </a>
                                                      )
                                                    })}
                                                  </CTableDataCell>
                                                </CTableRow>
                                              )
                                            })}
                                          </CTableBody>
                                        </CTable>
                                        {isButtonShow && (
                                          <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                            <CButton color="primary" onClick={outForServices}>
                                              submit
                                            </CButton>
                                          </div>
                                        )}
                                      </CCardBody>
                                    </CCard>
                                  </CCardGroup>
                                </CCol>
                              </CRow>
                            </CAccordionBody>
                          </CAccordionItem>
                        </CAccordion>
                      ) : (
                        ''
                      )}

                      {openwithSubscriptionForm ? (
                        <Formik
                          initialValues={{
                            images: '',
                            itemName: '',
                            packageServices: '',
                            packageAdditionalServices: '',
                            packageServicesPrice: '',
                            packageAdditionalServicesPrice: '',
                            totalPrice: '',
                            description: '',
                            servicesQuota: '',
                            packageName: '',
                            attributeName: '',
                            optionName: '',
                          }}
                          onSubmit={(values, { setSubmitting }) => {
                            handlePackageFormSubmit(values)
                            setSubmitting(false)
                          }}
                          innerRef={formRef}
                        >
                          {(props) => (
                            <form onSubmit={props?.handleSubmit}>
                              <CRow>
                                <CCol md={6}>
                                  <div style={{ marginTop: '10px' }}>
                                    <CFormLabel>Item Status</CFormLabel>
                                    <span className="text-danger" style={{ marginLeft: '5px' }}>
                                      *
                                    </span>
                                    <CFormCheck
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="pending"
                                      label="Issue with Item/Clothes"
                                      onClick={(e) => openStatusInfo(e, 'pending')}
                                    />
                                    <CFormCheck
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="rejected"
                                      label="Reject"
                                      onClick={(e) => openStatusInfo(e, 'rejected')}
                                    />
                                    <CFormCheck
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="confirm"
                                      label="Confirm"
                                      defaultChecked
                                      onClick={(e) => openStatusInfo(e, 'confirm')}
                                    />
                                  </div>

                                  <CFormLabel>Item Name</CFormLabel>
                                  <span className="text-danger" style={{ marginLeft: '5px' }}>
                                    *
                                  </span>

                                  <Select
                                    defaultValue={selectedItem}
                                    onChange={(val) => {
                                      setSelectedItem(val)
                                      props?.setFieldValue('itemName', val?.value)
                                    }}
                                    options={itemOptions}
                                    value={selectedItem}
                                    placeholder={'Select Item Name'}
                                    name="Items"
                                  />
                                  {selectedItem == undefined ? (
                                    <span className="text-danger">{props?.errors?.itemName}</span>
                                  ) : (
                                    ''
                                  )}

                                  <CFormLabel>Brand Name</CFormLabel>
                                  <span className="text-danger" style={{ marginLeft: '5px' }}>
                                    *
                                  </span>

                                  <Select
                                    defaultValue={selectedAttribute}
                                    onChange={(val) => {
                                      setSelectedAttribute(val)
                                      getOptionsList(val?.value)
                                      props?.setFieldValue('attributeName', val?.value)
                                    }}
                                    options={AttributeOptions}
                                    value={selectedAttribute}
                                    placeholder={'Select Brand Name'}
                                    name="Brand"
                                  />
                                  {selectedAttribute == undefined ? (
                                    <span className="text-danger">
                                      {props?.errors?.attributeName}
                                    </span>
                                  ) : (
                                    ''
                                  )}

                                  <CFormLabel>Options</CFormLabel>
                                  <span className="text-danger" style={{ marginLeft: '5px' }}>
                                    *
                                  </span>

                                  <Select
                                    isMulti={true}
                                    defaultValue={selectedoptionList}
                                    onChange={(val) => {
                                      setSelectedOptionList(val)
                                      props?.setFieldValue('optionName', val)
                                    }}
                                    options={OptionsArr}
                                    value={selectedoptionList}
                                    placeholder={'Select Options Name'}
                                    name="Options"
                                  />
                                  {selectedoptionList == undefined ? (
                                    <span className="text-danger">{props?.errors?.optionName}</span>
                                  ) : (
                                    ''
                                  )}

                                  <CFormLabel>Package Name</CFormLabel>
                                  <span className="text-danger" style={{ marginLeft: '5px' }}>
                                    *
                                  </span>
                                  <DefaultInput
                                    type="text"
                                    disabled={true}
                                    placeholder={'Package name'}
                                    value={props?.values?.packageName}
                                    onChange={props?.handleChange('packageName')}
                                  />

                                  <CFormLabel>Image</CFormLabel>
                                  <span className="text-danger" style={{ marginLeft: '5px' }}>
                                    *
                                  </span>
                                  <DefaultInput
                                    multiple
                                    type={'file'}
                                    placeholder={'Image'}
                                    accept="image/*"
                                    refr={fileRef}
                                    onChange={(e) => uploadSingleFile(e)}
                                  />
                                  <div className="myImges row">
                                    {imageURI?.length > 0 &&
                                      imageURI?.map((item, index) => {
                                        return (
                                          <>
                                            <div className="col-md-3">
                                              <figure>
                                                <img src={`${item}`} />
                                                <figcaption>
                                                  <button type="button">
                                                    <FaTrash
                                                      onClick={() => deleteParticularFile(index)}
                                                      style={{ color: 'red' }}
                                                    />
                                                  </button>
                                                </figcaption>
                                              </figure>
                                            </div>
                                          </>
                                        )
                                      })}
                                  </div>
                                </CCol>
                                {pendingStatus ? (
                                  <CCol md="6">
                                    <CFormLabel>Services</CFormLabel>
                                    <span className="text-danger" style={{ marginLeft: '5px' }}>
                                      *
                                    </span>
                                    <Select
                                      isMulti={true}
                                      defaultValue={selectService}
                                      onChange={(val) => {
                                        setSelectService(val)
                                        if (Object.keys(val)?.length == 0) {
                                          setSelectedServicesQuota([])
                                          servicesPrice = 0
                                          formRef.current.setFieldValue(
                                            'packageServicesPrice',
                                            servicesPrice,
                                          )
                                          formRef.current.setFieldValue(
                                            'totalPrice',
                                            props?.values?.packageAdditionalServicesPrice,
                                          )
                                          formRef.current.setFieldValue('servicesQuota', 0)
                                        }
                                        allServices?.find((element) => {
                                          val?.map((item) => {
                                            if (element?._id == item?.value) {
                                              servicesPrice = servicesPrice + element?.price
                                              formRef.current.setFieldValue(
                                                'packageServicesPrice',
                                                servicesPrice,
                                              )
                                              formRef.current.setFieldValue(
                                                'totalPrice',
                                                props?.values?.packageAdditionalServicesPrice +
                                                  servicesPrice,
                                              )
                                            }
                                          })
                                        })

                                        if (
                                          remainingQuota == undefined ||
                                          remainingQuota?.length == 0
                                        ) {
                                          allServices.find((element) => {
                                            val?.map((item) => {
                                              if (element?._id == item?.value) {
                                                servicesQuotaData?.push({
                                                  quota: element?.serviceQuota - 1,
                                                  value: element?._id,
                                                  serviceName: element?.serviceName_EN,
                                                })
                                                setSelectedServicesQuota(servicesQuotaData)
                                              }
                                            })
                                          })
                                        } else {
                                          remainingQuota?.find((data) => {
                                            val?.map((valItem) => {
                                              // console.log('vallllllllllll', valItem)
                                              if (valItem?.value == data?.value) {
                                                servicesQuotaData?.push({
                                                  quota: data?.quota - 1,
                                                  value: data?.value,
                                                  serviceName: data?.serviceName,
                                                })
                                                return setSelectedServicesQuota(servicesQuotaData)
                                              }
                                            })
                                          })
                                        }

                                        handleAdditionalOptions(val)
                                        props.setFieldValue('packageServices', val)
                                      }}
                                      options={packageSelectedServices}
                                      value={selectService}
                                      placeholder={'Select Services'}
                                      name="Services"
                                    />
                                    <CFormLabel>Additional Services</CFormLabel>
                                    <Select
                                      isMulti={true}
                                      defaultValue={selectAdditionalService}
                                      onChange={(val) => {
                                        setSelectAdditionalService(val)
                                        if (Object.keys(val).length == 0) {
                                          additionalServicePrice = 0
                                          formRef.current.setFieldValue(
                                            'packageAdditionalServicesPrice',
                                            additionalServicePrice,
                                          )
                                          formRef.current.setFieldValue(
                                            'totalPrice',
                                            props?.values?.packageServicesPrice,
                                          )
                                        }

                                        allServices?.find((element) => {
                                          val?.map((item) => {
                                            if (element?._id == item?.value) {
                                              additionalServicePrice =
                                                additionalServicePrice + element?.price
                                              formRef.current.setFieldValue(
                                                'packageAdditionalServicesPrice',
                                                additionalServicePrice,
                                              )
                                              formRef.current.setFieldValue(
                                                'totalPrice',
                                                props?.values?.packageServicesPrice +
                                                  additionalServicePrice,
                                              )
                                            }
                                          })
                                        })
                                        props.setFieldValue('packageAdditionalServices', val)
                                      }}
                                      options={additionalServicesOptions}
                                      value={selectAdditionalService}
                                      placeholder={'Select Additional Services'}
                                      name="Additional Services"
                                    />

                                    {selectedServicesQuota?.length > 0
                                      ? Object.values(selectedServicesQuota).map((item, index) => {
                                          return (
                                            <CCol style={{ marginBottom: '5px' }}>
                                              <CFormLabel>
                                                {item?.serviceName} (Remain Quota)
                                              </CFormLabel>
                                              <span
                                                className="text-danger"
                                                style={{ marginLeft: '5px' }}
                                              >
                                                *
                                              </span>
                                              <DefaultInput
                                                type="number"
                                                disabled={true}
                                                placeholder={'Remain Service Quota'}
                                                value={item?.quota}
                                                onChange={props?.handleChange('servicesQuota')}
                                              />
                                            </CCol>
                                          )
                                        })
                                      : ''}

                                    <CFormLabel>Services Price</CFormLabel>
                                    <span className="text-danger" style={{ marginLeft: '5px' }}>
                                      *
                                    </span>
                                    <DefaultInput
                                      type="number"
                                      disabled={true}
                                      placeholder={'Services price'}
                                      value={props?.values?.packageServicesPrice}
                                      onChange={props?.handleChange('packageServicesPrice')}
                                    />

                                    <CFormLabel>Additonal Services Price</CFormLabel>
                                    {/* <span className='text-danger' style={{ marginLeft: '5px' }}>*</span> */}
                                    <DefaultInput
                                      type="number"
                                      disabled={true}
                                      placeholder={'Additonal services price'}
                                      value={props?.values?.packageAdditionalServicesPrice}
                                      onChange={props?.handleChange(
                                        'packageAdditionalServicesPrice',
                                      )}
                                    />

                                    <CFormLabel>Approximate Total Price</CFormLabel>
                                    <span className="text-danger" style={{ marginLeft: '5px' }}>
                                      *
                                    </span>
                                    <DefaultInput
                                      type="number"
                                      disabled={true}
                                      placeholder={'Approximate total price'}
                                      value={props?.values?.totalPrice}
                                      onChange={props?.handleChange('totalPrice')}
                                    />
                                  </CCol>
                                ) : (
                                  ''
                                )}

                                {rejectedStatus ? (
                                  <CCol md={6}>
                                    <CFormLabel>Services</CFormLabel>
                                    <span className="text-danger" style={{ marginLeft: '5px' }}>
                                      *
                                    </span>
                                    <Select
                                      isMulti={true}
                                      defaultValue={selectService}
                                      onChange={(val) => {
                                        setSelectService(val)
                                        // props?.setFieldValue('services', val)
                                        props?.setFieldValue('packageServices', val)
                                      }}
                                      // options={servicesOptions}
                                      options={packageSelectedServices}
                                      value={selectService}
                                      placeholder={'Select Services'}
                                      name="Services"
                                    />
                                    <DefaultTextarea
                                      placeholder={'Description'}
                                      name={'Description'}
                                      value={props?.values?.description}
                                      onChange={props?.handleChange('description')}
                                    />
                                  </CCol>
                                ) : (
                                  ''
                                )}

                                {confirmStatus ? (
                                  <CCol md={6}>
                                    <CFormLabel>Services</CFormLabel>
                                    <span className="text-danger" style={{ marginLeft: '5px' }}>
                                      *
                                    </span>
                                    <Select
                                      isMulti={true}
                                      defaultValue={selectService}
                                      onChange={(val) => {
                                        setSelectService(val)
                                        if (Object.keys(val)?.length == 0) {
                                          setSelectedServicesQuota([])
                                          servicesPrice = 0
                                          formRef.current.setFieldValue(
                                            'packageServicesPrice',
                                            servicesPrice,
                                          )
                                          formRef.current.setFieldValue(
                                            'totalPrice',
                                            props?.values?.packageAdditionalServicesPrice,
                                          )
                                        }

                                        allServices.find((element) => {
                                          val?.map((item) => {
                                            if (element?._id == item?.value) {
                                              servicesPrice = servicesPrice + element?.price
                                              formRef.current.setFieldValue(
                                                'packageServicesPrice',
                                                servicesPrice,
                                              )
                                              formRef.current.setFieldValue(
                                                'totalPrice',
                                                props?.values?.packageAdditionalServicesPrice +
                                                  servicesPrice,
                                              )
                                            }
                                          })
                                        })

                                        if (
                                          remainingQuota == undefined ||
                                          remainingQuota?.length == 0
                                        ) {
                                          allServices.find((element) => {
                                            val?.map((item) => {
                                              if (element?._id == item?.value) {
                                                servicesQuotaData?.push({
                                                  quota: element?.serviceQuota - 1,
                                                  value: element?._id,
                                                  serviceName: element?.serviceName_EN,
                                                })
                                                setSelectedServicesQuota(servicesQuotaData)
                                              }
                                            })
                                          })
                                        } else {
                                          remainingQuota?.find((data) => {
                                            val?.map((valItem) => {
                                              // console.log('vallllllllllll', valItem)
                                              if (valItem?.value == data?.value) {
                                                servicesQuotaData?.push({
                                                  quota: data?.quota - 1,
                                                  value: data?.value,
                                                  serviceName: data?.serviceName,
                                                })
                                                return setSelectedServicesQuota(servicesQuotaData)
                                              }
                                            })
                                          })
                                        }
                                        handleAdditionalOptions(val)
                                        props.setFieldValue('packageServices', val)
                                      }}
                                      options={packageSelectedServices}
                                      value={selectService}
                                      placeholder={'Select Services'}
                                      name="Services"
                                    />

                                    <CFormLabel>Additional Services</CFormLabel>
                                    <Select
                                      isMulti={true}
                                      defaultValue={selectAdditionalService}
                                      onChange={(val) => {
                                        setSelectAdditionalService(val)
                                        if (Object.keys(val).length == 0) {
                                          additionalServicePrice = 0
                                          formRef.current.setFieldValue(
                                            'packageAdditionalServicesPrice',
                                            additionalServicePrice,
                                          )
                                          formRef.current.setFieldValue(
                                            'totalPrice',
                                            props?.values?.packageServicesPrice,
                                          )
                                        }

                                        allServices?.find((element) => {
                                          val?.map((item) => {
                                            if (element?._id == item?.value) {
                                              additionalServicePrice =
                                                additionalServicePrice + element?.price
                                              formRef.current.setFieldValue(
                                                'packageAdditionalServicesPrice',
                                                additionalServicePrice,
                                              )
                                              formRef.current.setFieldValue(
                                                'totalPrice',
                                                props?.values?.packageServicesPrice +
                                                  additionalServicePrice,
                                              )
                                            }
                                          })
                                        })
                                        props.setFieldValue('packageAdditionalServices', val)
                                      }}
                                      options={additionalServicesOptions}
                                      value={selectAdditionalService}
                                      placeholder={'Select Additional Services'}
                                      name="Additional Services"
                                    />

                                    {selectedServicesQuota?.length > 0
                                      ? Object.values(selectedServicesQuota).map((item, index) => {
                                          return (
                                            <CCol style={{ marginBottom: '5px' }}>
                                              <CFormLabel>
                                                {item?.serviceName} (Remain Quota)
                                              </CFormLabel>
                                              <span
                                                className="text-danger"
                                                style={{ marginLeft: '5px' }}
                                              >
                                                *
                                              </span>
                                              <DefaultInput
                                                type="number"
                                                disabled={true}
                                                placeholder={'Remain Service Quota'}
                                                value={item?.quota}
                                                onChange={props?.handleChange('servicesQuota')}
                                              />
                                            </CCol>
                                          )
                                        })
                                      : ''}

                                    <CFormLabel>Services Price</CFormLabel>
                                    <span className="text-danger" style={{ marginLeft: '5px' }}>
                                      *
                                    </span>
                                    <DefaultInput
                                      type="number"
                                      disabled={true}
                                      placeholder={'Services price'}
                                      value={props?.values?.packageServicesPrice}
                                      onChange={props?.handleChange('packageServicesPrice')}
                                    />
                                    <CFormLabel>Additonal Services Price</CFormLabel>
                                    <span className="text-danger" style={{ marginLeft: '5px' }}>
                                      *
                                    </span>
                                    <DefaultInput
                                      type="number"
                                      disabled={true}
                                      placeholder={'Additonal services price'}
                                      value={props?.values?.packageAdditionalServicesPrice}
                                      onChange={props?.handleChange(
                                        'packageAdditionalServicesPrice',
                                      )}
                                    />

                                    <CFormLabel>Approximate Total Price</CFormLabel>
                                    <span className="text-danger" style={{ marginLeft: '5px' }}>
                                      *
                                    </span>
                                    <DefaultInput
                                      type="number"
                                      disabled={true}
                                      placeholder={'Approximate total price'}
                                      value={props?.values?.totalPrice}
                                      onChange={props?.handleChange('totalPrice')}
                                    />
                                    <DefaultTextarea
                                      placeholder={'Description'}
                                      name={'Description'}
                                      value={props?.values?.description}
                                      onChange={props?.handleChange('description')}
                                    />

                                    {servicesQuotaEmpty?.length > 0 ? (
                                      <span>
                                        <b>Note:- </b>
                                        <span>
                                          {showExccedServices(servicesQuotaEmpty)} services quota
                                          has been exceed.
                                        </span>
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                    {/* {servicesQuotaEmpty?.length > 0 ?
                                      <span>
                                        <b>Note:- </b>{servicesQuotaEmpty?.map((data)=> {
                                          servicesUsedArr.push(data?.label)
                                        })}
                                        <span>{servicesUsedArr?.join(',')} services quota has been exceed.</span>
                                      </span>
                                    : ""} */}
                                  </CCol>
                                ) : (
                                  ''
                                )}
                              </CRow>
                              <CRow>
                                <CCol md="2">
                                  <button type="submit" className={'btn btn-success mt-2'}>
                                    Submit
                                  </button>
                                </CCol>
                                <CCol md="3">
                                  <button
                                    type="button"
                                    className={'btn btn-warning mt-2'}
                                    onClick={() => setOpenWithSubscriptionForm(false)}
                                  >
                                    Cancel
                                  </button>
                                </CCol>
                              </CRow>
                            </form>
                          )}
                        </Formik>
                      ) : (
                        ''
                      )}
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default PackageExaminItems
