import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { get, deleteApi, post } from '../../utils/apiManager'
import CIcon from '@coreui/icons-react'
import { cilPen, cilPlus, cilTrash } from '@coreui/icons'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CCardSubtitle,
  CTable,
  CRow,
  CImage,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import { DefaultInputWithIcon } from 'src/components/common/input'
import Pagination from 'react-js-pagination'
import { FaRegEye, FaEdit, FaTrash } from 'react-icons/fa'
import ShowMoreText from 'react-show-more-text'
import { ImageUrl } from 'src/config/config'

function index() {
  const navigate = useNavigate()
  const [introList, setIntroList] = useState([])
  const [imageUrl, setimageUrl] = useState(ImageUrl)
  const [totalPages, settotalPages] = useState(0)
  const [deletedArray, setDeletedArray] = useState([])

  const [pageData, setpageData] = useState({
    page: 1,
    perPage: 10,
    searchItem: '',
  })
  const [searchItem, setsearchItem] = useState('')

  useEffect(() => {
    getintroData()
  }, [pageData])

  const getintroData = () => {
    get(
      `/admin/Intro/get-intro?page=${pageData?.page || 1}&perPage=${pageData?.perPage || 10
      }&searchItem=${pageData?.searchItem || ''}`,
    ).then((response) => {
      handleResponse(response)
    })
  }
  const handleResponse = (response) => {
    if (!response?.status) toast.success(response?.message, response?.status)
    else {
      setIntroList([...response?.data])
      settotalPages(response?.pages)
      // setimageUrl(response?.imageUrl)
    }
  }
  const changePage = (index) => {
    setpageData({
      ...pageData,
      page: index,
    })
  }
  const onSearchCLick = () => {
    setpageData({
      ...pageData,
      searchItem: searchItem,
    })
  }
  const onClickUserEdit = async (value) => {
    navigate('/admin/intro/edit-intro', { state: value })
  }
  const onClickUserView = (value) => {
    navigate(`/admin/intro/intro-detail/${value?._id}`, { state: value })
  }
  const deleteData = (id) => {
    if (confirm('Are you sure')) {
      let formData = {
        _id: id,
      }
      deleteApi(`/admin/Intro/delete?_id=${id}`).then((data) => {
        handleDelete(data)
      })
    }
  }
  const handleDelete = (data) => {
    toast.success(data?.message, data?.status)
    getintroData()
  }
  const executeOnClick = async (isExpanded) => {
    // console.log(isExpanded);
  }

  const handleCheck = (id) => {
    let findValue = deletedArray.includes(id)
    if (findValue) {
      let filterData = deletedArray?.filter((item) => item != id)
      setDeletedArray(filterData)
    } else {
      setDeletedArray((prev) => [...prev, id])
    }
  }

  const deleteOrders = () => {
    post(`admin/Intro/deleted-intro`, deletedArray).then((data) =>
      handleDeletedResponse(data),
    )
  }

  const handleDeletedResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      setDeletedArray([])
      getintroData()
    }
  }


  return (
    <>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader >
                  <div className="d-flex" style={{ flexDirection: 'column' }}>
                    <CCardSubtitle as="h4">Intro's</CCardSubtitle>
                    {deletedArray.length > 0 && (
                      <button className="btn btn-danger mt-2 text-white" onClick={deleteOrders}>
                        Delete
                      </button>
                    )}
                  </div>
                  <CRow>
                    <CCol md="8">
                      <DefaultInputWithIcon
                        type={'text'}
                        placeholder="Search"
                        customType={'search'}
                        value={searchItem}
                        onClick={onSearchCLick}
                        onChange={(e) => {
                          onSearchCLick()
                          setsearchItem(e?.target?.value)
                        }}
                      />
                    </CCol>
                    {/* <CCol md="2"></CCol> */}
                    <CCol md="4">
                      <Link to={{ pathname: '/admin/intro/add-intro' }} className="btn btn-dark">
                        <CIcon icon={cilPlus} />
                        Add
                      </Link>
                    </CCol>
                  </CRow>
                </CHeader>

                <CCardBody>
                  <CTable bordered hover responsive>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell>S. No</CTableHeaderCell>
                        <CTableHeaderCell>Title</CTableHeaderCell>
                        {/* <CTableHeaderCell>Title (English)</CTableHeaderCell> */}
                        {/* <CTableHeaderCell>Title (Thai)</CTableHeaderCell> */}
                        <CTableHeaderCell>Image</CTableHeaderCell>
                        <CTableHeaderCell>Description</CTableHeaderCell>
                        {/* <CTableHeaderCell>Description (English)</CTableHeaderCell> */}
                        {/* <CTableHeaderCell>Description (Thai)</CTableHeaderCell> */}
                        <CTableHeaderCell>Action</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {introList &&
                        introList?.map((data, key) => {
                          return (
                            <CTableRow key={key} onClick={() => handleCheck(data._id)}>

                              <CTableDataCell className="col col-md-1">
                                {pageData?.perPage * (pageData?.page - 1) + key + 1}
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    style={{ marginLeft: '2px', marginTop: '-20px' }}
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                    checked={deletedArray?.includes(data._id)}
                                  // onChange={() => handleCheck(ele._id)}
                                  />
                                </div>
                              </CTableDataCell>
                              <CTableDataCell>{data.title_en}</CTableDataCell>
                              {/* <CTableDataCell>{data.title_th}</CTableDataCell> */}

                              <CTableDataCell className="col col-md-3">
                                <CImage
                                  rounded
                                  src={`${imageUrl}${data?.image}`}
                                  // onError={addDefaultSrc}
                                  style={{
                                    maxHeight: '50px',
                                    maxWidth: '50px',
                                    borderRadius: 50,
                                    alignItems: 'center',
                                  }}
                                />
                              </CTableDataCell>

                              <CTableDataCell className="col col-md-3">
                                <ShowMoreText
                                  lines={1}
                                  more="Show more"
                                  less="Show less"
                                  className="content-css"
                                  anchorClass="my-anchor-css-class"
                                  onClick={executeOnClick}
                                  expanded={false}
                                  truncatedEndingComponent={'... '}
                                >
                                  <p dangerouslySetInnerHTML={{ __html: data.description_en }}></p>
                                </ShowMoreText>
                              </CTableDataCell>

                              {/* <CTableDataCell
                                className="col col-md-8"
                              >

                                <ShowMoreText
                                  lines={1}
                                  more="Show more"
                                  less="Show less"
                                  className="content-css"
                                  anchorClass="my-anchor-css-class"
                                  onClick={executeOnClick}
                                  expanded={false}
                                  truncatedEndingComponent={"... "}
                                >
                                  <p dangerouslySetInnerHTML={{ __html: data.description_th }}></p>
                                </ShowMoreText>


                              </CTableDataCell> */}

                              <CTableDataCell className="col col-md-2">
                                <span
                                  className="mx-2"
                                  onClick={() => onClickUserEdit(data)}
                                  style={{ fontSize: '20px', color: '#d3dc40', cursor: 'pointer' }}
                                >
                                  <FaEdit />
                                </span>
                                <span
                                  className="mx-2 text-danger"
                                  onClick={() => deleteData(data?._id)}
                                  style={{ fontSize: '20px', padding: '5px', cursor: 'pointer' }}
                                >
                                  <FaTrash />
                                </span>
                                <span
                                  className="mx-2"
                                  onClick={() => onClickUserView(data)}
                                  style={{
                                    fontSize: '20px',
                                    color: '#d3dc40',
                                    padding: '5px',
                                    cursor: 'pointer',
                                  }}
                                >
                                  <FaRegEye />
                                </span>
                              </CTableDataCell>
                            </CTableRow>
                          )
                        })}
                    </CTableBody>
                  </CTable>
                  {!introList?.length && (
                    <>
                      <h1>No Data</h1>
                    </>
                  )}
                  {introList?.length ? (
                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={pageData?.page}
                      itemsCountPerPage={pageData?.perPage}
                      totalItemsCount={totalPages * 10}
                      pageRangeDisplayed={5}
                      onChange={changePage}
                    />
                  ) : (
                    ''
                  )}
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default index
