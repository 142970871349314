import React, { useRef, useState, useEffect } from 'react'
import { Formik } from 'formik'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { post, get, put } from 'src/utils/apiManager'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ImageUrl } from 'src/config/config'
import { CCard, CCardBody, CCardGroup, CCol, CContainer, CFormLabel, CImage, CRow } from '@coreui/react'
import { DefaultInput } from 'src/components/common/input'
import { validateItem, validateProcess, validateProcessEdit } from 'src/utils/validator'
function edit() {
    const navigate = useNavigate()
    const { state } = useLocation()
    const formRef = useRef(null)
    const [imageUrl, setimageUrl] = useState()
    const fileRef = useRef(null)
    const [invalidImage, setInvalidImage] = useState('')
    const [selecteSizeOption, setSelecteSizeOption] = useState(null)
    const [itemSizeData, setItemSizeData] = useState(null)
    const options = []
    const [itemCategoryData, setItemCategoryData] = useState(null)
    const [disable, setDisable] = useState(false)
    const [id, setId] = useState()
    const ItemCategoryoptions = []
    const [itemImagePreview, setItemImagePreview] = useState();

    useEffect(() => {
        if (state != null) {
            formRef.current.setFieldValue('processName_EN', state?.processName_EN)
            formRef.current.setFieldValue('processName_TH', state?.processName_TH)
            if (state?.image) setimageUrl(`${ImageUrl}${state?.image}`)
            setId(state?._id)
        }
    }, [state])


    const getItemSize = () => {
        get(`admin/items/item-size`).then((data) => {
            setItemSizeData(data?.data)
        })
    }
    const submit = async (values) => {
        setDisable(true)
        let formData = new FormData()
        formData.append('processName_EN', values?.processName_EN)
        formData.append('processName_TH', values?.processName_TH)
        formData.append('image', values?.image)
        put(`admin/washing-process/` + id, formData, 1).then((data) => handleResponse(data))
    }
    const handleResponse = (data) => {
        if (data?.status == true) {
            toast.success(data?.message)
            setTimeout(() => {
                navigate('/admin/wash-process')
                // formRef.current.resetForm()
                // fileRef.current.value = null
                setSelecteSizeOption('')
            }, 800)
        } else {
            setDisable(false)
            toast.error(data?.message)
        }

    }
    const onClickReset = () => {
        console.log('this')
        console.log(this)
        // Reset Button Function
        // formRef.current.setFieldValue('vanNo', '')
    }

    return (
        <>
            <CContainer>
                <CRow>
                    <CCol>
                        <CCardGroup>
                            <CCard className="p-4">
                                <CCol xs={12}>
                                    <CCardGroup>
                                        <CCardBody>
                                            <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                                            <CRow>
                                                <CCol md="11">
                                                    <h1>Edit Washing Process</h1>
                                                </CCol>
                                                <CCol md="1">
                                                    <Link
                                                        className={'btn btn-success mt-2'}
                                                        style={{ background: '#d3dc40', border: '#d3dc40' }}
                                                        to={{ pathname: `/admin/wash-process` }}
                                                    >
                                                        Back
                                                    </Link>
                                                </CCol>
                                            </CRow>
                                            <Formik
                                                initialValues={{
                                                    processName_EN: '',
                                                    processName_TH: '',
                                                    image: '',
                                                }}
                                                validationSchema={validateProcessEdit}
                                                onSubmit={(values, { setSubmitting }) => {
                                                    submit(values)
                                                    setSubmitting(false)
                                                }}
                                                innerRef={formRef}
                                            >
                                                {(props) => (
                                                    <form onSubmit={props.handleSubmit}>
                                                        <CRow>
                                                            <CCol md={6}>
                                                                <CFormLabel>Process Name (EN)</CFormLabel>
                                                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                                                <DefaultInput
                                                                    type={'text'}
                                                                    placeholder={'Process Name (EN)'}
                                                                    value={props?.values?.processName_EN}
                                                                    onChange={props?.handleChange('processName_EN')}
                                                                    onBlur={() => props?.setFieldTouched('processName_EN', true)}
                                                                    error={props?.touched?.processName_EN && props?.errors?.processName_EN}
                                                                />
                                                                <CFormLabel>Image</CFormLabel>
                                                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                                                <DefaultInput
                                                                    type={'file'}
                                                                    placeholder={'Image'}
                                                                    accept="image/*"
                                                                    refr={fileRef}
                                                                    onChange={(e) => {
                                                                        const imageFile = e?.target?.files[0]

                                                                        if (!imageFile) {
                                                                            return setInvalidImage('Please select valid image')
                                                                        }
                                                                        if (!imageFile?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
                                                                            setInvalidImage('Please select valid image')
                                                                        } else {
                                                                            setInvalidImage('')
                                                                        }
                                                                        //for image preview

                                                                        if (imageFile?.name) {
                                                                            setimageUrl(null)
                                                                        }
                                                                        const reader = new FileReader();
                                                                        reader?.addEventListener("load", () => setItemImagePreview(reader?.result));
                                                                        reader?.readAsDataURL(e?.target?.files[0]);
                                                                        //for image preview
                                                                        props?.setFieldValue('image', e?.target?.files[0])
                                                                    }}
                                                                    onBlur={() => props?.setFieldTouched('image', true)}
                                                                    error={
                                                                        (props?.touched?.image && props?.errors?.image) || invalidImage
                                                                    }
                                                                />
                                                                {/* for image preview */}
                                                                {itemImagePreview?.length > 0 ?
                                                                    <>
                                                                        <CCol>
                                                                            <CImage
                                                                                rounded
                                                                                thumbnail
                                                                                className="mt-2"
                                                                                src={`${itemImagePreview}`}
                                                                                style={{
                                                                                    maxHeight: '80px',
                                                                                    maxWidth: '80px',
                                                                                    alignItems: 'center',
                                                                                }}
                                                                            />
                                                                        </CCol>
                                                                    </>
                                                                    : ''}
                                                                {/* for image preview */}
                                                                {imageUrl && (
                                                                    <CImage
                                                                        rounded
                                                                        thumbnail
                                                                        className="mt-2"
                                                                        src={`${imageUrl}`}
                                                                        style={{
                                                                            maxHeight: '80px',
                                                                            maxWidth: '80px',
                                                                            alignItems: 'center',
                                                                        }}
                                                                    />
                                                                )}
                                                            </CCol>
                                                            <CCol md="6">
                                                                <CFormLabel>Process Name (TH)</CFormLabel>
                                                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                                                <DefaultInput
                                                                    type={'text'}
                                                                    placeholder={'Process Name (TH)'}
                                                                    value={props?.values?.processName_TH}
                                                                    onChange={props?.handleChange('processName_TH')}
                                                                    onBlur={() => props?.setFieldTouched('processName_TH', true)}
                                                                    error={props?.touched?.processName_TH && props?.errors?.processName_TH}
                                                                />
                                                            </CCol>
                                                        </CRow>
                                                        <CRow>
                                                            <CCol md="2">
                                                                <button
                                                                    type="submit"
                                                                    className={'btn btn-success mt-2'}
                                                                    disabled={disable || props?.isSubmitting}
                                                                >
                                                                    Submit
                                                                </button>
                                                            </CCol>
                                                            <CCol md="2">
                                                                <button
                                                                    type="button"
                                                                    className={'btn btn-warning mt-2'}
                                                                    onClick={() => onClickReset(props.isSubmitting)}
                                                                >
                                                                    Reset
                                                                </button>
                                                            </CCol>
                                                            <CCol md="3"></CCol>
                                                        </CRow>
                                                    </form>
                                                )}
                                            </Formik>
                                        </CCardBody>
                                    </CCardGroup>
                                </CCol>
                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </>
    )
}

export default edit
