import React, { useRef, useState } from 'react'
import { CCard, CCardBody, CCardGroup, CCardHeader, CCol, CContainer, CRow } from '@coreui/react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Barcode from 'src/assets/images/barcode-scan.png'
import { post } from 'src/utils/apiManager'
import { toast } from 'react-toastify'


const OrderScanner = () => {
    const inputRef = useRef()
    const navigate = useNavigate()
    const [barcodeValue, setBarcodeValue] = useState('')
    const { state } = useLocation()
    const { item_id, bag_id, lastPageNavigateData } = state


    const handleSubmit = () => {
        let temp = {
            item_id,
            bag_id,
            status: "active"
        }
        post(`admin/offline-order/changeItemStatus`, temp).then((data) => {
            toast.success(data?.message)
            navigate("/admin/offline-orders/items", { state: { item: lastPageNavigateData?.item, orderData: lastPageNavigateData?.orderData } })
        }).catch(() => {
            console.log("error", error)
        })
    }


    const handleKeyPress = (event) => {
        if (event.charCode === 13 && barcodeValue.length > 0) {
            handleSubmit()
        }
    }

    return (
        <>
            <CCard>
                <CCardGroup>
                    <CCardBody>
                        {/* <Link
                            className={'btn btn-success'}
                            style={{ background: '#d3dc40', border: '#d3dc40', float: "right" }}
                            to={{ pathname: `/admin/offline-order/bag/add-item-service/${bagId}/${orderId}` }}
                        >
                            Back
                        </Link> */}
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '70vh',
                                // overflowY: 'scroll',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <img
                                src={Barcode}
                                alt="barcode" style={{ maxWidth: '200px' }}
                            />
                            <h4 className='mt-3'>Scan OR code for item</h4>
                            <input
                                autoFocus={true}
                                // disabled
                                // hidden
                                // style={{ border: 'none', outline: 'none', widht: '0', height: '0' }}
                                ref={inputRef}
                                name="barcodeValue"
                                id="barcodeValue"
                                value={barcodeValue}
                                onChange={(event) => setBarcodeValue(event.target.value)}
                                onKeyPress={handleKeyPress}
                            // onClick={console.log('running')}
                            />
                        </div>

                        <div className="d-flex justify-content-end gap-2">
                            <button
                                className={'btn btn-success'}
                                style={{ background: '', border: '#d3dc40', }}
                                onClick={handleSubmit}
                            >
                                Submit
                            </button>
                        </div>
                    </CCardBody>
                </CCardGroup>
            </CCard>
        </>
    )
}

export default OrderScanner