import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilBell, cilEnvelopeOpen, cilList, cilMenu } from '@coreui/icons'

import { AppBreadcrumb } from './index'
import { AppHeaderDropdown } from './header/index'
import { logo } from 'src/assets/brand/logo'
import logoImage from 'src/assets/images/logo.png'
// import {sliderShow} from 'src/reduxToolkit/slice/sliderShow'
import { toggleInvisible, toggleVisible } from 'src/reduxToolkit/slice/auth'
import { toggleFilter, toggleFilterInvisible, toggleFilterVisible } from 'src/reduxToolkit/slice/auth'

const AppHeader = () => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const slideSelecter = useSelector((state) => { return state?.auth?.sidebarShow })
  const filterSelecter = useSelector(state => state?.auth?.filterShow);


  const handleSideBarToggle = () => {
    { slideSelecter ? dispatch(toggleInvisible()) : dispatch(toggleVisible()) }
  }

  const handlefilterToggle = () => {
    { filterSelecter ? dispatch(toggleFilterInvisible()) : dispatch(toggleFilterVisible()) }
  }


  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        {/* side toggle code */}
        <CHeaderToggler
          className="ps-1"
          onClick={() => handleSideBarToggle()}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        {/* side toggle code */}
        <CHeaderBrand className="mx-auto d-md-none" to="/">
          <img src={logoImage ? logoImage : ''} />{' '}<strong>Admin Panel</strong>
          {/* <CIcon icon={logo} height={48} alt="Logo" /> */}
        </CHeaderBrand>
        <CHeaderNav className="d-none d-md-flex me-auto">
          <CNavItem>
            <CNavLink to="/dashboard" component={NavLink}>
              Dashboard
            </CNavLink>
          </CNavItem>
          {/* <CNavItem>
            <CNavLink href="#">Users</CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">Settings</CNavLink>
          </CNavItem> */}
        </CHeaderNav>
        {/* <CHeaderNav>
          <CNavItem>
            <CNavLink href="#">
              <CIcon icon={cilBell} size="lg" />
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">
              <CIcon icon={cilList} size="lg" />
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">
              <CIcon icon={cilEnvelopeOpen} size="lg" />
            </CNavLink>
          </CNavItem>
        </CHeaderNav> */}
        <CHeaderNav className="ms-3">
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
      <CHeaderDivider />
      <CContainer fluid>
        <AppBreadcrumb />
      </CContainer>
      {pathname === "/admin/dashboard" && <div style={{ position: "absolute", right: "50px", top: "76px", cursor: "pointer" }} onClick={() => handlefilterToggle()}>
        <i class="fa-solid fa-filter" ></i>
      </div>}
    </CHeader>
  )
}

export default AppHeader
