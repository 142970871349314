import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { post, get, deleteApi } from '../../utils/apiManager'
import CIcon from '@coreui/icons-react'
import { cilPlus } from '@coreui/icons'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CCardSubtitle,
  CTable,
  CRow,
  CImage,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CButton,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import { DefaultInputWithIcon } from 'src/components/common/input'
import Pagination from 'react-js-pagination'
import { FaRegEye, FaEdit, FaTrash, FaPrint, FaRecycle } from 'react-icons/fa'
import ShowMoreText from 'react-show-more-text'

function add() {
  const navigate = useNavigate()
  const [randaomQR, setRandomQR] = useState([])
  const [searchItem, setsearchItem] = useState('')
  const [totalPages, setTotalPages] = useState(0)
  const [count, setCount] = useState()
  const [pageData, setPageData] = useState({
    page: 1,
    perPage: 10,
    searchItem: '',
  })
  const [deletedArray, setDeletedArray] = useState([])
  useEffect(() => {
    getData()
  }, [pageData])

  const getData = () => {
    get(
      `/admin/bulk-order/get-QR-code?page=${pageData?.page || 1}&perPage=${pageData?.perPage || 10
      }&searchItem=${pageData?.searchItem || ''}`,
    ).then((data) => handleResponse(data))
  }
  const handleResponse = (data) => {
    if (!data?.status) toast.success(data?.message, data?.status)
    else {
      setRandomQR(data?.data?.freshQrCode[0]?.data)
      setTotalPages(data?.data?.freshQrCode[0]?.metadata[0]?.page)
      setCount(data?.data?.freshQrCode[0]?.metadata[0]?.total)
    }
  }

  const changePage = (index) => {
    setPageData({
      ...pageData,
      page: index,
    })
  }

  const deleteData = (id) => {
    if (confirm('Are you sure')) {
      deleteApi(`/admin/bulk-order/delete-qr/${id}`).then((data) => {
        handleDelete(data)
      })
    }
  }

  const refreshQr = (id, type = 0) => {
    get(`/admin/bulk-order/refresh-qr/${id}`).then((data) => {
      if (type == 1) {
        if (data.status == true) toast.success(data?.message, data?.status)
        else toast.error(data?.message, data?.status)
      }
    })
  }

  const handleDelete = (data) => {
    if (data.status == true) toast.success(data?.message, data?.status)
    else toast.error(data?.message, data?.status)
    getData()
  }

  const onSearchCLick = () => {
    setPageData({
      ...pageData,
      searchItem: searchItem,
    })
  }
  const printData = (data) => {
    navigate(`/admin/print-bulk-qr`, { state: data })
  }

  const handleCheck = (id) => {
    let findValue = deletedArray.includes(id)
    if (findValue) {
      let filterData = deletedArray?.filter((item) => item != id)
      setDeletedArray(filterData)
    } else {
      setDeletedArray((prev) => [...prev, id])
    }
  }

  const deleteOrders = () => {
    post(`admin/bulk-order/bulk-deleted-qr`, { qrIds: deletedArray }).then((data) =>
      handleDeletedResponse(data),
    )
  }

  const handleDeletedResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      setDeletedArray([])
      getData()
    }
  }

  return (
    <>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <div className="d-flex" style={{ flexDirection: 'column' }}>
                    <CCardSubtitle as="h4">Bulk Order QR </CCardSubtitle>
                    {deletedArray.length > 0 && (
                      <button className="btn btn-danger mt-2 text-white" onClick={deleteOrders}>
                        Delete
                      </button>
                    )}
                  </div>
                  <CRow>
                    <CCol md="6">
                      <DefaultInputWithIcon
                        type={'text'}
                        placeholder="Search"
                        customType={'search'}
                        value={searchItem}
                        onClick={onSearchCLick}
                        onChange={(e) => {
                          onSearchCLick()
                          setsearchItem(e?.target?.value)
                        }}
                      />
                    </CCol>
                    <CCol>
                      <Link to={{ pathname: '/admin/generate-qr-code' }} className="btn btn-dark">
                        <CIcon icon={cilPlus} />
                        Generate Bulk Order QR
                      </Link>
                    </CCol>
                  </CRow>
                </CHeader>

                <CCardBody>
                  <CTable bordered hover responsive>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell>S. No</CTableHeaderCell>
                        <CTableHeaderCell>QR ID</CTableHeaderCell>
                        <CTableHeaderCell style={{ textAlign: 'center' }}>
                          QR Image
                        </CTableHeaderCell>
                        <CTableHeaderCell>Driver</CTableHeaderCell>
                        <CTableHeaderCell>Action</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {randaomQR?.map((ele, index) => {
                        return (
                          <CTableRow key={index} >
                            <CTableDataCell onClick={() => handleCheck(ele._id)}>
                              {pageData?.perPage * (pageData?.page - 1) + index + 1}
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  style={{ marginLeft: '2px', marginTop: '-20px' }}
                                  type="checkbox"
                                  value=""
                                  id="flexCheckDefault"
                                  checked={deletedArray?.includes(ele._id)}
                                // onChange={() => handleCheck(ele._id)}
                                />
                              </div>
                            </CTableDataCell>
                            <CTableDataCell>QR{ele?.QrId}</CTableDataCell>
                            <CTableDataCell style={{ textAlign: 'center' }}>
                              <img src={ele?.QrImage} style={{ width: '100px' }} />
                              <br></br>
                              <p style={{ fontSize: '10px' }}>{ele?._id}</p>
                            </CTableDataCell>
                            <CTableDataCell>
                              {ele?.driver !== null && ele?.driver?.username !== null
                                ? ele?.driver?.username
                                : '-'}
                            </CTableDataCell>
                            <CTableDataCell>
                              <span
                                className="mx-2"
                                onClick={() => {
                                  refreshQr(ele?._id)
                                  printData(ele)
                                }}
                                style={{ fontSize: '20px', color: '#000', cursor: 'pointer' }}
                              >
                                <FaPrint />
                              </span>
                              <span
                                className="mx-2 text-danger"
                                onClick={() => deleteData(ele?._id)}
                                style={{ fontSize: '20px', padding: '5px', cursor: 'pointer' }}
                              >
                                <FaTrash />
                              </span>
                              {/* <span className="mx-2 text-danger" onClick={() => refreshQr(ele?._id,1)} style={{ fontSize: '20px', padding: '5px', cursor: 'pointer' }}><FaRecycle /></span> */}
                            </CTableDataCell>
                          </CTableRow>
                        )
                      })}
                    </CTableBody>
                  </CTable>
                  {!randaomQR?.length && (
                    <>
                      <h1>No Data</h1>
                    </>
                  )}
                  {randaomQR?.length ? (
                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={pageData?.page}
                      itemsCountPerPage={pageData?.perPage}
                      totalItemsCount={count}
                      pageRangeDisplayed={5}
                      onChange={changePage}
                    />
                  ) : (
                    ''
                  )}
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default add
