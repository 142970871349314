import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { post, get, deleteApi } from '../../utils/apiManager'
import CIcon from '@coreui/icons-react'
import { cilPen, cilPlus, cilTrash } from '@coreui/icons'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CCardSubtitle,
  CTable,
  CRow,
  CImage,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CButton,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import { DefaultInputWithIcon } from 'src/components/common/input'
import Pagination from 'react-js-pagination'
import { FaRegEye, FaEdit, FaTrash } from 'react-icons/fa'
import ShowMoreText from 'react-show-more-text'

function index() {
  const navigate = useNavigate();
  const [addOnService, setAddOnService] = useState();
  const [searchItem, setsearchItem] = useState("");
  const [imageUrl, setimageUrl] = useState('')
  const [totalPages, setTotalPages] = useState(0);
  const [count, setCount] = useState()
  const [pageData, setPageData] = useState({
    page: 1,
    perPage: 10,
    searchItem: "",
  });
  useEffect(() => {
    getData();
  }, [pageData]);

  const getData = () => {
    get(
      `/admin/add-on-service/get?page=${pageData?.page || 1}&perPage=${pageData?.perPage || 10
      }&searchItem=${pageData?.searchItem || ""}`
    ).then((data) => handleResponse(data));
  };
  const handleResponse = (data) => {
    if (!data?.status) toast.success(data?.message, data?.status);
    else {
      setAddOnService(data?.data[0]?.data);
      setTotalPages(data?.data[0]?.metadata[0]?.page);
      setCount(data?.data[0]?.metadata[0]?.total)
      setimageUrl(data?.imageUrl)
    }
  };
  const changePage = (index) => {
    setPageData({
      ...pageData,
      page: index,
    });
  };
  const statusChange = (status, id) => {
    let default_status = ''
    if (status == 0) default_status = "Active"
    else default_status = "Inactive"
    if (confirm(`Are you sure you want to ${default_status} the add on service`)) {
      let formData = {
        _id: id,
      };
      post(`${status ? "/admin/add-on-service/inactive" : "/admin/add-on-service/active"}`, formData).then(
        (data) => {
          handleStatusChange(data);
        }
      );
    }
  };
  const handleStatusChange = (data) => {
    toast.success(data?.message, data?.status);
    getData();
  };
  const onClickUserEdit = async (value) => {
    navigate('/admin/add-on-service/edit', { state: value })
  }
  const onClickUserView = (value) => {
    navigate(`/admin/add-on-service/view/${btoa(value?._id)}`, { state: value })
  }
  const deleteData = (id) => {
    if (confirm("Are you sure")) {
      let formData = {
        _id: id,
      };
      deleteApi(`/admin/add-on-service/delete?_id=${id}`).then((data) => {
        handleDelete(data);
      });
    }
  };
  const handleDelete = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
    } else {
      toast.error(data?.message)
    }

    getData();
  };
  const onSearchCLick = () => {
    setPageData({
      ...pageData,
      searchItem: searchItem,
    });
  };
  return (
    <>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <CCardSubtitle as="h4">Additional Services </CCardSubtitle>
                  <CRow>
                    <CCol md="8">
                      <DefaultInputWithIcon
                        type={'text'}
                        placeholder="Search"
                        customType={'search'}
                        value={searchItem}
                        onClick={onSearchCLick}
                        onChange={(e) => {
                          onSearchCLick()
                          setsearchItem(e?.target?.value)
                        }}
                      />
                    </CCol>
                    <CCol md="4">
                      <Link to={{ pathname: '/admin/add-on-service/add' }} className="btn btn-dark">
                        <CIcon icon={cilPlus} />
                        Add
                      </Link>
                    </CCol>
                  </CRow>
                </CHeader>

                <CCardBody>
                  <CTable bordered hover responsive>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell>S. No</CTableHeaderCell>
                        <CTableHeaderCell>Image</CTableHeaderCell>
                        <CTableHeaderCell>Name</CTableHeaderCell>
                        <CTableHeaderCell>Offer Applied</CTableHeaderCell>
                        <CTableHeaderCell>Status</CTableHeaderCell>
                        <CTableHeaderCell>Action</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {addOnService?.map((ele, index) => {
                        // console.log(ele?.offer[0], "loop")
                        return (
                          <CTableRow key={index}>
                            <CTableDataCell>{pageData?.perPage * (pageData?.page - 1) + index + 1}</CTableDataCell>
                            <CTableDataCell><CImage
                              rounded
                              src={`${imageUrl}${ele?.image}`}
                              // onError={addDefaultSrc}
                              style={{
                                maxHeight: '50px',
                                maxWidth: '50px',
                                borderRadius: 50,
                                alignItems: 'center',
                              }}
                            /></CTableDataCell>
                            <CTableDataCell>{ele?.serviceName_EN}</CTableDataCell>
                            <CTableDataCell style={{ color: '#2196F3', fontWeight: 500 }}>
                              {ele?.offer[0] != undefined ? ele.offer[0].couponCode : '--'}
                            </CTableDataCell>
                            <CTableDataCell>
                              <CButton
                                size="sm"
                                outline={ele?.status ? "1" : "0"}
                                style={{ backgroundColor: ele?.status ? '#00796B' : '#D32F2F', borderColor: ele?.status ? '#00796B' : '#D32F2F' }}
                                onClick={() =>
                                  statusChange(ele?.status, ele?._id)
                                }
                              >
                                {ele?.status ? "Active" : "Inactive"}
                              </CButton>
                            </CTableDataCell>
                            <CTableDataCell>
                              <span className="mx-2" onClick={() => onClickUserEdit(ele)} style={{ fontSize: '20px', color: '#d3dc40', cursor: 'pointer' }}><FaEdit /></span>
                              <span className="mx-2 text-danger" onClick={() => deleteData(ele?._id)} style={{ fontSize: '20px', padding: '5px', cursor: 'pointer' }}><FaTrash /></span>
                              <span className="mx-2" onClick={() => onClickUserView(ele)} style={{ fontSize: '20px', color: '#d3dc40', padding: '5px', cursor: 'pointer' }}><FaRegEye /></span>
                            </CTableDataCell>
                          </CTableRow>
                        );
                      })}
                    </CTableBody>
                  </CTable>
                  {!addOnService?.length && (
                    <>
                      <h1>No Data</h1>
                    </>
                  )}
                  {addOnService?.length ? <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={pageData?.page}
                    itemsCountPerPage={pageData?.perPage}
                    totalItemsCount={count}
                    pageRangeDisplayed={5}
                    onChange={changePage}
                  /> : ''}
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default index