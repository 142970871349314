import React from 'react';

export const ErrorText = (props) => {
  return (
    <>
      <div>
        <p className="errorText text-danger mt-2">{props.title}</p>
      </div>
    </>
  );
};
export const ErrorDesc = (props) => {
  // console.log(props)
  return (
    <>
      <div>
        <p className="errorText text-danger">{props.description_EN}</p>
      </div>
      <div>
        <p className="errorText text-danger">{props.description_TH}</p>
      </div>
    </>
  );
};
export const ErrorSelect = (props) => {
  // console.log(props)
  return (
    <>
      <div>
        <p className="errorText text-danger">{props.areazone}</p>
      </div>
    </>
  );
};
