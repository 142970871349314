
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const BagScanComplete = ({ visible, setVisible }) => {
    const navigate = useNavigate()
    return (
        <CModal
            alignment="center"
            visible={visible}
            onClose={() => setVisible(false)}
            aria-labelledby="VerticallyCenteredExample"
            className=' all-order-scan-modal'
        >

            <CModalBody>
                <div className='warning-content     '>
                    <span className='img-con'>
                        <img src="/img/check.png" alt='warning_image' />
                    </span>
                    <h1>Oh snap!</h1>
                    <span className="order-success-txt">
                        <h5>Order Successful</h5>
                        <p> Thank you so much for you order. </p>
                    </span>
                    <CButton onClick={() => navigate("/checkout-orders")}>Check out</CButton>
                </div>
            </CModalBody>
        </CModal>
    )
}

export default BagScanComplete