import React, { useState, useEffect } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { get } from 'src/utils/apiManager';
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CRow,
  CButton,
  CImage,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
} from '@coreui/react'
import { FaArrowLeft, FaEdit, FaRegEye, FaTrash } from 'react-icons/fa'
import { ImageUrl } from 'src/config/config';
import CIcon from '@coreui/icons-react';
import { cibElectron } from '@coreui/icons';

function view() {
  const { id } = useParams();
  const [driverInfo, setDriverInfo] = useState();
  const [driverOrderInfo, setDriverOrderInfo] = useState([]);
  const areaArr = [];

  const getDriverDetails = () => {
    get(`admin/Driver/view-driver?_id=${atob(id)}`).then((response) => {
      console.log(response?.data?.getDriverPickupOrder);
      setDriverInfo(response?.data?.driverDetail)
      setDriverOrderInfo(response?.data?.getDriverPickupOrder)
    })
  };

  useEffect(() => {
    getDriverDetails();
  }, []);

  return (
    <>
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <CRow>
                    <CCol >
                      {' '}
                      <Link style={{ color: '#000' }} to={{ pathname: `/admin/driver` }}>
                        <FaArrowLeft />
                      </Link>
                      <span>{' '} Driver Details</span>
                    </CCol>
                  </CRow>
                </CHeader>
                <CCardBody>
                  <CRow>
                    <CCol md={2}>
                      <strong>Driver Name :-</strong>
                    </CCol>
                    <CCol>
                      <span>{driverInfo?.username}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Email :-</strong>
                    </CCol>
                    <CCol>
                      <span>{driverInfo?.email}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Mobile no :-</strong>
                    </CCol>
                    <CCol>
                      <span>{driverInfo?.mobile}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Area Name :-</strong>
                    </CCol>
                    <CCol>
                      {driverInfo?.areaName?.areaName_EN}
                      {/* {driverInfo?.areaName?.map((item, index) => {
                        areaArr.push(item?.areaName_EN)
                      })}
                      <span>{areaArr?.join(',')}</span> */}
                    </CCol>
                  </CRow>
                  {/* <CRow>
                    <CCol md={2}>
                      <strong>City Name :-</strong>
                    </CCol>
                    <CCol>
                      {driverInfo?.city_id?.cityName_EN}
                    </CCol>
                  </CRow> */}
                  <CRow>
                    <CCol md={2}>
                      <strong>Assign Van number :-</strong>
                    </CCol>
                    <CCol>
                      {driverInfo?.vanNo?.vanNo}
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>Status:-</strong>
                    </CCol>
                    <CCol>
                      <CButton size="sm" outline={driverInfo?.status ? '1' : '0'}>
                        {driverInfo?.status == '1' ? 'Active' : 'Inactive'}
                      </CButton>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>
                      <strong>image:-</strong>
                    </CCol>
                    <CCol>
                      <div
                        style={{
                          marginTop: '10px',
                          justifyContent: 'center',
                        }}
                      >
                        <a target='_blank' href={`${ImageUrl}${driverInfo?.image}`}>
                          <CImage
                            rounded
                            src={`${ImageUrl}${driverInfo?.image}`}
                            style={{
                              maxHeight: '100px',
                              maxWidth: '100px',
                              borderRadius: 50,
                              alignItems: 'center',
                            }}
                          />
                        </a>
                      </div>
                    </CCol>
                  </CRow>
                  <h3 className="mt-2">Orders details</h3>
                  <CTable bordered hover responsive>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell>S. No</CTableHeaderCell>
                        <CTableHeaderCell>Order ID</CTableHeaderCell>
                        <CTableHeaderCell>Order Status</CTableHeaderCell>
                        <CTableHeaderCell>Order Type</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>

                      {driverOrderInfo.map((ele, index) => {
                        return (
                          <CTableRow key={index}>
                            <CTableDataCell>{index + 1}</CTableDataCell>
                            <CTableDataCell># {ele?.orderId}</CTableDataCell>
                            <CTableDataCell >
                              {
                                ele?.orderStatus == 'order_place' ?
                                  <CButton size="sm" style={{ backgroundColor: '#d3dc40', border: '#d3dc40' }} outline={ele?.orderStatus}>
                                    <span><strong>Order Place</strong></span>
                                  </CButton>
                                  : ele?.orderStatus == 'cancel(request)' ?
                                    <CButton size="sm" style={{ backgroundColor: '#BDBDBD', border: '#BDBDBD' }} outline={ele?.orderStatus}>
                                      <span><strong>Customer Request </strong></span>
                                    </CButton>
                                    : ele?.orderStatus == 'cancel(customer)' ?
                                      <CButton size="sm" style={{ backgroundColor: '#757575', border: '#757575' }} outline={ele?.orderStatus}>
                                        <span><strong>Cancel By Customer </strong></span>
                                      </CButton>
                                      : ele?.orderStatus == 'picked_up' ?
                                        <CButton size="sm" style={{ backgroundColor: '#FBC02D', border: '#FBC02D' }} outline={ele?.orderStatus}>
                                          <span><strong>Picked Up</strong></span>
                                        </CButton>
                                        : ele?.orderStatus == 'inprocess(Examine clothes)' ?
                                          <CButton size="sm" style={{ backgroundColor: '#757575', border: '#757575' }} outline={ele?.orderStatus}>
                                            <span><strong>In Process {' '}</strong></span>
                                          </CButton>
                                          : ele?.orderStatus == 'inprocess(service process)' ?
                                            <CButton size="sm" style={{ backgroundColor: '#BDBDBD', border: '#BDBDBD' }} outline={ele?.orderStatus}>
                                              <span><strong>In Process {' '}<CIcon icon={cibElectron} customClassName="nav-icon" style={{ height: '20px' }} /></strong></span>
                                            </CButton>
                                            : ele?.orderStatus == 'inprocess(payment success)' ?
                                              <CButton size="sm" style={{ backgroundColor: '#BDBDBD', border: '#BDBDBD' }} outline={ele?.orderStatus}>
                                                <span><strong>Payment Process {ele?.orderStatus}</strong></span>
                                              </CButton>
                                              : ele?.orderStatus == 'cancel' ?
                                                <CButton size="sm" style={{ backgroundColor: '#FF0000', border: '#FF0000' }} outline={ele?.orderStatus}>
                                                  <span><strong>Cancel</strong></span>
                                                </CButton>
                                                : ele?.orderStatus == 'hold' ?
                                                  <CButton size="sm" style={{ backgroundColor: '#2196F3', border: '#2196F3' }} outline={ele?.orderStatus}>
                                                    <span><strong>Hold</strong></span>
                                                  </CButton>
                                                  : ele?.orderStatus == 'delivery' ?
                                                    <CButton size="sm" style={{ backgroundColor: '#689F38', border: '#689F38' }} outline={ele?.orderStatus}>
                                                      <span><strong>Ready to Deliver</strong></span>
                                                    </CButton>
                                                    : ele?.orderStatus == 'out_for_delivery' ?
                                                      <CButton size="sm" style={{ backgroundColor: '#689F38', border: '#689F38' }} outline={ele?.orderStatus}>
                                                        <span><strong>Out for deliver</strong></span>
                                                      </CButton>
                                                      : ele?.orderStatus == 'deliverd' ?
                                                        <CButton size="sm" style={{ backgroundColor: '#2E7D32', border: '#2E7D32' }} outline={ele?.orderStatus}>
                                                          <span><strong>Deliverd</strong></span>
                                                        </CButton>
                                                        : 'In Process'
                              }
                            </CTableDataCell>
                            <CTableDataCell>{ele?.orderType}</CTableDataCell>
                          </CTableRow>
                        );
                      })}
                    </CTableBody>
                  </CTable>
                  {!driverOrderInfo?.length && (
                    <>
                      <strong className="text-danger"><center>No Order Yet</center></strong>
                    </>
                  )}
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default view