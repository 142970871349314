import React, { useRef, useState, useEffect } from 'react'
import { Formik } from 'formik'
import { useNavigate, Link } from 'react-router-dom'
import { post, get } from 'src/utils/apiManager'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { CCard, CCardBody, CCardGroup, CCol, CContainer, CFormLabel, CRow } from '@coreui/react'
import { DefaultInput } from 'src/components/common/input'
import { validateCity } from '../../utils/validator'
import Select from 'react-select'

const AddCity = () => {
  const navigate = useNavigate()
  const formRef = useRef(null)
  const [stateOptions, setStateOptions] = useState(null)
  const [stateList, setStateList] = useState(null)
  const [disable, setDisable] = useState(false)

  const StateListData = []

  useEffect(() => {
    fetchStateList()
  }, [])

  const fetchStateList = () => {
    get(`/admin/city/getAllState`).then((res) => {
      setStateList(res?.data)
    })
  }
  if (stateList !== null) {
    stateList.map((item, key) => {
      StateListData.push({ label: item.stateName_EN, value: item?._id })
    })
  }

  const submit = async (values) => {
    setDisable(true)

    let CityData = {
      state_id: values?.state_id,
      cityName_EN: values?.cityName_EN,
      cityName_TH: values?.cityName_TH,
    }
    post(`admin/city/insert`, CityData).then((res) => {
      handleResponse(res)
    })
  }

  const handleResponse = (res) => {
    if (res.status == true) {
      toast.success(res?.message)
      setTimeout(() => {
        navigate('/admin/city')
      }, 800)
    } else {
      setDisable(false)
      toast.error(res.message)
    }
    formRef.current.resetForm()
  }

  const onClickReset = () => {
    formRef.current.setFieldValue('state_id', '')
    formRef.current.setFieldValue('cityName_EN', '')
    formRef.current.setFieldValue('cityName_TH', '')
  }

  return (
    <>
      <CContainer>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <CRow>
                        <CCol md="11">
                          <h1>New City</h1>
                        </CCol>
                        <CCol md="1">
                          <Link
                            className={'btn btn-success mt-2'}
                            style={{ background: '#d3dc40', border: '#d3dc40' }}
                            to={{ pathname: `/admin/city` }}
                          >
                            Back
                          </Link>
                        </CCol>
                      </CRow>
                      <Formik
                        initialValues={{
                          state_id: '',
                          cityName_EN: '',
                          cityName_TH: '',
                        }}
                        validationSchema={validateCity}
                        onSubmit={(values, { setSubmitting }) => {
                          submit(values)
                          setSubmitting(false)
                        }}
                        innerRef={formRef}
                      >
                        {(props) => (
                          <form onSubmit={props.handleSubmit}>
                            <CFormLabel>State Name</CFormLabel>
                            <span className="text-danger" style={{ marginLeft: '5px' }}>
                              *
                            </span>
                            <Select
                              onChange={(val) => {
                                setStateOptions(val)
                                props.setFieldValue('state_id', val.value)
                              }}
                              options={StateListData}
                              value={stateOptions}
                              placeholder={'Select State'}
                              name={'State Name (EN)'}
                            />
                            {stateOptions == undefined ? (
                              <span className="text-danger">{props.errors.state_id}</span>
                            ) : (
                              ''
                            )}

                            <DefaultInput
                              type={'text'}
                              placeholder={'City Name (EN)'}
                              name={'City Name (EN)'}
                              // maxLength={30}
                              value={props.values.cityName_EN}
                              onChange={props.handleChange('cityName_EN')}
                              onBlur={() => props.setFieldTouched('cityName_EN', true)}
                              error={props.touched.cityName_EN && props.errors.cityName_EN}
                            />

                            <DefaultInput
                              type={'text'}
                              placeholder={'City Name (TH)'}
                              name={'City Name (TH)'}
                              // maxLength={30}
                              value={props.values.cityName_TH}
                              onChange={props.handleChange('cityName_TH')}
                              onBlur={() => props.setFieldTouched('cityName_TH', true)}
                              error={props.touched.cityName_TH && props.errors.cityName_TH}
                            />

                            <CRow>
                              <CCol md="2">
                                <button
                                  type="submit"
                                  className={'btn btn-success mt-2'}
                                  disabled={disable}
                                >
                                  Submit
                                </button>
                              </CCol>
                              <CCol md="2">
                                <button
                                  type="button"
                                  className={'btn btn-warning mt-2'}
                                  onClick={onClickReset}
                                >
                                  Reset
                                </button>
                              </CCol>
                              <CCol md="3"></CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default AddCity
