import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { post, get, deleteApi } from '../../utils/apiManager'
import CIcon from '@coreui/icons-react'
import { cilPlus } from '@coreui/icons'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CCardSubtitle,
  CTable,
  CRow,
  CImage,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CButton,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import { DefaultInputWithIcon } from 'src/components/common/input'
import Pagination from 'react-js-pagination'
import { FaRegEye, FaEdit, FaTrash, FaPrint } from 'react-icons/fa'
import ShowMoreText from 'react-show-more-text'
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CFormInput,
} from '@coreui/react'
function add() {
  const navigate = useNavigate()
  const [areaData, setAreaData] = useState([])
  const [randaomQR, setRandomQR] = useState([])
  const [searchItem, setsearchItem] = useState('')
  const [totalPages, setTotalPages] = useState(0)
  const [count, setCount] = useState()
  const [deletedArray, setDeletedArray] = useState([])
  const [pageData, setPageData] = useState({
    page: 1,
    perPage: 10,
    searchItem: '',
  })
  useEffect(() => {
    getData()
  }, [pageData])

  const getData = () => {
    get(
      `/admin/genrate-QR/get-QR-code?page=${pageData?.page || 1}&perPage=${pageData?.perPage || 10
      }&searchItem=${pageData?.searchItem || ''}`,
    ).then((data) => handleResponse(data))
  }
  const handleResponse = (data) => {
    if (!data?.status) toast.success(data?.message, data?.status)
    else {
      setRandomQR(data?.data?.freshQrCode[0]?.data)
      setTotalPages(data?.data?.freshQrCode[0]?.metadata[0]?.page)
      setCount(data?.data?.freshQrCode[0]?.metadata[0]?.total)
    }
  }

  const changePage = (index) => {
    setPageData({
      ...pageData,
      page: index,
    })
  }

  const deleteData = (id) => {
    if (confirm('Are you sure')) {
      deleteApi(`/admin/genrate-QR/delete-qr/${id}`).then((data) => {
        handleDelete(data)
      })
    }
  }

  const handleDelete = (data) => {
    toast.success(data?.message, data?.status)
    getData()
  }

  const generateQR = (value) => {
    post(`/admin/genrate-QR/QR-code-genrate`, { numberOfQR: value }).then((res) => {
      handleGenerateQR(res)
    })
  }

  const handleGenerateQR = (res) => {
    if (res?.status) {
      toast.success(res?.message)
      getData()
    }
  }
  const onSearchCLick = () => {
    setPageData({
      ...pageData,
      searchItem: searchItem,
    })
  }
  const printData = (data) => {
    navigate(`/admin/print`, { state: data })
  }

  const [modal, setModal] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const toggleModal = () => {
    setModal(false)
    setInputValue('')
  }

  const handleInputChange = (event) => {
    setInputValue(event.target.value)
  }

  const handleButtonClick = () => {
    if (!/^\d+$/.test(inputValue)) {
      setErrorMessage('Please enter a valid number')
    } else {
      generateQR(inputValue)
      setModal(false)
      setInputValue('')
    }
  }

  const handleCheck = (id) => {
    let findValue = deletedArray.includes(id)
    if (findValue) {
      let filterData = deletedArray?.filter((item) => item != id)
      setDeletedArray(filterData)
    } else {
      setDeletedArray((prev) => [...prev, id])
    }
  }

  const selectAll = () => {
    setDeletedArray([])
    let arr = [];
    randaomQR.forEach((ele) => arr.push(ele._id));
    setDeletedArray(arr);
  }

  const deleteOrders = () => {
    post(`admin/genrate-QR/bulk-deleted-qr`, { qrIds: deletedArray }).then((data) =>
      handleDeletedResponse(data),
    )
  }

  const handleDeletedResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      setDeletedArray([])
      getData()
    }
  }

  return (
    <>
      <CModal visible={modal} onClose={toggleModal}>
        <CModalHeader onClose={toggleModal}>
          <CModalTitle>Enter Value</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CFormInput
            type="text"
            id="input"
            value={inputValue}
            onChange={handleInputChange}
            placeholder="Enter value"
          />
          {errorMessage && <small className="text-danger">{errorMessage}</small>}
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={toggleModal}>
            Cancel
          </CButton>
          <CButton color="primary" onClick={handleButtonClick}>
            Submit
          </CButton>
        </CModalFooter>
      </CModal>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <div className="d-flex" style={{ flexDirection: 'column' }}>
                    <CCardSubtitle as="h4">Generate Random QR's</CCardSubtitle>
                    {deletedArray.length > 0 && (
                      <button className="btn btn-danger mt-2 text-white" onClick={deleteOrders}>
                        Delete
                      </button>
                    )}
                  </div>
                  <CRow>
                    <CCol md="7">
                      <DefaultInputWithIcon
                        type={'text'}
                        placeholder="Search"
                        customType={'search'}
                        value={searchItem}
                        onClick={onSearchCLick}
                        onChange={(e) => {
                          onSearchCLick()
                          setsearchItem(e?.target?.value)
                        }}
                      />
                    </CCol>
                    <CCol>
                      <button onClick={() => setModal(true)} className="btn btn-dark">
                        <CIcon icon={cilPlus} />
                        Generate QR
                      </button>
                    </CCol>
                  </CRow>
                </CHeader>
                <CCardBody>
                  <CTable bordered hover responsive>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell>S. No </CTableHeaderCell>
                        <CTableHeaderCell>QR ID</CTableHeaderCell>
                        <CTableHeaderCell style={{ textAlign: 'center' }}>
                          QR Image
                        </CTableHeaderCell>
                        {/* <CTableHeaderCell>Order ID</CTableHeaderCell> */}
                        <CTableHeaderCell>Action</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {randaomQR?.map((ele, index) => {
                        return (
                          <CTableRow key={index} >
                            <CTableDataCell onClick={() => handleCheck(ele._id)}>
                              {pageData?.perPage * (pageData?.page - 1) + index + 1}
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  style={{ marginLeft: '2px', marginTop: '-20px' }}
                                  type="checkbox"
                                  value=""
                                  id="flexCheckDefault"
                                  checked={deletedArray?.includes(ele._id)}
                                // onChange={() => handleCheck(ele._id)}
                                />
                              </div>
                            </CTableDataCell>
                            <CTableDataCell>{ele?._id}</CTableDataCell>
                            <CTableDataCell style={{ textAlign: 'center' }}>
                              <img src={ele?.QrImage} style={{ width: '40px' }} />
                              <br></br>
                              <p style={{ fontSize: '10px' }}>{ele?._id}</p>
                            </CTableDataCell>
                            {/* <CTableDataCell>{ele?.order_id !== null ? ele?.order_id : '-'}</CTableDataCell> */}
                            <CTableDataCell>
                              <span
                                className="mx-2"
                                onClick={() => printData(ele)}
                                style={{ fontSize: '20px', color: '#000', cursor: 'pointer' }}
                              >
                                <FaPrint />
                              </span>
                              <span
                                className="mx-2 text-danger"
                                onClick={() => deleteData(ele?._id)}
                                style={{ fontSize: '20px', padding: '5px', cursor: 'pointer' }}
                              >
                                <FaTrash />
                              </span>
                            </CTableDataCell>
                          </CTableRow>
                        )
                      })}
                    </CTableBody>
                  </CTable>
                  {!randaomQR?.length && (
                    <>
                      <h1>No Data</h1>
                    </>
                  )}
                  {randaomQR?.length ? (
                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={pageData?.page}
                      itemsCountPerPage={pageData?.perPage}
                      totalItemsCount={count}
                      pageRangeDisplayed={5}
                      onChange={changePage}
                    />
                  ) : (
                    ''
                  )}
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default add
