import {
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CRow,
  CCol,
  CContainer,
} from '@coreui/react'
import { ImageUrl2 } from 'src/config/config'

const ItemImagePreviewStain = ({ visiblePreview, setVisiblePreview, image, stain }) => {
  return (
    <>
      <CModal
        visible={visiblePreview}
        alignment="center"
        onClose={() => setVisiblePreview(false)}
        aria-labelledby="LiveDemoExampleLabel"
        size="xl"
      >
        <CModalHeader onClose={() => setVisiblePreview(false)}>
          <CModalTitle id="LiveDemoExampleLabel">Image Preview </CModalTitle>
        </CModalHeader>
        <CModalBody>
          {stain.map((item) => {
            return (
              <>
                <CRow className="justify-content-center mb-2">{item.stainName}</CRow>
                <CContainer>
                  <CRow>
                    {item.images &&
                      item.images.length > 0 &&
                      item.images.map((value) => {
                        return (
                          <CCol xs={6} className="mb-3">
                            <img
                              src={ImageUrl2 + value}
                              style={{ cursor: 'pointer', width: '100%', height: '100%' }}
                              onClick={() => setVisiblePreview(!visiblePreview)}
                            />
                          </CCol>
                        )
                      })}
                  </CRow>
                </CContainer>
              </>
            )
          })}
          {/* <CRow className="justify-content-center mb-2">
                        {image && image.length > 0 && image.map((value) => {
                            return (<CCol
                                xs={6}
                                className="align-items-center mb-3"
                                style={{ display: 'flex', flexDirection: 'column' }}
                            >
                                <img
                                    src={ImageUrl2 + value}
                                    style={{ cursor: "pointer", width: '100%', height: '100%    ' }}
                                    onClick={() => setVisiblePreview(!visiblePreview)}
                                /></CCol>)
                        })}
                    </CRow> */}
        </CModalBody>
      </CModal>
    </>
  )
}

export default ItemImagePreviewStain
