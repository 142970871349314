import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CCardSubtitle,
  CRow,
  CFormLabel,
  CImage,
  CButton,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
} from '@coreui/react'
import { ImageUrl } from 'src/config/config'
import { FaArrowLeft, FaSearchengin } from 'react-icons/fa'
import { get } from 'src/utils/apiManager'
import CIcon from '@coreui/icons-react'
import { cibElectron } from '@coreui/icons'

function UserDetail() {
  const { state } = useLocation()
  const { id } = useParams();
  const [deliveryQuota, setDeliveryQuota] = useState(0);
  const [userInfo, setUserInfo] = useState();
  const [userOrderInfo, setUserOrderInfo] = useState([]);
  const [remaingingQuota, setRemainingQuota] = useState({})
  const areaArr = [];
  const getDriverDetails = () => {
    get(`admin/User/view-user?_id=${atob(id)}`).then((response) => {
      setDeliveryQuota(response?.data?.deliveryQuota)
      setUserInfo(response?.data?.userDetail)
      setUserOrderInfo(response?.data?.getUserOrder)
      setRemainingQuota(response?.data?.quota)
    })
  };
  useEffect(() => {
    getDriverDetails();
  }, []);
  return (<>
    <CContainer fluid>
      <CRow>
        <CCol>
          <CCardGroup>
            <CCard className="p-4">
              <CHeader>
                <CRow>
                  <CCol >
                    {' '}
                    <Link style={{ color: '#000' }} to={{ pathname: `/admin/user` }}>
                      <FaArrowLeft />
                    </Link>
                    <span> User Information</span>
                  </CCol>
                </CRow>
              </CHeader>
              <CCardBody>
                <CRow>
                  <CCol md={2}>
                    <strong>User Name:-</strong>
                  </CCol>
                  <CCol>
                    <span>{userInfo?.username}</span>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md={2}>
                    <strong>Email:-</strong>
                  </CCol>
                  <CCol>
                    <span>{userInfo?.email}</span>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md={2}>
                    <strong>Mobile:-</strong>
                  </CCol>
                  <CCol>
                    <span>{userInfo?.mobile}</span>
                  </CCol>
                </CRow>
                <CRow className='mb-4'>
                  <CCol md={2}>
                    <strong>Role:-</strong>
                  </CCol>
                  <CCol>
                    <CButton size="sm" style={{ backgroundColor: '#d3dc40', border: '#d3dc40' }} outline={userInfo?.role ? 'Customer' : 'Sub-Admin'}>
                      <span>{userInfo?.role == 'customer' ? 'Customer' : 'Sub-Admin'}</span>
                    </CButton>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md={2}>
                    <strong>Status:-</strong>
                  </CCol>
                  <CCol>
                    <CButton size="sm" outline={userInfo?.status ? '2' : '0'}>
                      {userInfo?.status ? 'Active' : 'Inactive'}
                    </CButton>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md={2}>
                    <strong>Image:-</strong>
                  </CCol>
                  <CCol>
                    <div
                      style={{
                        marginTop: '10px',
                        justifyContent: 'center',
                      }}
                    >
                      <a target='_blank' href={`${ImageUrl}${userInfo?.image}`}>
                        <CImage
                          rounded
                          src={`${ImageUrl}${userInfo?.image}`}
                          style={{
                            maxHeight: '100px',
                            maxWidth: '100px',
                            borderRadius: 50,
                            alignItems: 'center',
                          }}
                        />
                      </a>
                    </div>
                  </CCol>
                </CRow>
                {/* <CRow>
                  <CCol md={2}>
                    <strong>Delivery Quota:-</strong>
                  </CCol>
                  {console.log("userInfo", userInfo)}
                  <CCol>
                    <span>{deliveryQuota}</span>
                  </CCol>
                </CRow> */}
                <h3 className="mt-2">Quota details</h3>
                <CTable bordered hover responsive>
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell>S. No</CTableHeaderCell>
                      <CTableHeaderCell>Service Name</CTableHeaderCell>
                      <CTableHeaderCell>Item Quata</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {remaingingQuota?.remainingServicesQuota?.map((ele, index) => {
                      return (
                        <CTableRow key={index}>
                          <CTableDataCell>{index + 1}</CTableDataCell>
                          <CTableDataCell> {ele?.serviceName}</CTableDataCell>
                          <CTableDataCell>{ele?.quota ?? 0}</CTableDataCell>
                        </CTableRow>
                      );
                    })}
                  </CTableBody>
                </CTable>
                <CTable bordered hover responsive>
                  <CTableBody>
                    <CTableRow >
                      <CTableHeaderCell style={{ width: "50%" }}> Delivery Quota</CTableHeaderCell>
                      <CTableHeaderCell>{deliveryQuota}</CTableHeaderCell>
                    </CTableRow>
                  </CTableBody>
                </CTable>
                <h3 className="mt-2">Orders details</h3>
                <CTable bordered hover responsive>
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell>S. No</CTableHeaderCell>
                      <CTableHeaderCell>Order ID</CTableHeaderCell>
                      <CTableHeaderCell>Order Status</CTableHeaderCell>
                      <CTableHeaderCell>Order Type</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>

                    {userOrderInfo?.map((ele, index) => {
                      return (
                        <CTableRow key={index}>
                          <CTableDataCell>{index + 1}</CTableDataCell>
                          <CTableDataCell># {ele?.orderId}</CTableDataCell>
                          <CTableDataCell >
                            {
                              ele?.orderStatus == 'order_place' ?
                                <CButton size="sm" style={{ backgroundColor: '#d3dc40', border: '#d3dc40' }} outline={ele?.orderStatus}>
                                  <span><strong>Order Place</strong></span>
                                </CButton>
                                : ele?.orderStatus == 'cancel(request)' ?
                                  <CButton size="sm" style={{ backgroundColor: '#BDBDBD', border: '#BDBDBD' }} outline={ele?.orderStatus}>
                                    <span><strong>Customer Request </strong></span>
                                  </CButton>
                                  : ele?.orderStatus == 'cancel(customer)' ?
                                    <CButton size="sm" style={{ backgroundColor: '#757575', border: '#757575' }} outline={ele?.orderStatus}>
                                      <span><strong>Cancel By Customer </strong></span>
                                    </CButton>
                                    : ele?.orderStatus == 'picked_up' ?
                                      <CButton size="sm" style={{ backgroundColor: '#FBC02D', border: '#FBC02D' }} outline={ele?.orderStatus}>
                                        <span><strong>Picked Up</strong></span>
                                      </CButton>
                                      : ele?.orderStatus == 'inprocess(Examine clothes)' ?
                                        <CButton size="sm" style={{ backgroundColor: '#757575', border: '#757575' }} outline={ele?.orderStatus}>
                                          <span><strong>In Process {' '}<FaSearchengin /></strong></span>
                                        </CButton>
                                        : ele?.orderStatus == 'inprocess(service process)' ?
                                          <CButton size="sm" style={{ backgroundColor: '#BDBDBD', border: '#BDBDBD' }} outline={ele?.orderStatus}>
                                            <span><strong>In Process {' '}<CIcon icon={cibElectron} customClassName="nav-icon" style={{ height: '20px' }} /></strong></span>
                                          </CButton>
                                          : ele?.orderStatus == 'inprocess(payment success)' ?
                                            <CButton size="sm" style={{ backgroundColor: '#BDBDBD', border: '#BDBDBD' }} outline={ele?.orderStatus}>
                                              <span><strong>Payment Process {ele?.orderStatus}</strong></span>
                                            </CButton>
                                            : ele?.orderStatus == 'cancel' ?
                                              <CButton size="sm" style={{ backgroundColor: '#FF0000', border: '#FF0000' }} outline={ele?.orderStatus}>
                                                <span><strong>Cancel</strong></span>
                                              </CButton>
                                              : ele?.orderStatus == 'hold' ?
                                                <CButton size="sm" style={{ backgroundColor: '#2196F3', border: '#2196F3' }} outline={ele?.orderStatus}>
                                                  <span><strong>Hold</strong></span>
                                                </CButton>
                                                : ele?.orderStatus == 'delivery' ?
                                                  <CButton size="sm" style={{ backgroundColor: '#689F38', border: '#689F38' }} outline={ele?.orderStatus}>
                                                    <span><strong>Ready to Deliver</strong></span>
                                                  </CButton>
                                                  : ele?.orderStatus == 'out_for_delivery' ?
                                                    <CButton size="sm" style={{ backgroundColor: '#689F38', border: '#689F38' }} outline={ele?.orderStatus}>
                                                      <span><strong>Out for deliver</strong></span>
                                                    </CButton>
                                                    : ele?.orderStatus == 'deliverd' ?
                                                      <CButton size="sm" style={{ backgroundColor: '#2E7D32', border: '#2E7D32' }} outline={ele?.orderStatus}>
                                                        <span><strong>Deliverd</strong></span>
                                                      </CButton>
                                                      : 'In Process'
                            }
                          </CTableDataCell>
                          <CTableDataCell>{ele?.orderType}</CTableDataCell>
                        </CTableRow>
                      );
                    })}
                  </CTableBody>
                </CTable>
                {!userOrderInfo?.length && (
                  <>
                    <strong className="text-danger"><center>No Order Placed Yet</center></strong>
                  </>
                )}
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </>)
}

export default UserDetail
