import React,{useRef,useEffect} from 'react';
import { Formik } from 'formik';
import { useLocation,useNavigate,Link } from 'react-router-dom'
import { put } from 'src/utils/apiManager';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import {
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CFormLabel,
    CRow,
  } from '@coreui/react'
  import { DefaultInput } from 'src/components/common/input'
import { validateFaq } from 'src/utils/validator'

function editFaq() {
    const navigate = useNavigate()
  const { state } = useLocation()
  // console.log(state)
  const formRef = useRef(null)
  const submit = async (values) => {
    let blogData = {
      id: state?._id,
      question_EN: values?.question_EN,
      question_TH: values?.question_TH,
      answer_EN: values?.answer_EN,
      answer_TH: values?.answer_TH,
    }
    put(`admin/faq/update?_id=${state?._id}`, blogData).then((data) => {
      // console.log(data)
      handleResponse(data)
    })
  }
  const handleResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      setTimeout(() => {
        navigate('/admin/faq')
      }, 800)
    }
    formRef.current.resetForm()
  }
  useEffect(() => {
    if (state != null) {
      formRef.current.setFieldValue('question_EN', state?.question_EN)
      formRef.current.setFieldValue('question_TH', state?.question_TH)
      formRef.current.setFieldValue('answer_EN', state?.answer_EN)
      formRef.current.setFieldValue('answer_TH', state?.answer_TH)
    }
  }, [state])
  return (
    <>
    <CContainer >
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                <CCardGroup>
                    <CCardBody>
                    <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                        <CRow>
                          <CCol md="11">
                          <h1>Edit FAQ</h1>
                          </CCol>                    
                          <CCol md="1">
                            <Link className={'btn btn-success mt-2'} style={{background:'#d3dc40',border: '#d3dc40' }} to={{ pathname: `/admin/Faq` }}>
                                Back
                            </Link>
                          </CCol>
                        </CRow>
                        <Formik
                          initialValues={{
                              question_EN: '',
                              question_TH: '',
                              answer_EN: '',
                              answer_TH: '',
                          }}
                          validationSchema={validateFaq}
                          onSubmit={(values, { setSubmitting }) => {
                              submit(values)
                              setSubmitting(false)
                              
                          }}
                          innerRef={formRef}
                        >
                        {(props) => (
                            <form onSubmit={props?.handleSubmit}>
                              <CRow>
                                <CCol md="6">
                                <CFormLabel>Question (EN)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                  <DefaultInput
                                    type={"text"}
                                    placeholder={"Question"}
                                    // name={"Question (EN)"}
                                    value={props?.values?.question_EN}
                                    onChange={props?.handleChange("question_EN")}
                                    onBlur={() => props?.setFieldTouched("question_EN", true)}
                                    error={props?.touched?.question_EN && props?.errors?.question_EN}
                                  />
                                </CCol>
                                <CCol md="6">
                                <CFormLabel>Question (TH)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                  <DefaultInput
                                    type={"text"}
                                    placeholder={"Question"}
                                    // name={"Question (TH)"}
                                    value={props?.values?.question_TH}
                                    onChange={props?.handleChange("question_TH")}
                                    onBlur={() => props?.setFieldTouched("question_TH", true)}
                                    error={props?.touched?.question_TH && props?.errors?.question_TH}
                                  />
                                </CCol>
                                <CCol md="6">
                                <CFormLabel>Answer (EN)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                  <DefaultInput
                                    type={"text"}
                                    placeholder={"answer"}
                                    // name={"Answer (EN)"}
                                    value={props?.values?.answer_EN}
                                    onChange={props?.handleChange("answer_EN")}
                                    onBlur={() => props?.setFieldTouched("answer_EN", true)}
                                    error={props?.touched?.answer_EN && props?.errors?.answer_EN}
                                  />
                                </CCol>
                                <CCol md="6">
                                <CFormLabel>Answer (TH)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                  <DefaultInput
                                    type={"text"}
                                    placeholder={"answer"}
                                    // name={"Answer (TH)"}
                                    value={props?.values?.answer_TH}
                                    onChange={props?.handleChange("answer_TH")}
                                    onBlur={() => props?.setFieldTouched("answer_TH", true)}
                                    error={props?.touched?.answer_TH && props?.errors?.answer_TH}
                                  />
                                </CCol>
                              </CRow> 
                              <CRow>
                                  <CCol md="2">
                                  <button
                                      type="submit"
                                      
                                      className={'btn btn-success mt-2'}
                                      disabled={props.isSubmitting}
                                  >
                                      Submit
                                  </button>
                                  </CCol>
                                  <CCol md="2">
                                  <button
                                      type="button"
                                      className={'btn btn-warning mt-2'}
                                      onClick={() => navigate('/admin/faq')}
                                  >
                                      Cancel
                                  </button>
                                  </CCol>
                                  <CCol md="3"></CCol>
                              </CRow>
                            </form>
                        )}
                        </Formik>
                    </CCardBody>
                </CCardGroup>
                </CCol>               
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default editFaq